import React from 'react';

import colors from '../../../../../theme/tailwind/colors';
import cn from '../../../../../Utils/cn';
import { Text } from '../../../TextComponents';
import type { CollapsibleCardProps } from '../types';
import { useCollapsible } from '../useCollapsible';

type NumberOfLines = 1 | 2 | 3 | 4 | 5 | 6;

const iconColor = colors?.teal[700];

interface ShowAllCollapsibleProps extends Omit<CollapsibleCardProps, 'collapsible'> {
  visibleLines?: NumberOfLines;
}

const VISIBLE_LINES_TO_CLASS_MAP: Record<NumberOfLines, string> = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6',
};

export const ShowAllCollapsible: React.FC<React.PropsWithChildren<ShowAllCollapsibleProps>> = ({
  children,
  isOpen,
  openByDefault,
  onCollapseClick,
  visibleLines = 2,
}) => {
  const { onClick, getChevron, isOpenCombined } = useCollapsible({
    collapsible: true,
    isOpen,
    openByDefault,
    onCollapseClick,
    chevronClassName: 'ml-[-4px]',
  });
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (containerRef.current) {
      const { clientHeight: containerHeight, scrollHeight } = containerRef.current;
      setIsOverflowing(scrollHeight > containerHeight);
    }
  }, [visibleLines, children]);

  return (
    <div className="flex flex-col gap-20">
      <div
        ref={containerRef}
        className={cn({
          [VISIBLE_LINES_TO_CLASS_MAP[visibleLines]]: !isOpenCombined,
        })}
      >
        {children}
      </div>
      {isOverflowing ? (
        <div className="row-auto flex items-center">
          <div className="row-auto flex w-full justify-end gap-10" onClick={onClick}>
            <div className="flex justify-end gap-10">
              <Text
                variant={Text.VARIANTS.SM}
                weight={Text.WEIGHTS.MEDIUM}
                colorVariant={Text.COLOR_VARIANTS.BUTTON_LINK}
              >
                {isOpenCombined ? 'Show Less' : 'Show All'}
              </Text>
              <div className="flex items-center">{getChevron({ iconColor })}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
