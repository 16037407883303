import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import { isEmpty, isNil } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import AssistanceAiBanner from '~/components/core/AssistanceAiBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import AiButton from '~/components/core/Buttons/AiButton/AiButton';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import Text from '~/components/core/TextComponents/Text';
import PaymentRequestDynamicContainer from '~/components/exposures/PaymentRequestContainer/PaymentRequestDynamicContainer';
import { getExposuresLabels, reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../../CardDialog';
import { Sparkle } from '../../icons';
import TextFieldFormik from '../../TextFieldFormik';

import styles from './GenAiPaymentSuggestion.module.scss';

const onGenAiDoc = async ({ fileId, organizationId, claimId }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/api/v1/generative_ai/documents_ai/${organizationId}/${claimId}/file/${fileId}/payment_suggestion`,
    });
    const { data } = response;
    return data;
  } catch (error) {
    await reportAxiosError(error);
  }
};

const getOverrideInitialValues = ({ paymentInitialValues, payableType }) => {
  const overrideInitialValues = {};
  if (paymentInitialValues) {
    if (!isNil(paymentInitialValues.amount)) {
      overrideInitialValues.amount = paymentInitialValues.amount;
    }

    if (!isNil(paymentInitialValues.invoice_number) && payableType === 'expenses') {
      overrideInitialValues.invoice_number = paymentInitialValues.invoice_number;
    }

    if (!isNil(paymentInitialValues.note)) {
      overrideInitialValues.note = paymentInitialValues.note;
    }
  }
  return overrideInitialValues;
};

const GenAiPaymentSuggestion = ({ open, setOpen, claim, document }) => {
  const classes = useStyles();
  const [documentPaymentState, setDocumentPaymentState] = useState(null);
  const [paymentInitialValues, setPaymentInitialValues] = useState(null);
  const showPaymentContainer = !isEmpty(documentPaymentState);
  const claimId = claim.id;
  const fileId = document?.stored_file?.id;
  const organizationId = claim.organization_id;

  useEffect(() => {
    if (showPaymentContainer) {
      const getInitialValues = async () => {
        const { amount, invoice_number, payment_description } = await onGenAiDoc({
          fileId,
          claimId,
          organizationId,
        });
        setPaymentInitialValues({ amount, invoice_number, note: payment_description });
      };
      getInitialValues();
    }
  }, [claimId, fileId, organizationId, showPaymentContainer]);

  if (showPaymentContainer && documentPaymentState) {
    const exposureId = documentPaymentState.exposureId;
    const payableType = documentPaymentState.payableType;

    const overrideInitialValues = getOverrideInitialValues({
      paymentInitialValues,
      payableType: documentPaymentState.payableType,
    });

    return (
      <CardDialog
        isDialog={true}
        title={
          <AssistanceAiBanner
            title="AI Assistant Payment Suggestion"
            size={AssistanceAiBanner.SIZE_VARIANTS.SM}
            iconSize={20}
            weight={AssistanceAiBanner.WEIGHTS.MEDIUM}
          />
        }
        fullWidth
        open={open}
        maxWidth="xl"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={4}>
            {paymentInitialValues ? (
              <PaymentRequestDynamicContainer
                claimId={claim.id}
                exposureId={exposureId}
                payableWithReserveType={payableType}
                cardDialogProps={{
                  isDialog: false,
                  maxWidth: 'md',
                  fullWidth: true,
                }}
                overrideInitialValues={overrideInitialValues}
                forceManualDataInit
                onClose={() => {
                  setDocumentPaymentState(null);
                  setPaymentInitialValues(null);
                  setOpen(false);
                }}
                onUpdate={() => {}}
                paymentSuggestion={{ source: 'document_ai', values: paymentInitialValues }}
              />
            ) : (
              <div className={styles.aiLoaderContainer}>
                <Sparkle animated size={48} />
              </div>
            )}
          </Grid>
          <Grid item md={8}>
            {/* pdf viewer */}
            <object
              data={`/api/v1/claims/${claim.id}/documents/${document.id}`}
              style={{ height: '85vh', width: '100%' }}
            >
              Invoice PDF
            </object>
          </Grid>
        </Grid>
      </CardDialog>
    );
  }

  const exposureIds = document.exposure_ids;
  const exposures = getExposuresLabels(claim);
  const defaultExposureId = exposureIds?.length === 1 ? exposureIds[0] : null;
  return (
    <CardDialog
      isDialog={true}
      title={
        <AssistanceAiBanner
          title="AI Assistant Payment Suggestion"
          size={AssistanceAiBanner.SIZE_VARIANTS.XL}
          iconSize={28}
          weight={AssistanceAiBanner.WEIGHTS.SEMI_BOLD}
        />
      }
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        initialValues={{
          payableType: defaultExposureId === 0 ? 'expenses' : 'indemnity',
          exposureId: exposureIds?.length === 1 ? exposureIds[0] : null,
        }}
        validationSchema={Yup.object().shape({
          payableType: Yup.string().when('exposureId', {
            is: (exposureId) => exposureId !== 0, // Condition for 'indemnity' validation
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notOneOf(['indemnity'], 'Cannot be indemnity when exposure is General'),
          }),
          exposureId: Yup.number().required('Required'),
        })}
      >
        {(formikProps) => (
          <>
            <div className={styles.container}>
              <Text weight={Text.WEIGHTS.SEMI_BOLD} variant={Text.VARIANTS.LG}>
                Payment Details
              </Text>
              <RadioButtonGroupFormik
                className={styles.radioButtonGroup}
                label="Payable Type"
                id="payableType"
                direction="row"
                options={[
                  {
                    text: 'Indemnity',
                    optionValue: 'indemnity',
                    disabled: formikProps.values.exposureId === 0,
                  },
                  {
                    text: 'Expenses',
                    optionValue: 'expenses',
                  },
                ]}
                btnClassName={styles.btnClassName}
                variant="body1"
                btnContainerClassName={styles.btnContainerClassName}
              />
              <TextFieldFormik
                id="exposureId"
                label="Exposure"
                className={cn(classes.textField, styles.exposureSelect)}
                fullWidth
                select
                disabled={formikProps.isSubmitting}
              >
                {exposures.map((exposure) => (
                  <MenuItem key={exposure.id} value={exposure.id}>
                    {exposure.label}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
            <div className={styles.dialogButtonContainer}>
              <AiButton
                onClick={() => {
                  setDocumentPaymentState(formikProps.values);
                }}
              >
                Make Payment
              </AiButton>
            </div>
          </>
        )}
      </Formik>
    </CardDialog>
  );
};

GenAiPaymentSuggestion.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
};

export default GenAiPaymentSuggestion;
