import React, { useRef, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { connect, Formik } from 'formik';
import { AccountGroupOutline, PhonePlus } from 'mdi-material-ui';
import * as Yup from 'yup';

import { Text } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import { getExposuresArrayFromClaimByIds, reportAxiosError, yupPhoneValidation } from '../../Utils';
import { isClaimWriteDisabled } from '../../Utils/ClaimUtils';
import CheckboxFormik from '../CheckboxFormik';
import { useClaim } from '../ClaimContainer';
import mixpanel from '../CmsMain/mixpanel';
import { ContactEntity } from '../Contact';
import { useCms } from '../hooks/useCms';
import InlineIconButton from '../InlineIconButton';
import { TextFieldFormik } from '../TextFieldFormik';

import CommunicationsAiInsight from './CommunicationsAiInsight/CommunicationsAiInsight';
import CommunicationCard, { CommunicationActionSendNew, useCreateCommunication } from './CommunicationCard';

import styles, { useStyles } from '../../assets/styles';

export const phoneCallMessageValidation = Yup.object().shape({
  contact_id: Yup.number().required('Required'),
  contact: Yup.object().test(
    'is-phone-exists',
    'Contact must contain at least one phone number',
    (contact) => contact && contact.phones && contact.phones.length > 0
  ),
  phone_id: Yup.number().required('Required'),
  is_no_answer: Yup.bool(),
  content: Yup.string().when('is_no_answer', {
    is: (val) => val !== true,
    then: Yup.string().required('Required'),
  }),
  exposure_ids: Yup.array().required('Required').min(1, 'Required'),
  summary: Yup.string(),
});

export const phoneCallCommunicationToFormikValues = (communication) => ({
  summary: communication.summary,
  content: communication.content,
  is_no_answer: communication.is_no_answer,
});

const FormikPhoneCallContentTextField = withStyles(styles)(
  connect((props) => {
    const { classes, formik, communication, isView, isEditing, onRequestEdit, isDocument } = props;
    const { values } = formik;

    return (
      <>
        {isView && !isEditing && communication.is_no_answer ? (
          <Typography display="block">Phone call was not answered</Typography>
        ) : (
          <div className={classes.inputContainer}>
            <TextFieldFormik
              id="content"
              label="Content"
              className={classes.textField}
              fullWidth
              multiline
              rows="5"
              onEdit={onRequestEdit ? () => onRequestEdit(true) : undefined}
              showOnly={isView && !isEditing}
              disabled={values['is_no_answer']}
            />
          </div>
        )}

        {isView && communication.extra.phone_call_forwarded && (
          <Typography style={{ color: '#00217d' }} display="block">
            Call was forwarded to {communication.extra.phone_call_forwarded.type}{' '}
            {communication.extra.phone_call_forwarded.target_friendly_name}
          </Typography>
        )}

        {((isView && isEditing) || isDocument) && (
          <div className={classes.inputContainer}>
            <CheckboxFormik id="is_no_answer" label="No answer" className={classes.textField} fullWidth />
          </div>
        )}
      </>
    );
  })
);

FormikPhoneCallContentTextField.propTypes = {
  communication: requiredIf(PropTypes.object, (props) => props.isView),
  isView: PropTypes.bool.isRequired,
  isDocument: PropTypes.bool,
  isEditing: PropTypes.bool,
  onRequestEdit: PropTypes.func,
};

export function getPhoneCallCommunicationSpecificIdentifier(communication) {
  return communication.specific_identifier;
}

export function PhoneCallCommunicationSpecificBody(props) {
  const classes = useStyles();
  const { communication, communicationInitialFields, isDocument, isNew, isEditing, onRequestEdit, isNotClaimRelated } =
    props;

  const cacheKey = useRef(Date.now());

  const isView = !isNew && !isDocument;

  return (
    <>
      {isView ? (
        <>
          <CommunicationsAiInsight communicationId={communication.id} />
          {communication.recording_stored_file_id && (
            <div>
              <audio controls src={`/api/v1/calls/${communication.id}/recording?nocache=${cacheKey.current}`} />
            </div>
          )}

          {communication.additional_conference_participants.length !== 0 && (
            <>
              <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <AccountGroupOutline />
                &nbsp;
                <Typography display="inline" variant="subtitle1">
                  Conference Call:
                </Typography>
              </div>
              {communication.additional_conference_participants.map((participant) => (
                <Grid key={participant.id} item xs={12}>
                  <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    {participant.contact_id ? (
                      <ContactEntity
                        classes={classes}
                        contactId={participant.contact.id}
                        contactDisplayName={participant.contact.full_name}
                      />
                    ) : (
                      <Typography display="inline">
                        {participant.name} &nbsp;-&nbsp; {participant.phone_number}
                      </Typography>
                    )}
                    {participant.status !== 'Off' && <span>&nbsp;({participant.status})</span>}{' '}
                    {/* Call was not answered */}
                  </div>
                </Grid>
              ))}
            </>
          )}
        </>
      ) : isNotClaimRelated ? (
        <div className={classes.containerCentered} style={{ justifyContent: 'center' }}>
          <TextFieldFormik
            id="phone_number"
            label="Phone Number"
            className={classes.textField}
            style={{ width: '280px' }}
          />
        </div>
      ) : communicationInitialFields.communicationContact &&
        communicationInitialFields.communicationContact.id &&
        communicationInitialFields.communicationContact.phones.length > 0 ? (
        <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
          {communicationInitialFields.communicationContact.phones.length === 1 ? (
            <Text variant={Text.VARIANTS.SM}>
              {communicationInitialFields.communicationContact.phones[0].phone} (
              {communicationInitialFields.communicationContact.phones[0].phone_type})
            </Text>
          ) : (
            <div className={classes.inputContainer}>
              <TextFieldFormik
                id="phone_id"
                label="Phone"
                className={classes.textField}
                style={{ width: '280px' }}
                select
              >
                {communicationInitialFields.communicationContact.phones.map((phone) => (
                  <MenuItem key={phone.id} value={phone.id}>
                    {phone.phone} ({phone.phone_type})
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
          )}{' '}
        </div>
      ) : (
        <></>
      )}
      <FormikPhoneCallContentTextField
        communication={communication}
        isView={isView}
        isDocument={isDocument}
        isEditing={isEditing}
        onRequestEdit={onRequestEdit}
      />
    </>
  );
}

PhoneCallCommunicationSpecificBody.propTypes = {
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  isEditing: PropTypes.bool,
  onRequestEdit: PropTypes.func,
  communicationInitialFields: PropTypes.shape({
    communicationChannel: PropTypes.string.isRequired,
    communicationContact: PropTypes.object,
    communicationDirection: requiredIf(PropTypes.string, (props) => props.isNew),
  }),
  isNotClaimRelated: PropTypes.bool,
};

function DocumentPhoneCallCommunicationCardContainer({
  contact,
  contactPhoneId,
  onCancel,
  onDocumentPhoneCall,
  direction,
  disableSoftClose,
  open = true,
  onMinimized,
  disableMinimized,
}) {
  const { claim } = useClaim();
  const { communicationCommonInitialValues } = useCreateCommunication('phone_call', { contact });
  const validationSchema = phoneCallMessageValidation;

  return (
    <Formik
      initialValues={{
        ...communicationCommonInitialValues,
        phone_id: contact ? (contactPhoneId ? contactPhoneId : contact.primary_phone.id) : '',
        is_no_answer: false,
        content: '',
        direction: direction || 'Outgoing',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formikProps) => {
        return axios
          .post(
            `/api/v1/claims/${claim.id}/contacts/${values.contact.id}/phones/${values['phone_id']}/document_phone_call`,
            values
          )
          .then((res) => {
            formikProps.setSubmitting(false);
            onDocumentPhoneCall(res.data);
          })
          .catch((error) => {
            formikProps.setSubmitting(false);
            reportAxiosError(error);
          });
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;

        return (
          <CommunicationCard
            communicationInitialFields={{
              communicationChannel: 'phone_call',
              communicationContact: values.contact,
              communicationDirection: direction,
            }}
            communicationHeaderAdditionalProps={{
              isDynamicContact: !contact,
              dynamicContactLabel: 'Caller Contact',
              onSelectContact: (contact) => {
                if (!contact || contact.id === '') {
                  setFieldValue('phone_id', '');
                } else {
                  if (contact.phones.length > 0) {
                    setFieldValue('phone_id', contact.phones[0].id);
                  } else {
                    setFieldValue('phone_id', '');
                  }
                }
              },
            }}
            disableSoftClose={disableSoftClose}
            CommunicationTypeSpecificBody={PhoneCallCommunicationSpecificBody}
            isDialog
            isDocument
            isSubmitting={formikProps.isSubmitting}
            onClose={onCancel}
            CommunicationAction={CommunicationActionDocumentNew}
            communicationActionProps={{
              onSubmit: () => {
                formikProps.handleSubmit();
              },
              isSubmitting: formikProps.isSubmitting,
            }}
            onMinimized={onMinimized}
            disableMinimized={disableMinimized}
            open={open}
          />
        );
      }}
    </Formik>
  );
}

DocumentPhoneCallCommunicationCardContainer.propTypes = {
  claim: PropTypes.object.isRequired, // comes from withClaim
  contact: PropTypes.object,
  contactPhoneId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onDocumentPhoneCall: PropTypes.func.isRequired,
  direction: PropTypes.bool,
  disableSoftClose: PropTypes.bool,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

function CommunicationActionDocumentNew(props) {
  const classes = useStyles();
  const { isSubmitting, onSubmit } = props;

  return (
    <div className={classes.buttonsContainer}>
      <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitting}>
        Save
      </Button>
    </div>
  );
}

CommunicationActionDocumentNew.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

function NewPhoneCallCommunicationCard({
  contact,
  contactPhoneId,
  onCancel,
  onNewPhoneCallCreated,
  isNotClaimRelated,
  notClaimRelatedCallPhoneNumber,
}) {
  const { communicationCommonInitialValues } = useCreateCommunication('phone_call', { contact });
  const { setCallInProgress } = useCms();
  const { claim } = useClaim();

  return (
    <Formik
      initialValues={{
        ...communicationCommonInitialValues,
        phone_id: contactPhoneId,
        phone_number: notClaimRelatedCallPhoneNumber || '',
        content: '',
      }}
      validationSchema={Yup.object().shape(
        isNotClaimRelated
          ? { phone_number: yupPhoneValidation.required('Required'), summary: Yup.string() }
          : {
              contact_id: Yup.number().required('Required'),
              phone_id: Yup.number().required('Required'),
              exposure_ids: Yup.array().required('Required').min(1, 'Required'),
              summary: Yup.string(),
            }
      )}
      enableReinitialize
      onSubmit={async (values) => {
        setCallInProgress({
          ...(isNotClaimRelated
            ? {
                phoneNumber: values.phone_number,
              }
            : {
                claimId: claim.id,
                contactId: values.contact.id,
                contactPhoneId: values.phone_id,
                exposureIds: values.exposure_ids,
              }),
          isNotClaimRelated,
          summary: values.summary,
          direction: 'Outgoing',
          content: values.content,
        });

        await onNewPhoneCallCreated();

        const exposures = getExposuresArrayFromClaimByIds(claim, values.exposure_ids);
        mixpanel.track(MIXPANEL_EVENTS.PHONE_CALL_COMMUNICATION_MADE, {
          ...mixpanel.getMixpanelAttributes(claim, exposures, {
            source: MIXPANEL_EVENT_SOURCES.NEW_PHONE_CALL,
          }),
        });
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;

        return (
          <CommunicationCard
            communicationInitialFields={{
              communicationChannel: 'phone_call',
              communicationContact: values.contact,
              communicationDirection: 'Outgoing',
            }}
            CommunicationTypeSpecificBody={PhoneCallCommunicationSpecificBody}
            communicationTypeSpecificBodyProps={{ isNotClaimRelated }}
            communicationHeaderAdditionalProps={{
              isDynamicContact: !contact && !isNotClaimRelated,
              dynamicContactLabel: 'Call To',
              onSelectContact: (contact) => {
                if (!contact || contact.id === '') {
                  setFieldValue('phone_id', '');
                } else {
                  if (contact.phones.length > 0) {
                    setFieldValue('phone_id', contact.phones[0].id);
                  } else {
                    setFieldValue('phone_id', '');
                  }
                }
              },
            }}
            isDialog
            isNew
            maxWidth="sm"
            onClose={onCancel}
            CommunicationAction={CommunicationActionSendNew}
            communicationActionProps={{
              onSubmit: () => {
                formikProps.handleSubmit();
              },
              isSubmitting: formikProps.isSubmitting,
              buttonText: 'Call',
            }}
          />
        );
      }}
    </Formik>
  );
}

NewPhoneCallCommunicationCard.propTypes = {
  contact: PropTypes.object,
  contactPhoneId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onNewPhoneCallCreated: PropTypes.func.isRequired,
  isNotClaimRelated: PropTypes.bool,
  notClaimRelatedCallPhoneNumber: PropTypes.string,
};

function NewPhoneCallCommunicationContainer() {
  const [showPhoneCallCommunicationCard, setShowPhoneCallCommunicationCard] = useState(false);
  const [showDocumentPhoneCallCommunicationCard, setShowDocumentPhoneCallCommunicationCard] = useState(false);
  const { claim, onAsyncClaimUpdate } = useClaim();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useCms();

  if (!claim) {
    throw Error('New phone call should be within claim context');
  }

  function handleNewCallClicked(event) {
    if (user.is_call_center_allowed) {
      setAnchorEl(event.currentTarget);
    } else {
      setShowDocumentPhoneCallCommunicationCard(true);
    }
  }

  return (
    <>
      <InlineIconButton
        defaultColor="inherit"
        onClick={handleNewCallClicked}
        icon={PhonePlus}
        tooltipTitle="New Phone Call"
        disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
      />

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setShowDocumentPhoneCallCommunicationCard(true);
            setAnchorEl(null);
          }}
        >
          Document call
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowPhoneCallCommunicationCard(true);
            setAnchorEl(null);
          }}
        >
          Make a call
        </MenuItem>
      </Menu>

      {showPhoneCallCommunicationCard && (
        <NewPhoneCallCommunicationCard
          onCancel={() => setShowPhoneCallCommunicationCard(false)}
          onNewPhoneCallCreated={() => {
            onAsyncClaimUpdate();
            setShowPhoneCallCommunicationCard(false);
          }}
        />
      )}

      {showDocumentPhoneCallCommunicationCard && (
        <DocumentPhoneCallCommunicationCardContainer
          claim={claim}
          onCancel={() => setShowDocumentPhoneCallCommunicationCard(false)}
          onDocumentPhoneCall={() => {
            onAsyncClaimUpdate();
            setShowDocumentPhoneCallCommunicationCard(false);
          }}
        />
      )}
    </>
  );
}

export { NewPhoneCallCommunicationCard, NewPhoneCallCommunicationContainer };
export default DocumentPhoneCallCommunicationCardContainer;
