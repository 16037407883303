import React from 'react';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { getAllSearchableContactRoles } from '../../communications/ContactUtils';
import ContactTextFieldFormik from '../../ContactTextFieldFormik';
import useOrganization from '../../OrganizationContext';
import TextFieldFormik from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const ExternalClaimNumberFragment = () => {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();
  const { isSubmitting } = useFormikContext();

  return (
    <Grid container spacing={5}>
      <Grid item xs={6} className="my-2">
        <TextFieldFormik
          id="external_claim_number"
          label="External Claim Number"
          placeholder="External Claim Number"
          className={classes.textField}
          fullWidth
          disabled={isSubmitting}
        />
      </Grid>
      <Grid item xs={6} className="my-5">
        <ContactTextFieldFormik
          id="external_contact"
          label="External Contact"
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          fullWidth
          fixedSearchResults
          disabled={isSubmitting}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id="description"
          label="Description"
          placeholder="Description"
          className={classes.textField}
          fullWidth
          disabled={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

export default ExternalClaimNumberFragment;
