import React from 'react';

import type { MessageActionsProps } from '~/components/AiChat/components/Message/MessageActions';
import { MessageActions } from '~/components/AiChat/components/Message/MessageActions';
import WithConfirm from '~/components/ConfirmModal';
import { FsButton, Text } from '~/components/core';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';

export const ChatTitleBar: React.FC<{
  title?: string;
  onNewChat: () => Promise<void>;
  actions: Omit<MessageActionsProps, 'type'>;
  isChatLoading?: boolean;
}> = ({ title, onNewChat, actions, isChatLoading }) => {
  return (
    <div className="flex items-center justify-between bg-aiV2-fadedWhite px-20 py-10">
      <OverflowTextWithToolTip>
        <Text className="font-bold text-aiV2-grey-400" variant={Text.VARIANTS.SM}>
          {!isChatLoading && title ? title : ''}
        </Text>
      </OverflowTextWithToolTip>
      {!isChatLoading ? <MessageActions type="outlined" {...actions} /> : null}
      {!isChatLoading ? (
        <WithConfirm
          primaryButtonName="restart"
          title="Are you sure?"
          contentText="This will delete all messages and restart the chat"
          shouldCloseOnPrimary={true}
        >
          <FsButton
            variant={FsButton.VARIANTS.outlined}
            onClick={onNewChat}
            className="rounded-md border-2 border-aiV2-pink bg-aiV2-lightpink text-aiV2-pink"
          >
            + New Chat
          </FsButton>
        </WithConfirm>
      ) : null}
    </div>
  );
};
