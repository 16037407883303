import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import useCccAssignment from '~/components/hooks/useCccAssigment';
import useXactAssigment from '~/components/hooks/useXactAssignment';

import { isoDateTimeToLocal, serverDateTimeToLocal } from '../../../DateTimeUtils';
import { ShowOnlyTextField } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const VendorStatusDataDialogCard = ({ moiRecord, onBackClick = noop, claimIdDisplayWithExposureSuffix }) => {
  const classes = useStyles();
  const { cccTransactionDetails, isCccAssignment } = useCccAssignment(moiRecord.id, claimIdDisplayWithExposureSuffix);
  const { xactTransactionDetails, isXactAssignment } = useXactAssigment(moiRecord.id);

  return (
    <>
      <div className="h-full">
        {!!moiRecord.status_update_datetime && (
          <div className="mt-10 text-slate-800">
            Information was last updated at:&nbsp;
            <b>{serverDateTimeToLocal(moiRecord.status_update_datetime)}</b>
          </div>
        )}
        <p className="my-20">
          <b>Details</b>
        </p>
        <Grid container alignItems="stretch" spacing={1}>
          {isCccAssignment
            ? cccTransactionDetails
            : isXactAssignment
            ? xactTransactionDetails
            : moiRecord.vendor_status_data.map((status_data, i) => (
                <Grid item xs={4} key={i}>
                  <ShowOnlyTextField
                    classes={classes}
                    showOnlyValueComponent={
                      status_data.is_datetime ? isoDateTimeToLocal(status_data.value) : status_data.value
                    }
                    label={status_data.display_name}
                  />
                </Grid>
              ))}
        </Grid>
      </div>
      <div className="mt-24 flex w-full justify-end">
        <CancelButton onClick={onBackClick} content="Back" autoFocus contained withMarginRight={false} />
      </div>
    </>
  );
};

VendorStatusDataDialogCard.propTypes = {
  moiRecord: PropTypes.object,
  onBackClick: PropTypes.func,
  claimIdDisplayWithExposureSuffix: PropTypes.string,
};

export default VendorStatusDataDialogCard;
