import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { getIn, useFormikContext } from 'formik';

import { ShowOnlyTextField } from '../../TextFieldFormik';

import styles, { useStyles } from '../../../assets/styles';

function SwitchFormik(props) {
  const {
    id,
    label,
    showOnly,
    disabled,
    onChange,
    color,
    className,
    withShowOnlyText,
    labelStyle,
    hideErrorGap,
    reverse,
  } = props;
  const classes = useStyles();

  const { values, touched, errors, setFieldTouched, setFieldValue } = useFormikContext();

  if (showOnly && !withShowOnlyText) {
    return <ShowOnlySwitch label={label} showOnlyValueComponent={getIn(values, id)} />;
  }

  if (showOnly && withShowOnlyText) {
    return (
      <ShowOnlyTextField classes={classes} showOnlyValueComponent={getIn(values, id) ? 'Yes' : 'No'} label={label} />
    );
  }

  const handleChange = (event, switched) => {
    if (onChange) {
      return onChange(event, switched);
    }
    setFieldValue(id, reverse ? !switched : switched);
  };

  const checkedValue = reverse ? !getIn(values, id) : !!getIn(values, id);

  return (
    <>
      <FormControl component="fieldset" error={getIn(errors, id) && getIn(touched, id)}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                value={checkedValue}
                disabled={disabled}
                onChange={handleChange}
                onBlur={() => setFieldTouched(id, true)}
                color={color ? color : undefined}
                className={className}
                checked={checkedValue}
              />
            }
            label={label}
            style={Object.assign({ margin: '0px', display: 'flex', alignItems: 'center' }, labelStyle ?? {})}
          />
        </FormGroup>
        {!hideErrorGap &&
          (getIn(errors, id) && getIn(touched, id) ? (
            <FormHelperText>{getIn(errors, id)}</FormHelperText>
          ) : (
            <div style={{ height: '18px' }} />
          ))}
      </FormControl>
    </>
  );
}

SwitchFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  hideErrorGap: PropTypes.bool,
  withShowOnlyText: PropTypes.bool,
  labelStyle: PropTypes.object,
  reverse: PropTypes.bool,
};

function ShowOnlySwitch(props) {
  const { label, showOnlyValueComponent } = props;
  const classes = useStyles();

  return (
    <div className={classes.showField}>
      <div className={classes.showValueField} style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Switch checked={showOnlyValueComponent} disabled={true} />
        <Typography display="block" variant="caption">
          {label}
        </Typography>
      </div>
    </div>
  );
}

ShowOnlySwitch.propTypes = {
  label: PropTypes.string.isRequired,
  showOnlyValueComponent: PropTypes.node.isRequired,
};

export { ShowOnlySwitch };
export default withStyles(styles)(SwitchFormik);
