import React from 'react';

import Text from '~/components/core/TextComponents/Text';

const RefreshToaster: React.FC = () => (
  <div className="w-[300px] rounded border-1 border-solid border-green-700 bg-green-100 px-15 py-10 opacity-80 shadow-sm">
    <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
      Refreshing...
    </Text>
  </div>
);

export default RefreshToaster;
