import React from 'react';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import { capitalize } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import Text from '~/components/core/TextComponents/Text';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import LoadingIndicator from '~/components/LoadingIndicator';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik, { MonetaryValueTextFieldFormik } from '~/components/TextFieldFormik';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

import Caption from '../core/TextComponents/Caption';

interface GeneralRecoveryDialogProps {
  claimId: number;
  exposureIdOrNullIfGeneralExpenses: number | null;
  onClose: () => void;
  onUpdate: () => void | Promise<void>;
}

interface GeneralRecoveryDialogFormSubmitValuesProps {
  payable_type: string;
  amount: string;
  recovered_from_contact_id: string | number;
  reference: string;
  note: string;
}

const GeneralRecoveryDialog: React.FC<GeneralRecoveryDialogProps> = ({
  claimId,
  exposureIdOrNullIfGeneralExpenses,
  onClose,
  onUpdate,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();

  const handleSubmit = async (values: GeneralRecoveryDialogFormSubmitValuesProps) => {
    const url = exposureIdOrNullIfGeneralExpenses
      ? `/api/v1/claims/${claimId}/exposures/${exposureIdOrNullIfGeneralExpenses}/${values.payable_type}/general_recoveries`
      : `/api/v1/claims/${claimId}/general_expenses/general_recoveries`;
    try {
      await axios.post(url, {
        amount: values.amount,
        recovered_from_contact_id: values.recovered_from_contact_id,
        reference: values.reference,
        note: values.note,
      });
      await onUpdate();
      onClose();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  return (
    <Formik
      initialValues={{
        payable_type: exposureIdOrNullIfGeneralExpenses === null ? 'expenses' : '',
        amount: '',
        recovered_from_contact_id: '',
        reference: '',
        note: '',
      }}
      validationSchema={Yup.object().shape({
        payable_type: Yup.string().required('Required'),
        amount: Yup.number().nullable().required('Required'),
        recovered_from_contact_id: Yup.number().required('Required'),
        reference: Yup.string(),
        note: Yup.string(),
      })}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            open
            isDialog
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            title="Add Recovery Amount"
            preventClose={isSubmitting}
          >
            <div className="grid gap-10">
              <Caption variant={Caption.VARIANTS.LABEL}>Payable Type</Caption>
              <RadioButtonGroupFormik
                id="payable_type"
                options={['indemnity', 'expenses'].map((payableType) => ({
                  optionValue: payableType,
                  text: capitalize(payableType),
                }))}
                showOnly={isSubmitting || exposureIdOrNullIfGeneralExpenses === null}
              />
              <div className="w-1/2">
                <MonetaryValueTextFieldFormik
                  id="amount"
                  label="Amount"
                  className={classes.textField}
                  disabled={isSubmitting}
                  fullWidth
                />
              </div>
              <div className="w-1/2">
                <ContactTextFieldFormik
                  id="recovered_from_contact"
                  label="Recovered from Contact"
                  acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                  fixedSearchResults
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
              <div>
                <TextFieldFormik
                  id="reference"
                  label="Reference"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
              <div>
                <TextFieldFormik
                  id="note"
                  label="Recovery Note"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className={classes.buttonsContainer}>
              <CancelButton content="Cancel" disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Add
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

interface PayableWithReserveForGeneralRecovery {
  general_recoveries_sum: number | null;
}

interface GeneralRecoveryPayableWithReserveSumProps {
  payableWithReserve: PayableWithReserveForGeneralRecovery;
}

const GeneralRecoveryPayableWithReserveSum: React.FC<GeneralRecoveryPayableWithReserveSumProps> = ({
  payableWithReserve,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { currencyFormatter } = useCurrencyFormatter();

  const completeColor = 'text-status-done';
  const recoverySum = payableWithReserve.general_recoveries_sum;

  if (recoverySum && recoverySum > 0) {
    // Recovery complete/pending with recovered syn
    return (
      <Typography variant="body2" className={completeColor}>
        Gen Rec: {currencyFormatter.format(recoverySum)}
      </Typography>
    );
  } else {
    return <></>;
  }
};

interface CancelGeneralRecoveryDialogProps {
  claimId: number;
  exposureIdOrNullIfGeneralExpenses: number | null;
  payableType: string;
  recoveryAmountId: number;
  onCancel: () => void;
  onUpdate: () => void | Promise<void>;
}

const CancelGeneralRecoveryDialog: React.FC<CancelGeneralRecoveryDialogProps> = ({
  claimId,
  exposureIdOrNullIfGeneralExpenses,
  payableType,
  recoveryAmountId,
  onCancel,
  onUpdate,
}) => {
  const baseResourceUrl = exposureIdOrNullIfGeneralExpenses
    ? `/api/v1/claims/${claimId}/exposures/${exposureIdOrNullIfGeneralExpenses}/${payableType}/general_recoveries/${recoveryAmountId}`
    : `/api/v1/claims/${claimId}/general_expenses/general_recoveries/${recoveryAmountId}`;

  const { isLoading, isError, data: recoveryAmount } = useDataFetcher(baseResourceUrl);
  const classes = useStyles();

  if (isLoading) {
    return (
      <CardDialog isDialog open onClose={onCancel} title="Cancel Recovery">
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  return (
    <Formik
      initialValues={{ cancellation_note: '' }}
      validationSchema={Yup.object().shape({ cancellation_note: Yup.string().required('Required') })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.delete(baseResourceUrl, { data: { ...values } });
          await onUpdate();
          formikProps.resetForm();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog isDialog onClose={onCancel} title="Cancel Recovery" preventClose={isSubmitting}>
            <div className={classes.textField}>
              <Text weight={Text.WEIGHTS.REGULAR}>Recovery of amount: {recoveryAmount.amount}</Text>
              {recoveryAmount.reference && (
                <Text weight={Text.WEIGHTS.REGULAR}>Reference: {recoveryAmount.check_number}</Text>
              )}
              {recoveryAmount.note && <Text weight={Text.WEIGHTS.REGULAR}>Note: {recoveryAmount.note}</Text>}
            </div>
            <TextFieldFormik id="cancellation_note" label="Reason" fullWidth className={classes.textField} />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Cancel Recovery
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default GeneralRecoveryDialog;
export { CancelGeneralRecoveryDialog, GeneralRecoveryPayableWithReserveSum };
