import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'formik';

import styles from '~/assets/styles';
import Editor from '~/components/core/Editor';
import Text from '~/components/core/TextComponents/Text';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import ReactQuillWrapper from './ReactQuillWrapper';

import 'react-quill/dist/quill.snow.css';

const EditorFormik = withStyles(styles)(
  connect(({ formik, ...props }) => {
    const { userOrganization } = useCms();
    const { id, label, disabled, showOnly, autoFocus, maxHeight } = props;
    const { values, setFieldValue } = formik;
    const content = values[id];
    const displayNewEditor = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NEW_EMAIL_EDITOR);

    const handleChange = (value) => {
      setFieldValue(id, value);
    };

    return (
      <div>
        {label && (
          <Text className="pt-10" variant={Text.VARIANTS.SM} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            {label}
          </Text>
        )}
        {!showOnly ? (
          <div className="py-10">
            {!displayNewEditor ? (
              <ReactQuillWrapper value={values[id]} onChange={handleChange} disabled={disabled} autoFocus={autoFocus} />
            ) : (
              <Editor
                className="max-h-[24vh] overflow-auto"
                disabled={disabled}
                onChange={handleChange}
                autoFocus={autoFocus}
                displayConfig={{
                  font: true,
                  textColor: true,
                  textStyle: true,
                  align: true,
                  link: true,
                }}
                content={content}
              />
            )}
          </div>
        ) : (
          <div style={{ maxHeight, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
    );
  })
);

EditorFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxHeight: PropTypes.string,
};

export default EditorFormik;
