import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import filenamify from 'filenamify';
import fileSize from 'filesize';
import validFilename from 'valid-filename';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import { compose } from '~/Utils';

import CardDialog from '../CardDialog';
import { withClaim } from '../ClaimContainer';
import { GalleryCard, getAllClaimPhotos } from '../Gallery/GalleryScreen';
import { withCmsContext } from '../hooks/useCms';
import HoverChangeField from '../HoverChangeField';
import InlineIconButton from '../InlineIconButton';
import { withOrganization } from '../OrganizationContext';
import PlainTable from '../PlainTable';

import DocumentsContainer, { getDocumentsTableColumnData } from './DocumentsContainer';

import styles from '../../assets/styles';

function AttachDocumentDialogInner({ classes, claim, onClaimUpdate, open, onCancel, onAttach }) {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

  return (
    <CardDialog
      title="Attach Document"
      fullWidth
      isDialog
      open={open}
      maxWidth="lg"
      onClose={() => {
        setSelectedDocumentIds([]);
        onCancel();
      }}
    >
      <DocumentsContainer
        classes={classes}
        claim={claim}
        selectedDocumentIds={selectedDocumentIds}
        onDocumentsClick={(documentIds) => setSelectedDocumentIds(documentIds)}
        onNewDocumentCreated={onClaimUpdate}
        maxHeight="550px"
        removeUploadedTime
        autoPaginateRowsPerPage={25}
      />
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedDocumentIds.length === 0}
          onClick={() => onAttach(selectedDocumentIds)}
        >
          Attach
        </Button>
      </div>
    </CardDialog>
  );
}

AttachDocumentDialogInner.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAttach: PropTypes.func.isRequired,
};

const AttachDocumentDialog = withClaim(withStyles(styles)(AttachDocumentDialogInner));

function createAttachedDocumentEntry(claim, docId) {
  const doc = claim.documents.find((d) => d.id === docId);
  const attachmentFilename = filenamify(doc.document_name_with_ext, { replacement: '' });
  return { ...doc, attachment_filename: attachmentFilename };
}

function DocumentsAttachment({
  claim,
  classes,
  attachedDocuments = [],
  showOnly,
  maxHeight,
  hideAttachmentFilename,
  documentTypesDict,
  userOrganization,
  onUpdateAttachedDocuments,
}) {
  const [showAttachDocumentDialog, setShowAttachDocumentDialog] = useState(false);
  const [showAddPhotos, setShowAddPhotos] = useState(false);

  const attachDocuments = async (documentIds) => {
    let newAttachedDocuments = [...attachedDocuments];
    for (const docId of documentIds) {
      if (!newAttachedDocuments.map((doc) => doc.id).includes(docId)) {
        newAttachedDocuments = [...newAttachedDocuments, createAttachedDocumentEntry(claim, docId)];
      }
    }
    await onUpdateAttachedDocuments(newAttachedDocuments);
  };

  const handleAttachPhotos = async (photoIds) => {
    await attachDocuments(photoIds);
    setShowAddPhotos(false);
  };

  const handleAttachDocuments = async (documentIds) => {
    await attachDocuments(documentIds);
    setShowAttachDocumentDialog(false);
  };

  const handleUpdateAttachedDocumentFilename = (id, newFilename) => {
    return onUpdateAttachedDocuments(
      attachedDocuments.map((attachedDocument) =>
        attachedDocument.id === id ? { ...attachedDocument, attachment_filename: newFilename } : attachedDocument
      )
    );
  };

  const handleRemoveAttachment = (documentId) => {
    const newAttachedDocuments = attachedDocuments.filter((document) => document.id !== documentId);
    onUpdateAttachedDocuments(newAttachedDocuments);
  };

  let columnData = [
    // copy a subset of the columns of the documents table
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'view_action'),
    {
      id: 'attachment_filename',
      numeric: false,
      label: 'Attachment Filename',
      specialCell: (document) =>
        showOnly ? (
          document.attachment_filename
        ) : (
          <HoverChangeField
            name="attachment_filename"
            validationSchema={Yup.string()
              .required('Required')
              .test(
                'valid-filename',
                String.raw`Please enter a valid file name (the following characters are not allowed: \ / : * ? " < > |)`,
                (document_name) => validFilename(document_name)
              )}
            value={document.attachment_filename}
            label="Attachment Filename"
            onUpdate={(updatedValues) =>
              handleUpdateAttachedDocumentFilename(document.id, updatedValues['attachment_filename'])
            }
          />
        ),
    },
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'id'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'type'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'document_name'),
    {
      id: 'size',
      label: 'File Size',
      specialCell: (document) => <span style={{ whiteSpace: 'nowrap' }}>{fileSize(document.size)}</span>,
    },
    {
      id: 'remove_attachment',
      width: '30px',
      disableSort: true,
      specialCell: (document, isHover) =>
        isHover ? (
          <InlineIconButton
            icon={DeleteIcon}
            className={classes.textIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveAttachment(document.id);
            }}
          />
        ) : (
          <div className={classes.textIconFiller} />
        ),
    },
  ];

  if (showOnly) {
    columnData = columnData.filter((column) => column.id !== 'remove_attachment');
  }

  if (hideAttachmentFilename) {
    columnData = columnData.filter((column) => column.id !== 'attachment_filename');
  }

  return (
    <>
      {!showOnly && (
        <div className={classes.buttonsContainer}>
          <Button color="primary" onClick={() => setShowAttachDocumentDialog(true)}>
            <AddIcon className={classes.leftButtonIcon} />
            Add Documents
          </Button>
          <Button color="primary" onClick={() => setShowAddPhotos(true)}>
            <AddIcon className={classes.leftButtonIcon} />
            Add Photos
          </Button>
        </div>
      )}
      <PlainTable classes={classes} columns={columnData} rows={attachedDocuments} maxHeight={maxHeight} />
      <AttachDocumentDialog
        open={showAttachDocumentDialog}
        onCancel={() => setShowAttachDocumentDialog(false)}
        onAttach={handleAttachDocuments}
      />
      {showAddPhotos && (
        <GalleryCard // using the "showAddPhotos && ..." to reset the selected photos every time the card is opened
          allMedia={getAllClaimPhotos(claim)}
          title="Add Photos"
          onClose={() => setShowAddPhotos(false)}
          shouldAllowSelect={true}
          onPhotosSelected={handleAttachPhotos}
        />
      )}
    </>
  );
}

DocumentsAttachment.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  userOrganization: PropTypes.object.isRequired, // from cms context
  attachedDocuments: PropTypes.array.isRequired,
  showOnly: PropTypes.bool,
  hideAttachmentFilename: PropTypes.bool,
  onUpdateAttachedDocuments: requiredIf(PropTypes.func, (props) => !props.showOnly),
  maxHeight: PropTypes.string,
  documentTypesDict: PropTypes.object.isRequired, // from organization context
};

export { createAttachedDocumentEntry };

export default compose(withStyles(styles), withClaim, withOrganization, withCmsContext)(DocumentsAttachment);
