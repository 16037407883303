import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocal } from '../DateTimeUtils';
import { reportAxiosError, stringCmp } from '../Utils';

import DamageAssessmentContainer from './exposures/DamageAssessmentContainer';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import ClaimLink from './ClaimLink';
import { ContactShowOnlyTextField } from './ContactTextFieldFormik';
import { SortableTable } from './core';
import {
  ActiveRequestActionContainer,
  DaRequestSummaryDialog,
  InspectionDateContainer,
  SchedulingInspectionContactContainer,
  ViewOnlyRequestsDialog,
} from './DaSpecialistContainer';
import InlineIconButton from './InlineIconButton';
import { default as InvolvedPropertyInfoTitle } from './InvolvedPropertyHoverInfo';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function FieldInspectionQueuesContainer() {
  const { userOrganization, user } = useCms();
  const classes = useStyles();

  const [selectedFieldInspectorId, setSelectedFieldInspectorId] = useState();
  const [fieldInspectorsIdToUser, setFieldInspectorsIdToUser] = useState({});
  const [filterFutureRequests, setFilterFutureRequests] = useState(true);

  const {
    isLoadingFieldInspectors,
    isErrorFieldInspectors,
    data: fieldInspectors,
  } = useDataFetcher(`/api/v1/organizations/${userOrganization.id}/get_all_field_inspectors`);

  useEffect(() => {
    if (!isLoadingFieldInspectors && !isErrorFieldInspectors && fieldInspectors && fieldInspectors.length) {
      const idToUserMap = _.keyBy(fieldInspectors, 'id');
      setFieldInspectorsIdToUser(idToUserMap);
      if (idToUserMap[user.id]) {
        setSelectedFieldInspectorId(user.id);
      } else {
        setSelectedFieldInspectorId(fieldInspectors[0].id);
      }
    }
  }, [isLoadingFieldInspectors, isErrorFieldInspectors, fieldInspectors, user]);

  const FIELD_INSPECTOR_SELECT_ID = 'field_inspector';
  const FIELD_INSPECTOR_SELECT_LABEL = 'Field Inspector';

  const disabled = isLoadingFieldInspectors || isErrorFieldInspectors;

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Field Inspection Queues">
        <Grid container>
          <div className={classes.leftButtonsContainer}>
            {selectedFieldInspectorId && (
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '300px' }}>
                <InputLabel id={FIELD_INSPECTOR_SELECT_ID} className={classes.textFieldRow}>
                  {FIELD_INSPECTOR_SELECT_LABEL}
                </InputLabel>
                <Select
                  id={FIELD_INSPECTOR_SELECT_ID}
                  onChange={(event) => {
                    setSelectedFieldInspectorId(event.target.value);
                  }}
                  label={FIELD_INSPECTOR_SELECT_LABEL}
                  labelId={FIELD_INSPECTOR_SELECT_ID}
                  className={classes.textFieldRow}
                  value={selectedFieldInspectorId}
                  disabled={disabled}
                  fullWidth
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Object.keys(fieldInspectorsIdToUser).map((fieldInspectorId) => (
                    <MenuItem key={fieldInspectorId} value={fieldInspectorId}>
                      {fieldInspectorsIdToUser[fieldInspectorId].username}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <Chip
                key="filter_future_requests"
                size="small"
                color={filterFutureRequests ? 'default' : 'primary'}
                onClick={() => setFilterFutureRequests(!filterFutureRequests)}
                label="Show Future Requests"
                className={classes.chip}
              />
            </div>
          </div>
          {selectedFieldInspectorId && (
            <FieldInspectionQueues
              selectedFieldInspector={fieldInspectorsIdToUser[selectedFieldInspectorId]}
              filterFutureRequests={filterFutureRequests}
            />
          )}
          {isLoadingFieldInspectors && <LoadingIndicator isError={isErrorFieldInspectors} />}
        </Grid>
      </CardDialog>
    </div>
  );
}

function FieldInspectionQueues({ selectedFieldInspector, filterFutureRequests }) {
  const { moiOptions, moiOptionsOrder } = useOrganization();
  const { userOrganization } = useCms();

  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const initialDetailsDialogState = { openDialog: false, isFieldRequest: true, daRequest: undefined };
  const [showDetailsDialog, setShowDetailsDialog] = useState(initialDetailsDialogState);
  const initialNonActiveRequestsDialogState = { openDialog: false, isFieldRequest: true, status: undefined };
  const [showNonActiveRequests, setShowNonActiveRequests] = useState(initialNonActiveRequestsDialogState);
  const [activeRequestsByInspectionType, setActiveRequestsByInspectionType] = useState({});

  const {
    isLoading,
    isError,
    data: activeDaRequests,
    reloadData,
  } = useDataFetcher(
    `/api/v1/auto_claims/da_requests?handling_specialist_id=${selectedFieldInspector.id}&status=active`
  );

  const disabled = isLoading || isSubmitting;

  const fieldInspectionQueues = [];

  Object.keys(moiOptions).forEach((claimType) => {
    Object.keys(moiOptions[claimType]).forEach((method) => {
      if (moiOptions[claimType][method].is_field_inspection) {
        fieldInspectionQueues.push({
          inspectionType: method,
          title: moiOptions[claimType][method].title,
          isVendorAssigned: moiOptions[claimType][method].required_vendor,
        });
      }
    });
  });

  if (moiOptionsOrder) {
    const getOrderIndexForQueueOption = (inspectionType) =>
      moiOptionsOrder.findIndex((method) => inspectionType === method);

    fieldInspectionQueues.sort((queue1, queue2) =>
      getOrderIndexForQueueOption(queue1.inspectionType) > getOrderIndexForQueueOption(queue2.inspectionType) ? 1 : -1
    );
  }

  async function handleDismissing(url, params) {
    try {
      setIsSubmitting(true);
      await axios({ method: 'post', url, data: params });
      await reloadData();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function dismissRequest(request, is_complete) {
    await handleDismissing(
      `/api/v1/auto_claims/${request.claim_id}/exposures/${request.exposure.id}/da_requests/${request.id}/dismiss`,
      { is_complete }
    );
  }

  const getActiveRequestsColumns = (isVendorAssigned) =>
    getDaRequestsColumns(
      false,
      reloadData,
      disabled,
      ActiveRequestActionContainer,
      { dismissRequest: async (request, isComplete) => await dismissRequest(request, isComplete) },
      (request) => setShowDetailsDialog({ openDialog: true, isFieldRequest: true, daRequest: request }),
      isVendorAssigned
    );

  useEffect(() => {
    const activeRequestsByType = {};

    if (activeDaRequests) {
      activeDaRequests.da_requests
        .filter((daRequest) =>
          filterFutureRequests
            ? !daRequest.inspection_date ||
              moment(daRequest.inspection_date)
                .tz(userOrganization.timezone)
                .isSameOrBefore(moment.tz(userOrganization.timezone))
            : true
        )
        .forEach((daRequest) => {
          if (!activeRequestsByType[daRequest.field_inspection_type]) {
            activeRequestsByType[daRequest.field_inspection_type] = [];
          }
          activeRequestsByType[daRequest.field_inspection_type].push(daRequest);
        });

      setActiveRequestsByInspectionType(activeRequestsByType);
    }
  }, [activeDaRequests, filterFutureRequests, userOrganization.timezone]);

  if (isLoading) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <>
      <Grid container>
        {fieldInspectionQueues.map(({ inspectionType, title, isVendorAssigned }) => (
          <Grid item xs={12} key={inspectionType}>
            <div className={classes.cardDivRow}>
              <CardDialog title={`${title} Queue`} outlinedCard>
                <div className={classes.leftButtonsContainer}>
                  <IconButton
                    onClick={() =>
                      setShowNonActiveRequests({ openDialog: true, isFieldRequest: true, status: 'dismissed' })
                    }
                  >
                    <Tooltip title="See dismissed assignments">
                      <AssignmentReturnIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      setShowNonActiveRequests({ openDialog: true, isFieldRequest: true, status: 'completed' })
                    }
                  >
                    <Tooltip title="See completed assignments">
                      <AssignmentTurnedInIcon />
                    </Tooltip>
                  </IconButton>
                </div>
                <SortableTable
                  columns={getActiveRequestsColumns(isVendorAssigned)}
                  defaultOrderColumn={0}
                  order="desc"
                  rows={
                    activeRequestsByInspectionType[inspectionType] ? activeRequestsByInspectionType[inspectionType] : []
                  }
                  maxHeight="30vh"
                  stickyHeader
                />
              </CardDialog>
            </div>
          </Grid>
        ))}
      </Grid>

      <ViewOnlyRequestsDialog
        user={selectedFieldInspector}
        requestStates={showNonActiveRequests.status}
        open={showNonActiveRequests.openDialog}
        isFieldRequest={showNonActiveRequests.isFieldRequest}
        onClose={() => setShowNonActiveRequests(initialNonActiveRequestsDialogState)}
        onUpdate={reloadData}
        handleDismissing={handleDismissing}
        onShowRequestDetails={(request) =>
          setShowDetailsDialog({ openDialog: true, isFieldRequest: true, daRequest: request })
        }
        disabled={disabled}
      />
      <DaRequestSummaryDialog
        open={showDetailsDialog.openDialog}
        daRequest={showDetailsDialog.daRequest}
        isFieldRequest={showDetailsDialog.isFieldRequest}
        onClose={() => setShowDetailsDialog(initialDetailsDialogState)}
        isFromFieldInspectionQueue
      />
    </>
  );
}

FieldInspectionQueues.propTypes = {
  selectedFieldInspector: PropTypes.object.isRequired,
  filterFutureRequests: PropTypes.bool,
};

function getDaRequestsColumns(
  showOnly,
  onUpdate,
  disabled,
  ActionComponent,
  actionComponentAdditionalProps,
  onShowRequestDetails,
  isVendorAssigned
) {
  const daRequestsColumns = [
    { id: 'id', numeric: true, disablePadding: true, label: '#', width: '40px' },
    {
      id: 'creation_date',
      numeric: false,
      label: 'Assignment Date',
      width: '200px',
      specialCell: (request) => serverDateToLocal(request.creation_date),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'claim',
      label: 'Claim',
      width: '150px',
      specialCell: (request) => <ClaimLink claimId={request.claim_id} linkText={request.claim_id_display} />,
      specialCmpFunc: (row1, row2) => row1.claim_id - row2.claim_id,
    },
    {
      id: 'exposure_claim_internal_id',
      numeric: true,
      align: 'center',
      width: '40px',
      disableSort: true,
      label: <span style={{ whiteSpace: 'nowrap' }}>Exp. #</span>,
    },
    {
      id: 'exposure',
      numeric: false,
      label: 'Exposure',
      // eslint-disable-next-line react/display-name
      specialCell: (request) => (
        <>
          {request.exposure.coverage_type_desc}
          &nbsp;-&nbsp;
          <InvolvedPropertyInfoTitle claimId={request.claim_id} involvedProperty={request.involved_vehicle} />
        </>
      ),
      specialCmpFunc: (row1, row2) => row1.exposure.coverage_type - row2.exposure.coverage_type,
    },
    {
      id: 'scheduling_inspection_contact',
      numeric: false,
      label: 'Scheduling Contact',
      // eslint-disable-next-line react/display-name
      specialCell: (request) => (
        <SchedulingInspectionContactContainer
          daRequest={request}
          onUpdate={onUpdate}
          showOnly={showOnly}
          claimId={request.claim_id}
        />
      ),
      specialCmpFunc: (request1, request2) =>
        stringCmp(
          request1.scheduling_inspection_contact ? request1.scheduling_inspection_contact.full_name : '',
          request2.scheduling_inspection_contact ? request2.scheduling_inspection_contact.full_name : ''
        ),
    },
    {
      id: 'vendor',
      numeric: false,
      label: 'Body Shop',
      // eslint-disable-next-line react/display-name
      specialCell: (request) =>
        request.vendor && (
          <ContactShowOnlyTextField
            label=""
            contactId={request.vendor ? request.vendor.id : ''}
            contactDisplayName={request.vendor ? request.vendor.full_name : ''}
            claimId={request.claim_id}
          />
        ),
      specialCmpFunc: (request1, request2) =>
        stringCmp(
          request1.scheduling_inspection_contact ? request1.scheduling_inspection_contact.full_name : '',
          request2.scheduling_inspection_contact ? request2.scheduling_inspection_contact.full_name : ''
        ),
    },
    {
      id: 'inspection_date',
      label: 'Expected Inspection End Date',
      leftPaddingOnly: true,
      specialCell:
        // eslint-disable-next-line react/display-name
        (request) => (
          <InspectionDateContainer
            requestId={request.id}
            claimId={request.claim_id}
            exposureId={request.exposure.id}
            inspectionDate={request.inspection_date}
            onUpdate={onUpdate}
            showOnly={showOnly}
          />
        ),
    },
    {
      id: 'damage_assessment',
      label: 'Damage Assessment',
      disableSort: true,
      // eslint-disable-next-line react/display-name
      specialCell: (request) =>
        request.inspection_date && (
          <DamageAssessmentContainer
            claimId={request.claim_id}
            exposure={request.exposure}
            onUpdate={onUpdate}
            viewOnly={showOnly}
          />
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'action',
      label: 'Action',
      disablePadding: true,
      disableSort: true,
      width: 25,
      specialCell: (request) => (
        <ActionComponent request={request} disabled={disabled} {...actionComponentAdditionalProps} />
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'view_details',
      disableSort: true,
      width: 15,
      specialCell: (request) => (
        <InlineIconButton icon={VisibilityIcon} onClick={() => onShowRequestDetails(request)} disabled={disabled} />
      ),
    },
  ];

  if (!isVendorAssigned) {
    daRequestsColumns.splice(
      daRequestsColumns.findIndex((column) => column.id === 'vendor'),
      1
    );
  }

  return daRequestsColumns;
}

export default FieldInspectionQueuesContainer;
