import React from 'react';
import { noop } from 'lodash';
import moment from 'moment/moment';

import { Text } from '~/components/core';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import { DashboardReportsIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';

export interface WorkbookCardProps {
  id: string;
  name: string;
  imageUrl?: string;
  owner?: string;
  updatedDate?: Date;
  numberOfViews?: number | string;
  onClick?: (p: { id: string }) => void;
  onEdit?: (p: { id: string }) => void;
  onDelete?: (p: { id: string }) => void;
  onShare?: (p: { id: string }) => void;
}

export const WorkbookCard: React.FC<WorkbookCardProps> = ({
  id,
  name,
  imageUrl = '',
  numberOfViews = '',
  owner = '',
  updatedDate,
  onClick = noop,
  onEdit,
  onDelete,
  onShare,
}) => {
  const isViewOnly = !onEdit && !onDelete && !onShare;
  const options = isViewOnly
    ? []
    : [
        {
          onClick: () => onEdit && onEdit({ id }),
          node: 'Edit',
          key: 'edit',
          disabled: !onEdit,
        },
        {
          onClick: () => onShare && onShare({ id }),
          node: 'Share To',
          key: 'share',
          disabled: !onShare,
        },
        {
          onClick: () => onDelete && onDelete({ id }),
          node: 'Delete',
          key: 'delete',
          disabled: !onDelete,
        },
      ];

  const formattedDate = updatedDate ? moment(updatedDate).format('ll') : '';
  return (
    <div
      className="flex w-[350px] cursor-pointer flex-col gap-20 rounded-xl bg-white p-24 shadow-xs
    duration-100 ease-in hover:shadow-lg lg:w-[350px] xl:w-[380px]  2xl:w-[400px]"
    >
      <div className="--HEADER-- m-0 flex w-full gap-10">
        <Text variant="small" className="truncate">
          {name}
        </Text>

        <span className="ml-auto">
          <ThreeDotsMenu options={options} disabled={!Object.keys(options || {}).length} />
        </span>
      </div>

      <div
        className="--IMAGE-- flex h-[210px] items-center justify-center overflow-hidden rounded-md border-1 border-solid border-slate-500 lg:h-[210px] xl:h-[230px] 2xl:h-[250px]"
        onClick={() => onClick({ id })}
      >
        <img src={imageUrl} alt={name} width="350px" />
      </div>

      <div className="--FOOTER-- flex h-20 w-full items-center gap-5">
        <Text variant="small" colorVariant="secondary" weight="regular" className="truncate">
          {owner}
        </Text>
        <div className="text-slate-800">&#x25CF;</div>
        <Text variant="small" colorVariant="secondary" weight="regular" className="w-[120px] shrink-0 ">
          {formattedDate}
        </Text>
        <div className="ml-auto flex w-1/4 shrink-0 justify-end gap-5">
          <DashboardReportsIcon iconColor={colors.slate[700]} width={20} height={20} />
          <Text variant="small" colorVariant="secondary" weight="regular">
            {numberOfViews}
          </Text>
          <Text variant="small" colorVariant="secondary" weight="regular">
            Views
          </Text>
        </div>
      </div>
    </div>
  );
};

export default WorkbookCard;
