import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import axios from 'axios';

import useOrganization from '~/components/OrganizationContext';
import { reportAxiosError } from '~/Utils';

import LabledValue from '../core/Molecules/LabledValue';

import { useCms } from './useCms';

const useCccAssigment = (moiId, claimIdDisplayWithExposureSuffix) => {
  const { isCccEnabled } = useOrganization();
  const { userOrganization } = useCms();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [cccTransactionId, setCccTransactionId] = useState('');

  useEffect(() => {
    const fetchCccAssignment = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`/api/v1/ccc/organizations/${userOrganization?.id}/assignments`, {
          params: { method_of_inspection_id: moiId },
        });
        if (data) {
          setCccTransactionId(data.ccc_transaction_id);
        }
      } catch (error) {
        setIsError(true);
        await reportAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isCccEnabled && moiId && !cccTransactionId) {
      return fetchCccAssignment();
    }
  }, [cccTransactionId, moiId, isCccEnabled, userOrganization?.id]);

  return {
    isCccEnabled,
    isLoadingCccAssignment: isLoading,
    isErrorCccAssigment: isError,
    isCccAssignment: !!cccTransactionId,
    cccTransactionDetails: (
      <div className="grid grid-cols-2 gap-15">
        <Tooltip title="Use this id to search the claim the CCC portal">
          <span>
            <LabledValue value={claimIdDisplayWithExposureSuffix} label="Exposure ID" />
          </span>
        </Tooltip>
        <LabledValue value={cccTransactionId} label="CCC Transaction ID" />
      </div>
    ),
  };
};

export default useCccAssigment;
