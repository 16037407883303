import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { getIn, useFormikContext } from 'formik';

import { FORMIK_W9_FIELD_IDS } from '~/components/Contacts/UpsertContact/Tabs/W9/constants';
import { getFullW9Path } from '~/components/Contacts/UpsertContact/Tabs/W9/utils';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import { REPORT_TYPES_1099 } from '~/Types';
import { capitalize } from '~/Utils';

const Form1099 = ({ disabled, showOnly }) => {
  const { values } = useFormikContext();
  const { show1099Fields } = usePaymentsConfiguration();

  if (!show1099Fields) {
    return null;
  }

  return (
    <>
      <TextFieldFormik
        disabled={disabled}
        showOnly={showOnly}
        id={getFullW9Path(FORMIK_W9_FIELD_IDS.IS_1099_REPORTABLE)}
        label="Is 1099 Reportable?"
        fullWidth
        select
      >
        <MenuItem value={true}>Reportable</MenuItem>
        <MenuItem value={false}>Not Reportable</MenuItem>
      </TextFieldFormik>
      {getIn(values, getFullW9Path(FORMIK_W9_FIELD_IDS.IS_1099_REPORTABLE)) ? (
        <TextFieldFormik
          disabled={disabled}
          showOnly={showOnly}
          id={getFullW9Path(FORMIK_W9_FIELD_IDS.REPORT_TYPE_1099)}
          label="Report Type"
          fullWidth
          select
        >
          {REPORT_TYPES_1099.map((reportType) => (
            <MenuItem key={reportType} value={reportType}>
              {capitalize(reportType)}
            </MenuItem>
          ))}
        </TextFieldFormik>
      ) : null}
    </>
  );
};

Form1099.propTypes = {
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
};

export default Form1099;
