import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import RadioButtonFormik from '../RadioButtonFormik';
import { ShowOnlyTextField, TextFieldFormik } from '../TextFieldFormik';

import DamageDialogContainer from './DamageDialogContainer';

import { useStyles } from '../../assets/styles';

function MitigationDetailsContainer(props) {
  const { values, setFieldValue, questionText, helperText, vendorsStatus } = props;
  const mitigationDamageData = getIn(values, 'mitigation');

  // we assume the text is of the form "Question? Other details"
  let questionTextStyled = questionText;
  const questionMarkIdx = questionText.indexOf('?');
  if (questionMarkIdx > -1) {
    questionTextStyled = (
      <p>
        <strong>{questionText.slice(0, questionMarkIdx + 1)}</strong>
        <br />
        {questionText.slice(questionMarkIdx + 1)}
      </p>
    );
  }

  return (
    <DamageDialogContainer
      damageId="mitigation"
      questionText={questionTextStyled}
      customDialogTitle="Immediate mitigation?"
      helperText={
        <>
          {helperText}
          {mitigationDamageData.is_damaged === 'yes' && vendorsStatus.mitigation && (
            <Typography display="block" variant="caption">
              {' '}
              {mitigationDamageData.damage_details.refer_vendor === 'yes'
                ? `${vendorsStatus.mitigation_vendor.full_name} will be referred`
                : 'Damages will be mitigated by insured'}
            </Typography>
          )}
        </>
      }
      values={values}
      setFieldValue={setFieldValue}
      customDialogProps={{
        isMitigationVendorExists: vendorsStatus.mitigation,
        mitigationVendor: vendorsStatus.mitigation_vendor,
      }}
      CustomDetailsDialog={MitigationDetailsDialog}
    />
  );
}

MitigationDetailsContainer.propTypes = {
  vendorsStatus: PropTypes.object.isRequired,
  questionText: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired, // from Formik
  setFieldValue: PropTypes.func.isRequired, // from Formik
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

function MitigationDetailsDialog(props) {
  const { dialogTitle, onSubmitDetails, damageDetails, open, onCancel, isMitigationVendorExists, mitigationVendor } =
    props;
  const classes = useStyles();
  const mitigationCommunicationText =
    'If temporary repairs are required, they should be started immediately.\n\nWe can not guarantee they will be covered, but insured should keep all receipts.\n\nIf insured cannot complete mitigation repairs by themselves, ';
  const mitigationTextNoVendor =
    'It is vital that insured take whatever actions are necessary to mitigate further damage.\nWhile we cannot commit to coverage at this point, insured should keep any receipts for costs incurred during damage mitigation';
  const mitigationInitialValues = { mitigation_description: '', refer_vendor: '' };

  return (
    <Formik
      initialValues={isMitigationVendorExists ? { ...mitigationInitialValues, ...damageDetails } : {}}
      validationSchema={
        isMitigationVendorExists
          ? Yup.object().shape({
              refer_vendor: Yup.string().required('Required'),
              mitigation_description: Yup.string().required('Required'),
            })
          : undefined
      }
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSubmitDetails(values);
        return formikProps.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title={dialogTitle}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            {isMitigationVendorExists ? (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ShowOnlyTextField
                      label=""
                      classes={classes}
                      showOnlyValueComponent={mitigationCommunicationText}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                      Refer our vendor &nbsp;<strong>{mitigationVendor.full_name}</strong>
                    </span>
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="refer_vendor" />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                      <RadioButtonFormik id="refer_vendor" label="No" optionValue="no" />
                      <RadioButtonFormik id="refer_vendor" label="Yes" optionValue="yes" />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="mitigation_description"
                      label="Mitigation Description"
                      rows="4"
                      className={classes.textField}
                      placeholder="What damage should be mitigated? What was done?"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ShowOnlyTextField
                      label=""
                      classes={classes}
                      showOnlyValueComponent={mitigationTextNoVendor}
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Ok
                  </Button>
                </div>
              </>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MitigationDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  damageDetails: PropTypes.object.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  isMitigationVendorExists: PropTypes.bool.isRequired,
  mitigationVendor: requiredIf(PropTypes.object, (props) => props.isMitigationVendorExists),
};

export default MitigationDetailsContainer;
