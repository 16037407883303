import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import CheckboxFormik from '~/components/CheckboxFormik';
import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import { Text } from '~/components/core';
import { useCms } from '~/components/hooks/useCms';
import { LEGAL_ACTION_PARTY_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import {
  ArrayFieldFormPropTypes,
  getArrayFieldKey,
} from '~/components/LegalActions/FormFragments/LegalActionsFormUtils';
import { getPartyFieldLabel } from '~/components/LegalActions/LegalActionsUtils';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';

export const PartyForm = ({ partyType, arrayFieldPrefix, index, hideCarrierInvolvedParty }) => {
  const { organizationContactRolesDict } = useOrganization();
  const { userOrganization } = useCms();
  const { values } = useFormikContext();

  const isCarrierPartyFieldId = getArrayFieldKey(LEGAL_ACTION_PARTY_FIELDS.isCarrierParty.key, arrayFieldPrefix, index);
  const isCarrierParty = getIn(values, isCarrierPartyFieldId);

  return (
    <>
      {!hideCarrierInvolvedParty ? (
        <CheckboxFormik id={isCarrierPartyFieldId} label={LEGAL_ACTION_PARTY_FIELDS.isCarrierParty.label} />
      ) : null}

      {/* This is hard coded because the contact component will compute the name of the field where the contact id is set */}
      {isCarrierParty ? (
        <Text>{userOrganization.name}</Text>
      ) : (
        <ContactTextFieldFormik
          id={getArrayFieldKey('contact', arrayFieldPrefix, index)}
          label={getPartyFieldLabel(partyType, LEGAL_ACTION_PARTY_FIELDS.contact.label)}
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          fixedSearchResults={!arrayFieldPrefix}
          fullWidth
          nullIfEmpty
        />
      )}
      <div className="flex w-full gap-30">
        <div className="flex w-1/3">
          {/* This is hard coded because the contact component will compute the name of the field where the contact id is set */}
          <ContactTextFieldFormik
            id={getArrayFieldKey('counsel_contact', arrayFieldPrefix, index)}
            acceptedRoles={['attorney', 'defense_counsel']}
            label={getPartyFieldLabel(partyType, LEGAL_ACTION_PARTY_FIELDS.counsel.label)}
            fixedSearchResults={!arrayFieldPrefix}
            fullWidth
            nullIfEmpty
          />
        </div>
        <div className="flex w-1/3">
          <TextFieldFormik
            fullWidth
            id={getArrayFieldKey(LEGAL_ACTION_PARTY_FIELDS.fileNumber.key, arrayFieldPrefix, index)}
            label={getPartyFieldLabel(partyType, LEGAL_ACTION_PARTY_FIELDS.fileNumber.label)}
          />
        </div>
        <div className="flex w-1/3">
          <DatePickerTextFieldFormik
            fullWidth
            containerClassName="w-full"
            id={getArrayFieldKey(LEGAL_ACTION_PARTY_FIELDS.dateOfRepresentation.key, arrayFieldPrefix, index)}
            label={LEGAL_ACTION_PARTY_FIELDS.dateOfRepresentation.label}
            nullIfEmpty
          />
        </div>
      </div>
    </>
  );
};

PartyForm.propTypes = {
  partyType: PropTypes.string.isRequired,
  hideCarrierInvolvedParty: PropTypes.bool,
  ...ArrayFieldFormPropTypes,
};
