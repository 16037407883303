import React from 'react';
import { Link } from '@material-ui/core';

import type { ContactFullModel } from '~/components/Contacts/types';
import Caption from '~/components/core/TextComponents/Caption';
import useOrganization from '~/components/OrganizationContext';
import { isoDateToUs } from '~/DateTimeUtils';

interface COIPropsType {
  contact: ContactFullModel;
}

const COI: React.FC<COIPropsType> = ({ contact }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();

  if (!organizationContactRolesDict[contact.role].is_coi_required) {
    return null;
  }

  const getDescription = () =>
    contact.coi_expiration ? `COI - Expires ${isoDateToUs(contact.coi_expiration)}` : 'COI';

  return (
    <div className="mt-10 rounded bg-slate-200 p-10">
      <Caption variant={Caption.VARIANTS.LABEL}>COI</Caption>
      <div>
        {contact.coi_contact_document_id ? (
          <Link
            className="font-medium text-teal-600 underline"
            href={`/api/v1/contacts/${contact.id}/coi`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getDescription()}
          </Link>
        ) : (
          <>-</>
        )}
      </div>
    </div>
  );
};

export default COI;
