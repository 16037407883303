/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import { FsIconButton } from '~/components/core';
import { CopyToClipboardIcon, NoteIcon, TranslateIcon } from '~/components/icons';
import { EmailIcon, ReminderIcon } from '~/components/icons/notifications';
import cn from '~/Utils/cn';

export interface MessageActionsProps {
  type: 'filled' | 'outlined';
  onCopy?: () => void;
  onCreateNotification?: () => void;
  onCreateNote?: () => void;
  onCreateEmail?: () => void;
  onTranslate?: () => void;
}

export const MessageActions: React.FC<MessageActionsProps> = ({
  type,
  onCopy,
  onCreateNotification,
  onCreateNote,
  onCreateEmail,
  onTranslate,
}) => {
  const actions = [
    { label: 'Copy', onClick: onCopy, icon: CopyToClipboardIcon },
    { label: 'Create Notification', onClick: onCreateNotification, icon: ReminderIcon },
    { label: 'Create Note', onClick: onCreateNote, icon: NoteIcon },
    { label: 'Create Email', onClick: onCreateEmail, icon: EmailIcon },
    { label: 'Translate', onClick: onTranslate, icon: TranslateIcon },
  ].filter(({ onClick }) => Boolean(onClick));

  return (
    <div className="flex gap-10">
      {actions.map(({ label, onClick, icon: Icon }) => (
        <FsIconButton
          key={label}
          onClick={onClick}
          className={cn({
            'bg-aiV2-fadedWhite': type === 'filled',
            'border-1 border-solid border-[#E6E7E8]': type === 'outlined',
          })}
          tooltipText={label}
        >
          <Icon />
        </FsIconButton>
      ))}
    </div>
  );
};
