import type { ReactElement } from 'react';
import React, { useEffect, useState } from 'react';
import MUIStep from '@mui/material/Step';
import MUIStepConnector from '@mui/material/StepConnector';
import MUIStepLabel from '@mui/material/StepLabel';
import MUIStepper from '@mui/material/Stepper';
import { useFormikContext } from 'formik';
import { isEmpty, noop } from 'lodash';

import CardDialog from '~/components/CardDialog';
import CancelButton from '~/components/core/Buttons/CancelButton';
import FsButton from '~/components/core/FsWrappers/FsButton/FsButton';
import Step from '~/components/core/Stepper/Step';
import { StepperContextProvider, useStepper } from '~/components/core/Stepper/StepperContext';
import type { StepperProps, StepperWith } from '~/components/core/Stepper/types';
import cn from '~/Utils/cn';

import type { WithConfirmProps } from '../../ConfirmModal';
import WithConfirm from '../../ConfirmModal';
import Text from '../TextComponents/Text';

import StepIconComponent from './StepIconComponent';
import StepperFinishScreen from './StepperFinishScreen';

const StepperWrapper: React.FC<StepperProps> = ({ children, ...rest }) => {
  return (
    <StepperContextProvider>
      <Stepper {...rest}>{children}</Stepper>
    </StepperContextProvider>
  );
};

const Stepper: React.FC<StepperProps> = ({
  stepsContainerClassName,
  headerClassName,
  contentClassName,
  footerClassName,
  footerComponent,
  nextBtnText,
  finishBtnText,
  backBtnText,
  actionBtnText,
  firstStepBackBtnText,
  firstStepNextBtnText,
  onActionBtnClick,
  onFirstStepBack,
  onStepChange,
  onComplete,
  cancelButtonsWithoutBorder,
  withConfirmOnFirstBack,
  withConfirmOnFirstBackProps,
  withConfirmOnComplete,
  withConfirmOnCompleteProps,
  children,
  sideComponent,
  isDialog,
  dialogProps,
}) => {
  const [finishScreen, setFinishScreen] = useState();
  const [showFinishScreen, setShowFinishScreen] = useState(false);
  const { isSubmitting } = useFormikContext();
  const context = useStepper();
  const { handleNext, handleBack, isLastStep, isFirstStep, steps, activeStep, setSteps } = context;

  useEffect(() => {
    setSteps(
      (React.Children.toArray(children) as ReactElement[])
        .filter(({ type }) => type === Step)
        .map(({ props }) => ({ ...props }))
    );

    setFinishScreen(
      (React.Children.toArray(children) as ReactElement[]).find(({ type }) => type === StepperFinishScreen)?.props
    );
  }, [children, setSteps]);

  const onHandleNext = async () => {
    const nextStep = await handleNext();
    if (isLastStep) {
      const isOnCompleteSuccess = await onComplete();
      if (isOnCompleteSuccess !== false) {
        onStepChange(steps[nextStep], nextStep);
        setShowFinishScreen(Boolean(finishScreen));
      }
    } else {
      onStepChange(steps[nextStep], nextStep);
    }
  };

  const onHandleBack = async (validate = true) => {
    const nextStep = await handleBack(validate);
    onStepChange(steps[nextStep], nextStep);
  };

  if (isEmpty(steps)) {
    return null;
  }

  const cancelButton = (
    <CancelButton
      content={isFirstStep ? firstStepBackBtnText : backBtnText}
      disabled={(isFirstStep && !onFirstStepBack) || steps[activeStep].disableBack || isSubmitting}
      onClick={isFirstStep && onFirstStepBack ? onFirstStepBack : onHandleBack}
      withoutBorder={!isFirstStep && !!cancelButtonsWithoutBorder}
    />
  );

  const nextButton = (
    <FsButton
      onClick={onHandleNext}
      color="primary"
      variant="contained"
      disabled={steps[activeStep].disableNext || isSubmitting}
    >
      {isLastStep ? finishBtnText : isFirstStep ? firstStepNextBtnText : nextBtnText}
    </FsButton>
  );

  const Footer = (
    <>
      {!showFinishScreen && (
        <footer className={cn('inline-flex w-full flex-initial justify-between p-24', footerClassName)}>
          {actionBtnText && !isFirstStep && (
            <CancelButton
              content={actionBtnText}
              onClick={onActionBtnClick}
              withoutBorder={cancelButtonsWithoutBorder}
              disabled={isSubmitting}
            />
          )}
          {(!actionBtnText || isFirstStep) && <div />}
          {footerComponent}
          <div>
            {withConfirmOnFirstBack && isFirstStep ? (
              <WithConfirm {...(withConfirmOnFirstBackProps as WithConfirmProps)}>{cancelButton}</WithConfirm>
            ) : (
              cancelButton
            )}
            {withConfirmOnComplete && isLastStep ? (
              <WithConfirm {...(withConfirmOnCompleteProps as WithConfirmProps)}>{nextButton}</WithConfirm>
            ) : (
              nextButton
            )}
          </div>
        </footer>
      )}
    </>
  );

  const Wrapper = isDialog ? CardDialog : React.Fragment;

  const wrapperProps = isDialog ? { ...dialogProps, footerActions: Footer } : {};

  return (
    <Wrapper {...wrapperProps}>
      <div className="flex h-full w-full flex-col">
        <div className="flex h-full w-full">
          <div className="flex h-full w-full flex-col justify-between">
            <MUIStepper
              activeStep={activeStep}
              className={cn('flex-initial pb-10', headerClassName)}
              connector={
                <MUIStepConnector
                  classes={{
                    line: 'border-0 border-t border-solid group-[.is-active]:border-teal-600 group-[.is-completed]:border-teal-600',
                    active: 'is-active group',
                    completed: 'is-completed group',
                  }}
                />
              }
            >
              {steps.map(({ label }, index) => (
                <MUIStep key={index}>
                  <MUIStepLabel
                    StepIconComponent={StepIconComponent}
                    classes={{
                      active: 'text-slate-900 font-bold',
                      completed: 'text-slate-900',
                      disabled: 'border-slate-600',
                    }}
                  >
                    <Text weight={Text.WEIGHTS.REGULAR}>{label}</Text>
                  </MUIStepLabel>
                </MUIStep>
              ))}
            </MUIStepper>
            <div className={stepsContainerClassName ? stepsContainerClassName : ''}>
              {!showFinishScreen && (
                <div className={cn('flex-1 overflow-auto', contentClassName)}>
                  {steps[activeStep] && steps[activeStep].children}
                </div>
              )}
              {showFinishScreen && (
                <div className={cn('flex-1 overflow-auto', contentClassName)}>
                  <StepperFinishScreen
                    {...(finishScreen as unknown as React.ComponentProps<typeof StepperFinishScreen>)}
                  />
                </div>
              )}
            </div>
          </div>
          {sideComponent}
        </div>
        {!isDialog && Footer}
      </div>
    </Wrapper>
  );
};

const defaultStepperProps: Partial<StepperProps> = {
  nextBtnText: 'Next',
  finishBtnText: 'Finish',
  backBtnText: 'Back',
  firstStepBackBtnText: 'Back',
  firstStepNextBtnText: 'Next',
  onComplete: noop,
  onActionBtnClick: noop,
  onStepChange: noop,
};

Stepper.defaultProps = defaultStepperProps;

(StepperWrapper as StepperWith).Step = Step;
(StepperWrapper as StepperWith).FinishScreen = StepperFinishScreen;

export default StepperWrapper;
