import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import { isMarshmallowPolicy } from '~/Utils';

import { CustomField } from '../../../IncidentConfiguration/ConfiguredFields';
import { CustomFieldsContextProvider } from '../../../IncidentConfiguration/CustomFieldsContext';
import { ShowOnlyTextField } from '../../../TextFieldFormik';

const PolicyCustomFields = (props) => {
  const { customFields, configuredFieldsValues, configuredFieldsConfiguration, policy } = props;

  const classes = useStyles();
  return (
    <div>
      <CollapsibleWrapper actionCard noBorder title="Additional Details">
        <div className="flex flex-wrap pt-0">
          {customFields
            .map((f) => Object.values(f)[0])
            .map((field, i) => (
              <div className="mx-5" key={i}>
                <ShowOnlyTextField
                  classes={classes}
                  label={field.displayName ?? field.display_name}
                  showOnlyValueComponent={
                    <OverflowTextWithToolTip maxWidth={130}>{field.value}</OverflowTextWithToolTip>
                  }
                />
              </div>
            ))}
          <CustomFieldsContextProvider customFieldsConfigurations={configuredFieldsConfiguration}>
            {configuredFieldsConfiguration.map((field) => (
              <div className="mx-5" key={field.id}>
                <CustomField
                  field={field}
                  readOnly
                  disabled
                  value={configuredFieldsValues[field.id]}
                  inline={false}
                  idPrefix=""
                  maxWidth={130}
                  overFlowWithTooltip={true}
                />
              </div>
            ))}
          </CustomFieldsContextProvider>
          {policy.underwriting_company && (
            <div className="mx-5">
              <ShowOnlyTextField
                classes={classes}
                label="Underwriting Company"
                showOnlyValueComponent={policy.underwriting_company}
              />
            </div>
          )}
          {isMarshmallowPolicy(policy) && (
            <div className="mx-5">
              <ShowOnlyTextField classes={classes} label="Package" showOnlyValueComponent={policy.policy_type} />
            </div>
          )}
        </div>
      </CollapsibleWrapper>
    </div>
  );
};

PolicyCustomFields.propTypes = {
  customFields: PropTypes.array.isRequired,
  configuredFieldsValues: PropTypes.object.isRequired,
  configuredFieldsConfiguration: PropTypes.array.isRequired,
  policy: PropTypes.object.isRequired,
};

export default PolicyCustomFields;
