import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { ConfirmModal } from '../ConfirmModal';
import { LoadingSwitch } from '../core';
import LoadingDialog from '../LoadingDialog';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

function CloseClaimDialog({ title, claim, onReasonChosen, onClose }) {
  const classes = useStyles();
  const { isLoading, isError, data: closingReasons } = useDataFetcher(`/api/v1/claims/${claim.id}/closing_reasons`);
  const {
    isLoading: isCloseRequirementsLoading,
    isError: isCloseRequirementsError,
    data: mandatoryUnansweredFields,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/close_requirements`);
  const reasonDict = Object.fromEntries(closingReasons?.map((x) => [x.closing_reason_key, x]) || []);

  if (isCloseRequirementsLoading || isCloseRequirementsError) {
    return <LoadingDialog onClose={onClose} isError={isCloseRequirementsError} track="" />;
  }

  if (mandatoryUnansweredFields?.length > 0) {
    const mandatoryUnansweredFieldsDisplayNames = mandatoryUnansweredFields?.map((field) => (
      <>
        <br />
        {field.desc}
      </>
    ));
    return (
      <ConfirmModal
        isOpen={true}
        title="Claim Cannot be Closed"
        contentText={
          <>
            The following details must be filled to close the claim:
            {mandatoryUnansweredFieldsDisplayNames}
          </>
        }
        onClose={onClose}
        primaryButtonName="OK"
        onPrimaryBtnClick={onClose}
        removeSecondaryButton
      />
    );
  }

  return (
    <Formik
      initialValues={{ claim_reason: '', claim_extra: '' }}
      validationSchema={Yup.object().shape({
        claim_reason: Yup.string().required('Required'),
        claim_extra: Yup.string().when('claim_reason', {
          is: (val) => val && reasonDict?.[val].is_note_required,
          then: Yup.string().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={onReasonChosen}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit } = formikProps;
        const isNoteEnabled = values.claim_reason && reasonDict?.[values.claim_reason]?.is_note_enabled;

        return (
          <CardDialog
            isDialog={true}
            open={true}
            title={title}
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <LoadingSwitch isLoading={isLoading} isError={isError}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextFieldFormik id="claim_reason" select label="Reason" className={classes.textField} fullWidth>
                    {Object.keys(reasonDict || {})
                      .sort((a, b) =>
                        (reasonDict?.[a]?.display_name || '').localeCompare(reasonDict?.[b]?.display_name || '')
                      )
                      .map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {reasonDict?.[reason]?.display_name}
                        </MenuItem>
                      ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="claim_extra"
                    label="Note"
                    disabled={!isNoteEnabled}
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid />
              <div className={classes.buttonsContainer}>
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                  Close Claim
                </Button>
              </div>
            </LoadingSwitch>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

CloseClaimDialog.propTypes = {
  title: PropTypes.string,
  claim: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onReasonChosen: PropTypes.func.isRequired,
};

export default CloseClaimDialog;
