import React from 'react';
import axios from 'axios';

import { useInterval } from '~/components/AiChat/components/Message/useInterval';
import { useClaim } from '~/components/ClaimContainer';
import { reportAxiosError } from '~/Utils';

interface IUseDocumentChat {
  documentBaseRoute: string;
  isReadyForChat: boolean;
}

export const useDocumentChat = ({ documentId }: { documentId: number }): IUseDocumentChat => {
  const { claim } = useClaim();
  const baseRoute = `/api/v1/claims/${claim.id}`;
  const documentBaseRoute = `/documents/${documentId}/chat`;
  const readyRoute = `${baseRoute}${documentBaseRoute}/ready`;

  const isReady = React.useRef<boolean>(false);

  const [isReadyForChat, setIsReadyForChat] = React.useState<boolean>(false);
  const [wasProcessingRequested, setWasProcessingRequested] = React.useState<boolean>(false);

  const requestDocumentProcessing = async () => {
    try {
      setWasProcessingRequested(true);
      await axios.post(readyRoute);
    } catch (error) {
      await reportAxiosError(error);
      stopPolling();
    }
  };

  const checkIsDocumentReady = async () => {
    try {
      if (isReady.current) {
        stopPolling();
        return;
      }
      const { data } = await axios.get(readyRoute);
      isReady.current = data.is_ready;
      if (data.is_ready) {
        setIsReadyForChat(data.is_ready);
        stopPolling();
      } else if (!wasProcessingRequested) {
        await requestDocumentProcessing();
      }
    } catch (error) {
      stopPolling();
      await reportAxiosError(error);
    }
  };

  const { start: startPolling, stop: stopPolling } = useInterval(checkIsDocumentReady, 5000);

  React.useEffect(() => {
    if (!isReady.current) {
      startPolling();
    }
    return stopPolling;
  }, [startPolling, stopPolling]);

  return {
    documentBaseRoute,
    isReadyForChat,
  };
};
