import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from './CardDialog';
import TextFieldFormik from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function IncidentTypeUpdateDialog({ incidentTypesDict, incidentType, incidentSubType, onUpdate, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ incident_type: incidentType, incident_sub_type: incidentSubType }}
      validationSchema={Yup.object().shape({
        incident_type: Yup.string().required('Required'),
        incident_sub_type: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        if (values.incident_type === incidentType && values.incident_sub_type === incidentSubType) {
          await onClose();
          return;
        }

        try {
          await onUpdate(values);
          await onClose();
        } finally {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const selectIncidentType = values['incident_type'] || '';

        let incidentsTypeKeys = Object.keys(incidentTypesDict).filter(
          (option) => incidentTypesDict[option].active !== false || option === incidentType
        );
        let incidentSubTypeDictForIncidentType =
          selectIncidentType === '' ? {} : incidentTypesDict[selectIncidentType].sub_types;
        let incidentSubTypeKeysForIncidentType = Object.keys(incidentSubTypeDictForIncidentType).filter(
          (option) => incidentSubTypeDictForIncidentType[option].active !== false || option === incidentSubType
        );

        return (
          <CardDialog
            isDialog={true}
            open
            title="Edit Incident Type"
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="incident_type"
                  select
                  label="Incident type"
                  className={classes.textField}
                  disabled={isSubmitting}
                  onChange={(e) => {
                    setFieldValue('incident_type', e.target.value);
                    setFieldValue('incident_sub_type', '');
                  }}
                  fullWidth
                >
                  {incidentsTypeKeys.map((option) => (
                    <MenuItem key={option} value={option} disabled={incidentTypesDict[option].active === false}>
                      {incidentTypesDict[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="incident_sub_type"
                  select
                  label="Incident sub-type"
                  disabled={isSubmitting}
                  className={classes.textField}
                  fullWidth
                >
                  {incidentSubTypeKeysForIncidentType.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      disabled={incidentSubTypeDictForIncidentType[option].active === false}
                    >
                      {incidentSubTypeDictForIncidentType[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

IncidentTypeUpdateDialog.propTypes = {
  open: PropTypes.bool,
  incidentType: PropTypes.string.isRequired,
  incidentSubType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  incidentTypesDict: PropTypes.object.isRequired,
};

export default IncidentTypeUpdateDialog;
