import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { getIn, useFormikContext } from 'formik';

import Chip from '~/components/core/Atomic/Chip/Chip';
import cn from '~/Utils/cn';

import { useArrayText } from '../../hooks/useArrayText';
import OverflowTextWithToolTip from '../../OverflowTextWithToolTip';
import { MultiSelectIdLabelTextFieldFormik } from '../../TextFieldFormik';
import FsTooltip from '../FsWrappers/FsTooltip/FsTooltip';
import { Text } from '../TextComponents';

import { useStyles } from '../../../assets/styles';
import styles from './ArrayMultiselectFieldFormik.module.scss';

const CHIP_ADDITIONAL_WIDTH = 8 + 27; // padding + chip

const ArrayMultiselectFieldWithChipsFormik = ({
  id,
  label,
  options,
  showValues = false,
  showOnly = false,
  disabled = false,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const selectedValues = getIn(values, id);

  const getSelectedValueLabel = useCallback(
    (value) => {
      if (showValues) {
        return value;
      }
      const option = options?.find((option) => option?.value === value);
      return option?.label ?? '';
    },
    [options, showValues]
  );

  const {
    containerRef: chipsContainerRef,
    displayedItems,
    remainingCount,
  } = useArrayText({
    array: selectedValues,
    renderLabel: getSelectedValueLabel,
    labelAdditionalWidth: CHIP_ADDITIONAL_WIDTH,
    showOnly,
  });

  const disableDelete = showOnly || disabled;

  const displayedOptionsWithLabels = displayedItems.map((item) => {
    const option = options.find((option) => option.value === item);
    const label = getSelectedValueLabel(item);
    return { ...option, label };
  });

  const handleRemoveChip = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    setFieldValue(
      id,
      selectedValues.filter((item) => item !== value)
    );
  };

  const tooltipText = options
    ?.filter((item) => selectedValues?.includes(item.value) && !displayedItems?.includes(item.value))
    ?.map((item) => item.label)
    ?.join(', ');

  return (
    <MultiSelectIdLabelTextFieldFormik
      id={id}
      select
      label={label}
      className={classes.textField}
      fullWidth
      showOnly={showOnly}
      options={options}
      disabled={disabled}
      renderValue={() => (
        <span className={cn(classes.chips, styles.valueContainer)} ref={chipsContainerRef}>
          {displayedOptionsWithLabels.map((item) => (
            <span key={item.id} className={styles.chipContainer}>
              <Chip
                data-testid="chip"
                size="small"
                label={<OverflowTextWithToolTip maxWidth="220px">{item.label}</OverflowTextWithToolTip>}
                deleteIcon={
                  disableDelete ? undefined : <CancelIcon onMouseDown={(e) => handleRemoveChip(e, item.value)} />
                }
                onDelete={disableDelete ? undefined : (e) => handleRemoveChip(e, item.value)}
              />
            </span>
          ))}
          <FsTooltip title={tooltipText}>
            {remainingCount ? (
              <Text className={styles.plusText} weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                +{remainingCount}
              </Text>
            ) : (
              <></>
            )}
          </FsTooltip>
        </span>
      )}
    />
  );
};

ArrayMultiselectFieldWithChipsFormik.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  showValues: PropTypes.bool,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ArrayMultiselectFieldWithChipsFormik;
