import React from 'react';
import { useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { get, omit, set } from 'lodash';
import moment from 'moment-timezone';
import * as Yup from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import type { InitialValuesType, Tab } from '~/components/Contacts/UpsertContact/types';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { PERMISSION_ACTIONS } from '~/components/core/Permissions/PermissionUtils';
import { governmentIdValidationSchema } from '~/components/GovernmentId';
import CONTACT_IDENTITY_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_IDENTITY_CHANGE_REASONS';
import MEDICAL_BILL_PROVIDER_PROFESSIONS from '~/server_shared/generated-types/MEDICAL_BILL_PROVIDER_PROFESSIONS';
import { isLocaleRegionIsUs } from '~/Utils';

import ChangeReason from '../../ChangeReason';
import { CONTACT_EXTRA_KEY, DETAILS_TAB_KEY, FORMIK_DETAILS_FIELD_IDS, SEX_OPTIONS } from '../../constants';
import { getFullDetailsTabFieldPath, getInitializedValues, getReasonValidationSchema } from '../../utils';
import TabWrapper from '../TabWrapper';

import Attorney from './Attorney';
import Exposures from './Exposures';
import Identity from './Identity';
import MedicalBillProvider from './MedicalBillProvider';
import MoiExpertise from './MoiExpertise';
import Note from './Note';
import RoleSelect from './RoleSelect';

const DetailsTab: React.FC = () => {
  const { isSubmitting } = useFormikContext();
  const { isCreation } = useContact();

  const sharedProps = {
    disabled: isSubmitting,
  };

  return (
    <TabWrapper>
      <div className="mb-30 grid grid-cols-1 gap-20">
        <RoleSelect {...sharedProps} />
        <Identity {...sharedProps} />
        <MoiExpertise {...sharedProps} />
        <MedicalBillProvider {...sharedProps} />
        <Attorney {...sharedProps} />
        <Exposures {...sharedProps} />
        <Note {...sharedProps} />
        {!isCreation ? (
          <ChangeReason
            reasonFieldId={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.REASON)}
            explanationFieldId={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.EXPLANATION)}
            reasonsDict={CONTACT_IDENTITY_CHANGE_REASONS}
            {...sharedProps}
          />
        ) : null}
      </div>
    </TabWrapper>
  );
};

const useDetailsTab = (): Tab => {
  // No Formik Context
  const { claim } = useClaim();
  const { originalContact, isCreation, isCompany } = useContact();

  const getInitialValues = (contact?: ContactFullModel, initialValues?: InitialValuesType) => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.IS_COMPANY, contact?.is_company, false);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.ROLE, initialValues?.role ?? contact?.role);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.ROLE_OTHER_DESC, contact?.role_other_desc);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER, contact?.is_moi_service_provider, false);
    setInitialValue(
      FORMIK_DETAILS_FIELD_IDS.MOI_EXPERTISES,
      contact?.moi_expertises ? [...contact.moi_expertises] : []
    );

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.PROFESSION, contact?.contact_extra?.profession);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_NUMBER, contact?.attorney_bar_number);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_STATE, contact?.attorney_bar_state);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.EXPOSURE_IDS, contact?.exposure_ids ? [...contact.exposure_ids] : []);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.NOTE, contact?.note);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.COMPANY_NAME, initialValues?.company_name ?? contact?.company_name);

    setInitialValue(FORMIK_DETAILS_FIELD_IDS.FIRST_NAME, initialValues?.first_name ?? contact?.first_name);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.MIDDLE_NAME, contact?.middle_name);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.LAST_NAME, initialValues?.last_name ?? contact?.last_name);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.DATE_OF_BIRTH, contact?.date_of_birth);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.GOVERNMENT_ID, contact?.government_id);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.SEX, contact?.sex);
    setInitialValue(FORMIK_DETAILS_FIELD_IDS.IS_DECEASED, contact?.is_deceased, false);

    if (!isCreation) {
      setInitialValue(FORMIK_DETAILS_FIELD_IDS.REASON, '');
      setInitialValue(FORMIK_DETAILS_FIELD_IDS.EXPLANATION, '');
    }

    return values;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation?: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    addValidation(FORMIK_DETAILS_FIELD_IDS.ROLE, Yup.string().required('Required'));
    addValidation(
      FORMIK_DETAILS_FIELD_IDS.ROLE_OTHER_DESC,
      Yup.string().when('role', { is: 'other', then: Yup.string(), otherwise: Yup.string() })
    );

    addValidation(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER, Yup.bool());
    addValidation(
      FORMIK_DETAILS_FIELD_IDS.MOI_EXPERTISES,
      Yup.array().when(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER, {
        is: true,
        then: Yup.array().min(1, 'At least one expertise should be selected'),
        otherwise: Yup.array(),
      })
    );

    const contactExtraValidations = {
      [FORMIK_DETAILS_FIELD_IDS.PROFESSION]: Yup.string().oneOf(Object.keys(MEDICAL_BILL_PROVIDER_PROFESSIONS)),
    };

    addValidation(CONTACT_EXTRA_KEY, Yup.object().shape(contactExtraValidations));

    addValidation(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_NUMBER, Yup.string());
    addValidation(
      FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_STATE,
      Yup.string().when(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_NUMBER, {
        is: (attorney_bar_number: string) => attorney_bar_number && isLocaleRegionIsUs(),
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      })
    );

    addValidation(FORMIK_DETAILS_FIELD_IDS.EXPOSURE_IDS, claim ? Yup.array().min(1, 'Required') : Yup.array());
    addValidation(FORMIK_DETAILS_FIELD_IDS.NOTE, Yup.string());

    addValidation(
      FORMIK_DETAILS_FIELD_IDS.COMPANY_NAME,
      isCompany ? Yup.string().trim().required('Required') : undefined
    );

    addValidation(
      FORMIK_DETAILS_FIELD_IDS.FIRST_NAME,
      !isCompany ? Yup.string().trim().required('Required') : undefined
    );
    addValidation(FORMIK_DETAILS_FIELD_IDS.MIDDLE_NAME, !isCompany ? Yup.string().trim() : undefined);
    addValidation(
      FORMIK_DETAILS_FIELD_IDS.LAST_NAME,
      !isCompany ? Yup.string().trim().required('Required') : undefined
    );
    addValidation(
      FORMIK_DETAILS_FIELD_IDS.DATE_OF_BIRTH,
      !isCompany ? Yup.date().max(new Date(), 'Must be in the past') : undefined
    );
    addValidation(FORMIK_DETAILS_FIELD_IDS.GOVERNMENT_ID, !isCompany ? governmentIdValidationSchema : undefined);
    addValidation(FORMIK_DETAILS_FIELD_IDS.SEX, !isCompany ? Yup.string().oneOf(SEX_OPTIONS) : undefined);
    addValidation(FORMIK_DETAILS_FIELD_IDS.IS_DECEASED, !isCompany ? Yup.boolean() : undefined);

    if (isCreation) {
      addValidation(FORMIK_DETAILS_FIELD_IDS.IS_COMPANY, Yup.boolean());
    } else {
      addValidation(
        FORMIK_DETAILS_FIELD_IDS.REASON,
        Yup.string().test(
          'identity-reason',
          'Required',
          getReasonValidationSchema(originalContact, getValuesToCompare, getInitialValues)
        )
      );
      addValidation(
        FORMIK_DETAILS_FIELD_IDS.EXPLANATION,
        Yup.string().when(FORMIK_DETAILS_FIELD_IDS.REASON, {
          is: 'other',
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        })
      );
    }

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    const omittedValues = omit(values, [FORMIK_DETAILS_FIELD_IDS.REASON, FORMIK_DETAILS_FIELD_IDS.EXPLANATION]);
    const valuesToCompare = getInitializedValues(omittedValues);

    // handle different date formats
    const date_of_birth = get(valuesToCompare, FORMIK_DETAILS_FIELD_IDS.DATE_OF_BIRTH);
    if (date_of_birth) {
      set(valuesToCompare, FORMIK_DETAILS_FIELD_IDS.DATE_OF_BIRTH, moment(date_of_birth).format('YYYY-MM-DD'));
    }

    // sometimes undefined in context due to nesting
    const profession = get(valuesToCompare, FORMIK_DETAILS_FIELD_IDS.PROFESSION);
    if (!profession) {
      set(valuesToCompare, FORMIK_DETAILS_FIELD_IDS.PROFESSION, '');
    }

    return valuesToCompare;
  };

  const fieldsPermissionActions = {
    [getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.DATE_OF_BIRTH)]: PERMISSION_ACTIONS.CONTACT_PII,
    [getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.GOVERNMENT_ID)]: PERMISSION_ACTIONS.CONTACT_PII,
  };

  return {
    label: 'Details',
    tabKey: DETAILS_TAB_KEY,
    tabComponent: DetailsTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    fieldsPermissionActions,
  };
};

export default useDetailsTab;
