import React from 'react';
import axios from 'axios';

import type {
  AiChatApiResponse,
  AiChatExchangeApiResponse,
  AiChatExchangesAndReferencesApiResponse,
  UpdateExchangeUserFeedbackCallback,
} from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

export interface UseAiChatReturn {
  chat: AiChatApiResponse | undefined;
  reloadChat: () => Promise<AiChatApiResponse>;
  isChatLoading: boolean;
  isChatError: boolean;
  getChatResponse: (userInput: string) => Promise<void>;
  updateExchangeUserFeedback: UpdateExchangeUserFeedbackCallback;
  restartChat: () => Promise<void>;
  regenerateResponse: (exchangeId: number) => Promise<void>;
  isFetchingResponse: boolean;
  exchanges: AiChatExchangeApiResponse[];
}

export const useChat = ({
  routeSuffix,
  shouldStart = true,
}: {
  routeSuffix: string;
  shouldStart?: boolean;
}): UseAiChatReturn => {
  const { claim } = useClaim();

  const baseRoute = `/api/v1/claims/${claim.id}${routeSuffix}`;

  const { data, reloadData, isLoading, isError } = useDataFetcher(baseRoute, {}, shouldStart);
  const [isFetchingResponse, setIsFetchingResponse] = React.useState(false);
  const [exchanges, setExchanges] = React.useState<AiChatExchangeApiResponse[]>([]);
  const [isRestarting, setIsRestarting] = React.useState(false);

  const handleReloadData = async () => {
    const chatResponse: AiChatApiResponse = await reloadData();
    setExchanges(chatResponse.exchanges);
    return chatResponse;
  };

  const getChatResponse = async (userInput: string) => {
    setIsFetchingResponse(true);
    try {
      const { data } = await axios.patch<AiChatExchangesAndReferencesApiResponse>(baseRoute, {
        user_input: userInput,
      });
      const { exchanges: newExchanges } = data;
      setExchanges(newExchanges);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    } finally {
      setIsFetchingResponse(false);
    }
  };

  const updateExchangeUserFeedback = async (exchangeId: number, feedbackScore: number, feedbackText: string) => {
    try {
      await axios.patch(`${baseRoute}/exchanges/${exchangeId}`, {
        user_feedback_score: feedbackScore,
        user_feedback_text: feedbackText,
      });
      await handleReloadData();
      return true;
    } catch (error) {
      await reportAxiosError(error);
      return false;
    }
  };

  const restartChat = async () => {
    try {
      setIsRestarting(true);
      await axios.delete(baseRoute);
      await handleReloadData();
      setIsRestarting(false);
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const regenerateResponse = async (exchangeId: number) => {
    try {
      setIsFetchingResponse(true);
      const { data } = await axios.post(`${baseRoute}/exchanges/${exchangeId}/regenerate`);
      const { exchanges: newExchanges } = data;
      setExchanges(newExchanges);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    } finally {
      setIsFetchingResponse(false);
    }
  };

  React.useEffect(() => {
    if (data && !exchanges?.length) {
      setExchanges(data.exchanges);
    }
  }, [data, exchanges]);

  return {
    chat: data as AiChatApiResponse | undefined,
    reloadChat: handleReloadData as () => Promise<AiChatApiResponse>,
    isChatLoading: shouldStart ? isLoading || isRestarting : true,
    isChatError: isError as boolean,
    getChatResponse,
    isFetchingResponse,
    exchanges,
    updateExchangeUserFeedback,
    restartChat,
    regenerateResponse,
  };
};

export const ChatContext = React.createContext<UseAiChatReturn>({
  chat: undefined,
  reloadChat: () => Promise.resolve({} as AiChatApiResponse),
  isChatLoading: true,
  isChatError: false,
  getChatResponse: () => Promise.resolve(),
  isFetchingResponse: false,
  exchanges: [],
  updateExchangeUserFeedback: () => Promise.resolve(false),
  restartChat: () => Promise.resolve(),
  regenerateResponse: () => Promise.resolve(),
});

export const ChatProvider: React.FC<{ routeSuffix: string; shouldStart?: boolean }> = ({
  routeSuffix,
  shouldStart = true,
  children,
}) => {
  const value = useChat({ routeSuffix, shouldStart });
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = (): UseAiChatReturn => {
  return React.useContext(ChatContext);
};
