import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

const ManipulatePdfViewer = ({ claim, document, onExtractPhotosClick, onCreateSubDocumentClick, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open fullScreen>
      <Grid container style={{ margin: '8px' }} justify="flex-end" alignItems="flex-start">
        <Grid item className={classes.leftButtonDialog}>
          <Grid container style={{ height: '100%' }} direction="column" alignItems="flex-end" justify="center">
            <Grid item>
              <Button color="primary" variant="contained" size="small" onClick={onExtractPhotosClick}>
                <span>Extract photos</span>
              </Button>
            </Grid>
            <Grid>
              <Typography variant="caption" style={{ color: '#e65100' }}>
                Not supported for scanned documents
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Button
            className={classes.leftButtonDialog}
            color="primary"
            variant="contained"
            size="small"
            onClick={onCreateSubDocumentClick}
          >
            <span>Create sub document</span>
          </Button>
        </Grid>

        <Grid item>
          <IconButton className={classes.leftButtonDialog} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <object
        style={{ height: '100%', width: '100%', zIndex: '1' }}
        alt=""
        data={`/api/v1/claims/${claim.id}/documents/${document.id}`}
        type="application/pdf"
      />
    </Dialog>
  );
};

ManipulatePdfViewer.propTypes = {
  claim: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onExtractPhotosClick: PropTypes.func.isRequired,
  onCreateSubDocumentClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManipulatePdfViewer;
