import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import RadioWithButtonWrapperFormik from '~/components/core/Formik/RadioWithButtonWrapperFormik';
import type {
  MoiAssigneeMethodStandardParams,
  MoiFormikValues,
  MoiStepProps,
} from '~/components/exposures/moi/MoiStepper/types';
import cn from '~/Utils/cn';

import AssigneeSelectField from './AssigneeSelectField';

export interface SelectAssigneeStepProps extends MoiStepProps, MoiAssigneeMethodStandardParams {}

const SelectAssigneeStep: React.FC<SelectAssigneeStepProps> = ({
  setSelectedContact,
  exposure,
  default_select,
  disable_select,
  alert_content,
  setAlertMessage,
  alertMessage,
  moiMethodsReturn,
  ...rest
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<MoiFormikValues>();
  const classes = useStyles();

  const { isMoiMethodOnlyForServiceProviders, moiMethodsById } = moiMethodsReturn;

  useEffect(() => {
    if (!values['assignee_type']) {
      setFieldValue('assignee_type', default_select);
    }
  }, [default_select, setFieldValue, values]);

  useEffect(() => {
    if (alert_content && alertMessage?.message !== alert_content) {
      setAlertMessage({
        message: alert_content,
        type: 'warning',
      });
    }
  }, [alertMessage?.message, alert_content, setAlertMessage]);

  return (
    <div
      className={cn('grid grid-cols-12 gap-30', {
        ['text-slate-900']: values['moi_method_id'] || values['method'],
        ['text-slate-700']: !(values['moi_method_id'] || values['method']),
      })}
    >
      <div className="col-span-3">
        <RadioWithButtonWrapperFormik
          disabled={
            disable_select ||
            !values['moi_method_id'] ||
            isMoiMethodOnlyForServiceProviders(moiMethodsById[values['moi_method_id']])
          }
          id="assignee_type"
          className={classes.radioOrCheckboxWithIconOverride}
          value="internal_user"
          text="Internal User"
          onClick={() => {
            setSelectedContact(undefined);
            setFieldValue('contact_id', undefined);
            setFieldTouched('contact_id', false);
          }}
        />
        <ErrorHelperTextFormik className="mt-0" id="assignee_type" />
      </div>
      <div className="col-span-3">
        <RadioWithButtonWrapperFormik
          disabled={disable_select || !values['moi_method_id']}
          id="assignee_type"
          className={classes.radioOrCheckboxWithIconOverride}
          value="service_provider"
          text="Service Provider"
          onClick={() => {
            setFieldValue('user_id', undefined);
            setFieldTouched('user_id', false);
          }}
        />
        <ErrorHelperTextFormik className="mt-0" id="assignee_type" />
      </div>
      <AssigneeSelectField
        setSelectedContact={setSelectedContact}
        exposure={exposure}
        setAlertMessage={setAlertMessage}
        alertMessage={alertMessage}
        moiMethodsReturn={moiMethodsReturn}
        {...rest}
      />
    </div>
  );
};

export default SelectAssigneeStep;
