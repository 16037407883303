import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../Utils';

import useOrganization from './OrganizationContext';
import TextFieldFormik from './TextFieldFormik';

const ReopenClaimDialog = ({ classes, claim, open, onSubmit, onCancel }) => {
  const { reopenClaimOptions } = useOrganization();

  const handleReopenClaim = async (values) => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/reopen`, { note: values.note });
      return onSubmit();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const spacing = 1;
  return (
    <Formik
      initialValues={{ note: '' }}
      validationSchema={Yup.object().shape({
        note: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        handleReopenClaim(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleReset, handleSubmit } = formikProps;

        return (
          <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Reopen Claim</DialogTitle>
            <DialogContent>
              <Grid container spacing={spacing}>
                <Grid item xs={12}>
                  <Typography display="block" variant="body1">
                    Are you sure you want to reopen the claim?
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {reopenClaimOptions.is_reason_free_text ? (
                    <TextFieldFormik id="note" label="Reason" multiline fullWidth className={classes.textField} />
                  ) : (
                    <TextFieldFormik id="note" label="Reason" className={classes.textField} select fullWidth>
                      {Object.keys(reopenClaimOptions.reason_options_dict[claim.lob]).map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {reopenClaimOptions.reason_options_dict[claim.lob][reason]['desc']}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <CancelButton
                disabled={isSubmitting}
                onClick={() => {
                  handleReset();
                  onCancel();
                }}
                autoFocus
              />
              <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

ReopenClaimDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ReopenClaimDialog;
