import type { ReactElement } from 'react';

import type { AiChatExchangeApiResponse, UpdateExchangeUserFeedbackCallback } from '~/components/AiChat/types';

export interface BaseMessageProps {
  text: string;
  avatar: ReactElement;
  sender: string;
  isLoading: boolean;
  exchangeId?: number;
  onUserFeedback?: UpdateExchangeUserFeedbackCallback;
  onRegenerate?: () => void;
  withActions?: boolean;
  withFeedback?: boolean;
  withTypeEffect?: boolean;
  onType?: () => void;
  onTypeEnd?: () => void;
  currentUserFeedback?: number;
  messageContainer: React.FC<{ className: string }>;
}

export interface MessageProps {
  exchange: AiChatExchangeApiResponse;
}

export const AI_NAME = 'Clive';

export type OnUserFeedbackCallback = (exchangeId: number, feedbackScore: number) => void;
