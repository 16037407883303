import React from 'react';

import { CardBase } from '../CardBase';
import type { CardBaseExternalProps, CardType, CollapsibleCardProps } from '../types';
import { CardTypeToStyleAttributesMap } from '../types';

export interface SecondaryCardProps extends CardBaseExternalProps, CollapsibleCardProps {
  type: CardType;
}

export const SecondaryCard: React.FC<SecondaryCardProps> = ({
  type,
  title,
  collapsible,
  isOpen,
  action,
  openByDefault,
  onCollapseClick,
  children,
}) => (
  <CardBase
    paddingX={20}
    paddingY={15}
    contentGap={15}
    {...CardTypeToStyleAttributesMap[type]}
    titleProps={{
      variant: 'SM',
      weight: 'SEMI_BOLD',
      colorVariant: 'PRIMARY',
    }}
    title={title}
    collapsible={collapsible}
    isOpen={isOpen}
    action={action}
    openByDefault={openByDefault}
    onCollapseClick={onCollapseClick}
  >
    {children}
  </CardBase>
);
