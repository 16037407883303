import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';

import { useIncidentConfiguration } from '../../../../../hooks/useIncidentConfiguration';
import { TrashIcon } from '../../../../../icons';
import { PreDefinedField, preparePreDefinedFields } from '../../../../../IncidentConfiguration/ConfiguredFields';

import styles from './index.module.scss';

const INJURIES_FIELD_NAME = 'injuries';
const BODILY_INJURIES_CONFIG_PATH = 'involved_parties.involved_person.bodily_injuries';

const getInitialBodilyInjuriesPredefinedFields = (incidentConfiguration, fieldConfigPath) =>
  preparePreDefinedFields(get(incidentConfiguration, fieldConfigPath, {}));
const injuriesInitialValues = () => ({
  injury_type: '',
  body_part: '',
  injury_severity: '',
  other_body_part: '',
  description: '',
  id: `new_${uuidv4()}`,
});

const BodilyInjuriesFragment = ({
  showOnly,
  fieldId = INJURIES_FIELD_NAME,
  fieldConfigPath = BODILY_INJURIES_CONFIG_PATH,
}) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const { values } = useFormikContext();
  const preDefinedFields = getInitialBodilyInjuriesPredefinedFields(incidentConfiguration, fieldConfigPath);

  return (
    <div className={styles.bodilyInjuriesContainer}>
      <InnerCard title="Injuries" className={styles.bodilyInjuriesInner} titleClassName={styles.bodyInjuriesInnerTitle}>
        <FieldArray
          name={fieldId}
          render={({ remove, push }) => (
            <>
              {get(values, fieldId, []).map((item, idx) => {
                return (
                  <Grid key={item.id} container spacing={2} alignItems="flex-end">
                    <PreDefinedField
                      id="injury_type"
                      idPrefix={`${fieldId}.${idx}`}
                      fields={preDefinedFields}
                      inline
                      gridXs={4}
                      readOnly={showOnly}
                    />
                    <PreDefinedField
                      id="body_part"
                      idPrefix={`${fieldId}.${idx}`}
                      fields={preDefinedFields}
                      inline
                      gridXs={3}
                      readOnly={showOnly}
                    />
                    <PreDefinedField
                      id="injury_severity"
                      idPrefix={`${fieldId}.${idx}`}
                      fields={preDefinedFields}
                      inline
                      gridXs={3}
                      readOnly={showOnly}
                    />
                    <Grid item xs={1}>
                      {!showOnly && (
                        <Button onClick={() => remove(idx)}>
                          <TrashIcon />
                        </Button>
                      )}
                    </Grid>
                    {get(values, `${fieldId}.${idx}.body_part`) === 'other' && (
                      <PreDefinedField
                        id="other_body_part"
                        idPrefix={`${fieldId}.${idx}`}
                        fields={preDefinedFields}
                        inline
                        gridXs={10}
                        readOnly={showOnly}
                      />
                    )}
                    <Grid item xs={12} />
                  </Grid>
                );
              })}
              {!showOnly && (
                <Button color="primary" onClick={() => push(injuriesInitialValues())}>
                  <span className={styles.addBtn}>
                    <AddIcon />
                    <span className={styles.addBtnLabel}>Add Injuries</span>
                  </span>
                </Button>
              )}
            </>
          )}
        />
      </InnerCard>
    </div>
  );
};

BodilyInjuriesFragment.propTypes = {
  showOnly: PropTypes.bool,
  fieldId: PropTypes.string,
  fieldConfigPath: PropTypes.string,
};

export default BodilyInjuriesFragment;
