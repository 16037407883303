import React, { useEffect } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import { get, isEmpty, omitBy } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import YesNoNaUnknownQuestionFormik from '~/components/core/Formik/YesNoNaUnknownQuestionFormik';
import HoverActionField from '~/components/HoverActionField';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';

import { isoDateToUs, localTimeToViewTime } from '../../DateTimeUtils';
import { BOOL4_OPTIONS, COUNTRY_TO_STATE_MAP } from '../../Types';
import { localeDetails } from '../CmsMain/globals';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import WithConfirm, { ConfirmModal } from '../ConfirmModal';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import useCurrencyFormatter from '../CurrencyFormatterContext';
import NcicCodeFragment from '../Fnol/NewFnolUI/InvolvedParties/AutoParties/NcicCodeFragment';
import { LossLocationShowOnly, LossLocationTextFieldFormik } from '../GlobalLossLocation';
import { useLob } from '../hooks/useLob';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
  NumericTextFieldFormik,
  ShowOnlyTextField,
  TimePickerTextFieldFormik,
  YearPickerSelectTextFieldFormik,
} from '../TextFieldFormik';

import useCustomFields, { CustomFieldsContextProvider, getAdditionalDataValidations } from './CustomFieldsContext';

import { useStyles } from '../../assets/styles';

const METADATA_FIELDS = [
  'lob_specific',
  'inherit',
  'type',
  'desc',
  'active',
  'incident_sub_type_categories',
  'db_field',
];
const CONFIGURABLE_FIELDS_ID_PREFIX = 'configured_fields_values';
const FULL_ROW_TYPES = ['yes_no_na_unknown', 'multiline_string'];
const WRAPPED_TYPES = ['location'];

const getInitialSectionConfig = (incidentConfiguration, configPath, defaultConfig, isFieldSupportedBySubtype) => {
  const config = { ...get(incidentConfiguration, configPath, defaultConfig) }; // spread config to not touch the original config

  const isActive = (value) => value?.active === true;
  const isMandatory = (value) => value?.mandatory === true;
  const matchGeoSpecific = (value) => !shouldNotDisplayRegionSpecificField(value);
  const matchIncidentSubtype = (value) => isFieldSupportedBySubtype && isFieldSupportedBySubtype(value);
  const sectionShouldBeMandatory = (value) =>
    isActive(value) && isMandatory(value) && matchGeoSpecific(value) && matchIncidentSubtype(value);

  if (Object.values(config).some((value) => sectionShouldBeMandatory(value))) {
    config.mandatory = true;
  }

  return config;
};

const preparePreDefinedFields = (fields, additionalFieldOmitFn) => {
  if (isEmpty(fields)) {
    return {};
  }
  const preDefinedFields = omitBy(
    fields,
    (field) =>
      !field?.active ||
      shouldNotDisplayRegionSpecificField(field) ||
      (additionalFieldOmitFn && !additionalFieldOmitFn(field))
  );
  Object.keys(preDefinedFields).forEach((key) => {
    if (METADATA_FIELDS.includes(key)) {
      delete preDefinedFields[key];
    } else if (preDefinedFields[key]) {
      preDefinedFields[key].id = key; // in pre_defined_fields the id is not inside the field object
    }
  });

  return preDefinedFields;
};

const getConfiguredFieldGridXs = (field) => (FULL_ROW_TYPES.includes(field?.type) ? 12 : 6);

const getInitialValuesWithIncidentTypes = (values, path) => ({
  incident_type: values?.incident_type,
  incident_sub_type: values?.incident_sub_type,
  ...get(values, path),
});

const getConfiguredFieldsValidations = (incidentConfiguration, section = 'incident_details', additionalFieldOmitFn) => {
  const configuredFields = incidentConfiguration?.incident_details?.configured_fields.filter((field) =>
    additionalFieldOmitFn ? additionalFieldOmitFn(field) : true
  );

  if (isEmpty(configuredFields)) {
    return {};
  }

  const fields = {};
  const fieldsArray =
    section === 'supplementary_info'
      ? configuredFields?.filter((field) => field?.section === 'supplementary_info') || []
      : configuredFields?.filter((field) => field?.section === 'incident_details' || !field?.section) || [];

  fieldsArray?.forEach((field) => {
    fields[field?.id] = field;
  });

  return getAdditionalDataValidations(fields);
};

const shouldNotDisplayRegionSpecificField = (field) => {
  const region = localeDetails.locale.region;

  return field?.geo_specific && field.geo_specific !== region;
};

const isWrappedField = (field) => WRAPPED_TYPES.includes(field?.type);

const PreDefinedField = ({
  id,
  fields,
  value,
  onUpdate,
  readOnly = false,
  inline = false,
  saveGrid = false,
  newRowAfter = false,
  rows = undefined,
  gridXs = undefined,
  desc = undefined,
  idPrefix = undefined,
  ...rest
}) => {
  const formikContext = useFormikContext(); // emits a warning if not under Formik context - this might happen
  const field = get(fields, id);
  const xs = gridXs || getConfiguredFieldGridXs(field);

  if (!field?.active || shouldNotDisplayRegionSpecificField(field)) {
    return saveGrid ? <Grid item xs={xs} /> : <></>;
  }

  field.id = id; // in pre_defined_fields the id is not inside the field object
  field.desc = desc || field.desc;
  field.disableFuture = rest?.disableFuture || false; // disableFuture also used inside the validations
  const full_id = idPrefix ? `${idPrefix}.${id}` : field.id;
  const fieldValue = value || (formikContext ? getIn(formikContext.values, full_id) : undefined);

  return (
    <CustomFieldsContextProvider customFieldsConfigurations={[field]}>
      <Grid item xs={xs}>
        <CustomField
          field={field}
          idPrefix={idPrefix}
          value={fieldValue}
          onUpdate={onUpdate}
          readOnly={readOnly}
          inline={inline}
          rows={rows}
          {...rest}
        />
      </Grid>
      {newRowAfter && <Grid item xs={12 - xs} />}
    </CustomFieldsContextProvider>
  );
};

PreDefinedField.propTypes = {
  id: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  value: PropTypes.object,
  onUpdate: requiredIf(PropTypes.func, (props) => !props.inline),
  readOnly: PropTypes.bool,
  inline: PropTypes.bool,
  saveGrid: PropTypes.bool,
  newRowAfter: PropTypes.bool,
  rows: PropTypes.number,
  gridXs: PropTypes.number,
  desc: PropTypes.string,
  idPrefix: PropTypes.string,
};

const ConfiguredFields = ({
  values,
  customFields,
  onFieldUpdate,
  readOnly = false,
  inline = false,
  idPrefix = CONFIGURABLE_FIELDS_ID_PREFIX,
}) => {
  return (
    <CustomFieldsContextProvider customFieldsConfigurations={customFields}>
      <Grid container spacing={1}>
        {customFields
          ?.filter((field) => !(field?.active === false))
          .map((field) => (
            <Grid item xs={getConfiguredFieldGridXs(field)} key={field.id}>
              <CustomField
                field={field}
                value={get(values, idPrefix ? `${idPrefix}.${field.id}` : field.id)}
                onUpdate={onFieldUpdate}
                readOnly={readOnly}
                inline={inline}
                idPrefix={idPrefix}
              />
            </Grid>
          ))}
      </Grid>
    </CustomFieldsContextProvider>
  );
};

ConfiguredFields.propTypes = {
  values: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
  onFieldUpdate: requiredIf(PropTypes.func, (props) => !props.inline),
  readOnly: PropTypes.bool,
  inline: PropTypes.bool,
  idPrefix: PropTypes.string,
};

const customFieldsBasePropTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  onUpdate: requiredIf(PropTypes.func, (props) => !props.inline),
  readOnly: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired,
  idPrefix: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overFlowWithTooltip: PropTypes.bool,
};

const CustomField = ({
  field,
  value,
  onUpdate,
  readOnly,
  inline,
  rows = undefined,
  idPrefix = undefined,
  maxWidth,
  overFlowWithTooltip,
  ...rest
}) => {
  const onFieldUpdate = async (updatedValues) =>
    await onUpdate(field.id, isWrappedField(field) ? updatedValues : updatedValues[field.id]);

  const props = {
    field,
    value,
    readOnly,
    inline,
    onUpdate: onFieldUpdate,
    rows,
    idPrefix,
    maxWidth,
    ...rest,
  };

  switch (field.type) {
    case 'string':
      return (
        <TextCustomField
          {...props}
          title={value}
          value={
            overFlowWithTooltip ? <OverflowTextWithToolTip maxWidth={maxWidth}>{value}</OverflowTextWithToolTip> : value
          }
        />
      );
    case 'multiline_string':
      return <TextCustomField {...props} rows={rows || 2} title={value} />;
    case 'select':
      return <SelectCustomField {...props} />;
    case 'us_state_select':
      return (
        <SelectCustomField
          overrideOptions={Object.keys(COUNTRY_TO_STATE_MAP['US'])
            .sort((s1, s2) => COUNTRY_TO_STATE_MAP['US'][s1].localeCompare(COUNTRY_TO_STATE_MAP['US'][s2]))
            .map((state) => ({
              id: state,
              desc: COUNTRY_TO_STATE_MAP['US'][state],
            }))}
          {...props}
        />
      );
    case 'multiselect':
      return <SelectCustomField isMultiSelect {...props} />;
    case 'date': {
      return <DateCustomField {...props} />;
    }
    case 'year': {
      return <YearCustomField {...props} />;
    }
    case 'time': {
      return <TimeCustomField {...props} />;
    }
    case 'number': {
      return <NumericCustomField {...props} />;
    }
    case 'boolean':
      return <BoolCustomField additionalChildrenProps={{ width: '300px' }} {...props} />;
    case 'contact':
      return <ContactCustomField {...props} />;
    case 'yes_no_na_unknown':
      return <YesNoNaUnknownQuestionCustomField {...props} />;
    case 'monetary':
      return <MonetaryValueCustomField {...props} />;
    case 'location':
      return <LocationCustomField {...props} />;
    case 'make_ncic_code':
      return <NcicCodeFragment {...props} />;
    default:
      return <></>;
  }
};

CustomField.propTypes = {
  ...customFieldsBasePropTypes,
  rows: PropTypes.number,
};

const TextCustomField = ({
  field,
  value,
  onUpdate,
  readOnly,
  inline,
  rows = undefined,
  idPrefix = undefined,
  ...rest
}) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline) {
    return (
      <TextFieldFormik
        id={id}
        label={field.desc}
        className={classes.textField}
        fullWidth
        showOnly={readOnly}
        multiline={!!rows}
        rows={rows}
        {...rest}
      />
    );
  }

  const readOnlyField = (
    <ShowOnlyTextField classes={classes} showOnlyValueComponent={value} label={field.desc} {...rest} />
  );

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      value={value ?? ''}
      label={field.desc}
      onUpdate={onUpdate}
      fullWidth
      multiline
      showOnly
      rows={rows ? rows : 3}
      validationSchema={additionalDataValidations[field.id]}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

TextCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  rows: PropTypes.number,
};

const SelectCustomField = ({
  field,
  value,
  onUpdate,
  isMultiSelect = false,
  readOnly,
  inline,
  idPrefix = undefined,
  overrideOptions = undefined,
  ...rest
}) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const formikContext = useFormikContext(); // emits a warning if not under Formik context - this might happen

  field.options = overrideOptions || field.options;

  const selectIdsList = isMultiSelect ? value : [value];
  const disabledIdsSelected = field.options
    .filter((opt) => opt.active === false && selectIdsList?.includes(opt.id))
    .map((opt) => opt.id);

  const optionsEnabledOrSelected = field.options.filter(
    (opt) => opt.active === undefined || opt.active === true || disabledIdsSelected.includes(opt.id)
  );
  const displayValueFunc = (key) => field.options.find((o) => o.id === key)?.desc;
  const valueInline = selectIdsList?.map((key) => displayValueFunc(key)).join(', ');
  const disabledSelectedOptions = disabledIdsSelected.map((id) => displayValueFunc(id)).join(', ');
  const disabledOptionsConfirmTitle = `Editing ${field.desc}?`;
  const disabledOptionsConfirmContentText = `To edit this field, please confirm the removal of the following disabled options: ${disabledSelectedOptions}`;

  useEffect(() => {
    if (!isConfirmOpen && isMultiSelect && !isEmpty(disabledIdsSelected) && inline) {
      setIsConfirmOpen(true);
    }
  }, [disabledIdsSelected, inline, isConfirmOpen, isMultiSelect]);

  if (inline) {
    if (isMultiSelect) {
      if (!isEmpty(disabledIdsSelected) && isConfirmOpen) {
        return (
          <WithConfirm
            title={disabledOptionsConfirmTitle}
            contentText={disabledOptionsConfirmContentText}
            onClose={() => setIsConfirmOpen(false)}
            primaryButtonName="OK, Remove"
            shouldCloseOnPrimary
            centerDialog
          >
            <TextFieldFormik
              id={id}
              label={field.desc}
              className={classes.textField}
              value={valueInline}
              fullWidth
              onClick={() => {
                setIsConfirmOpen(false);
                formikContext?.setFieldValue(
                  id,
                  selectIdsList?.filter((id) => !disabledIdsSelected.includes(id))
                );
              }}
            />
          </WithConfirm>
        );
      }

      return (
        <MultiSelectTextFieldFormik
          id={id}
          label={field.desc}
          className={classes.textField}
          options={optionsEnabledOrSelected.map((o) => o.id)}
          renderValue={(selected) => selected.map((key) => displayValueFunc(key)).join(', ')}
          renderOption={displayValueFunc}
          fullWidth
          showOnly={readOnly}
          {...rest}
        />
      );
    } else {
      if (readOnly) {
        return (
          <ShowOnlyTextField classes={classes} showOnlyValueComponent={displayValueFunc(value)} label={field.desc} />
        );
      }
      return (
        <TextFieldFormik
          id={id}
          label={field.desc}
          className={classes.textField}
          fullWidth
          select
          showOnly={readOnly}
          {...rest}
        >
          {optionsEnabledOrSelected.map((option) => (
            <MenuItem key={option.id} value={option.id} disabled={disabledIdsSelected.includes(option.id)}>
              {option.desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      );
    }
  }

  const selectProps = {
    value: value || (isMultiSelect ? [] : null),
    label: field.desc,
    fieldId: field.id,
    keys: optionsEnabledOrSelected.map((o) => o.id),
    keysDisabled: disabledIdsSelected,
    onUpdate,
    overrideOnEdit: true,
    isMultiSelect,
    validationSchema: additionalDataValidations[field.id],
    idPrefix: PropTypes.string,
  };

  const readOnlyProps = {
    classes,
    label: field.desc,
  };

  if (isMultiSelect) {
    selectProps.displayValueFunc = (id) => optionsEnabledOrSelected.find((o) => o.id === id).desc;
    readOnlyProps.showOnlyValueComponent = value
      ? value.map((v) => optionsEnabledOrSelected.find((o) => o.id === v).desc).join(', ')
      : '';
  } else {
    selectProps.displayValueFunc = (id) => optionsEnabledOrSelected.find((o) => o.id === id).desc;
    readOnlyProps.showOnlyValueComponent = value ? optionsEnabledOrSelected.find((o) => o.id === value).desc : '';
  }

  const readOnlyField = <ShowOnlyTextField {...readOnlyProps} />;

  if (readOnly) {
    return readOnlyField;
  }

  if (isMultiSelect && !isEmpty(disabledIdsSelected)) {
    return (
      <>
        <HoverActionField onAction={() => setIsConfirmOpen(true)} {...rest}>
          {valueInline}
        </HoverActionField>
        <ConfirmModal
          isOpen={isConfirmOpen}
          onClose={() => setIsConfirmOpen(false)}
          title={disabledOptionsConfirmTitle}
          contentText={disabledOptionsConfirmContentText}
          primaryButtonName="OK, Remove"
          onPrimaryBtnClick={async () => {
            await onUpdate({ [field.id]: selectIdsList.filter((id) => !disabledIdsSelected.includes(id)) });
            setIsConfirmOpen(false);
          }}
        />
      </>
    );
  }

  return <SelectHoverChangeField {...selectProps}>{readOnlyField}</SelectHoverChangeField>;
};

SelectCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  isMultiSelect: PropTypes.bool,
};

const DateCustomField = ({
  field,
  value,
  onUpdate,
  readOnly,
  inline,
  idPrefix = undefined,
  disableFuture = undefined,
  withoutLabelOnReadOnly = false,
  ...rest
}) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return (
      <DatePickerTextFieldFormik
        id={id}
        label={field.desc}
        className={classes.textField}
        fullWidth
        disableFuture={disableFuture}
        {...rest}
      />
    );
  }

  return (
    <SpecialCustomField
      specialType="date"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
      disableFuture={disableFuture}
      withoutLabelOnReadOnly={withoutLabelOnReadOnly}
    />
  );
};

DateCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  disableFuture: PropTypes.bool,
  withoutLabelOnReadOnly: PropTypes.bool,
};

const TimeCustomField = ({ field, value, onUpdate, readOnly, inline, idPrefix = undefined }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return <TimePickerTextFieldFormik id={id} label={field.desc} className={classes.textField} fullWidth />;
  }

  return (
    <SpecialCustomField
      specialType="time"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

TimeCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const YearCustomField = ({ field, value, onUpdate, readOnly, inline, idPrefix = undefined, ...rest }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return (
      <YearPickerSelectTextFieldFormik id={id} label={field.desc} className={classes.textField} fullWidth {...rest} />
    );
  }

  return (
    <SpecialCustomField
      specialType="year"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

YearCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const NumericCustomField = ({ field, value, onUpdate, readOnly, inline, idPrefix = undefined, ...rest }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return <NumericTextFieldFormik id={id} label={field.desc} className={classes.textField} fullWidth {...rest} />;
  }

  return (
    <SpecialCustomField
      specialType="numeric"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

NumericCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const ContactCustomField = ({
  field,
  value,
  contactDisplayName,
  onUpdate,
  readOnly,
  inline,
  idPrefix = undefined,
  maxWidth,
  ...rest
}) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const { organizationContactRolesDict } = useOrganization();
  const acceptedRoles = field.accepted_roles || getAllSearchableContactRoles(organizationContactRolesDict);
  const formik = useFormikContext() || {};

  // we assume each contact field.id is of the form 'X_id' while all FE components expect to receive X and append the '_id' automatically
  const contactObjFieldId = field.id.slice(0, -1 * '_id'.length);
  const id = idPrefix ? `${idPrefix}.${contactObjFieldId}` : contactObjFieldId;

  if (inline && !readOnly) {
    const fullNameId = id?.replace(/contact$/, 'contact_full_name');
    let contactFullName = get(formik?.values, fullNameId);

    return (
      <ContactTextFieldFormik
        id={id}
        label={field.desc}
        className={classes.textField}
        acceptedRoles={acceptedRoles}
        fullWidth
        fixedSearchResults
        contactDisplayName={contactFullName}
        {...rest}
      />
    );
  }

  const readOnlyField = (
    <ContactShowOnlyTextField
      label={field.desc}
      contactId={value ?? ''}
      contactDisplayName={contactDisplayName}
      key={value ?? ''} // otherwise - if we update, it might not get it
      maxWidth={maxWidth}
    />
  );

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      customFieldIdName={contactObjFieldId}
      value={value ?? ''}
      label={field.desc}
      specialFieldType="contact"
      specialFieldAdditionalProps={{ acceptedRoles }}
      onUpdate={(values) => {
        const fieldVal = values[field.id];
        return onUpdate({ [field.id]: fieldVal === '' ? null : fieldVal });
      }}
      validationSchema={additionalDataValidations[field.id]}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

ContactCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  contactDisplayName: PropTypes.string,
};

const YesNoNaUnknownQuestionCustomField = ({ field, value, onUpdate, readOnly, inline, idPrefix = undefined }) => {
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return (
      <YesNoNaUnknownQuestionFormik
        id={id}
        questionText={field.desc}
        disable_na={field.disable_na}
        disable_unknown={field.disable_unknown}
      />
    );
  }

  return (
    <SpecialCustomField
      specialType="yes_no_na_unknown"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

YesNoNaUnknownQuestionCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const MonetaryValueCustomField = ({ field, value, onUpdate, readOnly, inline, idPrefix = undefined, ...rest }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return (
      <MonetaryValueTextFieldFormik id={id} label={field.desc} className={classes.textField} fullWidth {...rest} />
    );
  }

  return (
    <SpecialCustomField
      specialType="monetary"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

MonetaryValueCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const LocationCustomField = ({ field, value, onUpdate, readOnly, inline, idPrefix = undefined, ...rest }) => {
  const { lob } = useLob();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;
  const withIsHighway = lob === 'auto_claim';
  const dropRequiredValidation = id !== 'loss_location' && id !== 'vehicle_location';

  if (inline && !readOnly) {
    return (
      <LossLocationTextFieldFormik
        id={id}
        label={field.desc}
        dropRequiredValidation={dropRequiredValidation}
        flatten
        withClearButton
        withIsHighway={withIsHighway}
        {...rest}
      />
    );
  }

  return (
    <LossLocationShowOnly
      location={value || {}}
      label={field.desc}
      onUpdate={onUpdate}
      withIsHighway={withIsHighway}
      dropRequiredValidation={dropRequiredValidation}
      {...rest}
    />
  );
};

LocationCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const SpecialCustomField = ({
  field,
  value,
  onUpdate,
  specialType,
  validationSchema,
  readOnly,
  disableFuture = undefined,
  withoutLabelOnReadOnly = false,
}) => {
  const classes = useStyles();
  const { symbol } = useCurrencyFormatter();

  let formattedValue;

  switch (specialType) {
    case 'date':
      formattedValue = value ? isoDateToUs(value) : '';
      break;
    case 'time':
      formattedValue = value ? localTimeToViewTime(value) : '';
      break;
    case 'yes_no_na_unknown':
      formattedValue = value ? BOOL4_OPTIONS[value] : '';
      break;
    case 'monetary':
      formattedValue = value ? `${symbol}${value}` : '';
      break;
    default:
      formattedValue = value || '';
      break;
  }

  const readOnlyField = (
    <ShowOnlyTextField
      classes={classes}
      showOnlyValueComponent={formattedValue}
      label={!withoutLabelOnReadOnly ? field.desc : undefined}
    />
  );

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      value={value ?? ''}
      label={field.desc}
      specialFieldType={specialType}
      specialFieldProps={field}
      onUpdate={onUpdate}
      validationSchema={validationSchema}
      specialFieldAdditionalProps={{ disableFuture }}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

SpecialCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  specialType: PropTypes.oneOf(['date', 'time', 'numeric', 'year', 'yes_no_na_unknown']).isRequired,
  validationSchema: PropTypes.object,
};

const BOOL_OPTIONS = [
  {
    label: 'Yes',
    optionValue: true,
  },
  {
    label: 'No',
    optionValue: false,
  },
];

const BoolCustomField = ({
  field,
  value,
  onUpdate,
  additionalChildrenProps,
  readOnly,
  inline,
  idPrefix = undefined,
}) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();
  const id = idPrefix ? `${idPrefix}.${field.id}` : field.id;

  if (inline && !readOnly) {
    return (
      <YesNoNaUnknownQuestionFormik id={id} questionText={field.desc} disabled={readOnly} options={BOOL_OPTIONS} />
    );
  }

  const fieldText = (() => {
    if (value === undefined || value === null) {
      return '';
    }

    return value ? 'Yes' : 'No';
  })();

  const readOnlyField = <ShowOnlyTextField classes={classes} showOnlyValueComponent={fieldText} label={field.desc} />;

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      value={value ?? ''}
      label={field.desc}
      specialFieldType="yes_no_na_unknown"
      specialFieldProps={{ field, options: BOOL_OPTIONS }}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      {...additionalChildrenProps}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

BoolCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  additionalChildrenProps: PropTypes.object,
};

function getConfiguredFieldsEmptyFormikInitialValues(
  fieldsConfig,
  section = 'incident_details',
  additionalFieldOmitFn
) {
  const initialValues = {};
  fieldsConfig
    .filter((field) => (additionalFieldOmitFn ? additionalFieldOmitFn(field) : true))
    .forEach((field) => {
      if (field?.section === section || (!field?.section && section === 'incident_details')) {
        if (field.type === 'multiselect') {
          initialValues[field.id] = [];
        } else {
          initialValues[field.id] = '';
        }
      }
    });
  return initialValues;
}

const getPredefinedFieldsEmptyFormikInitialValues = (fieldsConfig) => {
  const initialValues = {};
  Object.values(fieldsConfig).forEach((field) => {
    if (field.type === 'pre_defined_fields') {
      return;
    } else if (field.type === 'multiselect') {
      initialValues[field.id] = [];
    } else {
      initialValues[field.id] = '';
    }
  });
  return initialValues;
};

function cleanConfiguredFieldsFormikValuesBeforeSend(fieldsConfig, values, options = {}) {
  const {
    additionalFieldOmitFn,
    shouldSendNullForEmpty, // useful for PATCH operations
  } = options;
  const valuesClean = {};
  fieldsConfig
    .filter((field) => (additionalFieldOmitFn ? additionalFieldOmitFn(field) : true))
    .forEach((field) => {
      const k = field.id;
      if (values[k] === '' || values[k] === null) {
        if (shouldSendNullForEmpty) {
          valuesClean[k] = null;
        }
      } else if (values[k] !== undefined) {
        valuesClean[k] = values[k];
        if (k.endsWith('contact_id')) {
          const contactKeyWithoutId = k.slice(0, -1 * '_id'.length);
          valuesClean[contactKeyWithoutId] = values[contactKeyWithoutId];
        }
      }
    });
  return valuesClean;
}

export {
  cleanConfiguredFieldsFormikValuesBeforeSend,
  getConfiguredFieldsEmptyFormikInitialValues,
  getConfiguredFieldsValidations,
  getInitialSectionConfig,
  getInitialValuesWithIncidentTypes,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
};

export { CustomField };
export default ConfiguredFields;
