import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import DirectionsBikeOutlinedIcon from '@material-ui/icons/DirectionsBikeOutlined';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { BIKE_ANTI_THEFT_DICT, BIKE_DAMAGES_LIST, BIKE_MARKING_TYPE_DICT, BIKE_TYPE_DICT } from '../../Types';
import { reportAxiosError } from '../../Utils';
import { findIncidentParty } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ContactEntity } from '../Contact';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import HoverActionField from '../HoverActionField';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
} from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function BikeInvolvedCard() {
  const { claim, onClaimUpdate } = useClaim();
  const classes = useStyles();

  const firstPartyBike = findIncidentParty(claim, claim.bike_party_id);
  const [showInvolvedBikeDialog, setShowInvolvedBikeDialog] = useState(false);
  const [editThirdPartyDialogOpen, setEditThirdPartyDialogOpen] = useState(false);

  const handleUpdateInvolvedBikeDetails = async (bike_values) => {
    try {
      await axios.put(`/api/v1/bike_claims/${claim.id}/first_party_bike/involved_bike`, bike_values);
      await onClaimUpdate();
      setShowInvolvedBikeDialog(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleUpdateThirdPartyDetails = async (values) => {
    try {
      await axios.put(`/api/v1/bike_claims/${claim.id}/third_party`, values);
      await onClaimUpdate();
      setEditThirdPartyDialogOpen(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      <CardDialog title="Involved Parties">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CardDialog title="Insured Bike" outlinedCard>
              <div className={classes.containerCentered}>
                <DirectionsBikeOutlinedIcon className={classes.indicationIcon} style={{ color: '#9232CA' }} />
                &nbsp;
                <HoverActionField onAction={() => setShowInvolvedBikeDialog(true)} classes={classes}>
                  {firstPartyBike.involved_bike.bike.display_name}
                </HoverActionField>
              </div>
            </CardDialog>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <CardDialog title="Third Party" outlinedCard>
              {claim.incident.third_party_contact_id ? (
                <HoverActionField onAction={() => setEditThirdPartyDialogOpen(true)} classes={classes}>
                  <div className={classes.containerCentered}>
                    <ContactEntity
                      contactId={claim.incident.third_party_contact_id}
                      contactDisplayName={claim.incident.third_party_contact_full_name}
                    />
                  </div>
                </HoverActionField>
              ) : (
                <Button color="primary" onClick={() => setEditThirdPartyDialogOpen(true)}>
                  Set Third Party
                </Button>
              )}
            </CardDialog>
          </Grid>
        </Grid>
      </CardDialog>

      {showInvolvedBikeDialog && (
        <InvolvedBikeEditDialog
          bikeInvolved={firstPartyBike.involved_bike}
          onCancel={() => setShowInvolvedBikeDialog(false)}
          onSaveBikeDetails={handleUpdateInvolvedBikeDetails}
          isInsured
        />
      )}

      {editThirdPartyDialogOpen && (
        <BikeThirdPartyDetails
          claim={claim}
          onClose={() => setEditThirdPartyDialogOpen(false)}
          onUpdate={handleUpdateThirdPartyDetails}
        />
      )}
    </>
  );
}

function InvolvedBikeEditDialog({ bikeInvolved, onCancel, onSaveBikeDetails, isInsured, showOnly }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ ...bikeInvolved }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, formikProps) => {
        try {
          await onSaveBikeDetails(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        return (
          <CardDialog isDialog={true} title="Bike" maxWidth="sm" onClose={onCancel} preventClose={isSubmitting}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ContactTextFieldFormik
                  id="owner_contact"
                  label="Owner"
                  className={classes.textField}
                  acceptedRoles={isInsured ? ['insured'] : ['claimant', 'other']}
                  contactSearchProps={isInsured ? undefined : { newContactRole: 'claimant' }}
                  showOnly={showOnly || isInsured}
                  fixedSearchResults
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <BikeFragmentDetails showOnly={isInsured || showOnly} isInsured={isInsured} />
              <Grid item xs={3}>
                <TextFieldFormik
                  id="gps_exists"
                  label="GPS Exists"
                  className={classes.textField}
                  showOnly={showOnly}
                  fullWidth
                  select
                >
                  <MenuItem key={true} value={true}>
                    Yes
                  </MenuItem>
                  <MenuItem key={false} value={false}>
                    No
                  </MenuItem>
                </TextFieldFormik>
              </Grid>

              {values.gps_exists ? (
                <>
                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="gps_built_in"
                      label="GPS Built In"
                      className={classes.textField}
                      showOnly={showOnly}
                      fullWidth
                      select
                    >
                      <MenuItem key={true} value={true}>
                        Yes
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                        No
                      </MenuItem>
                    </TextFieldFormik>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="gps_brand"
                      label="GPS Brand"
                      className={classes.textField}
                      showOnly={showOnly}
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={9} />
              )}

              <Grid item xs={6}>
                <TextFieldFormik
                  id="lock_brand"
                  label="Lock Brand & Model"
                  className={classes.textField}
                  showOnly={showOnly}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextFieldFormik
                  id="marking_type"
                  label="Marking Type"
                  className={classes.textField}
                  showOnly={showOnly}
                  select
                  fullWidth
                >
                  {Object.keys(BIKE_MARKING_TYPE_DICT).map((type) => (
                    <MenuItem key={type} value={type}>
                      {BIKE_MARKING_TYPE_DICT[type].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={6}>
                {values.marking_type !== 'no' && (
                  <TextFieldFormik
                    id="marking_id"
                    label="Marking ID"
                    className={classes.textField}
                    showOnly={showOnly}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <MultiSelectTextFieldFormik
                  id="damages"
                  label="Damage Areas"
                  options={BIKE_DAMAGES_LIST}
                  renderValue={(selected) => selected.join(', ')}
                  className={classes.textField}
                  fullWidth
                  showOnly={showOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="note"
                  label="Note"
                  fullWidth
                  multiline
                  className={classes.textField}
                  showOnly={showOnly}
                />
              </Grid>
            </Grid>
            {!showOnly && (
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={isSubmitting} onClick={onCancel} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

InvolvedBikeEditDialog.propTypes = {
  bikeInvolved: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveBikeDetails: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function BikeFragmentDetails({ showOnly }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={4}>
        <TextFieldFormik id="bike.type" label="Type" className={classes.textField} showOnly={showOnly} fullWidth select>
          {Object.keys(BIKE_TYPE_DICT).map((type) => (
            <MenuItem key={type} value={type}>
              {BIKE_TYPE_DICT[type].desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik id="bike.make" label="Brand" fullWidth className={classes.textField} showOnly={showOnly} />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik id="bike.model" label="Model" fullWidth className={classes.textField} showOnly={showOnly} />
      </Grid>
      <Grid item xs={4}>
        <DatePickerTextFieldFormik
          id="bike.purchase_date"
          label="Purchase Date"
          disablePast
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <MonetaryValueTextFieldFormik id="bike.original_value" label="Insured Value" showOnly />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik
          id="bike.serial_number"
          label="Serial Number"
          fullWidth
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik id="bike.anti_theft_measure_type" label="Anti-Theft Measure" showOnly select>
          {Object.keys(BIKE_ANTI_THEFT_DICT).map((type) => (
            <MenuItem key={type} value={type}>
              {BIKE_ANTI_THEFT_DICT[type].desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik id="bike.is_new_bike" label="Is New Bike" showOnly select>
          <MenuItem key={true} value={true}>
            Yes
          </MenuItem>
          <MenuItem key={false} value={false}>
            No
          </MenuItem>
        </TextFieldFormik>
      </Grid>
      <Grid item xs={4} />
    </>
  );
}

BikeFragmentDetails.propTypes = {
  isInsured: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function ShowOnlyBikeDetailsInfoCardDialog({ bike, title, isDialog, onClose }) {
  return (
    <Formik initialValues={{ bike: { ...bike } }} onSubmit={() => {}}>
      {() => {
        return (
          <CardDialog isDialog={isDialog} open title={title || 'Bike Info'} maxWidth="sm" onClose={onClose}>
            <Grid container spacing={1}>
              <BikeFragmentDetails showOnly isInsured />
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ShowOnlyBikeDetailsInfoCardDialog.propTypes = {
  bike: PropTypes.object.isRequired,
  title: PropTypes.string,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
};

function BikeThirdPartyDetails({ claim, onUpdate, onClose, readOnly }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        third_party_contact_id: claim.incident.third_party_contact_id,
        third_party_reference_number: claim.incident.third_party_reference_number || '',
        third_party_attorney_contact_id: claim.incident.third_party_attorney_contact_id || '',
        third_party_attorney_reference_number: claim.incident.third_party_attorney_reference_number || '',
        third_party_adverse_carrier_contact_id: claim.incident.third_party_adverse_carrier_contact_id || '',
        third_party_adverse_carrier_reference_number: claim.incident.third_party_adverse_carrier_reference_number || '',
        third_party_expert_contact_id: claim.incident.third_party_expert_contact_id || '',
        third_party_expert_reference_number: claim.incident.third_party_expert_reference_number || '',
        third_party_broker_contact_id: claim.incident.third_party_broker_contact_id || '',
        third_party_broker_reference_number: claim.incident.third_party_broker_reference_number || '',
      }}
      validationSchema={Yup.object().shape({
        third_party_contact_id: Yup.number().nullable().required('Required'),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onUpdate(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog isDialog title="Third Party Details" onClose={onClose}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <ContactTextFieldFormik
                id="third_party_contact"
                label="Third Party"
                className={classes.textField}
                showOnly={readOnly}
                acceptedRoles={['third_party', 'thief']}
                fixedSearchResults
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="third_party_reference_number"
                label="Reference Number"
                className={classes.textField}
                showOnly={readOnly}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ContactTextFieldFormik
                id="third_party_attorney_contact"
                label="Attorney"
                className={classes.textField}
                showOnly={readOnly}
                acceptedRoles={['attorney']}
                fixedSearchResults
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="third_party_attorney_reference_number"
                label="Attorney Reference Number"
                className={classes.textField}
                showOnly={readOnly}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ContactTextFieldFormik
                id="third_party_adverse_carrier_contact"
                label="Insurer"
                className={classes.textField}
                showOnly={readOnly}
                acceptedRoles={['insurer_for_real']}
                fixedSearchResults
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="third_party_adverse_carrier_reference_number"
                label="Insurer Reference Number"
                className={classes.textField}
                showOnly={readOnly}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ContactTextFieldFormik
                id="third_party_expert_contact"
                label="Expert"
                className={classes.textField}
                showOnly={readOnly}
                acceptedRoles={['expert']}
                fixedSearchResults
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="third_party_expert_reference_number"
                label="Expert Reference Number"
                className={classes.textField}
                showOnly={readOnly}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ContactTextFieldFormik
                id="third_party_broker_contact"
                label="Broker"
                className={classes.textField}
                showOnly={readOnly}
                acceptedRoles={['broker']}
                fixedSearchResults
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="third_party_broker_reference_number"
                label="Broker Reference Number"
                className={classes.textField}
                showOnly={readOnly}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <CancelButton disabled={isSubmitting} onClick={onClose} />
            <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

BikeThirdPartyDetails.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export { InvolvedBikeEditDialog, ShowOnlyBikeDetailsInfoCardDialog };
export default BikeInvolvedCard;
