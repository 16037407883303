import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Link, Typography } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import axios from 'axios';
import { Formik, getIn } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import FileDropZone from '../FileDropZone';
import LoadingIndicator from '../LoadingIndicator';
import { TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const spacing = 1;

function UploadDocumentDialog(props) {
  const {
    open,
    onCancel,
    onUpload,
    title,
    postUrl,
    withIsIncoming,
    withClaimIdDisplay,
    upperExplanation,
    withReceivingEmailAddress,
    withUuid,
  } = props;

  const classes = useStyles();
  const [uploadPercent, setUploadPercent] = React.useState(0);

  return (
    <Formik
      initialValues={{
        file_name: '',
        file: undefined,
        file_size: 0,
        is_incoming: withIsIncoming, // true if withIsIncoming is on, undefined otherwise
        claim_id_display: withClaimIdDisplay && '', // '' if withClaimIdDisplay is on, undefined otherwise
        receiving_email_address: withReceivingEmailAddress && '',
        email_uuid: uuidv4(),
      }}
      validationSchema={Yup.object().shape({
        file_size: Yup.number().required('Required').min(1, 'Required'), // we prefer validating file_size to file since we can vouch for its value
      })}
      enableReinitialize
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadPercent(percentCompleted);
          },
        };

        const formData = new FormData();
        formData.append('file', values['file']);
        formData.append('file_name', values['file_name']);
        withIsIncoming && formData.append('is_incoming', values['is_incoming']);
        withClaimIdDisplay && formData.append('claim_id_display', values['claim_id_display']);
        withReceivingEmailAddress && formData.append('receiving_email_address', values['receiving_email_address']);
        withUuid && formData.append('email_uuid', values['email_uuid']);
        try {
          if (postUrl) {
            await axios.post(postUrl, formData, config);
          }
          onUpload();
          resetForm();
        } catch (error) {
          reportAxiosError(error);
          setSubmitting(false);
        }
        setUploadPercent(0);
      }}
    >
      {({ isSubmitting, handleSubmit, errors, values, touched, setFieldValue, setFieldTouched }) => {
        const onFileSelect = (file) => {
          setFieldValue('file', file);
          setFieldTouched('file', true);
          setFieldValue('file_name', file.name);
          setFieldTouched('file_name', true);
          setFieldValue('file_size', file.size);
          setFieldTouched('file_size', true);
        };

        const onCancelFileSelect = () => {
          setFieldValue('file', undefined);
          setFieldValue('file_name', undefined);
          setFieldValue('file_size', undefined);
        };

        return (
          <CardDialog open={open} isDialog title={title}>
            <Grid container spacing={spacing}>
              {upperExplanation}
              <Grid item xs={12}>
                <FileDropZone
                  onFileSelect={onFileSelect}
                  onCancelFileSelect={onCancelFileSelect}
                  uploadPercent={uploadPercent}
                  file={values['file']}
                  error={getIn(errors, 'file_size') && getIn(touched, 'file_size')}
                  errorMessage={getIn(errors, 'file_size')}
                />
              </Grid>
              {withIsIncoming && (
                <Grid item xs={12}>
                  <CheckboxFormik id="is_incoming" label="Incoming" fullWidth />
                </Grid>
              )}
              {withClaimIdDisplay && (
                <Grid item xs={12}>
                  <TextFieldFormik id="claim_id_display" label="Claim Number" fullWidth />
                </Grid>
              )}
              {withReceivingEmailAddress && (
                <Grid item xs={12}>
                  <TextFieldFormik id="receiving_email_address" label="Receiving email address" fullWidth />
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Upload
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

UploadDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  postUrl: PropTypes.string,
  withIsIncoming: PropTypes.bool,
  withClaimIdDisplay: PropTypes.bool,
  upperExplanation: PropTypes.node,
  withReceivingEmailAddress: PropTypes.bool,
  withUuid: PropTypes.bool,
};

function UploadTscPhysicalMailDialog(props) {
  return (
    <UploadDocumentDialog
      postUrl="/test/api/v1/upload_tsc_physical_mail"
      title="Upload TSC Physical Mail"
      withIsIncoming
      {...props}
    />
  );
}

UploadTscPhysicalMailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function UploadInshurPhysicalMailDialog(props) {
  return (
    <UploadDocumentDialog
      postUrl="/test/api/v1/upload_inshur_physical_mail"
      title="Upload Inshur Physical Mail"
      withClaimIdDisplay
      {...props}
    />
  );
}

UploadInshurPhysicalMailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function UploadInshurMedlogixDocumentDialog(props) {
  return (
    <UploadDocumentDialog
      postUrl="/test/api/v1/upload_inshur_medlogix_document"
      title="Upload Inshur Medlogix Document"
      upperExplanation={
        <>
          <Typography>
            {'File name should of format: <document_type>_<claim_id_with_exposure_suffix>_D<document_id:06>.pdf'}
          </Typography>
          <Typography>Example: EOB_INSHUR-CLM-20-00248-03_D000123.pdf</Typography>
        </>
      }
      {...props}
    />
  );
}

UploadInshurMedlogixDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function UploadInshurNavigatorDocumentDialog(props) {
  return (
    <UploadDocumentDialog
      postUrl="/test/api/v1/inshur/simulate_navigator_response"
      title="Upload Inshur Navigator"
      upperExplanation={
        <>
          <Typography>{'File name should be of format: 40275459_TextDiary_<YYYYMMDD>.zip'}</Typography>
          <Typography>Example: 40275459_TextDiary_20210719.zip</Typography>
        </>
      }
      {...props}
    />
  );
}

UploadInshurNavigatorDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function SimulateInshurMedlogixPipAutomation({ claim, exposure, managedPayment, onSimulated }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSimulate = async () => {
    try {
      setIsSubmitting(true);
      await axios.post('/test/api/v1/medlogix/simulate_incoming_payment', {
        claim_id: claim.id,
        exposure_id: exposure.id,
        document_id: managedPayment.original_record_document_id,
      });
      await onSimulated();
    } catch (error) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  if (managedPayment.status !== 'Sent') {
    return null;
  }

  return (
    <>
      <IconButton variant="contained" onClick={handleSimulate} size="small" color="primary">
        <ArrowLeftIcon />
      </IconButton>
      {isSubmitting && <LoadingIndicator size={24} />}
    </>
  );
}

SimulateInshurMedlogixPipAutomation.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  managedPayment: PropTypes.object.isRequired,
  onSimulated: PropTypes.func.isRequired,
};

function UploadQoverClaimJsonDialog(props) {
  const { open, onCancel, onUpload } = props;

  const onUploadClaim = async (formData, config) => {
    const {
      data: { claim_id },
    } = await axios.post('/test/api/v1/qover/upload_claim_json', formData, config);
    onUpload();
    window.open(`/claims/${claim_id}/summary`, '_self');
  };

  return <UploadClaimJsonDialog open={open} onCancel={onCancel} onUpload={onUploadClaim} />;
}

UploadQoverClaimJsonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function UploadClaimJsonDialog(props) {
  return <UploadDocumentDialog title="Upload Claim" {...props} />;
}

UploadClaimJsonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function UploadScannedFileForPhysicalMailQueueDialog(props) {
  return (
    <UploadDocumentDialog
      title="Generic Physical Mail"
      postUrl="/test/api/v1/simulate_incoming_email"
      withReceivingEmailAddress
      upperExplanation={
        <>
          <Typography>
            Receiving email address should be an organization email that is defined as scanner_flow
          </Typography>
        </>
      }
      withUuid
      {...props}
    />
  );
}

UploadScannedFileForPhysicalMailQueueDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

function TestActions() {
  const [uploadTscPhysicalMailDialogOpen, setUploadTscPhysicalMailDialogOpen] = React.useState(false);
  const [uploadInshurMedlogixDocumentDialogOpen, setUploadInshurMedlogixDocumentDialogOpen] = React.useState(false);
  const [uploadInshurNavigatorDialogOpen, setUploadInshurNavigatorDialogOpen] = React.useState(false);
  const [uploadQoverClaimDialogOpen, setUploadQoverClaimDialogOpen] = React.useState(false);
  const [uploadScannedPhysicalMailDialog, setUploadScannedPhysicalMailDialog] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function simulateOutgoingPayments(companyName) {
    setIsSubmitting(true);

    try {
      await axios.post(`/test/api/v1/simulate_${companyName}_outgoing_payments`);
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateTscOutgoingPayments() {
    await simulateOutgoingPayments('tsc');
  }

  async function simulateTscReportPaymentsIssued() {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/simulate_tsc_report_payments_issued');
      window.location.reload();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateInshurUkReport() {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/simulate_inshur_uk_report');
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateTscIsoClueReport() {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/simulate_tsc_iso_clue_report');
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateQoverPaymentsReport(should_save) {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/qover/simulate_payments_report', { should_save });
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateMGMReportablePaymentsReport() {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/mgm/simulate_reportable_payments_report');
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateMGMNonReportablePaymentsReport() {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/mgm/simulate_non_reportable_payments_report');
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function simulateMGMOptumReport() {
    setIsSubmitting(true);
    try {
      await axios.post('/test/api/v1/mgm/simulate_optum_report');
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  return (
    <>
      <CardDialog title="Test Actions">
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => setUploadTscPhysicalMailDialogOpen(true)}>
            Upload TSC Physical Mail
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateTscOutgoingPayments()}>
            Simulate TSC Outgoing Payments
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateTscReportPaymentsIssued()}>
            Simulate TSC Report Payments Back As Issued (Today)
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateInshurUkReport()}>
            Simulate Inshur UK Report
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateTscIsoClueReport()}>
            Simulate TSC ISO CLUE Report
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button
            variant="contained"
            disabled={isSubmitting}
            onClick={() => setUploadInshurMedlogixDocumentDialogOpen(true)}
          >
            Upload Inshur Medlogix Document
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => setUploadInshurNavigatorDialogOpen(true)}>
            Upload Inshur Navigator zip
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Link href="/test/api/v1/inshur/providers_report" rel="noopener noreferrer">
            <Button variant="contained" disabled={isSubmitting}>
              Download Inshur Providers Report
            </Button>
          </Link>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => setUploadQoverClaimDialogOpen(true)}>
            Upload Qover Claim Json
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateQoverPaymentsReport(false)}>
            Simulate Qover Payments without saving
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateQoverPaymentsReport(true)}>
            Simulate Qover Payments and save
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateMGMReportablePaymentsReport()}>
            Simulate MGM Reportable Payments Report
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateMGMNonReportablePaymentsReport()}>
            Simulate MGM Non-Reportable Payments Report
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => simulateMGMOptumReport()}>
            Simulate MGM Optum report
          </Button>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '5px' }}>
          <Button variant="contained" disabled={isSubmitting} onClick={() => setUploadScannedPhysicalMailDialog(true)}>
            Add Scanned Physical mail
          </Button>
        </div>
      </CardDialog>

      <UploadTscPhysicalMailDialog
        open={uploadTscPhysicalMailDialogOpen}
        onCancel={() => setUploadTscPhysicalMailDialogOpen(false)}
        onUpload={() => setUploadTscPhysicalMailDialogOpen(false)}
      />
      <UploadInshurMedlogixDocumentDialog
        open={uploadInshurMedlogixDocumentDialogOpen}
        onCancel={() => setUploadInshurMedlogixDocumentDialogOpen(false)}
        onUpload={() => setUploadInshurMedlogixDocumentDialogOpen(false)}
      />
      <UploadInshurNavigatorDocumentDialog
        open={uploadInshurNavigatorDialogOpen}
        onCancel={() => setUploadInshurNavigatorDialogOpen(false)}
        onUpload={() => setUploadInshurNavigatorDialogOpen(false)}
      />
      <UploadQoverClaimJsonDialog
        open={uploadQoverClaimDialogOpen}
        onCancel={() => setUploadQoverClaimDialogOpen(false)}
        onUpload={() => setUploadQoverClaimDialogOpen(false)}
      />
      <UploadScannedFileForPhysicalMailQueueDialog
        open={uploadScannedPhysicalMailDialog}
        onCancel={() => setUploadScannedPhysicalMailDialog(false)}
        onUpload={() => setUploadScannedPhysicalMailDialog(false)}
      />
    </>
  );
}

export default TestActions;
export { SimulateInshurMedlogixPipAutomation, UploadClaimJsonDialog, UploadQoverClaimJsonDialog };
