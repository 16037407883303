import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { getIn, useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';

import { reportErrorInProductionOrThrow } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import HoverActionField from '../HoverActionField';
import InlineIconButton from '../InlineIconButton';

import DocumentLink from './DocumentLink';
import DocumentsContainer from './DocumentsContainer';

import { useStyles } from '../../assets/styles';

function DocumentTextFieldFormik({ id, label, showOnly, initialValues, allowTemplates }) {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const classes = useStyles();

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [openChooseDocumentDialog, setOpenChooseDocumentDialog] = useState(false);

  if (!claim) {
    reportErrorInProductionOrThrow('DocumentTextFieldFormik must be within claim context');
    return <></>;
  }

  const documentId = getIn(values, id);
  let document;

  if (documentId) {
    document = claim.documents.find((d) => d.id === documentId);
    if (!document) {
      reportErrorInProductionOrThrow(`document id: ${documentId} was not found in the claim (${claim.id})`);
    }
  }

  const onRemoveDocument = () => {
    setFieldValue(id, '');
  };

  const handleDocumentSelected = (documentId) => {
    setFieldValue(id, documentId);
    setOpenChooseDocumentDialog(false);
  };

  return (
    <>
      <div
        className={classes.showField}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <Typography
          style={{ color: getIn(errors, id) && getIn(touched, id) ? '#f44336' : undefined }}
          display="block"
          variant="caption"
        >
          {label}
        </Typography>
        <div className={classes.showValueEditableField} style={{ display: 'inline-flex', alignItems: 'center' }}>
          {documentId ? (
            <>
              <DocumentLink document={document} text={document.document_name} />
              {!showOnly && (
                <span style={{ visibility: isMouseOver ? 'visible' : 'hidden' }}>
                  <InlineIconButton
                    className={classes.inlineEditIcon}
                    icon={DeleteIcon}
                    onClick={(e) => onRemoveDocument(e)}
                  />
                </span>
              )}
            </>
          ) : (
            !showOnly && (
              <HoverActionField onAction={() => setOpenChooseDocumentDialog(true)} permanent>
                <em>Select Document</em>
              </HoverActionField>
            )
          )}
        </div>
        <ErrorHelperTextFormik id={id} />
      </div>
      {openChooseDocumentDialog && (
        <SelectDocumentDialog
          claim={claim}
          onAsyncClaimUpdate={onAsyncClaimUpdate}
          onSelect={handleDocumentSelected}
          onClose={() => setOpenChooseDocumentDialog(false)}
          initialValues={initialValues}
          allowTemplates={allowTemplates}
        />
      )}
    </>
  );
}

DocumentTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  initialValues: PropTypes.object,
  allowTemplates: PropTypes.bool,
};

DocumentTextFieldFormik.defaultProps = {
  allowTemplates: false,
};

function SelectDocumentDialog({ claim, onAsyncClaimUpdate, onSelect, onClose, initialValues, allowTemplates }) {
  const classes = useStyles();
  const [selectedDocumentId, setSelectedDocumentId] = useState();
  return (
    <CardDialog title="Select document" fullWidth isDialog maxWidth="lg" onClose={onClose}>
      <DocumentsContainer
        classes={classes}
        claim={claim}
        selectedDocumentIds={selectedDocumentId ? [selectedDocumentId] : []}
        disableChooseMultiple
        onDocumentsClick={(documentIds) => setSelectedDocumentId(documentIds[0])} // because of disableChooseMultiple, can only choose 1 document at a time
        onNewDocumentCreated={async (documentId) => {
          await onAsyncClaimUpdate();
          setSelectedDocumentId(documentId);
        }}
        hideFromTemplate={!allowTemplates}
        initialValues={initialValues}
        autoPaginateRowsPerPage={25}
      />
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => await onSelect(selectedDocumentId)}
          disabled={!selectedDocumentId}
        >
          Select
        </Button>
      </div>
    </CardDialog>
  );
}

SelectDocumentDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  onAsyncClaimUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  allowTemplates: PropTypes.bool,
};

export default DocumentTextFieldFormik;
