const GENERIC_TEMPLATES_TYPES = {
  sms: {
    display_name: 'SMS',
    template_title_required: false,
  },
  email: {
    display_name: 'Email',
    template_title_required: true,
  },
  note: {
    display_name: 'Note',
    template_title_required: true,
  },
} as const;

export default GENERIC_TEMPLATES_TYPES;
