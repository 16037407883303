import React from 'react';

import cn from '~/Utils/cn';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as styles from './MessageLoader.module.scss';

export const MessageLoader: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <div className={cn(styles.dotElastic, 'mx-15 my-24')} />;
};
