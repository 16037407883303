import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import WithConfirm from '../ConfirmModal';
import { MedicareWarning } from '../Fnol/NewFnolUI/InvolvedParties/IcdCodesFragment/medicareIcdCodesUtils';
import { TrashIcon } from '../icons';
import { ShowOnlyTextField } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

// FNOL-deprecated - once new FNOL is the only FNOL, remove this file
function IcdCodesFieldContainer({ onUpdate, viewOnly, involvedPerson, claimId }) {
  const classes = useStyles();

  const [showAddIcdCodeDialog, setShowAddIcdCodeDialog] = useState(false);
  const [showIcdCodesDialog, setShowIcdCodesDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleNewIcdCode(values) {
    setIsSubmitting(true);
    try {
      await axios.post(`/api/v1/claims/${claimId}/involved_person/${involvedPerson.id}/icd_code`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }

    setIsSubmitting(false);
    setShowAddIcdCodeDialog(false);
  }

  async function handleIcdCodeDelete(involvedPersonIcdCodeId) {
    setIsSubmitting(true);
    try {
      await axios.delete(
        `/api/v1/claims/${claimId}/involved_person/${involvedPerson.id}/icd_code/${involvedPersonIcdCodeId}`
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }

    setIsSubmitting(false);
  }

  return (
    <>
      <ShowOnlyTextField
        classes={classes}
        label="ICD Codes"
        onEdit={() => setShowIcdCodesDialog(true)}
        doNotRenderInTypography
        showOnlyValueComponent={
          involvedPerson.icd_codes.length === 0 ? (
            <span className={classes.warningColor}>
              <strong>No ICD Codes</strong>
            </span>
          ) : (
            `${involvedPerson.icd_codes.length} ICD Code${involvedPerson.icd_codes.length > 1 ? 's' : ''}`
          )
        }
      />

      {showAddIcdCodeDialog && (
        <AddIcdCodeDialog
          currentIcdCodes={involvedPerson.icd_codes.map(({ icd_code }) => icd_code)}
          onSubmit={handleNewIcdCode}
          onClose={() => setShowAddIcdCodeDialog(false)}
        />
      )}

      {showIcdCodesDialog && (
        <IcdCodesEditDialog
          icdCodes={involvedPerson.icd_codes}
          onClose={() => setShowIcdCodesDialog(false)}
          onDelete={handleIcdCodeDelete}
          onClickAddNew={() => setShowAddIcdCodeDialog(true)}
          viewOnly={viewOnly}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}

IcdCodesFieldContainer.propTypes = {
  onUpdate: PropTypes.func,
  viewOnly: PropTypes.bool,
  involvedPerson: PropTypes.object.isRequired,
  claimId: PropTypes.number.isRequired,
};

function AddIcdCodeDialog({ currentIcdCodes, onSubmit, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        icd_code: '',
        icd_code_desc: '',
      }}
      validationSchema={Yup.object().shape({
        icd_code: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CardDialog title="Add Icd Code" isDialog maxWidth="sm" fullWidth onClose={onClose}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <IcdCodesAutoComplete
                currentIcdCodes={currentIcdCodes}
                icdCodeId="icd_code"
                icdCodeDescId="icd_code_desc"
              />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              Add
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

AddIcdCodeDialog.propTypes = {
  currentIcdCodes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function IcdCodesEditDialog({ icdCodes, onClickAddNew, onDelete, onClose, viewOnly, isSubmitting }) {
  return (
    <CardDialog
      title="ICD Codes"
      isDialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      action={
        !viewOnly && (
          <Button color="primary" onClick={onClickAddNew} disabled={viewOnly || icdCodes.length >= 19}>
            <AddIcon />
            Add Icd Code
          </Button>
        )
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.WARNING}
            withIcon
            note="ICD codes that start with the prefixes 'V', 'W', 'X' or 'Y' can not be used for Medicare reporting"
          />
        </Grid>
        {icdCodes.map(({ id, icd_code, icd_code_description }) => (
          <Fragment key={id}>
            <Grid item xs={10}>
              <Typography variant="body1" display="inline">
                &#9679;&nbsp;{icd_code} -{' '}
              </Typography>
              <Typography variant="body2" display="inline">
                {icd_code_description}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <MedicareWarning icdCode={icd_code} />
            </Grid>
            <Grid item xs={1}>
              {!viewOnly && (
                <WithConfirm
                  title="Delete Icd Code?"
                  primaryButtonName="Delete"
                  contentText="Are you sure you want to delete this ICD Code?"
                  shouldCloseOnPrimary
                >
                  <IconButton style={{ padding: '4px' }} onClick={() => onDelete(id)} disabled={isSubmitting}>
                    <TrashIcon />
                  </IconButton>
                </WithConfirm>
              )}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </CardDialog>
  );
}

IcdCodesEditDialog.propTypes = {
  icdCodes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickAddNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
};

function IcdCodesAutoComplete({ icdCodeId, icdCodeDescId, currentIcdCodes }) {
  const limitsWarningMessage = 'Only 100 ICD codes are displayed. Consider using more precise search.';

  const [possibleOptionsDict, setPossibleOptionsDict] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorDetected, setErrorDetected] = useState(false);
  const [isMoreThanLimit, setIsMoreThanLimit] = useState(false);

  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext();

  function getOptionLabel(option) {
    if (option === limitsWarningMessage) {
      return limitsWarningMessage;
    }

    if (!option || !possibleOptionsDict[option]) {
      return '';
    }

    return `${option} ${possibleOptionsDict[option].icd_code_desc}`;
  }

  function filterOption(options) {
    return options.filter((option) => !currentIcdCodes.includes(option));
  }

  async function handleInputChange(_event, newVal) {
    setInputValue(newVal);
    if (!newVal || newVal.length < 3) {
      setPossibleOptionsDict({});
      return;
    }

    setIsLoading(true);

    try {
      const results = await axios.get('/api/v1/claims/icd_codes_search', { params: { search: newVal } });
      setPossibleOptionsDict(results.data['icd_codes']);
      setIsMoreThanLimit(results.data['is_more_than_limit']);
    } catch (error) {
      if (!errorDetected) {
        reportAxiosError(error);
        setErrorDetected(true);
      }
    }

    setIsLoading(false);
  }

  const getOptions = () => {
    const options = Object.keys(possibleOptionsDict);

    if (isMoreThanLimit) {
      options.unshift(limitsWarningMessage);
    }

    return options;
  };

  return (
    <Autocomplete
      id="icd_code"
      options={getOptions()}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) => option === limitsWarningMessage}
      loading={isLoading}
      inputValue={inputValue}
      value={values[icdCodeId] || null}
      filterOptions={filterOption}
      onInputChange={handleInputChange}
      onChange={(e, newVal) => {
        setFieldValue(icdCodeId, newVal || '');
        setFieldValue(icdCodeDescId, (newVal && possibleOptionsDict[newVal].icd_code_desc) || '');
      }}
      renderInput={(params) => (
        <TextField
          label="ICD Code"
          {...params}
          InputProps={{
            ...params.InputProps,
            margin: 'dense', // to match other TextField components
          }}
          error={getIn(errors, icdCodeId) && getIn(touched, icdCodeId)}
          helperText={getIn(errors, icdCodeId) && getIn(touched, icdCodeId) && getIn(errors, icdCodeId)}
          onBlur={() => setFieldTouched(icdCodeId, true)}
        />
      )}
      clearOnBlur={false}
    />
  );
}

IcdCodesAutoComplete.propTypes = {
  icdCodeId: PropTypes.string.isRequired,
  icdCodeDescId: PropTypes.string.isRequired,
  currentIcdCodes: PropTypes.array.isRequired,
};

export default IcdCodesFieldContainer;
