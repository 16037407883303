import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { capitalize } from 'lodash';

import { useStyles } from '~/assets/styles';
import AssistanceAiBanner from '~/components/core/AssistanceAiBanner';
import AiButton from '~/components/core/Buttons/AiButton/AiButton';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import useInterval from '~/components/hooks/useInterval';
import COMMUNICATIONS_AI from '~/server_shared/generated-types/COMMUNICATIONS_AI';
import colors from '~/theme/tailwind/colors';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../../CardDialog';
import { Caption, Text } from '../../core';
import { useCms } from '../../hooks/useCms';
import { SmileyHappyIcon, SmileyNeutralIcon, SmileySadIcon } from '../../icons';
import useDataFetcher from '../../useDataFetcher';

const URGENCY_TEXT = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

const SENTIMENT_TEXT = {
  positive: 'Positive',
  neutral: 'Neutral',
  negative: 'Negative',
};
const iconMap = {
  positive: (props) => <SmileyHappyIcon iconColor={colors.green[700]} {...props} />,
  negative: (props) => <SmileySadIcon iconColor={colors.red[700]} {...props} />,
  neutral: (props) => <SmileyNeutralIcon iconColor={colors.slate[700]} {...props} />,
};

const TRANSCRIPT_PREVIEW_CHAR_LIMIT = 150;

const TYPE_TO_STYLE = {
  low: 'bg-green-700',
  medium: 'bg-yellow-700',
  high: 'bg-red-700',
};

const Dot = ({ type }) => {
  const dotClassnames = cn('w-[5px] h-[5px] rounded-full ml-10', TYPE_TO_STYLE[type]);
  return <div className={dotClassnames} />;
};

Dot.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPE_TO_STYLE)),
};

const SpeakerFormattedTranscript = ({ text }) => {
  const speakerRegex = /(?=Speaker \d+:)/i;

  const getSpeakerFormattedTranscript = (transcript) => {
    const splitText = transcript.split(speakerRegex);

    return splitText.map((text) => {
      const [speaker, extractedText] = text.split(/(?<=speaker \d+:)/i);
      return { speaker, extractedText: extractedText?.trim() };
    });
  };

  if (!text) {
    return null;
  }

  if (!speakerRegex.test(text)) {
    return (
      <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR} className="mt-5">
        {text}
      </Text>
    );
  }

  return getSpeakerFormattedTranscript(text).map((speaker) => (
    <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR} key={speaker.extractedText} className="mt-5">
      <span className="font-bold">{speaker.speaker}</span> <span>{speaker.extractedText}</span>
    </Text>
  ));
};

SpeakerFormattedTranscript.propTypes = {
  text: PropTypes.string.isRequired,
};

const DataRowText = ({ text, title }) => {
  const classes = useStyles();

  if (title.toLowerCase() === 'urgency') {
    return (
      <Chip icon={<Dot type={text} />} label={URGENCY_TEXT[text]} size="small" className={cn(classes.chip, 'my-0')} />
    );
  }

  if (title.toLowerCase() === 'sentiment') {
    const SentimentIcon = iconMap[text];
    return (
      <Chip
        icon={<SentimentIcon size={16} className="baseline inline-flex text-sm" />}
        label={SENTIMENT_TEXT[text]}
        size="small"
        className={cn(classes.chip, 'my-0')}
      />
    );
  }
  if (title.toLowerCase() === 'transcript') {
    return (
      <div className={cn(text.length > TRANSCRIPT_PREVIEW_CHAR_LIMIT && 'line-clamp-3')}>
        <SpeakerFormattedTranscript text={text} />
      </div>
    );
  }
  return (
    <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR} className="inline">
      {text}
    </Text>
  );
};

DataRowText.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const DataRow = ({ title, text, textHidden = false }) => {
  return (
    <div className="mt-5">
      <Caption variant={Caption.VARIANTS.SEMI_BOLD} className="inline py-5">
        {title}:{' '}
      </Caption>
      <DataRowText text={text} title={title} textHidden={textHidden} />
    </div>
  );
};

DataRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textHidden: PropTypes.bool,
};

const CommunicationsAiInsight = ({ communicationId }) => {
  const { userOrganization } = useCms();

  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/api/v1/generative_ai/${userOrganization.id}/communications/${communicationId}/communication_insight`
  );
  const isStatusInProgress = data?.status === COMMUNICATIONS_AI.status.IN_PROGRESS;
  useInterval(reloadData, isStatusInProgress ? 30000 : null);

  const [transcriptModalOpen, setTranscriptModalOpen] = useState(false);

  if (isStatusInProgress) {
    return (
      <AssistanceAiBanner
        title="AI Assistant"
        subTitle="is working on creating insights for you"
        size={AssistanceAiBanner.SIZE_VARIANTS.SM}
        iconSize={20}
        weight={AssistanceAiBanner.WEIGHTS.MEDIUM}
        hasBackground
        className="my-20"
      />
    );
  }
  const allFieldsEmpty =
    data &&
    !data.urgency &&
    !data.sentiment &&
    !data.title &&
    !data.summary &&
    !data.suggested_action &&
    !data.transcription;

  if (!data || isError || isLoading || allFieldsEmpty) {
    return null;
  }

  const transcriptLength = data.transcription?.length;

  return (
    <div className="my-20">
      <CollapsibleWrapper
        titleTag={
          <AssistanceAiBanner
            title="AI Assistant"
            subTitle="generated insights for you"
            size={AssistanceAiBanner.SIZE_VARIANTS.SM}
            iconSize={20}
            weight={AssistanceAiBanner.WEIGHTS.MEDIUM}
          />
        }
        actionCard
        noBackground
        borderColor={CollapsibleWrapper.BORDER_COLOR.HIGHLIGHT}
        defaultState={true}
      >
        <div className="px-25">
          {data.urgency ? <DataRow title="Urgency" text={data.urgency} /> : null}
          {data.sentiment ? <DataRow title="Sentiment" text={data.sentiment} /> : null}
          {data.title ? <DataRow title="Title" text={capitalize(data.title)} /> : null}
          {data.summary ? <DataRow title="Summary" text={capitalize(data.summary)} /> : null}
          {data.suggested_action ? <DataRow title="Suggested Action" text={capitalize(data.suggested_action)} /> : null}
          {data.transcription ? (
            <>
              <DataRow title="Transcript" text={data.transcription} />
              {transcriptLength > TRANSCRIPT_PREVIEW_CHAR_LIMIT ? (
                <AiButton onClick={() => setTranscriptModalOpen(true)}>Show Full Transcript</AiButton>
              ) : null}
            </>
          ) : null}
        </div>
      </CollapsibleWrapper>
      {transcriptModalOpen ? (
        <CardDialog
          isDialog={true}
          title={
            <AssistanceAiBanner
              title="AI Assistant Transcript"
              size={AssistanceAiBanner.SIZE_VARIANTS.SM}
              iconSize={20}
              weight={AssistanceAiBanner.WEIGHTS.MEDIUM}
            />
          }
          fullWidth
          open={transcriptModalOpen}
          maxWidth="sm"
          onClose={() => {
            setTranscriptModalOpen(false);
          }}
        >
          <div className="max-h-[80%] overflow-y-scroll rounded-md border-1 border-solid border-slate-600 px-24 py-15">
            <SpeakerFormattedTranscript text={data.transcription} />
          </div>
          <div className="mt-25 text-right">
            <AiButton onClick={() => setTranscriptModalOpen(false)}>Close</AiButton>
          </div>
        </CardDialog>
      ) : null}
    </div>
  );
};

CommunicationsAiInsight.propTypes = {
  communicationId: PropTypes.number.isRequired,
};

// TODO: Remove when removing FF
const CommunicationsAiInsightFFWrapper = (props) => {
  const { userOrganization } = useCms();

  if (!isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_INSIGHT_AI)) {
    return null;
  }
  return <CommunicationsAiInsight {...props} />;
};
export default CommunicationsAiInsightFFWrapper;
