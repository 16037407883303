import React, { Fragment, useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, InputLabel, Link, ListItemText } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import LocationIcon from '@material-ui/icons/LocationOn';
import NoteIcon from '@material-ui/icons/Note';
import axios from 'axios';
import { FieldArray, Formik, getIn, useFormikContext } from 'formik';
import iban from 'iban';
import _, { get, isEmpty, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import ContactMiniCard from '~/components/Contacts/ContactMiniCard';
import IdentityHeader from '~/components/Contacts/ContactMiniCard/IdentityHeader';
import IdentitySubHeader from '~/components/Contacts/ContactMiniCard/IdentitySubHeader';
import { CreateContactDialog } from '~/components/Contacts/UpsertContact/Create/CreateContactDialog';
import { EditContactDialog } from '~/components/Contacts/UpsertContact/Edit/EditContactDialog';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { FsIconButton } from '~/components/core/FsWrappers';
import { RestrictedPermissions, useRestrictedPermissions } from '~/components/core/Permissions/RestrictedPermissions';
import useCccAppraiser from '~/components/hooks/useCccAppraiser';
import { useHasPermission, useSanitizeByPermissions } from '~/components/hooks/useHasPermission';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import {
  capitalize,
  getExposuresLabels,
  getPhoneValidationMessageError,
  getPostalCodeError,
  isFeatureEnabled,
  isLocaleRegionIsUs,
  isPostalCodeValid,
  isQoverUser,
  reportAxiosError,
  reportErrorInProductionOrThrow,
} from '~/Utils';
import cn from '~/Utils/cn';

import { getLocalDateToday, isoDateToUs } from '../DateTimeUtils';
import {
  CONFIGURATION_FEATURES_NAMES,
  CONTACT_ADDRESS_CHANGE_REASONS,
  CONTACT_EMAIL_CHANGE_REASONS,
  CONTACT_IDENTITY_CHANGE_REASONS,
  CONTACT_PHONE_CHANGE_REASONS,
  LANGUAGES_DICT,
  MEDICAL_BILL_PROVIDER_PROFESSIONS,
  REPORT_TYPES_1099,
} from '../Types';

import { displayAttorneyInformation, isTinRequired, isVatEnabled } from './communications/ContactUtils';
import { ErrorHelperTextFormik } from './core/Formik/ErrorHelperTextFormik';
import PermissionsButtonWrapper from './core/Permissions/PermissionsButtonWrapper';
import PermissionsRenderWrapper from './core/Permissions/PermissionsRenderWrapper';
import DocumentTextFieldFormik from './Documents/DocumentTextFieldFormik';
import { useCms } from './hooks/useCms';
import useGlobalAddresses from './hooks/useGlobalAddresses';
import useXactAnalysisContractor, {
  isXactAnalysisIntegrationEnabled,
  splitXactAnalysisContractorPayload,
  updateXactAnalysisContractor,
} from './hooks/useXactAnalysisContractor';
import { EmailIcon, PhoneIcon } from './icons/notifications';
import AutocompleteFormik from './AutocompleteFormik';
import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { useClaim } from './ClaimContainer';
import { WithConfirmFormik } from './ConfirmModal';
import { contactPopulate, shouldCheckForCommunicationPermissions } from './ContactUtils';
import CountryAutocompleteFormik from './CountryAutocompleteFormik';
import { useCurrencyFormatter } from './CurrencyFormatterContext';
import FileDropZone from './FileDropZone';
import { StateFieldFormik } from './GlobalLossLocation';
import { GovernmentIdFieldFormik, governmentIdValidationSchema } from './GovernmentId';
import HoverActionField from './HoverActionField';
import { ContactIcon } from './icons';
import useOrganization from './OrganizationContext';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';
import RadioButtonFormik from './RadioButtonFormik';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  usePrevious,
} from './TextFieldFormik';
import UsStatesSelectTextFieldFormik from './UsStatesSelectTextFieldFormik';

const contactNameValidate = (values) => {
  let errors = {};

  if (values['is_company']) {
    // company - only organization is required
    if (!values['company_name'].trim()) {
      errors['company_name'] = 'Required';
    }
  } else {
    // individual
    if (!values['first_name'].trim()) {
      errors['first_name'] = 'Required';
    }

    if (!values['last_name'].trim()) {
      errors['last_name'] = 'Required';
    }
  }

  return errors;
};

const multiClaimOrganizationRoles = (organizationContactRolesDict) =>
  Object.keys(organizationContactRolesDict).filter((key) => organizationContactRolesDict[key]?.is_multi_claim);

const contactIdentityValidate = (values, validateContactName) => {
  let errors = {};
  if (!values['role']) {
    errors['role'] = 'Required';
  }

  if (validateContactName) {
    errors = { ...errors, ...contactNameValidate(values) };
  }

  if (values['iban'] && !iban.isValid(values['iban'])) {
    errors['iban'] = 'Invalid IBAN';
  }

  if (
    values['uk_sort_code'] &&
    !/^\d{2}-\d{2}-\d{2}$/.test(values['uk_sort_code']) &&
    !/^\d{6}$/.test(values['uk_sort_code'])
  ) {
    errors['uk_sort_code'] = 'Sort code must be of the form 20-00-00 or 200000';
  }

  if (values['uk_bank_account_number'] && !/^\d{8}$/.test(values['uk_bank_account_number'])) {
    errors['uk_bank_account_number'] = 'Account number must be 8 digits';
  }

  if (values['date_of_birth'] === 'Invalid date') {
    errors['date_of_birth'] = 'Invalid date';
  }

  if (values['attorney_bar_number'] && isLocaleRegionIsUs() && !values['attorney_bar_state']) {
    errors['attorney_bar_state'] = 'Required';
  }

  if (values['is_moi_service_provider'] && isEmpty(values['moi_expertises'])) {
    errors['moi_expertises'] = 'At least one expertise should be selected';
  }

  if (values['is_xactanalysis_contractor']) {
    if (isEmpty(values['carrier_id'])) {
      errors['carrier_id'] = 'Required';
    }
    if (isEmpty(values['xactnet_address'])) {
      errors['xactnet_address'] = 'Required';
    }
  }

  return errors;
};

const contactAddressValidate = (values) => {
  let errors = {};

  if (values['zipcode']) {
    if (!isPostalCodeValid(values['zipcode'], values.country)) {
      errors['zipcode'] = getPostalCodeError(values.country);
    }
  }

  return errors;
};

const contactEmailsValidate = (values) => {
  let errors = {};
  let isError = false;

  errors['emails'] = [];
  for (const emailIdx in values['emails'].filter((email) => email.email)) {
    errors['emails'][emailIdx] = {};

    try {
      Yup.string().max(256).trim().email().validateSync(values['emails'][emailIdx]['email']);
    } catch (validationError) {
      errors['emails'][emailIdx]['email'] = validationError.message;
      isError = true;
    }

    try {
      Yup.string().max(32).nullable().validateSync(values['emails'][emailIdx]['description']);
    } catch (validationError) {
      errors['emails'][emailIdx]['description'] = validationError.message;
      isError = true;
    }
  }

  if (!isError) {
    return {};
  }

  return errors;
};

const contactPhonesValidate = (values) => {
  let errors = {};
  let isError = false;

  for (const phone_type of ['mobile', 'home', 'work', 'fax']) {
    errors[`${phone_type}_phones`] = [];
    if (!values[`${phone_type}_phones`]) {
      continue;
    }
    for (const phoneIdx in values[`${phone_type}_phones`].filter((phone) => phone.phone)) {
      const errorMsg = getPhoneValidationMessageError(values[`${phone_type}_phones`][phoneIdx]['phone']);
      errors[`${phone_type}_phones`][phoneIdx] = {};

      if (errorMsg) {
        errors[`${phone_type}_phones`][phoneIdx]['phone'] = errorMsg;
        isError = true;
      }

      try {
        Yup.string().max(32).nullable().validateSync(values[`${phone_type}_phones`][phoneIdx]['description']);
      } catch (validationError) {
        errors[`${phone_type}_phones`][phoneIdx]['description'] = validationError.message;
        isError = true;
      }
    }
  }

  if (!isError) {
    return {};
  }

  return errors;
};

const contactValidate = (
  values,
  validateContactName,
  requireAskCommunicationPrivileges = false,
  organization = null // Required if requireAskCommunicationPrivileges=true
) => {
  // We use the Formik validate props instead of validationSchema since it's currently easier, consider moving back
  let errors = {};

  errors = { ...errors, ...contactIdentityValidate(values, validateContactName) };

  errors = { ...errors, ...contactPhonesValidate(values) };
  errors = { ...errors, ...contactEmailsValidate(values) };

  if (values.claim_id && values['exposure_ids'].length === 0) {
    errors['exposure_ids'] = 'Required';
  }

  if (requireAskCommunicationPrivileges && !organization) {
    reportErrorInProductionOrThrow(
      'contactValidate::requireAskCommunicationPrivileges==True organization must be specified'
    );
  }

  if (
    requireAskCommunicationPrivileges &&
    shouldCheckForCommunicationPermissions(organization, values.is_company, values.role)
  ) {
    if (
      existsValidItemInArray(values['emails'], 'email') &&
      (values['is_emailing_allowed'] === null || values['is_emailing_allowed'] === '')
    ) {
      errors['is_emailing_allowed'] = 'Required';
    }

    if (
      existsValidItemInArray(values['phones'], 'phone') &&
      (values['is_smsing_allowed'] === null || values['is_smsing_allowed'] === '')
    ) {
      errors['is_smsing_allowed'] = 'Required';
    }
  }

  errors = { ...errors, ...contactAddressValidate(values) };

  return errors;
};

const existsValidItemInArray = (items, keyId) => {
  for (const itemIdx in items) {
    if (items[itemIdx][keyId]) {
      return true;
    }
  }

  return false;
};

const standardizeContactValues = (values) => {
  // TODO: not exactly compliant with JSON Merge Patch - leave the removed ids and send them with a null value
  // remove empty multi-values
  let stdValues = {
    ...values,
    emails: values['emails'].filter((email) => email.email),
    phones: undefined,
    mobile_phones: values['mobile_phones'].filter((phone) => phone.phone),
    home_phones: values['home_phones'].filter((phone) => phone.phone),
    work_phones: values['work_phones'].filter((phone) => phone.phone),
    fax_phones: values['fax_phones'].filter((phone) => phone.phone),
  };

  // if role is company, remove un-related values
  if (values['is_company']) {
    stdValues = {
      ...stdValues,
      title: '',
      first_name: '',
      middle_name: '',
      last_name: '',
    };
  } else {
    stdValues = {
      ...stdValues,
      company_name: '',
    };
  }

  if (values['is_smsing_allowed'] === '') {
    stdValues['is_smsing_allowed'] = undefined;
  }

  if (values['is_emailing_allowed'] === '') {
    stdValues['is_emailing_allowed'] = undefined;
  }

  return stdValues;
};

const spacing = 1;

function MultiValueFragment({ label, valueFieldName, objectsArrName, isPhoneType, ...restProps }) {
  const { user } = useCms();
  const { values, setFieldValue } = useFormikContext();
  const { showOnly } = restProps;
  const objectsArr = values[objectsArrName];

  const shouldShowDelete = objectsArr.length > 1 && !showOnly;

  return (
    <FieldArray
      name={objectsArrName}
      render={({ remove, push }) => (
        <Fragment>
          {objectsArr.map((item, idx) => {
            const shouldShowAdd = idx === objectsArr.length - 1 && !showOnly;
            return (
              <Fragment key={item.id}>
                <Grid item xs={12}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1, paddingBottom: '4px' }}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextFieldFormik
                            {...restProps}
                            id={`${objectsArrName}.${idx}.${valueFieldName}`}
                            label={label}
                            {...(isPhoneType && isQoverUser(user)
                              ? {
                                  onChange: (e) =>
                                    onChangeTextFieldAlwaysAddPlus(
                                      e,
                                      setFieldValue,
                                      `${objectsArrName}.${idx}.${valueFieldName}`
                                    ),
                                }
                              : null)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldFormik
                            {...restProps}
                            id={`${objectsArrName}.${idx}.description`}
                            label={`${label} Description`}
                            disabled={!values[`${objectsArrName}`][`${idx}`][`${valueFieldName}`]}
                          />
                        </Grid>
                      </Grid>
                    </span>
                    {shouldShowDelete && (
                      <IconButton onClick={() => remove(idx)}>
                        <CancelIcon />
                      </IconButton>
                    )}
                    {shouldShowAdd && (
                      <IconButton
                        onClick={() => push({ [valueFieldName]: '', id: `new_${uuidv4()}`, description: '' })}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </span>
                </Grid>
              </Fragment>
            );
          })}
        </Fragment>
      )}
    />
  );
}

MultiValueFragment.propTypes = {
  label: PropTypes.string.isRequired,
  valueFieldName: PropTypes.string.isRequired,
  objectsArrName: PropTypes.string.isRequired,
  isPhoneType: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function TextFieldIcon(props) {
  const { iconNode, noFormControl } = props;
  if (noFormControl) {
    return <InputLabel style={{ paddingBottom: '12px', paddingTop: '12px' }}>{iconNode}</InputLabel>;
  }
  return (
    <FormControl>
      <InputLabel>{iconNode}</InputLabel>
    </FormControl>
  );
}

TextFieldIcon.propTypes = {
  iconNode: PropTypes.node.isRequired,
  noFormControl: PropTypes.bool,
};

function ContactIdentityFragment({ disabled, disableEditingRole, acceptedRoles }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const { organizationContactRolesDict, organizationMoiExpertises } = useOrganization();
  const { isXactAnalysisEnabled, isContractorLoading } = useXactAnalysisContractor(values?.id);
  const { isUkBankTransferEnabledForAny, isIbanBankTransferEnabledForAny } = usePaymentsConfiguration();

  const isMedicalBillProviderProfessionFieldFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MEDICAL_BILL_PROVIDER_PROFESSION_FIELD
  );

  const { isCccEnabled, cccAppraiserCode } = useCccAppraiser(values?.id);

  const handleIsMoiServiceProvider = (isMoiServiceProvider) => {
    setFieldValue('is_moi_service_provider', isMoiServiceProvider);
    setFieldValue('moi_expertises', []);
  };

  function handleIsCompanyChange(isCompany) {
    if (values['is_company'] === isCompany) {
      return;
    }

    if (isCompany) {
      const value = [values['first_name'], values['middle_name'], values['last_name']]
        .filter((name) => !!name)
        .join(' ');
      setFieldValue('company_name', value);
    } else {
      let companyNameSplit = values['company_name'].split(' ');
      setFieldValue('last_name', companyNameSplit.pop());
      setFieldValue('middle_name', '');
      setFieldValue('first_name', companyNameSplit.join(' '));
    }

    setFieldValue('is_company', isCompany);
  }

  let contactRolesList = getSupportedContactRolesTypes(
    organizationContactRolesDict,
    acceptedRoles,
    claim ? [claim.lob] : userOrganization.supported_claim_types
  );
  const multiClaimRoles = multiClaimOrganizationRoles(organizationContactRolesDict);
  const onRoleSelect = (role) => {
    setFieldValue('role', role);

    if (role && !multiClaimRoles.includes(role)) {
      handleIsMoiServiceProvider(false);
    }
  };
  let sortedRoles = contactRolesList.filter((contactRole) => !organizationContactRolesDict[contactRole].is_system);
  sortedRoles.sort((a, b) => (organizationContactRolesDict[a].desc > organizationContactRolesDict[b].desc ? 1 : -1));

  return (
    <>
      <Grid item xs={6}>
        <TextFieldFormik
          id="role"
          label="Role"
          select
          fullWidth
          showOnly={acceptedRoles.length === 1 || (values.role && disableEditingRole) || values.is_policy_contact}
          onChange={(event) => onRoleSelect(event.target.value)}
        >
          {sortedRoles.map((contactRole) => (
            <MenuItem key={contactRole} value={contactRole}>
              {organizationContactRolesDict[contactRole].desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6} />
      {values['role'] === 'other' && (
        <Grid item xs={12}>
          <TextFieldFormik
            id="role_other_desc"
            label="Role Description"
            fullWidth
            showOnly={values.is_policy_contact}
          />
        </Grid>
      )}
      {isMedicalBillProviderProfessionFieldFeatureEnabled && values.role === 'medical_bill_provider' && (
        <Grid item xs={12}>
          <AutocompleteFormik
            id="contact_extra.profession"
            label="Profession"
            options={Object.keys(MEDICAL_BILL_PROVIDER_PROFESSIONS)}
            getOptionLabel={(key) => MEDICAL_BILL_PROVIDER_PROFESSIONS[key].desc}
            sortAlphabetic
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CheckboxFormik
          id="is_company"
          label="Contact is a company"
          fullWidth
          disabled={disabled}
          onChange={(event) => handleIsCompanyChange(event.target.checked)}
        />
      </Grid>
      <>
        <Grid item xs={12}>
          <CheckboxFormik
            id="is_moi_service_provider"
            label="Contact is an MOI Service Provider"
            fullWidth
            disabled={disabled || isEmpty(values.role) || !multiClaimRoles?.includes(values.role)}
            onChange={(event) => handleIsMoiServiceProvider(event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <MultiSelectTextFieldFormik
            id="moi_expertises"
            label="Select Expertise"
            disabled={disabled || !values['is_moi_service_provider']}
            className={classes.textField}
            fullWidth
            renderOption={(option) => organizationMoiExpertises[option]['desc']}
            options={Object.keys(organizationMoiExpertises).sort()}
            renderValue={() => ''}
            withOptionChips
            style={{ paddingBottom: '14px' }}
          />
        </Grid>
        <Grid item xs={6} />
        {isXactAnalysisEnabled && (
          <>
            <Grid item xs={12}>
              <CheckboxFormik
                id="is_xactanalysis_contractor"
                label="Contact is an XactAnalysis Dataset"
                fullWidth
                disabled={disabled || !values?.is_moi_service_provider || isContractorLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="carrier_id"
                label="XactAnalysis Dataset Carrier ID"
                fullWidth
                disabled={disabled || !values?.is_xactanalysis_contractor || !values?.is_moi_service_provider}
                value={values?.carrier_id || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="xactnet_address"
                label="Recipient XactNet Address"
                fullWidth
                disabled={disabled || !values?.is_xactanalysis_contractor || !values?.is_moi_service_provider}
                value={values?.xactnet_address || ''}
              />
            </Grid>
          </>
        )}
        {isCccEnabled && cccAppraiserCode && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="ccc_appraiser_code"
              label="CCC Appraiser Code"
              fullWidth
              disabled
              value={cccAppraiserCode || ''}
            />
          </Grid>
        )}
      </>

      {values['is_company'] ? (
        <Grid item xs={12}>
          <TextFieldFormik id="company_name" label="Name" fullWidth disabled={disabled} />
        </Grid>
      ) : (
        <>
          <Grid item xs={isLocaleRegionIsUs() ? 4 : 6}>
            <TextFieldFormik id="first_name" label="First Name" fullWidth disabled={disabled} />
          </Grid>
          {isLocaleRegionIsUs() && (
            <Grid item xs={4}>
              <TextFieldFormik id="middle_name" label="Middle Name" fullWidth disabled={disabled} />
            </Grid>
          )}
          <Grid item xs={isLocaleRegionIsUs() ? 4 : 6}>
            <TextFieldFormik id="last_name" label="Last Name" fullWidth disabled={disabled} />
          </Grid>
        </>
      )}
      {!values['is_company'] && (
        <>
          <Grid item xs={6}>
            <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT_PII} verb={PERMISSION_VERBS.WRITE}>
              <DatePickerTextFieldFormik id="date_of_birth" label="Date of Birth" fullWidth disabled={disabled} />
            </PermissionsButtonWrapper>
          </Grid>
          <Grid item xs={6}>
            <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
              <RadioButtonFormik id="sex" label="Male" optionValue="Male" disabled={disabled} />
              <RadioButtonFormik id="sex" label="Female" optionValue="Female" disabled={disabled} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT_PII} verb={PERMISSION_VERBS.WRITE}>
              <GovernmentIdFieldFormik
                id="government_id"
                value={get(values, 'government_id', '')}
                disabled={disabled}
              />
            </PermissionsButtonWrapper>
          </Grid>
          <Grid item xs={12}>
            <CheckboxFormik id="is_deceased" label="Contact is deceased" fullWidth disabled={disabled} />
          </Grid>
        </>
      )}
      {values['role'] && displayAttorneyInformation(values['role']) && (
        <>
          {isLocaleRegionIsUs() && (
            <Grid item xs={6}>
              <UsStatesSelectTextFieldFormik id="attorney_bar_state" label="State Bar" fullWidth disabled={disabled} />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextFieldFormik
              id="attorney_bar_number"
              label={`${isLocaleRegionIsUs() ? 'Bar' : 'SRA'} Number`}
              fullWidth
              disabled={disabled}
            />
          </Grid>
        </>
      )}
      {isVatEnabled(values) && (
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography display="inline">VAT Registered </Typography>
          </Grid>
          <Grid item xs={4}>
            <RadioButtonFormik id="is_vat_registered" label="No" optionValue={false} disabled={disabled} />
            <RadioButtonFormik id="is_vat_registered" label="Yes" optionValue={true} disabled={disabled} />
          </Grid>
          {values.is_vat_registered ? (
            <Grid item xs={4}>
              <TextFieldFormik id="vat_number" label="VAT Number" fullWidth disabled={disabled} />
            </Grid>
          ) : (
            <Grid item xs={4} />
          )}
        </Grid>
      )}

      {isIbanBankTransferEnabledForAny && (
        <>
          <Grid item xs={6}>
            <TextFieldFormik id="language" label="Language" fullWidth disabled={disabled} select>
              {['fr', 'nl', 'en', 'de', 'it', 'es', 'pt', 'pl', 'ga', 'sv', 'no', 'fi', 'da'].map((language) => (
                <MenuItem key={language} value={language}>
                  {LANGUAGES_DICT[language]}
                </MenuItem>
              ))}

              <MenuItem value="other">Other</MenuItem>
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextFieldFormik id="iban" label="IBAN" fullWidth disabled={disabled} />
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      {isUkBankTransferEnabledForAny && (
        <>
          <Grid item xs={6}>
            <TextFieldFormik id="uk_sort_code" label="Sort Code" fullWidth disabled={disabled} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik id="uk_bank_account_number" label="Account Number" fullWidth disabled={disabled} />
          </Grid>
        </>
      )}
    </>
  );
}

ContactIdentityFragment.propTypes = {
  disabled: PropTypes.bool,
  disableEditingRole: PropTypes.bool,
  acceptedRoles: requiredIf(PropTypes.array, (props) => !props.disabled),
};

function ContactHoverChangeField(props) {
  const { children, onChange, validationSchema, name, isNew } = props;
  const classes = useStyles();
  const [changeFieldPopoverAnchorEl, setChangeFieldPopoverAnchorEl] = React.useState(undefined);
  const { values } = useFormikContext();

  const childrenShowOnlyWithEdit = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      showOnly: true,
      onEdit: (e) => setChangeFieldPopoverAnchorEl(e.currentTarget),
    })
  );

  const childrenEdit = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      showOnly: false,
    })
  );

  if (isNew) {
    return childrenEdit;
  }

  return (
    <>
      {childrenShowOnlyWithEdit}
      <Formik
        initialValues={{ [name]: values[name] }}
        validationSchema={validationSchema ? Yup.object().shape({ [name]: validationSchema }) : undefined}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          try {
            await onChange(values);
            setChangeFieldPopoverAnchorEl(undefined);
          } catch (error) {
            formikProps.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => (
          <CardDialog
            noCardTitle
            isPopover
            closeOnBackdropClick
            open={Boolean(changeFieldPopoverAnchorEl)}
            anchorEl={changeFieldPopoverAnchorEl}
            onClose={() => setChangeFieldPopoverAnchorEl(undefined)}
            width="350px"
          >
            {childrenEdit}
            <div className={classes.buttonsContainer}>
              <CancelButton
                disabled={formikProps.isSubmitting}
                onClick={() => {
                  formikProps.handleReset();
                  setChangeFieldPopoverAnchorEl(undefined);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
              >
                Update
              </Button>
            </div>
          </CardDialog>
        )}
      </Formik>
    </>
  );
}

ContactHoverChangeField.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  validationSchema: PropTypes.object,
  onChange: requiredIf(PropTypes.func, (props) => !props.isNew),
  // width: PropTypes.string,
  isNew: PropTypes.bool,
};

function ExistingCommunicationWarningDialog(props) {
  const { contactsWithSameComm, commType, commValue, isSubmitting, onCancel, onCreateDuplicate } = props;
  const classes = useStyles();
  const valueDesc = commType === 'phone' ? 'phone number' : 'email address';
  const { organizationContactRolesDict } = useOrganization();

  return (
    <CardDialog isDialog title={`${capitalize(valueDesc)} already exists`}>
      <div className={classes.textField}>
        {`The ${valueDesc} you have entered - ${commValue} - already exists in the system and is listed for the following contact${
          contactsWithSameComm.length > 1 ? 's' : ''
        }:`}
      </div>

      {contactsWithSameComm.map((contact) => (
        <div key={contact.id} style={{ display: 'flex', alignItems: 'center' }}>
          <ContactEntity contactId={contact.id} hideDisplayName />
          <span>
            {contact.full_name}
            <em>{` - ${organizationContactRolesDict[contact.role]['desc']}`}</em>
          </span>
        </div>
      ))}

      <div className={classes.textField}>
        {`Listing the same ${valueDesc} for multiple contacts may cause problems with proper classification of incoming communications. Are you sure you want to create a duplicate address?`}
      </div>

      <div className={classes.buttonsContainer}>
        <Button
          className={classes.leftButtonDialog}
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
          onClick={onCreateDuplicate}
        >
          Create Duplicate
        </Button>
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={onCancel}>
          Return to previous dialog
        </Button>
      </div>
    </CardDialog>
  );
}

ExistingCommunicationWarningDialog.propTypes = {
  contactsWithSameComm: PropTypes.array.isRequired,
  commType: PropTypes.string.isRequired,
  commValue: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onCreateDuplicate: PropTypes.func.isRequired,
};

function ContactCommunicationChangeDialog(props) {
  const { contact, action, commValue, commLabel, type, onChange, onCancel } = props;

  const [contactsWithSameComm, setContactsWithSameComm] = React.useState();

  const classes = useStyles();
  const { user } = useCms();
  const label = capitalize(type);
  const title = `${capitalize(action)} Contact ${label}`;
  const isPhoneType = type === 'phone';

  const CONTACT_COMMUNICATION_CHANGE_REASONS =
    type === 'phone' ? CONTACT_PHONE_CHANGE_REASONS : CONTACT_EMAIL_CHANGE_REASONS;

  const shouldAskReason = (values) => {
    if (action === 'delete') return true;

    if (action === 'new') return false;

    // action === 'edit', ask for reason only if comm changed, not commLabel
    if (values['comm_value'] !== commValue) {
      return true;
    }
  };

  return (
    <Formik
      initialValues={{
        comm_value: action !== 'new' ? commValue : '',
        comm_label: action !== 'new' ? commLabel : '',
        reason: '',
        explanation: '',
        comm_description: '',
      }}
      validate={(values) => {
        let errors = {};
        if (action !== 'delete') {
          if (!values['comm_value']) {
            errors['comm_value'] = 'Required';
          } else {
            if (type === 'email') {
              try {
                Yup.string().max(256).trim().email().required().validateSync(values['comm_value']);
              } catch (validationError) {
                errors['comm_value'] = validationError.message;
              }
            }
            if (type === 'phone') {
              let errorMsg = 'Invalid phone number';
              try {
                Yup.string()
                  .max(32)
                  .test('is-possible-phone', errorMsg, function test(phone) {
                    if (action === 'delete') {
                      // allow delete invalid number
                      return true;
                    }

                    errorMsg = getPhoneValidationMessageError(phone);
                    return !errorMsg;
                  })
                  .validateSync(values['comm_value']);
              } catch (validationError) {
                errors['comm_value'] = errorMsg;
              }
            }
          }
        }

        try {
          Yup.string().max(32).nullable().validateSync(values['comm_description']);
        } catch (validationError) {
          errors['comm_description'] = validationError.message;
        }

        if (shouldAskReason(values)) {
          if (!values['reason']) {
            errors['reason'] = 'Required';
          }
          if (values['reason'] === 'other' && !values['explanation']) {
            errors['explanation'] = 'Required';
          }
        }
        return errors;
      }}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          // if new, verify the comm doesn't exist in a different contact
          // if !!contactsWithSameComm, we already verified but user requested to create duplicate
          if (action === 'new' && !contactsWithSameComm) {
            const existingContactCommQueryRes = await axios.get(`/api/v1/contacts/${type}s`, {
              params: { [type]: values.comm_value },
            });
            if (existingContactCommQueryRes.data[`contact_${type}s`].length > 0) {
              setContactsWithSameComm(existingContactCommQueryRes.data.contacts);
              return;
            }
          }
          await onChange(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <>
          <CardDialog
            isDialog
            title={title}
            open
            onClose={onCancel}
            preventClose={isSubmitting}
            maxWidth="xs"
            fullWidth
          >
            {isPhoneType && (
              <TextFieldFormik
                id="comm_label"
                label={`${label} Type`}
                fullWidth
                select
                disabled={isSubmitting || action === 'delete'}
              >
                {['home', 'mobile', 'work', 'fax'].map((possibleLabel) => (
                  <MenuItem key={possibleLabel} value={possibleLabel}>
                    {capitalize(possibleLabel)}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            )}
            <div className={classes.textField}>
              <TextFieldFormik
                id="comm_value"
                label={label}
                fullWidth
                disabled={isSubmitting || action === 'delete'}
                {...(isPhoneType &&
                  isQoverUser(user) && {
                    onChange: (e) => onChangeTextFieldAlwaysAddPlus(e, setFieldValue, 'comm_value'),
                  })}
              />
            </div>
            <div className={classes.textField}>
              <TextFieldFormik
                id="comm_description"
                label="Description"
                fullWidth
                disabled={isSubmitting || action === 'delete'}
              />
            </div>
            {shouldAskReason(values) && (
              <div className={classes.textField}>
                <TextFieldFormik
                  id="reason"
                  select
                  label="Reason"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                >
                  {Object.keys(CONTACT_COMMUNICATION_CHANGE_REASONS)
                    .filter((reason) => reason !== 'other')
                    .map((reason) => (
                      <MenuItem key={reason} value={reason}>
                        {CONTACT_COMMUNICATION_CHANGE_REASONS[reason]['desc']}
                      </MenuItem>
                    ))}
                  <MenuItem value="other">Other</MenuItem>
                </TextFieldFormik>
              </div>
            )}
            {shouldAskReason(values) && values['reason'] === 'other' && (
              <div className={classes.textField}>
                <TextFieldFormik id="explanation" label="Explanation" fullWidth disabled={isSubmitting} />
              </div>
            )}
            <div className={classes.buttonsContainer}>
              <WithConfirmFormik
                title="Are you sure?"
                contentText={`Are you sure you want to change the contact details for ${contact.full_name}? This change will take effect everywhere this contact is referenced, across all claims this contact belongs to`}
                primaryButtonName="Save"
                shouldCloseOnPrimary={true}
                disabled={action === 'new'}
              >
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </WithConfirmFormik>
            </div>
          </CardDialog>
          {contactsWithSameComm && (
            <ExistingCommunicationWarningDialog
              contactsWithSameComm={contactsWithSameComm}
              commType={type}
              commValue={values.comm_value}
              isSubmitting={isSubmitting}
              onCreateDuplicate={handleSubmit}
              onCancel={() => setContactsWithSameComm(undefined)}
            />
          )}
        </>
      )}
    </Formik>
  );
}

ContactCommunicationChangeDialog.propTypes = {
  contact: PropTypes.object.isRequired,
  action: PropTypes.oneOf(['new', 'edit', 'delete']).isRequired,
  commValue: requiredIf(PropTypes.string, (props) => props.action !== 'new'),
  commLabel: requiredIf(PropTypes.string, (props) => props.type === 'phone' && props.action !== 'new'),
  type: PropTypes.oneOf(['phone', 'email']).isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function ExistingContactCommunicationContainer(props) {
  const { commDisplay, onAction, disabled } = props;
  const classes = useStyles();
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  return (
    <>
      <span
        className={classes.showValueEditableField}
        style={{ display: 'flex', alignItems: 'center' }}
        onMouseOver={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <span style={{ flex: 1 }}>
          <Typography>{commDisplay}</Typography>
        </span>
        {/* <IconButton onClick={() => onAction('edit')} style={{ visibility: (isMouseOver && !disabled) ? 'visible' : 'hidden' }} >
          <EditIcon />
        </IconButton> */}
        <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
          <FsIconButton
            onClick={() => onAction('delete')}
            style={{ visibility: isMouseOver && !disabled ? 'visible' : 'hidden' }}
          >
            <DeleteIcon />
          </FsIconButton>
        </RestrictedPermissions>
      </span>
    </>
  );
}

ExistingContactCommunicationContainer.propTypes = {
  commDisplay: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

function ContactExposureLabelsMultiSelectFragment({ disabled, showOnly, onEdit }) {
  const { claim: claimInContext } = useClaim();
  const classes = useStyles();
  const { values } = useFormikContext();

  if (!claimInContext) {
    return null;
  }

  if (showOnly && !claimInContext.contacts.map((contact) => contact.id).includes(values['id'])) {
    return null;
  }

  const exposureLabels = getExposuresLabels(claimInContext);

  const renderExposureLabels = (selectedExposuresIds) => (
    <span className={classes.chips}>
      {selectedExposuresIds.map((exposureId) => (
        <span key={exposureId} style={{ padding: '4px' }}>
          <Chip
            size="small"
            label={
              <OverflowTextWithToolTip maxWidth="220px">
                {exposureLabels.find((exposureLabel) => exposureLabel.id === exposureId).label}
              </OverflowTextWithToolTip>
            }
          />
        </span>
      ))}
    </span>
  );

  const immutableExposureIds = values['immutable_exposure_ids'] || [];

  return (
    <Grid item xs={12}>
      <TextFieldFormik
        id="exposure_ids"
        label="Exposure Labels"
        fullWidth
        select
        SelectProps={{
          multiple: true,
          renderValue: renderExposureLabels,
        }}
        disabled={disabled}
        showOnly={showOnly}
        onEdit={onEdit}
      >
        {exposureLabels.map((exposure) => (
          <MenuItem
            key={exposure.id}
            value={exposure.id}
            disabled={disabled || immutableExposureIds.indexOf(exposure.id) > -1}
          >
            <Checkbox
              checked={values['exposure_ids']?.indexOf(exposure.id) > -1}
              disabled={disabled || immutableExposureIds.indexOf(exposure.id) > -1}
            />
            <ListItemText primary={exposure.label} />
          </MenuItem>
        ))}
      </TextFieldFormik>
    </Grid>
  );
}

ContactExposureLabelsMultiSelectFragment.propTypes = {
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
};

function EditContactExposureLabelsDialog(props) {
  const { open, contact, onCancel, onContactUpdate } = props;

  const classes = useStyles();
  const { claim: claimInContext } = useClaim();

  return (
    <Formik
      initialValues={{
        ...contact,
      }}
      validationSchema={Yup.object().shape({
        exposure_ids: Yup.array().required('Required').min(1, 'Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await axios.patch(`/api/v1/claims/${claimInContext.id}/contacts/${values['id']}/exposure_labels`, values);
          setSubmitting(false);
          await onContactUpdate();
        } catch (error) {
          setSubmitting(false);
          reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog title="Edit Contact Exposure Labels" open={open} isDialog onClose={onCancel}>
            <Grid container spacing={spacing}>
              <ContactExposureLabelsMultiSelectFragment disabled={isSubmitting} />
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditContactExposureLabelsDialog.propTypes = {
  open: PropTypes.bool,
  contact: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContactUpdate: PropTypes.func.isRequired,
};

function ContactFragment(props) {
  const {
    buttonsComponent,
    showOnly,
    isNew,
    createContactTitle,
    onEditContactIdentity,
    onChangeContactFields,
    onCommunicationAction,
    onEditContactAddress,
    onEditContactClaimExposureLabels,
    onEditW9Tin,
    onUploadCOI,
    action,
    isDialog,
    onClose,
    disableEditingRole,
    acceptedRoles,
    additionalCardContactComponent,
    onUploadContractOfService,
  } = props;

  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const { values, setFieldValue, isSubmitting, setSubmitting } = useFormikContext();
  const { organizationContactRolesDict } = useOrganization();
  const { currencyFormatter } = useCurrencyFormatter();

  const checkForCommunicationPermissions = shouldCheckForCommunicationPermissions(
    userOrganization,
    values['is_company'],
    values['role']
  );

  const existsEmails = existsValidItemInArray(values['emails'], 'email');
  const existsPhones = isNew
    ? existsValidItemInArray(values['mobile_phones'], 'phone')
    : existsValidItemInArray(values['phones'], 'phone');

  const dialogTitle = isNew ? (
    createContactTitle
  ) : (
    <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
      <HoverActionField disabled={isSubmitting} highlight onAction={() => onEditContactIdentity()}>
        <IdentityHeader contact={values} />
      </HoverActionField>
    </RestrictedPermissions>
  );

  async function handleChangeSingleContactField(id, val) {
    if (isNew) {
      setFieldValue(id, val);
    } else {
      setSubmitting(true);
      await onChangeContactFields({ [id]: val });
      setSubmitting(false);
    }
  }

  return (
    <CardDialog
      isDialog={isDialog}
      title={dialogTitle}
      trackAlt={isNew ? 'Create Contact' : 'Edit Contact'}
      action={action}
      maxWidth="sm"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={buttonsComponent && <DialogFooterActions buttonsOverride={buttonsComponent} />}
    >
      <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
        <Grid container>
          {isNew && (
            <>
              <Grid item xs={1} />
              <Grid container spacing={spacing} item xs={11}>
                <ContactIdentityFragment disableEditingRole={disableEditingRole} acceptedRoles={acceptedRoles} />
              </Grid>
            </>
          )}
          {!isNew && (
            <>
              <Grid item xs={12}>
                <DocumentContainer
                  shouldNotAppear={(contact) => !organizationContactRolesDict[contact.role].is_coi_required}
                  getDescription={(contact) => {
                    let coi_desc = 'COI';
                    if (contact.coi_expiration) {
                      coi_desc = `COI - Expires ${isoDateToUs(contact.coi_expiration)}`;
                    }
                    return coi_desc;
                  }}
                  keyName="coi_contact_document_id"
                  uploadTitle="Upload COI"
                  contact={values}
                  onUpload={onUploadCOI}
                  linkSuffix="coi"
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentContainer
                  shouldNotAppear={(contact) =>
                    !organizationContactRolesDict[contact.role].is_contract_of_service_required
                  }
                  getDescription={(contact) => {
                    let contract_description = 'Contract of service';
                    if (contact.medical_provider_discount_percentage) {
                      contract_description = `Contractual Discount of ${contact.medical_provider_discount_percentage}%`;
                    }
                    if (contact.medical_provider_discount_fixed_amount) {
                      contract_description = `Contractual Discount of ${currencyFormatter.format(
                        contact.medical_provider_discount_fixed_amount
                      )}`;
                    }
                    return contract_description;
                  }}
                  showDescriptionWithoutDocument={(contact) =>
                    contact.medical_provider_discount_percentage || contact.medical_provider_discount_fixed_amount
                  }
                  keyName="contract_of_service_contact_document_id"
                  uploadTitle="Upload Contract of Service"
                  contact={values}
                  onUpload={onUploadContractOfService}
                  linkSuffix="contract_of_service"
                />
              </Grid>
            </>
          )}
          {additionalCardContactComponent}
          <Grid item xs={12} />
          <Grid item xs={1}>
            <TextFieldIcon iconNode={<LocationIcon />} />
          </Grid>
          <Grid container spacing={spacing} item xs={11}>
            {isNew ? (
              <ContactAddressFragment />
            ) : (
              <>
                <Grid item xs={12}>
                  <ShowOnlyTextField
                    label="Address"
                    showOnlyValueComponent={values['full_address']}
                    className={classes.textField}
                    onEdit={onEditContactAddress}
                    classes={classes}
                    disabled={isSubmitting}
                  />
                  <ErrorHelperTextFormik id="zipcode" />
                </Grid>
                <Grid item xs={12} />
              </>
            )}
          </Grid>
          <Grid item xs={1}>
            <div style={{ paddingBottom: '12px', paddingTop: '12px' }}>
              <EmailIcon size={24} />
            </div>
          </Grid>
          <Grid container item xs={11}>
            {isNew ? (
              <MultiValueFragment
                label="Email"
                valueFieldName="email"
                objectsArrName="emails"
                fullWidth
                showOnly={showOnly}
              />
            ) : (
              <>
                {values['emails'].map((contactEmail, idx) => (
                  <Fragment key={contactEmail.id}>
                    <Grid item xs={11}>
                      <ExistingContactCommunicationContainer
                        commDisplay={`${contactEmail.email} ${
                          contactEmail.description ? ' - ' + contactEmail.description : ''
                        }`}
                        onAction={(action) =>
                          onCommunicationAction(action, 'email', contactEmail.id, contactEmail.email)
                        }
                        disabled={isSubmitting}
                      />
                    </Grid>
                    {idx !== values['emails'].length - 1 && <Grid item xs={1} />}
                  </Fragment>
                ))}
                {values['emails'].length === 0 && <Grid item xs={11} />}
                <Grid item xs={1}>
                  <FsIconButton disabled={isSubmitting} onClick={() => onCommunicationAction('new', 'email')}>
                    <AddIcon />
                  </FsIconButton>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={1}>
            <div style={{ paddingBottom: '12px', paddingTop: '12px' }}>
              <PhoneIcon size={24} />
            </div>
          </Grid>
          <Grid container item xs={11}>
            {isNew ? (
              <>
                {isQoverUser(user) ? (
                  <MultiValueFragment
                    label="Phone Number"
                    valueFieldName="phone"
                    objectsArrName="mobile_phones"
                    fullWidth
                    showOnly={showOnly}
                    isPhoneType
                  />
                ) : (
                  <>
                    <MultiValueFragment
                      label="Mobile"
                      valueFieldName="phone"
                      objectsArrName="mobile_phones"
                      fullWidth
                      showOnly={showOnly}
                      isPhoneType
                    />
                    <MultiValueFragment
                      label="Home"
                      valueFieldName="phone"
                      objectsArrName="home_phones"
                      fullWidth
                      showOnly={showOnly}
                      isPhoneType
                    />
                    <MultiValueFragment
                      label="Work"
                      valueFieldName="phone"
                      objectsArrName="work_phones"
                      fullWidth
                      showOnly={showOnly}
                      isPhoneType
                    />
                    <MultiValueFragment
                      label="Fax"
                      valueFieldName="phone"
                      objectsArrName="fax_phones"
                      fullWidth
                      showOnly={showOnly}
                      isPhoneType
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {values['phones'].map((contactPhone, idx) => (
                  <Fragment key={contactPhone.id}>
                    <Grid item xs={11}>
                      <ExistingContactCommunicationContainer
                        commDisplay={`${contactPhone.phone} - ${capitalize(contactPhone.phone_type)} ${
                          contactPhone.description ? ' - ' + contactPhone.description : ''
                        }`}
                        onAction={(action) =>
                          onCommunicationAction(
                            action,
                            'phone',
                            contactPhone.id,
                            contactPhone.phone,
                            contactPhone.phone_type
                          )
                        }
                        disabled={isSubmitting}
                      />
                    </Grid>
                    {idx !== values['phones'].length - 1 && <Grid item xs={1} />}
                  </Fragment>
                ))}
                {values['phones'].length === 0 && <Grid item xs={11} />}
                <Grid item xs={1}>
                  <FsIconButton disabled={isSubmitting} onClick={() => onCommunicationAction('new', 'phone')}>
                    <AddIcon />
                  </FsIconButton>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container item xs={12}>
            {existsEmails && checkForCommunicationPermissions && !isQoverUser(user) && (
              <>
                <Grid style={{ padding: 0 }} item xs={7}>
                  <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                    Permission to send email messages
                  </span>
                </Grid>
                <Grid style={{ padding: 0 }} item xs={5}>
                  <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                    <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                      <RadioButtonFormik
                        id="is_emailing_allowed"
                        label="No"
                        optionValue={false}
                        onChange={handleChangeSingleContactField}
                        disabled={isSubmitting}
                      />
                    </PermissionsButtonWrapper>
                    <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                      <RadioButtonFormik
                        id="is_emailing_allowed"
                        label="Yes"
                        optionValue={true}
                        onChange={handleChangeSingleContactField}
                        disabled={isSubmitting}
                      />
                    </PermissionsButtonWrapper>
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="is_emailing_allowed" />
                  </div>
                </Grid>
              </>
            )}
            {existsPhones && checkForCommunicationPermissions && (
              <>
                <Grid style={{ padding: 0 }} item xs={7}>
                  <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                    Permission to send text messages
                  </span>
                </Grid>
                <Grid style={{ padding: 0 }} item xs={5}>
                  <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                    <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                      <RadioButtonFormik
                        id="is_smsing_allowed"
                        label="No"
                        optionValue={false}
                        onChange={handleChangeSingleContactField}
                        disabled={isSubmitting}
                      />
                    </PermissionsButtonWrapper>
                    <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                      <RadioButtonFormik
                        id="is_smsing_allowed"
                        label="Yes"
                        optionValue={true}
                        onChange={handleChangeSingleContactField}
                        disabled={isSubmitting}
                      />
                    </PermissionsButtonWrapper>
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="is_smsing_allowed" />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={1}>
            <TextFieldIcon iconNode={<NoteIcon />} />
          </Grid>
          <Grid item xs={11}>
            <ContactHoverChangeField
              name="note"
              onChange={onChangeContactFields}
              validationSchema={Yup.string().nullable().required('Required')}
              isNew={isNew}
            >
              <TextFieldFormik id="note" label="Note" fullWidth multiline disabled={isSubmitting} />
            </ContactHoverChangeField>
          </Grid>
          <PermissionsRenderWrapper action={PERMISSION_ACTIONS.W9_TIN} verb={PERMISSION_VERBS.WRITE}>
            {!isNew && (
              <Grid item xs={12}>
                <ContactW9TinFragment contact={values} onUpload={onEditW9Tin} />
              </Grid>
            )}
          </PermissionsRenderWrapper>
          <ContactExposureLabelsMultiSelectFragment
            showOnly={!isNew}
            onEdit={onEditContactClaimExposureLabels}
            disabled={isSubmitting}
          />
        </Grid>
      </RestrictedPermissions>
    </CardDialog>
  );
}

ContactFragment.propTypes = {
  buttonsComponent: PropTypes.node,
  showOnly: PropTypes.bool,
  isNew: PropTypes.bool,
  createContactTitle: requiredIf(PropTypes.string, (props) => props.isNew),
  onEditContactIdentity: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  onChangeContactFields: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  onEditContactAddress: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  onEditContactClaimExposureLabels: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  onEditW9Tin: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  onUploadCOI: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  onCommunicationAction: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
  acceptedRoles: requiredIf(PropTypes.array, (props) => !props.showOnly),
  action: PropTypes.node,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
  additionalCardContactComponent: PropTypes.node,
  disableEditingRole: PropTypes.bool,
  onUploadContractOfService: requiredIf(PropTypes.func, (props) => !(props.isNew || props.showOnly)),
};

const isRoleUsuallyCompany = (organizationContactRolesDict, role) => {
  return organizationContactRolesDict[role]?.is_usually_company === true;
};

function DocumentContainer(props) {
  const {
    contact,
    onUpload,
    shouldNotAppear,
    getDescription,
    keyName,
    linkSuffix,
    uploadTitle,
    showDescriptionWithoutDocument,
  } = props;

  if (shouldNotAppear(contact)) {
    return null;
  }

  if (contact[keyName]) {
    return (
      <span>
        <HoverActionField onAction={onUpload}>
          <Link
            href={`/api/v1/contacts/${contact.id}/${linkSuffix}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: 0, textDecoration: 'underline' }}
          >
            {getDescription(contact)}
          </Link>
        </HoverActionField>
      </span>
    );
  }

  if (showDescriptionWithoutDocument && showDescriptionWithoutDocument(contact)) {
    return (
      <HoverActionField onAction={onUpload}>
        <span>{getDescription(contact)}</span>
      </HoverActionField>
    );
  }

  return (
    <Button color="primary" size="small" onClick={onUpload}>
      {uploadTitle}
    </Button>
  );
}

DocumentContainer.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
  shouldNotAppear: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  linkSuffix: PropTypes.string.isRequired,
  getDescription: PropTypes.func.isRequired,
  uploadTitle: PropTypes.string.isRequired,
  showDescriptionWithoutDocument: PropTypes.func,
};

function DocumentUploadDialog(props) {
  const {
    contact,
    onCancel,
    onContactUpdate,
    documentFieldKey,
    additionalFieldsInitialValues = {},
    additionalFieldsValidationSchema = {},
    additionalDocumentValidationSchema = {},
    children,
    keyName,
    title,
    documentType,
    documentFieldLabel,
    documentOptional,
    alertBannerNote,
    alertBannerType,
  } = props;
  const { claim: claimOrUndefined } = useClaim(); // might not be in claim context
  const [uploadPercent, setUploadPercent] = React.useState(0);

  const classes = useStyles();

  let documentValidationSchema = {};
  if (!documentOptional) {
    documentValidationSchema = {
      claim_document_or_upload_file: claimOrUndefined ? Yup.string().required('Required') : undefined,
      [documentFieldKey]: Yup.number().when('claim_document_or_upload_file', {
        is: 'claim_document',
        then: Yup.number().required('Required'),
      }),
      file_size: Yup.number().when('claim_document_or_upload_file', {
        is: 'upload_file',
        then: Yup.number().required('Required').min(1, 'Required'),
      }),
    };
  }

  return (
    <Formik
      initialValues={{
        claim_document_or_upload_file: claimOrUndefined ? '' : 'upload_file',
        claim_id: claimOrUndefined ? claimOrUndefined.id : '',
        [documentFieldKey]: '',
        file_size: 0,
        ...additionalFieldsInitialValues,
      }}
      validationSchema={Yup.object().shape({
        ...documentValidationSchema,
        ...additionalFieldsValidationSchema,
        ...additionalDocumentValidationSchema,
      })}
      onSubmit={async (values, formikProps) => {
        try {
          let params = { ...values };

          if (values['claim_document_or_upload_file'] === 'upload_file' && values['file']) {
            const file = values['file'];
            const uploadUrlResponse = await axios.post(`/api/v1/contacts/${contact.id}/documents/upload_url`, {
              file_size: file.size,
              file_type: file.type,
              file_name: file.name,
            });
            const uploadUrl = uploadUrlResponse.data.upload_url;
            const storageFileName = uploadUrlResponse.data.storage_filename;

            var config = {
              onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadPercent(percentCompleted);
              },
            };

            await axios.put(uploadUrl, file, config);

            params['file'] = {
              file_size: file.size,
              file_type: file.type,
              file_name: file.name,
              storage_name: storageFileName,
            };
          }
          if (values['claim_document_or_upload_file'] === 'upload_file' && !values['file']) {
            params['claim_document_or_upload_file'] = '';
          }

          await axios.post(`/api/v1/contacts/${contact.id}/${keyName}`, params);
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
          return;
        }
        await onContactUpdate();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, errors, touched, setFieldValue, setFieldTouched } = formikProps;

        return (
          <CardDialog title={title} isDialog onClose={onCancel} maxWidth="sm" fullWidth preventClose={isSubmitting}>
            {alertBannerNote && (
              <Grid item xs={12} style={{ marginBottom: '30px' }}>
                <AlertBanner alertType={alertBannerType} note={alertBannerNote} withIcon />
              </Grid>
            )}
            <Grid container spacing={1}>
              {claimOrUndefined && (
                <Grid style={{ padding: 0 }} item xs={12}>
                  <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                    <RadioButtonFormik
                      id="claim_document_or_upload_file"
                      label="Upload File"
                      optionValue="upload_file"
                      disabled={isSubmitting}
                    />
                    <RadioButtonFormik
                      id="claim_document_or_upload_file"
                      label="Claim Document"
                      optionValue="claim_document"
                      disabled={isSubmitting}
                    />
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="claim_document_or_upload_file" />
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                {values['claim_document_or_upload_file'] === 'claim_document' && (
                  <DocumentTextFieldFormik
                    id={documentFieldKey}
                    label={documentFieldLabel}
                    initialValues={{ type: documentType }}
                  />
                )}
                {values['claim_document_or_upload_file'] === 'upload_file' && (
                  <FileDropZone
                    onFileSelect={(file) => {
                      setFieldValue('file', file);
                      setFieldTouched('file', true);
                      setFieldValue('file_last_modified', new Date(file.lastModified).toISOString());
                      setFieldValue('file_size', file.size);
                      setFieldTouched('file_size', true);
                    }}
                    onCancelFileSelect={() => {
                      setFieldValue('file', undefined);
                      setFieldValue('file_size', undefined);
                      setFieldValue('file_last_modified', undefined);
                    }}
                    uploadPercent={uploadPercent}
                    file={values['file']}
                    error={getIn(errors, 'file_size') && getIn(touched, 'file_size')}
                    errorMessage={getIn(errors, 'file_size')}
                  />
                )}
              </Grid>
              {children}
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

DocumentUploadDialog.propTypes = {
  contact: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContactUpdate: PropTypes.func.isRequired,
  documentFieldKey: PropTypes.string.isRequired,
  documentFieldLabel: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  additionalFieldsInitialValues: PropTypes.object,
  additionalFieldsValidationSchema: PropTypes.object,
  additionalDocumentValidationSchema: PropTypes.object,
  title: PropTypes.string.isRequired,
  documentOptional: PropTypes.boolean,
  children: PropTypes.node.isRequired,
  alertBannerNote: PropTypes.string,
  alertBannerType: PropTypes.string,
};

function W9ContactAdditionalFields() {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <div style={{ marginTop: '40px', width: '100%' }}>
        <Grid container spacing={spacing}>
          <Grid item xs={6} style={{ marginTop: '3px' }}>
            <TextFieldFormik id="tin" label="TIN" fullWidth disabled={isSubmitting} />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="w9_date"
              label="Date Received"
              className={classes.textField}
              disableFuture
              disabled={isSubmitting}
              clearable
              fullWidth
            />
          </Grid>
          <Contact1099Fragment />
        </Grid>
      </div>
    </>
  );
}

function COIContactAdditionalFields() {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <Grid item xs={12}>
      <DatePickerTextFieldFormik
        id="coi_expiration"
        label="Expiration date"
        className={classes.textField}
        disablePast
        disabled={isSubmitting}
        clearable
      />
    </Grid>
  );
}

function ContractOfServiceContactAdditionalFields() {
  const classes = useStyles();
  const { isSubmitting, values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.percentage_discount) {
      setFieldValue('medical_provider_discount_fixed_amount', 0);
    } else {
      setFieldValue('medical_provider_discount_percentage', 0);
    }
  }, [values.percentage_discount, setFieldValue]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          Contractual Discount
        </Grid>
        <Grid item xs={6}>
          <RadioButtonFormik id="percentage_discount" label="Percentage (%)" optionValue={true} />
        </Grid>
        <Grid item xs={6}>
          <RadioButtonFormik id="percentage_discount" label="Fixed Amount (USD)" optionValue={false} />
        </Grid>
      </Grid>
      {values.percentage_discount && (
        <Grid item xs={12}>
          <TextFieldFormik
            id="medical_provider_discount_percentage"
            label="Contractual Discount - Percentage (%)"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </Grid>
      )}
      {!values.percentage_discount && (
        <Grid item xs={12}>
          <MonetaryValueTextFieldFormik
            id="medical_provider_discount_fixed_amount"
            label="Contractual Discount - fixed amount (USD)"
            allowNegative={false}
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </Grid>
      )}
    </>
  );
}

function CreateContact(props) {
  const {
    onCancel,
    onSubmitContact,
    title,
    claimId,
    newContactRole,
    suggestedValues,
    acceptedRoles,
    disableEditingRole,
  } = props;

  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const { organizationContactRolesDict } = useOrganization();
  const { defaultCountry } = useGlobalAddresses({ countriesConfigurationKey: 'contact_location' });

  let shouldBypassCommDuplicatesTest = React.useRef(false);

  const [duplicateCommsList, setDuplicateCommsList] = React.useState(undefined);

  async function checkExistingCommunicationMethods(values) {
    let duplicateComms = [];

    for (const contactEmail of values.emails) {
      if (!contactEmail.email) {
        continue;
      }
      const existingContactEmailsQueryRes = await axios.get('/api/v1/contacts/emails', {
        params: { email: contactEmail.email },
      });
      if (existingContactEmailsQueryRes.data.contact_emails.length > 0) {
        duplicateComms.push({
          contacts: existingContactEmailsQueryRes.data.contacts,
          type: 'email',
          commValue: contactEmail.email,
        });
      }
    }
    for (const phoneType of ['mobile', 'home', 'work', 'fax']) {
      for (const contactPhone of values[`${phoneType}_phones`]) {
        if (!contactPhone.phone) {
          continue;
        }
        const existingContactPhonesQueryRes = await axios.get('/api/v1/contacts/phones', {
          params: { phone: contactPhone.phone },
        });
        if (existingContactPhonesQueryRes.data.contact_phones.length > 0) {
          duplicateComms.push({
            contacts: existingContactPhonesQueryRes.data.contacts,
            type: 'phone',
            commValue: contactPhone.phone,
          });
        }
      }
    }

    return duplicateComms;
  }

  const sanitizePayload = useSanitizeByPermissions({
    fieldsPermissionActions: {
      date_of_birth: PERMISSION_ACTIONS.CONTACT_PII,
      government_id: PERMISSION_ACTIONS.CONTACT_PII,
    },
    verb: PERMISSION_VERBS.WRITE,
    forbiddenFields: ['tin'],
  });

  const hasContactPiiPermission = useHasPermission({
    action: PERMISSION_ACTIONS.CONTACT_PII,
    verb: PERMISSION_VERBS.WRITE,
  });

  // TODO NGTPA-14379 - delete component and use the new EditDialog directly
  if (isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONTACTS_UI_IMPROVEMENTS)) {
    return (
      <CreateContactDialog
        onClose={onCancel}
        onContactCreate={onSubmitContact}
        title={title}
        initialValues={{ role: newContactRole, ...suggestedValues }}
        specialRoleBehavior={{ acceptedRoles, isEditingRoleDisabled: disableEditingRole }}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        claim_id: claimId ? claimId : claim ? claim.id : undefined,
        exposure_ids: [], // TODO: suggest exposures + treat no claim
        organization_id: userOrganization.id,
        title: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        company_name: '',
        role: newContactRole || (acceptedRoles.length === 1 ? acceptedRoles[0] : ''),
        is_company: newContactRole ? isRoleUsuallyCompany(organizationContactRolesDict, newContactRole) : false,
        is_moi_service_provider: false,
        is_xactanalysis_contractor: false,
        moi_expertises: [],
        role_other_desc: '',
        tin: '',
        is_1099_reportable: '',
        report_type_1099: '',
        attorney_bar_state: '',
        attorney_bar_number: '',
        is_vat_registered: null,
        vat_number: '',
        organization: '',
        emails: [{ id: 0, email: '', description: '' }],
        is_emailing_allowed: '',
        mobile_phones: [{ id: 0, phone: '', description: '' }],
        home_phones: [{ id: 0, phone: '', description: '' }],
        work_phones: [{ id: 0, phone: '', description: '' }],
        fax_phones: [{ id: 0, phone: '', description: '' }],
        is_smsing_allowed: '',
        street_address1: '',
        street_address2: '',
        city: '',
        county: '',
        state: '',
        country: defaultCountry,
        zipcode: '',
        language: '',
        iban: '',
        uk_sort_code: '',
        uk_bank_account_number: '',
        note: '',
        contact_extra: {
          profession: '',
        },
        carrier_id: '',
        xactnet_address: '',
        ...suggestedValues,
      }}
      validate={(values) => contactValidate(values, true)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (!shouldBypassCommDuplicatesTest.current) {
            const duplicateComms = await checkExistingCommunicationMethods(values);

            if (duplicateComms.length > 0) {
              setDuplicateCommsList(duplicateComms);
              return;
            }
          }
          const payload = sanitizePayload(standardizeContactValues(values, false));
          const { contactPayload, xactAnalysisContractorPayload } = splitXactAnalysisContractorPayload(payload);
          const res = await axios.post(`/api/v1/contacts${hasContactPiiPermission ? '/full' : ''}`, contactPayload);
          const newContactClaimId = claimId ? claimId : claim ? claim.id : undefined;
          await updateXactAnalysisContractor(userOrganization, res.data.id, xactAnalysisContractorPayload);
          if (newContactClaimId) {
            await axios.post(`/api/v1/claims/${newContactClaimId}/contacts`, {
              contact_id: res.data.id,
              exposure_ids: values['exposure_ids'],
            });
          }
          await onSubmitContact(res.data);
        } catch (error) {
          setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { handleSubmit, handleReset, isSubmitting } = formikProps;

        const cancelFunc = () => {
          handleReset();
          onCancel();
        };

        const buttonsComponent = (
          <Fragment>
            <CancelButton disabled={isSubmitting} onClick={cancelFunc} />
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              Create
            </Button>
          </Fragment>
        );

        return (
          <>
            <ContactFragment
              buttonsComponent={buttonsComponent}
              isDialog
              onClose={cancelFunc}
              isNew
              acceptedRoles={acceptedRoles}
              createContactTitle={title || 'Create Contact'}
              disableEditingRole={disableEditingRole}
            />
            {duplicateCommsList && (
              <ExistingCommunicationWarningDialog
                contactsWithSameComm={duplicateCommsList[0].contacts}
                commType={duplicateCommsList[0].type}
                commValue={duplicateCommsList[0].commValue}
                isSubmitting={isSubmitting}
                onCreateDuplicate={() => {
                  if (duplicateCommsList.length > 1) {
                    setDuplicateCommsList((curr) => curr.slice(1)); // remove first element
                  } else {
                    // list has only a single item
                    shouldBypassCommDuplicatesTest.current = true;
                    handleSubmit();
                  }
                }}
                onCancel={() => setDuplicateCommsList(undefined)}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

CreateContact.propTypes = {
  onSubmitContact: PropTypes.func.isRequired,
  claimId: PropTypes.number,
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  newContactRole: PropTypes.string,
  suggestedValues: PropTypes.object,
  disableEditingRole: PropTypes.bool,
  acceptedRoles: PropTypes.array.isRequired,
};

function EditContactIdentityDialog(props) {
  const { open, contact, onCancel, onContactUpdate } = props;
  const { organizationContactRolesDict } = useOrganization();
  const { userOrganization } = useCms();

  const hasPiiPermission = useHasPermission({ action: PERMISSION_ACTIONS.CONTACT_PII, verb: PERMISSION_VERBS.WRITE });
  const sanitizePayload = useSanitizeByPermissions({
    fieldsPermissionActions: {
      date_of_birth: PERMISSION_ACTIONS.CONTACT_PII,
      government_id: PERMISSION_ACTIONS.CONTACT_PII,
    },
    verb: PERMISSION_VERBS.WRITE,
    forbiddenFields: ['tin'],
  });

  const classes = useStyles();
  return (
    <Formik
      initialValues={omit(
        {
          ...contact,
          reason: '',
          explanation: '',
        },
        'tin'
      )}
      validationSchema={Yup.object().shape({ government_id: governmentIdValidationSchema })}
      validate={(values) => {
        let errors = {};
        errors = { ...contactIdentityValidate(values, true, organizationContactRolesDict) };
        if (!values['reason']) {
          errors['reason'] = 'Required';
        }
        if (values['reason'] === 'other' && !values['explanation']) {
          errors['explanation'] = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const fullIfPii = hasPiiPermission ? '/full' : '';
          const payload = sanitizePayload(values);
          const { contactPayload, xactAnalysisContractorPayload } = splitXactAnalysisContractorPayload(payload);
          await axios.patch(`/api/v1/contacts/${contact.id}/contact_identity${fullIfPii}`, contactPayload);
          await updateXactAnalysisContractor(userOrganization, contact.id, xactAnalysisContractorPayload);
          await onContactUpdate();
          resetForm();
        } catch (error) {
          setSubmitting(false);
          reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, handleReset, values } = formikProps;
        const handleOnCancel = () => {
          handleReset();
          onCancel();
        };
        const actionButtons = (
          <div className={classes.buttonsContainer}>
            <CancelButton disabled={isSubmitting} onClick={handleOnCancel} />
            <WithConfirmFormik
              title="Are you sure?"
              contentText={`Are you sure you want to change the contact details for ${values.full_name}? This change will take effect everywhere this contact is referenced, across all claims this contact belongs to`}
              primaryButtonName="Save"
              shouldCloseOnPrimary={true}
            >
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </WithConfirmFormik>
          </div>
        );

        return (
          <CardDialog
            title="Edit Contact Identity"
            open={open}
            isDialog
            onClose={handleOnCancel}
            footerActions={actionButtons}
          >
            <Grid container spacing={spacing}>
              <ContactIdentityFragment
                acceptedRoles={Object.keys(organizationContactRolesDict)}
                disabled={isSubmitting}
              />
              <TextFieldFormik
                id="reason"
                select
                label="Reason"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              >
                {Object.keys(CONTACT_IDENTITY_CHANGE_REASONS)
                  .filter((reason) => reason !== 'other')
                  .map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {CONTACT_IDENTITY_CHANGE_REASONS[reason]['desc']}
                    </MenuItem>
                  ))}
                <MenuItem value="other">Other</MenuItem>
              </TextFieldFormik>
              {values['reason'] === 'other' && (
                <TextFieldFormik
                  id="explanation"
                  label="Explanation"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              )}
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditContactIdentityDialog.propTypes = {
  open: PropTypes.bool,
  contact: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContactUpdate: PropTypes.func.isRequired,
};

function ContactAddressFragment({ disabled }) {
  const { values, setFieldValue } = useFormikContext();

  const isCountryUs = values.country === 'US';

  const classes = useStyles();

  const prevCountryRef = usePrevious(values.country);

  useEffect(() => {
    if (prevCountryRef && prevCountryRef !== values.country) {
      // to prevent clearing the initial values
      setFieldValue('street_address1', '');
      setFieldValue('street_address2', '');
      setFieldValue('city', '');
      setFieldValue('county', '');
      setFieldValue('state', '');
      setFieldValue('zipcode', '');
    }
  }, [values.country, setFieldValue, prevCountryRef]);

  return (
    <>
      <Grid item xs={12}>
        <CountryAutocompleteFormik
          id="country"
          label="Country"
          fullWidth
          countriesConfigurationKey="contact_location"
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id="street_address1"
          label="Address 1"
          className={classes.textField}
          fullWidth
          disabled={disabled}
          helperText={!isCountryUs && 'Street name & number, P.O. box, C/O, etc.'}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id="street_address2"
          label="Address 2"
          className={classes.textField}
          fullWidth
          disabled={disabled}
          helperText={!isCountryUs && 'Apartment, suite, unit, building name, floor, etc.'}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="city" label="City" className={classes.textField} fullWidth disabled={disabled} />
      </Grid>
      {isCountryUs ? (
        <Grid item xs={12}>
          <TextFieldFormik id="county" label="County" className={classes.textField} fullWidth disabled={disabled} />
        </Grid>
      ) : (
        <></>
      )}
      {isCountryUs && (
        <Grid item xs={6}>
          <StateFieldFormik id="state" disabled={disabled} country={values.country} />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextFieldFormik id="zipcode" label="Postal Code" className={classes.textField} fullWidth disabled={disabled} />
      </Grid>
    </>
  );
}

ContactAddressFragment.propTypes = {
  disabled: PropTypes.bool,
};

function EditContactAddressDialog(props) {
  const { open, contact, onCancel, onContactUpdate } = props;

  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        ...contact,
        reason: '',
        explanation: '',
      }}
      validate={(values) => {
        let errors = {};
        errors = { ...contactAddressValidate(values) };
        if (!values['reason']) {
          errors['reason'] = 'Required';
        }
        if (values['reason'] === 'other' && !values['explanation']) {
          errors['explanation'] = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await axios.patch(`/api/v1/contacts/${contact.id}/contact_address`, values);
          setSubmitting(false);
          await onContactUpdate();
        } catch (error) {
          setSubmitting(false);
          reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, handleReset, values } = formikProps;

        return (
          <CardDialog title="Edit Contact Address" open={open} isDialog>
            <Grid container spacing={spacing}>
              <ContactAddressFragment disabled={isSubmitting} />
              <TextFieldFormik
                id="reason"
                select
                label="Reason"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              >
                {Object.keys(CONTACT_ADDRESS_CHANGE_REASONS)
                  .filter((reason) => reason !== 'other')
                  .map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {CONTACT_ADDRESS_CHANGE_REASONS[reason]['desc']}
                    </MenuItem>
                  ))}
                <MenuItem value="other">Other</MenuItem>
              </TextFieldFormik>
              {values['reason'] === 'other' && (
                <TextFieldFormik
                  id="explanation"
                  label="Explanation"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              )}
              <div className={classes.buttonsContainer}>
                <CancelButton
                  disabled={isSubmitting}
                  onClick={() => {
                    handleReset();
                    onCancel();
                  }}
                />
                <WithConfirmFormik
                  title="Are you sure?"
                  contentText={`Are you sure you want to change the contact details for ${values.full_name}? This change will take effect everywhere this contact is referenced, across all claims this contact belongs to`}
                  primaryButtonName="Save"
                  shouldCloseOnPrimary={true}
                >
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Save
                  </Button>
                </WithConfirmFormik>
              </div>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditContactAddressDialog.propTypes = {
  open: PropTypes.bool,
  contact: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContactUpdate: PropTypes.func.isRequired,
};

function EditContactCardDialog(props) {
  const {
    contact,
    onContactUpdate,
    onCancel,
    formikOverrides,
    isDialog,
    createContactTitle,
    specialValidationRequirements,
  } = props;

  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const { organizationContactRolesDict } = useOrganization();
  const [editContactIdentityOpen, setEditContactIdentityOpen] = React.useState(false);
  const [editContactAddressOpen, setEditContactAddressOpen] = React.useState(false);
  const [editContactClaimExposureLabels, setEditContactClaimExposureLabels] = React.useState(false);
  const [contactCommChange, setContactCommChange] = React.useState(undefined);
  const [isEditingW9Tin, setIsEditingW9Tin] = React.useState(undefined);
  const [uploadCOI, setUploadCOI] = React.useState(undefined);
  const [uploadContractOfService, setUploadContractOfService] = React.useState(undefined);

  const isXactAnalysisEnabled = isXactAnalysisIntegrationEnabled(userOrganization);
  const hasPiiPermission = useHasPermission({ action: PERMISSION_ACTIONS.CONTACT_PII, verb: PERMISSION_VERBS.WRITE });

  const sanitizePayload = useSanitizeByPermissions({
    fieldsPermissionActions: {
      date_of_birth: PERMISSION_ACTIONS.CONTACT_PII,
      government_id: PERMISSION_ACTIONS.CONTACT_PII,
    },
    verb: PERMISSION_VERBS.WRITE,
    forbiddenFields: ['tin'],
  });

  const handleChangeContactFields = async (contactFields) => {
    try {
      const urlSuffix = hasPiiPermission ? '/full' : '';
      const payload = sanitizePayload(contactFields);
      const { contactPayload, xactAnalysisContractorPayload } = splitXactAnalysisContractorPayload(payload);
      await axios.patch(`/api/v1/contacts/${contact.id}${urlSuffix}`, contactPayload);
      await updateXactAnalysisContractor(userOrganization, contact.id, xactAnalysisContractorPayload);
      await onContactUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const { initialValuesOverride, validateOverride, onSubmitOverride, buttonsComponentOverride } = formikOverrides;

  // TODO NGTPA-14379 - delete component and use the new EditDialog directly
  if (isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONTACTS_UI_IMPROVEMENTS) && isDialog) {
    const onSubmit = async () => {
      await onContactUpdate();
      if (onSubmitOverride) {
        await onSubmitOverride();
      }
    };
    return (
      <EditContactDialog
        contact={contact}
        onClose={onCancel}
        onContactUpdate={onSubmit}
        title={createContactTitle}
        buttonsOverride={buttonsComponentOverride}
        specialValidationRequirements={specialValidationRequirements}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        ...contact,
        is_emailing_allowed: contact.is_emailing_allowed === null ? '' : contact.is_emailing_allowed,
        is_smsing_allowed: contact.is_smsing_allowed === null ? '' : contact.is_smsing_allowed,
        claim_id: contact.claim_id,
        ...initialValuesOverride,
        carrier_id: isXactAnalysisEnabled ? '' : undefined,
        xactnet_address: isXactAnalysisEnabled ? '' : undefined,
      }}
      validate={(values) => (validateOverride ? validateOverride(values) : contactValidate(values, false))}
      onSubmit={onSubmitOverride ? onSubmitOverride : () => {}}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <>
            <ContactFragment
              isDialog={isDialog}
              onClose={isDialog && onCancel}
              buttonsComponent={buttonsComponentOverride ? buttonsComponentOverride(formikProps) : undefined}
              onEditContactIdentity={() => setEditContactIdentityOpen(true)}
              onEditContactAddress={() => setEditContactAddressOpen(true)}
              onEditContactClaimExposureLabels={() => setEditContactClaimExposureLabels(true)}
              onChangeContactFields={handleChangeContactFields}
              onCommunicationAction={(action, commType, commId, commValue, commLabel) =>
                setContactCommChange({ action, commType, commId, commValue, commLabel })
              }
              acceptedRoles={Object.keys(organizationContactRolesDict)}
              additionalCardContactComponent={claim && <IdentitySubHeader contact={contact} />}
              onEditW9Tin={() => setIsEditingW9Tin(true)}
              onUploadCOI={() => setUploadCOI(true)}
              onUploadContractOfService={() => setUploadContractOfService(true)}
            />
            <EditContactIdentityDialog
              open={editContactIdentityOpen}
              contact={contact}
              onCancel={() => setEditContactIdentityOpen(false)}
              onContactUpdate={async () => {
                await onContactUpdate();
                setEditContactIdentityOpen(false);
              }}
            />
            <EditContactAddressDialog
              open={editContactAddressOpen}
              contact={contact}
              onCancel={() => setEditContactAddressOpen(false)}
              onContactUpdate={async () => {
                await onContactUpdate();
                setEditContactAddressOpen(false);
              }}
            />
            <EditContactExposureLabelsDialog
              open={editContactClaimExposureLabels}
              contact={contact}
              onCancel={() => setEditContactClaimExposureLabels(false)}
              onContactUpdate={async () => {
                await onContactUpdate();
                setEditContactClaimExposureLabels(false);
              }}
            />
            {isEditingW9Tin && (
              <W9TinUpdateContainer
                contact={contact}
                onContactUpdate={onContactUpdate}
                onClose={() => setIsEditingW9Tin(false)}
              />
            )}
            {uploadCOI && (
              <DocumentUploadDialog
                contact={contact}
                onCancel={() => setUploadCOI(false)}
                onContactUpdate={async () => {
                  await onContactUpdate();
                  setUploadCOI(false);
                }}
                documentFieldKey="coi_document_id"
                additionalFieldsInitialValues={{
                  coi_expiration: '',
                }}
                additionalFieldsValidationSchema={{
                  coi_expiration: Yup.date().required('Required'),
                }}
                keyName="coi"
                title="Upload COI"
                documentType="coi_form"
                documentFieldLabel="COI"
              >
                <COIContactAdditionalFields />
              </DocumentUploadDialog>
            )}
            {uploadContractOfService && (
              <DocumentUploadDialog
                contact={contact}
                onCancel={() => setUploadContractOfService(false)}
                onContactUpdate={async () => {
                  await onContactUpdate();
                  setUploadContractOfService(false);
                }}
                documentFieldKey="contract_of_service_document_id"
                additionalFieldsInitialValues={{
                  percentage_discount: !contact.medical_provider_discount_fixed_amount,
                  medical_provider_discount_percentage: contact.medical_provider_discount_percentage || 0,
                  medical_provider_discount_fixed_amount: contact.medical_provider_discount_fixed_amount || 0,
                }}
                additionalFieldsValidationSchema={{
                  percentage_discount: Yup.boolean(),
                  medical_provider_discount_percentage: Yup.number(),
                  medical_provider_discount_fixed_amount: Yup.number(),
                }}
                keyName="contract_of_service"
                title="Upload Contract of Service"
                documentType="contract_of_service_form"
                documentFieldLabel="Contract of service"
                documentOptional
              >
                <ContractOfServiceContactAdditionalFields />
              </DocumentUploadDialog>
            )}
            {contactCommChange && (
              <ContactCommunicationChangeDialog
                contact={contact}
                action={contactCommChange.action}
                commValue={contactCommChange.commValue}
                commLabel={contactCommChange.commLabel}
                type={contactCommChange.commType}
                onCancel={() => setContactCommChange(undefined)}
                onChange={async (values) => {
                  try {
                    switch (contactCommChange.action) {
                      case 'edit':
                        await axios.patch(
                          `/api/v1/contacts/${contact.id}/${contactCommChange.commType}s/${contactCommChange.commId}`,
                          values
                        );
                        break;
                      case 'delete':
                        await axios.delete(
                          `/api/v1/contacts/${contact.id}/${contactCommChange.commType}s/${contactCommChange.commId}`,
                          { data: values }
                        );
                        break;
                      case 'new':
                        await axios.post(`/api/v1/contacts/${contact.id}/${contactCommChange.commType}s`, values);
                        break;
                      default:
                        throw Error(`Unknown contact communication change action: ${contactCommChange.action}`);
                    }
                    await onContactUpdate();
                    setContactCommChange(undefined);
                  } catch (error) {
                    reportAxiosError(error);
                    throw error;
                  }
                }}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

EditContactCardDialog.propTypes = {
  contact: PropTypes.object.isRequired,
  onContactUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
  formikOverrides: PropTypes.shape({
    initialValuesOverride: PropTypes.object,
    validateOverride: PropTypes.func,
    onSubmitOverride: PropTypes.func,
    buttonsComponentOverride: PropTypes.func,
  }),
  createContactTitle: PropTypes.string,
  specialValidationRequirements: PropTypes.object,
};

EditContactCardDialog.defaultProps = {
  formikOverrides: {},
};

function ContactEntity(props) {
  const {
    contactId,
    contactDisplayName,
    hideDisplayName,
    onContactUpdate,
    maxWidth,
    shallRefetchOnContactIdChange = false,
    claimId,
  } = props;

  const [contactAnchorEl, setContactAnchorEl] = useState(undefined);
  const [editContactOpen, setEditContactOpen] = useState(undefined);

  const [contact, setContact] = useState(undefined);

  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();

  const classes = useStyles();

  const { userHasContextPermissions } = useRestrictedPermissions();
  const hasContactReadPermission = useHasPermission({
    action: PERMISSION_ACTIONS.CONTACT,
    verb: PERMISSION_VERBS.READ,
  });

  const handleButtonClick = (e) => {
    e.preventDefault();

    if (!hasContactReadPermission) {
      return;
    }

    fetchContactDetails();
    setContactAnchorEl(e.currentTarget);
  };

  const updateClaimIfPossible = () => {
    if (onAsyncClaimUpdate) {
      onAsyncClaimUpdate();
    }
  };

  const handleContactUpdate = async () => {
    updateClaimIfPossible();

    const contact = await fetchContactDetails();
    // contact === undefined if fetchContactDetails failed fetching
    if (onContactUpdate && contact !== undefined) {
      await onContactUpdate(contact);
    }
  };

  const fetchContactDetails = async () => {
    try {
      const contact = await getContact(contactId, claim);
      setContact(contact);
      return contact;
    } catch (error) {
      reportAxiosError(error);
    }
  };

  React.useEffect(() => {
    if (!contact && !contactDisplayName) {
      fetchContactDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    shallRefetchOnContactIdChange && fetchContactDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const closePopover = () => setContactAnchorEl(undefined);

  const contactText = contact ? contact.full_name : contactDisplayName;

  return (
    <Fragment>
      <span className={classes.containerCentered}>
        <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.READ}>
          <ContactIcon
            className={cn(classes.leftButtonIcon, {
              [classes.hoverableIcon]: userHasContextPermissions,
              [classes.hoverableIconDisabled]: !userHasContextPermissions,
            })}
            onClick={handleButtonClick}
            disabled={user.role.is_view_only && !claim}
          />
        </PermissionsButtonWrapper>
        <OverflowTextWithToolTip maxWidth={maxWidth ? maxWidth : '150px'}>
          {!hideDisplayName && contactText}
        </OverflowTextWithToolTip>
      </span>
      <Popover
        key={contact && contact.id} // force creation of new popover once contact is loaded (to fit dimensions)
        open={Boolean(contactAnchorEl)}
        anchorEl={contactAnchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {!contact ? (
          <Card>
            <CircularProgress />
          </Card>
        ) : (
          <ContactMiniCard
            contact={contact}
            onEdit={() => setEditContactOpen(true)}
            onClose={closePopover}
            onUpdate={() => updateClaimIfPossible()}
            claimId={claimId}
          />
        )}
      </Popover>
      {
        // the following dialog can only be called from the ContactMiniCard which is rendered only after fetchContactDetails returns
      }
      {contact && editContactOpen && (
        <EditContactCardDialog
          claim={claim}
          contact={contact}
          onContactUpdate={handleContactUpdate}
          onCancel={() => setEditContactOpen(false)}
          isDialog
        />
      )}
    </Fragment>
  );
}

ContactEntity.propTypes = {
  contactId: PropTypes.number.isRequired,
  hideDisplayName: PropTypes.bool,
  contactDisplayName: requiredIf(PropTypes.string, (props) => !props.hideDisplayName),
  onContactUpdate: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claimId: PropTypes.number,
  shallRefetchOnContactIdChange: PropTypes.bool,
};

async function getContact(contactId, claim = null) {
  const isClaimContact = claim && claim.contacts.map((contact) => contact.id).includes(contactId);
  const result = await axios.get(
    isClaimContact ? `/api/v1/claims/${claim.id}/contacts/${contactId}` : `/api/v1/contacts/${contactId}`
  );
  const contact = contactPopulate(result.data);

  if (isClaimContact) {
    const res = await axios.get(`/api/v1/claims/${claim.id}/contacts/${contactId}/exposure_labels`);
    const exposureLabels = res.data;
    contact.exposure_ids = exposureLabels.map((exposureLabel) => exposureLabel.exposure_id);
    contact.immutable_exposure_ids = exposureLabels
      .filter((exposureLabel) => exposureLabel.is_immutable)
      .map((exposureLabel) => exposureLabel.exposure_id);
  }

  return contact;
}

function onChangeTextFieldAlwaysAddPlus(event, setFieldValue, fieldId) {
  if (!event.target.value.startsWith('+') && event.target.value.length > 0) {
    setFieldValue(fieldId, '+' + event.target.value);
  } else {
    setFieldValue(fieldId, event.target.value);
  }
}

function getSupportedContactRolesTypes(organizationRolesDict, initialRolesList, claimTypesList) {
  let rolesList = [...initialRolesList];
  rolesList = rolesList.filter(
    (roleType) =>
      organizationRolesDict[roleType] &&
      (organizationRolesDict[roleType].lobs.length > 0
        ? _.intersection(organizationRolesDict[roleType].lobs, claimTypesList).length !== 0
        : true)
  );

  rolesList = rolesList.filter(
    (roleType) =>
      organizationRolesDict[roleType] &&
      (organizationRolesDict[roleType].excluded_claim_types
        ? _.difference(claimTypesList, organizationRolesDict[roleType].excluded_claim_types).length !== 0
        : true)
  );

  return rolesList;
}

function ContactW9TinFragment({ contact, onUpload }) {
  const { organizationContactRolesDict } = useOrganization();
  const isShowTIN = contact['role'] && isTinRequired(organizationContactRolesDict, contact);
  const classes = useStyles();

  const getDescription = (contact) => {
    let w9_desc = 'W9 Form';
    if (contact.w9_date) {
      w9_desc += ` - ${isoDateToUs(contact.w9_date)}`;
    }
    return w9_desc;
  };

  if (!isShowTIN) {
    return null;
  }

  return (
    <>
      <div style={{ marginTop: '25px' }}>
        <InnerCard>
          <Grid item xs={12}>
            <RestrictedPermissions action={PERMISSION_ACTIONS.W9_TIN} verb={PERMISSION_VERBS.WRITE}>
              <HoverActionField onAction={onUpload} iconStyle={{ width: 20, height: 20 }} fullWidth>
                <Typography display="block" variant="subtitle1" styles={{ width: '100%' }}>
                  {contact['w9_contact_document_id'] ? 'W9 Form & TIN Number' : 'Upload W9 Form & TIN Number'}
                </Typography>
              </HoverActionField>
            </RestrictedPermissions>
          </Grid>

          <Grid style={{ marginTop: '10px' }} container>
            <Grid item xs={6}>
              <Typography variant="caption">W9 Form</Typography>
              <div>
                {contact.w9_date ? (
                  <Link
                    className={classes.lightLink}
                    href={`/api/v1/contacts/${contact.id}/w9_tin`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    {getDescription(contact)}
                  </Link>
                ) : (
                  <span>-</span>
                )}
              </div>
            </Grid>

            {(isShowTIN || contact.tin) && (
              <Grid item xs={6}>
                <Typography variant="caption">TIN Number</Typography>
                <div>
                  <Typography variant="body1">{contact.tin || '-'}</Typography>
                </div>
              </Grid>
            )}

            <Contact1099Fragment showOnly={true} />
          </Grid>
        </InnerCard>
      </div>
    </>
  );
}

ContactW9TinFragment.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
};

const Contact1099Fragment = ({ showOnly }) => {
  const { values } = useFormikContext();
  const { show1099Fields } = usePaymentsConfiguration();

  return show1099Fields ? (
    <>
      <Grid item xs={6}>
        <TextFieldFormik showOnly={showOnly} id="is_1099_reportable" label="Is 1099 Reportable?" fullWidth select>
          <MenuItem value={true}>Reportable</MenuItem>
          <MenuItem value={false}>Not Reportable</MenuItem>
        </TextFieldFormik>
      </Grid>
      {getIn(values, 'is_1099_reportable') ? (
        <Grid item xs={6}>
          <TextFieldFormik showOnly={showOnly} id="report_type_1099" label="Report Type" fullWidth select>
            {REPORT_TYPES_1099.map((reportType) => (
              <MenuItem key={reportType} value={reportType}>
                {capitalize(reportType)}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      ) : (
        <Grid item xs={6} />
      )}
    </>
  ) : null;
};

Contact1099Fragment.propTypes = {
  showOnly: PropTypes.bool,
};

const W9TinUpdateContainer = ({ contact, onClose, onContactUpdate }) => {
  const { show1099Fields } = usePaymentsConfiguration();

  return (
    <DocumentUploadDialog
      contact={contact}
      onCancel={onClose}
      onContactUpdate={async () => {
        await onContactUpdate();
        onClose();
      }}
      documentFieldKey="w9_document_id"
      additionalFieldsInitialValues={{
        w9_date: getLocalDateToday(),
        tin: contact.tin,
        is_1099_reportable: contact.is_1099_reportable,
        report_type_1099: contact.report_type_1099,
      }}
      additionalFieldsValidationSchema={{
        w9_date: Yup.date(),
        tin: Yup.number().required('Required'),
        is_1099_reportable: show1099Fields ? Yup.bool() : undefined,
        report_type_1099: show1099Fields
          ? Yup.string()
              .when('is_1099_reportable', {
                is: true,
                then: Yup.string().required('Required'),
              })
              .nullable()
          : undefined,
      }}
      keyName="w9_tin"
      title="Edit W9 Form & TIN Number"
      documentType="w9_form"
      documentFieldLabel="W9 Form"
      alertBannerNote="TIN number can be updated independently. To upload W9, you must update the TIN number first"
      alertBannerType={AlertBanner.ALERT_TYPES.INFO}
      documentOptional
    >
      <W9ContactAdditionalFields />
    </DocumentUploadDialog>
  );
};

W9TinUpdateContainer.propTypes = {
  contact: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onContactUpdate: PropTypes.func.isRequired,
};

export {
  ContactEntity,
  contactValidate,
  CreateContact,
  EditContactCardDialog,
  getContact,
  getSupportedContactRolesTypes,
  standardizeContactValues,
};
