import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { connect, Formik, setNestedObjectValues } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { RECIPIENTS_TYPES } from '../../Types';
import CardDialog from '../CardDialog';
import { Text } from '../core';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import HoverActionField from '../HoverActionField';
import PencilIcon from '../icons/PencilIcon';

import { useStyles } from '../../assets/styles';

const CommunicationRecipientContainerFormik = connect((props) => {
  const {
    formik,
    isView,
    AdditionalComponent,
    recipientContactValidationScheme,
    SpecificRecipientDialog,
    recipientType,
    isNotClaimRelated,
  } = props;
  const { values, setFieldValue, errors } = formik;
  const classes = useStyles();
  const [showRecipientsDialog, setShowRecipientsDialog] = useState(false);
  const recipientKey = recipientType + '_contacts';
  const recipientContacts = values[recipientKey];
  if (recipientContacts.length === 0 && isView) {
    return null;
  }
  return (
    <>
      <span className={classes.containerCentered}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.MEDIUM} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
          {RECIPIENTS_TYPES[recipientType]}:&nbsp;
        </Text>
        {recipientContacts.length === 0 ? (
          <Button color="primary" onClick={() => setShowRecipientsDialog(true)}>
            Add Contact
          </Button>
        ) : (
          <HoverActionField onAction={() => setShowRecipientsDialog(true)} icon={isView ? InfoIcon : PencilIcon}>
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              {recipientContacts.map((entry) => entry.contact.full_name).join(', ')}
            </Text>
          </HoverActionField>
        )}
      </span>
      {!isView && recipientContacts.length > 0 && AdditionalComponent && AdditionalComponent(recipientContacts)}
      {errors[recipientKey] && (
        <FormHelperText error>
          Issue with {recipientType} recipients, please edit {recipientType} recipients for more details
        </FormHelperText>
      )}
      <RecipientsDialog
        open={showRecipientsDialog}
        recipientContacts={recipientContacts}
        showOnly={isView}
        onCancel={() => setShowRecipientsDialog(false)}
        onUpdate={(recipientContacts) => {
          setFieldValue(recipientKey, recipientContacts);
          setShowRecipientsDialog(false);
        }}
        SpecificRecipientDialog={SpecificRecipientDialog}
        recipientContactValidationScheme={recipientContactValidationScheme}
        recipientType={recipientType}
        isNotClaimRelated={isNotClaimRelated}
      />
    </>
  );
});

CommunicationRecipientContainerFormik.propTypes = {
  isView: PropTypes.bool,
  AdditionalComponent: PropTypes.func,
  recipientContactValidationScheme: PropTypes.object.isRequired,
  SpecificRecipientDialog: PropTypes.func.isRequired,
  recipientType: PropTypes.string,
  isNotClaimRelated: PropTypes.bool,
};

function RecipientsDialog(props) {
  const {
    open,
    recipientContacts,
    onUpdate,
    onCancel,
    showOnly,
    SpecificRecipientDialog,
    recipientContactValidationScheme,
    recipientType,
    isNotClaimRelated,
  } = props;
  const classes = useStyles();
  const emptyEntry = { contact_id: '', id: uuidv4() };
  const recipientKey = recipientType + '_contacts';

  return (
    <Formik
      initialValues={{
        [recipientKey]: recipientContacts.length > 0 ? recipientContacts : [{ ...emptyEntry }],
      }}
      validationSchema={Yup.object().shape({
        [recipientKey]: Yup.array().of(recipientContactValidationScheme),
      })}
      onSubmit={async (values, formikProps) => {
        const { setSubmitting } = formikProps;
        await onUpdate(values[recipientKey]);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount={!!recipientContacts} // if filled show errors immediately (useful for automatically-filled CC scenario)
      initialTouched={
        recipientContacts.length > 0 ? { [recipientKey]: setNestedObjectValues(recipientContacts, true) } : undefined
      } // if filled touch everything to show errors immediately (formik does this on onSubmit) (useful for automatically-filled CC/BCC scenario)
    >
      {(formikProps) => {
        const { values, setFieldValue, isSubmitting, handleSubmit, handleReset } = formikProps;
        const recipientContacts = values[recipientKey];
        return (
          <CardDialog
            isDialog
            title={RECIPIENTS_TYPES[recipientType] + ' Recipients'}
            open={open}
            onClose={() => {
              onCancel();
              handleReset();
            }}
            maxWidth="sm"
            fullWidth
          >
            <Grid container alignItems="center" spacing={1}>
              {recipientContacts.map((recipientEntry, idx) => (
                <React.Fragment key={recipientEntry.id || 'empty'}>
                  <Grid item xs={9}>
                    <SpecificRecipientDialog
                      recipientEntry={recipientEntry}
                      recipientEntryId={`${recipientKey}[${idx}]`}
                      disabled={isSubmitting}
                      setFieldValue={setFieldValue}
                      showOnly={showOnly}
                      isNotClaimRelated={isNotClaimRelated}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {!showOnly && (
                      <>
                        <IconButton
                          onClick={() =>
                            setFieldValue(
                              recipientKey,
                              recipientContacts.filter((_, currIdx) => currIdx !== idx)
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                        {idx === recipientContacts.length - 1 && recipientEntry.contact_id && (
                          <IconButton
                            onClick={() =>
                              setFieldValue(recipientKey, [...recipientContacts, { ...emptyEntry, id: uuidv4() }])
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {!showOnly && (
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            )}
            <Grid item xs={9}>
              <ErrorHelperTextFormik id={recipientKey} withoutChildren={true} />
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

RecipientsDialog.propTypes = {
  open: PropTypes.bool,
  recipientContacts: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
  recipientContactValidationScheme: PropTypes.object.isRequired,
  SpecificRecipientDialog: PropTypes.func.isRequired,
  recipientType: PropTypes.string,
  isNotClaimRelated: PropTypes.bool,
};

export default CommunicationRecipientContainerFormik;
