import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import AdjusterSelectTextFieldFormik from '../../Adjuster/AdjusterSelectTextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { getCoveredObjectDisplayName, isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import useOrganization from '../OrganizationContext';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { ChooseOwnerAssignment } from './addExposure/ChooseOwnerAssignment';

import { useStyles } from '../../assets/styles';
import addExposuresStyles from './addExposure/addExposures.module.scss';

const exposureFields = {
  coverage_type: '',
  coverage_config_id: '',
  handling_adjuster_id: '',
  involved_person_id: '',
  involved_property_id: '',
  covered_entity_id: '',
  involved_entity_id: '',
  involved_entity_type: '',
  should_use_assignment_rules: '',
};

const getCoveredObjects = (claim) => claim?.policy?.covered_objects || [];

function AddExposureFormikInnerDialog(props) {
  const {
    open,
    isSubmitting,
    handleSubmit,
    handleReset,
    values,
    setFieldValue,
    onCancel,
    newExposureStore,
    onUpdateNewExposureStore,
    handleChange,
    setFieldTouched,
  } = props;
  const { claim } = useClaim();
  const classes = useStyles();

  const {
    isLoading: isLoadingPossibleCoverages,
    isError: isErrorPossibleCoverages,
    data: possibleCoverages,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/exposures/configurable_coverages/possible_coverages`);

  const { isLoading } = useOrganization();
  const { userOrganization } = useCms();

  const coverage_config_id = values.coverage_config_id;
  const covered_entity_id = values.covered_entity_id;
  const storeCoverageConfigID = newExposureStore.coverageConfigID;
  const storeCoveredEntityID = newExposureStore.CoveredEntityID;

  const isAssignmentsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION
  );
  const shouldHideHandlingAdjustersDropdown = isAssignmentsEnabled && values.should_use_assignment_rules !== false;

  useEffect(() => {
    async function fetchNewExposureStore() {
      try {
        setFieldValue('coverage_config_id', coverage_config_id);

        let queryParams = { params: { covered_entity_id } };
        let possibleInvolvedEntitiesURI = `/api/v1/claims/${claim.id}/exposures/configurable_coverages/${coverage_config_id}/new_exposure_info`;
        const newExposureInvolvedInfoData = (await axios.get(possibleInvolvedEntitiesURI, queryParams)).data;

        if (claim?.type === 'general_claim' && newExposureInvolvedInfoData?.possible_involved_entities?.length === 1) {
          setFieldValue('involved_entity_id', newExposureInvolvedInfoData.possible_involved_entities[0].id);
          setFieldValue('involved_entity_type', newExposureInvolvedInfoData.possible_involved_entities[0].type);
        } else if (
          newExposureInvolvedInfoData.loss_type === 'property_damage' &&
          newExposureInvolvedInfoData.possible_involved_properties.length === 1
        ) {
          setFieldValue('involved_property_id', newExposureInvolvedInfoData.possible_involved_properties[0].id);
        }

        onUpdateNewExposureStore({
          ...newExposureInvolvedInfoData,
          isFetching: false,
          coverageConfigID: coverage_config_id,
        });
      } catch (error) {
        reportAxiosError(error);
      }
    }

    const coveredObjects = getCoveredObjects(claim);
    const coveredObjectsEntityIds = coveredObjects.map((co) => co.covered_entity_id);
    if (!values.covered_entity_id) {
      setFieldValue('covered_entity_id', coveredObjectsEntityIds.length === 1 ? coveredObjectsEntityIds[0] : '');
    }
    const isCoveredEntityExistsAndUpdated = covered_entity_id && covered_entity_id !== storeCoveredEntityID;
    const isCoverageConfigUpdated = coverage_config_id !== storeCoverageConfigID;

    // get the possible involveds only if the coverage config exists and if the coverage config has been updated or the covered entity has been updated
    if (coverage_config_id && (isCoverageConfigUpdated || isCoveredEntityExistsAndUpdated)) {
      onUpdateNewExposureStore((prevExposureStore) => ({
        ...prevExposureStore,
        isFetching: true,
        coverageConfigID: coverage_config_id,
        coveredEntityID: covered_entity_id,
      }));
      fetchNewExposureStore();
    }
  }, [
    coverage_config_id,
    covered_entity_id,
    claim,
    storeCoverageConfigID,
    storeCoveredEntityID,
    onUpdateNewExposureStore,
    values,
    setFieldValue,
  ]);

  const isStoreReady = newExposureStore && !newExposureStore.isFetching;

  if (!open) {
    return <></>;
  }

  const onClose = () => {
    handleReset();
    onCancel();
  };

  if (isLoadingPossibleCoverages || isErrorPossibleCoverages) {
    return <LoadingDialog isError={isErrorPossibleCoverages} onClose={onClose} track="Add Exposure" />;
  }

  const possibleCoveragesSorted = possibleCoverages.sort((coverage1, coverage2) =>
    coverage1.display_name.toLowerCase() > coverage2.display_name.toLowerCase() ? 1 : -1
  );
  const noCoveragesExist = possibleCoveragesSorted.length === 0;

  const {
    loss_type: lossType,
    is_unspecified: isUnspecified,
    possible_involved_persons: possibleInvolvedPersons,
    possible_involved_properties: possibleInvolvedProperties,
    possible_involved_entities: possibleInvolvedEntities,
  } = newExposureStore;

  const isPersonInvolved = lossType === 'bodily_injury';
  const isPropertyInvolved = lossType === 'property_damage';
  const isEntityInvolved = claim?.type === 'general_claim'; // there must be an involved entity in GC claim exposures
  const isNoPossibleEntities =
    !isUnspecified &&
    !possibleInvolvedPersons?.length &&
    !possibleInvolvedProperties?.length &&
    !possibleInvolvedEntities?.length;
  const coveredObjects = getCoveredObjects(claim);
  const noCoveredObjectsExistButShould = claim?.type === 'general_claim' && coveredObjects.length === 0;
  const disabled = noCoveredObjectsExistButShould || noCoveragesExist;
  const isControlDisabled = isSubmitting || !isStoreReady || isLoading;

  return (
    <CardDialog title="Add Exposure" isDialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <Grid container spacing={1}>
        {claim.is_closed && <Typography>This will result in reopening the claim</Typography>}
        {noCoveragesExist && (
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.ERROR}
            note="There are no coverages matching this claim, please contact your IT administrator."
            withIcon
          />
        )}
        {noCoveredObjectsExistButShould && (
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.ERROR}
            note="Add a covered object in the policy to add an exposure"
            withIcon
          />
        )}
        {coveredObjects?.length > 1 && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="covered_entity_id"
              select
              label="Covered Entity"
              fullWidth
              className={classes.textField}
            >
              {coveredObjects.map((coveredObject) => (
                <MenuItem key={coveredObject.id} value={coveredObject.covered_entity_id}>
                  {getCoveredObjectDisplayName(coveredObject)}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldFormik
            id="coverage_config_id"
            select
            label="Coverage"
            fullWidth
            className={classes.textField}
            disabled={isControlDisabled || disabled}
            onChange={(e) => {
              handleChange(e);
              setFieldTouched('involved_person_id', true);
              setFieldTouched('involved_property_id', true);
              setFieldValue('involved_person_id', null);
              setFieldValue('involved_entity_type', null);
              setFieldValue('involved_property_id', null);
            }}
          >
            {possibleCoveragesSorted.map((coverage) => (
              <MenuItem key={coverage.id} value={coverage.id}>
                {coverage.display_name}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        {!isUnspecified && isEntityInvolved && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="involved_entity_id"
              select
              label="Involved Entity"
              fullWidth
              className={classes.textField}
              disabled={isControlDisabled || isNoPossibleEntities}
              onChange={(e) => {
                handleChange(e);
                const involved_entity_id = e.target.value;
                const involvedEntityType = possibleInvolvedEntities?.find(
                  (entity) => entity.id === involved_entity_id
                )?.type;
                setFieldValue('involved_entity_type', involvedEntityType);
              }}
            >
              {(newExposureStore.possible_involved_entities || []).map((involved_entity) => (
                <MenuItem key={involved_entity.id} value={involved_entity.id}>
                  {getCoveredObjectDisplayName(involved_entity)}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}
        {isPersonInvolved && !isUnspecified && !isEntityInvolved && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="involved_person_id"
              select
              label="Involved Person"
              fullWidth
              className={classes.textField}
              disabled={isControlDisabled || isNoPossibleEntities}
            >
              {newExposureStore.possible_involved_persons.map((involved_person) => (
                <MenuItem key={involved_person.id} value={involved_person.id}>
                  {involved_person.contact.full_name}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}
        {isPropertyInvolved && !isUnspecified && !isEntityInvolved && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="involved_property_id"
              select
              label="Involved Property"
              fullWidth
              className={classes.textField}
              disabled={isControlDisabled || isNoPossibleEntities}
            >
              {newExposureStore.possible_involved_properties.map((involved_property) => (
                <MenuItem key={involved_property.id} value={involved_property.id}>
                  {involved_property.display_name}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}
        {isAssignmentsEnabled ? (
          <ChooseOwnerAssignment formikId="should_use_assignment_rules" disabled={isControlDisabled} />
        ) : null}
        {shouldHideHandlingAdjustersDropdown ? null : (
          <Grid item xs={12}>
            <div className={isAssignmentsEnabled ? addExposuresStyles.handlingAdjusterContainer : null}>
              <AdjusterSelectTextFieldFormik
                id="handling_adjuster_id"
                label="Handling Adjuster"
                className={classes.textField}
                disabled={isControlDisabled || disabled}
                fullWidth
                extraValues={
                  claim.handling_adjuster
                    ? [
                        {
                          id: 'claim_owner',
                          username: `File Owner: ${claim.handling_adjuster}`,
                        },
                      ]
                    : []
                }
                checkLicenses
              />
            </div>
          </Grid>
        )}
      </Grid>
      <div>
        <div className={classes.buttonsContainer}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isControlDisabled || disabled}>
            Add Exposure
          </Button>
        </div>
      </div>
    </CardDialog>
  );
}

AddExposureFormikInnerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  newExposureStore: PropTypes.object.isRequired,
  onUpdateNewExposureStore: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

function AddExposureDialog(props) {
  const { open, onCancel, onAddExposure } = props;

  const { claim } = useClaim();
  const { userOrganization } = useCms();
  const isAssignmentsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION
  );

  const [newExposureStore, setNewExposureStore] = useState({
    isFetching: false,
    storeCoverageConfigID: null,
    storeCoveredEntityID: null,
  });

  const personIDValidation = (newExposureStore) => {
    const {
      loss_type: lossType,
      is_unspecified: isUnspecified,
      possible_involved_persons: possibleInvolvedPersons,
      possible_involved_entities: possibleInvolvedEntities,
    } = newExposureStore;
    const isPersonInvolved = lossType === 'bodily_injury';
    const isInvolvedEmpty = possibleInvolvedPersons?.length === 0;
    const isEntityInvolved = possibleInvolvedEntities?.length > 0;

    let validation = undefined;
    if (isPersonInvolved && !isUnspecified && !isEntityInvolved) {
      if (isInvolvedEmpty) {
        validation = Yup.number().required(
          'To add this exposure to the claim, you first need to add an involved person.'
        );
      } else {
        validation = Yup.number().required('Required');
      }
    }
    return validation;
  };

  const involvedEntityValidation = (newExposureStore) => {
    const { is_unspecified: isUnspecified, possible_involved_entities: possibleInvolvedEntities } = newExposureStore;
    const isEntityInvolved = possibleInvolvedEntities?.length > 0;

    let validation = undefined;
    if (!isUnspecified) {
      if (!isEntityInvolved) {
        validation = Yup.number().required(
          'To add this exposure to the claim, you first need to add an involved entity.'
        );
      } else {
        validation = Yup.number().required('Required');
      }
    }
    return validation;
  };

  const propertyIDValidation = (newExposureStore) => {
    const {
      loss_type: lossType,
      is_unspecified: isUnspecified,
      possible_involved_properties: possibleInvolvedProperties,
      possible_involved_entities: possibleInvolvedEntities,
    } = newExposureStore;
    const isPropertyInvolved = lossType === 'property_damage';
    const isInvolvedEmpty = possibleInvolvedProperties?.length === 0;
    const isEntityInvolved = possibleInvolvedEntities?.length > 0;

    let validation = undefined;
    if (isPropertyInvolved && !isUnspecified && !isEntityInvolved) {
      if (isInvolvedEmpty) {
        validation = Yup.number().required(
          'To add this exposure to the claim, you first need to add an involved property.'
        );
      } else {
        validation = Yup.number().required('Required');
      }
    }
    return validation;
  };

  const coverageConfigValidation = (newExposureStore) => {
    const { is_possible: isPossible, is_unspecified: isUnspecified } = newExposureStore;

    let validation = Yup.string().required('Required');
    if (isUnspecified && !isPossible) {
      validation = Yup.string().test(
        'is possible check',
        'This exposure is already open and can not be opened more than once',
        (coverageConfigID) => !coverageConfigID
      );
    }
    return validation;
  };

  return (
    <Formik
      initialValues={{ ...exposureFields }}
      validationSchema={Yup.object().shape({
        coverage_config_id: coverageConfigValidation(newExposureStore),
        covered_entity_id: claim?.type === 'general_claim' ? Yup.number().required('Required') : undefined,
        involved_entity_id: claim?.type === 'general_claim' ? involvedEntityValidation(newExposureStore) : undefined,
        involved_person_id: personIDValidation(newExposureStore),
        involved_property_id: propertyIDValidation(newExposureStore),
        should_use_assignment_rules: Yup.boolean().test('is_required', 'Required', (value) => {
          if (isAssignmentsEnabled) {
            return value !== undefined;
          }
          return true;
        }),
        handling_adjuster_id: Yup.string().when('should_use_assignment_rules', {
          is: (should_use_assignment_rules) =>
            !should_use_assignment_rules ||
            !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION),
          then: Yup.string().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={async (values, formikBag) => {
        try {
          const cleanValues = Object.fromEntries(
            Object.entries(values).map(([field, value]) => [field, value === '' ? null : value])
          );
          const response = await axios.post(`/api/v1/claims/${claim.id}/exposures`, cleanValues);
          await onAddExposure(response.id);
          formikBag.resetForm();
        } catch (error) {
          formikBag.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => (
        <AddExposureFormikInnerDialog
          open={open}
          onCancel={onCancel}
          newExposureStore={newExposureStore}
          onUpdateNewExposureStore={setNewExposureStore}
          {...formikProps}
        />
      )}
    </Formik>
  );
}

AddExposureDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAddExposure: PropTypes.func.isRequired,
};

export { AddExposureDialog as AddExposureDialogConfigurableCoverages };
