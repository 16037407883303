import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

import Button from '~/components/core/Atomic/Buttons/Button';
import { DocumentIcon } from '~/components/icons';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import LargeMediaContainer from '../Gallery/LargeMediaContainer';

import { EditDocumentDialog } from './DocumentCard';

export default function DocumentLink(props) {
  const { document, text, isMediaFile, indicateLinkIfRemoved, displayLinkAsButton } = props;
  const [isDocumentCardOpen, setIsDocumentCardOpen] = useState(false);

  const [claim, isLoading, isError] = useFetchClaim(document.claim_id);

  const isReady = !(isLoading || isError);

  const onClick = (e) => {
    e.preventDefault();
    setIsDocumentCardOpen(true);
  };

  return (
    <>
      {displayLinkAsButton && (
        <Button
          className="flex max-w-max cursor-pointer items-center rounded border border-solid border-slate-500 bg-slate-100 p-10 normal-case underline hover:border-teal-700	hover:underline"
          variant="outlined"
          onClick={onClick}
          startIcon={<DocumentIcon className="stroke-teal-700" size={16} />}
          classes={{ label: 'font-normal' }}
        >
          {text}
        </Button>
      )}
      {!displayLinkAsButton && (
        <Link href="" style={{ margin: 0, textDecoration: 'underline' }} onClick={onClick}>
          {text}
          {indicateLinkIfRemoved && document.is_removed && <em> (removed)</em>}
        </Link>
      )}
      {isDocumentCardOpen &&
        isReady &&
        (isMediaFile ? (
          <LargeMediaContainer
            media={document}
            mediaLink={`/api/v1/claims/${document.claim_id}/media/${document.id}`}
            onClose={() => setIsDocumentCardOpen(false)}
            shouldDisableLargePhotoActions
          />
        ) : (
          <EditDocumentDialog
            open={isDocumentCardOpen}
            claim={claim}
            onCancel={() => setIsDocumentCardOpen(false)}
            document={document}
            showOnly
          />
        ))}
    </>
  );
}

DocumentLink.propTypes = {
  document: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  isMediaFile: PropTypes.bool,
  indicateLinkIfRemoved: PropTypes.bool,
  displayLinkAsButton: PropTypes.bool,
};
