import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { reduce } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import useUnloadWindowEventListener from '~/components/hooks/useUnloadWindowEventListener';

import { serverDateToLocal } from '../../../DateTimeUtils';
import LoadingDialog from '../../LoadingDialog';
import usePerformanceQA from '../hooks/usePerformanceQA';

import ClaimQaFormCardFloating from './FormCardFloating';
import ClaimQaFormCardMinimized from './FormCardMinimized';
import ClaimQaFormDialog from './FormDialog';
import ClaimQaQuestion from './Question';
import { answersToAnswerStrings, answerToString, freeTextAnswersToAnswersStrings } from './utils';

import { useStyles } from '../../../assets/styles';

const ClaimQaCard = ({ onClose, onSubmit, claim, exposure }) => {
  const {
    isLoading,
    isError,
    reviewedAdjuster,
    isReviewed,
    reviewer,
    reviewSubmissionDatetime,
    getAutomaticAnswersDict,
    form,
    submit,
  } = usePerformanceQA({ claimId: claim.id, exposureId: exposure.id });

  const [cardVisibilityState, setCardVisibilityState] = React.useState('float');
  const { setDisableWarn } = useUnloadWindowEventListener();

  const classes = useStyles();

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} track="Individual Performance QA Review" onClose={onClose} />;
  }

  const adjusterReviewedName = reviewedAdjuster.name || exposure.handling_adjuster || claim.handling_adjuster;
  const adjusterReviewedId = reviewedAdjuster.id || exposure.handling_adjuster_id || claim.handling_adjuster_id;

  let ClaimQaFormContainer;
  switch (cardVisibilityState) {
    case 'full':
      ClaimQaFormContainer = ClaimQaFormDialog;
      break;
    case 'minimized':
      ClaimQaFormContainer = ClaimQaFormCardMinimized;
      break;
    case 'float':
    default:
      ClaimQaFormContainer = ClaimQaFormCardFloating;
      break;
  }

  const title = 'QA Review';
  const subtitle = (
    <>
      {`${exposure.label_text} / ${adjusterReviewedName}`}
      {isReviewed && (
        <Typography variant="subtitle2">
          {`Reviewed on ${serverDateToLocal(reviewSubmissionDatetime)} by ${reviewer}`}
        </Typography>
      )}
    </>
  );

  const handleQaSubmit = async (values, isDraft) => {
    await submit(values, isDraft);
    setDisableWarn(true);
    await onSubmit();
  };

  const freeTextQuestions = form.questions.filter((question) => question.is_free_text);
  const questions = form.questions.filter((question) => !question.is_free_text);

  let initialAnswers = reduce(questions, (acc, currQuestion) => ({ ...acc, [currQuestion.question_id]: '' }), {});
  let initialFreeTextAnswers = reduce(
    freeTextQuestions,
    (acc, currQuestion) => ({ ...acc, [currQuestion.question_id]: '' }),
    {}
  );

  const automaticAnswersDict = getAutomaticAnswersDict();
  for (const id in automaticAnswersDict) {
    // Override initial answer with automatic answer
    initialAnswers[id] = answerToString(automaticAnswersDict[id].answer);
  }

  if (form.answers) {
    // to handle partial forms - first fill defaults, then answers we have
    initialAnswers = { ...initialAnswers, ...answersToAnswerStrings(form.answers) };
  }

  if (form.free_text_answers) {
    initialFreeTextAnswers = { ...initialFreeTextAnswers, ...freeTextAnswersToAnswersStrings(form.freeTextAnswers) };
  }

  return (
    <Formik
      initialValues={{
        exposure_id: exposure.id,
        adjuster_reviewed_id: adjusterReviewedId,
        // TODO: refactor with Ticket: NGTPA-3862
        qa_form: form,
        answers: initialAnswers,
        free_text_answers: initialFreeTextAnswers,
      }}
      validationSchema={Yup.object().shape({
        answers: Yup.object().shape(
          reduce(
            form.questions,
            (acc, currQuestion) => ({
              ...acc,
              [currQuestion.question_id]: currQuestion.is_free_text
                ? Yup.string()
                : Yup.string().oneOf(['yes', 'no', 'n/a']).required('Required'),
            }),
            {}
          )
        ),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await handleQaSubmit(values, false);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        const questionsNum = questions.length;
        const questionsAnsweredNum = Object.values(values.answers).filter((ans) => ans).length;
        const questionsAnsweredYesNum = Object.values(values.answers).filter((ans) => ans === 'yes').length;
        const questionsAnsweredEligible = Object.values(values.answers).filter((ans) =>
          ['yes', 'no'].includes(ans)
        ).length;

        // helper function instead of calling handleSubmit to allow submitting partial forms without needing to feel all questions
        async function submitDraft() {
          try {
            formikProps.setSubmitting(true);
            await handleQaSubmit(values, true);
          } catch (error) {
            formikProps.setSubmitting(false);
          }
        }

        return (
          <ClaimQaFormContainer
            onClose={onClose}
            title={title}
            subtitle={subtitle}
            onChangeCardSize={setCardVisibilityState}
          >
            <Grid container>
              <>
                {questions.map(({ question_id, question_text, explanation }) => {
                  const automaticAnswer = automaticAnswersDict?.[question_id];

                  return (
                    <React.Fragment key={question_id}>
                      <ClaimQaQuestion
                        questionId={`answers.${question_id}`}
                        questionText={question_text}
                        disabled={isSubmitting || isReviewed}
                        explanation={explanation || automaticAnswer?.explanation}
                        automaticAnswer={automaticAnswer}
                      />
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
              <>
                {freeTextQuestions.map((qaQuestion) => (
                  <React.Fragment key={qaQuestion.question_id}>
                    <ClaimQaQuestion
                      questionId={`free_text_answers.${qaQuestion.question_id}`}
                      questionText={qaQuestion.question_text}
                      disabled={isSubmitting || isReviewed}
                      automaticAnswer={automaticAnswersDict?.[qaQuestion.question_id]}
                      isFreeText
                    />
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                ))}
              </>
              <div style={{ marginTop: '16px' }}>
                {questionsAnsweredNum < questionsNum ? (
                  <Typography variant="subtitle2">{`Questions answered: ${questionsAnsweredNum} / ${questionsNum}`}</Typography>
                ) : (
                  <>
                    <Typography variant="subtitle2">{`Score: ${(
                      (100 * questionsAnsweredYesNum) /
                      questionsAnsweredEligible
                    ).toFixed(2)}`}</Typography>
                    <Typography variant="subtitle2">{`(${questionsAnsweredYesNum} Yes out of ${questionsAnsweredEligible} Eligible questions)`}</Typography>
                  </>
                )}
              </div>
              {!isReviewed && (
                <div className={classes.buttonsContainer}>
                  <Button
                    className={classes.leftButtonDialog}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitDraft}
                  >
                    Save Draft
                  </Button>
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
              )}
            </Grid>
          </ClaimQaFormContainer>
        );
      }}
    </Formik>
  );
};

ClaimQaCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
};

export default ClaimQaCard;
