import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocalMoment } from '../../DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled, isKinClaim, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { SiuClaimSummaryChip } from '../ClaimSummary';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { PermissionsButtonWrapper } from '../core';
import { useCms } from '../hooks/useCms';
import HoverChangeField from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import LocationTextField from '../Location';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, { ShowOnlyTextField } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

var homeIncidentDetailsFields = {
  peril: '',
  is_complex: '',
  cause_of_loss: '',
  loss_location: null,
  description: '',
};

const spacing = 1;

function HomeIncidentDetailsCard(props) {
  const classes = useStyles();
  const { claim, onUpdate, readOnly } = props;
  const [isFetching, setIsFetching] = useState(false);
  const { incidentTypesDict } = useOrganization();
  const [showIncidentTypeUpdateDialog, setShowIncidentTypeUpdateDialog] = useState(false);

  const { userOrganization } = useCms();

  const perilsDict = incidentTypesDict['home_claim'];

  const disabled = readOnly || isFetching;

  const isDefenseAttorneyRequired = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DEFENSE_ATTORNEY);

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const handleUpdateFields = async (updateObject) => {
    // TODO - Add api to update only the incident
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/home_claims/${claim.id}`, updateObject);
      await onUpdate();
      setIsFetching(false);
    } catch (error) {
      reportAxiosError(error);
      setIsFetching(false);
      throw error;
    }
  };

  const handleChangeLitigation = async (fields) => {
    const claimLitigationUrl = `/api/v1/claims/${claim.id}/litigation`;
    const method = !claim.is_in_first_party_litigation ? 'post' : 'put';
    try {
      setIsFetching(true);
      await axios({
        method,
        url: claimLitigationUrl,
        data: {
          is_in_first_party_litigation: true,
          ...fields,
        },
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && (
            <IncidentMoreActionsContainer
              incident={claim.incident}
              onUpdateFields={handleUpdateIncidentFields}
              includeIsComplex
              includeAdvancedFirstPartyLitigation
              includeIsQra={isKinClaim(claim)}
              onChangeIsInFirstPartyLitigation={handleChangeLitigation}
              includesLitigationReferral={isDefenseAttorneyRequired}
            />
          )
        }
      >
        <IncidentMutualChips claim={claim} />
        {claim.incident.is_complex && <Chip size="small" color="primary" label="Complex" className={classes.chip} />}
        {isKinClaim(claim) && claim.is_qra && (
          <Tooltip title={claim.qra_note}>
            <Chip size="small" color="primary" label="QRA" className={classes.chip} />
          </Tooltip>
        )}
        {claim.is_in_first_party_litigation && (
          <Chip size="small" color="primary" label="In Litigation" className={classes.chip} />
        )}
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={claim.incident.incident_type_desc}
              label="Peril"
              disabled={disabled}
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            {claim.incident.cause_of_loss ? (
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.incident_sub_type_desc}
                label="Cause of Loss"
                disabled={disabled}
                onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
              />
            ) : (
              <Grid container alignItems="center" direction="row" style={{ height: '100%' }}>
                <Grid item>
                  <PermissionsButtonWrapper>
                    <Button color="primary" disabled={disabled} onClick={() => setShowIncidentTypeUpdateDialog(true)}>
                      Set Cause Of loss
                    </Button>
                  </PermissionsButtonWrapper>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <LocationTextField
              classes={classes}
              label="Loss Location"
              location={claim.incident['loss_location']}
              onChangeLocation={(lossLocation) => handleUpdateIncidentField('loss_location', lossLocation)}
              onEdit={() => {}}
              showOnly
              disabled={disabled}
              countriesConfigurationKey="loss_location"
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocalMoment(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="public_adjuster_contact_id"
              customFieldIdName="public_adjuster_contact"
              value={claim.incident.public_adjuster_contact_id || ''}
              label="Public Adjuster"
              onUpdate={(updatedValues) =>
                handleUpdateIncidentField('public_adjuster_contact_id', updatedValues['public_adjuster_contact_id'])
              }
              specialFieldType="contact"
              specialFieldAdditionalProps={{ acceptedRoles: ['public_adjuster'] }}
              overrideOnEdit
              showOnly
              disabled={disabled}
            >
              <ContactShowOnlyTextField
                contactId={claim.incident.public_adjuster_contact_id}
                contactDisplayName={claim.public_adjuster_contact_full_name}
                label="Public Adjuster"
                disabled={disabled}
                showOnly
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="first_party_attorney_contact_id"
              customFieldIdName="first_party_attorney_contact"
              validationSchema={claim.is_in_first_party_litigation ? Yup.number().required('Required') : undefined}
              value={claim.first_party_attorney_contact_id || ''}
              label="1st Party Attorney"
              onUpdate={(updatedValues) =>
                handleUpdateClaimField(
                  'first_party_attorney_contact_id',
                  updatedValues['first_party_attorney_contact_id']
                )
              }
              specialFieldType="contact"
              specialFieldAdditionalProps={{ acceptedRoles: ['attorney'] }}
              overrideOnEdit
              showOnly
              disabled={disabled}
            >
              <ContactShowOnlyTextField
                contactId={claim.first_party_attorney_contact_id}
                contactDisplayName={claim.first_party_attorney_contact_full_name}
                label="1st Party Attorney"
                disabled={disabled}
                showOnly
              />
            </HoverChangeField>
          </Grid>
          {isDefenseAttorneyRequired && (
            <Grid item xs={6}>
              <HoverChangeField
                name="defense_attorney_contact_id"
                customFieldIdName="defense_attorney_contact"
                validationSchema={claim.is_in_first_party_litigation ? Yup.number().required('Required') : undefined}
                value={claim.defense_attorney_contact_id || ''}
                label="Defense Attorney"
                onUpdate={(updatedValues) =>
                  handleUpdateClaimField('defense_attorney_contact_id', updatedValues['defense_attorney_contact_id'])
                }
                specialFieldType="contact"
                specialFieldAdditionalProps={{ acceptedRoles: ['defense_counsel'] }}
                overrideOnEdit
                showOnly
                disabled={disabled}
              >
                <ContactShowOnlyTextField
                  contactId={claim.defense_attorney_contact_id}
                  contactDisplayName={claim.defense_attorney_contact?.full_name}
                  label="Defense Attorney"
                  disabled={disabled}
                  showOnly
                />
              </HoverChangeField>
            </Grid>
          )}
          <Grid item xs={12}>
            <HoverChangeField
              name="special_internal_note"
              value={claim.special_internal_note}
              label="Special Internal Note"
              onUpdate={(updatedValues) =>
                handleUpdateClaimField('special_internal_note', updatedValues['special_internal_note'])
              }
              multiline
              fullWidth
              rows={5}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.special_internal_note}
                label="Special Internal Note"
                maxHeight="5.3em" // ~ 3 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <HoverChangeField
                name="description"
                value={claim.incident.description}
                label="Description"
                onUpdate={async (updatedValues) =>
                  await handleUpdateIncidentField('description', updatedValues['description'])
                }
                fullWidth
                multiline
                rows={8}
                width="450px"
                showOnly
                disabled={disabled}
              >
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={claim.incident.description}
                  label="Description"
                  maxHeight="12em" // ~ 3 line
                  width="100%"
                />
              </HoverChangeField>
            </Grid>
          </Grid>
        </Grid>
      </CardDialog>
      {showIncidentTypeUpdateDialog && (
        <HomeIncidentTypeUpdateDialog
          perilsDict={perilsDict}
          peril={claim.incident.incident_type}
          causeOfLoss={claim.incident.incident_sub_type}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowIncidentTypeUpdateDialog(false)}
        />
      )}
    </>
  );
}

HomeIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  // onUpdate is not required, but we disallow onEdit if undefined
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
};

function HomeIncidentTypeUpdateDialog({ perilsDict, peril, causeOfLoss, onUpdate, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ peril, cause_of_loss: causeOfLoss }}
      validationSchema={Yup.object().shape({
        peril: Yup.string().required('Required'),
        cause_of_loss: Yup.string().nullable(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        if (values.peril === peril && values.cause_of_loss === causeOfLoss) {
          await onClose();
          return;
        }

        try {
          await onUpdate(values);
          await onClose();
        } finally {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const selectPeril = values['peril'] || '';
        const perilCausesOfLossDict = perilsDict?.[selectPeril]?.sub_types || {};

        let incidentsTypeKeys = Object.keys(perilsDict);
        let incidentSubTypeKeysForIncidentType = Object.keys(perilCausesOfLossDict);

        return (
          <CardDialog
            isDialog={true}
            open
            title="Edit Incident Type"
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="peril"
                  select
                  label="Peril"
                  className={classes.textField}
                  disabled={isSubmitting}
                  onChange={(e) => {
                    const newPeril = e.target.value;
                    setFieldValue('peril', newPeril);
                    if (!(values['cause_of_loss'] in perilsDict?.[newPeril]?.sub_types)) {
                      setFieldValue('cause_of_loss', '');
                    }
                  }}
                  fullWidth
                >
                  {incidentsTypeKeys.map((option) => (
                    <MenuItem key={option} value={option}>
                      {perilsDict[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="cause_of_loss"
                  select
                  label="Cause of Loss"
                  disabled={isSubmitting}
                  className={classes.textField}
                  fullWidth
                >
                  {incidentSubTypeKeysForIncidentType.map((option) => (
                    <MenuItem key={option} value={option}>
                      {perilCausesOfLossDict[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

HomeIncidentTypeUpdateDialog.propTypes = {
  open: PropTypes.bool,
  peril: PropTypes.string.isRequired,
  causeOfLoss: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  perilsDict: PropTypes.object.isRequired,
};

function ChooseFirstPartyAttorney({ onSubmit, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ first_party_attorney_contact_id: '' }}
      validationSchema={Yup.object().shape({
        first_party_attorney_contact_id: Yup.number().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => (
        <CardDialog title="Set 1st party attorney" onClose={onClose} width="sm" isDialog open>
          <ContactTextFieldFormik
            id="first_party_attorney_contact"
            label="1st Party Attorney"
            className={classes.textField}
            acceptedRoles={['attorney']}
            fixedSearchResults
            fullWidth
          />

          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={formikProps.isSubmitting}
              onClick={formikProps.handleSubmit}
            >
              Update
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

ChooseFirstPartyAttorney.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { HomeIncidentDetailsCard, homeIncidentDetailsFields };
