import React from 'react';
import PropTypes from 'prop-types';

import { useContact } from '~/components/Contacts/UpsertContact/useContact';

const TabWrapper = ({ children }) => {
  const { isCreation } = useContact();
  return (
    <div className={`my-5 ${isCreation ? '' : 'h-[500px]'} overflow-y-auto overflow-x-hidden py-20`}>{children}</div>
  );
};

TabWrapper.propTypes = {
  children: PropTypes.node,
};

export default TabWrapper;
