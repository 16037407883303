import React from 'react';
import { useFormikContext } from 'formik';

import { FsMenuItem } from '~/components/core';
import { OptionsSelectFieldWithOtherText } from '~/components/LegalActions/components/OptionsSelectFieldWithOtherText';
import { LEGAL_ACTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import TextFieldFormik from '~/components/TextFieldFormik';

export const LawsuitDetailsForm = () => {
  const { lawsuitTypeOptions, natureOfDisputeOptions } = useLegalActionsConfiguration();
  const { values } = useFormikContext();

  const controllingFieldOptions = natureOfDisputeOptions.filter((option) =>
    option.categories.includes(values[LEGAL_ACTION_FIELDS.lawsuitType.key])
  );

  return (
    <>
      <div className="flex gap-30">
        <div className="flex w-1/2">
          <TextFieldFormik
            id={LEGAL_ACTION_FIELDS.lawsuitType.key}
            label={LEGAL_ACTION_FIELDS.lawsuitType.label}
            select
            fullWidth
          >
            {lawsuitTypeOptions.map((option) => (
              <FsMenuItem key={option.key} value={option.key}>
                {option.description}
              </FsMenuItem>
            ))}
          </TextFieldFormik>
        </div>
        <div className="flex w-1/2">
          <TextFieldFormik
            id={LEGAL_ACTION_FIELDS.complaintNumber.key}
            label={LEGAL_ACTION_FIELDS.complaintNumber.label}
            fullWidth
          />
        </div>
      </div>
      <OptionsSelectFieldWithOtherText
        controllingFieldKey={LEGAL_ACTION_FIELDS.natureOfDispute.key}
        controllingFieldLabel={LEGAL_ACTION_FIELDS.natureOfDispute.label}
        controllingFieldOptions={controllingFieldOptions}
        controlledFieldKey={LEGAL_ACTION_FIELDS.natureOfDisputeText.key}
        controlledFieldLabel={LEGAL_ACTION_FIELDS.natureOfDisputeText.label}
        disabled={!values[LEGAL_ACTION_FIELDS.lawsuitType.key]}
      />
    </>
  );
};
