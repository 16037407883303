import React from 'react';

import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import { FsMenuItem } from '~/components/core';
import { DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import {
  ArrayFieldFormPropTypes,
  getArrayFieldKey,
} from '~/components/LegalActions/FormFragments/LegalActionsFormUtils';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';

export const DisputeResolutionProfessionalForm = ({ arrayFieldPrefix, index }) => {
  const { disputeResolutionProfessionalTypeOptions } = useLegalActionsConfiguration();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <>
      <div className="flex gap-30">
        <div className="flex w-1/2">
          <TextFieldFormik
            id={getArrayFieldKey(DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.type.key, arrayFieldPrefix, index)}
            label={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.type.label}
            select
            fullWidth
          >
            {disputeResolutionProfessionalTypeOptions.map((option) => (
              <FsMenuItem key={option.key} value={option.key}>
                {option.description}
              </FsMenuItem>
            ))}
          </TextFieldFormik>
        </div>
        <div className="flex w-1/2">
          {/* This is hard coded because the contact component will compute the name of the field where the contact id is set */}
          <ContactTextFieldFormik
            id={getArrayFieldKey('contact', arrayFieldPrefix, index)}
            label={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.contact.label}
            acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
            fixedSearchResults={!arrayFieldPrefix}
            fullWidth
            nullIfEmpty
          />
        </div>
      </div>
      <div className="flex gap-30">
        <div className="flex w-1/2">
          <DatePickerTextFieldFormik
            containerClassName="w-full"
            fullWidth
            id={getArrayFieldKey(DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.startDate.key, arrayFieldPrefix, index)}
            label={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.startDate.label}
            nullIfEmpty
          />
        </div>
        <div className="flex w-1/2">
          <DatePickerTextFieldFormik
            containerClassName="w-full"
            fullWidth
            id={getArrayFieldKey(DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.endDate.key, arrayFieldPrefix, index)}
            label={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.endDate.label}
            nullIfEmpty
          />
        </div>
      </div>
    </>
  );
};

DisputeResolutionProfessionalForm.propTypes = ArrayFieldFormPropTypes;
