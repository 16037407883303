import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { capitalize } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import BackTextButton from '~/components/core/Buttons/BackTextButton';
import { COLUMN_KEYS, EXTENDABLE_COLUMN_KEYS } from '~/components/Finances/FinancesTable/constants';

import { PAYMENT_MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import mixpanel from '../../CmsMain/mixpanel';
import { LoadingSwitch } from '../../core';
import useCurrencyFormatter from '../../CurrencyFormatterContext';
import {
  getPolicyExposureLimit,
  getPolicyExposureLimitPerAccidentIfExists,
  isLimitExists,
} from '../../exposures/ExposureUtils';
import PaymentRequestContainer from '../../exposures/PaymentRequestContainer/PaymentRequestContainer';
import { ShowOnlyTextField } from '../../TextFieldFormik';
import FinancesTable from '../FinancesTable';

import { useStyles } from '../../../assets/styles';
import styles from './multiPaymentsDialog.module.scss';

const RESULTS_PER_PAGE = 10;

const MultiPaymentsDialog = ({
  claim,
  exposure,
  payableWithReserve,
  payableType,
  onClose,
  onUpdate,
  onMinimized,
  disableMinimized,
  open,
}) => {
  const [currentStep, setCurrentStep] = useState(null);
  const [finances, setFinances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [page, setPage] = useState(0);
  const [totalFinancesCount, setTotalFinancesCount] = useState(0);
  const { currencyFormatter } = useCurrencyFormatter();
  const classes = useStyles();

  const sortByColumn = { id: 'request_date', order: 'desc' };
  const claimId = claim.id;
  const exposureFilter = exposure && exposure.id ? exposure.id : 'general';

  const policyExposureLimit = getPolicyExposureLimit(claim, exposure);
  const policyExposureLimitPerAccident = getPolicyExposureLimitPerAccidentIfExists(exposure);
  const isDifferPerPersonAndAccident = !!policyExposureLimitPerAccident && policyExposureLimitPerAccident > -1;

  const getFinances = useCallback(async () => {
    const params = {
      page_number: page + 1,
      results_per_page: RESULTS_PER_PAGE,
      exposures_filters: [exposureFilter],
      payable_types_filter: [payableType],
    };

    try {
      const { data } = await axios.get(`/api/v1/claims/${claimId}/finances`, { params });
      setFinances(data.finances);
      setTotalFinancesCount(data.count);
      setIsLoading(false);
    } catch (err) {
      setIsError(true);
      await reportAxiosError(err);
    }
  }, [claimId, exposureFilter, payableType, page]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getFinances();
  }, [getFinances]);

  const handleUpdate = async () => {
    await onUpdate();
    await getFinances();
  };

  const handleAddPaymentClick = () => {
    setCurrentStep('add payment');
    mixpanel.track(PAYMENT_MIXPANEL_EVENTS.SET_PAYMENT_CLICKED);
  };

  const handleInnerStepClose = () => {
    setCurrentStep(null);
    setIsSubmittingPayment(false);
  };

  const getDialogTitle = () => {
    if (currentStep) {
      return <BackTextButton disabled={isSubmittingPayment} onClick={handleInnerStepClose} />;
    }

    if (!exposure.label_text) {
      return exposure.isGeneralExpenses ? 'General Expenses' : exposure.coverage_type_desc;
    } else if (claim.type === 'wc_claim') {
      return exposure.label_text;
    } else {
      return `${exposure.label_text} - ${capitalize(payableType)}`;
    }
  };

  return (
    <CardDialog
      isDialog
      title={getDialogTitle()}
      onClose={currentStep ? handleInnerStepClose : onClose}
      maxWidth="md"
      fullWidth
      onMinimized={onMinimized}
      open={open}
      disableMinimized={disableMinimized}
      preventClose={isSubmittingPayment}
    >
      {!currentStep && (
        <Grid container className={styles.multiPaymentsDialogContainer}>
          <Grid item container xs={12} direction="row" spacing={3}>
            <Grid item>
              <ShowOnlyTextField
                label="Total Paid Currently"
                classes={classes}
                showOnlyValueComponent={currencyFormatter.format(payableWithReserve.paid_sum)}
              />
            </Grid>
            {exposure.damage_assessment && Number.isFinite(exposure.damage_assessment?.amount) && (
              <Grid item>
                <ShowOnlyTextField
                  label="Damage Assessment"
                  classes={classes}
                  showOnlyValueComponent={currencyFormatter.format(exposure.damage_assessment?.amount)}
                />
              </Grid>
            )}
            {isLimitExists(policyExposureLimit) && (
              <Grid item>
                <ShowOnlyTextField
                  label={`Coverage limit${isDifferPerPersonAndAccident ? ' per person' : ''}`}
                  classes={classes}
                  showOnlyValueComponent={currencyFormatter.format(policyExposureLimit)}
                />
              </Grid>
            )}
            {isDifferPerPersonAndAccident && (
              <Grid item>
                <ShowOnlyTextField
                  label="Coverage limit per incident"
                  classes={classes}
                  showOnlyValueComponent={currencyFormatter.format(policyExposureLimitPerAccident)}
                />
              </Grid>
            )}
            {Number.isFinite(exposure.policy_deductible) && (
              <Grid item>
                <ShowOnlyTextField
                  label="Deductible"
                  classes={classes}
                  showOnlyValueComponent={currencyFormatter.format(exposure.policy_deductible)}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider className={styles.divider} />
          </Grid>
          <Grid item container xs={12} justify="flex-end" direction="row">
            <Button color="primary" onClick={handleAddPaymentClick}>
              <AddIcon />
              Add payment
            </Button>
          </Grid>
          <Grid item xs={12}>
            <LoadingSwitch isLoading={isLoading} isError={isError}>
              <FinancesTable
                finances={finances}
                paginationProps={{
                  page,
                  rowsPerPage: RESULTS_PER_PAGE,
                  onChangePage: (e, newPage) => {
                    setPage(newPage);
                  },
                  count: totalFinancesCount || 0,
                  rowsPerPageOptions: [RESULTS_PER_PAGE],
                }}
                displayColumnIds={DISPLAY_COLUMN_IDS}
                sortByColumn={sortByColumn}
                onReloadFinances={handleUpdate}
              />
            </LoadingSwitch>
          </Grid>
        </Grid>
      )}
      {currentStep && (
        <div className={styles.innerPaymentRequestContainer}>
          <PaymentRequestContainer
            claim={claim}
            exposure={exposure}
            payableWithReserve={payableWithReserve}
            payableType={payableType}
            cardDialogProps={{
              maxWidth: 'md',
              fullWidth: true,
            }}
            onUpdate={async () => {
              await handleUpdate();
              handleInnerStepClose();
            }}
            setIsSubmitting={setIsSubmittingPayment}
          />
        </div>
      )}
    </CardDialog>
  );
};

MultiPaymentsDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

const DISPLAY_COLUMN_IDS = [
  COLUMN_KEYS.request_date,
  COLUMN_KEYS.financial_status_last_update,
  COLUMN_KEYS.request_status,
  COLUMN_KEYS.financial_status,
  COLUMN_KEYS.amount,
  COLUMN_KEYS.actions,

  EXTENDABLE_COLUMN_KEYS.issued_by,
  EXTENDABLE_COLUMN_KEYS.payment_type,
  EXTENDABLE_COLUMN_KEYS.payees,
  EXTENDABLE_COLUMN_KEYS.sent_to,
  EXTENDABLE_COLUMN_KEYS.reference,
  EXTENDABLE_COLUMN_KEYS.note,
  EXTENDABLE_COLUMN_KEYS.deductible_applied,
  EXTENDABLE_COLUMN_KEYS.payment_method,
];

export default MultiPaymentsDialog;
