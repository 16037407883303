import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { IconButton, MenuItem } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import { Formik } from 'formik';
import { BadgeAccountHorizontalOutline, TextBoxCheckOutline } from 'mdi-material-ui';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, isInshurUkClaim, isQoverClaim, isTscUser, reportAxiosError } from '~/Utils';
import { findInvolvedProperty, useFetchClaim } from '~/Utils/ClaimUtils';

import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { ClaimContextProvider, useClaim } from '../ClaimContainer';
import SendEmailCommunicationCardContainer from '../communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import { EmailIcon } from '../icons/notifications';
import PencilIcon from '../icons/PencilIcon';
import LoadingDialog from '../LoadingDialog';
import LoadingIndicator from '../LoadingIndicator';
import { DatePickerTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import DamageAssessmentReinspectionDialog from './DamageAssessmentReinspectionDialog';
import { FormikEstimationDocuments } from './EstimationDocumentsContainer';
import ExposureTooltipedLock from './ExposureTooltipedLock';
import { getAutoPersonCoverages, isExposureWriteDisabled } from './ExposureUtils';

function useDamageAssessmentReinspection(claimId, exposure, damageAssessment) {
  const [reinspection, setReinspection] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const { user } = useCms();

  const fetchDamageAssessmentReinspection = React.useCallback(() => {
    if (!user.is_org_level_supervisor || !damageAssessment) {
      setIsLoading(false);
      return Promise.resolve();
    }

    return axios
      .get(`/api/v1/claims/${claimId}/exposures/${exposure.id}/damage_assessments/${damageAssessment.id}/reinspection`)
      .then((response) => {
        setReinspection(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        reportAxiosError(error);
        setIsError(true);
      });
  }, [claimId, exposure.id, user, damageAssessment]);

  React.useEffect(() => {
    fetchDamageAssessmentReinspection();
  }, [fetchDamageAssessmentReinspection]);

  return [reinspection, fetchDamageAssessmentReinspection, isLoading, isError];
}

function DamageAssessmentContainer(props) {
  const { claimId, exposure, viewOnly, iconOnly, onUpdate, enableReinspection } = props;

  const { claim: claimInContext, onAsyncClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { damage_assessment, methods_of_inspection } = exposure;
  const newDamageAssessmentInitialState =
    !damage_assessment || damage_assessment.amount === null || damage_assessment.amount === undefined;
  const [newDamageAssessment, setNewDamageAssessment] = useState(newDamageAssessmentInitialState);

  const damageAssessmentReinspectionClaimId = claimId ? claimId : claimInContext.id;
  const [reinspectionReport, onUpdateReinspectionReport] = useDamageAssessmentReinspection(
    damageAssessmentReinspectionClaimId,
    exposure,
    damage_assessment
  );
  const [reinspectionOpen, setReinspectionOpen] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState({ open: false });

  const viewOnlyDamageAssessment = viewOnly || isExposureWriteDisabled(exposure, user);
  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );

  if (isConfigurableCoveragesEnabled && exposure.involved_person && claimInContext?.type !== 'general_claim') {
    return <></>;
  }

  if (
    !isConfigurableCoveragesEnabled &&
    getAutoPersonCoverages()
      .filter((cov) => cov !== 'coverage_eco_xpl')
      .concat(['coverage_e', 'coverage_f'])
      .includes(exposure.coverage_type)
  ) {
    return <></>;
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewDamageAssessment(newDamageAssessmentInitialState);
  };

  function onUpdateIfExists() {
    if (onUpdate) {
      return onUpdate();
    } else {
      return Promise.resolve();
    }
  }

  const onSubmitDamageAssessment = (values) => {
    return axios
      .post(`/api/v1/claims/${claimId}/exposures/${exposure.id}/damage_assessments`, values)
      .then(() => {
        if (claimInContext) {
          return onAsyncClaimUpdate();
        } else {
          return Promise.resolve();
        }
      })
      .then(() => onUpdateIfExists())
      .then(() => setNewDamageAssessment(values.amount === null || values.amount === undefined))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      })
      .then(() => setIsDialogOpen(false));
  };

  // DamageAssessmentContainer appears also in payment request approval dialog at the home screen. So to prevent crash we check the existence of claimIncContext
  // Actually, in the flow of payment request approval from the home page the claim is fetched, but not propagate in here. Theoretically we could pass it / wrap this in claim context provider.
  // But for now, this means that the DamageAssessment itself wont show in the approval dialog (even if exists) at the home page, but will appear inside the claim page
  if (!(claimInContext && ['pet_claim'].includes(claimInContext.type))) {
    // Skip this check for the above claim type
    if (
      exposure.is_coverage_issue_exists ||
      (!isConfigurableCoveragesEnabled &&
        (!methods_of_inspection.filter((moi) => moi.is_active).length ||
          (!damage_assessment && viewOnlyDamageAssessment)))
    ) {
      return <ExposureTooltipedLock exposure={exposure} />;
    }
  }

  if (!damage_assessment && viewOnlyDamageAssessment) {
    return <></>;
  }

  function getIconComponentForDa(damage_assessment) {
    if (!damage_assessment.is_estimation_report_exists) {
      return <BadgeAccountHorizontalOutline />;
    } else {
      return <TextBoxCheckOutline />;
    }
  }

  return (
    <>
      <HoverActionField
        icon={viewOnlyDamageAssessment ? InfoIcon : PencilIcon}
        onAction={() => setIsDialogOpen(true)}
        permanent={!damage_assessment}
        actionAriaLabel="Set damage assessment"
      >
        {damage_assessment ? (
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            {getIconComponentForDa(damage_assessment)}
            {!iconOnly && damage_assessment.amount !== null && (
              <>&nbsp;{currencyFormatter.format(damage_assessment.amount)}</>
            )}
          </span>
        ) : (
          <em>Set</em>
        )}
      </HoverActionField>
      {isDialogOpen && (
        <DamageAssessmentCard
          claimId={claimId}
          exposure={exposure}
          isNewDamageAssessment={newDamageAssessment}
          damageAssessment={damage_assessment}
          cardDialogProps={{
            isDialog: true,
            open: isDialogOpen,
            closeOnBackdropClick: damage_assessment && !newDamageAssessment,
          }}
          onSendEmailClick={(damageAssessment) => setShowSendEmail({ open: true, damageAssessment })}
          viewOnly={viewOnlyDamageAssessment}
          onSubmitDamageAssessment={onSubmitDamageAssessment}
          onSelectNewDamageAssessment={() => setNewDamageAssessment(true)}
          onCancel={handleCloseDialog}
          onReinspect={
            user.is_org_level_supervisor && !isTscUser(user) && enableReinspection && !reinspectionReport
              ? () => setReinspectionOpen(true)
              : undefined
          }
          onViewReinspectionReport={
            !isTscUser(user) && reinspectionReport ? () => setReinspectionOpen(true) : undefined
          }
        />
      )}
      {damage_assessment && (
        <DamageAssessmentReinspectionDialog
          cardDialogProps={{
            open: reinspectionOpen,
            onClose: () => setReinspectionOpen(false),
            closeOnBackdropClick: !!reinspectionReport,
          }}
          claimId={claimId}
          exposure={exposure}
          damageAssessment={damage_assessment}
          reinspectionReport={reinspectionReport}
          onCompleteReinspection={() => {
            onUpdateReinspectionReport()
              .then(() => onUpdateIfExists())
              .then(() => setReinspectionOpen(false));
          }}
        />
      )}
      {showSendEmail.open && (
        <SendEmailOfDamageAssessment
          claimId={claimId}
          exposure={exposure}
          damageAssessment={showSendEmail.damageAssessment}
          onCancel={() => setShowSendEmail({ open: false })}
          onSendEmail={async () => {
            if (claimInContext) {
              await onAsyncClaimUpdate();
            }
            setShowSendEmail({ open: false });
          }}
        />
      )}
    </>
  );
}

DamageAssessmentContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  enableReinspection: PropTypes.bool,
  viewOnly: PropTypes.bool,
  iconOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
};

function SendEmailOfDamageAssessment({ claimId, exposure, damageAssessment, onCancel, onSendEmail }) {
  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(claimId);

  return isLoading || isError ? (
    <LoadingIndicator isError={isError} />
  ) : (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <SendEmailCommunicationCardContainer
        onClose={onCancel}
        onSendEmail={onSendEmail}
        emailTitle={`Regarding damage assessment in claim: ${claim.claim_id_display}, exposure # ${exposure.claim_internal_id}`}
        attachments={claim.documents
          .filter((document) => damageAssessment.estimation_reports_ids.includes(document.id))
          .map((document) => ({ ...document, attachment_filename: document.document_name_with_ext }))}
      />
    </ClaimContextProvider>
  );
}

SendEmailOfDamageAssessment.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  damageAssessment: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSendEmail: PropTypes.func.isRequired,
};

const damageAssessmentFields = {
  estimation_reports_ids: [],
  amount: '',
  inspection_date: '',
  note: '',
  was_roof_repair_replace: '',
  roof_repair_replace: '',
  was_windscreen_repair_replace: '',
  windscreen_repair_replace: '',
};

function DamageAssessmentCard(props) {
  const {
    claimId,
    exposure,
    isNewDamageAssessment,
    damageAssessment,
    viewOnly,
    onSubmitDamageAssessment,
    onSelectNewDamageAssessment,
    cardDialogProps,
    onCancel,
    onReinspect,
    onViewReinspectionReport,
    onSendEmailClick,
  } = props;
  const { user } = useCms();
  const classes = useStyles();
  const { currencyFormatter, groupSeparator, decimalSeparator, symbol } = useCurrencyFormatter();

  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(claimId);

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} track="Damage Assessment - Loading Claim" />;
  }

  let initialValues = damageAssessment ? { ...damageAssessment } : { ...damageAssessmentFields };

  const isInvolvedPropertyVehicle =
    exposure.involved_property && exposure.involved_property.type === 'involved_vehicle';
  if (isInvolvedPropertyVehicle) {
    const involvedVehicle = findInvolvedProperty(claim, exposure.involved_property);
    initialValues['is_total_loss'] = involvedVehicle.is_total_loss === 'yes' || involvedVehicle.is_total_loss === true;
  }

  const isWindscreenRepairReplaceRequired = isInshurUkClaim(claim);

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(
          isQoverClaim(claim)
            ? { amount: Yup.number().required('Required') }
            : {
                estimation_reports_ids: Yup.array().required().min(1, 'At least one estimation report is required'),
                windscreen_repair_replace: isWindscreenRepairReplaceRequired
                  ? Yup.string()
                      .nullable()
                      .when('was_windscreen_repair_replace', {
                        is: (val) => val === true,
                        then: Yup.string().nullable().required('Required'),
                      })
                  : undefined,
              }
        )}
        enableReinitialize
        onSubmit={(values, formikProps) => {
          onSubmitDamageAssessment(values)
            .then(() => formikProps.resetForm())
            .catch(() => {
              formikProps.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit, setFieldValue, setFieldTouched, values } = formikProps;
          return (
            <CardDialog
              title="Damage Assessment"
              maxWidth="lg"
              action={
                onSendEmailClick ? (
                  <IconButton
                    onClick={() => onSendEmailClick(values)}
                    disabled={isSubmitting || user.role.is_view_only}
                  >
                    <EmailIcon />
                  </IconButton>
                ) : undefined
              }
              onClose={() => {
                formikProps.handleReset();
                onCancel();
              }}
              preventClose={isSubmitting}
              {...cardDialogProps}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <FormikEstimationDocuments
                    claim={claim}
                    exposureId={exposure.id}
                    showOnly={!isNewDamageAssessment}
                    id="estimation_reports_ids"
                    maxHeight="300px"
                    disableDocumentDate
                  />
                  <ErrorHelperTextFormik id="estimation_reports_ids" />
                </Grid>
                {isWindscreenRepairReplaceRequired && (
                  <Grid item xs={12} container>
                    <div>
                      <YesNoQuestionFormik
                        questionText="Was windscreen repair / replace required?"
                        id="was_windscreen_repair_replace"
                        disabled={!isNewDamageAssessment}
                      />
                      {values['was_windscreen_repair_replace'] && (
                        <TextFieldFormik
                          id="windscreen_repair_replace"
                          label="windscreen Resolution"
                          className={classes.textField}
                          disabled={!isNewDamageAssessment}
                          fullWidth
                          select
                        >
                          {['Repair', 'Replace'].map((k) => (
                            <MenuItem key={k} value={k}>
                              {k}
                            </MenuItem>
                          ))}
                        </TextFieldFormik>
                      )}
                    </div>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <DatePickerTextFieldFormik
                    id="inspection_date"
                    label={claim.type === 'pet_claim' ? 'Date of visit' : 'Inspection Date'}
                    className={classes.textField}
                    disableFuture
                    disabled={!isNewDamageAssessment}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7} />
                <Grid item xs={5}>
                  {/* TODO use MonetaryValueTextFieldFormik */}
                  <NumberFormat
                    customInput={TextFieldFormik}
                    thousandSeparator={groupSeparator ? groupSeparator : true}
                    decimalSeparator={decimalSeparator || '*'} // decimal separator must have 1 character value
                    decimalScale={!decimalSeparator ? 0 : 2} // if currency does not have decimalSeparator set decimalScale to 0
                    fixedDecimalScale
                    allowNegative={false}
                    prefix={symbol}
                    id="amount"
                    label="Amount"
                    placeholder={currencyFormatter.format(0)}
                    value={values['amount']}
                    className={classes.textField}
                    onValueChange={(numberValues) => {
                      const { floatValue } = numberValues;
                      setFieldValue('amount', floatValue);
                    }}
                    onBlur={() => setFieldTouched('amount', true)}
                    fullWidth
                    disabled={!isNewDamageAssessment}
                  />
                </Grid>
                {isInvolvedPropertyVehicle && (
                  <Grid item xs={12}>
                    <CheckboxFormik id="is_total_loss" label="Total loss" disabled={!isNewDamageAssessment} />
                  </Grid>
                )}
                <Grid item xs={7} />
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="note"
                    label="Note"
                    className={classes.textField}
                    disabled={!isNewDamageAssessment}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonsContainer}>
                {!isNewDamageAssessment ? (
                  <>
                    {!viewOnly && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={onSelectNewDamageAssessment}
                      >
                        Update Damage Assessment
                      </Button>
                    )}
                    {onReinspect && (
                      <Button
                        className={classes.rightButtonIcon}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={onReinspect}
                      >
                        Reinspect
                      </Button>
                    )}
                    {onViewReinspectionReport && (
                      <Button
                        className={classes.rightButtonIcon}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={onViewReinspectionReport}
                      >
                        View Reinspection
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {!viewOnly && (
                      <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                        Set Damage Assessment
                      </Button>
                    )}
                  </>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </ClaimContextProvider>
  );
}

DamageAssessmentCard.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  damageAssessment: PropTypes.object,
  isNewDamageAssessment: PropTypes.bool.isRequired,
  cardDialogProps: PropTypes.object.isRequired,
  onSubmitDamageAssessment: PropTypes.func.isRequired,
  onSelectNewDamageAssessment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReinspect: PropTypes.func,
  onViewReinspectionReport: PropTypes.func,
  viewOnly: PropTypes.bool,
  onSendEmailClick: PropTypes.func,
};

export default DamageAssessmentContainer;
