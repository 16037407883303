import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Tooltip } from '@material-ui/core';
import { get } from 'lodash';

import { adjustInvolvedPerson } from '~/components/Fnol/NewFnolUI/InvolvedParties/GeneralClaimParties';
import { useLob } from '~/components/hooks/useLob';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { useInvolvedAttorneys } from '~/components/LegalActions/hooks/useInvolvedAttorneys';
import useDataFetcher from '~/components/useDataFetcher';
import cn from '~/Utils/cn';

import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isFeatureEnabled } from '../Utils';
import { findIncidentParty, findInvolvedPerson, useFetchClaim } from '../Utils/ClaimUtils';

import { ShowOnlyDriverInvolvedDialog } from './Auto/AutoInvolved/DriverInvolved';
import { ShowOnlyNonMotoristInvolvedDialog } from './Auto/AutoInvolved/NonMotoristInvolved';
import { ShowOnlyPassengerInvolvedDialog } from './Auto/AutoInvolved/PassengerInvolved';
import { ReadOnlyCustomInvolvedPartyDialog } from './Custom/CustomInvolvedParties';
import { ShowOnlyDriverInvolvedDialog as ShowOnlyDriverInvolvedDialogNew } from './Fnol/NewFnolUI/InvolvedParties/AutoParties/DriverInvolved';
import { ShowOnlyNonMotoristInvolvedDialog as ShowOnlyNonMotoristInvolvedDialogNew } from './Fnol/NewFnolUI/InvolvedParties/AutoParties/NonMotoristInvolved';
import { ShowOnlyPassengerInvolvedDialog as ShowOnlyPassengerInvolvedDialogNew } from './Fnol/NewFnolUI/InvolvedParties/AutoParties/PassengerInvolved';
import { ShowOnlyInvolvedPersonDialog as ShowOnlyInvolvedPersonDialogNew } from './Fnol/NewFnolUI/InvolvedParties/InvolvedPersonParty';
import { InvolvedPersonFragment } from './Fnol/NewFnolUI/InvolvedParties/InvolvedPersonParty/InvolvedPersonFragment';
import { ShowOnlyInjuredPersonDialog } from './Home/InjuredPerson';
import { useCms } from './hooks/useCms';
import { ShowOnlyEmployeeInvolvedDialog } from './WC/EmployeeInvolved';
import { ClaimContextProvider, useClaim } from './ClaimContainer';
import { ContactEntity } from './Contact';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import { BriefcaseIcon, InfoIcon } from './icons';
import InlineIconButton from './InlineIconButton';
import LoadingDialog from './LoadingDialog';
import { PolicyContextProvider } from './PolicyContainer';

import { useStyles } from '../assets/styles';

function InvolvedPersonHoverInfo(props) {
  const { claimId, involvedPerson, textualButton, textualExtraInfo } = props;
  const { claim } = useClaim();
  const [openInfo, setOpenInfo] = useState(false);
  const classes = useStyles();

  function getPersonTypeDesc(involvedPerson) {
    if (involvedPerson.type === 'involved_driver') {
      return 'Driver';
    } else if (involvedPerson.type === 'involved_passenger') {
      return 'Passenger';
    } else if (involvedPerson.type === 'involved_non_motorist') {
      return 'Non Motorist';
    } else if (involvedPerson.type === 'involved_person') {
      return 'Injured Person';
    } else if (involvedPerson.type === 'involved_employee') {
      return 'Injured Employee';
    } else if (involvedPerson.type === 'custom_involved_person') {
      const peopleConfigurations = claim.custom_configuration.configuration.involved_parties.people;
      const personConfig = peopleConfigurations.find((config) => config.id === involvedPerson.custom_type);
      return personConfig.desc;
    } else {
      throw Error(`Unknown involved person type ${involvedPerson.type}`);
    }
  }

  return (
    <>
      {textualButton ? (
        <Link
          component="button"
          variant="body2"
          onClick={() => setOpenInfo(true)}
          className={`${classes.primaryLink} text-base font-normal`}
        >
          {getPersonTypeDesc(involvedPerson)}
          {textualExtraInfo ? ` (${textualExtraInfo})` : ''}
        </Link>
      ) : (
        <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.READ}>
          <InlineIconButton
            className={cn(classes.inLineButtonsContainer, classes.hoverableIcon)}
            iconStyle={{ paddingLeft: '2px' }}
            onClick={() => setOpenInfo(true)}
            icon={InfoIcon}
          />
        </RestrictedPermissions>
      )}
      {openInfo && (
        <ShowOnlyInvolvedPersonDialog
          claimId={claimId}
          involvedPerson={involvedPerson}
          onClose={() => setOpenInfo(false)}
        />
      )}
    </>
  );
}

InvolvedPersonHoverInfo.propTypes = {
  claimId: PropTypes.number.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  textualButton: PropTypes.bool,
  textualExtraInfo: PropTypes.string,
};

function ShowOnlyInvolvedPersonDialog({ claimId, involvedPerson, onClose }) {
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(claimId);
  const classes = useStyles();
  const { lob } = useLob();
  const { userOrganization } = useCms();
  const isNewFnolUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const { lobConfigurationsDict } = useLobConfiguration();
  const {
    isLoading: isLoadingInvolved,
    isError: isErrorInvolved,
    data: involvedFetchedOnNewFnol,
  } = useDataFetcher(`/api/v1/claims/${claimId}/involved_person/${involvedPerson.id}`);

  const isLoading = isLoadingClaim || isLoadingInvolved;
  const isError = isErrorClaim || isErrorInvolved;

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track="Show Only Involved Person" />;
  }

  let fullInvolvedPerson = isNewFnolUIEnabled ? involvedFetchedOnNewFnol : findInvolvedPerson(claim, involvedPerson);
  if (claim.type === 'general_claim') {
    fullInvolvedPerson = adjustInvolvedPerson(fullInvolvedPerson);
  }

  const getShowOnlyPersonDialog = (involvedPerson, onClose) => {
    if (fullInvolvedPerson.type === 'involved_driver') {
      return (
        <ShowOnlyDriverInvolvedDialog
          classes={classes}
          driver={fullInvolvedPerson}
          onCancel={onClose}
          isInsured={findIncidentParty(claim, fullInvolvedPerson.incident_party_id).is_first_party}
          open
        />
      );
    } else if (fullInvolvedPerson.type === 'involved_passenger') {
      return <ShowOnlyPassengerInvolvedDialog passenger={fullInvolvedPerson} onCancel={onClose} open />;
    } else if (fullInvolvedPerson.type === 'involved_non_motorist') {
      return <ShowOnlyNonMotoristInvolvedDialog nonMotorist={fullInvolvedPerson} onCancel={onClose} open />;
    } else if (fullInvolvedPerson.type === 'involved_person') {
      return <ShowOnlyInjuredPersonDialog injuredPerson={fullInvolvedPerson} onCancel={onClose} open />;
    } else if (fullInvolvedPerson.type === 'involved_employee') {
      return <ShowOnlyEmployeeInvolvedDialog employee={fullInvolvedPerson} onCancel={onClose} open />;
    } else if (fullInvolvedPerson.type === 'custom_involved_person') {
      return <ReadOnlyCustomInvolvedPartyDialog isPerson party={fullInvolvedPerson} onCancel={onClose} open />;
    } else {
      throw Error(`Unknown involved Person type ${fullInvolvedPerson.type}`);
    }
  };

  const getShowOnlyPersonDialogNew = (involvedPerson, onClose) => {
    if (fullInvolvedPerson.type === 'involved_driver') {
      return (
        <ShowOnlyDriverInvolvedDialogNew
          driver={fullInvolvedPerson}
          onCancel={onClose}
          isInsured={findIncidentParty(claim, fullInvolvedPerson.incident_party_id).is_first_party}
          open
        />
      );
    } else if (fullInvolvedPerson.type === 'involved_passenger') {
      return <ShowOnlyPassengerInvolvedDialogNew passenger={fullInvolvedPerson} onCancel={onClose} open />;
    } else if (fullInvolvedPerson.type === 'involved_non_motorist') {
      return <ShowOnlyNonMotoristInvolvedDialogNew nonMotorist={fullInvolvedPerson} onCancel={onClose} open />;
    } else if (fullInvolvedPerson.type === 'involved_person' && fullInvolvedPerson?.general_claim_involved_type) {
      const involvedPartiesConfig = get(lobConfigurationsDict, `${lob}.involved_parties`, []);
      const involvedConfig = involvedPartiesConfig?.find(
        (involved) => involved?.id === fullInvolvedPerson?.general_claim_involved_type
      );

      return (
        <ShowOnlyInvolvedPersonDialogNew
          involvedPerson={fullInvolvedPerson}
          InvolvedPersonFragmentOverride={InvolvedPersonFragment}
          propertyLabel={involvedConfig?.desc}
          onCancel={onClose}
          open
        />
      );
    } else if (fullInvolvedPerson.type === 'involved_person') {
      return (
        <ShowOnlyInvolvedPersonDialogNew
          involvedPerson={fullInvolvedPerson}
          InvolvedPersonFragmentOverride={InvolvedPersonFragment}
          onCancel={onClose}
          open
        />
      );
    } else {
      throw Error(`Unknown involved Person type ${fullInvolvedPerson.type}`);
    }
  };

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <PolicyContextProvider policy={claim.policy}>
        {isNewFnolUIEnabled
          ? getShowOnlyPersonDialogNew(involvedPerson, onClose)
          : getShowOnlyPersonDialog(involvedPerson, onClose)}
      </PolicyContextProvider>
    </ClaimContextProvider>
  );
}

ShowOnlyInvolvedPersonDialog.propTypes = {
  claimId: PropTypes.number.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

function InvolvedPersonInfoTitle({ claimId, involvedPerson, exposureId }) {
  const classes = useStyles();

  const { attorneys, attorneyNames } = useInvolvedAttorneys(involvedPerson, exposureId);

  const attorneyTooltip = attorneys.length > 0 ? `Represented by ${attorneyNames}` : null;

  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <ContactEntity
        classes={classes}
        contactId={involvedPerson.contact_id}
        contactDisplayName={involvedPerson.contact_full_name}
      />
      <InvolvedPersonHoverInfo claimId={claimId} involvedPerson={involvedPerson} />
      {attorneyTooltip && (
        <Tooltip title={attorneyTooltip}>
          <span style={{ display: 'flex', paddingLeft: '6px' }}>
            <BriefcaseIcon />
          </span>
        </Tooltip>
      )}
    </span>
  );
}

InvolvedPersonInfoTitle.propTypes = {
  involvedPerson: PropTypes.object.isRequired,
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number,
};

export { InvolvedPersonHoverInfo, InvolvedPersonInfoTitle };
