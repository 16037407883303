import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import OverflowArrayTextDisplayWithTooltip from '~/components/core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { CONFIGURATION_FEATURES_NAMES, CONSTANTS } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { getClaimsTableColumnsByUser } from './ClaimsTable';
import useOrganization from './OrganizationContext';
import PlainTable from './PlainTable';

const spacing = 1;

const ClaimSearch = (props) => {
  const { classes, searchValue, onChangeSearch, onSearch, disabled, errorMsg, isSearchInProgress, label } = props;

  return (
    <Card>
      <CardContent>
        {label && <Typography variant="subtitle2">{label}</Typography>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch();
          }}
        >
          <div className={classes.containerCentered}>
            <TextField
              className={classes.textField}
              InputProps={{
                placeholder: 'Search for claim by: claim number, policy number, insured name or claimant name',
                endAdornment: isSearchInProgress ? (
                  <InputAdornment position="end">
                    <CircularProgress size={32} />
                  </InputAdornment>
                ) : null,
              }}
              disabled={disabled}
              fullWidth
              value={searchValue}
              onChange={(e) => onChangeSearch(e.target.value)}
              error={!!errorMsg}
              helperText={errorMsg && 'No claims found!'}
            />
            <Button
              type="submit"
              className={classes.button}
              disabled={isSearchInProgress || disabled}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

ClaimSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  searchValue: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  label: PropTypes.string,
  isSearchInProgress: PropTypes.bool,
};

function DefaultSelectComponent({ claim, onSelect }) {
  return (
    <Button color="primary" component={Link} to={`/claims/${claim.id}`} onClick={() => onSelect(claim)}>
      <span style={{ whiteSpace: 'nowrap' }}> Go to claim</span>
    </Button>
  );
}

DefaultSelectComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};

function ClaimSearchResults({
  additionalComponent,
  preResultsTableComponent,
  searchResults,
  onCancel,
  SelectComponent,
  selectComponentProps,
  title,
  hideClaimType,
  shouldOpenClaimInNewTab,
  onClaimLinkClick,
}) {
  const { user, userOrganization } = useCms();
  const organizationPreferences = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const isCommercialPoliciesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.COMMERCIAL_POLICIES
  );
  const isConfigurableFnolEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  let columnData = [
    ...getClaimsTableColumnsByUser(
      user,
      !!shouldOpenClaimInNewTab,
      'searchResults',
      onClaimLinkClick,
      organizationPreferences,
      lobConfigurationsDict,
      userOrganization
    ),
    {
      id: 'select_button',
      numeric: false,
      label: '',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        SelectComponent ? (
          <SelectComponent claim={row} onSelect={onClaimLinkClick} {...selectComponentProps} />
        ) : (
          <DefaultSelectComponent claim={row} onSelect={onClaimLinkClick} />
        ),
    },
  ];

  if (hideClaimType) {
    columnData = columnData.filter((column) => column.id !== 'type');
  }

  if (isConfigurableFnolEnabled && isCommercialPoliciesEnabled) {
    const firstPartyInvolvedEntities = {
      id: 'first_party_name_chip',
      label: 'First Party Involved',
      specialCell: (row) => {
        if (isEmpty(row?.first_party_involved_entities_display_names)) {
          return null;
        }

        return (
          <div>
            <OverflowArrayTextDisplayWithTooltip
              value={row.first_party_involved_entities_display_names}
              renderItem={(label) => (
                <span key={label}>
                  <Chip label={label} size="small" className="mr-10" />
                </span>
              )}
            />
          </div>
        );
      },
      disableSort: true,
      width: '250px',
    };

    columnData.splice(1, 0, firstPartyInvolvedEntities);
  }

  return (
    <CardDialog
      isDialog
      title={title || 'Claims'}
      onClose={onCancel}
      maxWidth={isCommercialPoliciesEnabled ? 'lg' : false}
      fullWidth
    >
      <Grid container spacing={spacing} style={{ marginTop: '8px' }}>
        <Grid item xs={12}>
          {preResultsTableComponent}
          <PlainTable columns={columnData} rows={searchResults} />
        </Grid>
      </Grid>
      {additionalComponent}
    </CardDialog>
  );
}

ClaimSearchResults.propTypes = {
  additionalComponent: PropTypes.node,
  preResultsTableComponent: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  SelectComponent: PropTypes.func, // SelectComponent should receive claim in the props
  selectComponentProps: PropTypes.object,
  title: PropTypes.string,
  hideClaimType: PropTypes.bool,
  shouldOpenClaimInNewTab: PropTypes.bool,
  onClaimLinkClick: PropTypes.func,
};

class ClaimSearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      isSearchInProgress: false,
      searchResults: undefined,
    };
  }

  handleSearch = (subOrganizationIds, lobs) => {
    const { searchValue } = this.state;

    this.setState({ isSearchInProgress: true });

    let searchParams = {
      search: searchValue,
      sub_organization_ids: subOrganizationIds,
      lobs,
    };

    // By default, returns only the first 20 results
    axios
      .get('/api/v1/claims/search_query', { params: searchParams })
      .then((res) => {
        const searchResults = res.data.claims;
        this.setState({ searchResults, isSearchInProgress: false });
      })
      .catch((error) => {
        this.setState({ isSearchInProgress: false });
        reportAxiosError(error);
      });
  };

  render() {
    const {
      classes,
      SelectComponent,
      selectComponentProps,
      disabled,
      label,
      shouldOpenClaimInNewTab,
      subOrganizationIds,
      lobs,
    } = this.props;
    const { searchValue, isSearchInProgress, searchResults } = this.state;

    const handleCancelOrClaimLinkClicked = () => {
      this.setState({ searchResults: undefined });
    };

    return (
      <Fragment>
        <ClaimSearch
          classes={classes}
          searchValue={searchValue}
          onChangeSearch={(val) => this.setState({ searchValue: val })}
          onSearch={() => this.handleSearch(subOrganizationIds, lobs)}
          isSearchInProgress={isSearchInProgress}
          errorMsg={searchResults && searchResults.length === 0 ? 'No claims found' : ''}
          disabled={disabled}
          label={label}
        />
        {searchResults && searchResults.length > 0 && (
          <ClaimSearchResults
            classes={classes}
            searchResults={searchResults}
            preResultsTableComponent={
              searchResults.length === CONSTANTS.DEFAULT_CLAIMS_PER_PAGE && (
                <div className={classes.cardDivRow}>
                  <span
                    className={classes.textFieldRow}
                  >{`Showing only the first ${CONSTANTS.DEFAULT_CLAIMS_PER_PAGE} results. Refine your search query to find other claims or use the All Claims view`}</span>
                </div>
              )
            }
            onCancel={handleCancelOrClaimLinkClicked}
            onClaimLinkClick={handleCancelOrClaimLinkClicked}
            SelectComponent={SelectComponent}
            selectComponentProps={selectComponentProps}
            shouldOpenClaimInNewTab={shouldOpenClaimInNewTab}
          />
        )}
      </Fragment>
    );
  }
}

ClaimSearchContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  SelectComponent: PropTypes.func,
  selectComponentProps: PropTypes.object,
  label: PropTypes.string,
  shouldOpenClaimInNewTab: PropTypes.bool,
  subOrganizationIds: PropTypes.array,
  lobs: PropTypes.array,
};

export default ClaimSearchContainer;
export { ClaimSearchResults };
