import { useEffect, useState } from 'react';
import axios from 'axios';

import useOrganization from '~/components/OrganizationContext';
import { reportAxiosError } from '~/Utils';

import { useCms } from './useCms';

const useCccAppraiser = (contactId) => {
  const { isCccEnabled } = useOrganization();
  const { userOrganization } = useCms();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [cccAppraiserCode, setCccAppraiserCode] = useState('');

  const fieldId = 'ccc_appraiser_code';

  useEffect(() => {
    const fetchCccContact = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`/api/v1/ccc/organizations/${userOrganization?.id}/contacts`, {
          params: { contact_id: contactId },
        });
        if (data) {
          setCccAppraiserCode(data.appraiser_code);
        }
      } catch (error) {
        setIsError(true);
        await reportAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isCccEnabled && contactId && !cccAppraiserCode) {
      return fetchCccContact();
    }
  }, [cccAppraiserCode, contactId, isCccEnabled, userOrganization?.id]);

  return {
    isCccEnabled,
    isLoadingCccContact: isLoading,
    isErrorCccContact: isError,
    cccAppraiserCodeFieldId: fieldId,
    cccAppraiserCode,
  };
};

export default useCccAppraiser;
