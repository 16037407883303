import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import { noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../../components/CardDialog';
import { CsvIcon, InfoIcon } from '../../../components/icons';

export const UploadDialogContent = ({ classes, templateTooltip, note, onCsvTemplateClick = noop }) => (
  <Grid container direction="row">
    <Grid item xs={12}>
      <CardDialog noCardTitle contentStyle={{ paddingLeft: '0' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon size={20} iconColor="#909090" />
          <Typography variant="subtitle1" style={{ padding: '8px', fontSize: '14px' }}>
            Note
          </Typography>
        </div>
        <div style={{ color: '#606060', paddingLeft: '30px' }}>{note}</div>
      </CardDialog>
    </Grid>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <Button color="primary" onClick={onCsvTemplateClick} style={{ padding: '8px' }}>
        <CsvIcon size={24} iconColor="#1A9C9E" className={classes.leftButtonIcon} />
        Download CSV Template
        <Tooltip title={templateTooltip}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon size={20} iconColor="#909090" className={classes.rightButtonIcon} />
          </div>
        </Tooltip>
      </Button>
    </Grid>
  </Grid>
);

UploadDialogContent.propTypes = {
  classes: PropTypes.object,
  onCsvTemplateClick: PropTypes.func,
  templateTooltip: PropTypes.string,
  note: PropTypes.string,
};
