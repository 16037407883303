import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import PaymentDetailsDialog from './PaymentDetailsDialog';

const PaymentDetailsMenuOptions = ({ financeRecord }) => {
  const [paymentDetailsDialogOpen, setPaymentDetailsDialogOpen] = useState(false);
  const paymentRequest = financeRecord.payment_request;

  const handleOpenPaymentDetailsDialog = () => {
    setPaymentDetailsDialogOpen(true);
  };

  const handleClosePaymentDetailsDialog = () => {
    setPaymentDetailsDialogOpen(false);
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          handleOpenPaymentDetailsDialog();
        }}
      >
        Additional Payment Details
      </MenuItem>
      {paymentDetailsDialogOpen ? (
        <PaymentDetailsDialog paymentRequest={paymentRequest} onClose={handleClosePaymentDetailsDialog} />
      ) : null}
    </>
  );
};

PaymentDetailsMenuOptions.propTypes = {
  financeRecord: PropTypes.object.isRequired,
};

export default PaymentDetailsMenuOptions;
