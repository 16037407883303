import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import OrganizationContactsAdvancedSearchFilters from '~/components/Contacts/OrganizationContactsScreen/OrganizationContactsAdvancedSearch/OrganizationContactsAdvancedSearchFilters';
import {
  ADVANCED_CONTACTS_SEARCH_FIELD_IDS,
  ALL_OPTIONS_VALUE,
  CONTACTS_PER_PAGE,
} from '~/components/Contacts/OrganizationContactsScreen/organizationContactsUtils';
import { FsButton } from '~/components/core';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { governmentIdValidationSchema } from '~/components/GovernmentId';
import { getPostalCodeYupTestParams } from '~/Utils';
import cn from '~/Utils/cn';

const OrganizationContactsAdvancedSearchContainer = ({
  handleContactSearch,
  initialSearchTextValue = '',
  includeSingleClaimRoles = false,
}) => {
  const classes = useStyles();

  const [isContactsAdvancedSearchOpen, setIsContactsAdvancedSearchOpen] = useState(false);

  return (
    <div className="mt-30">
      <CollapsibleWrapper
        title="Advanced Contacts Search"
        actionCard
        noBorder
        open={isContactsAdvancedSearchOpen}
        onCollapse={() => setIsContactsAdvancedSearchOpen((prevOpen) => !prevOpen)}
        unmountOnExit={false}
        isActionCardDrawer={false}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SEARCH]: initialSearchTextValue,
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_ROLES]: [ALL_OPTIONS_VALUE],
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_EXPERTISE]: [],
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SOCIAL_SECURITY_NUMBER]: '',
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ZIPCODE]: '',
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.PHONE_NUMBER]: '',
          }}
          validationSchema={Yup.object().shape({
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SEARCH]: Yup.string(),
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_ROLES]: Yup.array()
              .of(Yup.string())
              .min(1, 'You must select at least 1 Contact Role'),
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_EXPERTISE]: Yup.array(),
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SOCIAL_SECURITY_NUMBER]: governmentIdValidationSchema,
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ZIPCODE]: Yup.string()
              .nullable()
              .test(...getPostalCodeYupTestParams(false)),
            [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.PHONE_NUMBER]: Yup.string(),
          })}
          onSubmit={async (values) => {
            const paginationProps = {
              page: 0,
              limit: CONTACTS_PER_PAGE,
              sort_by: 'full_name',
              order_by: 'asc',
            };

            await handleContactSearch(values, paginationProps);
          }}
        >
          {({ handleSubmit, resetForm, isSubmitting }) => (
            <div className="grid">
              <OrganizationContactsAdvancedSearchFilters includeSingleClaimRoles={includeSingleClaimRoles} />
              <div className="flex gap-10">
                <div className="mt-5">
                  <FsButton
                    onClick={resetForm}
                    variant="contained"
                    className={classes.cancelButton}
                    disabled={isSubmitting}
                  >
                    Clear
                  </FsButton>
                </div>
                <div>
                  <FsButton
                    className={cn(classes.button, 'm-5, ml-10')}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Search
                  </FsButton>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </CollapsibleWrapper>
    </div>
  );
};

OrganizationContactsAdvancedSearchContainer.propTypes = {
  handleContactSearch: PropTypes.func,
  initialSearchTextValue: PropTypes.string,
  includeSingleClaimRoles: PropTypes.bool,
};

export default OrganizationContactsAdvancedSearchContainer;
