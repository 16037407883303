import React from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CommunicationCard, {
  CommunicationActionSendNew,
  useCreateCommunication,
} from '~/components/communications/CommunicationCard';
import type { VideoCallCommunication } from '~/components/communications/types';
import VideoCallSpecificBody from '~/components/communications/VideoCommunication/VideoCallSpecificBody';
import { reportAxiosError } from '~/Utils';

interface Props {
  communication: VideoCallCommunication;
  onClose: () => void;
  cardTitle: string;
}
const PostVideoCommunicationCard: React.FC<Props> = ({ communication, onClose, cardTitle }) => {
  const { communicationCommonInitialValues } = useCreateCommunication('video_call', {
    exposure_ids: communication.exposure_ids,
    summary: communication.summary,
  });

  const initialValues = {
    ...communicationCommonInitialValues,
    claim_id: communication.claim_id || '',
    summary: communication.summary || '',
    direction: 'Outgoing',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        exposure_ids: Yup.array().required('Required').min(1, 'Required'),
        summary: Yup.string(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await axios.patch(`/api/v1/communications/${communication.id}`, {
            summary: values.summary,
            exposure_ids: values.exposure_ids,
          });
          formikProps.setSubmitting(false);
          onClose();
        } catch (error) {
          formikProps.setSubmitting(false);
          await reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        return (
          <CommunicationCard
            communicationInitialFields={{
              communicationChannel: 'video_call',
              communicationContact: communication.contact,
              communicationDirection: 'Outgoing',
            }}
            CommunicationTypeSpecificBody={VideoCallSpecificBody}
            communicationHeaderAdditionalProps={{
              isDynamicContact: false,
              dynamicContactLabel: 'Call To',
            }}
            isDialog
            isNew
            maxWidth="sm"
            onClose={onClose}
            CommunicationAction={CommunicationActionSendNew}
            title={cardTitle}
            disableSoftClose
            communicationActionProps={{
              onSubmit: () => {
                formikProps.handleSubmit();
              },
              isSubmitting: formikProps.isSubmitting,
              buttonText: 'Save',
            }}
          />
        );
      }}
    </Formik>
  );
};

export default PostVideoCommunicationCard;
