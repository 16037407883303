import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';

import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';

import { useStyles } from '../../assets/styles';

function LossTakingConfirmationDialog(props) {
  const { open, claim, vendorsStatus, onClose } = props;
  const classes = useStyles();

  if (!open) {
    return <> </>;
  }

  const pcsItemsList =
    claim && claim.claim_extra && claim.claim_extra.post_completion_script
      ? claim.claim_extra.post_completion_script
      : ['provide_claim_id', 'advise_not_commit_cov'];

  let confirmationInitialValues = {};
  let fieldValidationShape = {};
  pcsItemsList.forEach((pcsItemString) => {
    confirmationInitialValues[pcsItemString] = false;
    fieldValidationShape[pcsItemString] = Yup.boolean().oneOf([true], 'Required');
  });

  return (
    <Formik
      initialValues={confirmationInitialValues}
      validationSchema={Yup.object().shape(fieldValidationShape)}
      enableReinitialize
      onSubmit={() => onClose()}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog isDialog={true} open={open} title="Loss Taken" fullWidth maxWidth="sm" preventClose={true}>
            {pcsItemsList.map((pcsItemString) => (
              <React.Fragment key={pcsItemString}>{renderPcsItem(pcsItemString, claim, vendorsStatus)}</React.Fragment>
            ))}
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Done
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

LossTakingConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  claim: requiredIf(PropTypes.object, (props) => props.open),
  vendorsStatus: requiredIf(PropTypes.object, (props) => props.open),
  onClose: PropTypes.func.isRequired,
};

function renderPcsItem(pcsItemString, claim, vendorsStatus) {
  switch (pcsItemString) {
    case 'advise_claim_email_sent':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <Typography display="block" variant="body1" style={{ color: '#e65100' }}>
              <strong>NOTE:</strong>
              <br />
              Advise insured that claim information will also be emailed to them for their convenience
            </Typography>
          }
        />
      );
    case 'reported_not_by_insured':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label="No email was sent as claim was reported by someone other than the insured"
        />
      );
    case 'provide_claim_id':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={`Provided insured with Claim Number: ${claim.claim_id_display}`}
        />
      );
    case 'advise_not_commit_cov':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>
              Advised that we <strong>cannot yet commit to coverage</strong> because we must investigate
            </span>
          }
        />
      );
    case 'set_callback_expectations':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>
              Set callback expectations to <strong>within the next 24 hours</strong>
            </span>
          }
        />
      );
    case 'advice_not_discuss':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>
              Advised insured not to <strong>discuss their claim or disclose their policy information</strong> to the
              claimant or any other party from this point forward
            </span>
          }
        />
      );
    case 'advice_keep_receipts':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>
              Advised insured to <strong>keep receipts</strong> for expenses
            </span>
          }
        />
      );
    case 'take_photos':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>
              Asked insured to send <strong>photos of the damage</strong> prior to any cleanup
            </span>
          }
        />
      );
    case 'faulty_appliances':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>
              Advised insured to keep <strong>faulty appliances</strong>
            </span>
          }
        />
      );
    case 'mitigation_vendor_assigned':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>{`Advised insured that our vendor ${vendorsStatus.mitigation_vendor.full_name} will be in touch with them to assist with mitigation repairs`}</span>
          }
        />
      );
    case 'mitigation_self_service':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>Advised insured that actions required to mitigate any further damage must be taken immediately</span>
          }
        />
      );
    case 'restoration_vendor_assigned':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={
            <span>{`Advised insured that our vendor ${vendorsStatus.restoration_vendor.full_name} will be in touch with them to assist with restoration works`}</span>
          }
        />
      );
    case 'restoration_self_service':
      return (
        <PcsItemCheckbox
          pcsItemString={pcsItemString}
          label={<span>Advised insured that he committed to make all necessary restoration works by himself</span>}
        />
      );
    default:
      throw Error(`Unknown post completion script item ${pcsItemString}`);
  }
}

function PcsItemCheckbox(props) {
  const { pcsItemString, label } = props;
  const classes = useStyles();

  return <CheckboxFormik id={pcsItemString} label={label} fullWidth className={classes.textField} />;
}

PcsItemCheckbox.propTypes = {
  pcsItemString: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default LossTakingConfirmationDialog;
