import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import { stringCmp } from '~/Utils';

import Checkbox from '../../../Atomic/Checkboxes/Checkbox';
import OptionChips from '../../../OptionChips';
import type { TextFieldProps } from '../TextField/TextField';
import TextField from '../TextField/TextField';

// TODO: ellahellah fix chip of the all option + removing via chips

export interface MultiSelectFieldProps extends Omit<TextFieldProps, 'onChange'> {
  renderValue: (value: unknown) => React.ReactNode;
  renderOption: (option: unknown) => React.ReactNode;
  options: string[];
  sortAlphabetic?: boolean;
  withOptionChips?: boolean;
  disabledOptions?: string[];
  addAllOption?: boolean;
  allOptionValue?: string;
  emptyChoiceValue?: string;
  onChange: (newValues: string[]) => void;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  id,
  value,
  disabled,
  renderValue,
  renderOption,
  options,
  sortAlphabetic,
  addAllOption,
  disabledOptions,
  withOptionChips,
  allOptionValue,
  onChange,
  emptyChoiceValue,
  ...rest
}) => {
  if (sortAlphabetic) {
    options.sort((option1, option2) => stringCmp(renderOption(option1), renderOption(option2)));
  }

  const optionsWithPossibleAll = addAllOption ? [allOptionValue].concat(options) : options;

  const isOptionChecked = (option: string) => (value as unknown[])?.indexOf(option) > -1 ?? false;

  const innerOnChange = (newValues: unknown) => {
    const currentValue = value as unknown[];
    const nextValue = newValues as unknown[] as string[];

    if (allOptionValue) {
      if (nextValue.includes(allOptionValue)) {
        // allOptionValue was selected and user now selected something else - unselect allOptionValue
        if (currentValue.includes(allOptionValue)) {
          return onChange(nextValue.filter((i) => i !== allOptionValue));
        } else {
          // user selected allOptionValue - unselect everything else
          return onChange([allOptionValue]);
        }
      }
    }

    if (emptyChoiceValue && nextValue.includes(emptyChoiceValue)) {
      // emptyChoiceValue was selected and user now selected something else - unselect emptyChoiceValue
      if (currentValue.includes(emptyChoiceValue)) {
        return onChange(nextValue.filter((i) => i !== emptyChoiceValue));
      } else {
        // user selected emptyChoiceValue - unselect everything else
        return onChange([emptyChoiceValue]);
      }
    }

    return onChange(newValues as unknown[] as string[]);
  };

  const removeSingleValueFromSelectedOptions = (id: string | undefined, values: unknown, removedValue: unknown) => {
    const castValues = values as unknown as string[];
    const indexValue = castValues.indexOf(removedValue as string);
    castValues.splice(indexValue, 1);
    innerOnChange(values as unknown[] as string[]);
  };

  return (
    <div>
      <TextField
        name={id}
        value={value}
        disabled={disabled}
        select
        onChange={onChange as (newValues: unknown) => void}
        SelectProps={{
          multiple: true,
          renderValue:
            renderValue ??
            ((selected: unknown) =>
              renderOption
                ? (selected as unknown as unknown[]).map(renderOption).join(', ')
                : (selected as unknown as unknown[]).join(', ')),
        }}
        {...rest}
      >
        {optionsWithPossibleAll.map((option) => {
          const castOption = option as unknown as string;
          const isChecked = isOptionChecked(castOption);
          return (
            <MenuItem key={castOption} value={castOption} disabled={disabledOptions?.includes(castOption)}>
              <Checkbox checked={isChecked} style={{ color: isChecked ? '#1A9C9E' : '#707070' }} />
              <ListItemText
                primary={
                  !addAllOption || (addAllOption && castOption !== 'All') ? renderOption(castOption) : castOption
                }
              />
            </MenuItem>
          );
        })}
      </TextField>
      {withOptionChips && (
        <OptionChips
          disabled={disabled}
          onChange={(removedValue) => removeSingleValueFromSelectedOptions(id, value, removedValue)}
          values={(value as unknown as string[]).map((v) => [v, renderOption ? renderOption(v) : v])}
          disabledChips={disabledOptions}
        />
      )}
    </div>
  );
};

export default MultiSelectField;
