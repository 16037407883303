import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper } from '@material-ui/core';
import MaximizeIcon from '@material-ui/icons/Maximize';
import { ArrowExpand } from 'mdi-material-ui';

import CardDialog from '../../../CardDialog';
import OverflowTextWithToolTip from '../../../OverflowTextWithToolTip';

const ClaimQaFormCardMinimized = ({ onClose, title, subtitle, onChangeCardSize }) => (
  <div style={{ width: '50vw', position: 'fixed', bottom: '25px', right: '25px', zIndex: 2 }} elevation={6}>
    <Paper style={{ width: '100%', height: '100%' }}>
      <CardDialog
        title={
          <OverflowTextWithToolTip maxWidth={600}>
            {title}, {subtitle}
          </OverflowTextWithToolTip>
        }
        titleVariant="subtitle1"
        onClose={onClose}
        height="100%"
        contentStyle={{ height: '0px', overflow: 'auto' }}
        action={
          <>
            <IconButton onClick={() => onChangeCardSize('float')} size="small">
              <MaximizeIcon />
            </IconButton>
            <IconButton onClick={() => onChangeCardSize('full')} size="small">
              <ArrowExpand />
            </IconButton>
          </>
        }
      />
    </Paper>
  </div>
);

ClaimQaFormCardMinimized.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object.isRequired,
  onChangeCardSize: PropTypes.func.isRequired,
};

export default ClaimQaFormCardMinimized;
