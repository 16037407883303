import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { ButtonGroup, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import _ from 'lodash';
import { PlayCircleOutline } from 'mdi-material-ui';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import VideoPlayer from '~/components/core/Atomic/VideoPlayer';
import PermissionsButtonWrapper from '~/components/core/Permissions/PermissionsButtonWrapper';
import LargeMediaContainer from '~/components/Gallery/LargeMediaContainer';

import { compareDatesStrings, isoDateToUs } from '../../DateTimeUtils';
import { reportAxiosError } from '../../Utils';
import { isClaimWriteDisabled } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { UploadMultipleMedia } from '../Documents/DocumentCard';
import { getProcessedUrl, isDocumentAPhoto } from '../Documents/DocumentUtils';
import ExposureLabelChips from '../ExposureLabelChips';
import ExposuresLabelFilter from '../exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from '../exposures/ExposureUtils';
import { useCms } from '../hooks/useCms';

import { useStyles } from '../../assets/styles';

const backgroundGrayColor = '#f2f2f2';

function SmallPhotoContainer(props) {
  const { media, photoLink, altText, onClick, height, galleryRef } = props;
  const [ref, inView] = useInView({
    triggerOnce: true,
    root: galleryRef.current,
    rootMargin: `${height * 2}px 0px`,
  });

  const [showPhoto, setShowPhoto] = useState(false);

  return (
    <Grid item style={{ width: '100%' }} innerRef={ref}>
      {(inView || showPhoto) && (
        <Grid item container justify="center" direction="row">
          <Grid item>
            <div
              style={{
                verticalAlign: 'middle',
                width: '100%',
                height,
                backgroundColor: showPhoto ? undefined : backgroundGrayColor,
                display: showPhoto ? 'table-cell' : 'none',
              }}
            >
              <img
                src={getProcessedUrl(media, photoLink, true)}
                alt={altText}
                onClick={onClick}
                onLoad={() => setShowPhoto(true)}
                style={{ maxWidth: '100%', cursor: 'pointer', maxHeight: height }}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {!showPhoto && (
        <div style={{ width: '100%', height, backgroundColor: showPhoto ? undefined : backgroundGrayColor }} />
      )}
    </Grid>
  );
}

SmallPhotoContainer.propTypes = {
  media: PropTypes.object.isRequired,
  photoLink: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  galleryRef: PropTypes.any.isRequired,
};

function SmallVideoContainer(props) {
  const { media, photoLink, onClick, height } = props;

  return (
    <div
      style={{
        verticalAlign: 'middle',
        width: '100%',
        height,
        backgroundColor: backgroundGrayColor,
        display: 'table-cell',
        textAlign: 'center',
        margin: 'auto',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Grid container justify="center" direction="row" style={{ height: '100%', position: 'relative' }}>
        <VideoPlayer src={getProcessedUrl(media, photoLink, true)} className="max-h-full max-w-full" />
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <PlayCircleOutline style={{ fontSize: '6em', color: '#ffffff' }} opacity="0.85" />
        </span>
      </Grid>
    </div>
  );
}

SmallVideoContainer.propTypes = {
  media: PropTypes.object.isRequired,
  photoLink: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

function SmallUnpresentableMediaContainer(props) {
  const { media, photoLink, height } = props;

  return (
    <Grid item style={{ width: '100%' }}>
      <Grid item container justify="center" direction="row">
        <Grid item>
          <div
            style={{
              verticalAlign: 'middle',
              width: '100%',
              height,
              display: 'table-cell',
            }}
          >
            <a
              href={getProcessedUrl(media, photoLink, true)}
              style={{ maxWidth: '100%', cursor: 'pointer', maxHeight: height }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {media.document_name}
            </a>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

SmallUnpresentableMediaContainer.propTypes = {
  media: PropTypes.object.isRequired,
  photoLink: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

function GalleryMediaContainer(props) {
  const {
    media,
    selected,
    shouldAllowSelect,
    onSelectChanged,
    isStoredFile,
    PhotoActionComponent,
    shouldDisableLargePhotoActions,
    getPhotoUrl,
    disableSelect,
    disableReason,
    onRotatePhoto,
    galleryRef,
  } = props;

  const [showLargePhoto, setShowLargePhoto] = useState(false);
  const mediaLink = !getPhotoUrl ? `/api/v1/claims/${media.claim_id}/media/${media.id}` : getPhotoUrl(media);
  const classes = useStyles();
  const [width, setWidth] = useState(0);
  const measuredRef = useRef();

  useEffect(
    () =>
      window.addEventListener('resize', () => {
        measuredRef.current && setWidth(measuredRef.current.clientWidth);
      }),
    [measuredRef]
  );

  useEffect(() => setWidth(measuredRef.current.clientWidth), []);

  const handleRotatePhoto = async () => {
    await onRotatePhoto(media);
  };

  const desireHeight = width * 0.66;
  const textStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
    width: width * 0.8,
  };

  let MediaDisplayComponent = undefined;
  if (isDocumentAVideo(media)) {
    MediaDisplayComponent = SmallVideoContainer;
  } else if (isDocumentAPhoto(media)) {
    MediaDisplayComponent = SmallPhotoContainer;
  } else {
    MediaDisplayComponent = SmallUnpresentableMediaContainer; // case for a file we allowed uploading but can't be rendered by Chrome
  }

  return (
    <>
      <Grid
        innerRef={measuredRef}
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <MediaDisplayComponent
          photoLink={mediaLink}
          altText={!isStoredFile ? media.document_name : media.file_name}
          onClick={() => setShowLargePhoto(true)}
          height={desireHeight}
          media={media}
          galleryRef={galleryRef}
        />

        {shouldAllowSelect && (
          <Grid item>
            <Tooltip title={disableSelect ? disableReason : ''}>
              <div>
                <Checkbox
                  checked={selected}
                  disabled={disableSelect}
                  onChange={(event) => onSelectChanged(event.target.checked)}
                  value="Select media"
                  color="primary"
                />
              </div>
            </Tooltip>
          </Grid>
        )}

        <Grid item container direction="column" justify="center" alignItems="center" style={{ width: '100%' }}>
          {!isStoredFile ? (
            <>
              <Grid item>
                <ExposureLabelChips claimObject={media} />
              </Grid>
              <Grid item className={classes.containerCentered}>
                <Typography variant="body1" style={textStyle}>
                  {media.document_name}
                </Typography>
                {PhotoActionComponent && <PhotoActionComponent photoId={media.id} />}
              </Grid>
              <Grid item>
                <Typography variant="body2" style={textStyle}>
                  <em>{isoDateToUs(media.document_date)}</em>
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography variant="body2" style={textStyle}>
                {media.file_name}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {showLargePhoto && (
        <LargeMediaContainer
          media={media}
          mediaLink={mediaLink}
          onClose={() => setShowLargePhoto(false)}
          isStoredFile={isStoredFile}
          shouldDisableLargePhotoActions={shouldDisableLargePhotoActions}
          onRotatePhoto={handleRotatePhoto}
        />
      )}
    </>
  );
}

GalleryMediaContainer.propTypes = {
  media: PropTypes.object.isRequired,
  shouldAllowSelect: PropTypes.bool,
  selected: requiredIf(PropTypes.bool, (props) => props.shouldAllowSelect),
  disableSelect: PropTypes.bool,
  disableReason: requiredIf(PropTypes.string, (props) => props.disableSelect),
  onSelectChanged: requiredIf(PropTypes.func, (props) => props.shouldAllowSelect),
  isStoredFile: PropTypes.bool,
  PhotoActionComponent: PropTypes.func,
  shouldDisableLargePhotoActions: PropTypes.bool,
  getPhotoUrl: PropTypes.func,
  onRotatePhoto: PropTypes.func,
  galleryRef: PropTypes.any.isRequired,
};

const isDocumentAVideo = (document) => {
  if (!document.stored_file || !document.stored_file.mime_type) {
    return false;
  }
  return document.stored_file.mime_type.startsWith('video');
};

function getAllClaimPhotos(claim) {
  return claim.documents.filter(isDocumentAPhoto).filter((photo) => !photo.is_removed);
}

function getAllClaimVideos(claim) {
  return claim.documents.filter(isDocumentAVideo).filter((photo) => !photo.is_removed);
}

// IMPORTANT: To keep UI working well with this component, this should NOT be rendered together with elements not inside 'flex' display
function GalleryContainerInner(props) {
  const {
    allMedia,
    selectedPhotoIds,
    disableSelectingPhotoIds,
    disableReason,
    shouldAllowSelect,
    onPhotosSelected,
    selectButtonText,
    noPhotosFoundText,
    actionsRow,
    onPhotoClicked,
    isStoredFile,
    PhotoActionComponent,
    shouldDisableLargePhotoActions,
    getPhotoUrl,
    onRotatePhoto,
  } = props;
  const classes = useStyles();
  const galleryRef = useRef();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {actionsRow}
        <div style={{ flex: '1 1 auto', overflowY: 'auto' }} ref={galleryRef}>
          <Grid container justify="center">
            {allMedia.length === 0 ? (
              <Typography className={classes.textField}>
                {noPhotosFoundText ? noPhotosFoundText : 'No photos found under selected exposures'}
              </Typography>
            ) : (
              allMedia.map((photo) => (
                <Grid item xs={6} sm={4} md={3} xl={2} key={photo.id}>
                  <div className={classes.cardDivRow} style={{ height: '100%' }}>
                    <GalleryMediaContainer
                      shouldAllowSelect={shouldAllowSelect}
                      selected={shouldAllowSelect ? selectedPhotoIds.includes(photo.id) : undefined}
                      disableSelect={disableSelectingPhotoIds ? disableSelectingPhotoIds.includes(photo.id) : undefined}
                      disableReason={disableReason}
                      onSelectChanged={(isSelected) => onPhotoClicked(isSelected, photo)}
                      media={photo}
                      isStoredFile={isStoredFile}
                      PhotoActionComponent={PhotoActionComponent}
                      shouldDisableLargePhotoActions={shouldDisableLargePhotoActions}
                      getPhotoUrl={getPhotoUrl}
                      onRotatePhoto={onRotatePhoto}
                      galleryRef={galleryRef}
                    />
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </div>
        {shouldAllowSelect && (
          <Grid item className={classes.inLineButtonsContainer}>
            <Button
              className={classes.inputContainer}
              disabled={_.difference(selectedPhotoIds, disableSelectingPhotoIds).length === 0}
              variant="contained"
              color="primary"
              onClick={() => onPhotosSelected(selectedPhotoIds)}
            >
              {selectButtonText}
            </Button>
          </Grid>
        )}
      </div>
    </>
  );
}

GalleryContainerInner.propTypes = {
  allMedia: PropTypes.array.isRequired, // must contain id, stored_file and file_name/document_name
  actionsRow: PropTypes.node,
  shouldAllowSelect: PropTypes.bool,
  selectedPhotoIds: requiredIf(PropTypes.array, (props) => props.shouldAllowSelect),
  disableSelectingPhotoIds: PropTypes.array,
  disableReason: requiredIf(PropTypes.string, (props) => props.disableSelectingPhotoIds),
  onPhotosSelected: requiredIf(PropTypes.func, (props) => props.shouldAllowSelect),
  onPhotoClicked: requiredIf(PropTypes.func, (props) => props.shouldAllowSelect),
  selectButtonText: requiredIf(PropTypes.string, (props) => props.shouldAllowSelect),
  noPhotosFoundText: PropTypes.string,
  isStoredFile: PropTypes.bool,
  PhotoActionComponent: PropTypes.func,
  shouldDisableLargePhotoActions: PropTypes.bool,
  getPhotoUrl: PropTypes.func,
  onRotatePhoto: PropTypes.func,
};

function GalleryContainer(props) {
  const {
    allMedia,
    shouldAllowSelect,
    onPhotosSelected,
    shouldDisableUpload,
    PhotoActionComponent,
    shouldDisableLargePhotoActions,
    disableExposuresFilter,
  } = props;
  const classes = useStyles();
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();
  const [selectedPhotoIds, setSelectedPhotoIds] = useState([]);
  const [showUploadPhotos, setShowUploadPhotos] = useState(false);
  const [showUploadVideos, setShowUploadVideos] = useState(false);
  const [filteredExposuresIds, setFilteredExposuresIds] = useState(() => getUserRelatedExposuresId(user, claim));

  let photosToShow = claim.documents.filter((document) => allMedia.includes(document));

  if (!disableExposuresFilter && filteredExposuresIds.length !== 0) {
    photosToShow = photosToShow.filter((photo) => _.intersection(filteredExposuresIds, photo.exposure_ids).length > 0);
  }

  photosToShow.sort((photo1, photo2) => compareDatesStrings(photo1.document_date, photo2.document_date));

  const handlePhotoClicked = (isSelected, photo) => {
    if (isSelected) {
      setSelectedPhotoIds([...selectedPhotoIds, photo.id]);
    } else {
      setSelectedPhotoIds(_.without(selectedPhotoIds, photo.id));
    }
  };

  const handleRotatePhoto = async (photo) => {
    try {
      await axios.post(`/api/v1/claims/${photo.claim_id}/documents/${photo.id}/rotate_photo`);
      await onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const actionsRow = (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      {!disableExposuresFilter && (
        <div style={{ flex: '1 1 auto' }}>
          <Grid container justify="center">
            <ExposuresLabelFilter filterList={filteredExposuresIds} onUpdateFiltered={setFilteredExposuresIds} />
          </Grid>
        </div>
      )}
      {!shouldDisableUpload && (
        <>
          <div>
            <PermissionsButtonWrapper>
              <Button
                color="primary"
                className={classes.leftButtonDialog}
                onClick={() => setShowUploadVideos(true)}
                disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
              >
                <CloudUploadIcon className={classes.leftButtonIcon} />
                <span style={{ whiteSpace: 'nowrap' }}>Upload Videos</span>
              </Button>
            </PermissionsButtonWrapper>
          </div>
          <div>
            <PermissionsButtonWrapper>
              <Button
                color="primary"
                className={classes.leftButtonDialog}
                onClick={() => setShowUploadPhotos(true)}
                disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
              >
                <CloudUploadIcon className={classes.leftButtonIcon} />
                <span style={{ whiteSpace: 'nowrap' }}>Upload Photos</span>
              </Button>
            </PermissionsButtonWrapper>
          </div>
        </>
      )}
      {shouldAllowSelect && (
        <div>
          <ButtonGroup variant="text" size="small" className={classes.leftButtonDialog}>
            <Button onClick={() => setSelectedPhotoIds(photosToShow.map((photo) => photo.id))}>All</Button>
            <Button onClick={() => setSelectedPhotoIds([])}>None</Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );

  return (
    <>
      <GalleryContainerInner
        allMedia={photosToShow}
        actionsRow={actionsRow}
        shouldAllowSelect={shouldAllowSelect}
        selectedPhotoIds={selectedPhotoIds}
        onPhotoClicked={handlePhotoClicked}
        onPhotosSelected={onPhotosSelected}
        selectButtonText="Add selected photos"
        PhotoActionComponent={PhotoActionComponent}
        shouldDisableLargePhotoActions={shouldDisableLargePhotoActions}
        onRotatePhoto={handleRotatePhoto}
      />
      {showUploadPhotos && (
        <UploadMultipleMedia
          onClose={() => setShowUploadPhotos(false)}
          claimId={claim.id}
          mediaType="damage_photo"
          onSubmit={async () => {
            await onAsyncClaimUpdate();
            setShowUploadPhotos(false);
          }}
        />
      )}
      {showUploadVideos && (
        <UploadMultipleMedia
          onClose={() => setShowUploadVideos(false)}
          claimId={claim.id}
          mediaType="video"
          onSubmit={async () => {
            await onAsyncClaimUpdate();
            setShowUploadVideos(false);
          }}
        />
      )}
    </>
  );
}

GalleryContainer.propTypes = {
  allMedia: PropTypes.array.isRequired,
  open: PropTypes.bool,
  shouldAllowSelect: PropTypes.bool,
  onPhotosSelected: requiredIf(PropTypes.func, (props) => props.shouldAllowSelect),
  shouldDisableUpload: PropTypes.bool,
  PhotoActionComponent: PropTypes.func,
  shouldDisableLargePhotoActions: PropTypes.bool,
  disableExposuresFilter: PropTypes.bool,
};

function GalleryCard(props) {
  const {
    title,
    allMedia,
    open,
    onClose,
    shouldAllowSelect,
    onPhotosSelected,
    shouldDisableUpload,
    PhotoActionComponent,
    shouldDisableLargePhotoActions,
    disableExposuresFilter,
  } = props;

  return (
    <CardDialog title={title} open={open} isDialog maxWidth="xl" fullWidth onClose={onClose}>
      <div style={{ height: '80vh' }}>
        <GalleryContainer
          allMedia={allMedia}
          shouldDisableUpload={shouldDisableUpload}
          shouldAllowSelect={shouldAllowSelect}
          onPhotosSelected={onPhotosSelected}
          PhotoActionComponent={PhotoActionComponent}
          shouldDisableLargePhotoActions={shouldDisableLargePhotoActions}
          disableExposuresFilter={disableExposuresFilter}
        />
      </div>
    </CardDialog>
  );
}

GalleryCard.propTypes = {
  title: PropTypes.string.isRequired,
  allMedia: PropTypes.array.isRequired,
  open: PropTypes.bool,
  shouldAllowSelect: PropTypes.bool,
  onPhotosSelected: requiredIf(PropTypes.func, (props) => props.shouldAllowSelect),
  onClose: PropTypes.func.isRequired,
  shouldDisableUpload: PropTypes.bool,
  PhotoActionComponent: PropTypes.func,
  shouldDisableLargePhotoActions: PropTypes.bool,
  disableExposuresFilter: PropTypes.bool,
};

function GalleryScreen() {
  const { claim } = useClaim();
  let photos = getAllClaimPhotos(claim);
  let videos = getAllClaimVideos(claim);

  return <GalleryContainer allMedia={[...photos, ...videos]} />;
}

export { GalleryCard, GalleryContainerInner, GalleryMediaContainer, getAllClaimPhotos, isDocumentAPhoto };
export default GalleryScreen;
