import React from 'react';

import type { LabeledValueProps } from '../../Molecules/LabledValue';
import LabeledValue from '../../Molecules/LabledValue';
import Label from '../../TextComponents/Label';

export interface LabeledValueWithKeyProps extends LabeledValueProps {
  key: string;
}

export interface SummarySectionProps {
  title: string;
  fields: LabeledValueWithKeyProps[];
}

const SummarySection: React.FC<SummarySectionProps> = ({ title, fields }) => {
  return (
    <div className="mb-20">
      <Label
        variant={Label.VARIANTS.MEDIUM}
        className="mb-10 border-0 border-b-1 border-solid border-slate-600 py-5 uppercase text-slate-800"
      >
        {title}
      </Label>
      <div className="flex flex-col gap-20">
        {fields.map(({ value, label, key, type }) => (
          <LabeledValue key={key} value={value} label={label} type={type} />
        ))}
      </div>
    </div>
  );
};

export default SummarySection;
