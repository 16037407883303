import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { TooltipIcon } from '../core';
import {
  EmailErrorIcon,
  EmailIcon,
  PhoneIcon,
  PhysicalMailIcon,
  SmsErrorIcon,
  SmsIcon,
  VendorIcon,
  VideoIcon,
} from '../icons/notifications';
import ApiIcon from '../icons/notifications/ApiIcon';

export function getCommunicationChannelIconComponent(communicationChannel, error = false, prefix = '') {
  switch (communicationChannel) {
    case 'email':
      return (
        <TooltipIcon title={prefix + (error ? 'Email Error' : 'Email')}>
          {error ? <EmailErrorIcon /> : <EmailIcon />}
        </TooltipIcon>
      );
    case 'sms':
      return (
        <TooltipIcon title={prefix + (error ? 'Sms Error' : 'Sms')}>
          {error ? <SmsErrorIcon /> : <SmsIcon />}
        </TooltipIcon>
      );
    case 'phone_call':
      return (
        <TooltipIcon title={prefix + 'Phone'}>
          <PhoneIcon />
        </TooltipIcon>
      );
    case 'physical_mail':
      return (
        <TooltipIcon title={prefix + 'Physical Mail'}>
          <PhysicalMailIcon />
        </TooltipIcon>
      );
    case 'video_call':
      return (
        <TooltipIcon title={prefix + 'Video'}>
          <span>
            <VideoIcon />
          </span>
        </TooltipIcon>
      );
    case 'vendor':
      return (
        <TooltipIcon title={prefix + 'API'}>
          <ApiIcon />
        </TooltipIcon>
      );
    default:
      throw Error(`Unknown communication channel ${communicationChannel}`);
  }
}

export function getCommunicationChannelIconComponentWithDotIndication(
  communicationChannel,
  claimNotification,
  getIconWrapperWithDotIndication
) {
  switch (communicationChannel) {
    case 'email':
      return getIconWrapperWithDotIndication(claimNotification, <EmailIcon />);
    case 'sms':
      return getIconWrapperWithDotIndication(claimNotification, <SmsIcon />);
    case 'phone_call':
      return getIconWrapperWithDotIndication(claimNotification, <PhoneIcon />);
    case 'physical_mail':
      return getIconWrapperWithDotIndication(claimNotification, <PhysicalMailIcon />);
    case 'video_call':
      return getIconWrapperWithDotIndication(claimNotification, <VideoIcon />);
    case 'vendor':
      return getIconWrapperWithDotIndication(claimNotification, <VendorIcon />);
    default:
      throw Error(`Unknown communication channel ${communicationChannel}`);
  }
}

export function getCommunicationDirectionIconComponent(communicationDirection) {
  const IconComponent = getCommunicationDirectionIcon(communicationDirection);
  return <IconComponent style={{ color: getCommunicationDirectionIconColor(communicationDirection) }} />;
}

export function getCommunicationDirectionIcon(communicationDirection) {
  const communicationDirectionIcon = {
    Incoming: ArrowDownwardIcon,
    Outgoing: ArrowUpwardIcon,
  };

  return communicationDirectionIcon[communicationDirection];
}

export function getCommunicationDirectionIconColor(communicationDirection) {
  const communicationDirectionIcon = {
    Incoming: 'red',
    Outgoing: 'green',
  };

  return communicationDirectionIcon[communicationDirection];
}
