import React from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { Paper } from '@mui/material';
import urljoin from 'url-join';

import EmptyState from '~/components/core/EmptyState';
import cn from '~/Utils/cn';

import { useStyles } from '../../../../assets/styles';
import styles from './InnerTabs.module.scss';

const InnerTabs = ({ tabs, tabsContainerClassName, renderContainerClassName, actionsComponent }) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const path = location.pathname.substring(match.url.length + 1);
  const breadcrumbs = path.split('/');
  const activeTabs = tabs.filter((tab) => !tab.disableTab);
  let currTabValue = activeTabs.findIndex((tab) => breadcrumbs.includes(tab.url));
  currTabValue = currTabValue >= 0 ? currTabValue : 0;
  if (activeTabs.length === 0) {
    return <EmptyState title="No tabs available to show" />;
  }

  return (
    <>
      <div>
        <Paper
          className={cn(
            'bg-initial relative rounded-none border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-slate-600',
            tabsContainerClassName
          )}
          elevation={0}
        >
          {actionsComponent && actionsComponent()}
          <Tabs
            value={currTabValue}
            className={styles.innerTabsContainer}
            variant="standard"
            indicatorColor="primary"
            textColor="primary"
          >
            {activeTabs.map((tab) => (
              <Tab
                className={cn(styles.innerTab, tab.className)}
                key={tab.url}
                label={tab.label}
                component={Link}
                to={`${match.url}/${tab.url}`}
              />
            ))}
          </Tabs>
        </Paper>
      </div>
      <div className={cn(classes.pageBody, renderContainerClassName)}>
        <Switch>
          <Redirect exact from={`${match.path}/`} to={urljoin(match.path, activeTabs[0].url)} />
          {activeTabs.map((tab) => (
            <Route
              key={tab.url}
              path={urljoin(match.path, tab.url)}
              render={() => {
                return <tab.component {...tab.props} />;
              }}
            />
          ))}
          ;
          <Redirect to={match.path} />
        </Switch>
      </div>
    </>
  );
};

InnerTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      props: PropTypes.object,
      disableTab: PropTypes.bool,
      className: PropTypes.string,
    })
  ).isRequired,
  tabsContainerClassName: PropTypes.string,
  renderContainerClassName: PropTypes.string,
  actionsComponent: PropTypes.func,
};

export default InnerTabs;
