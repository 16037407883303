import React from 'react';
import { Collapse } from '@mui/material';

import { SIDEBAR_DIALOG_CONFIG } from '~/components/AiChat/SideBarDialog/SidebarDialogConstants';
import { Text } from '~/components/core';
import cn from '~/Utils/cn';

const clive = "Hi, I'm Clive";

export const ChatDialogGreetingBar: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => (
  <Collapse
    in={!isCollapsed}
    timeout="auto"
    unmountOnExit
    className="max-h-[115px]"
    easing={{
      enter: 'linear',
      exit: 'linear',
    }}
  >
    <div
      className={cn(SIDEBAR_DIALOG_CONFIG.paddingX, 'h-[180px]  transition-opacity duration-1000', {
        'opacity-0': isCollapsed,
        'opacity-100': !isCollapsed,
      })}
    >
      <div className="row flex h-full">
        <div className="row flex  w-1/2 flex-col items-center gap-5 pt-20">
          <div className="w-fit items-start">
            <Text className="text-4xl font-bold text-white">{clive}</Text>
            <Text className="text-[20px] font-light text-white ">Your AI Assistant</Text>
          </div>
        </div>
        <div className="center mt-[-25px] flex h-full w-2/5 justify-center">
          <div className="z-300">
            <div className="relative z-[200] h-[260px] w-[240px] bg-justerFull3D bg-contain bg-center bg-no-repeat" />
          </div>
        </div>
      </div>
    </div>
  </Collapse>
);
