import React from 'react';

interface NotesPaginationProps {
  total: number;
  perPage: number;
}

const NotesPagination: React.FC<NotesPaginationProps> = ({ total, perPage }) => {
  return <div className="flex justify-end">{`Notes per page ${perPage} / ${total}`}</div>;
};

export default NotesPagination;
