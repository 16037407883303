import React from 'react';
import type { SelectProps } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';

import type { TextFieldProps } from '../TextField/TextField';
import TextField from '../TextField/TextField';

export interface SingleSelectFieldProps extends TextFieldProps {
  clearSelection?: () => void;
  clearable?: boolean;
  SelectProps?: Partial<SelectProps>;
  children?: React.ReactNode;
}

const SingleSelectField: React.FC<SingleSelectFieldProps> = ({
  id,
  clearable,
  value,
  disabled,
  clearSelection,
  children,
  SelectProps,
  ...rest
}) => {
  return (
    <TextField
      value={value}
      disabled={disabled}
      InputProps={{
        id,
        ...(clearable
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      padding: 4,
                      visibility: value && !disabled ? 'visible' : 'hidden',
                      marginRight: 15,
                    }}
                    title="Clear"
                    onClick={() => (clearSelection ? clearSelection() : null)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      select
      name={id}
      SelectProps={{
        ...SelectProps,
      }}
      {...rest}
    >
      {children}
    </TextField>
  );
};

export default SingleSelectField;
