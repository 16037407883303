import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import useCip from '~/components/hooks/useCip';
import useItrak from '~/components/hooks/useItrak';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { getBasicFnolValues, isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useContacts } from '../ContactsContext';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import FnolDocumentsFormik from '../Fnol/FnolDocumentsFormik';
import { trackOldFnolClick } from '../Fnol/FnolScreen';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import { usePolicy } from '../PolicyContainer';
import { TextFieldFormik } from '../TextFieldFormik';
import { WitnessesSummary } from '../Witness';

import {
  EmployeeInvolvedFormik,
  getInvolvedEmployeeFieldsValidationFields,
  involvedEmployeeFields,
} from './EmployeeInvolved';
import {
  WCIncidentDetailsCardFormik,
  wcIncidentDetailsFields,
  wcIncidentDetailsValidationFields,
} from './WCIncidentDetails';

import { useStyles } from '../../assets/styles';

function WCFnol(props) {
  const { organization, searchValues, onSubmit } = props;
  const { contacts } = useContacts();
  const { policy } = usePolicy();
  const { userOrganization } = useCms();
  const classes = useStyles();
  const { ItrakComponent, isItrakAutofillEnabled, itrakContactsList, ItrakInvolvedPersonSelect, ItrakUploadFiles } =
    useItrak({ claimType: useItrak.CLAIM_TYPE.wc_claim });
  const { CipComponent, isCipAutofillEnabled, cipContactsList, CipInvolvedPersonSelect, CipUploadFiles } = useCip({
    claimType: useCip.CLAIM_TYPE.wc_claim,
  });

  const isWcEmployeeHrSystemIntegration = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.WC_EMPLOYEE_HR_SYSTEM_INTEGRATION
  );

  const involvedPersonSelect = isItrakAutofillEnabled ? ItrakInvolvedPersonSelect : CipInvolvedPersonSelect;

  const submitWCFnol = async (values) => {
    try {
      const res = await axios.post('/api/v1/wc_claims', {
        ...values,
        fnol_contacts_ids: isItrakAutofillEnabled
          ? itrakContactsList.map((contact) => contact.id)
          : isCipAutofillEnabled
          ? cipContactsList.map((contact) => contact.id)
          : contacts.map((contact) => contact.id),
      });
      onSubmit(res.data.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const mapPropsToFormikValues = () => {
    let values = {
      ...getBasicFnolValues(),
      ...wcIncidentDetailsFields,
      ...policy,
      policy_id: policy.id,
      is_manual_policy: !!policy.is_manual,

      was_there_physical_evidence: '',
      physical_evidence_location: '',
      date_of_loss: searchValues.incident_date,
      property: searchValues.property_name,
      involved_employee: {
        ...involvedEmployeeFields,
        involved_person_filled: isWcEmployeeHrSystemIntegration ? false : true, // if no integration, don't wait for "employee filling", do manually
      },
      organization_id: organization.id,
      witnesses: [],
      stored_files_ids: [],
    };

    values.involved_employee.employee_external_id = searchValues.employee_external_id;
    return values;
  };

  return (
    <Formik
      initialValues={mapPropsToFormikValues()}
      validationSchema={Yup.object().shape({
        ...wcIncidentDetailsValidationFields,
        involved_employee: Yup.object().shape(getInvolvedEmployeeFieldsValidationFields(userOrganization)),
        stored_files_ids: Yup.array().of(Yup.number()),
      })}
      onSubmit={async (values, formikBag) => {
        const { setSubmitting } = formikBag;
        await submitWCFnol(values, setSubmitting);
        setSubmitting(false);
        trackOldFnolClick(policy, 'old_wc_fnol', values.reported_date);
      }}
    >
      {(formikProps) => {
        const { setFieldValue, values, isSubmitting, handleSubmit } = formikProps;
        return (
          <>
            <Grid container>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <WCIncidentDetailsCardFormik
                    ItrakComponent={ItrakComponent}
                    CipComponent={isCipAutofillEnabled && !isItrakAutofillEnabled ? CipComponent : null}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <CardDialog title="Injured Employee Details">
                    <EmployeeInvolvedFormik
                      parentId="involved_employee"
                      ContactFieldOverride={
                        isWcEmployeeHrSystemIntegration ? FnolInvolvedEmployeeForm : involvedPersonSelect
                      }
                      isFnol
                    />
                  </CardDialog>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <WitnessesSummary
                    classes={classes}
                    witnesses={values['witnesses']}
                    onAddWitness={(witness) =>
                      setFieldValue('witnesses', [...values['witnesses'], { ...witness, id: uuidv4() }])
                    }
                    onEditWitness={(witness) =>
                      setFieldValue(
                        'witnesses',
                        values['witnesses'].map((w) => (w.id === witness.id ? witness : w))
                      )
                    }
                    onDeleteWitness={(id) =>
                      setFieldValue(
                        'witnesses',
                        values['witnesses'].filter((w) => w.id !== id)
                      )
                    }
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <ItrakUploadFiles />
                  <CipUploadFiles />
                  <FnolDocumentsFormik
                    organization={organization}
                    additionalFilesList={{
                      C1: undefined,
                      C3: undefined,
                      C4: undefined,
                      'Incident Report': undefined,
                      D36: undefined,
                      'Accident Report': undefined,
                      'Workers Comp. Info & Ack': undefined,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <div className={classes.cardDivRow}>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting}>
                  Create FNOL
                </Button>
                {isSubmitting && <LoadingDialog isError={false} track="Submit WC fnol" />}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

WCFnol.propTypes = {
  organization: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchValues: PropTypes.object.isRequired,
  claimValues: PropTypes.object,
};

function FnolInvolvedEmployeeForm() {
  const { setFieldValue } = useFormikContext();

  const idPrefix = 'involved_employee.';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <LocateEmployeeContainer
        onLocate={(vals) => {
          setFieldValue(`${idPrefix}employee_id`, vals.employee_id);
          setFieldValue(`${idPrefix}contact_id`, vals.contact_id);
          setFieldValue(`${idPrefix}contact_name`, vals.contact_name);
          setFieldValue(`${idPrefix}contact.government_id`, vals.government_id);
          setFieldValue(`${idPrefix}involved_person_filled`, true);
        }}
      />
      <ErrorHelperTextFormik id={`${idPrefix}involved_person_filled`} />
    </div>
  );
}

function LocateEmployeeContainer({ onLocate }) {
  const [showDialog, setShowDialog] = React.useState(false);
  const [contactLocated, setContactLocated] = React.useState(false);

  return (
    <>
      {contactLocated ? (
        <ContactShowOnlyTextField
          label="Employee"
          contactId={contactLocated.contact_id}
          contactDisplayName={contactLocated.contact_name}
        />
      ) : (
        <Button color="primary" onClick={() => setShowDialog(true)}>
          Locate Employee
        </Button>
      )}
      {showDialog && (
        <LocateEmployeeDialog
          onClose={() => setShowDialog(false)}
          onLocate={async (vals) => {
            await onLocate(vals);
            setContactLocated(vals);
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
}

LocateEmployeeContainer.propTypes = {
  onLocate: PropTypes.func.isRequired,
};

function LocateEmployeeDialog({ onClose, onLocate }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        search_field: '',
        search_string: '',
      }}
      validationSchema={Yup.object().shape({
        search_field: Yup.string().required('Required'),
        search_string: Yup.string().required('Required'),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          const res = await axios.post('/api/v1/wc_claims/locate_employee_and_create_contact', values);
          const locateRes = res.data;
          if (locateRes.was_found) {
            onLocate(res.data);
          } else {
            formikProps.setFieldError('search_string', 'Was not found');
          }
        } catch (exception) {
          reportAxiosError(exception);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;
        return (
          <CardDialog title="Locate Employee" isDialog open onClose={onClose}>
            <TextFieldFormik
              id="search_field"
              label="Search By"
              className={classes.textField}
              disabled={isSubmitting}
              fullWidth
              select
            >
              {['Employee ID', 'Social Security Number'].map((x) => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </TextFieldFormik>
            {values['search_field'] && (
              <TextFieldFormik
                id="search_string"
                label={values['search_field']}
                disabled={isSubmitting}
                className={classes.textField}
                fullWidth
              />
            )}
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Search
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

LocateEmployeeDialog.propTypes = {
  onLocate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WCFnol;
