import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { FormHelperText, Typography } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import InvolvedInsurerFields from '~/components/Fnol/NewFnolUI/InvolvedParties/InvolvedInsurerFields';
import LoadingDialog from '~/components/LoadingDialog';

import { capitalize } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { getAllClaimRelatedRoles } from '../../../../communications/ContactUtils';
import WithConfirm from '../../../../ConfirmModal';
import { ContactEntity } from '../../../../Contact';
import {
  FsButton,
  FsIconButton,
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
} from '../../../../core';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { TrashIcon } from '../../../../icons';
import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import InvolvedPersonDialog, { involvedPersonFields } from '../../../../InvolvedPerson';
import InvolvedWrapper from '../../../../InvolvedWrapper';
import useOrganization from '../../../../OrganizationContext';
import IcdCodesFragment from '../IcdCodesFragment';

import BodilyInjuriesFragment from './BodilyInjuriesFragment';

import { useStyles } from '../../../../../assets/styles';
import styles from '../index.module.scss';

const involvedNonMotoristFields = {
  ...involvedPersonFields,
  means_of_transportation: '',
  contact_id: '',
  contact_full_name: '',
  contact: null,
  was_ambulance_needed: '',
  government_id: '',
  injuries: [],
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  representative_contact_id: '',
  representative_contact_full_name: '',
  attorney_reference_number: '',
  note: '',
  injuries_description: '',
  is_medicare_eligible: null,
  medicare_number: '',
  hospitals: [],
  was_hospital_attended: null,
  icd_codes: [],
  legal_venue_state: '',
  legal_venue_reason: '',
};

const getInvolvedNonMotoristPersonDefinedFields = (incidentConfiguration) => {
  const preDefinedFields = {
    ...incidentConfiguration.involved_parties.involved_person,
    ...incidentConfiguration.involved_parties.involved_non_motorist,
  };

  return preparePreDefinedFields(preDefinedFields);
};

const getInvolvedNonMotoristPersonValidationFields = (incidentConfiguration) =>
  getAdditionalDataValidations(getInvolvedNonMotoristPersonDefinedFields(incidentConfiguration));

const InvolvedNonMotoristPersonFragment = ({
  contactSearchProps,
  disableEditIdentity,
  disableEditContact,
  showOnly,
  contactAcceptedRoles,
  parentId,
}) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedNonMotoristPersonDefinedFields(incidentConfiguration);

  return (
    <Grid container spacing={1} alignItems="center">
      <PreDefinedField
        id="contact_id"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        disabled={disableEditIdentity || disableEditContact}
        readOnly={showOnly}
        acceptedRoles={contactAcceptedRoles}
        contactSearchProps={contactSearchProps}
        fixedSearchResults
        fullWidth
      />
      <PreDefinedField
        id="means_of_transportation"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        readOnly={showOnly}
      />
      <PreDefinedField
        id="attorney_contact_id"
        fields={preDefinedFields}
        inline
        fixedSearchResults
        readOnly={showOnly}
      />
      <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="representative_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="relationship_to_policyholder"
        fields={preDefinedFields}
        inline
        disabled={showOnly}
        readOnly={showOnly}
      />
      <PreDefinedField
        id="was_ambulance_needed"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        readOnly={showOnly}
      />
      <PreDefinedField id="was_hospitalized" fields={preDefinedFields} idPrefix={parentId} inline readOnly={showOnly} />
      <PreDefinedField
        id="hospital_contact_id"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        readOnly={showOnly}
        gridXs={4}
      />
      <PreDefinedField
        id="admission_datetime"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        readOnly={showOnly}
        gridXs={4}
      />
      <PreDefinedField
        id="discharge_datetime"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        readOnly={showOnly}
        gridXs={4}
      />
      <InvolvedInsurerFields preDefinedFields={preDefinedFields} showOnly={showOnly} />
      <PreDefinedField id="legal_venue_state" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="legal_venue_reason" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="injury_date" fields={preDefinedFields} inline readOnly={showOnly} />
      <Grid item xs={12}>
        <BodilyInjuriesFragment showOnly={showOnly} />
      </Grid>
      <PreDefinedField
        id="injuries_description"
        fields={preDefinedFields}
        idPrefix={parentId}
        inline
        readOnly={showOnly}
        gridXs={12}
      />
      <IcdCodesFragment showOnly={showOnly} fields={preDefinedFields} />
      <PreDefinedField id="note" fields={preDefinedFields} idPrefix={parentId} inline readOnly={showOnly} />
    </Grid>
  );
};

InvolvedNonMotoristPersonFragment.propTypes = {
  additionalAfterContactFields: PropTypes.object,
  contactAcceptedRoles: requiredIf(PropTypes.array, (props) => !props.showOnly),
  contactSearchProps: PropTypes.object,
  disableEditIdentity: PropTypes.bool,
  disableEditContact: PropTypes.bool,
  showOnly: PropTypes.bool,
  personLabel: PropTypes.string,
  parentId: PropTypes.string,
  hideInjuries: PropTypes.bool,
  additionalAtEndOfDialog: PropTypes.object,
};

const EditNonMotoristInvolvedDialog = (props) => {
  const { open, nonMotorist, onCancel, onSaveNonMotoristDetails, disableEditIdentity } = props;
  const { organizationContactRolesDict } = useOrganization();
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <InvolvedPersonDialog
      contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter((role) => role !== 'witness')}
      disableEditIdentity={disableEditIdentity}
      person={{ ...nonMotorist }}
      onCancel={onCancel}
      onSubmit={onSaveNonMotoristDetails}
      open={open}
      title="Edit Non Motorist"
      CustomInvolvedPersonFragment={InvolvedNonMotoristPersonFragment}
      customValidationFields={getInvolvedNonMotoristPersonValidationFields(incidentConfiguration)}
      customInitialFields={involvedNonMotoristFields}
    />
  );
};

EditNonMotoristInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  nonMotorist: requiredIf(PropTypes.object, (props) => props.open),
  onCancel: PropTypes.func.isRequired,
  onSaveNonMotoristDetails: PropTypes.func.isRequired,
  disableEditIdentity: PropTypes.bool,
};

const ShowOnlyNonMotoristInvolvedDialog = (props) => {
  const { open, nonMotorist, onCancel } = props;
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <InvolvedPersonDialog
      person={{ ...nonMotorist }}
      onCancel={onCancel}
      open={open}
      title="Non Motorist Details"
      CustomInvolvedPersonFragment={InvolvedNonMotoristPersonFragment}
      customValidationFields={getInvolvedNonMotoristPersonValidationFields(incidentConfiguration)}
      customInitialFields={involvedNonMotoristFields}
      showOnly
    />
  );
};

ShowOnlyNonMotoristInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  nonMotorist: requiredIf(PropTypes.object, (props) => props.open),
  onCancel: PropTypes.func.isRequired,
};

const AddNonMotoristInvolved = (props) => {
  const { open, onCancel, onSaveNonMotoristDetails } = props;
  const { organizationContactRolesDict } = useOrganization();
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <InvolvedPersonDialog
      contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter((role) => role !== 'witness')}
      onCancel={onCancel}
      onSubmit={onSaveNonMotoristDetails}
      open={open}
      title="Set Non Motorist"
      CustomInvolvedPersonFragment={InvolvedNonMotoristPersonFragment}
      customValidationFields={getInvolvedNonMotoristPersonValidationFields(incidentConfiguration)}
      customInitialFields={involvedNonMotoristFields}
    />
  );
};

AddNonMotoristInvolved.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveNonMotoristDetails: PropTypes.func.isRequired,
};

const NonMotoristDetails = (props) => {
  const {
    title,
    disabled,
    nonMotoristParty,
    onFetchNonMotoristDetails,
    onSaveNonMotoristDetails,
    onDeleteNonMotorist,
    errors,
  } = props;

  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [involvedFetched, setInvolvedFetched] = React.useState();

  const openEditDialog = async () => {
    setEditDialogOpen(true);
    try {
      const personFullDetails = await onFetchNonMotoristDetails(nonMotoristParty.involved_non_motorist);
      setInvolvedFetched(personFullDetails);
    } catch {
      setEditDialogOpen(false);
    }
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setInvolvedFetched(undefined);
  };

  const handleAddNonMotoristDetails = async (values) => {
    await onSaveNonMotoristDetails({
      ...values,
      non_motorist_type: nonMotoristParty?.involved_non_motorist?.means_of_transportation,
    });
    setAddDialogOpen(false);
  };
  const handleSaveNonMotoristDetails = async (values) => {
    await onSaveNonMotoristDetails(values);
    closeEditDialog();
  };
  const handleDeleteNonMotorist = async () => {
    await onDeleteNonMotorist();
    closeEditDialog();
  };

  const classes = useStyles();

  const action = onDeleteNonMotorist && (
    <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CONTACT}>
      <WithConfirm title="Delete Non Motorist?" primaryButtonName="Delete" shouldCloseOnPrimary>
        <FsIconButton onClick={onDeleteNonMotorist} disabled={disabled} icon={TrashIcon} />
      </WithConfirm>
    </PermissionsButtonWrapper>
  );

  const involvedNonMotoristError = errors && errors['involved_non_motorist'];
  return (
    <>
      <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
        <CardDialog
          title={
            <>
              {title} {action}
            </>
          }
          outlinedCard
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {nonMotoristParty.involved_non_motorist ? (
                <InvolvedWrapper
                  onEdit={openEditDialog}
                  disabled={disabled}
                  involved={nonMotoristParty.involved_non_motorist}
                  involvedType="person"
                >
                  <div className={classes.containerCentered}>
                    <ContactEntity
                      contactId={
                        nonMotoristParty.involved_non_motorist.contact_id === ''
                          ? null
                          : nonMotoristParty.involved_non_motorist.contact_id
                      }
                      contactDisplayName={nonMotoristParty.involved_non_motorist.contact_full_name}
                    />
                    <Typography style={{ paddingLeft: '8px' }} display="inline" variant="caption">
                      {capitalize(nonMotoristParty.non_motorist_type)}
                    </Typography>
                  </div>
                </InvolvedWrapper>
              ) : (
                <div className={styles.buttonWrapper}>
                  <FsButton
                    color="primary"
                    className={styles.setButton}
                    disabled={disabled}
                    onClick={() => setAddDialogOpen(true)}
                  >
                    Set Non Motorist
                  </FsButton>
                </div>
              )}
              {involvedNonMotoristError && <FormHelperText error>{involvedNonMotoristError}</FormHelperText>}
            </Grid>
          </Grid>
        </CardDialog>
        <AddNonMotoristInvolved
          nonMotoristParty={nonMotoristParty}
          open={addDialogOpen}
          onSaveNonMotoristDetails={handleAddNonMotoristDetails}
          onCancel={() => setAddDialogOpen(false)}
        />
        {editDialogOpen &&
          (!involvedFetched ? (
            <LoadingDialog isError={false} track="EditNonMotoristInvolvedDialog" />
          ) : (
            <EditNonMotoristInvolvedDialog
              open
              nonMotorist={involvedFetched}
              onSaveNonMotoristDetails={handleSaveNonMotoristDetails}
              onDeleteNonMotorist={handleDeleteNonMotorist}
              onCancel={closeEditDialog}
              disableEditIdentity={nonMotoristParty.is_locked}
            />
          ))}
      </RestrictedPermissions>
    </>
  );
};

NonMotoristDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  nonMotoristParty: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onFetchNonMotoristDetails: PropTypes.func,
  onSaveNonMotoristDetails: PropTypes.func.isRequired,
  onDeleteNonMotorist: PropTypes.func,
  errors: PropTypes.object,
};

export { involvedNonMotoristFields, NonMotoristDetails, ShowOnlyNonMotoristInvolvedDialog };

export default NonMotoristDetails;
