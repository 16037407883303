import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { useFormikContext } from 'formik';

import cn from '~/Utils/cn';

import { useClaim } from '../../ClaimContainer';
import InlineIconButton from '../../InlineIconButton';

import { getPayeeContacts } from './paymentContainerUtils';

import styles from './PaymentRequestContainer.module.scss';

function PrimaryPayeeSelectorFormikInner(props) {
  const { values, setFieldValue, contactId, disabled, paymentRequestStore } = props;
  const { claim } = useClaim();
  const { setFieldTouched } = useFormikContext();

  const payeeContacts = getPayeeContacts(values, paymentRequestStore.data, claim, {
    ignoreNonPrimaryPayeeCandadiages: true,
  });
  const contact = payeeContacts.find((contact) => contact.id === contactId);
  const currPrimaryPayeeId = values['primary_payee_id'];
  const isSelected = currPrimaryPayeeId === contactId && contactId !== '';
  const isPrimaryPayeeDisabled = disabled || contactId === '' || !contact;

  React.useEffect(() => {
    if (currPrimaryPayeeId === contactId) {
      if (values.primary_payee !== contact) {
        if (contact) {
          setFieldValue('primary_payee', contact);
        } else {
          setFieldValue('primary_payee_id', '');
          setFieldValue('primary_payee', '');
        }
      }
    }
  }, [contact, contactId, currPrimaryPayeeId, setFieldValue, values.primary_payee]);

  React.useEffect(() => {
    if (payeeContacts.length === 0) {
      return;
    }

    if (!currPrimaryPayeeId || !payeeContacts.map((contact) => contact.id).includes(currPrimaryPayeeId)) {
      setFieldValue('primary_payee_id', payeeContacts[0].id);
    }
  });

  React.useEffect(() => {
    if (
      (!values.primary_payee && currPrimaryPayeeId) ||
      (currPrimaryPayeeId && values.primary_payee.id !== currPrimaryPayeeId)
    ) {
      setFieldValue(
        'primary_payee',
        payeeContacts.find((contact) => contact.id === currPrimaryPayeeId)
      );
      setFieldTouched('primary_payee', false);
    }
  }, [currPrimaryPayeeId, setFieldValue, setFieldTouched, values, claim, paymentRequestStore, payeeContacts]);

  return (
    <Tooltip title={isSelected ? 'Primary Payee' : isPrimaryPayeeDisabled ? '' : 'Select as Primary Payee'}>
      <span style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
        <InlineIconButton
          className={cn(styles.starIcon, { [styles.selected]: isSelected })}
          icon={StarIcon}
          onClick={() => {
            setFieldValue('primary_payee_id', contactId);
          }}
          disabled={isPrimaryPayeeDisabled}
        />
      </span>
    </Tooltip>
  );
}

PrimaryPayeeSelectorFormikInner.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  paymentRequestStore: PropTypes.object.isRequired,
  contactId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default PrimaryPayeeSelectorFormikInner;
