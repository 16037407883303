import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { COUNTRIES_DICT } from '../../../Types';

export default function MailToParagraph({ paymentRequest }) {
  return (
    <Typography style={{ marginBottom: '8px' }} variant="caption" display="block">
      <div style={{ textDecoration: 'underline' }}>Mail To:</div>
      <div>{paymentRequest.mail_to_name}</div>
      <div>{paymentRequest.mail_to_address.address1}</div>
      {paymentRequest.mail_to_address.address2 && <div>{paymentRequest.mail_to_address.address2}</div>}
      <div>
        {paymentRequest.mail_to_address.city}
        {paymentRequest.mail_to_address.state}, {paymentRequest.mail_to_address.zipcode}
      </div>
      <div>
        {!paymentRequest.mail_to_address.country === 'US' &&
          paymentRequest.mail_to_address.country &&
          COUNTRIES_DICT[paymentRequest.mail_to_address.country]}
      </div>
    </Typography>
  );
}
MailToParagraph.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
};
