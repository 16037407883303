import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { FormHelperText, Typography } from '@material-ui/core';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { capitalize } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { getAllClaimRelatedRoles } from '../../communications/ContactUtils';
import WithConfirm from '../../ConfirmModal';
import { ContactEntity } from '../../Contact';
import { FsButton, FsIconButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../core';
import { TrashIcon } from '../../icons';
import InvolvedPersonDialog from '../../InvolvedPerson';
import InvolvedWrapper from '../../InvolvedWrapper';
import useOrganization from '../../OrganizationContext';

import { useStyles } from '../../../assets/styles';

const spacing = 1;

const nonMotoristPartyFields = {
  involved_non_motorist: null,
  non_motorist_type: '',
};

const pedestrianPartyFields = {
  ...nonMotoristPartyFields,
  non_motorist_type: 'pedestrian',
};

const bicyclistPartyFields = {
  ...nonMotoristPartyFields,
  non_motorist_type: 'bicyclist',
};

function EditNonMotoristInvolvedDialog(props) {
  const { open, nonMotorist, onCancel, onSaveNonMotoristDetails, disableEditIdentity } = props;
  const nonMotoristType = nonMotorist && capitalize(nonMotorist.non_motorist_type);
  const { organizationContactRolesDict } = useOrganization();

  return (
    <InvolvedPersonDialog
      contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter((role) => role !== 'witness')}
      disableEditIdentity={disableEditIdentity}
      person={{ ...nonMotorist }}
      onCancel={onCancel}
      onSubmit={onSaveNonMotoristDetails}
      open={open}
      title={`Edit ${nonMotoristType}`}
    />
  );
}

EditNonMotoristInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  nonMotorist: requiredIf(PropTypes.object, (props) => props.open),
  onCancel: PropTypes.func.isRequired,
  onSaveNonMotoristDetails: PropTypes.func.isRequired,
  disableEditIdentity: PropTypes.bool,
};

function ShowOnlyNonMotoristInvolvedDialog(props) {
  const { open, nonMotorist, onCancel } = props;

  return (
    <InvolvedPersonDialog
      person={{ ...nonMotorist }}
      onCancel={onCancel}
      open={open}
      title={`Non Motorist (${nonMotorist.non_motorist_type}) Details`}
      showOnly
    />
  );
}

ShowOnlyNonMotoristInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  nonMotorist: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const AddNonMotoristInvolved = (props) => {
  const { nonMotoristParty, open, onCancel, onSaveNonMotoristDetails } = props;
  const { organizationContactRolesDict } = useOrganization();

  return (
    <InvolvedPersonDialog
      contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter((role) => role !== 'witness')}
      onCancel={onCancel}
      onSubmit={onSaveNonMotoristDetails}
      open={open}
      title={`Add ${capitalize(nonMotoristParty.non_motorist_type)}`}
    />
  );
};

AddNonMotoristInvolved.propTypes = {
  nonMotoristParty: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveNonMotoristDetails: PropTypes.func.isRequired,
};

function NonMotoristDetails(props) {
  const { title, disabled, nonMotoristParty, onSaveNonMotoristDetails, onDeleteNonMotorist, errors } = props;

  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const handleAddNonMotoristDetails = async (values) => {
    await onSaveNonMotoristDetails({ ...values, non_motorist_type: nonMotoristParty.non_motorist_type });
    setAddDialogOpen(false);
  };
  const handleSaveNonMotoristDetails = async (values) => {
    await onSaveNonMotoristDetails(values);
    setEditDialogOpen(false);
  };
  const handleDeleteNonMotorist = async () => {
    await onDeleteNonMotorist();
    setEditDialogOpen(false);
  };

  const classes = useStyles();

  const action = onDeleteNonMotorist && (
    <WithConfirm title="Delete NonMotorist?" primaryButtonName="Delete" shouldCloseOnPrimary>
      <FsIconButton onClick={onDeleteNonMotorist} disabled={disabled} icon={TrashIcon} />
    </WithConfirm>
  );

  const involvedNonMotoristError = errors && errors['involved_non_motorist'];
  return (
    <>
      <CardDialog
        title={
          <>
            {title} {action}
          </>
        }
        outlinedCard
      >
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            {nonMotoristParty.involved_non_motorist ? (
              <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                <InvolvedWrapper
                  onEdit={() => setEditDialogOpen(true)}
                  disabled={disabled}
                  involved={nonMotoristParty.involved_non_motorist}
                  involvedType="person"
                >
                  <div className={classes.containerCentered}>
                    <ContactEntity
                      contactId={
                        nonMotoristParty.involved_non_motorist.contact_id === ''
                          ? null
                          : nonMotoristParty.involved_non_motorist.contact_id
                      }
                      contactDisplayName={nonMotoristParty.involved_non_motorist.contact_full_name}
                    />
                    <Typography style={{ paddingLeft: '8px' }} display="inline" variant="caption">
                      {capitalize(nonMotoristParty.non_motorist_type)}
                    </Typography>
                  </div>
                </InvolvedWrapper>
              </RestrictedPermissions>
            ) : (
              <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                <FsButton color="primary" disabled={disabled} onClick={() => setAddDialogOpen(true)}>
                  Set {nonMotoristParty.non_motorist_type}
                </FsButton>
              </RestrictedPermissions>
            )}
            {involvedNonMotoristError && <FormHelperText error>{involvedNonMotoristError}</FormHelperText>}
          </Grid>
        </Grid>
      </CardDialog>
      <AddNonMotoristInvolved
        nonMotoristParty={nonMotoristParty}
        open={addDialogOpen}
        onSaveNonMotoristDetails={handleAddNonMotoristDetails}
        onCancel={() => setAddDialogOpen(false)}
      />
      <EditNonMotoristInvolvedDialog
        open={editDialogOpen}
        nonMotorist={nonMotoristParty.involved_non_motorist}
        onSaveNonMotoristDetails={handleSaveNonMotoristDetails}
        onDeleteNonMotorist={handleDeleteNonMotorist}
        onCancel={() => setEditDialogOpen(false)}
        disableEditIdentity={nonMotoristParty.is_locked}
      />
    </>
  );
}

NonMotoristDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  nonMotoristParty: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSaveNonMotoristDetails: PropTypes.func.isRequired,
  onDeleteNonMotorist: PropTypes.func,
  errors: PropTypes.object,
};

function AddNonMotoristParty({ onSubmitNewNonMotorist, onCancel }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  async function handleSubmit(nonMotoristType) {
    setIsSubmitting(true);
    await onSubmitNewNonMotorist({ non_motorist_type: nonMotoristType });
    setIsSubmitting(false);
  }

  return (
    <CardDialog title="Choose Non Motorist Type" isDialog onClose={onCancel} maxWidth="xs">
      <div className={classes.buttonsContainer}>
        {['pedestrian', 'bicyclist'].map((nonMotoristType) => (
          <Button
            key={nonMotoristType}
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(nonMotoristType)}
            disabled={isSubmitting}
          >
            {nonMotoristType}
          </Button>
        ))}
      </div>
    </CardDialog>
  );
}

AddNonMotoristParty.propTypes = {
  onSubmitNewNonMotorist: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export {
  AddNonMotoristInvolved as AddNonMotoristInvolvedEmbedded,
  AddNonMotoristParty,
  bicyclistPartyFields,
  EditNonMotoristInvolvedDialog,
  pedestrianPartyFields,
  ShowOnlyNonMotoristInvolvedDialog,
};
export default NonMotoristDetails;
