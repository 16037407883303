import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import NoteIcon from '@material-ui/icons/Note';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import Text from '~/components/core/TextComponents/Text';
import FailedTokensWarningBanner from '~/components/GenericTemplates/FailedTokensWarningBanner';
import GenericTemplateSelectionContainerFormik from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import { FROM_TEMPLATE_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { serverDateTimeToLocal, serverDateToLocal } from '~/DateTimeUtils';
import { COMMUNICATION_CHANNEL_DICT, CONFIGURATION_FEATURES_NAMES, NOTE_SUBJECT } from '~/Types';
import { getExposuresLabels, isFeatureEnabled, reportAxiosError } from '~/Utils';
import { isClaimWriteDisabled } from '~/Utils/ClaimUtils';

import CommunicationLink from './communications/CommunicationLink';
import DocumentLink from './Documents/DocumentLink';
import PhotosLink from './Gallery/PhotosLink';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import ExposureMultiSelectTextFieldFormik from './ExposureMultiSelectTextFieldFormik';
import InlineIconButton from './InlineIconButton';
import { getInternalCommunicationObjectDescription } from './InternalCommunication';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import TextFieldFormik from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

const noteValidation = Yup.object().shape({
  title: Yup.string().required('Required'),
  note_text: Yup.string().required('Required'),
  exposure_ids: Yup.array().required('Required').min(1, 'Required'),
  [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID]: Yup.number().nullable(),
  [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT]: Yup.object().nullable(),
});

const spacing = 1;

const CreateNoteDialog = (props) => {
  const {
    onSubmitNote,
    onClose,
    defaultExposureIds,
    initialTitle = '',
    initialNoteText = '',
    open = true,
    onMinimized,
    disableMinimized,
  } = props;

  const { claim } = useClaim();
  const { userOrganization } = useCms();

  const [isFromTemplateDialogOpen, setIsFromTemplateDialogOpen] = useState(false);
  const isGenericTemplatesEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.GENERIC_TEMPLATES);
  const [failedTokens, setFailedTokens] = useState(null);

  const classes = useStyles();

  const handleOpenFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(true);
  };

  const handleCloseFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(false);
  };

  return (
    <Formik
      initialValues={{
        title: initialTitle,
        note_text: initialNoteText,
        exposure_ids: defaultExposureIds || [],
        is_confidential: false,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID]: null,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT]: null,
      }}
      validationSchema={noteValidation}
      onSubmit={async (values, formikProps) => {
        try {
          const res = await axios.post(`/api/v1/claims/${claim.id}/notes`, values);
          await onSubmitNote(res.data.note_id);
        } catch (error) {
          await reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => (
        <CardDialog
          open={open}
          isDialog
          title="Create Note"
          maxWidth="sm"
          onClose={onClose}
          preventClose={formikProps.isSubmitting}
          onMinimized={onMinimized}
          disableMinimized={disableMinimized}
          action={
            <RestrictedPermissions action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.FULL}>
              <ConfidentialityButton
                isConfidential={formikProps.values['is_confidential']}
                onChangeConfidentiality={() =>
                  formikProps.setFieldValue('is_confidential', !formikProps.values['is_confidential'])
                }
              />
            </RestrictedPermissions>
          }
        >
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <div className="flex justify-between">
                <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
                  <span className="flex inline-flex items-center">
                    <NoteIcon /> &nbsp; Note &nbsp; {claim.claim_id_display}
                  </span>
                </Text>
                {isGenericTemplatesEnabled ? (
                  <div className="flex items-center justify-end">
                    <Button color="primary" onClick={handleOpenFromTemplateDialog}>
                      <AddIcon />
                      From template
                    </Button>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="title" label="Title" fullWidth className={classes.textField} />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="note_text" label="Note" className={classes.textField} fullWidth multiline />
            </Grid>
            <Grid item xs={12}>
              <FailedTokensWarningBanner failedTokens={failedTokens} />
            </Grid>
            <Grid item xs={12}>
              <ExposureMultiSelectTextFieldFormik claim={claim} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formikProps.handleSubmit}
                  disabled={formikProps.isSubmitting}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
          {isFromTemplateDialogOpen ? (
            <GenericTemplateSelectionContainerFormik
              handleClose={handleCloseFromTemplateDialog}
              templateType="note"
              titleFieldId="title"
              bodyFieldId="note_text"
              shouldConvertBodyHtmlToText
              setFailedTokens={setFailedTokens}
            />
          ) : null}
        </CardDialog>
      )}
    </Formik>
  );
};

CreateNoteDialog.propTypes = {
  notesThreadId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSubmitNote: PropTypes.func.isRequired,
  defaultExposureIds: PropTypes.array,
  initialTitle: PropTypes.string,
  initialNoteText: PropTypes.string,
  open: PropTypes.bool,
  disableMinimized: PropTypes.bool,
  onMinimized: PropTypes.func,
};

function ConfidentialityButton({ isConfidential, onChangeConfidentiality }) {
  const { user } = useCms();
  const classes = useStyles();
  const { claim } = useClaim();

  const tooltipPrefix = isConfidential ? 'Note is confidential. ' : '';
  return (
    <InlineIconButton
      useIconButton
      icon={isConfidential ? LockIcon : LockOpenIcon}
      tooltipTitle={tooltipPrefix + 'Confidential notes will not appear in claim export'}
      defaultColor={isConfidential ? 'secondary' : undefined}
      onClick={onChangeConfidentiality}
      className={classes.leftButtonIcon}
      disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
    />
  );
}
ConfidentialityButton.propTypes = {
  isConfidential: PropTypes.bool.isRequired,
  onChangeConfidentiality: PropTypes.func.isRequired,
};

function DocumentNote({ document }) {
  const { documentTypesDict } = useOrganization();

  return (
    <>
      {document.is_removed && (
        <>
          <Typography style={{ color: 'red' }}>Document was removed from claim</Typography>
          <div>{document.removed_reason}</div>
          <div>
            <br />
          </div>
        </>
      )}
      <DocumentLink text={`View document #${document.claim_internal_id}`} document={document} />
      <div>
        Name: <em>{document.document_name}</em>, Type:{' '}
        <em>{documentTypesDict?.[document.type]?.desc || document.type}</em>
      </div>
      <div>
        Summary: <em>{document.summary}</em>
      </div>
    </>
  );
}

DocumentNote.propTypes = {
  document: PropTypes.object.isRequired,
};

function NoteMiniCard(props) {
  const { note, onNoteUpdated } = props;
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();

  // if type is unknown, use the regular note type
  const noteSubject = NOTE_SUBJECT[note.subject] ? NOTE_SUBJECT[note.subject] : NOTE_SUBJECT['note'];
  const exposureLabels = getExposuresLabels(claim);

  const handleChangeConfidentiality = async () => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/notes/${note.id}/confidentiality`, {
        is_confidential: !note.is_confidential,
      });
      await onNoteUpdated();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  function noteToText(claim, onClaimUpdate, note) {
    if (note.type === 'communication_note') {
      return (
        <>
          {note.is_detached && (
            <>
              <Typography style={{ color: 'red' }}>Communication was removed from claim</Typography>
              <div>
                <br />
              </div>
            </>
          )}
          <CommunicationLink text="View communication" communication={note.communication} onUpdate={onClaimUpdate} />
          {['email', 'sms'].includes(note.communication.channel) &&
            note.communication.is_delivery_issue_error_exists && (
              <Typography variant="body2" style={{ color: 'red' }}>
                {COMMUNICATION_CHANNEL_DICT[note.communication.channel]} delivery failed
              </Typography>
            )}
          <div>
            Summary: <em>{note.communication.summary}</em>
          </div>
        </>
      );
    } else if (note.type === 'document_note') {
      return <DocumentNote document={note.document} />;
    } else if (note.type === 'multiple_documents_note') {
      return note.documents.map((document, i) => <DocumentNote document={document} key={i} />);
    } else if (note.type === 'photos_note') {
      return (
        <PhotosLink
          photos={claim.documents.filter((doc) => note.document_ids.includes(doc.id))}
          text="View media files"
          galleryDialogTitle="Media Uploaded"
        />
      );
    } else if (note.type === 'internal_communication_note') {
      const internalCommunication = note.internal_communication;
      const objectDescription = getInternalCommunicationObjectDescription(internalCommunication, claim, onClaimUpdate);
      return (
        <>
          {objectDescription && <span>Regarding {objectDescription}</span>}
          <div>
            Title: <em>{internalCommunication.title}</em>
          </div>
          <div>Details:</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{internalCommunication.details}</div>
        </>
      );
    }

    return (
      <Typography display="block" style={{ whiteSpace: 'pre-wrap' }}>
        {note.note_text}
      </Typography>
    );
  }

  return (
    <CardDialog noCardTitle outlinedCard>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <div className={classes.container} style={{ alignItems: 'center' }}>
            <Icon className={classes.leftButtonIcon}>{noteSubject.icon}</Icon>
            <Typography variant="caption">{noteSubject.display}</Typography>
            <div className={classes.chips}>
              {exposureLabels
                .filter((exposure) => note.exposure_ids.includes(exposure.id))
                .map((exposure) => (
                  <Chip
                    size="small"
                    color="primary"
                    key={exposure.id}
                    label={exposure.label}
                    className={classes.chip}
                  />
                ))}
            </div>
            <span className={classes.spacer} />
            {/* { note.is_automatic && <Icon className={classes.leftButtonDialog}>{'settings'}</Icon> } */}
            <RestrictedPermissions action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.FULL}>
              <ConfidentialityButton
                isConfidential={note.is_confidential}
                onChangeConfidentiality={handleChangeConfidentiality}
              />
            </RestrictedPermissions>
            <Typography display="block" variant="caption" className={classes.leftButtonDialog}>
              {note.creator}
            </Typography>
            <Tooltip title={serverDateTimeToLocal(note.creation_date)}>
              <Typography display="block" variant="caption">
                {serverDateToLocal(note.creation_date)}
              </Typography>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
          <Typography display="block" variant="h6">{`NOTE-${note.claim_internal_id} - ${note.title}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          {noteToText(claim, onClaimUpdate, note)}
        </Grid>
      </Grid>
    </CardDialog>
  );
}

NoteMiniCard.propTypes = {
  note: PropTypes.object.isRequired,
  onNoteUpdated: PropTypes.func.isRequired,
};

function ExposureNotesCard({ claim, exposure, onUpdate }) {
  const classes = useStyles();
  const { userOrganization } = useCms();
  const [showNewNoteDialog, setShowNewNoteDialog] = useState(false);
  const { add } = useMinimizedDialogs();
  const {
    isLoading,
    isError,
    data: notes,
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/notes`, { params: { exposure_ids: [exposure.id] } });

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }
  const isMinimizedDialogEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MINIMIZED_DIALOG_FEATURE
  );

  const handleSubmit = async () => {
    await onUpdate();
    await reloadData();
  };

  const createNoteDialogProps = {
    claimId: claim.id,
    onClose: () => setShowNewNoteDialog(false),
    onSubmitNote: async () => {
      await handleSubmit();
      setShowNewNoteDialog(false);
    },
    defaultExposureIds: [exposure.id],
  };

  const openCreateNoteDialog = () => {
    if (isMinimizedDialogEnabled) {
      const { handleCloseDialog } = add({
        barHeader: 'New Exposure Note',
        type: 'NOTE',
        dialogComponent: CreateNoteDialog,
        dialogProps: {
          ...createNoteDialogProps,
          onClose: () => handleCloseDialog(),
          onSubmitNote: async () => {
            await handleSubmit();
            handleCloseDialog();
          },
        },
      });
    } else {
      setShowNewNoteDialog(true);
    }
  };

  return (
    <>
      <CardDialog
        title="Exposure Notes"
        action={
          <Button color="primary" onClick={openCreateNoteDialog}>
            <AddIcon className={classes.leftButtonIcon} />
            Add New Note
          </Button>
        }
      >
        <div style={{ maxHeight: '35vh', overflowY: 'scroll' }}>
          {notes
            .sort((note1, note2) => (note1.id < note2.id ? -1 : 1))
            .reverse()
            .map((note) => (
              <div className={classes.cardDivRow} key={note.id}>
                <NoteMiniCard note={note} onNoteUpdated={reloadData} />
              </div>
            ))}
        </div>
      </CardDialog>

      {showNewNoteDialog ? <CreateNoteDialog {...createNoteDialogProps} /> : null}
    </>
  );
}

ExposureNotesCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export { CreateNoteDialog, ExposureNotesCard, NoteMiniCard };
