import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import PermissionDeniedTooltip from '~/components/core/Permissions/PermissionDeniedTooltip';
import cn from '~/Utils/cn';

import { useRestrictedPermissions } from '../../Permissions/RestrictedPermissions';
import { FsWrappersWithTooltipSharedProptypes, getEnumObjectFromArray, MUI_COLOR_STRINGS } from '../FsWrappersUtils';
import { WrapperWithTooltip } from '../UtilityComponents/WrapperWithTooltip';

import { useStyles } from '../../../../assets/styles';

const FsIconButton = ({
  icon: Icon,
  onClick,
  disabled,
  size,
  color,
  style,
  title,
  className,
  component,
  to,
  'aria-label': ariaLabel,
  ignorePermissions,
  tooltipText,
  children,
}) => {
  const classes = useStyles();

  const { userHasContextPermissions } = useRestrictedPermissions();
  const hasPermissions = ignorePermissions || userHasContextPermissions;
  const isDisabled = disabled || !hasPermissions;

  const iconComponentProps = {
    disabled: isDisabled,
  };
  const iconComponentClasses = { [classes.hoverableIconDisabled]: isDisabled };
  const iconComponent = Icon ? (
    <Icon className={cn(classes.iconSize, iconComponentClasses)} {...iconComponentProps} />
  ) : (
    React.cloneElement(children, {
      ...children.props,
      ...iconComponentProps,
      className: cn(hasPermissions && children.props.className, iconComponentClasses),
    })
  );

  const iconButton = (
    <IconButton
      onClick={onClick}
      disabled={isDisabled}
      size={size}
      color={color}
      style={style}
      title={title}
      component={component}
      to={to}
      className={cn(classes.iconButton, className)}
      aria-label={ariaLabel}
    >
      {iconComponent}
    </IconButton>
  );

  if (hasPermissions) {
    return <WrapperWithTooltip component={iconButton} tooltipText={tooltipText} />;
  }

  return <PermissionDeniedTooltip>{iconButton}</PermissionDeniedTooltip>;
};

FsIconButton.SIZES = getEnumObjectFromArray(['small', 'medium']);
FsIconButton.COLORS = getEnumObjectFromArray(MUI_COLOR_STRINGS);

FsIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  to: PropTypes.string,
  'aria-label': PropTypes.string,
  children: PropTypes.node,
  ...FsWrappersWithTooltipSharedProptypes,
};

export default FsIconButton;
