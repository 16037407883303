import React from 'react';

import type { VideoCallCommunication } from '~/components/communications/types';
import VideoPlayer from '~/components/core/Atomic/VideoPlayer';
import Text from '~/components/core/TextComponents/Text';

interface Props {
  communication: VideoCallCommunication;
}

const EndedVideoCallDetails: React.FC<Props> = ({ communication }) => (
  <div className="my-20 gap-10">
    <div className="flex gap-10">
      <Text variant={Text.VARIANTS.XS}>Meeting start time:</Text>{' '}
      <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
        {communication.meeting_start_datetime ? new Date(communication.meeting_end_datetime).toLocaleString() : ''}
      </Text>
    </div>
    <div className="flex gap-10">
      <Text variant={Text.VARIANTS.XS}>Meeting end time:</Text>{' '}
      <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
        {communication.meeting_end_datetime ? new Date(communication.meeting_end_datetime).toLocaleString() : ''}
      </Text>
    </div>

    {communication.recording_url ? (
      <VideoPlayer className="mt-20 max-w-full" controls src={communication.recording_url} />
    ) : null}
  </div>
);

export default EndedVideoCallDetails;
