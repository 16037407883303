import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import _ from 'lodash';

import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { getLobDescription } from '../../../Utils/lobUtils';
import useOrganization from '../../OrganizationContext';
import TextFieldFormik from '../../TextFieldFormik';

import useLobOptions from './hooks/useLobOptions';

import { useStyles } from '../../../assets/styles';

export const LobSelectFormik = ({ fieldId, subOrganizationIds = [], disabled = false, label = 'Line of Business' }) => {
  const classes = useStyles();
  const { subOrganizationEnabled } = useOrganization();
  const { lobOptions, isLoading } = useLobOptions({ subOrganizationIds });
  const { lobConfigurationsDict } = useLobConfiguration();

  const isDisabled = disabled || isLoading || (subOrganizationEnabled && _.isEmpty(subOrganizationIds));

  return (
    <TextFieldFormik id={fieldId} label={label} className={classes.textField} disabled={isDisabled} select fullWidth>
      {lobOptions.map((lob) => (
        <MenuItem key={lob} value={lob}>
          {getLobDescription(lob, lobConfigurationsDict)}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

LobSelectFormik.propTypes = {
  subOrganizationIds: PropTypes.array.isRequired,
  fieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};
