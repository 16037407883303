import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import mixpanel from '~/components/CmsMain/mixpanel';
import SendSmsCommunicationCardContainer from '~/components/communications/SmsCommunicationCard/SmsCommunicationCard';
import { useCms } from '~/components/hooks/useCms';
import SmsIcon from '~/components/icons/notifications/SmsIcon';
import InlineIconButton from '~/components/InlineIconButton';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import cn from '~/Utils/cn';

const SmsCommunicationPopover = ({
  contact,
  onUpdate,
  communicationDetails = {},
  mixpanelSource,
  ignorePermissions = false,
  useIconButton = false,
  messageContent,
}) => {
  const classes = useStyles();
  const { user } = useCms();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_SMS_COMMUNICATION_CLICKED, { source: mixpanelSource });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const isDisabled = !user.is_sending_sms_allowed || contact.is_smsing_allowed === false || user.role.is_view_only;
  return (
    <>
      <InlineIconButton
        icon={SmsIcon}
        onClick={handleOpenDialog}
        disabled={isDisabled}
        className={cn({ [classes.hoverableNonFilledIcon]: !isDisabled })}
        ignorePermissions={ignorePermissions}
        useIconButton={useIconButton}
      />
      {isDialogOpen && (
        <SendSmsCommunicationCardContainer
          contact={contact}
          contactPhoneId={communicationDetails.id}
          onCancel={handleCloseDialog}
          onSendSms={() => {
            handleCloseDialog();
            if (onUpdate) {
              onUpdate();
            }
          }}
          messageContent={messageContent}
        />
      )}
    </>
  );
};

SmsCommunicationPopover.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  communicationDetails: PropTypes.object,
  mixpanelSource: PropTypes.string,
  ignorePermissions: PropTypes.bool,
  useIconButton: PropTypes.bool,
  messageContent: PropTypes.string,
};

export default SmsCommunicationPopover;
