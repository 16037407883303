const BASE_PATH = `/api/v1/generic_templates`;

export const GENERIC_TEMPLATES_ROUTES = {
  GET_TEMPLATES: (organizationId: number): string => `${BASE_PATH}/organization/${organizationId}`,
  CREATE_TEMPLATE: (organizationId: number): string => `${BASE_PATH}/organization/${organizationId}`,
  GET_TEMPLATE: (organizationId: number, template_id: number): string =>
    `${BASE_PATH}/organization/${organizationId}/template/${template_id}`,
  GENERATE_FROM_TEMPLATE: ({
    organizationId,
    template_id,
    claimId,
    exposureId,
  }: {
    organizationId: number;
    template_id: number;
    claimId: number;
    exposureId?: number;
  }): string =>
    `${BASE_PATH}/organization/${organizationId}/claim/${claimId}/template/${template_id}/generate_from_template${
      exposureId ? `?exposure_id=${exposureId}` : ''
    }`,
  UPDATE_TEMPLATE: (organizationId: number, template_id: number): string =>
    `${BASE_PATH}/organization/${organizationId}/template/${template_id}`,
};
