import React, { useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import CardDialog from '../CardDialog';
import ClaimLink from '../ClaimLink';
import { SortableTable } from '../core';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import LoadingIndicator from '../LoadingIndicator';
import useDataFetcher from '../useDataFetcher';

import DamageAssessmentContainer from './DamageAssessmentContainer';

import { useStyles } from '../../assets/styles';

function useDamageAssessmentsFiltered(isReinspected) {
  const resource = isReinspected ? 'damage_assessments_reinspected' : 'damage_assessments_not_reinspected';
  const {
    isLoading,
    isError,
    data: damageAssessments,
    reloadData: fetchDamageAssessments,
  } = useDataFetcher(`/api/v1/management/${resource}`);
  return [damageAssessments, fetchDamageAssessments, isLoading, isError];
}

function DamageAssessmentReinspectionsScreen() {
  const classes = useStyles();
  const { setPageTitle } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();

  const [reinspectedDAs, getReinspectedDAs, isLoadingReinspected, isErrorReinspected] =
    useDamageAssessmentsFiltered(true);
  const [notReinspectedDAs, getNotReinspectedDAs, isLoadingNotReinspected, isErrorNotReinspected] =
    useDamageAssessmentsFiltered(false);

  useEffect(
    () => setPageTitle('Damage Assessment Reinspection Dashboard', 'Reinspection - Five Sigma CMS'),
    [setPageTitle]
  );

  const columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'da_container',
      disableSort: true,
      width: '40px',
      specialCell: (row) => (
        <DamageAssessmentContainer
          claimId={row.claim.id}
          exposure={row.exposure}
          viewOnly
          iconOnly
          onUpdate={() => Promise.all([getReinspectedDAs(), getNotReinspectedDAs()])}
        />
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'claim_id_display',
      label: 'Claim',
      specialCell: (row) => <ClaimLink claimId={row.claim_id} linkText={row.claim_id_display} />,
    },
    { id: 'handling_adjuster', label: 'File Owner' },
    { id: 'peril_desc', label: 'Peril' },
    { id: 'coverage', label: 'Coverage', specialCell: (row) => row.exposure.coverage_type_desc },
    {
      id: 'amount',
      numeric: true,
      label: 'Assessment',
      specialCell: (row) => currencyFormatter.format(row.damage_assessment.amount),
    },
  ];

  const columnsDataInspected = [
    ...columnData,
    {
      id: 'total_overwrite',
      label: 'Overwrite',
      specialCell: (row) => currencyFormatter.format(row.damage_assessment_reinspection.total_overwrite),
    },
    {
      id: 'total_underwrite',
      label: 'Underwrite',
      specialCell: (row) => currencyFormatter.format(row.damage_assessment_reinspection.total_underwrite),
    },
    {
      id: 'total_exception',
      label: 'Exception',
      specialCell: (row) => currencyFormatter.format(row.damage_assessment_reinspection.total_exception),
    },
    {
      id: 'score',
      label: 'Score',
      specialCell: (row) => `${(row.damage_assessment_reinspection.score * 100).toFixed(2)}%`,
    },
    {
      id: 'net_exception_variance',
      label: 'Net Variance',
      specialCell: (row) => currencyFormatter.format(row.damage_assessment_reinspection.net_exception_variance),
    },
  ];

  if (isLoadingReinspected || isLoadingNotReinspected) {
    return <LoadingIndicator isError={isErrorReinspected || isErrorNotReinspected} />;
  }

  const totalOverwrite = reinspectedDAs.reduce((acc, x) => acc + x.damage_assessment_reinspection.total_overwrite, 0);
  const totalUnderwrite = reinspectedDAs.reduce((acc, x) => acc + x.damage_assessment_reinspection.total_underwrite, 0);
  const avgScore =
    reinspectedDAs.reduce((acc, x) => acc + x.damage_assessment_reinspection.score, 0) / reinspectedDAs.length;
  const totalGrossEstimate = reinspectedDAs.reduce((acc, x) => acc + x.damage_assessment.amount, 0);

  return (
    <div className={classes.cardDivRow}>
      <CardDialog title="Damage Assessment Reinspection Dashboard">
        <div>
          <Typography display="block" variant="h6">
            Not Reinspected
          </Typography>
          <SortableTable rows={notReinspectedDAs} columns={columnData} />
        </div>
        <br />
        <div style={{ marginTop: '12px', marginBottom: '12px' }}>
          <Typography display="block" variant="h6">
            Reinspected
          </Typography>
          <SortableTable rows={reinspectedDAs} columns={columnsDataInspected} />
        </div>
        <div style={{ marginTop: '12px', marginBottom: '12px' }}>
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Total Overwrite: </strong>
                    {currencyFormatter.format(totalOverwrite)}
                  </TableCell>
                  <TableCell>
                    <strong>Total Underwrite: </strong>
                    {currencyFormatter.format(totalUnderwrite)}
                  </TableCell>
                  <TableCell>
                    <strong>Total Exception Value: </strong>
                    {currencyFormatter.format(totalOverwrite + totalUnderwrite)}
                  </TableCell>
                  <TableCell>
                    <strong>Avg Score: </strong>
                    {`${(avgScore * 100).toFixed(2)}%`}
                  </TableCell>
                  <TableCell>
                    <strong>Overall Score: </strong>
                    {`${(((totalOverwrite + totalUnderwrite) / totalGrossEstimate) * 100).toFixed(2)}%`}
                  </TableCell>
                  <TableCell>
                    <strong>Net Exception Variance: </strong>
                    {currencyFormatter.format(totalOverwrite - totalUnderwrite)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </CardDialog>
    </div>
  );
}

export default DamageAssessmentReinspectionsScreen;
