import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { findKey, get, isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../../../CardDialog';
import { ErrorHelperTextFormik } from '../../../../core/Formik/ErrorHelperTextFormik';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { CustomFieldsContextProvider } from '../../../../IncidentConfiguration/CustomFieldsContext';
import { usePolicy } from '../../../../PolicyContainer';
import { InvolvedGenericParty } from '../InvolvedGenericParty';
import {
  getInvolvedGenericValidationFields,
  InvolvedGenericFragment,
} from '../InvolvedGenericParty/InvolvedGenericFragment';

import { FIRST_PARTY_VALUES } from '.';

import { useStyles } from '../../../../../assets/styles';
import styles from '../index.module.scss';

const GeneralInvolvedPartiesCard = ({
  firstPartyValues,
  thirdPartyValues,
  onFetchInvolvedDetails,
  onAddParty,
  onSaveParty,
  onDeleteFirstParty,
  onDeleteThirdParty,
  isFnol = false,
}) => {
  const classes = useStyles();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { policy } = usePolicy();

  const containerStyle = {
    border: '1px solid #D0D0D0',
    marginBottom: '24px',
    marginTop: '25px',
  };

  const headerStyle = {
    padding: '24px 12px 14px 24px',
  };

  const incidentConfigParties = incidentConfiguration?.involved_parties || {};

  const isInvolvedParty = (party, isFirstParty) => party?.active && party?.is_first_party === isFirstParty;

  const incidentConfigFirstPartyKey = findKey(incidentConfigParties, (party) => isInvolvedParty(party, true));
  const incidentConfigThirdPartyKeys = Object.keys(incidentConfigParties).filter((key) =>
    isInvolvedParty(incidentConfigParties[key], false)
  );

  const isMultipleFirstPartiesEnabled =
    incidentConfigParties[incidentConfigFirstPartyKey]?.is_multiple_first_parties || false;

  return (
    <>
      {incidentConfigFirstPartyKey && (
        <CustomFieldsContextProvider
          customFieldsConfigurations={get(
            incidentConfigParties,
            `${incidentConfigFirstPartyKey}.configured_fields`,
            []
          )}
        >
          <CardDialog title="First Parties" headerStyle={headerStyle} containerStyle={containerStyle}>
            <Grid item xs={12}>
              {firstPartyValues.map((firstPartyInvolved, curIdx) => (
                <div className={styles.cardSpace} key={firstPartyInvolved.id}>
                  <InvolvedGenericParty
                    title={`${incidentConfigParties[incidentConfigFirstPartyKey].desc} (${curIdx + 1})`}
                    propertyLabel={incidentConfigParties[incidentConfigFirstPartyKey].desc}
                    involvedGeneric={firstPartyInvolved}
                    onFetchInvolvedDetails={onFetchInvolvedDetails}
                    onSaveInvolvedGenericParty={async (firstParty) =>
                      await onSaveParty(firstParty, incidentConfigFirstPartyKey)
                    }
                    onDeleteInvolvedGenericParty={
                      onDeleteFirstParty &&
                      firstPartyValues.length > 1 &&
                      (async () => await onDeleteFirstParty(firstPartyInvolved))
                    }
                    InvolvedGenericFragmentOverride={
                      <InvolvedGenericFragment involvedParty={incidentConfigFirstPartyKey} />
                    }
                    involvedGenericValidationFields={getInvolvedGenericValidationFields(
                      incidentConfiguration,
                      incidentConfigFirstPartyKey,
                      policy
                    )}
                    errorHelper={
                      isFnol && <ErrorHelperTextFormik id={`${FIRST_PARTY_VALUES}.${curIdx}.involved_details_filled`} />
                    }
                  />
                </div>
              ))}

              {isEmpty(firstPartyValues) && <ErrorHelperTextFormik id={FIRST_PARTY_VALUES} />}

              {isMultipleFirstPartiesEnabled && (
                <div className={classes.buttonsContainer}>
                  <Button onClick={() => onAddParty(incidentConfigFirstPartyKey)}>
                    <AddIcon />
                    Add {incidentConfigParties[incidentConfigFirstPartyKey].desc}{' '}
                  </Button>
                </div>
              )}
            </Grid>
          </CardDialog>
        </CustomFieldsContextProvider>
      )}

      {incidentConfigThirdPartyKeys.length > 0 && (
        <CardDialog title="Third Parties" headerStyle={headerStyle} containerStyle={containerStyle}>
          <Grid item xs={12}>
            {incidentConfigThirdPartyKeys.map((incidentConfigThirdPartyKey) => (
              <CustomFieldsContextProvider
                key={incidentConfigThirdPartyKey}
                customFieldsConfigurations={get(
                  incidentConfigParties,
                  `${incidentConfigThirdPartyKey}.configured_fields`,
                  []
                )}
              >
                {thirdPartyValues
                  .filter(
                    (thirdPartyValue) => thirdPartyValue.general_claim_involved_type === incidentConfigThirdPartyKey
                  )
                  .map((thirdPartyInvolved, curIdx) => (
                    <div className={styles.cardSpace} key={thirdPartyInvolved.id}>
                      <InvolvedGenericParty
                        title={`${incidentConfigParties[incidentConfigThirdPartyKey].desc} (${curIdx + 1})`}
                        propertyLabel={incidentConfigParties[incidentConfigThirdPartyKey].desc}
                        involvedGeneric={thirdPartyInvolved}
                        onFetchInvolvedDetails={onFetchInvolvedDetails}
                        onSaveInvolvedGenericParty={async (thirdParty) =>
                          await onSaveParty(thirdParty, incidentConfigThirdPartyKey)
                        }
                        onDeleteInvolvedGenericParty={
                          onDeleteThirdParty && (async () => await onDeleteThirdParty(thirdPartyInvolved))
                        }
                        InvolvedGenericFragmentOverride={
                          <InvolvedGenericFragment involvedParty={incidentConfigThirdPartyKey} />
                        }
                        involvedGenericValidationFields={getInvolvedGenericValidationFields(
                          incidentConfiguration,
                          incidentConfigThirdPartyKey,
                          policy
                        )}
                      />
                    </div>
                  ))}
                <div className={classes.buttonsContainer}>
                  <Button onClick={() => onAddParty(incidentConfigThirdPartyKey)}>
                    <AddIcon />
                    Add {incidentConfigParties[incidentConfigThirdPartyKey].desc}{' '}
                  </Button>
                </div>
              </CustomFieldsContextProvider>
            ))}
          </Grid>
        </CardDialog>
      )}
    </>
  );
};

GeneralInvolvedPartiesCard.propTypes = {
  firstPartyValues: PropTypes.array.isRequired,
  thirdPartyValues: PropTypes.array.isRequired,
  onFetchInvolvedDetails: PropTypes.func.isRequired,
  onAddParty: PropTypes.func.isRequired,
  onSaveParty: PropTypes.func.isRequired,
  onDeleteFirstParty: PropTypes.func,
  onDeleteThirdParty: PropTypes.func,
  isFnol: PropTypes.bool,
};

export default GeneralInvolvedPartiesCard;
