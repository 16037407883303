import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { FieldArray, Formik, getIn, useFormikContext } from 'formik';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import useCip from '~/components/hooks/useCip';
import useItrak from '~/components/hooks/useItrak';

import { isoDateToUs } from '../../DateTimeUtils';
import {
  CONFIGURATION_FEATURES_NAMES,
  LIGHT_DUTY_STATUSES_DICT,
  MGM_LABEL_DICT,
  MGM_WC_BODY_PART_CODE_DICT,
  MGM_WC_CAUSE_CODE_DICT,
  MGM_WC_INJURY_CODE_DICT,
} from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import AutocompleteFormik from '../AutocompleteFormik';
import CardDialog from '../CardDialog';
import { WCClaimFinanceSummaryTable } from '../ClaimFinanceSummaryTable';
import { SiuClaimSummaryChip } from '../ClaimSummary';
import WithConfirm from '../ConfirmModal';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { ExternalCarrierReferralContainer } from '../ExternalCarrierReferralContainer';
import { useCms } from '../hooks/useCms';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import useOrganization from '../OrganizationContext';
import {
  DatePickerTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

// Maybe merge with glIncidentDetailsFields?
const wcIncidentDetailsFields = {
  date_of_loss: '',
  time_of_loss: '',
  property_id: '',
  location_id: '',
  cause_code: '',
  c4_filled_date: '',
  injury_codes: [],
  description: '',
  reporter_contact_id: '',
  reported_date: '',
  primary_contact_id: '',
  itrak_number: '',
  cip_number: '',
  body_part_codes: [],
};

// Maybe merge with glIncidentDetailsValidationFields?
const wcIncidentDetailsValidationFields = {
  date_of_loss: Yup.date().required('Required'),
  time_of_loss: Yup.string()
    .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
    .required('Required'),
  property_id: Yup.number().required('Required'),
  location_id: Yup.number().required('Required'),
  cause_code: Yup.string().required('Required').oneOf(Object.keys(MGM_WC_CAUSE_CODE_DICT)),
  c4_filled_date: Yup.date(),
  description: Yup.string().required('Required'),
  reporter_contact_id: Yup.number(),
  reported_date: Yup.date().required('Required'),
  primary_contact_id: Yup.number(),
  itrak_number: Yup.string(),
  cip_number: Yup.string(),
  injury_codes: Yup.array().of(
    Yup.object().shape({
      code: Yup.string().oneOf(Object.keys(MGM_WC_INJURY_CODE_DICT)),
    })
  ),
  body_part_codes: Yup.array().of(
    Yup.object().shape({
      code: Yup.string().oneOf(Object.keys(MGM_WC_BODY_PART_CODE_DICT)),
    })
  ),
};

const getInjuryCodeLabel = (option) => `${option} - ${_.capitalize(MGM_WC_INJURY_CODE_DICT[option]?.desc || '')}`;
const injuryCodesKeysSorted = Object.keys(MGM_WC_INJURY_CODE_DICT).sort((option1, option2) =>
  getInjuryCodeLabel(option1).localeCompare(getInjuryCodeLabel(option2))
);

const getBodyPartsCodeLabel = (option) => `${option} - ${_.capitalize(MGM_WC_BODY_PART_CODE_DICT[option]?.desc || '')}`;
const bodyPartsCodesKeysSorted = Object.keys(MGM_WC_BODY_PART_CODE_DICT).sort((option1, option2) =>
  getBodyPartsCodeLabel(option1).localeCompare(getBodyPartsCodeLabel(option2))
);

function WCIncidentDetailsCardFormik({ ItrakComponent, CipComponent }) {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const classes = useStyles();
  const disabled = isSubmitting;
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  const locationCodeDictForProperty =
    values['property_id'] === '' ? {} : insuredPropertiesAndLocationsDict[values['property_id']].sublocations;
  const locationCodeKeysForProperty = Object.keys(locationCodeDictForProperty);

  const INJURY_CODES_FIELD_NAME = 'injury_codes';
  const BODY_PART_CODES_FIELD_NAME = 'body_part_codes';

  // TODO: remove the filtering once all abbreviations will be valid
  const insuredPropertiesOptions = _.sortBy(
    Object.keys(insuredPropertiesAndLocationsDict),
    (k) => insuredPropertiesAndLocationsDict[k].desc
  ).filter((k) => !!insuredPropertiesAndLocationsDict[k].insured_property_extra.property_abbreviation);

  return (
    <>
      <CardDialog title="Incident Details">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="date_of_loss"
              label="Date of Incident"
              className={classes.textField}
              fullWidth
              disableFuture
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="time_of_loss"
              label="Time of Incident"
              fullWidth
              className={classes.textField}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="primary_contact"
              label="Preferred Contact"
              acceptedRoles={[
                'injured_employee',
                'spouse',
                'claimant',
                'family_member',
                'public_adjuster',
                'attorney',
                'agent',
                'other',
              ]}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="reporter_contact"
              label="Reporter"
              acceptedRoles={[
                'injured_employee',
                'agent',
                'claimant',
                'spouse',
                'family_member',
                'public_adjuster',
                'insurer',
                'attorney',
                'other',
              ]}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="reported_date"
              label="Reported On"
              className={classes.textField}
              fullWidth
              disableFuture
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <ItrakComponent />
          </Grid>
          {CipComponent && (
            <Grid item xs={6}>
              <CipComponent />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextFieldFormik
              id="property_id"
              select
              label="Property"
              className={classes.textField}
              disabled={disabled}
              onChange={(e) => {
                setFieldValue('property_id', e.target.value);
                setFieldValue('location_id', '');
              }}
              fullWidth
            >
              {insuredPropertiesOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {insuredPropertiesAndLocationsDict[option].desc}
                  </MenuItem>
                );
              })}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="location_id"
              label="Location code"
              disabled={disabled}
              className={classes.textField}
              fullWidth
              options={locationCodeKeysForProperty.filter(
                (k) =>
                  !locationCodeDictForProperty[k].claim_type || locationCodeDictForProperty[k].claim_type === 'wc_claim'
              )}
              getOptionLabel={(option) => locationCodeDictForProperty[option].desc}
              sortAlphabetic
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="cause_code"
              label="Cause code"
              options={Object.keys(MGM_WC_CAUSE_CODE_DICT)}
              getOptionLabel={(option) => MGM_WC_CAUSE_CODE_DICT[option].desc}
              sortAlphabetic
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="c4_filled_date"
              label="C4 Filled Date"
              className={classes.textField}
              fullWidth
              disableFuture
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CodesListFormContainer
              fieldName={INJURY_CODES_FIELD_NAME}
              values={values}
              codesOptions={injuryCodesKeysSorted}
              getCodeLabel={getInjuryCodeLabel}
              listItemName="Injury Code"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CodesListFormContainer
              fieldName={BODY_PART_CODES_FIELD_NAME}
              values={values}
              codesOptions={bodyPartsCodesKeysSorted}
              getCodeLabel={getBodyPartsCodeLabel}
              listItemName="Body Part Code"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="description"
              label="Incident description"
              className={classes.textField}
              fullWidth
              multiline
              rows="8"
              rowsMax="14"
            />
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
}

WCIncidentDetailsCardFormik.propTypes = {
  ItrakComponent: PropTypes.func.isRequired,
  CipComponent: PropTypes.func.isRequired,
};

function WCIncidentDetailsCard(props) {
  const classes = useStyles();
  const { insuredPropertiesAndLocationsDict } = useOrganization();
  const { claim, onUpdate, readOnly } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [showPropertyLocationUpdateDialog, setShowPropertyLocationUpdateDialog] = useState(false);

  const { incident, injury_codes, body_part_codes } = claim;
  const disabled = readOnly || isFetching;

  const shouldShowEsisReferral =
    insuredPropertiesAndLocationsDict[incident.insured_property_id].insured_property_extra.is_esis_referral;
  const shouldShowCssReferral =
    insuredPropertiesAndLocationsDict[incident.insured_property_id].insured_property_extra.is_css_referral;

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const handleUpdateFields = async (updateObject) => {
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/wc_claims/${claim.id}`, updateObject);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsFetching(false);
  };

  const { userOrganization } = useCms();
  const isLightDutyTrackEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ENABLE_LIGHT_DUTY_TRACK
  );
  const current_light_duty_status = claim.employee_party.involved_person.current_light_duty_status;
  const { isCipAutofillEnabled } = useCip({ claimType: useCip.CLAIM_TYPE.wc_claim });
  const { isItrakAutofillEnabled } = useItrak({ claimType: useItrak.CLAIM_TYPE.wc_claim });

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && (
            <IncidentMoreActionsContainer
              incident={incident}
              onUpdateFields={handleUpdateIncidentFields}
              includeIsMultiLoss
            />
          )
        }
      >
        {incident.is_multi_loss && (
          <Chip
            size="small"
            color="primary"
            label={`Multi Loss Incident ${incident.multi_loss_name}`}
            className={classes.chip}
          />
        )}
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        {claim.compensation_type && (
          <Chip size="small" color="primary" label={claim.compensation_type} className={classes.chip} />
        )}
        {claim.employee_party.involved_person.attorney_contact_id && (
          <Chip size="small" color="primary" label="Atty Rep" className={classes.chip} />
        )}
        {incident.labeled_criteria && (
          <Chip
            size="small"
            color="primary"
            label={MGM_LABEL_DICT[claim.type][incident.labeled_criteria]?.desc}
            className={classes.chip}
          />
        )}
        {isLightDutyTrackEnabled && current_light_duty_status && (
          <Chip
            size="small"
            color="primary"
            label={LIGHT_DUTY_STATUSES_DICT[current_light_duty_status]}
            className={classes.chip}
          />
        )}
        <IncidentMutualChips claim={claim} />
        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.employee_party.involved_person.contact_id}
              contactDisplayName={claim.employee_party.involved_person.contact_full_name}
              label="Injured Employee"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="reported_date"
              value={claim.reported_date}
              label="Reported On"
              specialFieldType="date"
              specialFieldAdditionalProps={{ disableFuture: true }}
              validationSchema={Yup.date().required('Required')}
              onUpdate={async (updatedValues) =>
                await handleUpdateClaimField('reported_date', updatedValues['reported_date'])
              }
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={isoDateToUs(claim.reported_date)}
                label="Reported On"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="c4_filled_date"
              value={claim.incident.c4_filled_date}
              label="C4 Filled Date"
              specialFieldType="date"
              specialFieldAdditionalProps={{ disableFuture: true }}
              validationSchema={Yup.date().required('Required')}
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('c4_filled_date', updatedValues['c4_filled_date'])
              }
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.c4_filled_date && isoDateToUs(claim.incident.c4_filled_date)}
                label="C4 Filled Date"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="itrak_number"
              value={claim.incident.itrak_number || ''}
              label="ITrak number"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('itrak_number', updatedValues['itrak_number'])
              }
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.itrak_number}
                label="ITrak number"
              />
            </HoverChangeField>
          </Grid>
          {isCipAutofillEnabled && !isItrakAutofillEnabled && (
            <Grid item xs={6}>
              <HoverChangeField
                name="cip_number"
                value={claim.incident.cip_number || ''}
                label="CIP number"
                onUpdate={async (updatedValues) =>
                  await handleUpdateIncidentField('cip_number', updatedValues['cip_number'])
                }
                disabled={disabled}
              >
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={claim.incident.cip_number}
                  label="CIP number"
                />
              </HoverChangeField>
            </Grid>
          )}
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={insuredPropertiesAndLocationsDict[claim.incident.insured_property_id]?.desc}
              label="Property"
              onEdit={disabled ? undefined : () => setShowPropertyLocationUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                insuredPropertiesAndLocationsDict[claim.incident.insured_property_id]?.sublocations[
                  claim.incident.insured_property_sublocation_id
                ]?.desc
              }
              label="Location code"
              onEdit={disabled ? undefined : () => setShowPropertyLocationUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectHoverChangeField
              label="Cause code"
              fieldId="cause_code"
              value={claim.incident.cause_code}
              keys={Object.keys(MGM_WC_CAUSE_CODE_DICT)}
              displayValueFunc={(key) => MGM_WC_CAUSE_CODE_DICT[key]['desc']}
              disabled={disabled}
              onUpdate={async (values) => await handleUpdateIncidentField('cause_code', values.cause_code)}
              width="300px"
              overrideOnEdit
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={MGM_WC_CAUSE_CODE_DICT[claim.incident.cause_code]?.desc}
                label="Cause code"
              />
            </SelectHoverChangeField>
          </Grid>
          <Grid item xs={12}>
            <CodesListDialog
              disabled={disabled}
              claimId={claim.id}
              originalList={injury_codes}
              onUpdate={onUpdate}
              setIsFetching={setIsFetching}
              listItemName="Injury Code"
              routeName="injury_codes"
              sortedKeys={injuryCodesKeysSorted}
              getCodeLabel={getInjuryCodeLabel}
            />
          </Grid>
          <Grid item xs={12}>
            <CodesListDialog
              disabled={disabled}
              claimId={claim.id}
              originalList={body_part_codes}
              onUpdate={onUpdate}
              setIsFetching={setIsFetching}
              listItemName="Body Part Code"
              routeName="body_part_codes"
              sortedKeys={bodyPartsCodesKeysSorted}
              getCodeLabel={getBodyPartsCodeLabel}
            />
          </Grid>
          {(shouldShowEsisReferral || shouldShowCssReferral) && (
            <Grid item xs={12}>
              <ExternalCarrierReferralContainer
                disabled={disabled}
                onUpdate={onUpdate}
                track={`WC ${shouldShowEsisReferral ? 'Esis' : 'CSS'} referral email`}
                title={`${shouldShowEsisReferral ? 'Esis' : 'CSS'} Referral`}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <WCClaimFinanceSummaryTable />
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={8}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="12em" // ~ 8 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>
      {showPropertyLocationUpdateDialog && (
        <PropertyAndLocationUpdateDialog
          property_id={claim.incident.insured_property_id}
          location_id={claim.incident.insured_property_sublocation_id}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowPropertyLocationUpdateDialog(false)}
        />
      )}
    </>
  );
}

WCIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

function PropertyAndLocationUpdateDialog({ property_id, location_id, onUpdate, onClose }) {
  const classes = useStyles();
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  // TODO: remove the filtering once all abbreviations will be valid
  const insuredPropertiesOptions = _.sortBy(
    Object.keys(insuredPropertiesAndLocationsDict),
    (k) => insuredPropertiesAndLocationsDict[k].desc
  ).filter((k) => !!insuredPropertiesAndLocationsDict[k].insured_property_extra.property_abbreviation);

  return (
    <Formik
      initialValues={{ property_id, location_id }}
      validationSchema={Yup.object().shape({
        property_id: Yup.string().required('Required'),
        location_id: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        if (values.property_id === property_id && values.location_id === location_id) {
          await onClose();
          return;
        }

        await onUpdate(values);
        await onClose();
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const propertyName = values['property_id'];
        const locationCodeDictForProperty =
          propertyName === '' ? {} : insuredPropertiesAndLocationsDict[propertyName].sublocations;
        const locationCodeKeysForProperty = Object.keys(locationCodeDictForProperty);

        return (
          <CardDialog
            isDialog={true}
            open
            title="Edit Property & Location"
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="property_id"
                  select
                  label="Property"
                  className={classes.textField}
                  disabled={isSubmitting}
                  onChange={(e) => {
                    setFieldValue('property_id', e.target.value);
                    setFieldValue('location_id', '');
                  }}
                  fullWidth
                >
                  {insuredPropertiesOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {insuredPropertiesAndLocationsDict[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={6}>
                <AutocompleteFormik
                  id="location_id"
                  label="Location code"
                  disabled={isSubmitting}
                  className={classes.textField}
                  fullWidth
                  options={locationCodeKeysForProperty.filter(
                    (k) =>
                      !locationCodeDictForProperty[k].claim_type ||
                      locationCodeDictForProperty[k].claim_type === 'wc_claim'
                  )}
                  getOptionLabel={(option) => locationCodeDictForProperty[option].desc}
                  sortAlphabetic
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

PropertyAndLocationUpdateDialog.propTypes = {
  open: PropTypes.bool,
  property_id: PropTypes.string.isRequired,
  location_id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function CodesListDialog({
  disabled,
  claimId,
  originalList,
  onUpdate,
  setIsFetching,
  listItemName,
  routeName,
  sortedKeys,
  getCodeLabel,
}) {
  const [showCodesDialog, setShowCodesDialog] = useState(false);
  const [showAddNewCodeDialog, setShowAddNewCodeDialog] = useState(false);
  const [codeToAdd, setCodeToAdd] = useState(undefined);
  const classes = useStyles();

  const handleAddCode = async (code) => {
    setIsFetching(true);
    try {
      await axios.post(`/api/v1/wc_claims/${claimId}/${routeName}`, { code });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }

    setShowAddNewCodeDialog(false);
    setCodeToAdd(undefined);
    setIsFetching(false);
  };

  const handleCodeDelete = async (codeId) => {
    setIsFetching(true);
    try {
      await axios.delete(`/api/v1/wc_claims/${claimId}/${routeName}/${codeId}`);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }

    setIsFetching(false);
  };

  return (
    <>
      <ShowOnlyTextField
        classes={classes}
        label={`${listItemName}s`}
        onEdit={() => setShowCodesDialog(true)}
        doNotRenderInTypography
        showOnlyValueComponent={
          !originalList.length ? (
            <span className={classes.warningColor}>
              <strong>{`No ${listItemName}s`}</strong>
            </span>
          ) : (
            `${originalList.map(({ code }) => getCodeLabel(code)).join(', ')} `
          )
        }
      />

      {showAddNewCodeDialog && (
        <CardDialog
          title={`Add ${listItemName}`}
          isDialog
          maxWidth="sm"
          fullWidth
          onClose={() => {
            setShowAddNewCodeDialog(false);
            setCodeToAdd(undefined);
          }}
        >
          <Autocomplete
            id="code"
            options={sortedKeys}
            getOptionLabel={getCodeLabel}
            filterOptions={(_, value) =>
              sortedKeys.filter((option) => getCodeLabel(option).toLowerCase().includes(value.inputValue.toLowerCase()))
            }
            onChange={(e, newValue) => setCodeToAdd(newValue)}
            value={codeToAdd || null}
            renderInput={(params) => (
              <TextField
                label={listItemName}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  margin: 'dense', // to match other TextField components
                }}
              />
            )}
            clearOnBlur={false}
          />
          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddCode(codeToAdd)}
              disabled={disabled || !codeToAdd}
            >
              Add
            </Button>
          </div>
        </CardDialog>
      )}

      {showCodesDialog && (
        <CardDialog
          title={`${listItemName}s`}
          isDialog
          maxWidth="sm"
          fullWidth
          onClose={() => setShowCodesDialog(false)}
          action={
            <Button color="primary" onClick={() => setShowAddNewCodeDialog(true)}>
              <AddIcon />
              {`Add ${listItemName}`}
            </Button>
          }
        >
          <>
            <Grid container spacing={1}>
              {originalList.map(({ id, code }) => (
                <React.Fragment key={id}>
                  <Grid item xs={10}>
                    <Typography variant="body1" display="inline">
                      {getCodeLabel(code)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <WithConfirm
                      title={`Delete ${listItemName}?`}
                      primaryButtonName="Delete"
                      contentText="Are you sure you want to delete this code?"
                      shouldCloseOnPrimary
                    >
                      <IconButton style={{ padding: '4px' }} onClick={() => handleCodeDelete(id)} disabled={disabled}>
                        <DeleteIcon />
                      </IconButton>
                    </WithConfirm>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </>
        </CardDialog>
      )}
    </>
  );
}

CodesListDialog.propTypes = {
  disabled: PropTypes.bool.isRequired,
  claimId: PropTypes.number.isRequired,
  originalList: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setIsFetching: PropTypes.func.isRequired,
  listItemName: PropTypes.string.isRequired,
  routeName: PropTypes.string.isRequired,
  sortedKeys: PropTypes.array.isRequired,
  getCodeLabel: PropTypes.func.isRequired,
};

function CodesListFormContainer({ fieldName, values, codesOptions, getCodeLabel, listItemName, disabled }) {
  const [showDialog, setShowDialog] = useState(false);
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();

  return (
    <>
      <ShowOnlyTextField
        classes={classes}
        showOnlyValueComponent={
          values[fieldName].length === 0 ? (
            <Button color="primary" disabled={disabled} onClick={() => setShowDialog(true)}>
              <AddIcon />
              {`Add ${listItemName}`}
            </Button>
          ) : (
            values[fieldName].map(({ code }) => getCodeLabel(code)).join(', ')
          )
        }
        label={`${listItemName}s`}
        onEdit={values[fieldName].length === 0 ? undefined : () => setShowDialog(true)}
      />
      {showDialog && (
        <EditCodesListDialog
          existingCodes={values[fieldName]}
          codesOptions={codesOptions}
          onCancel={() => setShowDialog(false)}
          onUpdate={({ selectedCodes }) => {
            setFieldValue(fieldName, selectedCodes);
            setShowDialog(false);
          }}
          disabled={disabled}
          getCodeLabel={getCodeLabel}
          listItemName={listItemName}
        />
      )}
    </>
  );
}

CodesListFormContainer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  listItemName: PropTypes.string.isRequired,
  codesOptions: PropTypes.array.isRequired,
  getCodeLabel: PropTypes.func.isRequired,
};

function EditCodesListDialog({
  existingCodes,
  codesOptions,
  onUpdate,
  onCancel,
  disabled,
  getCodeLabel,
  listItemName,
}) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ selectedCodes: existingCodes }}
      validationSchema={Yup.object().shape({
        selectedCodes: Yup.array().of(
          Yup.object().shape({
            code: Yup.string().required('Required').oneOf(codesOptions),
          })
        ),
      })}
      onSubmit={onUpdate}
    >
      {(formikProps) => {
        const { isSubmitting, values, handleSubmit } = formikProps;

        return (
          <CardDialog title={`${listItemName}s`} isDialog maxWidth="sm" onClose={onCancel} fullWidth>
            <>
              <FieldArray
                name="selectedCodes"
                render={({ remove, push }) => (
                  <>
                    {getIn(values, 'selectedCodes').map((item, idx) => {
                      return (
                        <Grid container key={item.id}>
                          <Grid item xs={10}>
                            <AutocompleteFormik
                              label=""
                              id={`selectedCodes.${idx}.code`}
                              options={codesOptions}
                              getOptionLabel={getCodeLabel}
                              sortAlphabetic
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => remove(idx)}>
                              <CancelIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <div className={classes.buttonsContainer}>
                      <Button
                        color="primary"
                        disabled={disabled}
                        onClick={() => push({ code: '', id: `new_${uuidv4()}` })}
                      >
                        <AddIcon />
                        Add
                      </Button>
                    </div>
                  </>
                )}
              />
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={disabled} onClick={onCancel} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditCodesListDialog.propTypes = {
  existingCodes: PropTypes.array.isRequired,
  codesOptions: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  getCodeLabel: PropTypes.func.isRequired,
  listItemName: PropTypes.string.isRequired,
};

export {
  WCIncidentDetailsCard,
  WCIncidentDetailsCardFormik,
  wcIncidentDetailsFields,
  wcIncidentDetailsValidationFields,
};
