import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as MuiIconButton, Typography } from '@material-ui/core';
import noop from 'lodash';

import styles from './buttons.module.scss';

const SidebarActionCreateButton = ({ onClick = noop, disabled = false, title, icon }) => {
  const Icon = icon;
  return (
    <div className={styles.sidebarActionCreateButton}>
      <div className={styles.container}>
        <MuiIconButton classes={{ root: styles.icon }} onClick={onClick} disabled={disabled}>
          <Icon size={18} iconColor="white" />
        </MuiIconButton>
      </div>
      {title && (
        <Typography className={styles.title} variant="subtitle2">
          {title}
        </Typography>
      )}
    </div>
  );
};

SidebarActionCreateButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default SidebarActionCreateButton;
