import React from 'react';
import AddIcon from '@material-ui/icons/Add';

import { FsButton } from '~/components/core';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import { useReportsPermissions } from '~/components/ReportsPage/Hooks/useReportsPermissions';
import { useReportsActions } from '~/components/ReportsPage/ReportsActions/useReportsActions';
import { MyDraftsTab } from '~/components/ReportsPage/Tabs/MyDraftsTab';
import { ReportsDashboardsTab } from '~/components/ReportsPage/Tabs/ReportsDashboardsTab';

const AddNewReportBtn = () => {
  const { onActionHandler } = useReportsActions();
  return (
    <FsButton
      className="absolute right-0 top-1/2 z-10 mr-10 -translate-y-1/2 transform"
      color="primary"
      disabled={false}
      onClick={() => onActionHandler({ action: 'create', params: {} })}
    >
      <AddIcon />
      ADD NEW REPORT
    </FsButton>
  );
};

export const ReportsTabs: React.FC = () => {
  const { hasCreatorPermission } = useReportsPermissions();

  const tabs = [
    {
      label: 'Reports & Dashboards',
      url: 'reports-dashboards',
      component: ReportsDashboardsTab,
      props: {},
      className: 'whitespace-nowrap',
    },
    {
      label: 'My Drafts',
      url: 'my-drafts',
      component: MyDraftsTab,
      props: {},
      disableTab: !hasCreatorPermission,
    },
  ];

  return (
    <section className="bg-white">
      <InnerTabs tabs={tabs} actionsComponent={hasCreatorPermission ? AddNewReportBtn : undefined} />
    </section>
  );
};
