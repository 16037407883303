import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import MgmFnolSearchResults from '../ClientSpecific/Mgm/MgmFnolSearchResults';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function GLFnolSearchScreen({ onReportNewFnol }) {
  const classes = useStyles();
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [foundClaims, setFoundClaims] = useState([]);
  const [searchValues, setSearchValues] = useState(undefined);

  const handleSearch = async (values) => {
    setSearchValues(values);
    try {
      setIsLoading(true);
      const res = await axios.get('/api/v1/gl_claims', { params: values });
      setFoundClaims(res.data.claims);
      setShowResults(true);
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={classes.cardDiv}>
        <GlClaimsSearch onSubmit={async (values) => await handleSearch(values)} />
      </div>
      {isLoading && <LoadingIndicator />}
      {showResults && (
        <div className={classes.cardDivRow}>
          <MgmFnolSearchResults
            claimType="gl_claim"
            foundClaims={foundClaims}
            onReportNewFnol={async () => await onReportNewFnol(searchValues)}
          />
        </div>
      )}
    </>
  );
}

GLFnolSearchScreen.propTypes = {
  onReportNewFnol: PropTypes.func.isRequired,
};

function GlClaimsSearch({ onSubmit }) {
  const classes = useStyles();
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  // TODO: remove the filtering once all abbreviations will be valid
  const insuredPropertiesOptions = _.sortBy(
    Object.keys(insuredPropertiesAndLocationsDict),
    (k) => insuredPropertiesAndLocationsDict[k].desc
  ).filter((k) => !!insuredPropertiesAndLocationsDict[k].insured_property_extra.property_abbreviation);

  return (
    <Formik
      initialValues={{
        claimant_name: '',
        incident_date: '',
        property_id: '',
      }}
      validationSchema={Yup.object().shape({
        claimant_name: Yup.string(),
        property_id: Yup.number(),
        incident_date: Yup.date().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={10} lg={8}>
            <CardDialog noCardTitle>
              <div style={{ height: '70px' }}>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <DatePickerTextFieldFormik
                      id="incident_date"
                      label="Incident Date"
                      className={classes.textField}
                      disableFuture
                      fullWidth
                      disabled={isSubmitting}
                      clearable
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="property_id"
                      label="Property Name"
                      className={classes.textField}
                      select
                      fullWidth
                      disabled={isSubmitting}
                    >
                      {insuredPropertiesOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {insuredPropertiesAndLocationsDict[option].desc}
                          </MenuItem>
                        );
                      })}
                    </TextFieldFormik>
                  </Grid>

                  <Grid item xs={4}>
                    <TextFieldFormik
                      id="claimant_name"
                      label="Claimant Name"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Button onClick={handleSubmit} variant="contained" color="primary">
                        Search
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </CardDialog>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

GlClaimsSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default GLFnolSearchScreen;
