import React, { useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { every, set } from 'lodash';
import * as Yup from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import { localeDetails } from '~/components/CmsMain/globals';
import type { ContactFullModel } from '~/components/Contacts/types';
import { FORMIK_W9_FIELD_IDS, W9_TAB_KEY } from '~/components/Contacts/UpsertContact/Tabs/W9/constants';
import Form1099 from '~/components/Contacts/UpsertContact/Tabs/W9/Form1099';
import { getFullW9Path } from '~/components/Contacts/UpsertContact/Tabs/W9/utils';
import type { Tab } from '~/components/Contacts/UpsertContact/types';
import UploadDocument from '~/components/Contacts/UpsertContact/UploadDocument';
import { DOCUMENT_FIELD_IDS } from '~/components/Contacts/UpsertContact/UploadDocument/constants';
import {
  getDocumentInitialValues,
  getDocumentPayload,
  getDocumentValidationSchema,
} from '~/components/Contacts/UpsertContact/UploadDocument/utils';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import AlertBanner from '~/components/core/AlertBanner';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core/Permissions/PermissionUtils';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import useOrganization from '~/components/OrganizationContext';
import { DatePickerTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';
import { getLocalDateToday } from '~/DateTimeUtils';

import { getInitializedValues } from '../../utils';
import TabWrapper from '../TabWrapper';

const W9Tab: React.FC = () => {
  const { isSubmitting, values } = useFormikContext();
  const { documentUploadPercent } = useW9Tab();
  const hasPermission = useHasPermission({ action: PERMISSION_ACTIONS.W9_TIN, verb: PERMISSION_VERBS.WRITE });

  const sharedProps = {
    disabled: isSubmitting,
    showOnly: !hasPermission,
  };

  return (
    <TabWrapper>
      <div className="mb-30 grid grid-cols-2 gap-20">
        <UploadDocument
          {...sharedProps}
          documentFieldKey={FORMIK_W9_FIELD_IDS.W9_DOCUMENT_ID}
          documentType="w9_form"
          documentFieldLabel="W9 Form"
          alertBannerNote="TIN number can be updated independently. To upload W9, you must update the TIN number"
          alertBannerType={AlertBanner.ALERT_TYPES.INFO}
          getFullPath={getFullW9Path}
          documentUploadPercent={documentUploadPercent}
        />
        <TextFieldFormik id={getFullW9Path(FORMIK_W9_FIELD_IDS.TIN)} label="TIN" fullWidth {...sharedProps} />
        <DatePickerTextFieldFormik
          id={getFullW9Path(FORMIK_W9_FIELD_IDS.W9_DATE)}
          label="Date Received"
          disableFuture
          {...sharedProps}
          clearable
          fullWidth
          disabled={
            sharedProps.disabled ||
            !(
              getIn(values, getFullW9Path(FORMIK_W9_FIELD_IDS.W9_DOCUMENT_ID)) ||
              getIn(values, getFullW9Path(DOCUMENT_FIELD_IDS.FILE))
            )
          }
        />
        <Form1099 {...sharedProps} />
      </div>
    </TabWrapper>
  );
};

const useW9Tab = (): Tab => {
  // No Formik Context
  const [documentUploadPercent, setDocumentUploadPercent] = useState(0);
  const { currentRole, currentCountry, originalContact, isCreation } = useContact();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  const { claim } = useClaim();
  const hasW9Permission = useHasPermission({ action: PERMISSION_ACTIONS.W9_TIN, verb: PERMISSION_VERBS.WRITE });

  const getInitialValues = (contact?: ContactFullModel) => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    const documentInitialValues = getDocumentInitialValues(FORMIK_W9_FIELD_IDS.W9_DOCUMENT_ID, claim);
    every(Object.keys(documentInitialValues), (formikFieldId) =>
      setInitialValue(formikFieldId, documentInitialValues[formikFieldId])
    );

    setInitialValue(FORMIK_W9_FIELD_IDS.W9_DATE, getLocalDateToday());
    setInitialValue(FORMIK_W9_FIELD_IDS.TIN, contact?.tin);
    setInitialValue(FORMIK_W9_FIELD_IDS.IS_1099_REPORTABLE, contact?.is_1099_reportable);
    setInitialValue(FORMIK_W9_FIELD_IDS.REPORT_TYPE_1099, contact?.report_type_1099);

    return values;
  };

  const getValuesToCompare = (values: FormikValues) => {
    return getInitializedValues(values);
  };

  const fieldsPermissionActions = {
    [getFullW9Path(FORMIK_W9_FIELD_IDS.TIN)]: PERMISSION_ACTIONS.W9_TIN,
  };

  const doesRoleRequireTin = () => {
    const inUS =
      (localeDetails as { locale?: Intl.Locale; currency?: string } | null)?.locale?.region === 'US' ||
      currentCountry === 'US';

    return !isCreation && !!organizationContactRolesDict[currentRole]?.is_tin_required && inUS && hasW9Permission;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation?: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    const documentValidationSchema = getDocumentValidationSchema(FORMIK_W9_FIELD_IDS.W9_DOCUMENT_ID, true, claim);
    every(Object.keys(documentValidationSchema), (formikFieldId) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      addValidation(formikFieldId, documentValidationSchema[formikFieldId])
    );

    addValidation(FORMIK_W9_FIELD_IDS.W9_DATE, Yup.date());
    addValidation(
      FORMIK_W9_FIELD_IDS.TIN,
      doesRoleRequireTin()
        ? Yup.number().when([FORMIK_W9_FIELD_IDS.W9_DOCUMENT_ID, DOCUMENT_FIELD_IDS.FILE_SIZE], {
            is: (document_id: number, file_size: number) =>
              document_id || file_size || originalContact?.w9_contact_document_id,
            then: Yup.number().typeError('Must be a number').required('Required'),
            otherwise: Yup.number().typeError('Must be a number'),
          })
        : undefined
    );
    addValidation(FORMIK_W9_FIELD_IDS.IS_1099_REPORTABLE, Yup.bool());
    addValidation(
      FORMIK_W9_FIELD_IDS.REPORT_TYPE_1099,
      Yup.string()
        .when(FORMIK_W9_FIELD_IDS.IS_1099_REPORTABLE, {
          is: true,
          then: Yup.string().required('Required'),
        })
        .nullable()
    );

    return validations;
  };

  const getPreparedPayload = async (values: FormikValues) => {
    return await getDocumentPayload(values, originalContact, setDocumentUploadPercent);
  };

  const isHiddenForContact = () => {
    // Hidden Tabs cannot have absolute require fields, only conditionally required fields
    return !doesRoleRequireTin();
  };

  return {
    label: 'W9',
    tabKey: W9_TAB_KEY,
    tabComponent: W9Tab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    isHiddenForContact,
    fieldsPermissionActions,
    documentUploadPercent,
    getPreparedPayload,
  };
};

export default useW9Tab;
