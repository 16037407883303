import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocalMoment } from '../../DateTimeUtils';
import { getPolicyStatusDuringLoss, getPolicyStatusSpan } from '../../PolicyUtils';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { SiuClaimSummaryChip } from '../ClaimSummary';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import useCurrencyFormatter from '../CurrencyFormatterContext';
import { CustomIncidentDetailsFieldEditor, CustomIncidentDetailsFieldForm } from '../Fnol/IncidentDetailsCard';
import InsuredInfoVerificationCard from '../Fnol/InsuredInfoVerificationCard';
import { LossLocationShowOnly, LossLocationTextFieldFormik } from '../GlobalLossLocation';
import HoverChangeField from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import IncidentTypeUpdateDialog from '../IncidentTypeUpdateDialog';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const travelIncidentDetailsFields = {
  incident_type: '',
  incident_sub_type: '',
  date_of_loss: '',
  time_of_loss: '',
  reporter_id: '',
  primary_contact_id: '',
  special_internal_note: '',
  description: '',
  verified_insured_info: false,
};

const travelIncidentDetailsValidationFields = {
  date_of_loss: Yup.date().required('Required'),
  time_of_loss: Yup.string().matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied'),
  reporter_id: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  primary_contact_id: Yup.string().required('Required'),
  verified_insured_info: Yup.boolean().oneOf([true], 'Must verify insured info'),
  claimed_amount: Yup.number(),
  loss_location: Yup.object().nullable(),
};

const CLAIM_TYPE = 'travel_claim';

function TravelIncidentDetailsFormikInner() {
  const spacing = 1;
  const classes = useStyles();
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { incidentTypesDict, incidentDetailsExtraFields } = useOrganization();
  const [showInsuredVerificationCard, setShowInsuredVerificationCard] = useState(false);
  const insuredDetailsVerified = getIn(values, 'verified_insured_info');
  const { policy } = usePolicy();
  const policyStatusDuringLoss = values.date_of_loss
    ? getPolicyStatusDuringLoss(policy, values.date_of_loss, values.time_of_loss)
    : null;

  const incidentType = values['incident_type'];
  const travelIncidentsDict = incidentTypesDict['travel_claim'];
  let incidentsTypeKeys = Object.keys(travelIncidentsDict);
  let incidentSubTypeDictForIncidentType = incidentType === '' ? {} : travelIncidentsDict[incidentType].sub_types;
  let incidentSubTypeKeysForIncidentType = Object.keys(incidentSubTypeDictForIncidentType);

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          insuredDetailsVerified ? (
            <Button onClick={() => setShowInsuredVerificationCard(true)}>Insured info is verified &gt;</Button>
          ) : (
            <>
              <Button
                color="secondary"
                onClick={() => {
                  setShowInsuredVerificationCard(true);
                }}
              >
                <ErrorIcon />
                Verify insured info &gt;
              </Button>
              <ErrorHelperTextFormik id="verified_insured_info" />
            </>
          )
        }
      >
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <TextFieldFormik
              id="incident_type"
              select
              label="Incident type"
              className={classes.textField}
              disabled={isSubmitting}
              fullWidth
            >
              {incidentsTypeKeys.map((option) => (
                <MenuItem key={option} value={option}>
                  {travelIncidentsDict[option].desc}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="incident_sub_type"
              select
              label="Incident sub-type"
              disabled={isSubmitting}
              className={classes.textField}
              fullWidth
            >
              {incidentSubTypeKeysForIncidentType.map((option) => (
                <MenuItem key={option} value={option}>
                  {incidentSubTypeDictForIncidentType[option].desc}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="date_of_loss"
              label="Date of Incident"
              className={classes.textField}
              fullWidth
              disableFuture
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="time_of_loss"
              label="Time of Incident"
              fullWidth
              className={classes.textField}
              disabled={isSubmitting}
            />
          </Grid>

          {getPolicyStatusSpan(policyStatusDuringLoss, policy)}

          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="reporter"
              label="Reporter"
              className={classes.textField}
              acceptedRoles={[
                'insured',
                'agent',
                'claimant',
                'spouse',
                'family_member',
                'public_adjuster',
                'insurer',
                'attorney',
                'other',
              ]}
              contactSearchProps={{ newContactRole: 'claimant' }}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="primary_contact"
              label="Preferred Contact"
              acceptedRoles={['insured', 'spouse', 'family_member', 'public_adjuster', 'attorney', 'agent', 'other']}
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <LossLocationTextFieldFormik id="loss_location" label="Loss Location" />
          </Grid>
          <Grid item xs={6}>
            <MonetaryValueTextFieldFormik id="claimed_amount" label="Claimed Amount" disabled={isSubmitting} />
          </Grid>
          {incidentDetailsExtraFields[CLAIM_TYPE].map((customFieldProps) => (
            <Grid item xs={12} key={customFieldProps.field_name}>
              <CustomIncidentDetailsFieldForm {...customFieldProps} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextFieldFormik
              id="special_internal_note"
              label="Special Internal Note"
              className={classes.textField}
              fullWidth
              multiline
              rows="3"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="description"
              label="Incident description"
              className={classes.textField}
              fullWidth
              multiline
              rows="8"
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </CardDialog>
      {showInsuredVerificationCard && (
        <InsuredInfoVerificationCard
          insuredContactId={values.insured_contact.id}
          onDetailsVerified={() => {
            setFieldValue('verified_insured_info', true);
          }}
          onClose={() => setShowInsuredVerificationCard(false)}
        />
      )}
    </>
  );
}

TravelIncidentDetailsFormikInner.propTypes = {};

function TravelIncidentDetailsCard(props) {
  const classes = useStyles();
  const { incidentDetailsExtraFields } = useOrganization();
  const { claim, onUpdate, readOnly } = props;
  const [isFetching, setIsFetching] = useState(false);
  const { currencyFormatter } = useCurrencyFormatter();
  const [showIncidentTypeUpdateDialog, setShowIncidentTypeUpdateDialog] = useState(false);

  const {
    incident,
    claim_extra: { custom_extra: customExtra },
  } = claim;
  const disabled = readOnly || isFetching;

  const handleCustomExtraField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ custom_extra: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const handleUpdateFields = async (updateObject) => {
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/travel_claims/${claim.id}`, updateObject);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsFetching(false);
  };

  const travelIncidentsDict = claim.incident_types_dict;

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && <IncidentMoreActionsContainer incident={incident} onUpdateFields={handleUpdateIncidentFields} />
        }
      >
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        <IncidentMutualChips claim={claim} />
        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={incident.incident_type ? travelIncidentsDict[incident.incident_type].desc : ''}
              label="Incident type"
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                incident.incident_type && incident.incident_sub_type
                  ? travelIncidentsDict[incident.incident_type].sub_types[incident.incident_sub_type].desc
                  : ''
              }
              label="Incident sub-type"
              disabled={disabled}
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={12}>
            <LossLocationShowOnly
              location={claim.incident.loss_location}
              label="Loss Location"
              disabled={disabled}
              onUpdate={async (newLossLocation) => await handleUpdateIncidentField('loss_location', newLossLocation)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocalMoment(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="claimed_amount"
              value={claim.claimed_amount}
              label="Claimed Amount"
              specialFieldType="monetary"
              onUpdate={async (updatedValues) =>
                await handleUpdateClaimField('claimed_amount', updatedValues['claimed_amount'])
              }
              width="200px"
              showOnly
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={currencyFormatter.format(claim.claimed_amount)}
                label="Claimed Amount"
                width="100px"
              />
            </HoverChangeField>
          </Grid>
          {incidentDetailsExtraFields[CLAIM_TYPE].map((customFieldProps) => {
            return (
              <Grid item xs={12} key={customFieldProps.field_name}>
                <CustomIncidentDetailsFieldEditor
                  {...customFieldProps}
                  onUpdate={handleCustomExtraField}
                  disabled={disabled}
                  currentValues={customExtra || {}}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <HoverChangeField
              name="special_internal_note"
              value={claim.special_internal_note}
              label="Special Internal Note"
              onUpdate={(updatedValues) =>
                handleUpdateClaimField('special_internal_note', updatedValues['special_internal_note'])
              }
              fullWidth
              multiline
              rows={5}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.special_internal_note}
                label="Special Internal Note"
                maxHeight="5.3em" // ~ 3 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12} style={{ height: '250px' }}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={8}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="20em"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>

      {showIncidentTypeUpdateDialog && (
        <IncidentTypeUpdateDialog
          incidentTypesDict={travelIncidentsDict}
          incidentType={claim.incident.incident_type}
          incidentSubType={claim.incident.incident_sub_type}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowIncidentTypeUpdateDialog(false)}
        />
      )}
    </>
  );
}

TravelIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export {
  TravelIncidentDetailsCard,
  travelIncidentDetailsFields,
  TravelIncidentDetailsFormikInner,
  travelIncidentDetailsValidationFields,
};
