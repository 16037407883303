import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import type { IconProps } from '~/components/icons/types';
import cn from '~/Utils/cn';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  icon: React.ComponentType<IconProps>;
  className?: string;
  iconColor?: string;
}
const IconButton: React.FC<Props> = ({ onClick, disabled = false, icon, className, iconColor }) => {
  const classes = useStyles();
  const Icon = icon;

  return (
    <MuiIconButton onClick={onClick} disabled={disabled} className={cn(classes.iconButton, className)}>
      <Icon className={classes.iconSize} iconColor={iconColor} />
    </MuiIconButton>
  );
};

export default IconButton;
