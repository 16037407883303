import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Formik, getIn, useFormikContext } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import cn from '~/Utils/cn';

import AdjusterSelectTextFieldFormik from '../../../../Adjuster/AdjusterSelectTextFieldFormik';
import { useCms } from '../../../hooks/useCms';
import useOrganization from '../../../OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik, useSetDefaultFieldsOnChange } from '../../../TextFieldFormik';
import { CoveragesMultiselectFormik } from '../../../TPA/Coverages/CoveragesMultiselectFormik';
import { IncidentSubTypeMultiselectFormik } from '../../../TPA/Incidents/IncidentSubTypeMultiselectFormik';
import { IncidentTypeMultiselectFormik } from '../../../TPA/Incidents/IncidentTypeMultiselectFormik';
import { LobSelectFormik } from '../../../TPA/LOB/LobSelectFormik';
import SubOrganizationSelectFormik from '../../../TPA/SubOrganizations/SubOrganizationSelectFormik';

import { useStyles } from '../../../../assets/styles';
import styles from './dashboardFilters.module.scss';

const FIELD_IDS = {
  ADJUSTER: 'adjuster',
  SUB_ORGANIZATION: 'sub_organization_id',
  INCIDENT_TYPES: 'incident_types',
  INCIDENT_SUB_TYPES: 'incident_sub_types',
  COVERAGES: 'coverages',
  LOB: 'claim_type',
  DATE_START: 'date_range_start',
  DATE_END: 'date_range_end',
  COUNT: 'count',
};

const INCIDENT_TYPE_TITLE = {
  auto_claim: 'Incident Type',
  home: 'Peril',
};

const INCIDENT_SUB_TYPE_TITLE = {
  auto_claim: 'Incident Sub Type',
  home: 'Cause of Loss',
};

const DIVIDER = ',';

const initialValues = {
  [FIELD_IDS.ADJUSTER]: '',
  [FIELD_IDS.SUB_ORGANIZATION]: '',
  [FIELD_IDS.LOB]: '',
  [FIELD_IDS.INCIDENT_TYPES]: [],
  [FIELD_IDS.INCIDENT_SUB_TYPES]: [],
  [FIELD_IDS.COVERAGES]: [],
  [FIELD_IDS.DATE_START]: '',
  [FIELD_IDS.DATE_END]: '',
  [FIELD_IDS.COUNT]: '',
};

const QaQueryFilters = ({ showOnly }) => {
  const [incidentTypes, setIncidentTypes] = useState({});
  const { subOrganizationEnabled } = useOrganization();
  const classes = useStyles();
  const { user } = useCms();
  const { values, isSubmitting, handleSubmit } = useFormikContext();

  // Supervisor can see everyone
  const allowedAdjustersIds = user.is_org_level_supervisor ? undefined : user.shared_notifications_user_ids;

  const subOrganizationIds = useMemo(() => {
    const subOrganization = getIn(values, FIELD_IDS.SUB_ORGANIZATION);
    return subOrganization ? [subOrganization] : [];
  }, [values]);

  const lobs = useMemo(() => {
    return values[FIELD_IDS.LOB] ? [values[FIELD_IDS.LOB]] : [];
  }, [values]);

  useSetDefaultFieldsOnChange(values[FIELD_IDS.SUB_ORGANIZATION], {
    [FIELD_IDS.LOB]: initialValues[FIELD_IDS.LOB],
  });

  useSetDefaultFieldsOnChange(values[FIELD_IDS.LOB], {
    [FIELD_IDS.COVERAGES]: initialValues[FIELD_IDS.COVERAGES],
    [FIELD_IDS.INCIDENT_TYPES]: initialValues[FIELD_IDS.INCIDENT_TYPES],
    [FIELD_IDS.INCIDENT_SUB_TYPES]: initialValues[FIELD_IDS.INCIDENT_SUB_TYPES],
  });

  const disabled = isSubmitting || showOnly;

  return (
    <InnerCard className={cn(styles.filtersContainer, styles.qaFilters)}>
      <Grid className={styles.generalContainer}>
        <Typography display="block" variant="subtitle1">
          General Details
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <AdjusterSelectTextFieldFormik
              id="adjuster"
              label="Adjuster"
              disabled={disabled}
              className={styles.adjuster}
              allowedAdjustersIds={allowedAdjustersIds} // Supervisor can see everyone
            />
          </Grid>
          {subOrganizationEnabled && (
            <Grid item xs={4}>
              <SubOrganizationSelectFormik fieldId={FIELD_IDS.SUB_ORGANIZATION} disabled={disabled} />
            </Grid>
          )}
          <Grid item xs={4}>
            <LobSelectFormik fieldId={FIELD_IDS.LOB} subOrganizationIds={subOrganizationIds} disabled={disabled} />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <DatePickerTextFieldFormik
              id="date_range_start"
              label="Date Range Start"
              className={classes.textField}
              disableFuture
              fullWidth
              disabled={disabled}
              clearable
            />
          </Grid>
          <Grid item xs={4}>
            <DatePickerTextFieldFormik
              id="date_range_end"
              label="Date Range End"
              className={classes.textField}
              disableFuture
              fullWidth
              disabled={disabled}
              clearable
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldFormik
              id="count"
              label="Count"
              disabled={disabled}
              className={classes.textField}
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
        </Grid>
      </Grid>
      <section className={styles.exposureContainer}>
        <Typography className={styles.exposureTitle} display="block" variant="subtitle1">
          Exposure definition
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <CoveragesMultiselectFormik
              coveragesFieldId={FIELD_IDS.COVERAGES}
              disabled={disabled}
              subOrganizationIds={subOrganizationIds}
              lobs={lobs}
            />
          </Grid>
          <Grid item xs={4}>
            <IncidentTypeMultiselectFormik
              fieldId={FIELD_IDS.INCIDENT_TYPES}
              subOrganizationIds={subOrganizationIds}
              lobs={lobs}
              disabled={disabled}
              onTypesFetch={setIncidentTypes}
              label={_.isEmpty(lobs) ? 'Type' : INCIDENT_TYPE_TITLE[lobs[0]]}
            />
          </Grid>
          <Grid item xs={4}>
            <IncidentSubTypeMultiselectFormik
              fieldId={FIELD_IDS.INCIDENT_SUB_TYPES}
              label={_.isEmpty(lobs) ? 'Sub Type' : INCIDENT_SUB_TYPE_TITLE[lobs[0]]}
              disabled={disabled}
              incidentTypes={_.pick(incidentTypes, values[FIELD_IDS.INCIDENT_TYPES])}
            />
          </Grid>
        </Grid>
      </section>
      {!showOnly && (
        <div className={classes.buttonsContainer}>
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            Search
          </Button>
        </div>
      )}
    </InnerCard>
  );
};

QaQueryFilters.propTypes = {
  onSelect: PropTypes.func,
  filters: PropTypes.object,
  showOnly: PropTypes.bool,
};

const FiltersFormik = ({ filters, showOnly, onSelect = _.noop }) => {
  const { subOrganizationEnabled } = useOrganization();

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...parseFilters(filters),
      }}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.SUB_ORGANIZATION]: subOrganizationEnabled ? Yup.string().required('Required') : undefined,
        [FIELD_IDS.LOB]: Yup.string().required('Required'),
        count: Yup.number().required('Required').min(1),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSelect(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      <QaQueryFilters filters={filters} showOnly={showOnly} />
    </Formik>
  );
};

FiltersFormik.propTypes = {
  onSelect: PropTypes.func,
  filters: PropTypes.object,
  showOnly: PropTypes.bool,
};

const valueIfNotEmpty = (value) => (!_.isNil(value) && value !== '' ? value : '');

const parseFilters = ({
  adjuster,
  sub_organization_id,
  coverage_type,
  date_range_start,
  date_range_end,
  claim_type,
  count,
  incident_type,
  incident_sub_type,
  coverages = [],
  incident_sub_types = [],
  incident_types = [],
} = {}) => ({
  [FIELD_IDS.ADJUSTER]: valueIfNotEmpty(adjuster),
  [FIELD_IDS.SUB_ORGANIZATION]: valueIfNotEmpty(sub_organization_id),
  [FIELD_IDS.LOB]: valueIfNotEmpty(claim_type),
  [FIELD_IDS.DATE_START]: date_range_start,
  [FIELD_IDS.DATE_END]: date_range_end,
  [FIELD_IDS.COVERAGES]: !_.isEmpty(coverage_type) ? coverage_type.split(DIVIDER) : coverages,
  [FIELD_IDS.INCIDENT_TYPES]: !_.isEmpty(incident_type) ? incident_type.split(DIVIDER) : incident_types,
  [FIELD_IDS.INCIDENT_SUB_TYPES]: !_.isEmpty(incident_sub_type) ? incident_sub_type.split(DIVIDER) : incident_sub_types,
  [FIELD_IDS.COUNT]: count,
});

export default FiltersFormik;
