import { Color } from '@tiptap/extension-color';
import { FontFamily } from '@tiptap/extension-font-family';
import { Highlight } from '@tiptap/extension-highlight';
import { Image } from '@tiptap/extension-image';
import { Link } from '@tiptap/extension-link';
import { Mention } from '@tiptap/extension-mention';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';
import type { Extensions } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import FontSize from '~/components/core/Editor/extensions/FontSize';
import { flattenOptions, getSuggestionConfig } from '~/components/core/Editor/MenuBar/suggestion/suggestion';
import type { DisplayConfigProps, SuggestionOption } from '~/components/core/Editor/types';

export const getExtensions = (
  suggestionOptions: SuggestionOption[],
  displayConfig: DisplayConfigProps,
  disabled?: boolean
): Extensions => {
  const extensions: Extensions = [
    StarterKit,
    Paragraph.extend({
      parseHTML() {
        return [{ tag: 'div' }];
      },
      renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { style: 'min-height: 1lh' }), 0];
      },
    }),
    TextStyle,
  ];

  if (disabled || displayConfig.textStyle) {
    extensions.push(Underline);
  }
  if (disabled || displayConfig.align) {
    extensions.push(
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      })
    );
  }

  if (disabled || displayConfig.textColor) {
    extensions.push(Highlight.configure({ multicolor: true }));
    extensions.push(Color);
  }

  if (disabled || displayConfig.font) {
    extensions.push(FontFamily);
    extensions.push(FontSize);
  }

  if (disabled || displayConfig.link) {
    extensions.push(
      Link.configure({
        openOnClick: false,
      })
    );
  }

  if (disabled || displayConfig.suggestions) {
    extensions.push(getMentionExtensionConfig(suggestionOptions));
  }

  const ExtendedImage = getExtendedImageExtension();

  extensions.push(
    ExtendedImage.configure({
      inline: true,
      allowBase64: true,
    })
  );

  extensions.push(Table);
  extensions.push(TableRow);
  extensions.push(TableCell);
  extensions.push(TableHeader);

  return extensions;
};

const getExtendedImageExtension = () => {
  return Image.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        height: {
          default: null,
          parseHTML: (element) => element.getAttribute('height'),
          renderHTML: (attributes) => ({
            height: attributes.height,
          }),
        },
        width: {
          default: null,
          parseHTML: (element) => element.getAttribute('width'),
          renderHTML: (attributes) => ({
            width: attributes.width,
          }),
        },
      };
    },
  });
};

const getMentionExtensionConfig = (suggestionOptions: SuggestionOption[]) => {
  // order matters, .extend() needs to happen before .configure()
  // this gets called only when mounting the editor, so any changes
  // here require to unmount and remount the editor to take place
  return Mention.extend({
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('id'),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {};
            }
            return {
              id: attributes.id,
            };
          },
        },
        label: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-label'),
          renderHTML: (attributes) => {
            if (!attributes.label) {
              return {};
            }
            return {
              'data-label': attributes.label,
            };
          },
        },
        class: {
          default: 'bg-slate-400 rounded-full pl-10 pr-10 pt-2 pb-2',
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'mention',
        },
      ];
    },
    renderHTML({ HTMLAttributes, node }) {
      return [
        'mention',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        node.attrs.label || node.attrs.id,
      ];
    },
  }).configure({
    HTMLAttributes: {
      class: 'mention',
    },
    renderHTML({ node }) {
      return [
        'mention',
        { class: 'bg-slate-400 rounded-full pl-10 pr-10 pt-2 pb-2' },
        `${node.attrs.label ?? node.attrs.id}`,
      ];
    },
    suggestion: Array.isArray(suggestionOptions) ? getSuggestionConfig(flattenOptions(suggestionOptions)) : undefined,
  });
};
