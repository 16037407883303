import React from 'react';

import { ChatEmptyState } from '~/components/AiChat/ChatEmptyState';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import { ChatDialogGreetingBar } from '~/components/AiChat/SideBarDialog/components/ChatDialogGreetingBar';
import { ChatDialogHeaderContainer } from '~/components/AiChat/SideBarDialog/components/ChatDialogHeaderContainer';
import { ChatDialogTitleBar } from '~/components/AiChat/SideBarDialog/components/ChatDialogTitleBar';
import { ChatTitleBar } from '~/components/AiChat/SideBarDialog/components/ChatTitleBar';
import { ClaimSummaryCollapsible } from '~/components/AiChat/SideBarDialog/components/ClaimSummaryCollapsible';
import { SidebarDialogActionButton } from '~/components/AiChat/SideBarDialog/components/SidebarDialogAction';
import WithConfirm from '~/components/ConfirmModal';
import { CloseIcon, MinimizeIcon, RefreshIcon } from '~/components/icons';
import cn from '~/Utils/cn';

export const ChatSidebarDialog: React.FC<{
  onClose: () => void;
  dialogOpen?: boolean;
  onMinimize: () => void;
  claimSummary?: string;
  isDialogMinimized: boolean;
  isHeaderCollapsed: boolean;
  dialogTitle?: string;
  chatTitle?: string;
}> = ({ children, onClose, dialogOpen, onMinimize, isHeaderCollapsed, claimSummary, dialogTitle, chatTitle }) => {
  const { restartChat, isChatLoading } = useChatContext();
  return (
    <div
      className={cn('__DIALOG__ z-20000 py-12 fixed right-0 top-0 flex h-full w-[600px] flex-col bg-white', {
        hidden: !dialogOpen,
      })}
      style={{
        boxShadow: '-7px 4px 72.6px 0px rgba(61, 74, 166, 0.23)',
      }}
    >
      <ChatDialogHeaderContainer isCollapsed={isHeaderCollapsed}>
        <ChatDialogTitleBar
          withAiIcon={isHeaderCollapsed}
          dialogTitle={dialogTitle}
          actions={[
            <WithConfirm
              primaryButtonName="restart"
              title="Are you sure?"
              contentText="This will delete all messages and restart the chat"
              shouldCloseOnPrimary={true}
              key="restart"
            >
              <SidebarDialogActionButton onClick={restartChat} tooltipText="Restart chat" icon={RefreshIcon} />
            </WithConfirm>,
            <SidebarDialogActionButton
              onClick={onMinimize}
              tooltipText="Minimize"
              key="minimize"
              icon={MinimizeIcon}
            />,
            <SidebarDialogActionButton onClick={onClose} tooltipText="Close" key="close" icon={CloseIcon} />,
          ]}
        />
        <ChatDialogGreetingBar isCollapsed={isHeaderCollapsed} />
        <ClaimSummaryCollapsible
          summary={claimSummary}
          isCollapsible={isHeaderCollapsed}
          isOpen={!isHeaderCollapsed}
          isChatLoading={isChatLoading}
          actions={
            isChatLoading
              ? {}
              : {
                  onCopy: () => navigator.clipboard.writeText(claimSummary ?? ''),
                }
          }
        />
      </ChatDialogHeaderContainer>
      <ChatTitleBar title={chatTitle} onNewChat={restartChat} isChatLoading={isChatLoading} actions={{}} />
      <div className={cn('__CHAT_CONTAINER__', 'h-full overflow-y-auto pt-10')}>
        {isChatLoading ? <ChatEmptyState /> : children}
      </div>
    </div>
  );
};
