import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './constants';

const EmailOutgoingIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M21.3122 8.53208L12.3122 2.53208C12.2198 2.47041 12.1111 2.4375 12 2.4375C11.8889 2.4375 11.7802 2.47041 11.6878 2.53208L2.68781 8.53208C2.61069 8.58354 2.54748 8.65326 2.50381 8.73504C2.46014 8.81682 2.43736 8.90812 2.4375 9.00083V18.7508C2.4375 19.0989 2.57578 19.4328 2.82192 19.6789C3.06806 19.9251 3.4019 20.0633 3.75 20.0633H20.25C20.5981 20.0633 20.9319 19.9251 21.1781 19.6789C21.4242 19.4328 21.5625 19.0989 21.5625 18.7508V9.00083C21.5626 8.90812 21.5399 8.81682 21.4962 8.73504C21.4525 8.65326 21.3893 8.58354 21.3122 8.53208ZM9.39188 14.2508L3.5625 18.3665V10.093L9.39188 14.2508ZM10.5422 14.8133H13.4578L19.2975 18.9383H4.7025L10.5422 14.8133ZM14.6081 14.2508L20.4375 10.093V18.3665L14.6081 14.2508ZM12 3.67677L20.0091 9.02052L13.4559 13.6883H10.5441L3.99094 9.01677L12 3.67677Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1875 12.4453C11.8768 12.4453 11.625 12.1935 11.625 11.8828V7.80331L11.0852 8.34306C10.8656 8.56273 10.5094 8.56273 10.2898 8.34306C10.0701 8.12339 10.0701 7.76723 10.2898 7.54756L11.5776 6.2597C11.9144 5.92287 12.4606 5.92287 12.7974 6.2597L14.0852 7.54756C14.3049 7.76723 14.3049 8.12339 14.0852 8.34306C13.8656 8.56273 13.5094 8.56273 13.2898 8.34306L12.75 7.80331V11.8828C12.75 12.1935 12.4982 12.4453 12.1875 12.4453Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default EmailOutgoingIcon;
