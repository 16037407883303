export const isDocumentAPhoto = (document) => {
  if (!document.stored_file || !document.stored_file.mime_type) {
    return false;
  }
  // Chrome and Firefox don't support TIFF
  if (document.stored_file.mime_type === 'image/tiff') {
    return false;
  }
  return document.stored_file.mime_type.startsWith('image');
};

export const getProcessedUrl = (media, mediaUrl, thumbnail = false) => {
  const url = new URL(mediaUrl, window.location);
  if (thumbnail) {
    url.searchParams.append('thumbnail', true);
  }
  url.searchParams.append('timestamp', media.datetime_updated); // To reload the <img> after the image data has changed we use this trick to 'change' the url with dummy query

  return url.href;
};
