import React from 'react';
import { Link } from '@material-ui/core';

import type { ContactFullModel } from '~/components/Contacts/types';
import Caption from '~/components/core/TextComponents/Caption';
import { useCurrencyFormatter } from '~/components/CurrencyFormatterContext';
import useOrganization from '~/components/OrganizationContext';

interface ContractOfServicePropsType {
  contact: ContactFullModel;
}

const ContractOfService: React.FC<ContractOfServicePropsType> = ({ contact }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { currencyFormatter } = useCurrencyFormatter();

  if (!organizationContactRolesDict[contact.role].is_contract_of_service_required) {
    return null;
  }

  const getDescription = () => {
    if (contact.medical_provider_discount_percentage) {
      return `Contractual Discount of ${contact.medical_provider_discount_percentage}%`;
    }
    if (contact.medical_provider_discount_fixed_amount) {
      return `Contractual Discount of ${currencyFormatter.format(contact.medical_provider_discount_fixed_amount)}`;
    }
    return 'Contract of service';
  };

  return (
    <div className="mt-10 rounded bg-slate-200 p-10">
      <div>
        <Caption variant={Caption.VARIANTS.LABEL}>Contract of Service</Caption>
        <div>
          {contact.contract_of_service_contact_document_id ? (
            <Link
              className="font-medium text-teal-600 underline"
              href={`/api/v1/contacts/${contact.id}/contract_of_service`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDescription()}
            </Link>
          ) : (
            <>-</>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractOfService;
