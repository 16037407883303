import React from 'react';

const numChildrenToClassMap: Record<number, string> = {
  1: 'w-full',
  2: 'w-1/2',
  3: 'w-1/3',
  4: 'w-1/4',
};

export const FormRow: React.FC = ({ children }) => {
  const numChildren = React.Children.count(children);
  const className = numChildrenToClassMap[numChildren];

  return (
    <div className="flex w-full gap-30">
      {React.Children.map(children, (child) => {
        return <div className={className}>{child}</div>;
      })}
    </div>
  );
};
