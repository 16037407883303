import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';

export const AiChatGradientIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="none" {...props}>
    <path
      d="M6.5607 5.80822C6.75001 5.45992 7.24999 5.45992 7.4393 5.80822L8.69008 8.10939C8.73617 8.19419 8.80581 8.26383 8.89061 8.30992L11.1918 9.5607C11.5401 9.75001 11.5401 10.25 11.1918 10.4393L8.89061 11.6901C8.80581 11.7362 8.73617 11.8058 8.69008 11.8906L7.4393 14.1918C7.24999 14.5401 6.75001 14.5401 6.5607 14.1918L5.30992 11.8906C5.26383 11.8058 5.19419 11.7362 5.10939 11.6901L2.80822 10.4393C2.45992 10.25 2.45992 9.75001 2.80822 9.5607L5.10939 8.30992C5.19419 8.26383 5.26383 8.19419 5.30992 8.10939L6.5607 5.80822Z"
      fill="url(#paint0_linear_105_1264)"
    />
    <path
      d="M14.5607 2.80822C14.75 2.45992 15.25 2.45992 15.4393 2.80822L15.9858 3.81367C16.0319 3.89846 16.1015 3.96811 16.1863 4.01419L17.1918 4.5607C17.5401 4.75001 17.5401 5.24999 17.1918 5.4393L16.1863 5.98581C16.1015 6.03189 16.0319 6.10154 15.9858 6.18633L15.4393 7.19178C15.25 7.54008 14.75 7.54008 14.5607 7.19178L14.0142 6.18633C13.9681 6.10154 13.8985 6.03189 13.8137 5.98581L12.8082 5.4393C12.4599 5.24999 12.4599 4.75001 12.8082 4.5607L13.8137 4.01419C13.8985 3.96811 13.9681 3.89846 14.0142 3.81367L14.5607 2.80822Z"
      fill="url(#paint1_linear_105_1264)"
    />
    <path
      d="M14.5607 12.8082C14.75 12.4599 15.25 12.4599 15.4393 12.8082L15.9858 13.8137C16.0319 13.8985 16.1015 13.9681 16.1863 14.0142L17.1918 14.5607C17.5401 14.75 17.5401 15.25 17.1918 15.4393L16.1863 15.9858C16.1015 16.0319 16.0319 16.1015 15.9858 16.1863L15.4393 17.1918C15.25 17.5401 14.75 17.5401 14.5607 17.1918L14.0142 16.1863C13.9681 16.1015 13.8985 16.0319 13.8137 15.9858L12.8082 15.4393C12.4599 15.25 12.4599 14.75 12.8082 14.5607L13.8137 14.0142C13.8985 13.9681 13.9681 13.8985 14.0142 13.8137L14.5607 12.8082Z"
      fill="url(#paint2_linear_105_1264)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_105_1264"
        x1="7.97059"
        y1="13.2941"
        x2="10.0972"
        y2="5.60648"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C375D" />
        <stop offset="0.927091" stopColor="#47CCCF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_105_1264"
        x1="15.5824"
        y1="6.97647"
        x2="16.8583"
        y2="2.36389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C375D" />
        <stop offset="0.927091" stopColor="#47CCCF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_105_1264"
        x1="15.5824"
        y1="16.9765"
        x2="16.8583"
        y2="12.3639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C375D" />
        <stop offset="0.927091" stopColor="#47CCCF" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default AiChatGradientIcon;
