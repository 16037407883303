import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import axios from 'axios';
import { AccountGroupOutline } from 'mdi-material-ui';

import { useStyles } from '~/assets/styles';
import { ContactEntity } from '~/components/Contact';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InlineIconButton from '~/components/InlineIconButton';
import { reportAxiosError } from '~/Utils';

const AdditionalConferenceParticipants = ({ isCallActive, participantsDetails, communication }) => {
  const classes = useStyles();
  const [isOngoingRemovingParticipant, setIsOngoingRemovingParticipant] = useState(false);

  function getStatusColor(status) {
    switch (status) {
      case 'Dialing':
        return '#ff8c08';
      case 'In Call':
        return 'green';
      case 'Busy':
        return 'red';
      default:
        return undefined;
    }
  }

  const removeConferenceParticipant = async (participantDetails) => {
    try {
      setIsOngoingRemovingParticipant(true);
      await axios.post(`/api/v1/calls/${communication.id}/remove_conference_participant`, {
        phone_call_conference_id: participantDetails.id,
      });
    } catch (error) {
      reportAxiosError(error);
    }
    setIsOngoingRemovingParticipant(false);
  };

  return (
    <>
      {participantsDetails.length !== 0 && (
        <>
          <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <AccountGroupOutline />
            &nbsp;
            <Typography display="inline" variant="subtitle1">
              Conference Call:
            </Typography>
          </div>
          <Grid container spacing={1}>
            {participantsDetails.map((participantDetails) => (
              <Grid key={participantDetails.id} item xs={12}>
                <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {participantDetails.contact_id ? (
                    <ContactEntity
                      classes={classes}
                      contactId={participantDetails.contact.id}
                      contactDisplayName={participantDetails.contact.full_name}
                    />
                  ) : (
                    <Typography display="inline">
                      {participantDetails.name} &nbsp;-&nbsp; {participantDetails.phone_number}
                    </Typography>
                  )}
                  <span style={{ color: getStatusColor(participantDetails.status) }}>
                    <strong>&nbsp;({participantDetails.status})</strong>
                  </span>
                  {isCallActive && ['Dialing', 'In Call', 'Busy'].includes(participantDetails.status) && (
                    <Tooltip title="Remove Participant From Call">
                      <div>
                        <InlineIconButton
                          icon={RemoveCircleIcon}
                          defaultColor="inherit"
                          className={classes.inlineEditIcon}
                          disabled={isOngoingRemovingParticipant}
                          onClick={() => removeConferenceParticipant(participantDetails)}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

AdditionalConferenceParticipants.propTypes = {
  isCallActive: PropTypes.bool.isRequired,
  participantsDetails: PropTypes.array.isRequired,
  communication: PropTypes.object.isRequired,
};

export default AdditionalConferenceParticipants;
