import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import HoverActionField from '../HoverActionField';
import TextFieldFormik from '../TextFieldFormik';

import CustomFields from './CustomFields';
import useCustomFields, { CustomFieldsContextProvider } from './CustomFieldsContext';

import { useStyles } from '../../assets/styles';

const CustomInvolvedParties = () => {
  const { claim, onClaimUpdate } = useClaim();

  const onSave = (baseUrl) => async (values, involvedPartyId) => {
    try {
      if (involvedPartyId) {
        await axios.put(`${baseUrl}/${involvedPartyId}`, values);
      } else {
        await axios.post(baseUrl, values);
      }

      await onClaimUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onSaveInvolvedPerson = onSave(`/api/v1/custom_claims/${claim.id}/involved_person`);

  const onSaveInvolvedProperty = onSave(`/api/v1/custom_claims/${claim.id}/involved_property`);

  return (
    <CardDialog title="Involved Parties">
      <CustomInvolvedPartiesByType
        partiesConfiguration={claim.custom_configuration.configuration.involved_parties.people}
        arePeople={true}
        parties={claim.incident.involved_persons}
        onSave={onSaveInvolvedPerson}
      />
      <CustomInvolvedPartiesByType
        partiesConfiguration={claim.custom_configuration.configuration.involved_parties.properties}
        arePeople={false}
        parties={claim.incident.involved_properties}
        onSave={onSaveInvolvedProperty}
      />
    </CardDialog>
  );
};

const CustomInvolvedPartiesByType = ({ parties, partiesConfiguration, arePeople, onSave }) => {
  const classes = useStyles();

  const entityType = arePeople ? 'custom_involved_person' : 'custom_involved_property';
  const involvedPartiesByType = _.chain(parties)
    .filter((p) => p.type === entityType)
    .groupBy('custom_type')
    .value();

  const [showInvolvedPartyDialog, setShowInvolvedPartyDialog] = useState(false);
  const [involvedPartyToEditData, setInvolvedPartyToEditData] = useState(null);

  const onCreateInvolvedParty = (configuration) => {
    setInvolvedPartyToEditData({
      party: null,
      configuration,
    });
    setShowInvolvedPartyDialog(true);
  };

  const onEditInvolvedParty = (party, configuration) => {
    setInvolvedPartyToEditData({
      party,
      configuration,
    });
    setShowInvolvedPartyDialog(true);
  };

  const onCloseEdit = () => {
    setShowInvolvedPartyDialog(false);
    setInvolvedPartyToEditData(null);
  };

  const generateOnSave = (party) => async (values) => {
    await onSave(values, party?.id);
    onCloseEdit();
  };

  return (
    <>
      <Grid container spacing={1}>
        {partiesConfiguration.map((typeConfig) => (
          <Grid item xs={12} key={typeConfig.id}>
            <Grid item xs={12} key={`party_${typeConfig.id}`}>
              <CardDialog title={typeConfig.desc} maxWidth="lg">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {involvedPartiesByType[typeConfig.id] &&
                      _.sortBy(involvedPartiesByType[typeConfig.id], ['display_name']).map((party) => (
                        <div key={`${typeConfig.id}_${party.id}`} className={classes.containerCentered}>
                          <HoverActionField onAction={() => onEditInvolvedParty(party, typeConfig)} classes={classes}>
                            {party.display_name}
                          </HoverActionField>
                        </div>
                      ))}
                    <Grid item xs={6}>
                      <Button color="primary" onClick={() => onCreateInvolvedParty(typeConfig)}>
                        <AddIcon className={classes.rightButtonIcon} />
                        Add {typeConfig.desc}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardDialog>
            </Grid>
            <Grid item xs={6} />
          </Grid>
        ))}
      </Grid>

      {showInvolvedPartyDialog && (
        <CustomFieldsContextProvider customFieldsConfigurations={involvedPartyToEditData.configuration.additional_data}>
          <InvolvedPartyDialog
            involvedParty={involvedPartyToEditData.party}
            partyConfiguration={involvedPartyToEditData.configuration}
            isPerson={arePeople}
            onCancel={onCloseEdit}
            onSave={generateOnSave(involvedPartyToEditData.party)}
          />
        </CustomFieldsContextProvider>
      )}
    </>
  );
};

CustomInvolvedPartiesByType.propTypes = {
  parties: PropTypes.array.isRequired,
  partiesConfiguration: PropTypes.array.isRequired,
  arePeople: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

const InvolvedPartyDialog = ({ involvedParty, partyConfiguration, isPerson, onCancel, onSave, readOnly = false }) => {
  const classes = useStyles();
  const contactPropertyName = isPerson ? 'contact' : 'owner_contact';
  const { additionalDataValidations } = useCustomFields();

  const additionalDataInitialValues = () => {
    const initialValues = {};
    partyConfiguration.additional_data.forEach((fieldConfig) => {
      if (involvedParty && Object.keys(involvedParty.additional_data).includes(fieldConfig.id)) {
        initialValues[fieldConfig.id] = involvedParty.additional_data[fieldConfig.id];
      } else {
        initialValues[fieldConfig.id] = fieldConfig.type === 'multiselect' ? [] : '';
      }
    });
    return initialValues;
  };

  return (
    <Formik
      initialValues={{
        display_name: involvedParty?.display_name || '',
        type: partyConfiguration.id,
        contact_id: involvedParty ? involvedParty[contactPropertyName].id : '',
        contact_full_name: involvedParty ? involvedParty[contactPropertyName].full_name : '',
        additional_data: additionalDataInitialValues(),
      }}
      validationSchema={Yup.object().shape({
        display_name: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        contact_id: Yup.number().required('Required'),
        additional_data: Yup.object().shape(additionalDataValidations),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSave(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        const title = involvedParty
          ? readOnly
            ? involvedParty.display_name
            : `Edit ${partyConfiguration.desc}`
          : `Add ${partyConfiguration.desc}`;

        return (
          <CardDialog isDialog={true} title={title} maxWidth="md" onClose={onCancel} preventClose={isSubmitting}>
            {readOnly && (
              <Typography variant="subtitle2" style={{ color: 'grey', marginBottom: 15 }}>
                {partyConfiguration.desc}
              </Typography>
            )}
            <Grid container spacing={1}>
              {!readOnly && (
                <Grid item xs={6}>
                  <TextFieldFormik id="display_name" label="Name" className={classes.textField} fullWidth />
                </Grid>
              )}
              <Grid item xs={6}>
                <ContactTextFieldFormik
                  id="contact"
                  label={isPerson ? 'Contact' : 'Owner'}
                  className={classes.textField}
                  acceptedRoles={[partyConfiguration.contact_role]}
                  fullWidth
                  showOnly={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomFields
                  customFields={partyConfiguration.additional_data}
                  values={values.additional_data}
                  readOnly={readOnly}
                  inline
                />
              </Grid>
            </Grid>
            {!readOnly && (
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={isSubmitting} onClick={onCancel} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
};

InvolvedPartyDialog.propTypes = {
  involvedParty: PropTypes.object,
  partyConfiguration: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: requiredIf(PropTypes.func, (props) => !props.readOnly),
  isPerson: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

const ReadOnlyCustomInvolvedPartyDialog = ({ open, party, onCancel, isPerson }) => {
  const { claim } = useClaim();
  const partyConfigurations =
    claim.custom_configuration.configuration.involved_parties[isPerson ? 'people' : 'properties'];
  const partyConfig = partyConfigurations.find((config) => config.id === party.custom_type);

  return (
    <InvolvedPartyDialog
      involvedParty={party}
      partyConfiguration={partyConfig}
      isPerson={isPerson}
      onCancel={onCancel}
      open={open}
      readOnly
    />
  );
};

ReadOnlyCustomInvolvedPartyDialog.propTypes = {
  open: PropTypes.bool,
  party: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPerson: PropTypes.bool.isRequired,
};

export { ReadOnlyCustomInvolvedPartyDialog };
export default CustomInvolvedParties;
