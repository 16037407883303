import { COUNTRY_TO_STATE_MAP } from '../../../Types';
import { isQoverClaim } from '../../../Utils';

export const attachedDocumentsMaxHeight = '200px';

export function getPayeeContacts(values, paymentRequestStoreData, claim, options = {}) {
  const { shouldIncludeOnlyChosen = true, ignoreNonPrimaryPayeeCandadiages = false } = options;
  let chosenPayeeContacts = [];

  paymentRequestStoreData.forEach((paymentRequestStoreDataEntry) => {
    if (ignoreNonPrimaryPayeeCandadiages && paymentRequestStoreDataEntry.can_be_primary === false) {
      return;
    }
    switch (paymentRequestStoreDataEntry.type) {
      case 'optional_known_contact':
        if (values[paymentRequestStoreDataEntry.field_name] || !shouldIncludeOnlyChosen) {
          const contact =
            paymentRequestStoreDataEntry.contact ||
            claim.contacts.find((contact) => paymentRequestStoreDataEntry.contact_id === contact.id);
          chosenPayeeContacts.push(contact);
        }
        break;
      case 'contact_search_single': {
        const contactEntry = values[paymentRequestStoreDataEntry.field_name];
        if (contactEntry.contact) {
          chosenPayeeContacts.push(contactEntry.contact);
        } else if (contactEntry.contact_id) {
          const contact =
            contactEntry.contact || claim.contacts.find((contact) => contactEntry.contact_id === contact.id);
          chosenPayeeContacts.push(contact);
        }
        break;
      }
      case 'contact_search': {
        const contactEntriesList = values[paymentRequestStoreDataEntry.field_name] || [];
        contactEntriesList.forEach((contactEntry) => {
          if (contactEntry.contact) {
            chosenPayeeContacts.push(contactEntry.contact);
          } else if (contactEntry.contact_id) {
            const contact =
              contactEntry.contact || claim.contacts.find((contact) => contactEntry.contact_id === contact.id);
            chosenPayeeContacts.push(contact);
          }
        });
        break;
      }
      default:
        throw Error(`Unknown paymentRequestStoreDataEntry type: ${paymentRequestStoreDataEntry.type}`);
    }
  });

  return chosenPayeeContacts;
}

export const PAY_TO_LENGTH_VALIDATION_FIELDS = [
  { fieldKey: 'full_name', description: 'full name', maxCharsKey: 'full_name_max_length' },
  { fieldKey: 'city', description: 'city', maxCharsKey: 'city_max_length' },
  { fieldKey: 'street_address1', description: 'street address 1', maxCharsKey: 'address1_max_length' },
  { fieldKey: 'street_address2', description: 'street address 2', maxCharsKey: 'address2_max_length' },
  {
    fieldKey: 'address_line1',
    description: 'address line 1',
    maxCharsKey: 'address_line1_max_length',
    joinedFieldsList: ['street_address1', 'street_address2'],
  },
  {
    fieldKey: 'address_line2',
    description: 'address line 2',
    maxCharsKey: 'address_line2_max_length',
    joinedFieldsList: ['city', 'county', 'state', 'zipcode'],
  },
  { fieldKey: 'pay_to_line', description: 'pay to line', maxCharsKey: 'pay_to_line_max_length' },
];

export const isStateCountry = (country) => country in COUNTRY_TO_STATE_MAP;

export const getCharsLimitValidationMessage = (maxLength) => `Max ${maxLength} characters`;

export function shouldAdjustReserve({
  isSubReservesConfigEnabled,
  payableWithReserve,
  paymentAmount,
  subReserveAmounts = null,
}) {
  if (isSubReservesConfigEnabled) {
    return subReserveAmounts?.some(
      ({ type_key, amount }) => amount > (payableWithReserve?.sub_reserves?.[type_key] || 0)
    );
  }
  return paymentAmount > payableWithReserve.reserve;
}

export const getReferenceInitialValue = (claim, payableType, prefillIndemnityReferenceWithClaimNumber) => {
  if ((prefillIndemnityReferenceWithClaimNumber && payableType === 'indemnity') || isQoverClaim(claim)) {
    return claim.claim_id_display;
  }
  return '';
};

export const shouldUseInvoiceForPaymentReferenceInitialValue = (
  payableType,
  prefillExpensesReferenceWithInvoiceNumber
) => {
  return prefillExpensesReferenceWithInvoiceNumber && payableType === 'expenses';
};
