import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import DocumentPhoneCallCommunicationCardContainer, {
  NewPhoneCallCommunicationCard,
} from '~/components/communications/PhoneCallCommunicationCard';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { useCms } from '~/components/hooks/useCms';
import { FaxIcon, PhoneIcon } from '~/components/icons/notifications';
import InlineIconButton from '~/components/InlineIconButton';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import cn from '~/Utils/cn';

const PhoneCallCommunicationPopover = ({
  contact,
  communicationDetails,
  onUpdate,
  mixpanelSource,
  ignorePermissions = false,
}) => {
  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const { claim } = useClaim();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [phoneCallAnchorEl, setPhoneCallAnchorEl] = useState(null);
  const [isDocumentPhoneCall, setIsDocumentPhoneCall] = useState(false);

  const { add } = useMinimizedDialogs();

  const isMinimizedDialogsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MINIMIZED_DIALOG_FEATURE
  );

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleIconClick = (event) => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHONE_CALL_COMMUNICATION_CLICKED, { source: mixpanelSource });

    if (user.is_call_center_allowed) {
      setPhoneCallAnchorEl(event.currentTarget);
    } else {
      handleOpenDocumentPhoneCallDialog();
    }
  };

  const documentPhoneCallProps = {
    claim,
    contact,
    contactPhoneId: communicationDetails.id,
    onCancel: handleCloseDialog,
    onDocumentPhoneCall: () => {
      onUpdate();
      handleCloseDialog();
    },
  };

  const handleOpenDocumentPhoneCallDialog = () => {
    if (isMinimizedDialogsEnabled) {
      const type = 'PHONE';
      const { handleCloseDialog } = add({
        barHeader: `Document Phone Call: ${contact.full_name}`,
        type,
        dialogComponent: DocumentPhoneCallCommunicationCardContainer,
        dialogProps: {
          ...documentPhoneCallProps,
          onMinimized: () => {
            mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
              source: mixpanelSource,
              type,
            });
          },
          onCancel: () => handleCloseDialog(),
          onDocumentPhoneCall: () => {
            handleCloseDialog();
            if (onUpdate) {
              onUpdate();
            }
          },
        },
      });
    } else {
      setIsDocumentPhoneCall(true);
      setIsDialogOpen(true);
    }
  };

  const handleDocumentCallMenuClick = () => {
    handleOpenDocumentPhoneCallDialog();
    setPhoneCallAnchorEl(null);
  };

  const handleMakeCallMenuClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHONE_CALL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.CONTACT_MINI_CARD_NEW_PHONE_CALL_FROM_DROPDOWN,
    });

    setIsDocumentPhoneCall(false);
    setPhoneCallAnchorEl(null);
    setIsDialogOpen(true);
  };

  const isDisabled = user.role.is_view_only || communicationDetails.phone_type === 'fax';

  return (
    <>
      <InlineIconButton
        icon={communicationDetails.phone_type !== 'fax' ? PhoneIcon : FaxIcon}
        onClick={handleIconClick}
        disabled={isDisabled}
        className={cn({ [classes.hoverableNonFilledIcon]: !isDisabled })}
        ignorePermissions={ignorePermissions}
      />
      {isDocumentPhoneCall && isDialogOpen ? (
        <DocumentPhoneCallCommunicationCardContainer {...documentPhoneCallProps} />
      ) : null}
      {!isDocumentPhoneCall && isDialogOpen ? (
        <NewPhoneCallCommunicationCard
          contact={contact}
          contactPhoneId={communicationDetails.id}
          onCancel={handleCloseDialog}
          onNewPhoneCallCreated={() => {
            onUpdate();
            handleCloseDialog();
          }}
        />
      ) : null}
      <Menu
        id="menu-appbar"
        anchorEl={phoneCallAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!phoneCallAnchorEl}
        onClose={() => setPhoneCallAnchorEl(null)}
      >
        <MenuItem onClick={() => handleDocumentCallMenuClick()}>Document call</MenuItem>
        <MenuItem onClick={() => handleMakeCallMenuClick(false)}>Make a call</MenuItem>
      </Menu>
    </>
  );
};

PhoneCallCommunicationPopover.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  communicationDetails: PropTypes.object.isRequired,
  mixpanelSource: PropTypes.string,
  ignorePermissions: PropTypes.bool,
};

export default PhoneCallCommunicationPopover;
