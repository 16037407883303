import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import { FsIconButton } from '../../core';
import { TrashIcon } from '../../icons';
import { InvolvedPersonsListDetails } from '../../InvolvedPerson';

import { DriverDetails } from './DriverInvolved';
import PassengerInvolvedFragment, { passengerFields } from './PassengerInvolved';
import VehicleInvolvedDetails from './VehicleDetails';

import { useStyles } from '../../../assets/styles';

const vehiclePartyFields = {
  involved_vehicle: null,
  passengers: [],
  driver: null,
};

function VehicleParty(props) {
  const classes = useStyles();

  const {
    disabled,
    title,
    isInsured,
    vehicleParty,
    showOnly,
    onUpdateVehicle,
    onDeleteVehicle,
    allowDeleteInvolved,
    shouldHidePassengers,
    errors,
  } = props;

  const action = onDeleteVehicle && (
    <WithConfirm title="Delete Vehicle?" primaryButtonName="Delete" shouldCloseOnPrimary>
      <FsIconButton onClick={onDeleteVehicle} disabled={disabled} icon={TrashIcon} />
    </WithConfirm>
  );

  return (
    <CardDialog
      title={
        <>
          {title} {action}
        </>
      }
      outlinedCard
    >
      <div className={classes.containerCentered}>
        <DirectionsCarOutlinedIcon className={classes.indicationIcon} style={{ color: '#9232CA' }} />
        &nbsp;
        <Typography style={{ paddingRight: 14 }} display="block" variant="subtitle1">
          Vehicle
        </Typography>
        <VehicleInvolvedDetails
          classes={classes}
          disabled={disabled}
          showOnly={showOnly}
          isInsured={isInsured}
          vehicleInvolved={vehicleParty.involved_vehicle}
          onSetVehicleDetails={(vehicleInvolved) => onUpdateVehicle('SET_INVOLVED_VEHICLE', vehicleInvolved)}
          onSaveVehicleDetails={(vehicleInvolved) => onUpdateVehicle('UPDATE_INVOLVED_VEHICLE', vehicleInvolved)}
          onDeleteVehicle={allowDeleteInvolved && (() => onUpdateVehicle('DELETE_INVOLVED_VEHICLE', ''))}
          error={errors && errors['involved_vehicle']}
        />
      </div>
      {!shouldHidePassengers && (
        <>
          <Divider style={{ margin: '8px 0px 8px 0px' }} />
          <div className={`${classes.containerCentered} ${classes.containerTitle}`}>
            <PeopleOutlineIcon style={{ color: '#9232CA' }} className={classes.indicationIcon} />
            &nbsp;
            <Typography display="block" variant="subtitle1">{`Passengers (including driver): ${
              (vehicleParty.passengers ? vehicleParty.passengers.length : 0) + (vehicleParty.driver ? 1 : 0)
            }`}</Typography>
          </div>
          <DriverDetails
            classes={classes}
            disabled={disabled}
            isInsured={isInsured}
            driver={vehicleParty.driver}
            onSetDriverDetails={(driver) => onUpdateVehicle('SET_DRIVER', driver)}
            onSaveDriverDetails={(driver) => onUpdateVehicle('UPDATE_DRIVER', driver)}
            onDeleteDriver={allowDeleteInvolved && (() => onUpdateVehicle('DELETE_DRIVER', ''))}
            error={errors && errors['driver']}
          />
          <Divider />
          <InvolvedPersonsListDetails
            involvedPersons={vehicleParty.passengers}
            onSaveInvolvedPersonDetails={(passenger, idx) => onUpdateVehicle('UPDATE_PASSENGER', { passenger, idx })}
            onAddInvolvedPerson={(passenger) => onUpdateVehicle('ADD_PASSENGER', passenger)}
            onDeleteInvolvedPerson={(passenger, idx) => onUpdateVehicle('DELETE_PASSENGER', { passenger, idx })}
            personLabel="Passenger"
            allowDelete={allowDeleteInvolved}
            CustomInvolvedPersonFragment={PassengerInvolvedFragment}
            customInitialFields={passengerFields}
            disabled={disabled}
            contactAcceptedRoles={['insured', 'named_driver', 'spouse', 'family_member', 'claimant', 'other']}
          />
        </>
      )}
    </CardDialog>
  );
}

VehicleParty.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  vehicleParty: PropTypes.object.isRequired,
  onUpdateVehicle: PropTypes.func.isRequired,
  onDeleteVehicle: PropTypes.func,
  allowDeleteInvolved: PropTypes.bool,
  isInsured: PropTypes.bool,
  showOnly: PropTypes.bool,
  shouldHidePassengers: PropTypes.bool,
  errors: PropTypes.object,
};

export { vehiclePartyFields };
export default VehicleParty;
