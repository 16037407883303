import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Text } from '~/components/core';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import LoadingIndicator from '~/components/LoadingIndicator';
import { ActionsButton } from '~/components/ReportsPage/ActionsButton';
import { BackButton } from '~/components/ReportsPage/BackButton';
import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { TableauEmbedViewer } from '~/components/ReportsPage/EmbedComponents/TableauEmbedViewer';
import {
  finalizeProjectName,
  getBackButtonText,
  PERSONAL_SPACE_PROJECT_ID,
  removeLastSegment,
} from '~/components/ReportsPage/helpers/ReportsUtils';
import { useTableauEmbedAuth } from '~/components/ReportsPage/Hooks/useTableauEmbedAuth';
import { WorkbookPageHeaderLayout } from '~/components/ReportsPage/pages/WorkbookPageHeaderLayout';
import { ReportsActions } from '~/components/ReportsPage/ReportsActions/ReportsActions';
import { useReportsActions } from '~/components/ReportsPage/ReportsActions/useReportsActions';
import type { View, Workbook } from '~/components/ReportsPage/types';
import colors from '~/theme/tailwind/colors';

interface CreateTabsProps {
  workbook?: Workbook;
  token?: string;
}

const createTabs = ({ workbook, token }: CreateTabsProps) => {
  if (!token || !workbook?.views) return [];

  return workbook.views.map((view: View) => ({
    label: view.name,
    url: view.id,
    component: TableauEmbedViewer,
    props: {
      workbook: workbook.content_url,
      view: view.content_url,
      token,
      options: { width: '100%', height: 'calc(100vh - 210px)' },
    },
    className: '',
    disabled: false,
  }));
};

interface ActionItem {
  id: string;
  label: string;
  onClick: () => void;
}

const createActionItem = ({ id, label, onClick }: ActionItem) => ({
  id,
  label,
  onClick,
});

interface WorkbookViewerPageProps {
  workbookId: string;
}

export const WorkbookViewerPage: React.FC<WorkbookViewerPageProps> = ({ workbookId }) => {
  const { workbooksMap, me, isLoading } = useTableau();
  const workbook: Workbook = workbooksMap[workbookId];
  const isOwner = workbook?.owner_id === me?.id;
  const isDraft = workbook?.project_id === PERSONAL_SPACE_PROJECT_ID;
  const { token } = useTableauEmbedAuth();
  const { action, onClose, onActionHandler, allowedProjectsToShare, showSuccessMessage, onCloseSuccessMessage } =
    useReportsActions();
  const history = useHistory();
  const match = useRouteMatch();
  const tabs = React.useMemo(() => createTabs({ workbook, token }), [workbook, token]);
  const onBackClick = React.useCallback(() => {
    history.push(removeLastSegment(match.url));
  }, [history, match]);

  const backButtonText = getBackButtonText(match.url);

  const actions = [
    createActionItem({
      id: 'edit',
      label: 'Edit',
      onClick: () => onActionHandler({ action: 'edit-dialog', params: { id: workbookId } }),
    }),
    createActionItem({
      id: 'share',
      label: 'Share',
      onClick: () => onActionHandler({ action: 'share-dialog', params: { id: workbookId } }),
    }),
    createActionItem({
      id: 'delete',
      label: 'Delete',
      onClick: () => onActionHandler({ action: 'delete-dialog', params: { id: workbookId } }),
    }),
  ];

  return (
    <section className="h-[calc(100vh-100px)] bg-slate-100">
      {(!token || isLoading) && <LoadingIndicator isError={false} />}
      {token && !isLoading && (
        <>
          <WorkbookPageHeaderLayout
            headerComponent={
              <>
                <BackButton text={backButtonText} onClick={onBackClick} />
                <div className="flex h-full items-center gap-10">
                  <Text
                    variant={Text.VARIANTS.XL}
                    weight={Text.WEIGHTS.SEMI_BOLD}
                    colorVariant={Text.COLOR_VARIANTS.DISABLED}
                  >
                    {`${finalizeProjectName(workbook?.project_name)}`}
                  </Text>
                  <ChevronRightIcon size={20} iconColor={colors.slate[600]} className="mt-2" />
                  <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
                    {workbook?.name || ''}
                  </Text>
                </div>
              </>
            }
            actionsComponent={<ActionsButton actions={actions} disabled={!isOwner} />}
          />

          {workbook && (
            <div className="--TABS-CONTAINER-- h-fit w-full px-20">
              <div className=" rounded-lg border-1 border-solid border-slate-300 shadow-md">
                <InnerTabs tabs={tabs} tabsContainerClassName="rounded-t-md border-solid border-slate-300 border-1" />
              </div>
            </div>
          )}
        </>
      )}

      <ReportsActions
        action={action}
        onClose={onClose}
        onConfirm={onActionHandler}
        projectsToShare={allowedProjectsToShare}
        isDrafts={isDraft}
        onCloseSuccessMessage={onCloseSuccessMessage}
        showSuccessMessage={showSuccessMessage}
      />
    </section>
  );
};
