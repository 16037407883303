import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

import Button from '~/components/core/Atomic/Buttons/Button';
import { CommunicationIcon } from '~/components/icons';

import { useClaim } from '../ClaimContainer';

import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

export default function CommunicationLink(props) {
  const { communication, text, onUpdate, displayLinkAsButton } = props;
  const [isCommunicationCardOpen, setIsCommunicationCardOpen] = useState(false);
  const { claim } = useClaim();

  const onClick = (e) => {
    e.preventDefault();
    setIsCommunicationCardOpen(true);
  };

  return (
    <>
      {displayLinkAsButton && (
        <Button
          className="flex max-w-max cursor-pointer items-center rounded border border-solid border-slate-500 bg-slate-100 p-10 normal-case underline hover:border-teal-700	hover:underline"
          variant="outlined"
          onClick={onClick}
          startIcon={<CommunicationIcon className="fill-teal-700" size={16} />}
          classes={{ label: 'font-normal' }}
        >
          {text}
        </Button>
      )}
      {!displayLinkAsButton && (
        <Link href="" style={{ margin: 0, textDecoration: 'underline' }} onClick={onClick}>
          {text}
        </Link>
      )}
      {isCommunicationCardOpen && (
        <ViewCommunicationCardContainer
          claimId={communication.claim_id}
          communicationId={communication.id}
          isDialog
          onClose={() => setIsCommunicationCardOpen(false)}
          onUpdate={onUpdate}
          isCommunicationNotRelatedToClaimInContext={!communication.claim_id || communication.claim_id !== claim?.id}
        />
      )}
    </>
  );
}

CommunicationLink.propTypes = {
  communication: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  displayLinkAsButton: PropTypes.bool,
};
