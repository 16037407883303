import React, { Component, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes, { bool } from 'prop-types';
import { MenuItem, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import YesNoNaUnknownQuestionFormik from '~/components/core/Formik/YesNoNaUnknownQuestionFormik';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import CardDialog from './CardDialog';
import { withClaim } from './ClaimContainer';
import ContactTextFieldFormik from './ContactTextFieldFormik';
import HoverActionField from './HoverActionField';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
  NumericTextFieldFormik,
  TextFieldFormik,
  TimePickerTextFieldFormik,
  YearPickerSelectTextFieldFormik,
} from './TextFieldFormik';

import styles, { useStyles } from '../assets/styles';

class HoverChangeField extends Component {
  state = {
    popoverAnchorEl: undefined,
  };

  handleClosePopover = () => {
    this.setState({ popoverAnchorEl: undefined });
  };

  render() {
    const {
      children,
      classes,
      label,
      name,
      value,
      validationSchema,
      specialFieldType,
      specialFieldProps,
      onUpdate,
      shouldUpdateClaim,
      onClaimUpdate,
      multiline,
      disabled,
      fullWidth,
      rows,
      width,
      customFieldIdName,
      overrideOnEdit,
      specialFieldAdditionalProps,
      centerContent,
      permanent,
      inputProps = undefined,
    } = this.props;

    const { popoverAnchorEl } = this.state;
    const customDivRef = React.createRef();

    return (
      <>
        {overrideOnEdit ? (
          <div ref={customDivRef}>
            {React.Children.map(children, (child) =>
              React.cloneElement(child, { onEdit: () => this.setState({ popoverAnchorEl: customDivRef.current }) })
            )}
          </div>
        ) : (
          <HoverActionField
            onAction={(e) => this.setState({ popoverAnchorEl: e.currentTarget })}
            fullWidth={fullWidth}
            disabled={disabled}
            centerContent={centerContent}
            permanent={permanent}
          >
            {children || value}
          </HoverActionField>
        )}
        <Formik
          initialValues={{ [name]: value }}
          validationSchema={validationSchema ? Yup.object().shape({ [name]: validationSchema }) : undefined}
          enableReinitialize
          onSubmit={(values, formikProps) => {
            return Promise.resolve(onUpdate(values))
              .then(() => {
                if (shouldUpdateClaim) {
                  onClaimUpdate().then(() => this.handleClosePopover());
                } else {
                  this.handleClosePopover();
                }
              })
              .catch(() => {
                formikProps.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => (
            <CardDialog
              noCardTitle
              isPopover
              closeOnBackdropClick
              open={Boolean(popoverAnchorEl)}
              anchorEl={popoverAnchorEl}
              onClose={this.handleClosePopover}
              width={width}
            >
              {specialFieldType ? (
                <>
                  {specialFieldType === 'contact' && (
                    <ContactTextFieldFormik
                      id={customFieldIdName ? customFieldIdName : name}
                      label={label}
                      className={classes.textField}
                      {...specialFieldAdditionalProps}
                      fixedSearchResults
                      fullWidth
                    />
                  )}
                  {specialFieldType === 'monetary' && (
                    <MonetaryValueTextFieldFormik id={name} label={label} fullWidth />
                  )}
                  {specialFieldType === 'date' && (
                    <DatePickerTextFieldFormik
                      id={name}
                      label={label}
                      {...specialFieldAdditionalProps}
                      className={classes.textField}
                      fullWidth
                    />
                  )}
                  {specialFieldType === 'time' && (
                    <TimePickerTextFieldFormik
                      id={name}
                      label={label}
                      {...specialFieldAdditionalProps}
                      className={classes.textField}
                      fullWidth
                    />
                  )}
                  {specialFieldType === 'year' && (
                    <YearPickerSelectTextFieldFormik
                      id={name}
                      label={label}
                      {...specialFieldAdditionalProps}
                      className={classes.textField}
                      fullWidth
                    />
                  )}
                  {specialFieldType === 'numeric' && (
                    <NumericTextFieldFormik id={name} label={label} className={classes.textField} fullWidth />
                  )}
                  {specialFieldType === 'bool' && (
                    <YesNoQuestionFormik id={name} questionText={label} {...specialFieldProps} />
                  )}
                  {specialFieldType === 'yes_no_na_unknown' && (
                    <YesNoNaUnknownQuestionFormik id={name} questionText={label} {...specialFieldProps} />
                  )}
                </>
              ) : (
                <TextFieldFormik
                  id={name}
                  label={label}
                  className={classes.textField}
                  multiline={multiline}
                  rows={rows}
                  fullWidth
                  InputProps={inputProps}
                />
              )}
              <div className={classes.buttonsContainer}>
                <CancelButton
                  disabled={formikProps.isSubmitting}
                  onClick={() => {
                    formikProps.handleReset();
                    this.handleClosePopover();
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                >
                  Update
                </Button>
              </div>
            </CardDialog>
          )}
        </Formik>
      </>
    );
  }
}

HoverChangeField.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired, // comes from withStyles
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  validationSchema: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  shouldUpdateClaim: bool,
  multiline: PropTypes.bool,
  width: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  specialFieldType: PropTypes.oneOf(['contact', 'monetary', 'date', 'numeric', 'bool', 'time', 'yes_no_na_unknown']),
  specialFieldProps: PropTypes.object,
  customFieldIdName: PropTypes.string,
  overrideOnEdit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  specialFieldAdditionalProps: PropTypes.object,
  onClaimUpdate: requiredIf(PropTypes.func, (props) => props.shouldUpdateClaim), // comes from withClaim
  centerContent: PropTypes.bool,
  permanent: PropTypes.bool,
  inputProps: PropTypes.object,
};

function SelectHoverChangeField({
  children,
  label,
  validationSchema,
  fieldId,
  value,
  keys,
  keysDisabled,
  displayValueFunc,
  disabled,
  fullWidth,
  onUpdate,
  width,
  overrideOnEdit,
  overrideOnClick,
  isMultiSelect = false,
}) {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState();
  const customDivRef = React.createRef();
  const classes = useStyles();

  return (
    <>
      {overrideOnEdit || overrideOnClick ? (
        disabled ? (
          children
        ) : (
          <div ref={customDivRef}>
            {React.Children.map(children, (child) => {
              let overrides = {};
              if (overrideOnEdit) {
                overrides.onEdit = () => setPopoverAnchorEl(customDivRef.current);
              }
              if (overrideOnClick) {
                overrides.onClick = () => setPopoverAnchorEl(customDivRef.current);
              }

              return React.cloneElement(child, overrides);
            })}
          </div>
        )
      ) : (
        <HoverActionField
          onAction={(e) => setPopoverAnchorEl(e.currentTarget)}
          fullWidth={fullWidth}
          disabled={disabled}
        >
          {children || value}
        </HoverActionField>
      )}

      <Formik
        initialValues={{ [fieldId]: value }}
        validationSchema={validationSchema ? Yup.object().shape({ [fieldId]: validationSchema }) : undefined}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          try {
            await onUpdate(values);
            setPopoverAnchorEl(undefined);
          } catch {
            formikProps.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => (
          <CardDialog
            noCardTitle
            isPopover
            closeOnBackdropClick
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={() => setPopoverAnchorEl(undefined)}
            width={width}
          >
            <div className={classes.textField}>
              {isMultiSelect && (
                <MultiSelectTextFieldFormik
                  id={fieldId}
                  label={label}
                  className={classes.textField}
                  fullWidth
                  options={keys}
                  renderValue={(selected) => selected.map((key) => displayValueFunc(key)).join(', ')}
                  renderOption={displayValueFunc}
                />
              )}
              {!isMultiSelect && (
                <TextFieldFormik
                  id={fieldId}
                  label={label}
                  className={classes.textField}
                  disabled={formikProps.isSubmitting}
                  select
                  fullWidth
                >
                  {keys.map((key) => (
                    <MenuItem key={key} value={key} disabled={keysDisabled?.includes(key)}>
                      {displayValueFunc(key)}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              )}
            </div>
            <div className={classes.buttonsContainer}>
              <CancelButton
                disabled={formikProps.isSubmitting}
                onClick={() => {
                  formikProps.handleReset();
                  setPopoverAnchorEl();
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
              >
                Update
              </Button>
            </div>
          </CardDialog>
        )}
      </Formik>
    </>
  );
}

SelectHoverChangeField.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  validationSchema: PropTypes.object,
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  keys: PropTypes.array.isRequired,
  keysDisabled: PropTypes.array,
  displayValueFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  overrideOnEdit: PropTypes.bool,
  overrideOnClick: PropTypes.bool,
  width: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};

export default withClaim(withStyles(styles)(HoverChangeField));
export { SelectHoverChangeField };
