import React from 'react';
import PropTypes from 'prop-types';
import { escape, noop } from 'lodash';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import SendEmailCommunicationCardContainer from '~/components/communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';
import useDataFetcher from '~/components/useDataFetcher';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import { getEmailText } from '../utils';

const ForwardEmailCommunicationBaseDialog = ({
  communication,
  onClose,
  onSendEmail = noop,
  onForward,
  open,
  disableMinimized,
  onMinimized,
  shouldDisplaySignature = true,
  shouldDisplaySenderEmail = true,
}) => {
  const { user } = useCms();
  const {
    isLoading: isLoadingEmailSignature,
    isError: isErrorEmailSignature,
    data: userSignatureHtmlData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/email_signature`, {
    params: { referred_communication_id: communication.id },
  });
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(communication.claim_id);

  const ccContacts = communication.cc_contacts ? communication.cc_contacts : undefined;
  const attachments = communication.attachments
    .filter((attachment) => !!attachment.document)
    .map((attachment) => ({ ...attachment.document, attachment_filename: attachment.file_name }));

  const userSignatureHtml =
    !isLoadingEmailSignature && shouldDisplaySignature && !isErrorEmailSignature && userSignatureHtmlData
      ? userSignatureHtmlData.user_email_signature_html
      : '';
  const emailHtmlBody = communication.body_html
    ? communication.body_html
    : '<div>' + escape(communication.body_text).replace(/(\r\n|\n)/g, '<br/>') + '</div>';

  let origEmailSender;
  let origEmailTarget;
  const adjusterDetails = `${communication.adjuster} &lt;${communication.adjuster_email}&gt;`;
  const contactDetails = communication?.contact
    ? `${communication.contact.full_name} &lt;${communication.contact_email.email}&gt;`
    : `&lt;${communication.specific_identifier}&gt;`;

  if (communication.direction === 'Outgoing') {
    origEmailSender = adjusterDetails;
    origEmailTarget = contactDetails;
  } else {
    //Incoming
    origEmailSender = contactDetails;
    origEmailTarget = adjusterDetails;
  }

  const emailText = getEmailText({
    userSignatureHtml,
    origEmailSender,
    origEmailTarget,
    communication,
    ccContacts,
    emailHtmlBody,
  });

  const shouldDisplayLoadingDialog = isLoadingEmailSignature || isErrorEmailSignature || isErrorClaim || isLoadingClaim;

  const sendEmailCommunicationCardContainer = (
    <SendEmailCommunicationCardContainer
      onClose={onClose}
      onSendEmail={async () => {
        await onSendEmail();
        onClose();
      }}
      open={open}
      onMinimized={onMinimized}
      disableMinimized={disableMinimized}
      emailText={emailText}
      emailTitle={`Fwd: ${communication.subject}`}
      attachments={attachments}
      onSubmitOverride={
        onForward
          ? async (values) => {
              await onForward(values);
              onClose();
            }
          : null
      }
      isNotClaimRelated={!communication.claim_id}
      referredCommunicationId={communication.id}
      communication={communication}
      shouldDisplaySenderEmail={shouldDisplaySenderEmail}
    />
  );

  return (
    <>
      {shouldDisplayLoadingDialog ? (
        <LoadingDialog track="Forward Email" isError={isErrorClaim || isErrorEmailSignature} />
      ) : communication.claim_id ? (
        <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
          {sendEmailCommunicationCardContainer}
        </ClaimContextProvider>
      ) : (
        sendEmailCommunicationCardContainer
      )}
    </>
  );
};

ForwardEmailCommunicationBaseDialog.propTypes = {
  communication: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendEmail: PropTypes.func,
  onMinimized: PropTypes.func,
  onForward: PropTypes.func,
  open: PropTypes.bool,
  disableMinimized: PropTypes.bool,
  shouldDisplaySignature: PropTypes.bool,
  shouldDisplaySenderEmail: PropTypes.bool,
};

export default ForwardEmailCommunicationBaseDialog;
