import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import MinimizeIcon from '@material-ui/icons/Minimize';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import axios from 'axios';
import { Formik } from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';

import mixpanel from '~/components/CmsMain/mixpanel';
import CommunicationCard, { useCreateCommunication } from '~/components/communications/CommunicationCard';
import type { DirectionType } from '~/components/communications/PhoneCall/types';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { useCms } from '~/components/hooks/useCms';
import useUnloadWindowEventListener from '~/components/hooks/useUnloadWindowEventListener';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import { useCommunicationCallContext } from '../Context/CommunicationCallContext';

import AddParticipantDialog from './AddParticipantDialog';
import CallInProgressAutoSave from './CallInProgressAutoSave';
import CallInProgressParticipantsStatusUpdate from './CallInProgressParticipantsStatusUpdate';
import DeprecatedMinimizedCard from './DeprecatedMinimizedCard';
import DialPadDialog from './DialPadDialog';
import ForwardCallDialog from './ForwardCallDialog';
import PhoneCallCommunicationAction from './PhoneCallCommunicationAction';
import PhoneCallCommunicationSpecificBody from './PhoneCallCommunicationSpecificBody';

interface CallInProgressCardInnerProps {
  isIncoming: boolean;
  onCallHandledAndFinished: () => void;
  openMinimizedDialog: () => void;
  additionalCardActions: ReactNode;
  onMinimized?: () => void;
  disableMinimized?: boolean;
  open?: boolean;
}

interface CallInProgressCardProps {
  isIncoming: boolean;
  onCallHandledAndFinished: () => void;
}

interface PartialFormikValuesProps {
  content: string;
  summary: string;
  exposure_ids: number[];
  claim_id: string;
  is_no_answer: boolean;
  direction: DirectionType;
}

const CallInProgressCardInner: React.FC<CallInProgressCardInnerProps> = (props) => {
  const {
    isIncoming,
    open,
    openMinimizedDialog,
    additionalCardActions,
    onMinimized,
    disableMinimized,
    onCallHandledAndFinished,
  } = props;
  const {
    isMuted,
    isCallActive,
    communication,
    callStatus,
    currentlyClickedDigits,
    callDurationSec,
    handleSendDigit,
    updateCommunication,
    updateMute,
    handleHangup,
  } = useCommunicationCallContext();

  const { userReloadTwilioDetails } = useCms() as { userReloadTwilioDetails: () => void };

  const { communicationCommonInitialValues } = useCreateCommunication('phone_call', {
    exposure_ids: communication.exposure_ids,
    summary: communication.summary,
  });
  const [wereAdditionalParticipantsAdded, setWereAdditionalParticipantsAdded] = useState(false);
  const [participantsDetails, setParticipantsDetails] = useState(communication.additional_conference_participants);
  const [showAddParticipant, setShowAddParticipant] = useState(false);
  const [showForwardCallDialog, setShowForwardCallDialog] = useState(false);
  const [showDialPadDialog, setShowDialPadDialog] = useState(false);
  const [isUpdatingHoldStatus, setIsUpdatingHoldStatus] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const { setDisableWarn } = useUnloadWindowEventListener();

  const direction = isIncoming ? 'Incoming' : 'Outgoing';

  const initialValues = {
    ...communicationCommonInitialValues,
    claim_id: communication.claim_id || '',
    is_no_answer: false,
    content: communication.content || '',
    summary: communication.summary || '',
    direction,
    is_recording_allowed: !!communication.recording_status, // Best effort when attaching to claim the card is reinitialized
  };

  const forwardCall = communication.direction === 'Incoming' && isCallActive && (
    <Tooltip title="Forward Call">
      <IconButton color="primary" onClick={() => setShowForwardCallDialog(true)}>
        <PhoneForwardedIcon />
      </IconButton>
    </Tooltip>
  );

  const handleClickPutOnHold = async () => {
    try {
      setIsUpdatingHoldStatus(true);
      await axios.post(
        `/api/v1/calls/${communication.id}/${isOnHold ? 'remove_participants_from_hold' : 'put_participants_on_hold'}`
      );
      setIsOnHold(!isOnHold);
    } catch (error) {
      await reportAxiosError(error);
    }
    setIsUpdatingHoldStatus(false);
  };

  const handleDismissed = () => {
    setDisableWarn(true);
    onCallHandledAndFinished();
    userReloadTwilioDetails();
  };

  const handleAddParticipant = async (values: PartialFormikValuesProps) => {
    try {
      await axios.post(`/api/v1/calls/${communication.id}/add_conference_participant`, values);
      const result = await axios.get(`/api/v1/communications/${communication.id}`);
      setParticipantsDetails(result.data.additional_conference_participants);
      setWereAdditionalParticipantsAdded(true);
      setShowAddParticipant(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleForwardCall = async (values: PartialFormikValuesProps) => {
    try {
      await axios.post(`/api/v1/calls/${communication.id}/forward_call`, values);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  // Open the "Big" Dialog when the call is finished, the user may minimize again
  useEffect(() => {
    if (!isCallActive) {
      openMinimizedDialog();
    }
  }, [isCallActive, openMinimizedDialog]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        content: Yup.string().required('Required'),
        claim_id: Yup.number().required('Claim must be attached'),
        summary: Yup.string(),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.patch(`/api/v1/communications/${communication.id}`, {
            content: values.content,
            summary: values.summary,
            exposure_ids: values.exposure_ids,
          });

          onCallHandledAndFinished();
          formikProps.setSubmitting(false);
        } catch (error) {
          formikProps.setSubmitting(false);
          await reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        return (
          <>
            <CommunicationCard
              open={open}
              communication={communication}
              communicationInitialFields={{
                communicationChannel: 'phone_call',
                communicationContact: communication.contact,
                communicationDirection: direction,
              }}
              onMinimized={onMinimized}
              disableMinimized={disableMinimized}
              CommunicationTypeSpecificBody={PhoneCallCommunicationSpecificBody}
              communicationTypeSpecificBodyProps={{
                setCommunication: updateCommunication,
                onHangup: handleHangup,
                callStatus,
                onToggleMute: updateMute,
                onClickDialPad: () => setShowDialPadDialog(true),
                isMuted,
                callDurationSec,
                onClickAddParticipant: () => setShowAddParticipant(true),
                participantsDetails,
                onClickPutOnHold: handleClickPutOnHold,
                isOnHold,
                isUpdatingHoldStatus,
              }}
              isNew
              isDialog
              disableSoftClose
              CommunicationAction={PhoneCallCommunicationAction}
              communicationActionProps={{
                callStatus,
                isSubmitting: formikProps.isSubmitting,
                onSubmit: () => {
                  formikProps.handleSubmit();
                },
                onDismiss: handleDismissed,
              }}
              additionalCardActions={
                <>
                  {forwardCall}
                  {additionalCardActions}
                </>
              }
              title={`${direction} phone call`}
            />
            <CallInProgressAutoSave
              values={formikProps.values}
              initialValues={initialValues}
              communication={communication}
            />
            {wereAdditionalParticipantsAdded && isCallActive && (
              <CallInProgressParticipantsStatusUpdate
                communication={communication}
                setParticipantsDetails={setParticipantsDetails}
              />
            )}

            {isCallActive && (
              <>
                {showAddParticipant && (
                  <AddParticipantDialog
                    isClaimCommunication={!!communication.claim_id}
                    onSubmit={handleAddParticipant}
                    onCancel={() => setShowAddParticipant(false)}
                    isOnHold={isOnHold}
                  />
                )}

                {showForwardCallDialog && (
                  <ForwardCallDialog
                    onSubmit={handleForwardCall}
                    participantsDetails={participantsDetails || []}
                    onCancel={() => setShowForwardCallDialog(false)}
                  />
                )}

                {showDialPadDialog && (
                  <DialPadDialog
                    currentlyClickedDigits={currentlyClickedDigits}
                    onClickDigit={handleSendDigit}
                    onClose={() => setShowDialPadDialog(false)}
                  />
                )}
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const CallInProgressCard: React.FC<CallInProgressCardProps> = (props) => {
  const [isCardMinimized, setIsCardMinimized] = useState(false);
  const openMinimizedDialog = useCallback(() => setIsCardMinimized(false), [setIsCardMinimized]);
  const { userOrganization } = useCms() as { userOrganization: unknown };
  const { isCallActive, callDurationSec, communication } = useCommunicationCallContext();

  const isMinimizedDialogsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MINIMIZED_DIALOG_FEATURE
  );

  const seconds = callDurationSec % 60;
  const secString = seconds >= 10 ? String(seconds) : `0${seconds}`;

  return (
    <>
      {!isMinimizedDialogsEnabled ? (
        <CallInProgressCardInner
          {...props}
          additionalCardActions={
            <IconButton onClick={() => setIsCardMinimized(true)}>
              <MinimizeIcon />
            </IconButton>
          }
          open={!isCardMinimized}
          openMinimizedDialog={openMinimizedDialog}
        />
      ) : (
        <MinimizedCallInProgressCard {...props} />
      )}
      {isCardMinimized ? (
        <DeprecatedMinimizedCard
          onClick={() => setIsCardMinimized(false)}
          communication={communication}
          isCallActive={isCallActive}
          callDurationSec={callDurationSec}
          secString={secString}
        />
      ) : null}
    </>
  );
};

const BarHeader: React.FC<{ isCallActive: boolean; callDurationSec: number }> = ({ isCallActive, callDurationSec }) => {
  const seconds = callDurationSec % 60;
  const secString = seconds >= 10 ? String(seconds) : `0${seconds}`;

  return (
    <>
      {isCallActive ? 'Active Phone Call' : 'Phone Call Ended'}{' '}
      <b>
        {Math.floor(callDurationSec / 60)}:{secString}
      </b>
    </>
  );
};

const MinimizedCallInProgressCard = (props: CallInProgressCardProps) => {
  const { add } = useMinimizedDialogs();
  const updateHeaderRef = useRef<(barHeader: ReactNode) => void>(() => noop);

  const { callDurationSec, isCallActive } = useCommunicationCallContext();

  useEffect(() => {
    updateHeaderRef.current(<BarHeader isCallActive={isCallActive} callDurationSec={callDurationSec} />);
  }, [callDurationSec, isCallActive]);

  useEffect(() => {
    const type = 'PHONE';
    const { handleCloseDialog, openDialog, updateHeader } = add({
      barHeader: <BarHeader isCallActive={isCallActive} callDurationSec={callDurationSec} />,
      type,
      dialogComponent: CallInProgressCardInner,
      dialogProps: {
        ...props,
        onCallHandledAndFinished: () => {
          handleCloseDialog();
          props.onCallHandledAndFinished();
        },
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            type,
            isCallActive,
            isIncoming: props.isIncoming,
          });
        },
        openMinimizedDialog: () => openDialog(),
      },
    });
    updateHeaderRef.current = updateHeader;
    // Clean up the dialog when the component unmounts
    return () => {
      handleCloseDialog();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CallInProgressCard;
