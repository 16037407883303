import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';

import ContactTextFieldFormik from '../../ContactTextFieldFormik';
import { Text } from '../../core';
import { ErrorHelperTextFormik } from '../../core/Formik/ErrorHelperTextFormik';
import { TextFieldFormik } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const PaymentPayeeFormik = ({
  contactFieldId,
  label,
  isDisabled,
  acceptedRoles,
  isEmailRequired,
  isPhoneNumberRequired,
  excludedContactIdsList = [],
  fixedSearchResults = false,
  emailFieldId,
  phoneNumberFieldId,
  additionalContactFieldId,
  isDateOfBirthRequired,
  dateOfBirthFieldId,
}) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const selectedContact = getIn(values, contactFieldId);
  const classes = useStyles();

  const handleContactSelectedForField = useCallback(
    (contact, fieldId, options) => {
      if (!contact) {
        setFieldValue(fieldId, '');
        return;
      }

      if (!getIn(values, fieldId) || !options.find((o) => o.id === getIn(values, fieldId))) {
        if (options.length === 1) {
          setFieldValue(fieldId, options[0].id);
        } else {
          setFieldValue(fieldId, '');
        }

        setFieldTouched(fieldId, true);
        if (additionalContactFieldId) {
          setFieldTouched(additionalContactFieldId, true);
        }
      }
    },
    [additionalContactFieldId, setFieldTouched, setFieldValue, values]
  );

  const handleContactSelected = useCallback(
    (contact) => {
      if (isEmailRequired) {
        handleContactSelectedForField(contact, emailFieldId, contact?.emails);
      }

      if (isPhoneNumberRequired) {
        handleContactSelectedForField(contact, phoneNumberFieldId, contact?.phones);
      }
    },
    [emailFieldId, handleContactSelectedForField, isEmailRequired, isPhoneNumberRequired, phoneNumberFieldId]
  );

  useEffect(() => {
    handleContactSelected(selectedContact);
  }, [selectedContact, handleContactSelected]);

  return (
    <>
      <ContactTextFieldFormik
        id={contactFieldId}
        label={label}
        onSelectContact={handleContactSelected}
        acceptedRoles={acceptedRoles}
        fixedSearchResults={fixedSearchResults}
        disabled={isDisabled}
        excludedContactIdsList={excludedContactIdsList}
      />
      {isEmailRequired &&
        (selectedContact && selectedContact.emails.length > 0 ? (
          <TextFieldFormik
            id={emailFieldId}
            disabled={isDisabled || selectedContact.emails.length <= 1}
            className={classes.textFieldRow}
            select
            fullWidth
            showOnly={selectedContact.emails.length <= 1}
          >
            {selectedContact.emails.map((email) => (
              <MenuItem key={email.id} value={email.id}>
                {email.email}
              </MenuItem>
            ))}
          </TextFieldFormik>
        ) : (
          <ErrorHelperTextFormik id={emailFieldId} />
        ))}
      {isPhoneNumberRequired &&
        (selectedContact && selectedContact.phones.length > 0 ? (
          <TextFieldFormik
            id={phoneNumberFieldId}
            className={classes.textFieldRow}
            disabled={isDisabled || selectedContact.phones.length <= 1}
            select
            fullWidth
            showOnly={selectedContact.phones.length <= 1}
          >
            {selectedContact.phones.map((phone) => (
              <MenuItem key={phone.id} value={phone.id}>
                {phone.phone}
              </MenuItem>
            ))}
          </TextFieldFormik>
        ) : (
          <ErrorHelperTextFormik id={phoneNumberFieldId} />
        ))}
      {isDateOfBirthRequired && selectedContact ? (
        <>
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            {selectedContact.date_of_birth ? `Date of birth: ${selectedContact.date_of_birth}` : ``}
          </Text>
          <ErrorHelperTextFormik id={dateOfBirthFieldId} />
        </>
      ) : null}
      {additionalContactFieldId && <ErrorHelperTextFormik id={additionalContactFieldId} />}
    </>
  );
};
PaymentPayeeFormik.propTypes = {
  contactFieldId: PropTypes.string.isRequired,
  additionalContactFieldId: PropTypes.string,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  acceptedRoles: PropTypes.array.isRequired,
  excludedContactIdsList: PropTypes.array,
  fixedSearchResults: PropTypes.bool,
  isEmailRequired: PropTypes.bool.isRequired,
  isPhoneNumberRequired: PropTypes.bool.isRequired,
  emailFieldId: PropTypes.string,
  phoneNumberFieldId: PropTypes.string,
  isDateOfBirthRequired: PropTypes.bool,
  dateOfBirthFieldId: PropTypes.string,
};

export default PaymentPayeeFormik;
