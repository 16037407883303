/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isExpirationAfterEffectiveDT } from '../../PolicyUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { DatePickerTextFieldFormik, TextFieldFormik, TimePickerTextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const autoPolicyDetailsInitialValues = {
  policy_number: '',
  policy_effective_date: '',
  effective_time: '',
  policy_expiration_date: '',
  expiration_time: '',
  insured_contact_id: '',
};

function getPolicyInitialValues(policy) {
  let policyInitialValues = {};

  for (const key of Object.keys(autoPolicyDetailsInitialValues)) {
    policyInitialValues[key] = policy[key];
  }
  policyInitialValues.drivers_list = [];
  for (const namedDriver of policy.named_drivers) {
    policyInitialValues.drivers_list.push({ contact: namedDriver, contact_id: namedDriver.id });
  }
  return policyInitialValues;
}

function getDriversListFromClaim(claim) {
  const drivers_list = claim.contacts
    .filter((contact) => contact.role === 'named_driver')
    .map((contact) => ({ contact, contact_id: contact.id }));

  if (drivers_list.length === 0 && !claim.policy.insured_contact.is_company) {
    drivers_list.push({ contact: claim.policy.insured_contact, contact_id: claim.policy.insured_contact_id });
  }
  return drivers_list;
}

const autoPolicyDetailsValidationSchema = Yup.object().shape({
  policy_number: Yup.string().required('Required'),
  policy_effective_date: Yup.string().required('Required'),
  effective_time: Yup.string()
    .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
    .nullable(),
  policy_expiration_date: Yup.string()
    .required('Required')
    .test(
      'expiration_after_effective_dt',
      'Expiration date must be after effective date',
      isExpirationAfterEffectiveDT
    ),
  expiration_time: Yup.string()
    .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
    .nullable(),
  insured_contact_id: Yup.number().required('Required'),
  drivers_list: Yup.array()
    .min(1, 'Required')
    .of(
      Yup.object().shape({
        contact_id: Yup.number().required('Required'),
        contact: Yup.object().required('Required'),
      })
    ),
});

function ManuallyFillDriversListFormik(props) {
  const { driversList, setFieldValue } = props;
  const { claim } = useClaim();
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        {driversList.map((driver, idx) => (
          <React.Fragment key={driver.contact.gid}>
            <Grid item xs={8}>
              <ContactTextFieldFormik
                id={`drivers_list[${idx}].contact`}
                label={`Driver ${idx + 1}`}
                className={classes.textField}
                acceptedRoles={['named_driver', 'insured']}
                fullWidth
                fixedSearchResults
                excludedContactIdsList={driversList.map((driver) => driver.contact_id)}
                claimId={claim.Id}
                onSelectContact={(selected) =>
                  setFieldValue(
                    'drivers_list',
                    driversList.map((driver, currIdx) =>
                      currIdx === idx ? { contact: selected, contact_id: selected.id } : driver
                    )
                  )
                }
              />
            </Grid>
            <Grid item xs={3}>
              {(idx >= 1 || driversList.length > 1) && (
                <IconButton
                  onClick={() =>
                    setFieldValue(
                      'drivers_list',
                      driversList.filter((_, currIdx) => currIdx !== idx)
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </React.Fragment>
        ))}
        {/* adding fake unique id to be used as key prop inside the map() functions */}
        <Button
          onClick={() => setFieldValue('drivers_list', [...driversList, { contact_id: '', contact: { id: uuidv4() } }])}
        >
          <AddIcon />
          Add Driver
        </Button>
      </Grid>
    </>
  );
}

ManuallyFillDriversListFormik.propTypes = {
  driversList: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

function AutoManualPolicyDetailsDialog(props) {
  const { onClose, onSubmitPolicy, isExistingPolicy } = props;
  const { claim } = useClaim();
  const classes = useStyles();

  const autoManualPolicyDetailsDialogInner = (formikProps) => {
    const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;

    const driversList = values['drivers_list'];

    return (
      <CardDialog isDialog={true} title="Policy Details" maxWidth="sm" onClose={onClose} preventClose={isSubmitting}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldFormik id="policy_number" label="Policy Number" fullWidth className={classes.textField} />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="insured_contact"
              label="Named Insured"
              acceptedRoles={['insured']}
              className={classes.textField}
              fullWidth
              fixedSearchResults
              claimId={claim.Id}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="policy_effective_date"
              label="Effective Date"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="effective_time"
              label="Effective Time"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="policy_expiration_date"
              label="Expiration Date"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="expiration_time"
              label="Expiration Time"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            {' '}
            {/* spacer */}
            <div />
          </Grid>
          <ManuallyFillDriversListFormik driversList={driversList} setFieldValue={setFieldValue} />
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                {isExistingPolicy ? 'Update' : 'Create'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardDialog>
    );
  };

  return (
    <Formik
      initialValues={
        isExistingPolicy
          ? {
              ...getPolicyInitialValues(claim.policy),
              type: 'auto_policy',
              insured_contact_id: claim.policy.insured_contact_id,
            }
          : {
              ...autoPolicyDetailsInitialValues,
              type: 'auto_policy',
              insured_contact_id: claim.policy.insured_contact_id,
              drivers_list: getDriversListFromClaim(claim),
            }
      }
      validationSchema={autoPolicyDetailsValidationSchema}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmitPolicy(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => autoManualPolicyDetailsDialogInner(formikProps)}
    </Formik>
  );
}

AutoManualPolicyDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitPolicy: PropTypes.func.isRequired,
  isExistingPolicy: PropTypes.bool,
};

export default AutoManualPolicyDetailsDialog;
