import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import useCip from '~/components/hooks/useCip';
import { useCms } from '~/components/hooks/useCms';
import useItrak from '~/components/hooks/useItrak';

import { serverDateToLocalMoment } from '../../DateTimeUtils';
import { MGM_LABEL_DICT } from '../../Types';
import { reportAxiosError } from '../../Utils';
import AutocompleteFormik from '../AutocompleteFormik';
import CardDialog from '../CardDialog';
import ClaimFinanceSummaryTable from '../ClaimFinanceSummaryTable';
import { SiuClaimSummaryChip } from '../ClaimSummary';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { ExternalCarrierReferralContainer } from '../ExternalCarrierReferralContainer';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import useOrganization from '../OrganizationContext';
import {
  DatePickerTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const glIncidentDetailsFields = {
  date_of_loss: '',
  time_of_loss: '',
  property_id: '',
  location_id: '',
  cause_code: '',
  source_code: '',
  description: '',
  reporter_contact_id: '',
  primary_contact_id: '',
  itrak_number: '',
  cip_number: '',
  event_type: '',
  claim_subtype: '',
};

const glIncidentDetailsValidationFields = (glIncidentTypesDict, glSourceCodesDict, glCauseCodesDict) => ({
  date_of_loss: Yup.date().required('Required'),
  time_of_loss: Yup.string()
    .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
    .required('Required'),
  involved_claimant: Yup.object().nullable().required('Required'),
  property_id: Yup.number().required('Required'),
  location_id: Yup.number().required('Required'),
  cause_code: Yup.string().required('Required').oneOf(Object.keys(glCauseCodesDict)),
  source_code: Yup.string().required('Required').oneOf(Object.keys(glSourceCodesDict)),
  description: Yup.string().required('Required'),
  reporter_contact_id: Yup.number(),
  primary_contact_id: Yup.number(),
  itrak_number: Yup.string(),
  cip_number: Yup.string(),
  event_type: Yup.string()
    .nullable()
    .when('claim_subtype', (claimSubtype, currSchema) =>
      claimSubtype &&
      glIncidentTypesDict[claimSubtype].type === 'event' &&
      glIncidentTypesDict[claimSubtype].event_type_dict
        ? currSchema.required('Required')
        : currSchema
    ),
});

function GLIncidentDetailsCardFormik({ ItrakComponent, CipComponent }) {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { insuredPropertiesAndLocationsDict, incidentTypesDict, glSourceCodesDict, glCauseCodesDict } =
    useOrganization();
  const classes = useStyles();
  const disabled = isSubmitting;

  const locationCodeDictForProperty =
    values['property_id'] === '' ? {} : insuredPropertiesAndLocationsDict[values['property_id']].sublocations;
  const locationCodeKeysForProperty = Object.keys(locationCodeDictForProperty);

  const glIncidentTypesDict = incidentTypesDict.gl_claim;

  // TODO: remove the filtering once all abbreviations will be valid
  const insuredPropertiesOptions = _.sortBy(
    Object.keys(insuredPropertiesAndLocationsDict),
    (k) => insuredPropertiesAndLocationsDict[k].desc
  ).filter((k) => !!insuredPropertiesAndLocationsDict[k].insured_property_extra.property_abbreviation);
  return (
    <>
      <CardDialog title="Incident Details" outlinedCard>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="date_of_loss"
              label="Date of Loss"
              className={classes.textField}
              fullWidth
              disableFuture
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="time_of_loss"
              label="Time of Loss"
              fullWidth
              className={classes.textField}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <ItrakComponent />
          </Grid>
          {CipComponent && (
            <Grid item xs={6}>
              <CipComponent />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextFieldFormik
              id="claim_subtype"
              select
              label="Type"
              className={classes.textField}
              disabled={disabled}
              fullWidth
            >
              {Object.keys(glIncidentTypesDict).map((option) => (
                <MenuItem key={option} value={option}>
                  {glIncidentTypesDict[option].desc}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          {values.claim_subtype &&
            glIncidentTypesDict[values.claim_subtype].type === 'event' &&
            glIncidentTypesDict[values.claim_subtype].event_type_dict && (
              <Grid item xs={6}>
                <TextFieldFormik
                  id="event_type"
                  select
                  label="Event Type"
                  className={classes.textField}
                  disabled={disabled}
                  fullWidth
                >
                  {Object.keys(glIncidentTypesDict[values.claim_subtype].event_type_dict).map((option) => (
                    <MenuItem key={option} value={option}>
                      {glIncidentTypesDict[values.claim_subtype].event_type_dict[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            )}
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="primary_contact"
              label="Preferred Contact"
              acceptedRoles={['spouse', 'claimant', 'family_member', 'public_adjuster', 'attorney', 'agent', 'other']}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="reporter_contact"
              label="Reporter"
              acceptedRoles={[
                'agent',
                'claimant',
                'spouse',
                'family_member',
                'public_adjuster',
                'insurer',
                'attorney',
                'other',
              ]}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="property_id"
              select
              label="Property"
              className={classes.textField}
              disabled={disabled}
              onChange={(e) => {
                setFieldValue('property_id', e.target.value);
                setFieldValue('location_id', '');
              }}
              fullWidth
            >
              {insuredPropertiesOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {insuredPropertiesAndLocationsDict[option].desc}
                  </MenuItem>
                );
              })}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="location_id"
              label="Location code"
              disabled={disabled || !values.property_id}
              className={classes.textField}
              fullWidth
              options={locationCodeKeysForProperty.filter(
                (k) =>
                  !locationCodeDictForProperty[k].claim_type || locationCodeDictForProperty[k].claim_type === 'gl_claim'
              )}
              getOptionLabel={(option) => locationCodeDictForProperty[option].desc}
              sortAlphabetic
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="cause_code"
              label="Cause code"
              options={Object.keys(glCauseCodesDict)}
              getOptionLabel={(option) => glCauseCodesDict[option].desc}
              sortAlphabetic
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="source_code"
              label="Source code"
              options={Object.keys(glSourceCodesDict)}
              getOptionLabel={(option) => glSourceCodesDict[option].desc}
              sortAlphabetic
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="description"
              label="Incident description"
              className={classes.textField}
              fullWidth
              multiline
              rows="8"
              rowsMax="14"
            />
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
}

GLIncidentDetailsCardFormik.propTypes = {
  ItrakComponent: PropTypes.func.isRequired,
  CipComponent: PropTypes.func.isRequired,
};

const getPossibleUpdatesToSubtype = (claim, incidentTypesDict, user, orgAdditionalConfigs) => {
  if (!claim.subtype) {
    return;
  }

  const currSubtypeOptions = incidentTypesDict.gl_claim[claim.subtype];
  const hasSuperOptions = currSubtypeOptions.supervisor_possible_conversions;
  const userIsSuper =
    orgAdditionalConfigs.gl_subtype_change_approved_users &&
    orgAdditionalConfigs.gl_subtype_change_approved_users.includes(user.id);

  const zeroPaymentsAndRecoveries = !claim.exposures.find(
    ({ expenses, indemnity, subro_recoveries_sum, salvage_recoveries_sum }) =>
      expenses.paid_sum || indemnity.paid_sum || subro_recoveries_sum || salvage_recoveries_sum
  );

  if (userIsSuper && hasSuperOptions && zeroPaymentsAndRecoveries) {
    return currSubtypeOptions.supervisor_possible_conversions;
  }

  return currSubtypeOptions.possible_conversions;
};

function GLIncidentDetailsCard(props) {
  const classes = useStyles();
  const { insuredPropertiesAndLocationsDict, glSourceCodesDict, glCauseCodesDict, orgAdditionalConfigs } =
    useOrganization();
  const { claim, onUpdate, readOnly } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [showPropertyLocationUpdateDialog, setShowPropertyLocationUpdateDialog] = useState(false);
  const { incidentTypesDict } = useOrganization();
  const { user } = useCms();
  const glIncidentTypesDict = incidentTypesDict.gl_claim;
  const possibleUpdatesToSubtype = getPossibleUpdatesToSubtype(claim, incidentTypesDict, user, orgAdditionalConfigs);

  const { incident } = claim;
  const disabled = readOnly || isFetching;

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };
  const { isCipAutofillEnabled } = useCip({ claimType: useCip.CLAIM_TYPE.gl_claim });
  const { isItrakAutofillEnabled } = useItrak({ claimType: useItrak.CLAIM_TYPE.gl_claim });

  const handleUpdateFields = async (updateObject) => {
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/gl_claims/${claim.id}`, updateObject);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsFetching(false);
  };

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && (
            <IncidentMoreActionsContainer
              incident={incident}
              onUpdateFields={handleUpdateIncidentFields}
              includeIsMultiLoss
              includesMarkAsUmbrella
            />
          )
        }
      >
        {incident.is_multi_loss && (
          <Chip
            size="small"
            color="primary"
            label={`Multi Loss Incident ${incident.multi_loss_name}`}
            className={classes.chip}
          />
        )}
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        {claim.claimant_party.involved_person.attorney_contact_id && (
          <Chip size="small" color="primary" label="Atty Rep" className={classes.chip} />
        )}
        {incident.is_umbrella && <Chip size="small" color="primary" label="Umbrella" className={classes.chip} />}
        {incident.labeled_criteria && (
          <Chip
            size="small"
            color="primary"
            label={MGM_LABEL_DICT[claim.type][incident.labeled_criteria]?.desc}
            className={classes.chip}
          />
        )}
        <IncidentMutualChips claim={claim} />
        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.claimant_party.involved_person.contact_id}
              contactDisplayName={claim.claimant_party.involved_person.contact_full_name}
              label="Claimant"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocalMoment(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectHoverChangeField
              label="Type"
              fieldId="type"
              value={claim.subtype}
              keys={possibleUpdatesToSubtype}
              displayValueFunc={(key) => glIncidentTypesDict[key].desc}
              disabled={disabled}
              onUpdate={async (values) => await handleUpdateClaimField('subtype', values.type)}
              width="300px"
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={glIncidentTypesDict[claim.subtype].desc}
                label="Type"
                disabled={disabled}
              />
            </SelectHoverChangeField>
          </Grid>
          {claim.subtype &&
            glIncidentTypesDict[claim.subtype].type === 'event' &&
            glIncidentTypesDict[claim.subtype].event_type_dict && (
              <Grid item xs={6}>
                <SelectHoverChangeField
                  label="Event Type"
                  fieldId="event_type"
                  value={claim.incident.event_type}
                  keys={Object.keys(glIncidentTypesDict[claim.subtype].event_type_dict)}
                  displayValueFunc={(key) => glIncidentTypesDict[claim.subtype].event_type_dict[key].desc}
                  disabled={disabled}
                  onUpdate={async (values) => await handleUpdateIncidentField('event_type', values.event_type)}
                  width="300px"
                >
                  <ShowOnlyTextField
                    classes={classes}
                    showOnlyValueComponent={
                      claim.incident.event_type
                        ? glIncidentTypesDict[claim.subtype].event_type_dict[claim.incident.event_type].desc
                        : ''
                    }
                    label="Event Type"
                    disabled={disabled}
                  />
                </SelectHoverChangeField>
              </Grid>
            )}
          <Grid item xs={6}>
            <HoverChangeField
              name="itrak_number"
              value={claim.incident.itrak_number || ''}
              label="ITrak number"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('itrak_number', updatedValues['itrak_number'])
              }
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.itrak_number}
                label="ITrak number"
              />
            </HoverChangeField>
          </Grid>
          {isCipAutofillEnabled && !isItrakAutofillEnabled && (
            <Grid item xs={6}>
              <HoverChangeField
                name="cip_number"
                value={claim.incident.cip_number || ''}
                label="CIP number"
                onUpdate={async (updatedValues) =>
                  await handleUpdateIncidentField('cip_number', updatedValues['cip_number'])
                }
                disabled={disabled}
              >
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={claim.incident.cip_number}
                  label="CIP number"
                />
              </HoverChangeField>
            </Grid>
          )}
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={insuredPropertiesAndLocationsDict[claim.incident.insured_property_id]?.desc}
              label="Property"
              onEdit={disabled ? undefined : () => setShowPropertyLocationUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                insuredPropertiesAndLocationsDict[claim.incident.insured_property_id]?.sublocations[
                  claim.incident.insured_property_sublocation_id
                ]?.desc
              }
              label="Location code"
              onEdit={disabled ? undefined : () => setShowPropertyLocationUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectHoverChangeField
              label="Cause code"
              fieldId="cause_code"
              value={claim.incident.cause_code}
              keys={Object.keys(glCauseCodesDict)}
              displayValueFunc={(key) => glCauseCodesDict[key]['desc']}
              onUpdate={async (values) => await handleUpdateIncidentField('cause_code', values.cause_code)}
              width="300px"
              overrideOnEdit
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={glCauseCodesDict[claim.incident.cause_code]?.desc || claim.incident.cause_code}
                label="Cause code"
              />
            </SelectHoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <SelectHoverChangeField
              label="Source code"
              fieldId="source_code"
              value={claim.incident.source_code}
              keys={Object.keys(glSourceCodesDict)}
              displayValueFunc={(key) => glSourceCodesDict[key]['desc']}
              onUpdate={async (values) => await handleUpdateIncidentField('source_code', values.source_code)}
              width="300px"
              overrideOnEdit
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  glSourceCodesDict[claim.incident.source_code]?.desc || claim.incident.source_code
                }
                label="Source code"
              />
            </SelectHoverChangeField>
          </Grid>
          <Grid item xs={12}>
            <ExternalCarrierReferralContainer
              disabled={disabled}
              onUpdate={onUpdate}
              track="GL external carrier referral email"
              title="External Carrier Referral"
            />
          </Grid>
          <Grid item xs={12}>
            <ClaimFinanceSummaryTable />
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={8}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="12em" // ~ 8 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>
      {showPropertyLocationUpdateDialog && (
        <PropertyAndLocationUpdateDialog
          property_id={claim.incident.insured_property_id}
          location_id={claim.incident.insured_property_sublocation_id}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowPropertyLocationUpdateDialog(false)}
        />
      )}
    </>
  );
}

GLIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

function PropertyAndLocationUpdateDialog({ property_id, location_id, onUpdate, onClose }) {
  const classes = useStyles();
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  // TODO: remove the filtering once all abbreviations will be valid
  const insuredPropertiesOptions = _.sortBy(
    Object.keys(insuredPropertiesAndLocationsDict),
    (k) => insuredPropertiesAndLocationsDict[k].desc
  ).filter((k) => !!insuredPropertiesAndLocationsDict[k].insured_property_extra.property_abbreviation);

  return (
    <Formik
      initialValues={{ property_id, location_id }}
      validationSchema={Yup.object().shape({
        property_id: Yup.string().required('Required'),
        location_id: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        if (values.property_id === property_id && values.location_id === location_id) {
          await onClose();
          return;
        }

        await onUpdate(values);
        await onClose();
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const propertyId = values['property_id'];
        const locationCodeDictForProperty =
          propertyId === '' ? {} : insuredPropertiesAndLocationsDict[propertyId].sublocations;
        const locationCodeKeysForProperty = Object.keys(locationCodeDictForProperty);

        return (
          <CardDialog
            isDialog={true}
            open
            title="Edit Property & Location"
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="property_id"
                  select
                  label="Property"
                  className={classes.textField}
                  disabled={isSubmitting}
                  onChange={(e) => {
                    setFieldValue('property_id', e.target.value);
                    setFieldValue('location_id', '');
                  }}
                  fullWidth
                >
                  {insuredPropertiesOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {insuredPropertiesAndLocationsDict[option].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={6}>
                <AutocompleteFormik
                  id="location_id"
                  label="Location code"
                  disabled={isSubmitting}
                  className={classes.textField}
                  fullWidth
                  options={locationCodeKeysForProperty.filter(
                    (k) =>
                      !locationCodeDictForProperty[k].claim_type ||
                      locationCodeDictForProperty[k].claim_type === 'gl_claim'
                  )}
                  getOptionLabel={(option) => locationCodeDictForProperty[option].desc}
                  sortAlphabetic
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

PropertyAndLocationUpdateDialog.propTypes = {
  open: PropTypes.bool,
  property_id: PropTypes.number.isRequired,
  location_id: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export {
  GLIncidentDetailsCard,
  GLIncidentDetailsCardFormik,
  glIncidentDetailsFields,
  glIncidentDetailsValidationFields,
};
