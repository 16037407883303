import React, { useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';

import { useClaim } from '~/components/ClaimContainer';
import type { PickerDate } from '~/components/core/Molecules/Fields/DatePickerField/DatePickerField';
import DatePickerField from '~/components/core/Molecules/Fields/DatePickerField/DatePickerField';
import MultiSelectTextField from '~/components/core/Molecules/Fields/MultiSelectField/MultiSelectField';
import SingleSelectTextField from '~/components/core/Molecules/Fields/SingleSelectField/SingleSelectField';
import TextField from '~/components/core/Molecules/Fields/TextField/TextField';
import ToggleButtonGroup from '~/components/core/Molecules/Fields/ToggleButtonGroup/ToggleButtonGroup';
import { MainCard } from '~/components/core/NewDesignSystem/Cards/MainCard/MainCard';
import ExposuresLabelFilter from '~/components/exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from '~/components/exposures/ExposureUtils';
import { useCms } from '~/components/hooks/useCms';
import { NOTE_SUBJECT } from '~/Types';
import { reportAxiosError } from '~/Utils';

const NOTE_TYPE_OPTIONS = [
  { key: 'all', value: 'all', label: 'All' },
  { key: 'automatic', value: 'automatic', label: 'Automatic' },
  { key: 'manual', value: 'manual', label: 'Manual' },
];

type NoteType = 'all' | 'automatic' | 'manuel';

interface NotesFiltersValues {
  exposures: number[];
  note_type: NoteType;
  free_text_search?: string;
  note_subject?: (keyof typeof NOTE_SUBJECT)[];
  adjuster?: string;
  start_date?: PickerDate;
  end_date?: PickerDate;
}

interface NotesFilterProps {
  hideTypeFilter?: boolean;
  exposureOptionsIds?: number[];
  onSearch: (currentFilters: NotesFiltersValues) => void;
}

const NotesFilter: React.FC<NotesFilterProps> = ({ exposureOptionsIds, onSearch }) => {
  const { claim } = useClaim();
  const { user } = useCms() as { user: unknown };
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedNoteType, setSelectedNoteType] = useState<NoteType>('all');
  const [freeTextSearch, setFreeTextSearch] = useState<string>('');
  const [selectedNoteSubjects, setSelectedNoteSubjects] = useState<(keyof typeof NOTE_SUBJECT)[]>([]);
  const [selectedAdjuster, setSelectedAdjuster] = useState<string>();
  const [selectedStartDate, setSelectedStartDate] = useState<PickerDate>();
  const [selectedEndDate, setSelectedEndDate] = useState<PickerDate>();
  const [selectedExposureIds, setSelectedExposureIds] = useState<number[]>(
    exposureOptionsIds ?? getUserRelatedExposuresId(user, claim)
  );

  useEffect(() => {
    const onSearchAsync = async () => {
      try {
        setIsSearching(true);
        onSearch({
          exposures: selectedExposureIds,
          note_type: selectedNoteType,
          free_text_search: freeTextSearch,
          note_subject: selectedNoteSubjects,
          adjuster: selectedAdjuster,
          start_date: selectedStartDate,
          end_date: selectedEndDate,
        });
      } catch (error) {
        await reportAxiosError(error);
      } finally {
        setIsSearching(false);
      }
    };

    if (!isSearching) {
      onSearchAsync();
    }
  }, [
    selectedNoteType,
    selectedNoteSubjects,
    freeTextSearch,
    selectedAdjuster,
    selectedStartDate,
    selectedEndDate,
    selectedExposureIds,
    onSearch,
    isSearching,
  ]);

  return (
    <div className="mb-30">
      <div className="mb-30 mt-10 flex justify-between">
        <div>
          <ExposuresLabelFilter
            exposureOptionsIds={exposureOptionsIds}
            filterList={selectedExposureIds}
            onUpdateFiltered={setSelectedExposureIds}
          />
        </div>
        <div>
          <ToggleButtonGroup
            value={selectedNoteType}
            onChange={(e, newValue) => setSelectedNoteType(newValue as NoteType)}
            options={NOTE_TYPE_OPTIONS}
          />
        </div>
      </div>
      <MainCard type="filled" collapsible title="Search and Filter" openByDefault>
        <div className="grid grid-cols-2 gap-x-20 gap-y-30">
          <div>
            <TextField
              id="free_text_search"
              label="Search"
              fullWidth
              disabled={isSearching}
              onChange={(newValue) => {
                setFreeTextSearch(newValue as string);
              }}
            />
          </div>
          <div>
            <MultiSelectTextField
              id="note_subject"
              label="Note Type"
              disabled={isSearching}
              options={Object.keys(NOTE_SUBJECT)}
              renderValue={(selectedSubjectKeys: unknown) => {
                const selectedSubjectKeysCast = selectedSubjectKeys as unknown as (keyof typeof NOTE_SUBJECT)[];
                return selectedSubjectKeysCast
                  .map((selectedSubjectKey) => NOTE_SUBJECT[selectedSubjectKey].display)
                  .join(', ');
              }}
              renderOption={(subjectKey: unknown) => {
                const castSubjectKey = subjectKey as keyof typeof NOTE_SUBJECT;
                return NOTE_SUBJECT[castSubjectKey].display;
              }}
              fullWidth
              sortAlphabetic
              onChange={setSelectedNoteSubjects as (newValues: string[]) => void}
              value={selectedNoteSubjects}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-x-20 gap-y-30">
          <div>
            <SingleSelectTextField
              id="adjuster"
              label="Adjuter"
              fullWidth
              disabled={isSearching}
              value={selectedAdjuster}
              onChange={setSelectedAdjuster as (newValues: unknown) => void}
            >
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
            </SingleSelectTextField>
          </div>
          <div>
            <DatePickerField
              value={selectedStartDate}
              onChange={(date) => setSelectedStartDate(date)}
              label="Start Date"
              disableFuture
              fullWidth
              disabled={isSearching}
              clearable
            />
          </div>
          <div>
            <DatePickerField
              value={selectedEndDate}
              onChange={(date) => setSelectedEndDate(date)}
              label="End Date"
              disableFuture
              fullWidth
              disabled={isSearching}
              clearable
            />
          </div>
        </div>
      </MainCard>
    </div>
  );
};

export default NotesFilter;
