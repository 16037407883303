import React, { useEffect, useMemo, useState } from 'react';
import { Link, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { CONSTANTS, COUNTRIES_DICT } from '../../Types';
import { isQoverUser } from '../../Utils';
import ClaimsTable from '../ClaimsTable';
import { getAllOrganizationWideRoles } from '../communications/ContactUtils';
import ContactSearchContainerOutOfClaim from '../ContactSearch/ContactSearchContainerOutOfClaim';
import { useCms } from '../hooks/useCms';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

export const ClaimScreenDefaultRowsPerPage = 15;

const DEFAULT_SORT_BY_COLUMN = { id: 'date_of_loss' };

const INITIAL_SEARCH_FILTER = {
  status: 'all',
  fileOwnerIds: [],
  country: 'all',
  product: 'all',
  partner: 'all',
  selectedContact: '',
  sortByColumn: DEFAULT_SORT_BY_COLUMN,
  page: 0,
  rowsPerPage: ClaimScreenDefaultRowsPerPage,
};

// TODO - migrate the ClaimsSearchScreen to use this class
function QoverClaimsPageContainer() {
  const { user, setPageTitle } = useCms();
  const { organizationContactRolesDict } = useOrganization();
  const classes = useStyles();

  useEffect(() => setPageTitle('Claims', 'Claims - Five Sigma CMS'), [setPageTitle]);

  const [searchClaimsDetails, setSearchClaimsDetails] = useState(INITIAL_SEARCH_FILTER);

  const { status, fileOwnerIds, product, partner, country, selectedContact, sortByColumn, page, rowsPerPage } =
    searchClaimsDetails;

  const searchRequestParams = useMemo(
    () => ({
      params: {
        status: status === 'all' ? '' : status,
        handling_adjuster: fileOwnerIds,
        contact_id: selectedContact?.id,
        page: page + 1, // page in the flask-SQLAlchemy is 1-based
        rows_per_page: rowsPerPage,
        sort_by_column: sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`, // +<colName> for ascending, -<colName> for descending
        partner: partner === 'all' ? '' : partner,
        product: product === 'all' ? '' : product,
        country: country === 'all' ? '' : country,
      },
    }),
    [status, fileOwnerIds, product, partner, country, selectedContact, sortByColumn, page, rowsPerPage]
  );

  const {
    isLoading: isLoadingClaims,
    isError: isErrorClaims,
    data: claimsResults,
  } = useDataFetcher('/api/v1/claims', searchRequestParams);
  const {
    isLoading: isLoadingSearchClaimProps,
    isError: isErrorSearchClaimProps,
    data: searchClaimProps,
  } = useDataFetcher('/api/v1/claims/search_claim_props');

  if ((isLoadingClaims || isLoadingSearchClaimProps) && !(claimsResults && searchClaimProps)) {
    return <LoadingIndicator isError={isErrorClaims || isErrorSearchClaimProps} />;
  }

  const {
    total_claims_count: allClaimsCount,
    possible_adjusters: possibleAdjusters,
    partners,
    products,
    countries,
  } = searchClaimProps;

  const updateFilterParam = (filterParamName, filterParamValue, resetSort = true) => {
    setSearchClaimsDetails((searchClaimsDetails) => ({
      ...searchClaimsDetails,
      [filterParamName]: filterParamValue,
      sortByColumn: resetSort ? DEFAULT_SORT_BY_COLUMN : searchClaimsDetails.sortByColumn,
      page: 0,
    }));
  };

  const { claims, count: foundClaimsCount } = claimsResults;
  const possibleFileOwners = [{ username: 'Unassigned FNOLs', id: null }, ...possibleAdjusters];
  const possibleFileOwnersById = possibleFileOwners.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

  return (
    <>
      <div className={classes.cardDivRow}>
        <Grid container>
          <TextField
            id="file_owners"
            label="File / Exposure owner"
            className={classes.textFieldRow}
            value={fileOwnerIds}
            onChange={(event) =>
              updateFilterParam('fileOwnerIds', event.target.value.includes('All') ? [] : event.target.value)
            }
            size="small"
            select
            SelectProps={{
              multiple: true,
              renderValue: (selectedIds) =>
                selectedIds.map((selectedId) => possibleFileOwnersById[selectedId].username).join(', '),
            }}
          >
            <MenuItem value="All">
              <Checkbox checked={fileOwnerIds.length === 0} />
              <ListItemText primary="All" />
            </MenuItem>
            {possibleFileOwners.map((fileOwner) => (
              <MenuItem key={fileOwner.id} value={fileOwner.id}>
                <Checkbox checked={fileOwnerIds.indexOf(fileOwner.id) > -1} />
                <ListItemText primary={fileOwner.username} />
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="status"
            label="Status"
            className={classes.textFieldRow}
            value={status}
            onChange={(e) => updateFilterParam('status', e.target.value)}
            size="small"
            select
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </TextField>

          <ContactSearchContainerOutOfClaim
            disallowNew
            onSelectContact={(newContact) => updateFilterParam('selectedContact', newContact)}
            selectedContactId={selectedContact ? selectedContact.id : undefined}
            selectedContactDisplayName={selectedContact ? selectedContact.full_name : undefined}
            TextFieldProps={{
              size: 'small',
              label: 'Filter by Contact',
              className: classes.textFieldRow,
            }}
            acceptedRoles={getAllOrganizationWideRoles(organizationContactRolesDict)}
          />

          <TextField
            id="product"
            label="Product"
            className={classes.textFieldRow}
            value={product}
            onChange={(e) => updateFilterParam('product', e.target.value)}
            size="small"
            select
          >
            <MenuItem value="all">All</MenuItem>

            {products.map((product) => (
              <MenuItem key={product} value={product}>
                {product}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="partner"
            label="Partner"
            className={classes.textFieldRow}
            value={partner}
            onChange={(e) => updateFilterParam('partner', e.target.value)}
            size="small"
            select
          >
            <MenuItem value="all">All</MenuItem>

            {partners.map((partner) => (
              <MenuItem key={partner} value={partner}>
                {partner}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="country"
            label="Country"
            className={classes.textFieldRow}
            value={country}
            onChange={(e) => updateFilterParam('country', e.target.value)}
            size="small"
            select
          >
            <MenuItem value="all">All</MenuItem>

            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {COUNTRIES_DICT[country]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </div>

      <div className={classes.cardDivRow}>
        <span
          className={classes.textFieldRow}
        >{`${foundClaimsCount} out of ${allClaimsCount} claims match the selected filters`}</span>
        <Link
          href=""
          onClick={(e) => {
            e.preventDefault();
            setSearchClaimsDetails(INITIAL_SEARCH_FILTER);
          }}
          className={classes.textFieldRow}
        >
          Reset filters
        </Link>
      </div>
      <ClaimsTable
        claims={claims}
        openInNewTab
        hideLastTouchedDate
        hideReportedDate={isQoverUser(user)}
        paginationProps={{
          page,
          rowsPerPage,
          onChangePage: (_, newPage) =>
            setSearchClaimsDetails((searchClaimsDetails) => ({ ...searchClaimsDetails, page: newPage })),
          count: foundClaimsCount,
          onChangeRowsPerPage: (e) => updateFilterParam('rowsPerPage', parseInt(e.target.value), false),
          rowsPerPageOptions: [10, ClaimScreenDefaultRowsPerPage, CONSTANTS.DEFAULT_CLAIMS_PER_PAGE, 30],
        }}
        onSortByColumn={(_, sortByColumn) =>
          setSearchClaimsDetails((searchClaimsDetails) => ({
            ...searchClaimsDetails,
            sortByColumn,
            page: 0,
          }))
        }
      />
    </>
  );
}

export default QoverClaimsPageContainer;
