import type { ReactNode } from 'react';

import cn from '../../../../Utils/cn';

export type CardType = 'contained' | 'filled' | 'elevated';

export interface TitleProps {
  variant: 'XL' | 'LG' | 'SM' | 'XS' | 'XXS';
  weight: 'REGULAR' | 'MEDIUM' | 'SEMI_BOLD';
  colorVariant: 'PRIMARY' | 'SECONDARY' | 'LINK' | 'BUTTON_LINK' | 'DISABLED' | 'BLACK' | 'NOTICE';
}

export interface CollapsibleCardProps {
  collapsible?: boolean;
  isOpen?: boolean;
  openByDefault?: boolean;
  onCollapseClick?: (isOpen: boolean) => void;
}

export interface CardBaseExternalProps {
  title?: string;
  action?: ReactNode;
}

type CardPadding = 15 | 20 | 24;
type CardContentGap = 5 | 10 | 15 | 20;

export interface CardBaseStyleProps {
  paddingX: CardPadding;
  paddingY: CardPadding;
  shadow: boolean;
  border: boolean;
  contentGap: CardContentGap;
  backgroundColor: string;
}

const ContentGapToClassMap: Record<CardContentGap, (hasTitle: boolean) => string> = {
  5: (hasTitle: boolean) => cn('gap-5', { 'pt-5': hasTitle }),
  10: (hasTitle: boolean) => cn('gap-10', { 'pt-10': hasTitle }),
  15: (hasTitle: boolean) => cn('gap-15', { 'pt-15': hasTitle }),
  20: (hasTitle: boolean) => cn('gap-20', { 'pt-20': hasTitle }),
};

export const getCardContentContainerSpacingClass = (contentGap: CardContentGap, hasTitle: boolean): string => {
  return ContentGapToClassMap[contentGap](hasTitle);
};

const PaddingXToClassMap: Record<CardPadding, string> = {
  15: 'px-15',
  20: 'px-20',
  24: 'px-24',
};

const PaddingYToClassMap: Record<CardPadding, string> = {
  15: 'py-15',
  20: 'py-20',
  24: 'py-24',
};

export const getCardPaddingClasses = (paddingX: CardPadding, paddingY: CardPadding): string => {
  return `${PaddingXToClassMap[paddingX]} ${PaddingYToClassMap[paddingY]}`;
};

export const CardTypeToStyleAttributesMap: Record<
  CardType,
  Pick<CardBaseStyleProps, 'shadow' | 'border' | 'backgroundColor'>
> = {
  elevated: {
    shadow: true,
    border: false,
    backgroundColor: 'bg-white',
  },
  contained: {
    shadow: false,
    border: true,
    backgroundColor: 'bg-transparent',
  },
  filled: {
    shadow: false,
    border: false,
    backgroundColor: 'bg-slate-200',
  },
};
