import React, { useEffect, useState } from 'react';
import { MenuItem, Snackbar, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { COMMUNICATION_CHANNEL_DICT } from '~/Types';

import CardDialog from '../CardDialog';
import { useCms } from '../hooks/useCms';
import useDataFetcher from '../useDataFetcher';

import CommunicationsTable from './CommunicationsTable';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

export default function AdjusterCommunications() {
  const classes = useStyles();
  const { user, setPageTitle } = useCms();
  const [selectedCommunicationId, setSelectedCommunicationId] = useState();
  const [communicationChannelFilter, setCommunicationChannelFilter] = useState('any');
  const [page, setPage] = useState(0);

  const COMMS_PER_PAGE = 20;

  useEffect(() => setPageTitle('Communications', 'Adjuster Communications - Five Sigma CMS'), [setPageTitle]);
  const {
    isLoading,
    isError,
    data: communicationResult = {},
    reloadData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/users/${user.id}/communications`, {
    params: {
      page: page + 1, // page in the flask-SQLAlchemy is 1-based,
      rows_per_page: COMMS_PER_PAGE,
      channel_type: communicationChannelFilter !== 'any' ? communicationChannelFilter : undefined,
    },
  });

  const displayLoading = isLoading && isEmpty(communicationResult);

  const { communications = [], count = 0, available_channels: availableChannels = [] } = communicationResult;

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <div style={{ padding: 8 }}>
          <TextField
            id="channel"
            label="Filter Communication Type"
            className={classes.textFieldRow}
            value={communicationChannelFilter}
            onChange={(event) => {
              setPage(0);
              setCommunicationChannelFilter(event.target.value || '');
            }}
            size="small"
            select
          >
            <MenuItem key="none" value="any">
              Any Channel
            </MenuItem>
            {availableChannels.map((channel) => (
              <MenuItem key={channel} value={channel}>
                {COMMUNICATION_CHANNEL_DICT[channel]}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item md={selectedCommunicationId ? 8 : 12}>
        {displayLoading && (
          <SkeletonTable rowsCount={COMMS_PER_PAGE} columnsCount={5} maxHeight="45vh" isError={isError} />
        )}
        {!displayLoading && (
          <CommunicationsTable
            communications={communications}
            selectedCommunicationId={selectedCommunicationId}
            onSelectCommunication={(communicationId) => setSelectedCommunicationId(communicationId)}
            paginationProps={{
              page,
              rowsPerPage: COMMS_PER_PAGE,
              onChangePage: (_, newPage) => setPage(newPage),
              count: count || 0,
              rowsPerPageOptions: [COMMS_PER_PAGE],
            }}
            removeExposuresColumn
            removeAdjusterColumn
            addClaimColumn
            disableSortByUser
          />
        )}
      </Grid>
      {selectedCommunicationId && (
        <Grid item md={4}>
          <CardDialog
            headerStyle={{ padding: '0' }}
            contentStyle={{ padding: '8px 0 0 0' }}
            containerStyle={{ marginLeft: '16px', maxHeight: `${window.innerHeight - 200}px`, overflow: 'auto' }}
            noCardTitle
          >
            <ViewCommunicationCardContainer
              key={selectedCommunicationId}
              communicationId={selectedCommunicationId}
              onUpdate={reloadData}
              displayAttachClaim={false}
            />
          </CardDialog>
        </Grid>
      )}
      {isLoading && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open message="Loading..." />}
    </Grid>
  );
}
