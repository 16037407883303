import React from 'react';

interface WorkbookPageHeaderProps {
  headerComponent: React.ReactNode;
  actionsComponent: React.ReactNode;
}

export const WorkbookPageHeaderLayout: React.FC<WorkbookPageHeaderProps> = ({ headerComponent, actionsComponent }) => {
  return (
    <div className="--HEADER-- flex w-full items-center gap-10 px-20">
      <div className="flex flex-col gap-10 py-20">{headerComponent}</div>
      <div className="--ACTIONS-BTN-CONTAINER-- ml-auto">{actionsComponent}</div>
    </div>
  );
};
