import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import { CONFIGURATION_FEATURES_NAMES, CONSTANTS } from '../Types';
import { formatSortOrder, isFeatureEnabled } from '../Utils';

import { useCms } from './hooks/useCms';
import AutocompleteFormik from './AutocompleteFormik';
import CardDialog from './CardDialog';
import ClaimsTable from './ClaimsTable';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import StateAutocompleteFormik from './StateAutocompleteFormik';
import TextFieldFormik, { DatePickerTextFieldFormik } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

const DEFAULT_SORT_BY_COLUMN = { id: 'date_of_loss' };
const DEFAULT_CLAIMS_PER_PAGE = 15;
const FILTER_PARAMS_INITIAL_VALUES = {
  handling_adjuster: [],
  page: 0,
  rows_per_page: DEFAULT_CLAIMS_PER_PAGE,
  sort_by_column: DEFAULT_SORT_BY_COLUMN,
  status: '',
  lob: 'all',
  claim_date_of_loss_start_date: '',
  claim_date_of_loss_end_date: '',
  incident_type: '',
  loss_location: '',
  claim_number: '',
  claimant_first_name: '',
  claimant_last_name: '',
  claimant_ssn: '',
  claimant_state: '',
  claimant_department: '',
};

const MgmAdvancedSearchPage = () => {
  const classes = useStyles();
  const { setPageTitle } = useCms();
  const [showResults, setShowResults] = useState(false);
  const { data: allClaimsCount } = useDataFetcher('/api/v1/claims/count');
  const [filterParams, setFilterParams] = useState(FILTER_PARAMS_INITIAL_VALUES);
  const { page, sort_by_column } = filterParams;
  const config = { params: { ...filterParams, page: page + 1, sort_by_column: formatSortOrder(sort_by_column) } }; // page in the flask-SQLAlchemy is 1-based
  const { isLoading, data: { count, claims } = {} } = useDataFetcher('/api/v1/claims/mgm_advanced_search', config);
  const { data: adjustersData = [] } = useDataFetcher('/api/v1/users/possible_adjusters', {});

  useEffect(() => setPageTitle('Advanced Search', 'Advanced Search - Five Sigma CMS'), [setPageTitle]);

  const onSubmit = (values) => {
    if (values.handling_adjuster?.length > 1) {
      values.handling_adjuster = values.handling_adjuster.filter((adjuster_id) => adjuster_id !== 'All');
    }

    setShowResults(true);
    setFilterParams(values);
  };

  const onReset = (values) => {
    setShowResults(false);
    setFilterParams(values);
  };

  const onChangePage = (_, newPage) => {
    setFilterParams({
      ...filterParams,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (event) => {
    setFilterParams({
      ...filterParams,
      rows_per_page: parseInt(event.target.value),
      page: 0,
    });
  };

  const onSortByColumn = (_, sort_by_column) => {
    setFilterParams({
      ...filterParams,
      sort_by_column,
      page: 0, // reset the page when sorting
    });
  };

  return (
    <>
      <div className={classes.cardDivRow}>
        <AdvancedClaimsSearchDialog onSubmit={onSubmit} onReset={onReset} adjustersData={adjustersData} />
      </div>
      {isLoading && showResults && <LoadingIndicator />}
      {showResults && (
        <>
          <div className={classes.cardDivRow}>
            {!!count && (
              <span
                className={classes.textFieldRow}
              >{`${count} out of ${allClaimsCount} claims match the selected filters`}</span>
            )}
          </div>
          {!isEmpty(claims) && (
            <ClaimsTable
              claims={claims}
              openInNewTab
              hideLastTouchedDate
              paginationProps={{
                page,
                rowsPerPage: filterParams?.rows_per_page,
                onChangePage,
                count,
                onChangeRowsPerPage,
                rowsPerPageOptions: [10, DEFAULT_CLAIMS_PER_PAGE, CONSTANTS.DEFAULT_CLAIMS_PER_PAGE, 30],
              }}
              onSortByColumn={onSortByColumn}
            />
          )}
        </>
      )}
    </>
  );
};

MgmAdvancedSearchPage.propTypes = {
  onReportNewFnol: PropTypes.func.isRequired,
};

const AdvancedClaimsSearchDialog = ({ onSubmit, onReset, adjustersData }) => {
  const { userOrganization } = useCms();
  const classes = useStyles();
  const { incidentTypesDict, insuredPropertiesAndLocationsDict } = useOrganization();
  const possibleFileOwners = useMemo(
    () => [{ username: 'Unassigned FNOLs', id: null }, ...adjustersData],
    [adjustersData]
  );
  const possibleFileOwnersById = useMemo(
    () => possibleFileOwners.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    [possibleFileOwners]
  );
  const glIncidentTypes = useMemo(() => incidentTypesDict.gl_claim, [incidentTypesDict]);
  const isItrakAdvancedSearchEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.T__MGM__ADVANCED_SEARCH__ITRAK
  );
  const isCipAutofillEnabled =
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_CIP_AUTOFILL) &&
    !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_ITRAK_AUTOFILL);
  const shouldDisplayIncidentNumberFields = isItrakAdvancedSearchEnabled || isCipAutofillEnabled;
  const incidentNumberSpace = isItrakAdvancedSearchEnabled && isCipAutofillEnabled ? 6 : 9;

  return (
    <Formik
      initialValues={FILTER_PARAMS_INITIAL_VALUES}
      validationSchema={Yup.object().shape({
        status: Yup.string().nullable(),
        lob: Yup.string().nullable(),
        claim_date_of_loss_start_date: Yup.date().nullable(),
        claim_date_of_loss_end_date: Yup.date().nullable(),
        loss_location: Yup.string().nullable(),
        incident_type: Yup.string().nullable(),
        claim_number: Yup.string().nullable(),
        claimant_first_name: Yup.string().nullable(),
        claimant_last_name: Yup.string().nullable(),
        claimant_ssn: Yup.number().nullable(),
        claimant_state: Yup.string().nullable(),
        claimant_department: Yup.string().nullable(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      onReset={(values, { setSubmitting }) => {
        onReset(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, isSubmitting, resetForm, values }) => (
        <CardDialog noCardTitle outlinedCard containerStyle={{ padding: '16px' }}>
          <CollapsibleWrapper title="Advanced Search">
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11} md={10} lg={9} spacing={1}>
                <Typography
                  display="block"
                  variant="subtitle1"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '120%' }}
                >
                  General Details
                </Typography>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="handling_adjuster"
                      label="File / Exposure owner"
                      className={classes.textField}
                      fullWidth
                      select
                      SelectProps={{
                        multiple: true,
                        renderValue: (selectedIds) =>
                          selectedIds.map((selectedId) => possibleFileOwnersById[selectedId]?.username).join(', '),
                      }}
                    >
                      <MenuItem value="All">
                        <Checkbox checked={values.handling_adjuster.length === 0} />
                        <ListItemText primary="All" />
                      </MenuItem>
                      {possibleFileOwners.map((fileOwner) => (
                        <MenuItem key={fileOwner.id} value={fileOwner.id}>
                          <Checkbox checked={values.handling_adjuster.indexOf(fileOwner.id) > -1} />
                          <ListItemText primary={fileOwner.username} />
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="lob"
                      label="LOB"
                      className={classes.textField}
                      select
                      disabled={isSubmitting}
                      fullWidth
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="gl">GL</MenuItem>
                      <MenuItem value="wc">WC</MenuItem>
                    </TextFieldFormik>
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="status"
                      label="Status"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                      select
                    >
                      <MenuItem value={null}>All</MenuItem>
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="closed">Closed</MenuItem>
                    </TextFieldFormik>
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="incident_type"
                      label="Incident Type"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                      select
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {Object.entries(glIncidentTypes).map(([type, { desc }]) => (
                        <MenuItem key={type} value={type}>
                          {desc}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>
                </Grid>
                <Typography
                  display="block"
                  variant="subtitle1"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '120%' }}
                >
                  Claim Details
                </Typography>

                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="claim_number"
                      label="Claim Number"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatePickerTextFieldFormik
                      id="claim_date_of_loss_start_date"
                      label="Date of loss Start Date"
                      className={classes.textField}
                      disableFuture
                      fullWidth
                      disabled={isSubmitting}
                      clearable
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatePickerTextFieldFormik
                      id="claim_date_of_loss_end_date"
                      label="Date of loss End Date"
                      className={classes.textField}
                      disableFuture
                      fullWidth
                      disabled={isSubmitting}
                      clearable
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <AutocompleteFormik
                      id="loss_location"
                      label="Property Name"
                      options={Object.keys(insuredPropertiesAndLocationsDict)}
                      getOptionLabel={(key) => insuredPropertiesAndLocationsDict[key].desc}
                      sortAlphabetic
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                <Typography
                  display="block"
                  variant="subtitle1"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '120%' }}
                >
                  Claimant Details
                </Typography>

                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="claimant_first_name"
                      label="First Name"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="claimant_last_name"
                      label="Last Name"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="claimant_ssn"
                      label="Social Security Number"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <StateAutocompleteFormik id="claimant_state" label="State" />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="claimant_department"
                      label="Department"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                {shouldDisplayIncidentNumberFields && (
                  <>
                    <Typography
                      display="block"
                      variant="subtitle1"
                      style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '120%' }}
                    >
                      Incident Number
                    </Typography>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      wrap="nowrap"
                      spacing={1}
                      style={{ height: '100%' }}
                    >
                      {isItrakAdvancedSearchEnabled && (
                        <Grid item xs={3}>
                          <TextFieldFormik
                            id="itrak_number"
                            label="ITrak Number"
                            className={classes.textField}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                      )}
                      {isCipAutofillEnabled && (
                        <Grid item xs={3}>
                          <TextFieldFormik
                            id="cip_number"
                            label="CIP Number"
                            className={classes.textField}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                      )}
                      <Grid item xs={incidentNumberSpace} />
                    </Grid>
                  </>
                )}
                <Grid container style={{ paddingTop: '8px' }}>
                  <div className={classes.buttonsContainer}>
                    <Button onClick={resetForm} variant="contained" className={classes.cancelButton}>
                      Clear
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CollapsibleWrapper>
        </CardDialog>
      )}
    </Formik>
  );
};

AdvancedClaimsSearchDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  adjustersData: PropTypes.array,
};

export default MgmAdvancedSearchPage;
