import React from 'react';
import { useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { every, set } from 'lodash';
import * as Yup from 'yup';

import type { Tab } from '~/components/Contacts/UpsertContact/types';
import { useCms } from '~/components/hooks/useCms';
import { isXactAnalysisIntegrationEnabled } from '~/components/hooks/useXactAnalysisContractor';

import { getInitializedValues } from '../../utils';
import TabWrapper from '../TabWrapper';

import { FORMIK_INTEGRATIONS_FIELD_IDS, INTEGRATIONS_TAB_KEY, XACT_FIELD_ID, XACT_FIELDS } from './constants';
import Xact from './Xact';

const IntegrationsTab: React.FC = () => {
  const { isSubmitting } = useFormikContext();

  const sharedProps = {
    disabled: isSubmitting,
  };

  return (
    <TabWrapper>
      <div className="mb-30 grid grid-cols-1 gap-20">
        <Xact {...sharedProps} />
      </div>
    </TabWrapper>
  );
};

const useIntegrationsTab = (): Tab => {
  // No Formik Context
  const { userOrganization } = useCms() as { userOrganization: unknown };

  const getInitialValues = () => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    // TODO NGTPA-14509: get from hook/api instead of starting out as empty
    setInitialValue(FORMIK_INTEGRATIONS_FIELD_IDS.IS_XACT_CONTRACTOR, false);
    setInitialValue(FORMIK_INTEGRATIONS_FIELD_IDS.XACT_CARRIER_ID, '');
    setInitialValue(FORMIK_INTEGRATIONS_FIELD_IDS.XACTNET_ADDRESS, '');

    return values;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    const xactValidations = {
      [XACT_FIELDS.IS_XACT_CONTRACTOR]: Yup.bool(),
      [XACT_FIELDS.XACT_CARRIER_ID]: Yup.string().when(XACT_FIELDS.IS_XACT_CONTRACTOR, {
        is: true,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      [XACT_FIELDS.XACTNET_ADDRESS]: Yup.string().when(XACT_FIELDS.IS_XACT_CONTRACTOR, {
        is: true,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
    };
    addValidation(XACT_FIELD_ID, Yup.object().shape(xactValidations));

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    return getInitializedValues(values);
  };

  const isHiddenForContact = () => {
    // Hidden Tabs cannot have absolute require fields, only conditionally required fields
    return every([isXactAnalysisIntegrationEnabled(userOrganization)], (result) => !result);
  };

  return {
    label: 'Integrations',
    tabKey: INTEGRATIONS_TAB_KEY,
    tabComponent: IntegrationsTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    isHiddenForContact,
  };
};

export default useIntegrationsTab;
