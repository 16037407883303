import React from 'react';
import PropTypes from 'prop-types';

import CardDialog from '~/components/CardDialog';
import { ContactEntity } from '~/components/Contact';
import FsButton from '~/components/core/FsWrappers/FsButton/FsButton';
import Text from '~/components/core/TextComponents/Text';
import useOrganization from '~/components/OrganizationContext';

const WarningDuplicateContactsDialog = ({ duplicates, onCancel, onCreateDuplicate, disabled }) => {
  return (
    <CardDialog isDialog title="Communications already exist">
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="mb-10">
        The information you have entered already exists in the system
      </Text>
      {duplicates.emails.map((duplicateEmail) => (
        <WarningDuplicateContact
          key={duplicateEmail.value}
          contactsWithSameComm={duplicateEmail.contacts}
          commType="email"
          commValue={duplicateEmail.value}
        />
      ))}
      {duplicates.phones.map((duplicatePhones) => (
        <WarningDuplicateContact
          key={duplicatePhones.value}
          contactsWithSameComm={duplicatePhones.contacts}
          commType="phone"
          commValue={duplicatePhones.value}
        />
      ))}
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="mb-20 mt-10">
        Listing the same information for multiple contacts may cause problems with proper classification of incoming
        communications. Are you sure you want to create a duplicate?
      </Text>

      <div className="mt-px flex w-full justify-end">
        <FsButton variant="contained" color="secondary" disabled={disabled} onClick={onCreateDuplicate}>
          Create Duplicate
        </FsButton>
        <FsButton className="ml-10" variant="contained" color="primary" disabled={disabled} onClick={onCancel}>
          Return to previous dialog
        </FsButton>
      </div>
    </CardDialog>
  );
};

WarningDuplicateContactsDialog.propTypes = {
  duplicates: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreateDuplicate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const WarningDuplicateContact = ({ contactsWithSameComm, commType, commValue }) => {
  const valueDesc = commType === 'phone' ? 'phone number' : 'email address';
  const { organizationContactRolesDict } = useOrganization();

  return (
    <div className="my-10 items-center rounded bg-slate-200 p-10">
      <Text variant="small" weight="regular">
        The {valueDesc} -{' '}
        <Text weight="medium" variant="small" className="inline">
          {commValue}
        </Text>{' '}
        - is listed for the following contact
        {contactsWithSameComm.length > 1 ? 's' : ''}:
      </Text>

      {contactsWithSameComm.map((contact) => (
        <div key={contact.id} style={{ display: 'flex', alignItems: 'center' }}>
          <ContactEntity contactId={contact.id} hideDisplayName />
          <span>
            {contact.full_name}
            <span className="font-bold">{` - ${organizationContactRolesDict[contact.role]['desc']}`}</span>
          </span>
        </div>
      ))}
    </div>
  );
};

WarningDuplicateContact.propTypes = {
  contactsWithSameComm: PropTypes.array.isRequired,
  commType: PropTypes.string.isRequired,
  commValue: PropTypes.string.isRequired,
};

export default WarningDuplicateContactsDialog;
