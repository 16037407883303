import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { MIXPANEL_EVENTS } from '../pocs/mixpanel';
import { isProductionEnv, reportGenericException } from '../Utils';

import mixpanel from './CmsMain/mixpanel';
import BackgroundSvg from './illustrations/Background.svg';
import CardDialog from './CardDialog';
import { ErrorIcon, InfoIcon } from './icons';
import { FiveSigmaIllustration } from './illustrations';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';
import '../assets/googleButton.css';

const loginFields = {
  email: '',
  password: '',
  should_remember: false,
};

const loginValidationFields = {
  email: Yup.string().required('Required').email(),
  password: Yup.string().required('Required'),
  should_remember: Yup.boolean(),
};

function LoginContainer(props) {
  const { onLoginSuccess } = props;
  const [loginErrorMessage, setLoginErrorMessage] = React.useState('');
  const [loginInfoMessage, setLoginInfoMessage] = React.useState('');
  const [mfaRequest, setMfaRequest] = React.useState();
  const [showUpdatePasswordRequest, setShowUpdatePasswordRequest] = React.useState(false);

  useEffect(() => {
    try {
      mixpanel?.trackPageView();
    } catch (error) {
      if (!isProductionEnv()) {
        console.error(error);
      }
    }
  }, []);

  const handleSubmit = async (values, formikProps) => {
    const { setSubmitting, resetForm } = formikProps;

    try {
      mixpanel.track(MIXPANEL_EVENTS.LOGIN_CLICKED, { method: '5s' });
      setLoginErrorMessage('');
      setLoginInfoMessage('');
      const res = await axios.post('/api/login', values);
      const loginRet = res.data;
      if (loginRet.success) {
        onLoginSuccess();
        return;
      } else if (loginRet.request_sms) {
        setLoginErrorMessage('');
        setMfaRequest({ cellphone: loginRet.cellphone });
      } else if (loginRet.request_update_password) {
        setShowUpdatePasswordRequest(true);
        resetForm();
      } else {
        setLoginErrorMessage(loginRet.message);
      }
    } catch (error) {
      setLoginErrorMessage('Unexpected error');
      reportGenericException(error);
    }

    setSubmitting(false);
  };

  const handleSubmitMfa = async (values, formikProps) => {
    const { setSubmitting } = formikProps;

    try {
      const res = await axios.post('/api/login_mfa', values);
      const loginRet = res.data;
      if (loginRet.success) {
        onLoginSuccess();
        return;
      } else {
        setLoginErrorMessage(loginRet.message);
      }
    } catch (error) {
      setLoginErrorMessage('Unexpected error');
      reportGenericException(error);
    }

    setSubmitting(false);
  };

  if (showUpdatePasswordRequest) {
    return (
      <UpdatePasswordPage
        onPasswordUpdated={() => {
          setLoginInfoMessage('Password Updated Successfully');
          setShowUpdatePasswordRequest(false);
        }}
        onClose={() => setShowUpdatePasswordRequest(false)}
        isDuringLogin
      />
    );
  }

  return mfaRequest ? (
    <Formik
      initialValues={{
        sms_token: '',
      }}
      validationSchema={Yup.object().shape({ sms_token: Yup.string().required('Required') })}
      onSubmit={handleSubmitMfa}
      enableReinitialize
    >
      {(formikProps) => (
        <LoginMfaPage loginErrorMessage={loginErrorMessage} cellphone={mfaRequest.cellphone} {...formikProps} />
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{
        ...loginFields,
      }}
      validationSchema={Yup.object().shape({ ...loginValidationFields })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <LoginPage
          loginErrorMessage={loginErrorMessage}
          loginInfoMessage={loginInfoMessage}
          mfaRequest={mfaRequest}
          onCancelMfa={() => {
            formikProps.resetForm();
            setMfaRequest(undefined);
          }}
          {...formikProps}
        />
      )}
    </Formik>
  );
}

LoginContainer.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
};

function GenericLoginPage(props) {
  const { children, showWelcomeTitle, isDialog, onClose } = props;

  let gridStyle = { height: '100vh', width: '100vw' };
  if (!isDialog) {
    gridStyle = { ...gridStyle, backgroundImage: `url(${BackgroundSvg})`, backgroundSize: 'cover' };
  }
  return (
    <Grid container style={gridStyle} justify="center" alignItems="center">
      <Grid item xs={12} md={4} lg={3}>
        <CardDialog
          isDialog={isDialog}
          fullWidth
          open
          onClose={onClose}
          noCardTitle
          containerStyle={{ minWidth: '500px', boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 20%)' }}
        >
          <Grid container direction="column" spacing={4} justify="center" alignItems="center">
            <Grid item xs={6} style={{ paddingBottom: '0px' }}>
              <FiveSigmaIllustration />
            </Grid>
            {showWelcomeTitle && (
              <Grid item xs={12} style={{ paddingTop: '0px' }}>
                <Typography display="block" style={{ fontWeight: 'bold', fontSize: '28px' }}>
                  Welcome to Five Sigma
                </Typography>
              </Grid>
            )}
            {children}
            <Grid item />
          </Grid>
        </CardDialog>
      </Grid>
    </Grid>
  );
}

GenericLoginPage.propTypes = {
  children: PropTypes.node.isRequired,
  showWelcomeTitle: PropTypes.bool,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
};

function LoginErrorMessage(props) {
  const { message } = props;

  return (
    <span style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
      <ErrorIcon style={{ margin: '4px' }} iconColor="#F44336" />
      <Typography display="block" style={{ color: '#F44336', fontSize: '12px' }}>
        {message}
      </Typography>
    </span>
  );
}

LoginErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

function LoginPage(props) {
  const {
    loginErrorMessage,
    loginInfoMessage,
    isSubmitting, // comes from Formik
    handleSubmit, // comes from Formik
  } = props;

  const classes = useStyles();
  const [queryStringError, setQueryStringError] = useState('');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('error')) {
      setQueryStringError(queryParams.get('error'));
      queryParams.delete('error');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [location.search, history]);

  const errorMessage = loginErrorMessage ? loginErrorMessage : queryStringError;

  return (
    <GenericLoginPage showWelcomeTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid container direction="column" spacing={1} alignItems="center">
          <Grid item xs={12}>
            {errorMessage && <LoginErrorMessage message={errorMessage} />}
            {loginInfoMessage && (
              <Typography display="block" variant="caption" color="primary">
                {loginInfoMessage}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik id="email" label="Username" className={classes.formTextFieldRow} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="password"
              label="Password"
              type="password"
              className={classes.formTextFieldRow}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.loginButton}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid item xs={12}>
        <span style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
          <Link to="/login_sso" className={classes.link}>
            Login with your company SSO
          </Link>
          <Tooltip
            placement="top"
            arrow
            title="Use this option if there is a dedicated SSO connection set up for your company"
          >
            <span style={{ height: '24px', margin: '4px', display: 'flex', alignItems: 'center' }}>
              <InfoIcon size={24} fill="#909090" />
            </span>
          </Tooltip>
        </span>
      </Grid>
      <Grid item>
        <LoginWithGoogleButton />
      </Grid>
      <Grid item>
        <Typography display="block" variant="body1">
          Forgot your password? Please contact your IT
        </Typography>
      </Grid>
    </GenericLoginPage>
  );
}

LoginPage.propTypes = {
  loginErrorMessage: PropTypes.string.isRequired,
  loginInfoMessage: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

function LoginMfaPage(props) {
  const {
    loginErrorMessage,
    cellphone,
    isSubmitting, // comes from Formik
    handleSubmit, // comes from Formik
  } = props;

  const classes = useStyles();

  return (
    <GenericLoginPage>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid container spacing={4} direction="column" alignItems="center" style={{ minHeight: '300px' }}>
          <Grid item style={{ width: '350px' }}>
            <Typography align="center">
              We have sent you a seven-digit code by sms to your mobile phone number:{' '}
              <span style={{ whiteSpace: 'nowrap' }}>{cellphone}</span>
            </Typography>
            <Typography align="center">Enter it below to login</Typography>
          </Grid>
          <Grid item xs={12}>
            {loginErrorMessage && <LoginErrorMessage message={loginErrorMessage} />}
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik id="sms_token" label="Code" className={classes.formTextFieldRow} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.loginButton}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              Validate
            </Button>
          </Grid>
        </Grid>
      </form>
    </GenericLoginPage>
  );
}

LoginMfaPage.propTypes = {
  loginErrorMessage: PropTypes.string.isRequired,
  cellphone: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export function UpdatePasswordPage(props) {
  const { onClose, onPasswordUpdated, isDuringLogin } = props;
  const classes = useStyles();
  const [updatePasswordErrorMessage, setUpdatePasswordErrorMessage] = useState();

  const handleUpdatePassword = async (currentPassword, newPassword, formikProps) => {
    const { setSubmitting } = formikProps;

    try {
      const res = await axios.post(`/api/${isDuringLogin ? 'login_' : ''}update_password`, {
        current_password: currentPassword,
        new_password: newPassword,
      });
      const loginRet = res.data;
      if (loginRet.success) {
        onPasswordUpdated();
        return;
      } else {
        setUpdatePasswordErrorMessage(loginRet.message);
      }
    } catch (error) {
      setUpdatePasswordErrorMessage('Unexpected error');
      reportGenericException(error);
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ current_password: '', new_password: '', new_password_confirmation: '' }}
      validationSchema={Yup.object().shape({
        current_password: Yup.string().required('Required'),
        new_password: Yup.string()
          .required('Required')
          .min(8, 'Password should be at least 8 chars')
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(.){8,}$/,
            'Password must contain 8 characters, at least one uppercase, one lowercase and one number'
          )
          .test('no-spaces', "Password can't start or end with a blank space", (pass) => pass && pass.trim() === pass),
        new_password_confirmation: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        handleUpdatePassword(values.current_password, values.new_password, formikProps)
          .then(() => {
            formikProps.resetForm();
          })
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <GenericLoginPage isDialog onClose={onClose}>
            <Grid item style={{ padding: '0px' }}>
              <Typography display="block" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Choose a new password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {updatePasswordErrorMessage && <LoginErrorMessage message={updatePasswordErrorMessage} />}
            </Grid>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Grid container spacing={1} direction="column" alignItems="center">
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="current_password"
                    label="Current Password"
                    type="password"
                    fullWidth
                    className={classes.formTextFieldRow}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="new_password"
                    label="New Password"
                    type="password"
                    fullWidth
                    className={classes.formTextFieldRow}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="new_password_confirmation"
                    label="New Password Confirmation"
                    type="password"
                    fullWidth
                    className={classes.formTextFieldRow}
                  />
                </Grid>
                <Grid item xs={12} style={{ padding: '10px' }}>
                  <Button
                    className={classes.loginButton}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Update Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </GenericLoginPage>
        );
      }}
    </Formik>
  );
}

UpdatePasswordPage.propTypes = {
  onPasswordUpdated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isDuringLogin: PropTypes.bool,
};

const LoginWithGoogleButton = () => {
  const classes = useStyles();

  return (
    <Button
      href="/api/login_with_google"
      variant="outlined"
      className={classes.googleButton}
      onClick={() => mixpanel.track(MIXPANEL_EVENTS.LOGIN_CLICKED, { method: 'google' })}
    >
      <span className="google-button-icon">
        <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
            id="Shape"
            fill="#EA4335"
          />
          <path
            d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
            id="Shape"
            fill="#FBBC05"
          />
          <path
            d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
            id="Shape"
            fill="#4285F4"
          />
          <path
            d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
            fill="#34A853"
          />
        </svg>
      </span>
      <span className="google-button-text">Continue with google</span>
    </Button>
  );
};

function LoginSsoPage() {
  const classes = useStyles();

  const [updatePasswordErrorMessage, setUpdatePasswordErrorMessage] = useState();

  useEffect(() => {
    try {
      mixpanel?.trackPageView();
    } catch (error) {
      if (!isProductionEnv()) {
        console.error(error);
      }
    }
  }, []);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Required').email(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          mixpanel.track(MIXPANEL_EVENTS.LOGIN_CLICKED, { method: 'sso' });
          const res = await axios.post('api/login_sso', values);
          const loginRet = res.data;
          if (loginRet.redirect) {
            window.location.href = loginRet.redirect;
            return;
          } else {
            setUpdatePasswordErrorMessage(loginRet.message);
          }
        } catch (error) {
          setUpdatePasswordErrorMessage('Unexpected error');
          reportGenericException(error);
        }

        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => (
        <GenericLoginPage showWelcomeTitle>
          <Grid item xs={12}>
            {updatePasswordErrorMessage && <LoginErrorMessage message={updatePasswordErrorMessage} />}
          </Grid>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container spacing={1} direction="column" alignItems="flex-start" style={{ minHeight: '350px' }}>
              <Grid item xs={12}>
                <TextFieldFormik id="email" label="Username" className={classes.formTextFieldRow} fullWidth />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '30px' }}>
                <Button
                  className={classes.loginButton}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Login
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link to="/login" className={classes.link}>
                  Back to Login
                </Link>
              </Grid>
            </Grid>
          </form>
        </GenericLoginPage>
      )}
    </Formik>
  );
}

export { LoginSsoPage };
export default LoginContainer;
