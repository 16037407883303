import React from 'react';
import { useFormikContext } from 'formik';

import { FsMenuItem } from '~/components/core';
import { LEGAL_ACTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import { isOptionOther } from '~/components/LegalActions/LegalActionsUtils';
import TextFieldFormik from '~/components/TextFieldFormik';

export const ReopenLegalActionForm = () => {
  const { reopenReasonOptions } = useLegalActionsConfiguration();
  const { values } = useFormikContext();

  return (
    <>
      <div className="flex w-full gap-30">
        <div className="flex w-1/2">
          <TextFieldFormik
            fullWidth
            id={LEGAL_ACTION_FIELDS.reopenReason.key}
            label={LEGAL_ACTION_FIELDS.reopenReason.label}
            select
          >
            {reopenReasonOptions.map((option) => (
              <FsMenuItem key={option.key} value={option.key}>
                {option.description}
              </FsMenuItem>
            ))}
          </TextFieldFormik>
        </div>
        <div className="flex w-1/2">
          <TextFieldFormik
            fullWidth
            id={LEGAL_ACTION_FIELDS.reopenReasonText.key}
            label={LEGAL_ACTION_FIELDS.reopenReasonText.label}
            disabled={!isOptionOther(reopenReasonOptions, values[LEGAL_ACTION_FIELDS.reopenReason.key])}
          />
        </div>
      </div>
    </>
  );
};
