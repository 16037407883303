import React from 'react';
import { capitalize } from 'lodash';

import type { ContactFullModel } from '~/components/Contacts/types';
import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

interface Form1099PropsType {
  contact: ContactFullModel;
}

const Form1099: React.FC<Form1099PropsType> = ({ contact }) => {
  const { show1099Fields } = usePaymentsConfiguration();

  if (!show1099Fields) {
    return null;
  }

  return (
    <div className="col-span-full mt-10 grid grid-cols-2">
      <div>
        <Caption variant={Caption.VARIANTS.LABEL}>Is 1099 Reportable?</Caption>
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          {contact.is_1099_reportable ? 'Reportable' : 'Not Reportable'}
        </Text>
      </div>
      {contact.is_1099_reportable ? (
        <div>
          <Caption variant={Caption.VARIANTS.LABEL}>Report Type</Caption>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
            {capitalize(contact.report_type_1099)}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

export default Form1099;
