import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import LoadingIndicator from '../LoadingIndicator';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

function CancelRecoveryDialog({ claimId, exposureId, recoveryAmountId, recoveryType, onCancel, onUpdate }) {
  const baseResourceUrl = `/api/v1/claims/${claimId}/exposures/${exposureId}/recoveries/${recoveryType}/recoveries_amount/${recoveryAmountId}`;

  const { isLoading, isError, data: recoveryAmount } = useDataFetcher(baseResourceUrl);
  const classes = useStyles();

  if (isLoading) {
    return (
      <CardDialog isDialog open onClose={onCancel} title="Cancel Recovery">
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  return (
    <Formik
      initialValues={{ cancel_note: '' }}
      validationSchema={Yup.object().shape({ cancel_note: Yup.string().required('Required') })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.delete(baseResourceUrl, { data: { ...values } });
          await onUpdate();
          formikProps.resetForm();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog isDialog onClose={onCancel} title="Cancel Recovery" preventClose={isSubmitting}>
            <div className={classes.textField}>
              <Typography>
                Recovery of amount: {recoveryAmount.amount} by {recoveryAmount.setting_user}
              </Typography>
              {recoveryAmount.check_number && <Typography>Check number: {recoveryAmount.check_number}</Typography>}
              {recoveryAmount.note && <Typography>Note: {recoveryAmount.note}</Typography>}
            </div>
            <TextFieldFormik id="cancel_note" label="Reason" fullWidth className={classes.textField} />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Cancel Recovery
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

CancelRecoveryDialog.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
  recoveryAmountId: PropTypes.number.isRequired,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']),
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CancelRecoveryDialog;
