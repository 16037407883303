import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Divider } from '@material-ui/core';

import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';

import colors from '../../../theme/tailwind/colors';
import { FsButton, LoadingSwitch, PropertyDetails, Text } from '../../core';
import { ChevronLeftIcon } from '../../icons';
import { ApplyToPropertyDetails } from '../components/ApplyToPropertyDetails';
import { CloseLegalActionDialog } from '../Dialogs/CloseLegalAction/CloseLegalActionDialog';
import { NewLegalActionDialog } from '../Dialogs/NewLegalAction/NewLegalActionDialog';
import { ReopenLegalActionDialog } from '../Dialogs/ReopenLegalAction/ReopenLegalActionDialog';
import { LegalActionContextProvider, useLegalAction } from '../hooks/useLegalAction';
import type { Option } from '../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../hooks/useLegalActionsConfiguration';
import { LegalActionsHeader } from '../LegalActionsTab/LegalActionsPageHeader';
import { getLabelByKey, isOptionOther } from '../LegalActionsUtils';

import { ClosedLegalActionBar } from './ClosedLegalActionBar/ClosedLegalActionBar';
import { InfoTab } from './InfoTab/InfoTab';
import { LogsTab } from './LogsTab/LogsTab';

interface LegalActionScreenProps {
  legalActionId: number;
  isNewLegalActionDialogOpen: boolean;
  setIsNewLegalActionDialogOpen: (state: boolean) => void;
}

export const LegalActionScreen: React.FC<LegalActionScreenProps> = ({
  legalActionId,
  isNewLegalActionDialogOpen,
  setIsNewLegalActionDialogOpen,
}) => (
  <LegalActionContextProvider legalActionId={legalActionId}>
    <LegalActionScreenInner
      setIsNewLegalActionDialogOpen={setIsNewLegalActionDialogOpen}
      isNewLegalActionDialogOpen={isNewLegalActionDialogOpen}
    />
  </LegalActionContextProvider>
);

const LegalActionScreenInner: React.FC<Omit<LegalActionScreenProps, 'legalActionId'>> = ({
  isNewLegalActionDialogOpen,
  setIsNewLegalActionDialogOpen,
}) => {
  const { isLoading: isConfigLoading, isError: isConfigError, reopenReasonOptions } = useLegalActionsConfiguration();
  const {
    legalAction,
    isLoading: isLegalActionLoading,
    isError: isLegalActionError,
    reloadLegalAction,
  } = useLegalAction();

  const match = useRouteMatch<{ claimId: string; legalActionId: string }>();
  const history = useHistory();

  const [isCloseLegalActionDialogOpen, setIsCloseLegalActionDialogOpen] = React.useState(false);
  const [isReopenLegalActionDialogOpen, setIsReopenLegalActionDialogOpen] = React.useState(false);

  const tabs = [
    {
      label: 'Information',
      url: 'info',
      component: InfoTab,
      props: { legalAction, reloadLegalAction },
    },
    {
      label: 'Logs',
      url: 'logs',
      component: LogsTab,
      props: { legalAction, reloadLegalAction },
    },
  ];

  const isLoading = isConfigLoading || isLegalActionLoading;
  const isError = isConfigError || isLegalActionError;

  const navigateToLegalActionsTab = () => history.push(`/claims/${match.params.claimId}/legal_actions`);

  const getReopenReason = () => {
    if (isConfigLoading) {
      return '';
    }

    return isOptionOther(reopenReasonOptions as Option[], legalAction.reopen_reason)
      ? legalAction.reopen_text
      : getLabelByKey(reopenReasonOptions as Option[], legalAction.reopen_reason);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="pb-20">
        <LegalActionsHeader
          onNewLegalActionClicked={() => setIsNewLegalActionDialogOpen(true)}
          showButton={!isLoading}
        />
      </div>
      <LoadingSwitch isLoading={isLoading && !legalAction} isError={isError}>
        {legalAction ? (
          <>
            <div className="flex w-full flex-col rounded-t-md bg-white">
              <div className="flex w-full flex-col p-24">
                <div className="flex w-fit cursor-pointer gap-5" onClick={navigateToLegalActionsTab}>
                  <ChevronLeftIcon size={20} iconColor={colors.blue[600]} className="mt-2" />
                  <Text
                    variant={Text.VARIANTS.SM}
                    colorVariant={Text.COLOR_VARIANTS.LINK}
                    weight={Text.WEIGHTS.MEDIUM}
                    className="underline"
                  >
                    Back to All Legal Actions
                  </Text>
                </div>
                <div className="flex w-full">
                  <div className="flex w-2/5 items-end">
                    <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
                      {legalAction.display_name}
                    </Text>
                  </div>
                  <div className="flex w-full items-end">
                    <ApplyToPropertyDetails legalAction={legalAction} />
                    <Divider orientation="vertical" variant="middle" />

                    <PropertyDetails title="Assignee" text="" />

                    {legalAction.reopen_reason && legalAction.is_open ? (
                      <>
                        <Divider orientation="vertical" variant="middle" />
                        <PropertyDetails title="Reason for Reopening" text={getReopenReason()} />
                      </>
                    ) : null}
                    <div className="flex min-w-[200px] justify-end">
                      {legalAction.is_open ? (
                        <FsButton
                          variant={FsButton.VARIANTS.contained}
                          color={FsButton.COLORS.primary}
                          onClick={() => setIsCloseLegalActionDialogOpen(true)}
                        >
                          Close Legal Action
                        </FsButton>
                      ) : null}
                    </div>
                  </div>
                </div>
                {!legalAction.is_open ? (
                  <div className="mt-24 flex w-full flex-col">
                    <ClosedLegalActionBar onReopenButtonClick={() => setIsReopenLegalActionDialogOpen(true)} />
                  </div>
                ) : null}
              </div>
            </div>
            <InnerTabs
              tabs={tabs}
              tabsContainerClassName="px-24 rounded-none border-t-1 border-b-1  border-y-slate[600] h-[50px] w-full"
              renderContainerClassName="flex w-full gap-24 px-24 py-20 bg-transparent columns-2"
            />
          </>
        ) : null}
      </LoadingSwitch>
      {isCloseLegalActionDialogOpen ? (
        <CloseLegalActionDialog
          onClose={() => setIsCloseLegalActionDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
          legalActionId={legalAction.id}
        />
      ) : null}
      {isReopenLegalActionDialogOpen ? (
        <ReopenLegalActionDialog
          onClose={() => setIsReopenLegalActionDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
          legalActionId={legalAction.id}
        />
      ) : null}
      {isNewLegalActionDialogOpen && (
        <NewLegalActionDialog
          onSubmitSuccess={navigateToLegalActionsTab}
          onClose={() => setIsNewLegalActionDialogOpen(false)}
        />
      )}
    </div>
  );
};
