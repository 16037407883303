import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './constants';

const MessageOutgoingIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M12.2344 8.10531C12.2344 8.23477 12.1829 8.35893 12.0914 8.45047C11.9999 8.54201 11.8757 8.59344 11.7462 8.59344H5.87289C5.74343 8.59344 5.61928 8.54201 5.52773 8.45047C5.43619 8.35893 5.38477 8.23477 5.38477 8.10531C5.38477 7.97586 5.43619 7.8517 5.52773 7.76016C5.61928 7.66862 5.74343 7.61719 5.87289 7.61719H11.7462C11.8757 7.61719 11.9999 7.66862 12.0914 7.76016C12.1829 7.8517 12.2344 7.97586 12.2344 8.10531ZM12.2344 11.042C12.2344 11.1714 12.1829 11.2956 12.0914 11.3871C11.9999 11.4787 11.8757 11.5301 11.7462 11.5301H5.87289C5.74343 11.5301 5.61928 11.4787 5.52773 11.3871C5.43619 11.2956 5.38477 11.1714 5.38477 11.042C5.38477 10.9125 5.43619 10.7884 5.52773 10.6968C5.61928 10.6053 5.74343 10.5539 5.87289 10.5539H11.7462C11.8757 10.5539 11.9999 10.6053 12.0914 10.6968C12.1829 10.7884 12.2344 10.9125 12.2344 11.042Z"
      fill={iconColor}
      stroke={iconColor}
      strokeWidth="0.125"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 3C0.25 2.30964 0.809644 1.75 1.5 1.75H11.875C12.2892 1.75 12.625 2.08579 12.625 2.5C12.625 2.91421 12.2892 3.25 11.875 3.25H1.75V17.8358L4.86136 15.0848C5.08986 14.8828 5.38434 14.7713 5.68934 14.7713H18.25V9.73404C18.25 9.31983 18.5858 8.98404 19 8.98404C19.4142 8.98404 19.75 9.31983 19.75 9.73404V15.0213C19.75 15.7116 19.1904 16.2713 18.5 16.2713H5.78401L2.32798 19.327C1.52113 20.0404 0.25 19.4675 0.25 18.3905V3ZM5.85494 16.2086C5.85485 16.2086 5.85477 16.2087 5.85468 16.2088L5.85494 16.2086Z"
      fill={iconColor}
    />
    <path
      d="M18.4027 3.38005L18.4027 3.22919L18.296 3.33585L14.8315 6.79941C14.739 6.88517 14.617 6.93184 14.4909 6.92961C14.3644 6.92738 14.2438 6.87615 14.1543 6.78672C14.0649 6.69728 14.0137 6.57663 14.0114 6.45017C14.0092 6.32407 14.0559 6.20202 14.1417 6.10958L17.6061 2.6451L17.7128 2.53841L17.5619 2.53841L15.2199 2.53841C15.0905 2.53841 14.9663 2.48698 14.8748 2.39544C14.7832 2.3039 14.7318 2.17974 14.7318 2.05028C14.7318 1.92082 14.7832 1.79667 14.8748 1.70512C14.9663 1.61358 15.0905 1.56215 15.2199 1.56215L18.8908 1.56216C19.0202 1.56216 19.1444 1.61358 19.2359 1.70512C19.3275 1.79667 19.3789 1.92082 19.3789 2.05028L19.3789 5.72113C19.3789 5.85059 19.3275 5.97474 19.2359 6.06628C19.1444 6.15783 19.0202 6.20925 18.8908 6.20925C18.7613 6.20925 18.6372 6.15783 18.5456 6.06628C18.4541 5.97474 18.4027 5.85059 18.4027 5.72113L18.4027 3.38005Z"
      fill={iconColor}
      stroke={iconColor}
      strokeWidth="0.125"
    />
  </SvgIcon>
);

export default MessageOutgoingIcon;
