import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';

export interface TooltipProps {
  title: NonNullable<React.ReactNode>;
  children: React.ReactElement;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children }) => {
  return <MuiTooltip title={title}>{children}</MuiTooltip>;
};

export default Tooltip;
