/* eslint-disable react/display-name */
import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { getPolicyStatus, getPolicyStatusDuringLoss } from '../../PolicyUtils';
import { AUTO_COVERAGES_DICT, CONFIGURATION_FEATURES_NAMES, COUNTRIES_DICT } from '../../Types';
import {
  isClaimPolicyManuallyFilled,
  isFeatureEnabled,
  isInshurPolicy,
  isLocaleRegionIsUK,
  isMarshmallowPolicy,
  isPolicyNotFound,
  reportAxiosError,
} from '../../Utils';
import { getFirstPartyFromClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { useClaim } from '../ClaimContainer';
import WithConfirm from '../ConfirmModal';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../core';
import CoveredEntityCoveragesTable from '../CoveredEntity';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import {
  NewEditVehicleDetailsInfoDialog,
  NewShowOnlyVehicleDetailsInfoDialog,
} from '../Fnol/NewFnolUI/InvolvedParties/AutoParties/VehicleDetails';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import { PencilIcon } from '../icons';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import { EditManuallyFilledPolicyContainer } from '../Policy/PolicyNotFoundContainer';
import { MonetaryValueTextFieldFormik, ShowOnlyTextField } from '../TextFieldFormik';
import UpdatePolicyEffectiveDateDialog from '../UpdatePolicyEffectiveDateDialog';

import { EditVehicleDetailsInfoDialog, ShowOnlyVehicleDetailsInfoDialog } from './AutoInvolved/VehicleDetails';

import { useStyles } from '../../assets/styles';

var autoPolicyDetailsFields = {
  policy_number: '',
  policy_effective_date: '',
  policy_expiration_date: '',
  insured_contact: '',
  client: '',
  underwriting_company: '',
};

const spacing = 1;

function AutoPolicyPage() {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { userOrganization } = useCms();
  const policy = claim.policy;
  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <div className={classes.cardDivRow}>
          <AutoPolicyDetailsCard claim={claim} onClaimUpdate={onClaimUpdate} />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.cardDivRow}>
          <AutoPolicyListedDriversCard namedDrivers={policy.named_drivers} />
        </div>
      </Grid>
      {_.sortBy(policy.covered_vehicles, ['id']).map((coveredVehicle) => (
        <Grid key={coveredVehicle.id} item xs={6}>
          <div className={classes.cardDivRow}>
            <AutoPolicyCoveredVehicleCard claim={claim} onClaimUpdate={onClaimUpdate} coveredVehicle={coveredVehicle} />
          </div>
        </Grid>
      ))}
      {claim.unlisted_covered_vehicle && (
        <Grid item xs={6}>
          <div className={classes.cardDivRow}>
            <AutoPolicyCoveredVehicleCard
              claim={claim}
              onClaimUpdate={onClaimUpdate}
              coveredVehicle={claim.unlisted_covered_vehicle}
              isUnlistedVehicle
              isRental={claim.is_unlisted_vehicle_rental}
            />
          </div>
        </Grid>
      )}
      {isConfigurableCoveragesEnabled && claim.pedestrian_covered_entity && (
        <Grid item xs={6}>
          <div className={classes.cardDivRow}>
            <AutoPolicyCoveredPedestrianCard
              claim={claim}
              pedestrianCoveredEntity={claim.pedestrian_covered_entity}
              firstParty={getFirstPartyFromClaim(claim)}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

function AutoPolicyDetailsCard({ claim, onClaimUpdate, showOnly }) {
  const { policy } = claim;
  const classes = useStyles();
  const { user } = useCms();

  const readOnly = showOnly || user.role.is_view_only;

  const [showEditPolicyEffectiveDate, setShowEditPolicyEffectiveDate] = useState(false);

  const { policySearchConfig } = useOrganization();
  const policyApiConfiguration = policySearchConfig.policy_api_configuration;

  const handleUpdateFields = async (updateObject) => {
    try {
      const res = await axios.patch(`/api/v1/claims/${claim.id}/policy`, updateObject);
      await onClaimUpdate(res.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  if (isPolicyNotFound(claim)) {
    return (
      <CardDialog className={classes.CardDialog} title="Policy Details">
        <Typography display="block" color="secondary">
          Policy Not Found
        </Typography>
      </CardDialog>
    );
  }

  const policyStatusDuringLoss = getPolicyStatusDuringLoss(
    policy,
    claim?.incident?.date_of_loss,
    claim?.incident?.time_of_loss
  );
  const policyStatus = getPolicyStatus(policyStatusDuringLoss);

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title="Policy Details"
        action={<EditManuallyFilledPolicyContainer />}
        subheader={
          <>
            {isInshurPolicy(policy) && policy.policy_type && (
              <span>
                <strong>{policy.policy_type} - </strong>
              </span>
            )}
            <span style={{ color: policyStatus === 'In Force' ? 'green' : 'red' }}>
              <strong>{policyStatus}</strong>
            </span>
          </>
        }
      >
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <ShowOnlyTextField label="Policy Number" classes={classes} showOnlyValueComponent={policy.policy_number} />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField label="Client" classes={classes} showOnlyValueComponent={policy.client} />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Effective Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
              }
              fullWidth
              onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Expiration Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
              }
              fullWidth
              onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
            />
          </Grid>
          {policyApiConfiguration?.enabled && policy.is_cancelled && policy.cancelled_date && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Cancelled Date"
                classes={classes}
                showOnlyValueComponent={
                  isoDateToUs(policy.cancelled_date) + ' ' + timeToLocalTime(policy.cancelled_time)
                }
              />
            </Grid>
          )}
          {policyApiConfiguration?.enabled && policy.bind_date && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Bind Date"
                classes={classes}
                showOnlyValueComponent={isoDateToUs(policy.bind_date)}
              />
            </Grid>
          )}
          {policyApiConfiguration?.enabled && policy.renewal_date && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Renewal Date"
                classes={classes}
                showOnlyValueComponent={isoDateToUs(policy.renewal_date)}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact.full_name}
              label="Named Insured"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Underwriting Company"
              classes={classes}
              showOnlyValueComponent={policy.underwriting_company}
            />
          </Grid>
          {isMarshmallowPolicy(policy) && (
            <Grid item xs={6}>
              <ShowOnlyTextField label="Package" classes={classes} showOnlyValueComponent={policy.policy_type} />
            </Grid>
          )}
          {policyApiConfiguration?.enabled && policy.policy_country && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Policy Country"
                classes={classes}
                showOnlyValueComponent={COUNTRIES_DICT[policy.policy_country]}
              />
            </Grid>
          )}
          {policyApiConfiguration?.enabled && policy.policy_state && (
            <Grid item xs={6}>
              <ShowOnlyTextField label="Policy State" classes={classes} showOnlyValueComponent={policy.policy_state} />
            </Grid>
          )}
          {policy.custom_fields &&
            policy.custom_fields?.map((field) => (
              <Grid item xs={6} key={Object.keys(field)[0]}>
                <ShowOnlyTextField
                  label={field[Object.keys(field)[0]].displayName || field[Object.keys(field)[0]].display_name}
                  classes={classes}
                  showOnlyValueComponent={field[Object.keys(field)[0]].value}
                />
              </Grid>
            ))}
        </Grid>
      </CardDialog>
      <UpdatePolicyEffectiveDateDialog
        onClose={() => setShowEditPolicyEffectiveDate(false)}
        policy={policy}
        open={showEditPolicyEffectiveDate}
        onSubmit={async (values) => {
          await handleUpdateFields(values);
          setShowEditPolicyEffectiveDate(false);
        }}
      />
    </>
  );
}

AutoPolicyDetailsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: requiredIf(PropTypes.func, (props) => !props.showOnly),
  showOnly: PropTypes.bool,
};

function AutoPolicyCoveredVehicleCard({ claim, onClaimUpdate, coveredVehicle, isUnlistedVehicle, isRental }) {
  const { currencyFormatter } = useCurrencyFormatter();
  const classes = useStyles();
  const { userOrganization } = useCms();

  const [openVehicleInfoCard, setOpenVehicleInfoCard] = useState(false);
  const [enableChangeListing, setEnableChangeListing] = useState(true);

  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  let coveredVehicleColumns = [
    // eslint-disable-next-line react/prop-types
    {
      id: 'warning',
      width: 5,
      disablePadding: true,
      specialCell: ({ coverage_type }) =>
        // eslint-disable-next-line react/prop-types
        coveredVehicle.coverages_status[coverage_type] === false ? (
          <Tooltip title="Missing Coverage Details">
            <WarningIcon className={classes.warningColor} style={{ paddingLeft: '5px' }} />
          </Tooltip>
        ) : (
          ''
        ),
    },
    // eslint-disable-next-line react/prop-types
    {
      id: 'coverage',
      label: 'Coverage',
      specialCell: ({ coverage_type }) => <span>{AUTO_COVERAGES_DICT[coverage_type].desc}</span>,
    },
    {
      id: 'limit',
      label: 'Limit',
      specialCell: ({ coverage_type }) => {
        if (AUTO_COVERAGES_DICT[coverage_type].is_differ_per_person_and_accident) {
          const limitPerPerson = coveredVehicle[`${coverage_type}_per_person_limit`];
          const limitPerAccident = coveredVehicle[`${coverage_type}_per_accident_limit`];
          return (
            <span>
              {limitPerPerson !== null && `${currencyFormatter.format(limitPerPerson)} per person`}
              <br />
              {limitPerAccident !== null && `${currencyFormatter.format(limitPerAccident)} per accident`}
            </span>
          );
        } else if (AUTO_COVERAGES_DICT[coverage_type].boolean_option) {
          return coveredVehicle[coverage_type] !== null && (coveredVehicle[coverage_type] ? 'Covered' : 'Not covered');
        } else if (AUTO_COVERAGES_DICT[coverage_type].is_limit_exists === false) {
          return <></>;
        } else {
          return coveredVehicle[`${coverage_type}_limit`] !== null ? (
            <span>
              {currencyFormatter.format(coveredVehicle[`${coverage_type}_limit`])}
              {AUTO_COVERAGES_DICT[coverage_type].extra_desc
                ? ` (${AUTO_COVERAGES_DICT[coverage_type].extra_desc})`
                : undefined}
            </span>
          ) : undefined;
        }
      },
    },
    {
      id: 'deductible',
      label: isLocaleRegionIsUK() ? 'Excess' : 'Deductible',
      specialCell: ({ coverage_type }) =>
        AUTO_COVERAGES_DICT[coverage_type].is_deduction_exist
          ? coveredVehicle[`${coverage_type}_deduction`] !== null &&
            currencyFormatter.format(coveredVehicle[`${coverage_type}_deduction`])
          : '',
    },
    // eslint-disable-next-line react/prop-types
    {
      id: 'edit_coverage',
      numeric: false,
      disablePadding: true,
      width: 20,
      specialCell: ({ coverage_type }, isHover) => (
        <EditCoverageHover
          claim={claim}
          onUpdate={onClaimUpdate}
          coveredVehicle={coveredVehicle}
          coverageType={coverage_type}
          displayIcon={isHover}
          isUnlistedVehicle={isUnlistedVehicle}
        />
      ),
    },
  ];

  const handleToggleVehicleListing = async () => {
    const url = isUnlistedVehicle
      ? 'unlisted_vehicle/set_listed'
      : `policy/covered_vehicles/${coveredVehicle.id}/set_unlisted`;
    try {
      await axios.patch(`/api/v1/auto_claims/${claim.id}/${url}`);
      await onClaimUpdate();
      setEnableChangeListing(true);
    } catch (error) {
      reportAxiosError(error);
      setEnableChangeListing(true);
      throw error;
    }
  };

  const handleEditVehicleDetails = async (values) => {
    const vehicleUrl = isUnlistedVehicle ? 'unlisted_vehicle/vehicle' : 'policy/covered_vehicle_details';
    try {
      await axios.patch(`/api/v1/auto_claims/${claim.id}/${vehicleUrl}/${coveredVehicle.id}`, values['vehicle']);
      await onClaimUpdate();
      setOpenVehicleInfoCard(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const unlistedVehicleTitle = isRental ? 'Rental Vehicle' : 'Unlisted Vehicle';
  return (
    <CardDialog
      className={classes.CardDialog}
      title={isUnlistedVehicle ? unlistedVehicleTitle : 'Listed Vehicle'}
      action={
        isClaimPolicyManuallyFilled(claim) &&
        !isRental && (
          <FsButton
            variant="outlined"
            color="primary"
            className={classes.button}
            disabled={!enableChangeListing}
            onClick={() => {
              setEnableChangeListing(false);
              handleToggleVehicleListing();
            }}
          >
            {isUnlistedVehicle ? 'Set as Listed Vehicle' : 'Set as Unlisted Vehicle'}
          </FsButton>
        )
      }
    >
      <div className={classes.containerCentered}>
        <DirectionsCarIcon />
        &nbsp;
        <Typography display="block" variant="h6">
          Vehicle
        </Typography>
      </div>
      <RestrictedPermissions
        action={isClaimPolicyManuallyFilled(claim) ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
        verb={isClaimPolicyManuallyFilled(claim) ? PERMISSION_VERBS.WRITE : PERMISSION_VERBS.READ}
      >
        <HoverActionField
          icon={isClaimPolicyManuallyFilled(claim) ? PencilIcon : InfoIcon}
          onAction={() => setOpenVehicleInfoCard(true)}
          permanent={true}
        >
          {coveredVehicle.vehicle.display_name}
        </HoverActionField>
      </RestrictedPermissions>
      <br />
      {isConfigurableCoveragesEnabled ? (
        <CoveredEntityCoveragesTable
          coveredEntity={coveredVehicle.covered_entity}
          shouldNotConfirmOnSave={isUnlistedVehicle}
          onCoverageUpdate={async (postValues, coverageKey) => {
            const coveredVehiclePath = isUnlistedVehicle
              ? 'unlisted_vehicle'
              : `policy/covered_vehicles/${coveredVehicle.id}`;
            await axios.patch(
              `/api/v1/auto_claims/${claim.id}/${coveredVehiclePath}/coverage/${coverageKey}`,
              postValues
            );
          }}
          onSharedCoverageUpdate={async (postValues, sharedCoverageKey) => {
            const coveredVehiclePath = isUnlistedVehicle
              ? 'unlisted_vehicle'
              : `policy/covered_vehicles/${coveredVehicle.id}`;
            await axios.patch(
              `/api/v1/auto_claims/${claim.id}/${coveredVehiclePath}/shared_coverage/${sharedCoverageKey}`,
              postValues
            );
          }}
        />
      ) : (
        <PlainTable
          columns={coveredVehicleColumns}
          rows={claim.supported_coverages
            .filter((coverage_type) => AUTO_COVERAGES_DICT[coverage_type].coverage_alias === undefined)
            .filter((coverage_type) => !AUTO_COVERAGES_DICT[coverage_type].is_outside_of_policy)
            .map((coverage_type, idx) => {
              return { id: idx, coverage_type };
            })}
        />
      )}
      {isNewUIEnabled && (
        <>
          {!isClaimPolicyManuallyFilled(claim) ? (
            <NewShowOnlyVehicleDetailsInfoDialog
              vehicle={coveredVehicle}
              open={openVehicleInfoCard}
              onClose={() => setOpenVehicleInfoCard(false)}
            />
          ) : (
            <NewEditVehicleDetailsInfoDialog
              vehicle={coveredVehicle}
              open={openVehicleInfoCard}
              onClose={() => setOpenVehicleInfoCard(false)}
              OnSaveVehicleDetails={handleEditVehicleDetails}
            />
          )}
        </>
      )}
      {!isNewUIEnabled && (
        <>
          {!isClaimPolicyManuallyFilled(claim) ? (
            <ShowOnlyVehicleDetailsInfoDialog
              vehicle={coveredVehicle}
              open={openVehicleInfoCard}
              onClose={() => setOpenVehicleInfoCard(false)}
            />
          ) : (
            <EditVehicleDetailsInfoDialog
              vehicle={coveredVehicle}
              open={openVehicleInfoCard}
              onClose={() => setOpenVehicleInfoCard(false)}
              OnSaveVehicleDetails={handleEditVehicleDetails}
            />
          )}
        </>
      )}
    </CardDialog>
  );
}

AutoPolicyCoveredVehicleCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
  coveredVehicle: PropTypes.object.isRequired,
  isUnlistedVehicle: PropTypes.bool,
  isRental: PropTypes.bool,
};

function AutoPolicyCoveredPedestrianCard({ claim, pedestrianCoveredEntity, firstParty }) {
  const classes = useStyles();

  const isBicyclist = firstParty.non_motorist_type === 'bicyclist';
  const pedestrianTitle = isBicyclist ? 'Bicyclist' : 'Pedestrian';
  const PedestrianIcon = isBicyclist ? DirectionsBikeIcon : PersonIcon;

  return (
    <CardDialog
      className={classes.CardDialog}
      title={claim.incident.incident_sub_type.replaceAll('_', ' ')}
      headerStyle={{ 'text-transform': 'capitalize' }}
    >
      <div className={classes.containerCentered}>
        <PedestrianIcon />
        &nbsp;
        <Typography display="block" variant="h6">
          {pedestrianTitle}
        </Typography>
      </div>
      <ContactShowOnlyTextField
        contactId={firstParty.involved_non_motorist.contact_id}
        contactDisplayName={firstParty.involved_non_motorist.contact_full_name}
        fullWidth
        showOnly
      />
      <br />
      <CoveredEntityCoveragesTable
        coveredEntity={pedestrianCoveredEntity}
        shouldNotConfirmOnSave
        onCoverageUpdate={async (postValues, coverageTypeId) => {
          await axios.patch(
            `/api/v1/auto_claims/${claim.id}/pedestrian_covered_entity/coverage/${coverageTypeId}`,
            postValues
          );
        }}
        onSharedCoverageUpdate={async (postValues, sharedCoverageKey) => {
          await axios.patch(
            `/api/v1/auto_claims/${claim.id}/pedestrian_covered_entity/shared_coverage/${sharedCoverageKey}`,
            postValues
          );
        }}
      />
    </CardDialog>
  );
}

AutoPolicyCoveredPedestrianCard.propTypes = {
  claim: PropTypes.object.isRequired,
  pedestrianCoveredEntity: PropTypes.object.isRequired,
  firstParty: PropTypes.object.isRequired,
};

function AutoPolicyListedDriversCard({ namedDrivers }) {
  const classes = useStyles();

  return (
    <CardDialog className={classes.CardDialog} title="Drivers List" action={<EditManuallyFilledPolicyContainer />}>
      <Grid container spacing={spacing}>
        {namedDrivers.map((driver) => (
          <Grid key={driver.id} item xs={12}>
            <ContactShowOnlyTextField
              contactId={driver.id}
              contactDisplayName={driver.full_name}
              label="Named Driver"
              fullWidth
              showOnly
            />
          </Grid>
        ))}
      </Grid>
    </CardDialog>
  );
}

AutoPolicyListedDriversCard.propTypes = {
  namedDrivers: PropTypes.array.isRequired,
};

function EditCoverageHover(props) {
  const { claim, onUpdate, coverageType, coveredVehicle, displayIcon, isUnlistedVehicle } = props;

  const classes = useStyles();
  const { user } = useCms();
  const [showEditCoverage, setShowEditCoverage] = useState(false);

  let initialValues = {};
  let yupShape = {};

  if (AUTO_COVERAGES_DICT[coverageType].is_differ_per_person_and_accident) {
    const limitPerPerson = coveredVehicle[`${coverageType}_per_person_limit`];
    const limitPerAccident = coveredVehicle[`${coverageType}_per_accident_limit`];
    initialValues[`${coverageType}_per_person_limit`] = limitPerPerson !== null ? limitPerPerson : '';
    initialValues[`${coverageType}_per_accident_limit`] = limitPerAccident !== null ? limitPerAccident : '';

    yupShape[`${coverageType}_per_person_limit`] = Yup.number().required('Required');
    yupShape[`${coverageType}_per_accident_limit`] = Yup.number().required('Required');
  } else if (AUTO_COVERAGES_DICT[coverageType].boolean_option) {
    const coverageLimit = coveredVehicle[coverageType];
    initialValues[coverageType] = coverageLimit !== null ? coverageLimit : false;
    yupShape[coverageType] = Yup.boolean().nullable().required('Required');
  } else if (AUTO_COVERAGES_DICT[coverageType].is_limit_exists !== false) {
    const coverageLimit = coveredVehicle[`${coverageType}_limit`];
    initialValues[`${coverageType}_limit`] = coverageLimit !== null ? coverageLimit : '';
    yupShape[`${coverageType}_limit`] = Yup.number().required('Required');
  }

  if (AUTO_COVERAGES_DICT[coverageType].is_deduction_exist) {
    const deductibleLimit = coveredVehicle[`${coverageType}_deduction`];
    initialValues[`${coverageType}_deduction`] = deductibleLimit !== null ? deductibleLimit : '';
    yupShape[`${coverageType}_deduction`] = Yup.number().required('Required');
  }
  return (
    <>
      {!user.role.is_view_only && (
        <HoverActionField onAction={() => setShowEditCoverage(true)} permanent={displayIcon} smallIcon />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(yupShape)}
        onSubmit={async (values, formikProps) => {
          try {
            const coveredVehiclePath = isUnlistedVehicle
              ? 'unlisted_vehicle'
              : `policy/covered_vehicles/${coveredVehicle.id}`;
            await axios.patch(`/api/v1/auto_claims/${claim.id}/${coveredVehiclePath}`, values);
            await onUpdate();
            setShowEditCoverage(false);
            formikProps.setSubmitting(false);
          } catch (error) {
            formikProps.setSubmitting(false);
            reportAxiosError(error);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit, resetForm } = formikProps;

          return (
            <CardDialog
              isDialog={true}
              open={showEditCoverage}
              title={`Edit ${AUTO_COVERAGES_DICT[coverageType].desc} coverage`}
              maxWidth="xs"
              onClose={() => {
                setShowEditCoverage(false);
                resetForm();
              }}
              preventClose={isSubmitting}
            >
              <Grid container spacing={spacing}>
                {AUTO_COVERAGES_DICT[coverageType].is_differ_per_person_and_accident ? (
                  <>
                    <Grid item xs={12}>
                      <MonetaryValueTextFieldFormik
                        id={`${coverageType}_per_person_limit`}
                        label="Coverage Per Person"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MonetaryValueTextFieldFormik
                        id={`${coverageType}_per_accident_limit`}
                        label="Coverage Per Accident"
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    {AUTO_COVERAGES_DICT[coverageType].boolean_option && (
                      <Grid item xs={12}>
                        <CheckboxFormik id={coverageType} label="Is Covered" fullWidth />
                      </Grid>
                    )}
                    {AUTO_COVERAGES_DICT[coverageType].is_limit_exists !== false && (
                      <Grid item xs={12}>
                        <MonetaryValueTextFieldFormik id={`${coverageType}_limit`} label="Coverage Limit" />
                      </Grid>
                    )}
                  </>
                )}
                {AUTO_COVERAGES_DICT[coverageType].is_deduction_exist && (
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik id={`${coverageType}_deduction`} label="Coverage Deductible" />
                  </Grid>
                )}
              </Grid>
              <div className={classes.buttonsContainer}>
                {isUnlistedVehicle || claim.policy.is_manually_created ? (
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Save
                  </Button>
                ) : (
                  <WithConfirm
                    title="Update Policy?"
                    contentText="This change will effect the policy and any claim related to it"
                    primaryButtonName="Yes"
                    shouldCloseOnPrimary={false}
                  >
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Save
                    </Button>
                  </WithConfirm>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

EditCoverageHover.propTypes = {
  claim: PropTypes.object.isRequired,
  coveredVehicle: PropTypes.object.isRequired,
  displayIcon: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  coverageType: PropTypes.string.isRequired,
  isUnlistedVehicle: PropTypes.bool,
};

export { AutoPolicyDetailsCard, autoPolicyDetailsFields };
export default AutoPolicyPage;
