import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography } from '@material-ui/core';
import { noop } from 'lodash';

import cn from '~/Utils/cn';

import { ChevronDownIcon, ChevronUpIcon } from '../../icons';

import styles from './Collapsible.module.scss';

const EXPANSION_MODES = { drawer: 'drawer', grow: 'grow' };

const VARIANT = {
  default: 'default',
  slim: 'slim',
};

const TITLE_COLOR = {
  buttonLink: 'buttonLink',
  textPrimary: 'textPrimary',
  error: 'error',
};

const variantClassNames = {
  container: {
    [VARIANT.default]: 'containerDefault',
    [VARIANT.slim]: 'containerSlim',
  },
  title: {
    [VARIANT.default]: 'titleDefault',
    [VARIANT.slim]: 'titleSlim',
  },
  subTitle: {
    [VARIANT.default]: 'subtitleDefault',
    [VARIANT.slim]: 'subtitleSlim',
  },
};

const titleColorClassNames = {
  [TITLE_COLOR.buttonLink]: 'titleColorButtonLink',
  [TITLE_COLOR.textPrimary]: 'titleColorTextPrimary',
  [TITLE_COLOR.error]: 'titleColorError',
};

const BACKGROUND_COLOR = {
  WHITE: 'WHITE',
  LIST: 'LIST',
};

const backgroundColorClassNames = {
  [BACKGROUND_COLOR.WHITE]: 'backgroundWhite',
  [BACKGROUND_COLOR.LIST]: 'backgroundList',
};

const BORDER_COLOR = {
  GREY: 'GREY',
  HIGHLIGHT: 'HIGHLIGHT',
};

const borderColorClassNames = {
  [BORDER_COLOR.GREY]: 'borderGrey',
  [BORDER_COLOR.HIGHLIGHT]: 'borderHighlight',
};

const CHEVRON_SIDE = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const CollapsibleWrapper = ({
  title,
  titleTag,
  isCardShadow = false,
  subtitle,
  children,
  defaultState = false,
  onCollapse = noop,
  expansionMode = EXPANSION_MODES.drawer,
  actionCard = false,
  noBorder = false,
  noBackground = false,
  noHorizontalPadding = false,
  disabled = false,
  variant = VARIANT.default,
  titleColor = TITLE_COLOR.textPrimary,
  noBorderLine = false,
  backgroundColor,
  borderColor = BORDER_COLOR.GREY,
  noDivider = false,
  actions,
  openOnChevronClickOnly = false,
  open,
  isCollapsible = true,
  unmountOnExit = true,
  additionalHeaderContent,
  summarySectionClassname,
  isActionCardDrawer = true,
  additionalSubtitleOnlyWhenOpen,
  withActionsContainerFullWidth = true,
  hideActionsOnCollapse = false,
  chevronSide = CHEVRON_SIDE.LEFT,
}) => {
  const [isOpen, setIsOpen] = useState(defaultState);
  const onClick = () => {
    if (!isCollapsible) {
      return;
    }
    setIsOpen(!isOpen);
    onCollapse();
  };

  const getMode = () => (actionCard ? 'grow' : expansionMode);

  const getChevron = () => {
    if (!isCollapsible) {
      return null;
    }
    return !disabled && isCollapsedCombined ? <ChevronUpIcon /> : <ChevronDownIcon />;
  };

  const ChevronContainer = () => (
    <span
      className={cn(styles.chevronContainer, 'pr-15')}
      onClick={(evt) => {
        evt.stopPropagation();
        onClick(evt);
      }}
    >
      {getChevron()}
    </span>
  );

  const isCollapsedCombined = open ?? isOpen;

  return (
    <>
      <div
        className={cn(
          styles.collapsibleWrapper,
          styles[backgroundColorClassNames[backgroundColor]],
          styles[borderColorClassNames[borderColor]],
          {
            [styles.collapsibleActionCard]: actionCard,
            [styles.cardShadow]: isCardShadow,
            [styles.noBorder]: noBorder,
            [styles.noBackground]: noBackground,
            [styles.noHorizontalPadding]: noHorizontalPadding,
            [styles.noBorderLine]: noBorderLine,
            [cn('flex w-full items-center justify-between')]: chevronSide === CHEVRON_SIDE.RIGHT,
          }
        )}
      >
        <div
          className={cn(
            styles.container,
            {
              [styles.actionCardContainer]: actionCard,
              [styles.noBottomBorder]: noDivider && actionCard,
              [styles.cursorDefault]: openOnChevronClickOnly,
            },
            styles[variantClassNames.container[variant]]
          )}
          onClick={openOnChevronClickOnly ? noop : onClick}
        >
          <div className={cn(styles.summarySectionContainer, summarySectionClassname)}>
            {chevronSide === CHEVRON_SIDE.LEFT && <ChevronContainer />}
            <div className={styles.titleSectionContainer}>
              <div className={styles.titleContainer}>
                <Typography
                  className={cn(
                    styles.title,
                    styles[variantClassNames.title[variant]],
                    styles[titleColorClassNames[titleColor]],
                    {
                      [styles.actionCardTitle]: actionCard,
                      [styles.disabled]: disabled,
                    }
                  )}
                  display="block"
                  variant="h6"
                >
                  {title}
                </Typography>
                {titleTag}
              </div>
              <Typography
                className={cn(styles.subtitle, styles[variantClassNames.subTitle[variant]], {
                  [styles.actionCardSubtitle]: actionCard,
                })}
                display="block"
                variant="subtitle1"
              >
                {subtitle}
                {isOpen && additionalSubtitleOnlyWhenOpen ? <div>{additionalSubtitleOnlyWhenOpen}</div> : null}
              </Typography>
              {additionalHeaderContent}
            </div>
            {actions && (hideActionsOnCollapse ? isOpen : true) && (
              <div
                className={cn(styles.actionsContainer, {
                  ['w-full']: withActionsContainerFullWidth,
                })}
              >
                {actions}
              </div>
            )}
          </div>
        </div>
        {chevronSide === CHEVRON_SIDE.RIGHT && <ChevronContainer />}
        {getMode() === 'grow' && (
          <Collapse
            className={isActionCardDrawer ? styles.actionCardDrawer : undefined}
            in={!disabled && isCollapsedCombined}
            timeout="auto"
            unmountOnExit={unmountOnExit}
          >
            {children}
          </Collapse>
        )}
      </div>
      {getMode() === 'drawer' && (
        <Collapse in={!disabled && isCollapsedCombined} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};

CollapsibleWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  titleTag: PropTypes.node,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultState: PropTypes.bool,
  onCollapse: PropTypes.func,
  containerStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  expansionMode: PropTypes.oneOf(Object.values(EXPANSION_MODES)),
  actionCard: PropTypes.bool,
  noBorder: PropTypes.bool,
  noBackground: PropTypes.bool,
  borderColor: PropTypes.oneOf(Object.values(BORDER_COLOR)),
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  noHorizontalPadding: PropTypes.bool,
  disabled: PropTypes.bool,
  isCardShadow: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  titleColor: PropTypes.oneOf(Object.values(TITLE_COLOR)),
  noBorderLine: PropTypes.bool,
  noDivider: PropTypes.bool,
  actions: PropTypes.node,
  openOnChevronClickOnly: PropTypes.bool,
  open: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  additionalHeaderContent: PropTypes.node,
  summarySectionClassname: PropTypes.string,
  isActionCardDrawer: PropTypes.bool,
  additionalSubtitleOnlyWhenOpen: PropTypes.string,
  withActionsContainerFullWidth: PropTypes.bool,
  hideActionsOnCollapse: PropTypes.bool,
  chevronSide: PropTypes.oneOf(Object.values(CHEVRON_SIDE)),
};

CollapsibleWrapper.VARIANT = VARIANT;
CollapsibleWrapper.TITLE_COLOR = TITLE_COLOR;
CollapsibleWrapper.EXPANSION_MODES = EXPANSION_MODES;
CollapsibleWrapper.BORDER_COLOR = BORDER_COLOR;
CollapsibleWrapper.BACKGROUND_COLOR = BACKGROUND_COLOR;
CollapsibleWrapper.CHEVRON_SIDE = CHEVRON_SIDE;

export default CollapsibleWrapper;
