import React from 'react';

import CardDialog from '~/components/CardDialog';
import type { Communication, DocumentMetadata, InternalCommunication } from '~/components/communications/types';
import Divider from '~/components/core/Atomic/Divider/Divider';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import NotesPagination from '~/components/Notes/NotesPagination';
import useDataFetcher from '~/components/useDataFetcher';
import type { NOTE_SUBJECT } from '~/Types';

import NoteMiniCard from './NoteMiniCard';
import NotesFilter from './NotesFilter';

export interface Note {
  id: number;
  type: string;
  claim_id: number;
  claim_internal_id: number;
  claim_id_display: string;
  exposure_ids: number[];
  title: string;
  subject: keyof typeof NOTE_SUBJECT;
  creation_date: string;
  is_automatic: boolean;
  is_confidential: boolean;
  creator: string;
  note_text: string;
  is_labeled_general_exposure: boolean; // GenericNote
  communication?: Communication; // CommunicationNote
  is_detached?: boolean; // CommunicationNote
  document?: DocumentMetadata; // DocumentNote
  document_id?: number; // DocumentNote
  documents?: DocumentMetadata[]; // PhotosNote / MultipleDocumentsNote
  document_ids?: number[]; // PhotosNote / MultipleDocumentsNote
  action: string; // DocumentNote / CommunicationNote
  internal_communication: InternalCommunication; // InternalCommunicationNote
}

interface NotesProps {
  isActive?: boolean;
  noCard?: boolean;
  hideTypeFilter?: boolean;
  claim: { id: number };
  exposureOptionsIds?: number[];
  onNoteClick: () => void;
}

const Notes: React.FC<NotesProps> = ({ isActive = true, noCard, hideTypeFilter, claim }) => {
  const {
    isLoading,
    isError,
    data: notes = [],
    reloadData,
  }: { isLoading: boolean; isError: boolean; data?: Note[]; reloadData: () => void } = useDataFetcher(
    `/api/v1/claims/${claim.id}/notes`
  ); // TODO: ellahellah - make it sorted in the BE (removed the .sort.reverse) + get pagination props
  const displayLoading = isLoading || isError;

  if (!isActive) {
    return null;
  }

  return (
    <CardDialog title="Notes" passThrough={noCard}>
      <NotesFilter hideTypeFilter={hideTypeFilter} onSearch={(values) => console.log('values', values)} />
      <NotesPagination total={20} perPage={5} />
      <Divider className="my-24" />
      {displayLoading && <SkeletonTable rowsCount={10} columnsCount={1} cellClassName="h-[120px]" />}
      {!displayLoading &&
        notes.map((note) => <NoteMiniCard key={note.id} note={note} claim={claim} onNoteUpdated={reloadData} />)}
    </CardDialog>
  );
};

export default Notes;
