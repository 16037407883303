import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export default function PayToParagraph({ paymentRequest }) {
  return (
    <Typography variant="caption" display="block" style={{ marginBottom: '8px' }}>
      <span style={{ textDecoration: 'underline' }}>Pay To</span>:&nbsp;
      <em>{paymentRequest.pay_to_line}</em>
    </Typography>
  );
}
PayToParagraph.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
};
