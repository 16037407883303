import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsButtonWrapper } from '../../../../core';
import DisableableTooltip from '../../../../DisableableTooltip';
import ExposureQaTable from '../../ExposureQaTable';
import QaQueryFilters from '../../Filters';

import { useStyles } from '../../../../../assets/styles';

const QaQueryDialog = ({ onClose, onUpdate, qaQueryResults }) => {
  const classes = useStyles();
  const areAllQaSubmitted = qaQueryResults.entries.every((entry) => entry.qa_status && entry.qa_status === 'Submitted');
  const isCompleted = !!qaQueryResults.datetime_completed;

  React.useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        onUpdate();
      }
    }

    if (isCompleted) {
      return;
    }

    // The user selected exposures which are opened in a new tab, the user submits the QA and new tab is closed
    // to reflect the newly-submitted QA, refresh when returning to this tab
    document.addEventListener('visibilitychange', onVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onUpdate, isCompleted]);

  async function handleCompleteQuery() {
    try {
      await axios.post(`/api/v1/qa_queries/${qaQueryResults.id}/complete`);
      await onUpdate();
      onClose();
    } catch (error) {
      reportAxiosError(error);
    }
  }

  const title = `Files Query - ${qaQueryResults.query_name}`;
  const cardAction = [
    <IconButton key="refresh" onClick={onUpdate}>
      <RefreshIcon />
    </IconButton>,
  ];

  return (
    <CardDialog title={title} isDialog onClose={onClose} maxWidth="lg" fullWidth action={cardAction}>
      <QaQueryFilters filters={qaQueryResults.filters} showOnly />
      <ExposureQaTable
        entries={qaQueryResults.entries}
        subOrganizationId={qaQueryResults.filters?.sub_organization_id}
        claimType={qaQueryResults.filters?.claim_type}
        includeQaStatus
      />
      {!qaQueryResults.datetime_completed && (
        <div className={classes.buttonsContainer}>
          <DisableableTooltip title="All QAs must be submitted first" disabled={!areAllQaSubmitted}>
            <PermissionsButtonWrapper verb={PERMISSION_VERBS.WRITE} action={PERMISSION_ACTIONS.QA}>
              <Button variant="contained" color="primary" disabled={!areAllQaSubmitted} onClick={handleCompleteQuery}>
                Complete Query
              </Button>
            </PermissionsButtonWrapper>
          </DisableableTooltip>
        </div>
      )}
    </CardDialog>
  );
};

QaQueryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  qaQueryResults: PropTypes.object,
};

export default QaQueryDialog;
