import React, { useState } from 'react';

import type {
  VideoCallCommunication,
  VideoCallSpecificBodyCommunicationState,
} from '~/components/communications/types';
import LinkShare from '~/components/communications/VideoCommunication/LinkShare';
import PostVideoCommunicationCard from '~/components/communications/VideoCommunication/PostVideoCommunicationCard';
import VideoCallProviderSpecificDialog from '~/components/communications/VideoCommunication/VideoCallProviderSpecificDialog';
import type { ContactFullModel } from '~/components/Contacts/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import useDataFetcher from '~/components/useDataFetcher';
import { reportErrorInProductionOrThrow } from '~/Utils';

interface Props {
  // contactId is required to make sure there is a contact on the communication, because according to model it can be null
  contactId: number;
  communication: VideoCallCommunication;
  communicationState: VideoCallSpecificBodyCommunicationState;
  showActionButtons: boolean;
  onCancelMeeting?: () => Promise<void>;
}
const JoinVideoCallProviderSpecificDetails: React.FC<Props> = ({
  communication,
  contactId,
  communicationState,
  showActionButtons,
  onCancelMeeting,
}) => {
  const {
    isLoading: isLoadingContact,
    isError: isErrorContact,
    data: contact,
  }: { isLoading: boolean; isError: boolean; data: ContactFullModel } = useDataFetcher(`/api/v1/contacts/${contactId}`);
  const [isPostVideoDialogOpen, setIsPostVideoDialogOpen] = useState<boolean>(false);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState<boolean>(false);
  const [isCancellingCall, setIsCancellingCall] = useState(false);

  if (communication.provider === 'Dyte') {
    if (communicationState === 'ready' || communicationState === 'ongoing') {
      const href = communication.provider_specific_details?.guest_link;

      if (!href) {
        reportErrorInProductionOrThrow(`No guest link for Dyte video communication ${communication.id}`);
        return null;
      }

      return (
        <LoadingSwitch isLoading={isLoadingContact} isError={isErrorContact}>
          <div className="mt-30">
            A link was created for a new video call. You can copy this link or send it to the contact you want to meet
            with. <LinkShare title="Guest Link" href={href} contact={contact} adjusterName={communication.adjuster} />
            {showActionButtons ? (
              <div className="my-20 flex items-center justify-between">
                {onCancelMeeting && communicationState === 'ready' ? (
                  <Button
                    color="secondary"
                    onClick={async () => {
                      try {
                        setIsCancellingCall(true);
                        await onCancelMeeting();
                      } catch {
                        // Do Nothing
                      }
                      setIsCancellingCall(false);
                    }}
                    disabled={isCancellingCall}
                  >
                    Cancel Meeting
                  </Button>
                ) : null}
                <Button
                  onClick={() => {
                    setIsVideoDialogOpen(true);
                  }}
                  color="primary"
                  variant="contained"
                  className="ml-auto"
                >
                  {communicationState === 'ongoing' ? 'Rejoin Call' : 'Start Call'}
                </Button>
              </div>
            ) : null}
          </div>
          {isVideoDialogOpen ? (
            <VideoCallProviderSpecificDialog
              provider={communication.provider}
              communication={communication}
              onClose={() => {
                setIsVideoDialogOpen(false);
                setIsPostVideoDialogOpen(true);
              }}
            />
          ) : null}

          {isPostVideoDialogOpen ? (
            <PostVideoCommunicationCard
              cardTitle="Video Call"
              communication={communication}
              onClose={() => {
                setIsPostVideoDialogOpen(false);
              }}
            />
          ) : null}
        </LoadingSwitch>
      );
    }
    return null;
  } else {
    reportErrorInProductionOrThrow(
      `Unsupported video provider ${communication.provider} provider to JoinVideoCallProviderSpecificDetails`
    );
    return null;
  }
};

export default JoinVideoCallProviderSpecificDetails;
