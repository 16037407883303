import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { getIn, useFormikContext } from 'formik';
import noop from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import ArrayFieldFormik from '~/components/core/Formik/NestedFieldFormik/ArrayFieldFormik';
import PlusIcon from '~/components/icons/PlusIcon';

import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useExposureSubReserves } from '../hooks/useSubReserves';
import TextFieldFormik, { MonetaryValueTextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';
import styles from './SubReservesFormik.module.scss';

const SubReservesFormik = ({
  subReservesFieldId,
  totalFieldId,
  subReservesConfigMap,
  showOnly,
  isDisabled,
  totalFieldLabel,
  children,
  PendingMessage = noop,
}) => {
  const classes = useStyles();
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  useEffect(() => {
    const subReservesValue = getIn(values, subReservesFieldId);
    if (subReservesValue) {
      const sumOfSubReserves = Object.keys(subReservesValue).reduce(
        (total, currSubReserve) => total + (subReservesValue[currSubReserve] || 0),
        0
      );
      setFieldValue(totalFieldId, sumOfSubReserves);
    }
  }, [setFieldValue, totalFieldId, values, subReservesFieldId]);

  return (
    <Grid container className={styles.subReserves}>
      <Grid item xs={12} className={`${isDisabled ? styles.disabled : ''}`}>
        <MonetaryValueTextFieldFormik
          id={totalFieldId}
          label={totalFieldLabel}
          className={classes.textField}
          showOnly={true}
          disabled={isDisabled}
        />
      </Grid>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      {PendingMessage && (
        <Grid item xs={12}>
          <PendingMessage />
        </Grid>
      )}
      <Grid item xs={12} className={styles.reservesBreakdown}>
        {[...subReservesConfigMap.entries()].map(([subReserveKey, subReserveValue]) => (
          <Grid item xs={12} key={subReserveKey}>
            <MonetaryValueTextFieldFormik
              id={`${subReservesFieldId}.${subReserveKey}`}
              label={subReserveValue.desc}
              className={classes.textField}
              fullWidth
              disabled={isSubmitting || isDisabled}
              showOnly={showOnly}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

SubReservesFormik.propTypes = {
  subReservesFieldId: PropTypes.string.isRequired,
  totalFieldId: PropTypes.string.isRequired,
  subReservesConfigMap: PropTypes.object.isRequired,
  showOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  totalFieldLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
  PendingMessage: PropTypes.node,
  fullWidth: PropTypes.bool,
};

const SubReservesAmountFormik = ({ id, showOnly, label, exposure, payableType, children, PendingMessage = noop }) => {
  const { subReservesConfigMap } = useExposureSubReserves(payableType, exposure);

  return (
    <Grid container className={styles.subReserves}>
      <Grid item xs={12}>
        <SubReservesFormik
          subReservesFieldId="sub_reserves"
          totalFieldId={id}
          totalFieldLabel={label}
          subReservesConfigMap={subReservesConfigMap}
          showOnly={showOnly}
          PendingMessage={PendingMessage}
        >
          {children}
        </SubReservesFormik>
      </Grid>
    </Grid>
  );
};

SubReservesAmountFormik.propTypes = {
  id: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  children: PropTypes.node,
  PendingMessage: PropTypes.node,
  exposure: PropTypes.object,
  payableType: PropTypes.string.isRequired,
};

const NEW_FIELD_INITIAL_VALUES = { type_key: '', amount: '' };

const SingleSubReserveSelectFormik = ({
  id,
  label,
  isSubmitting,
  showOnly,
  subReservesConfigMap,
  payableWithReserve,
}) => {
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();
  const { values } = useFormikContext();

  const currentSelectValue = getIn(values, id);

  const menuItems = [...subReservesConfigMap.entries()].filter(([subReserveKey]) => {
    return (
      currentSelectValue === subReserveKey ||
      !values.sub_reserve_amounts?.some((sub_reserve_amount) => sub_reserve_amount.type_key === subReserveKey)
    );
  });

  return (
    <TextFieldFormik
      id={id}
      label={label}
      className={classes.textField}
      fullWidth
      select
      disabled={isSubmitting}
      showOnly={showOnly}
    >
      {menuItems.map(([subReserveKey, subReserveValue]) => (
        <MenuItem key={subReserveKey} value={subReserveKey}>
          {subReserveValue.desc} ({currencyFormatter.format(payableWithReserve.sub_reserves?.[subReserveKey] || 0)})
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

SingleSubReserveSelectFormik.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isSubmitting: PropTypes.bool,
  showOnly: PropTypes.bool,
  subReservesConfigMap: PropTypes.array,
  payableWithReserve: PropTypes.object,
};

const MultipleSubReservePaymentSelectFormik = ({
  showOnly,
  payableType,
  exposure,
  payableWithReserve,
  isSubmitting = false,
}) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  const { subReservesConfigMap, isMultipleSubReservePaymentsEnabled } = useExposureSubReserves(payableType, exposure);

  const shouldAllowToAddMoreSubReserve =
    isMultipleSubReservePaymentsEnabled &&
    !showOnly &&
    (values.sub_reserve_amounts?.length || 0) < subReservesConfigMap?.size;

  useEffect(() => {
    if (Array.isArray(values.sub_reserve_amounts)) {
      const amount = values.sub_reserve_amounts.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.amount;
      }, 0);
      setFieldValue('amount', amount);
    }
  }, [setFieldValue, values.sub_reserve_amounts]);

  const SUB_RESERVES_PAYMENT_FIELDS_CONFIG = [
    {
      id: 'type_key',
      label: 'Sub-reserve',
      type: 'custom',
      customComponent: SingleSubReserveSelectFormik,
      additionalProps: {
        isSubmitting,
        showOnly,
        subReservesConfigMap,
        payableWithReserve,
      },
      disableEditAfterInitialSet: true,
    },
    {
      id: 'amount',
      label: 'Amount per sub-reserve',
      type: 'custom',
      customComponent: MonetaryValueTextFieldFormik,
      additionalProps: {
        hideErrorGap: true,
        className: classes.textField,
        fullWidth: true,
        disabled: isSubmitting,
        showOnly,
      },
    },
  ];
  return (
    <div>
      <ArrayFieldFormik
        fieldId="sub_reserve_amounts"
        innerObjectConfig={SUB_RESERVES_PAYMENT_FIELDS_CONFIG}
        addNewItemButtonText={
          shouldAllowToAddMoreSubReserve ? (
            <span className="flex items-center">
              <PlusIcon iconColor="currentColor" className="mr-5" /> Add Sub-reserve
            </span>
          ) : null
        }
        disabled={isSubmitting}
        initialNewItemValues={NEW_FIELD_INITIAL_VALUES}
      />
    </div>
  );
};

MultipleSubReservePaymentSelectFormik.propTypes = {
  payableType: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  payableWithReserve: PropTypes.object.isRequired,
  exposure: PropTypes.object,
};

const SubReservePaymentAmountsFormik = ({
  showOnly,
  payableType,
  exposure,
  payableWithReserve,
  isSubmitting = false,
}) => {
  const { isSubReservesConfigEnabled } = useExposureSubReserves(payableType, exposure);

  if (!isSubReservesConfigEnabled) {
    return <></>;
  }

  return (
    <MultipleSubReservePaymentSelectFormik
      showOnly={showOnly}
      payableType={payableType}
      exposure={exposure}
      payableWithReserve={payableWithReserve}
      isSubmitting={isSubmitting}
    />
  );
};

SubReservePaymentAmountsFormik.propTypes = {
  payableType: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  payableWithReserve: PropTypes.object.isRequired,
  exposure: PropTypes.object,
};

export { SubReservePaymentAmountsFormik, SubReservesAmountFormik, SubReservesFormik };
