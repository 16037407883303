import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import CheckboxFormik from '../CheckboxFormik';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
} from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

export function getDocumentTemplateDynamicFieldsValidation(templateDynamicFields) {
  if (!templateDynamicFields) {
    return {};
  }

  let fieldValidations = {};
  templateDynamicFields.forEach((templateField) => {
    switch (templateField.type) {
      case 'mselect':
        fieldValidations[templateField.id] = Yup.array();
        return;
      case 'string':
        fieldValidations[templateField.id] = Yup.string().required('Required');
        return;
      case 'mstring':
        fieldValidations[templateField.id] = Yup.string().required('Required');
        return;
      case 'ostring':
      case 'omstring':
        fieldValidations[templateField.id] = Yup.string();
        return;
      case 'checkbox':
        fieldValidations[templateField.id] = Yup.boolean();
        return;
      case 'contact':
      case 'ocontact':
        fieldValidations[`${templateField.id}_id`] = Yup.number();
        if (templateField.type === 'contact') {
          fieldValidations[`${templateField.id}_id`] = fieldValidations[`${templateField.id}_id`].required('Required');
        }
        if (
          templateField.sub.includes('address_1') ||
          templateField.sub.includes('address_2') ||
          templateField.sub.includes('country')
        ) {
          fieldValidations[templateField.id] = Yup.object().test(
            'is-valid',
            ({ originalValue }) =>
              `Contact must contain address1, city and ${originalValue.country === 'US' ? 'state' : 'country'}`,
            function test(contact) {
              const contactId = this.parent[`${templateField.id}_id`];
              return (
                !contactId ||
                (contact &&
                  contact.street_address1 &&
                  contact.city &&
                  (contact.country === 'US' ? contact.state : contact.country))
              );
            }
          );
        }
        return;
      case 'date':
        fieldValidations[templateField.id] = Yup.date().required('Required');
        return;
      case 'odate':
        fieldValidations[templateField.id] = Yup.date();
        return;
      case 'monetary':
      case 'number':
        fieldValidations[templateField.id] = Yup.number().required('Required');
        return;
      case 'omonetary':
      case 'onumber':
        fieldValidations[templateField.id] = Yup.number();
        return;
      default:
        throw Error(`Unknown document template field type: ${templateField.type}, field id: ${templateField.id}`);
    }
  });

  return fieldValidations;
}

export function extractDynamicFieldsValuesFromFormikValues(templateDynamicFields, values) {
  // This function comes in handy to prevent passing the contact object and more to GET functions

  if (!templateDynamicFields) {
    return {};
  }

  let valuesExtracted = {};
  templateDynamicFields.forEach((templateField) => {
    switch (templateField.type) {
      case 'contact':
      case 'ocontact':
        valuesExtracted[`${templateField.id}_id`] = values[`${templateField.id}_id`];
        break;
      case 'string':
      case 'ostring':
      case 'date':
      case 'odate':
      case 'monetary':
      case 'number':
      case 'omonetary':
      case 'onumber':
      case 'mselect':
      case 'checkbox':
      case 'mstring':
      case 'omstring':
        valuesExtracted[templateField.id] = values[templateField.id];
        break;
      default:
        throw Error(`Unknown document template field type: ${templateField.type}, field id: ${templateField.id}`);
    }
  });

  return valuesExtracted;
}

export function TemplateDynamicFieldsFragment({
  dynamicFieldsUri,
  exposureId,
  templateDynamicFields,
  onUpdateTemplateDynamicFields,
}) {
  const { setFieldValue } = useFormikContext();

  const {
    isLoading,
    isError,
    data: updatedDocumentTemplateDynamicFields,
  } = useDataFetcher(dynamicFieldsUri, { params: { exposure_id: exposureId } });

  React.useEffect(() => {
    if (isLoading || isError) {
      onUpdateTemplateDynamicFields(undefined);
      return;
    }

    updatedDocumentTemplateDynamicFields.forEach((templateField) => {
      switch (templateField.type) {
        case 'contact':
        case 'ocontact':
          setFieldValue(`${templateField.id}_id`, '');
          setFieldValue(`${templateField.id}`, '');
          setFieldValue(`${templateField.id}_full_name`, '');
          break;
        case 'mselect':
          setFieldValue(templateField.id, []);
          break;
        case 'checkbox':
          setFieldValue(templateField.id, false);
          break;
        case 'string':
        case 'mstring':
        case 'omstring':
        case 'ostring':
        case 'date':
        case 'odate':
        case 'monetary':
        case 'number':
        case 'omonetary':
        case 'onumber':
          setFieldValue(templateField.id, '');
          break;
        default:
          throw Error(`Unknown document template field type: ${templateField.type}, field id: ${templateField.id}`);
      }
    });

    onUpdateTemplateDynamicFields(updatedDocumentTemplateDynamicFields);
  }, [updatedDocumentTemplateDynamicFields, isError, isLoading, setFieldValue, onUpdateTemplateDynamicFields]);

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  if (!templateDynamicFields) {
    return null;
  }

  return (
    <>
      {templateDynamicFields.map((templateField) => (
        <Grid item xs={12} key={templateField.id}>
          <TemplateDynamicField templateField={templateField} />
        </Grid>
      ))}
    </>
  );
}

TemplateDynamicFieldsFragment.propTypes = {
  dynamicFieldsUri: PropTypes.string.isRequired,
  exposureId: PropTypes.number.isRequired,
  templateDynamicFields: PropTypes.array,
  onUpdateTemplateDynamicFields: PropTypes.func.isRequired,
};

function TemplateDynamicField({ templateField }) {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const fieldIds = {
    id: templateField.id,
    label: templateField.label,
    className: classes.textField,
    fullWidth: true,
  };

  switch (templateField.type) {
    case 'string':
    case 'ostring':
      return <TextFieldFormik {...fieldIds} />;
    case 'mstring':
    case 'omstring':
      return <TextFieldFormik {...fieldIds} multiline rows={4} />;
    case 'contact':
    case 'ocontact':
      return (
        <ContactTextFieldFormik
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          {...fieldIds}
          fixedSearchResults
        />
      );
    case 'date':
    case 'odate':
      return <DatePickerTextFieldFormik {...fieldIds} />;
    case 'monetary':
    case 'omonetary':
      return <MonetaryValueTextFieldFormik {...fieldIds} />;
    case 'number':
    case 'onumber':
      return <TextFieldFormik {...fieldIds} type="number" />;
    case 'mselect':
      return (
        <MultiSelectTextFieldFormik
          {...fieldIds}
          options={Object.keys(templateField.options)}
          renderValue={(selected) => selected.map((option) => templateField.options[option]).join(', ')}
          renderOption={(option) => templateField.options[option]}
        />
      );
    case 'checkbox':
      return <CheckboxFormik {...fieldIds} />;
    default:
      throw Error(`Unknown document template field type: ${templateField.type}, field id: ${templateField.id}`);
  }
}

TemplateDynamicField.propTypes = {
  templateField: PropTypes.object.isRequired,
};
