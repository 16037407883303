import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { isoDateToUs } from '~/DateTimeUtils';
import { isPolicyManuallyFilled, reportAxiosError } from '~/Utils';

import CardDialog from '../../../CardDialog';
import WithConfirm from '../../../ConfirmModal';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../../../ContactTextFieldFormik';
import { FsButton, FsIconButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../core';
import { InvolvedPersonIcon, TrashIcon } from '../../../icons';
import { ShowOnlyTextField } from '../../../TextFieldFormik';

import styles from './NamedDriversWrapper.module.scss';

const AddDriverDialog = (props) => {
  const classes = useStyles();
  const { onClose, isLoading, onSubmit, namedDrivers = [] } = props;
  const validationSchema = Yup.object().shape({
    named_driver_id: Yup.number().required('Required'),
  });

  return (
    <CardDialog isDialog dontTrack title="Add Driver" maxWidth="sm" fullWidth onClose={onClose}>
      <Formik
        initialValues={{ named_driver_id: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {({ handleSubmit }) => (
          <>
            <ContactTextFieldFormik
              id="named_driver"
              label="Driver"
              fullWidth
              acceptedRoles={['named_driver', 'insured']}
              excludedContactIdsList={namedDrivers?.map((driver) => driver.id)}
              className={classes.textField}
              fixedSearchResults
            />
            <div className={classes.buttonsContainer}>
              <CancelButton onClick={onClose} disabled={isLoading} contained />
              <FsButton variant="contained" color="primary" disabled={isLoading} onClick={handleSubmit}>
                Save
              </FsButton>
            </div>
          </>
        )}
      </Formik>
    </CardDialog>
  );
};

AddDriverDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  namedDrivers: PropTypes.array.isRequired,
};

const NamedDriversWrapper = (props) => {
  const classes = useStyles();
  const { namedDrivers, claim, onClaimUpdate } = props;
  const [addDriverDialogIsOpen, setAddDriverDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isManualPolicy = isPolicyManuallyFilled(claim);
  const policyPath = isManualPolicy ? `named_drivers` : `api_policy/named_drivers`;

  const title = (
    <span className={styles.cardTitleWrapper}>
      <InvolvedPersonIcon />
      <span className={styles.cardTitleWrapperLabel}>Named Drivers</span>
    </span>
  );

  async function sendDeleteNamedDriverRequest(driverId) {
    setIsLoading(true);
    try {
      const url = `/api/v1/auto_claims/${claim.id}/${policyPath}/${driverId}`;
      await axios.delete(url);
      await onClaimUpdate();
      setIsLoading(false);
    } catch (error) {
      reportAxiosError(error);
      setIsLoading(false);
      throw error;
    }
    setIsLoading(false);
  }

  async function onAddDriverSubmit(values) {
    setIsLoading(true);
    try {
      const url = `/api/v1/auto_claims/${claim.id}/${policyPath}`;
      await axios.post(url, values);
      await onClaimUpdate();
      setIsLoading(false);
      setAddDriverDialogIsOpen(false);
    } catch (error) {
      reportAxiosError(error);
      setIsLoading(false);
      throw error;
    }
    setIsLoading(false);
  }

  return (
    <CardDialog
      title={title}
      headerStyle={{ padding: '0 0 24px' }}
      containerStyle={{ padding: '24px', border: '1px solid #e0e0e0', marginTop: '30px' }}
      contentStyle={{ padding: '0' }}
    >
      {namedDrivers.map((namedDriver, i) => (
        <CardDialog
          headerStyle={{ padding: '0' }}
          containerStyle={{ backgroundColor: '#f5f5f5', marginBottom: '20px', padding: '15px' }}
          contentStyle={{ padding: '0' }}
          key={i}
        >
          <Grid container spacing={1}>
            <Grid item xs={5} md={4}>
              <ContactShowOnlyTextField
                classes={classes}
                label="Name"
                contactId={namedDriver.id}
                contactDisplayName={namedDriver.full_name}
                maxWidth={110}
              />
            </Grid>
            <Grid item xs={3} md={4}>
              <ShowOnlyTextField
                classes={classes}
                label="License Number"
                showOnlyValueComponent={namedDriver.contact_extra?.driving_license_number}
              />
            </Grid>
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                label="Date of Birth"
                showOnlyValueComponent={namedDriver.date_of_birth ? isoDateToUs(namedDriver.date_of_birth) : ''}
              />
            </Grid>
            <Grid item xs={1} className={styles.deleteIconWrapper}>
              <RestrictedPermissions
                action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
                verb={PERMISSION_VERBS.WRITE}
              >
                <WithConfirm title="Delete Driver?" primaryButtonName="Delete" shouldCloseOnPrimary={true}>
                  <FsIconButton
                    className={styles.deleteIcon}
                    onClick={() => sendDeleteNamedDriverRequest(namedDriver.id)}
                    icon={TrashIcon}
                  />
                </WithConfirm>
              </RestrictedPermissions>
            </Grid>
          </Grid>
        </CardDialog>
      ))}
      <div className={classes.leftButtonsContainer}>
        <RestrictedPermissions
          action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
          verb={PERMISSION_VERBS.WRITE}
        >
          <FsButton color="primary" onClick={() => setAddDriverDialogIsOpen(true)}>
            <AddIcon className={classes.leftButtonIcon} />
            Add Driver
          </FsButton>
        </RestrictedPermissions>
      </div>
      {addDriverDialogIsOpen && (
        <AddDriverDialog
          onClose={() => setAddDriverDialogIsOpen(false)}
          onSubmit={onAddDriverSubmit}
          isLoading={isLoading}
          namedDrivers={namedDrivers}
        />
      )}
    </CardDialog>
  );
};

NamedDriversWrapper.propTypes = {
  namedDrivers: PropTypes.array.isRequired,
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
};

export default NamedDriversWrapper;
