import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import CallIcon from '@material-ui/icons/Call';
import axios from 'axios';
import * as Twilio from 'twilio-client';

import { useStyles } from '~/assets/styles';
import IncomingCallInProgressContainer from '~/components/communications/PhoneCall/IncomingCallInProgressContainer';
import OutgoingCallInProgressContainer from '~/components/communications/PhoneCall/OutgoingCallInProgressContainer';
import Button from '~/components/core/Atomic/Buttons/Button';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { reportAxiosError, reportErrorInProductionOrThrow, reportTwilioError } from '~/Utils';

import CardDialog from '../CardDialog';
import ClaimLink from '../ClaimLink';
import mixpanel from '../CmsMain/mixpanel';
import { useCms } from '../hooks/useCms';

import { CommunicationCardHeader } from './CommunicationCard';
import { NewPhoneCallCommunicationCard } from './PhoneCallCommunicationCard';

const TWILIO_ERRORS_TO_IGNORE = [31205, 31009, 31204, 31003, 31005, 31206, 31000];

function IncomingCallRecognizedDialog(props) {
  const { connection, onAcceptCall, onRejectCall, callCanceled, onClose } = props;

  const classes = useStyles();
  const [showReturnPhoneCallCommunicationCard, setShowReturnPhoneCallCommunicationCard] = useState(false);

  let claimId;
  let claimIdDisplay;
  let contactId;
  let contactFullName;
  let contactRole;
  let isCallCenterCall = false;

  if (connection.customParameters.has('is_call_center_call')) {
    isCallCenterCall = connection.customParameters.get('is_call_center_call') === 'true';
  }

  if (connection.customParameters.has('claim_id')) {
    // TODO: consider getting the communication instead of the params
    claimId = Number(connection.customParameters.get('claim_id'));
    claimIdDisplay = connection.customParameters.get('claim_id_display');
  }

  if (connection.customParameters.has('contact_id')) {
    contactId = Number(connection.customParameters.get('contact_id'));
    contactFullName = connection.customParameters.get('contact_full_name');
    contactRole = connection.customParameters.get('contact_role');
  }

  // const communicationId = Number(connection.customParameters.get('communication_id'));
  let dialogTitle = 'Incoming Phone Call';

  return (
    <>
      <CardDialog title={dialogTitle} maxWidth="xs" fullWidth isDialog>
        <CommunicationCardHeader
          channel="phone_call"
          direction="Incoming"
          contactId={contactId}
          contactFullName={contactFullName}
          contactRole={contactRole}
        />
        <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography display="block" variant="subtitle2">
            {isCallCenterCall ? connection.customParameters.get('from_number') : connection.parameters.From}
          </Typography>
        </div>
        <br />
        {isCallCenterCall && (
          <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography display="block" variant="subtitle2">
              {connection.customParameters.get('queue')} Queue Call
            </Typography>
          </div>
        )}
        {claimId && (
          <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <ClaimLink claimId={claimId} linkText={claimIdDisplay} openInNewTab />
          </div>
        )}
        <br />
        {callCanceled ? (
          <>
            <Typography display="block" align="center" variant="subtitle2" style={{ color: '#e65100' }}>
              No answer. Call Ended
            </Typography>
            <br />
            <div className={classes.container} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              {!isCallCenterCall && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowReturnPhoneCallCommunicationCard(true)}
                  disabled={connection.status() !== 'closed'}
                >
                  <CallIcon className={classes.leftButtonIcon} />
                  Call Back
                </Button>
              )}
              <Button size="small" variant="contained" color="secondary" onClick={() => onClose()}>
                Close
              </Button>
            </div>
          </>
        ) : (
          <div className={classes.container} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => onRejectCall(connection)}
              disabled={connection.status() !== 'pending'}
            >
              <BlockIcon className={classes.leftButtonIcon} />
              {isCallCenterCall ? 'Decline and mark not available' : 'Decline'}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => onAcceptCall(connection)}
              disabled={connection.status() !== 'pending'}
            >
              <CallIcon className={classes.leftButtonIcon} />
              Accept
            </Button>
          </div>
        )}
      </CardDialog>

      {showReturnPhoneCallCommunicationCard && (
        <NewPhoneCallCommunicationCard
          onCancel={() => setShowReturnPhoneCallCommunicationCard(false)}
          onNewPhoneCallCreated={() => setShowReturnPhoneCallCommunicationCard(false)}
          isNotClaimRelated
          notClaimRelatedCallPhoneNumber={connection.parameters.From}
        />
      )}
    </>
  );
}

IncomingCallRecognizedDialog.propTypes = {
  connection: PropTypes.object.isRequired,
  callCanceled: PropTypes.bool,
  onAcceptCall: PropTypes.func.isRequired,
  onRejectCall: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function CmsCallCenter() {
  const deviceRef = React.useRef(undefined);
  const [callConnection, setCallConnection] = React.useState(undefined);
  const [callStatus, setCallStatus] = React.useState(undefined);
  const [callCanceled, setCallCanceled] = React.useState(false);

  const { callInProgress, userReLogin } = useCms();
  const isCallInProgress = !!callInProgress;

  const handleCallAccepted = (connection) => {
    // TODO: Report to backend - should not happen
    setCallStatus(connection.status());
  };

  const handleIncomingCall = (connection) => {
    setCallConnection(connection);

    if (!connection.customParameters.has('communication_id')) {
      connection.ignore();
      // TODO: Report to the backend
      return;
    }

    setCallStatus(connection.status());
    setCallCanceled(false);
  };

  const handleDisconnect = (connection) => {
    // This should not happen
    // TODO: report to backend?
    setCallStatus(connection.status());
    setCallConnection(undefined);
  };

  async function setupDevice(device) {
    try {
      const res = await axios.get('/api/v1/calls/twilio_access_token_io');
      const token = res.data;
      device.setup(token);
    } catch (error) {
      // Just 'random' 'something went wrong' error won't help the adjuster
      reportAxiosError(error, false);
    }
  }

  function acceptCall(callConnection) {
    const parentCallSid = callConnection.customParameters.get('ParentCallSid');
    const communicationId = callConnection.customParameters.get('communication_id');
    const claimId = callConnection.customParameters.get('claim_id');

    let acceptCallUrl = `/call_in_progress?callSid=${parentCallSid}&currCallSid=${callConnection.parameters.CallSid}&communicationId=${communicationId}`;

    if (claimId !== undefined) {
      acceptCallUrl += `&claimId=${claimId}`;
    }

    window.open(acceptCallUrl, '_blank');
    callConnection.ignore();
    setCallConnection(undefined);
  }

  function rejectCall(callConnection) {
    callConnection.reject();
    setCallConnection(undefined);
    mixpanel.track(MIXPANEL_EVENTS.INCOMING_PHONE_CALL_COMMUNICATION_DECLINED, {
      communicationId: callConnection.customParameters.get('communication_id'),
    });
    setTimeout(userReLogin, 5000); // Update the status in case it's becoming unavailable
  }

  async function handleCancel(connection) {
    setCallStatus(connection.status());
    try {
      const parentCallStatusRes = await axios.get(
        `/api/v1/calls/call_status/${connection.customParameters.get('ParentCallSid')}`
      );
      const status = parentCallStatusRes.data.status;
      if (status !== 'in-progress') {
        setCallCanceled(true);
      }
    } catch (error) {
      reportAxiosError(error);
    }
  }

  React.useEffect(() => {
    function handleOffline(device) {
      if (!deviceRef.current) {
        // TODO: This shouldn't happen - may happen if we failed to setup the device
        // If we will try to setup the device again we may enter to infinite loop
        return;
      }

      deviceRef.current = undefined;
      setupDevice(device);
    }

    const initializeCallCenter = () => {
      const device = new Twilio.Device();

      setupDevice(device);

      device.on('ready', (device) => (deviceRef.current = device));
      device.on('offline', handleOffline); // offline might happen due to token expiration
      device.on('cancel', handleCancel);
      device.on('incoming', handleIncomingCall);
      device.on('connect', handleCallAccepted);
      device.on('disconnect', handleDisconnect);
      device.on('error', function (error) {
        if (TWILIO_ERRORS_TO_IGNORE.includes(error.code)) {
          return;
        }

        reportTwilioError(error);
      });
    };

    if (!isCallInProgress) {
      initializeCallCenter();
    }

    return () => {
      const device = deviceRef.current;
      deviceRef.current = undefined; // We don't want to reinit the device in handleOffline
      if (device) {
        device.destroy();
      }
    };
  }, [isCallInProgress]);

  if (callInProgress) {
    return <ActiveCallContainer />;
  }

  if (callConnection) {
    if (callStatus === 'pending' || (callStatus === 'closed' && callCanceled)) {
      return (
        <IncomingCallRecognizedDialog
          connection={callConnection}
          onAcceptCall={acceptCall}
          onRejectCall={rejectCall}
          onClose={() => setCallConnection(undefined)}
          callCanceled={callCanceled}
        />
      );
    }
  }

  return <></>;
}

function ActiveCallContainer() {
  const { callInProgress } = useCms();

  if (!callInProgress) {
    reportErrorInProductionOrThrow();
    return <></>;
  }

  if (callInProgress.direction === 'Incoming') {
    return <IncomingCallInProgressContainer />;
  } else if (callInProgress.direction === 'Outgoing') {
    return <OutgoingCallInProgressContainer />;
  } else {
    reportErrorInProductionOrThrow();
    return <></>;
  }
}

export default CmsCallCenter;
export { TWILIO_ERRORS_TO_IGNORE };
