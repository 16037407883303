import React from 'react';

import cn from '~/Utils/cn';

export const ChatDialogHeaderContainer: React.FC<{ isCollapsed: boolean }> = ({ children, isCollapsed }) => (
  <div
    className={cn('__HEADER__ max-h-1/2 bg-aiChat bg-cover bg-no-repeat', {
      'h-min': isCollapsed,
      'mih-1/3 f-min': !isCollapsed,
    })}
  >
    <div className="h-full w-full bg-aiV2-darkBlue bg-opacity-30">
      <div
        className="__HEADER_OVERLAY_ flex h-full flex-col gap-30"
        style={{
          background: 'radial-gradient(47.06% 47.06% at 66.33% 42.13%, #2A3746ad 0%, #305A7Fc7 100%)',
          backgroundBlendMode: 'lighten',
        }}
      >
        {children}
      </div>
    </div>
  </div>
);
