import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import PermissionDeniedTooltip from '~/components/core/Permissions/PermissionDeniedTooltip';
import { useRestrictedPermissions } from '~/components/core/Permissions/RestrictedPermissions';

import { FsWrappersWithTooltipSharedProptypes, getMuiComponentWithPointerEvents } from '../FsWrappersUtils';
import { WrapperWithTooltip } from '../UtilityComponents/WrapperWithTooltip';

const MenuItemWithStyles = getMuiComponentWithPointerEvents(MenuItem);

const FsMenuItem = (props) => {
  const { 'data-value': value, disabled, onClick, children, ignorePermissions = false, tooltipText } = props;
  const { userHasContextPermissions } = useRestrictedPermissions();
  const userHasPermissions = userHasContextPermissions || ignorePermissions;
  const isDisabled = !userHasPermissions || disabled;

  const menuItemComponent = (
    <MenuItem value={value} disabled={isDisabled} onClick={onClick}>
      {children}
    </MenuItem>
  );

  if (userHasPermissions) {
    return <WrapperWithTooltip component={menuItemComponent} tooltipText={tooltipText} />;
  }

  return (
    <PermissionDeniedTooltip>
      <MenuItemWithStyles value={value} disabled={isDisabled} onClick={(event) => event.stopPropagation()}>
        {children}
      </MenuItemWithStyles>
    </PermissionDeniedTooltip>
  );
};

FsMenuItem.propTypes = {
  'data-value': PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  ...FsWrappersWithTooltipSharedProptypes,
};

export default FsMenuItem;
