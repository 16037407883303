import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import CardDialog from '~/components/CardDialog';
import { ClaimContextProvider, useClaim } from '~/components/ClaimContainer';
import ContactDetails from '~/components/Contacts/ContactMiniCard/Details';
import IdentityHeader from '~/components/Contacts/ContactMiniCard/IdentityHeader';
import IdentitySubHeader from '~/components/Contacts/ContactMiniCard/IdentitySubHeader';
import type { ContactFullModel } from '~/components/Contacts/types';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core/Permissions/PermissionUtils';
import { useCms } from '~/components/hooks/useCms';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import { CloseIcon, PencilIcon } from '~/components/icons';
import LoadingIndicator from '~/components/LoadingIndicator';
import useOrganization from '~/components/OrganizationContext';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import PermissionsButtonWrapper from '../../core/Permissions/PermissionsButtonWrapper';

interface ClaimWrapperProps {
  claimIdToFetch?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any;
  reloadClaim: () => void;
}
const ClaimWrapper: React.FC<ClaimWrapperProps> = ({ children, claimIdToFetch, claim, reloadClaim }) => {
  return claimIdToFetch ? (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {children}
    </ClaimContextProvider>
  ) : (
    <>{children}</>
  );
};

interface ContactMiniCardPropsType {
  contact: ContactFullModel;
  claimId?: number;
  onEdit: () => void;
  onClose?: () => void;
  onUpdate: () => Promise<void>;
}

const ContactMiniCard: React.FC<ContactMiniCardPropsType> = ({ contact, onEdit, onClose, onUpdate, claimId }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  const { claim: contextClaim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();

  let claimIdToFetch = claimId;
  if (!claimId && contextClaim) {
    claimIdToFetch = contextClaim.id;
  }
  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(claimIdToFetch);

  const contactRole = organizationContactRolesDict[contact.role];
  const isSystemContact = !!contactRole.is_system;
  const hasContactWritePermission = useHasPermission({
    action: PERMISSION_ACTIONS.CONTACT,
    verb: PERMISSION_VERBS.WRITE,
  });

  if (isLoading) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <ClaimWrapper claimIdToFetch={claimIdToFetch} claim={claim} reloadClaim={reloadClaim}>
      <CardDialog
        title={<IdentityHeader contact={contact} />}
        subheader={<IdentitySubHeader contact={contact} />}
        width="470px"
        action={
          <div className="self-start">
            {hasContactWritePermission && !isSystemContact ? (
              <PermissionsButtonWrapper>
                <IconButton onClick={onEdit} disabled={user.role.is_view_only}>
                  <PencilIcon />
                </IconButton>
              </PermissionsButtonWrapper>
            ) : null}
            {onClose ? (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        }
      >
        <>
          <ContactDetails contact={contact} onUpdate={onUpdate} />
        </>
      </CardDialog>
    </ClaimWrapper>
  );
};

export default ContactMiniCard;
