import React from 'react';

import CardDialog from '~/components/CardDialog';
import type { VideoChatMessages } from '~/components/communications/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import Heading from '~/components/core/TextComponents/Heading';
import Text from '~/components/core/TextComponents/Text';

interface Props {
  chat: VideoChatMessages;
}

const ChatMessagesDisplay: React.FC<Props> = ({ chat }) => {
  return (
    <>
      {chat.map((msg) => (
        <div key={msg.created_at}>
          <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.MEDIUM} className="inline">
            {msg.display_name}:
          </Text>{' '}
          <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR} className="inline">
            {msg.text || `Sent a file ${msg.attached_document_filename}`}
          </Text>
        </div>
      ))}
    </>
  );
};

const VideoCommunicationChatDisplay: React.FC<Props> = ({ chat }) => {
  const [isChatModalOpen, setIsChatModalOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const isChatClamped = ref.current && ref.current.scrollHeight > ref.current.clientHeight;
  return (
    <div className="my-20">
      <Heading colorVariant={Heading.COLOR_VARIANTS.SECONDARY} variant={Heading.TYPES.H4}>
        Video Chat
      </Heading>
      <div className="line-clamp-2 gap-5" ref={ref}>
        <ChatMessagesDisplay chat={chat} />
      </div>
      {isChatClamped ? (
        <div className="my-10">
          <Button
            onClick={() => {
              setIsChatModalOpen(true);
            }}
            color="primary"
            className="ml-auto block"
          >
            Show Full Chat
          </Button>
        </div>
      ) : null}
      {isChatModalOpen ? (
        <CardDialog
          isDialog={true}
          title="Video Chat"
          fullWidth
          open={isChatModalOpen}
          maxWidth="sm"
          onClose={() => {
            setIsChatModalOpen(false);
          }}
        >
          <div className="max-h-[80%] overflow-y-scroll rounded-md border-1 border-solid border-slate-600 px-24 py-15">
            <ChatMessagesDisplay chat={chat} />
          </div>
        </CardDialog>
      ) : null}
    </div>
  );
};

export default VideoCommunicationChatDisplay;
