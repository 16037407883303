import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { AUTO_PASSENGER_POSITIONS } from '../../../Types';
import { getAllClaimRelatedRoles } from '../../communications/ContactUtils';
import InvolvedPersonDialog, {
  involvedPersonFields,
  InvolvedPersonFragment,
  involvedPersonValidationFields,
} from '../../InvolvedPerson';
import useOrganization from '../../OrganizationContext';
import { TextFieldFormik } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const passengerFields = {
  ...involvedPersonFields,
  was_seat_belt_worn: '',
  position: '',
};

const passengerValidationFields = {
  ...involvedPersonValidationFields,
};

function PassengerInvolvedFragment(props) {
  const {
    disabled, // Note is always enabled
    disableEditIdentity,
    showOnly,
  } = props;

  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <InvolvedPersonFragment
      contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter((role) => role !== 'witness')}
      disableEditIdentity={disableEditIdentity}
      showOnly={showOnly}
      personLabel="Passenger"
      additionalAfterContactFields={
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <TextFieldFormik
              id="position"
              select
              label="Position"
              className={classes.textField}
              showOnly={showOnly}
              disabled={disabled}
              fullWidth
            >
              {AUTO_PASSENGER_POSITIONS.map((position) => (
                <MenuItem key={position} value={position}>
                  {position}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={12}>
            <YesNoQuestionFormik
              questionText="Was seat belt worn?"
              id="was_seat_belt_worn"
              disabled={disabled || showOnly}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

PassengerInvolvedFragment.propTypes = {
  disabled: PropTypes.bool,
  disableEditIdentity: PropTypes.bool,
  showOnly: PropTypes.bool,
};

const ShowOnlyPassengerInvolvedDialog = (props) => {
  const { open, passenger, onCancel } = props;

  return (
    <InvolvedPersonDialog
      onCancel={onCancel}
      person={passenger}
      open={open}
      title="Passenger Details"
      personLabel="Passenger"
      CustomInvolvedPersonFragment={PassengerInvolvedFragment}
      showOnly
    />
  );
};

ShowOnlyPassengerInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  passenger: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { passengerFields, passengerValidationFields, ShowOnlyPassengerInvolvedDialog };
export default PassengerInvolvedFragment;
