import React, { useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '../../../icons';

import type { CollapsibleCardProps } from './types';

interface UseCollapsibleProps extends CollapsibleCardProps {
  chevronClassName?: string;
}

export const useCollapsible = ({
  collapsible,
  isOpen,
  openByDefault,
  onCollapseClick,
}: UseCollapsibleProps): {
  onClick: () => void;
  getChevron: ({ iconColor, className }: { iconColor?: string; className?: string }) => React.ReactNode;
  isOpenCombined: boolean;
} => {
  const [isCollapseOpenInternal, setIsCollapseOpenInternal] = useState(openByDefault);
  const isOpenCombined = Boolean(isOpen ?? isCollapseOpenInternal);

  const onClick = () => {
    if (!collapsible) {
      return;
    }
    setIsCollapseOpenInternal(!isCollapseOpenInternal);
    if (onCollapseClick) {
      onCollapseClick(!isOpen);
    }
  };

  const getChevron = ({ iconColor, className }: { iconColor?: string; className?: string }) => {
    if (!collapsible) {
      return null;
    }
    return isOpenCombined ? (
      <ChevronUpIcon className={className} iconColor={iconColor} />
    ) : (
      <ChevronDownIcon className={className} iconColor={iconColor} />
    );
  };

  return {
    onClick,
    getChevron,
    isOpenCombined,
  };
};
