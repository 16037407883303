import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { SortableTable } from '../../../../core';
import { PencilIcon, TrashIcon_Deprecated } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';

import { AddGenericItems, EditGenericItems } from './GenericItemsDialog';

import { useStyles } from '../../../../../assets/styles';
import styles from '../index.module.scss';

const GenericItems = ({
  itemId,
  columnsDataItemsValues,
  dialogFields,
  ItemsFragment,
  itemsInitialValues,
  itemsValidationFields,
  title = undefined,
  itemName = 'item',
}) => {
  const [openAddItemDialog, setOpenAddItemDialog] = useState(false);
  const [openEditItemDialog, setOpenEditItemDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const actionsCell = ({ onDelete }) => ({
    id: 'actions',
    numeric: false,
    label: 'Actions',
    specialCell: (item) => (
      <div>
        <InlineIconButton
          icon={PencilIcon}
          tooltipTitle="Edit"
          className={cn(classes.textIcon, classes.marginedIcon, classes.hoverableNonFilledIcon)}
          onClick={() => {
            setCurrentItem(item);
            setOpenEditItemDialog(item);
          }}
          wrapWithSpan
          iconStyle={{ width: 16, height: 16 }}
        />
        {onDelete && (
          <InlineIconButton
            icon={TrashIcon_Deprecated}
            tooltipTitle="Delete"
            className={cn(classes.textIcon, classes.marginedIcon, classes.hoverableNonFilledIcon)}
            onClick={() => onDelete(item)}
            wrapWithSpan
            iconStyle={{ width: 16, height: 16 }}
          />
        )}
      </div>
    ),
  });

  const onDeleteItem = (item) => {
    const updateItems = items.filter((currItem) => currItem !== item);
    setFieldValue(itemId, updateItems);
  };

  const columnsData = [...columnsDataItemsValues, actionsCell({ onDelete: onDeleteItem })];

  const addItemButton = (text, onClick) => (
    <div className={classes.leftButtonsContainer}>
      <Button color="primary" onClick={onClick}>
        <AddIcon className={classes.leftButtonIcon} />
        {text}
      </Button>
    </div>
  );
  const items = getIn(values, itemId, []);
  return (
    <div>
      {title && (
        <div className={styles.titleWrapper}>
          <Grid item xs={12} className={styles.itemsTitleContainer}>
            <Typography className={styles.itemsTitle}>{title}</Typography>
          </Grid>
        </div>
      )}
      {!isEmpty(items) && <SortableTable columns={columnsData} rows={items} stickyHeader />}
      {addItemButton(`ADD ${itemName.toUpperCase()}`, () => setOpenAddItemDialog(true))}
      {openAddItemDialog && (
        <AddGenericItems
          onAddItem={(values) => {
            const item = values;
            setFieldValue(itemId, [...items, item]);
            setOpenAddItemDialog(false);
          }}
          onCancel={() => {
            setOpenAddItemDialog(false);
            setCurrentItem({});
          }}
          ItemsFragment={ItemsFragment}
          itemsInitialValues={itemsInitialValues}
          itemsValidationFields={itemsValidationFields}
          itemName={itemName}
        />
      )}
      {openEditItemDialog && (
        <EditGenericItems
          onSaveItemDetails={(values) => {
            const item = values;
            const updatedItems = items.map((updateItem) => (updateItem !== currentItem ? updateItem : item));
            setFieldValue(itemId, updatedItems);
            setOpenEditItemDialog(false);
          }}
          onEditCancel={() => {
            setOpenEditItemDialog(false);
            setCurrentItem({});
          }}
          dialogFields={dialogFields}
          item={{
            ...currentItem,
          }}
          itemsValidationFields={itemsValidationFields}
          ItemsFragment={ItemsFragment}
          itemName={itemName}
        />
      )}
    </div>
  );
};

GenericItems.propTypes = {
  itemId: PropTypes.string,
  columnsDataItemsValues: PropTypes.array,
  dialogFields: PropTypes.array,
  ItemsFragment: PropTypes.object,
  itemsInitialValues: PropTypes.object,
  itemsValidationFields: PropTypes.object,
  itemName: PropTypes.string,
  title: PropTypes.string,
};

export { GenericItems };
