import React from 'react';
import { Link } from '@material-ui/core';

import { isTinRequired } from '~/components/communications/ContactUtils';
import Form1099 from '~/components/Contacts/ContactMiniCard/Details/Form1099';
import type { ContactFullModel } from '~/components/Contacts/types';
import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import useOrganization from '~/components/OrganizationContext';
import { isoDateToUs } from '~/DateTimeUtils';

interface W9PropsType {
  contact: ContactFullModel;
}

const W9: React.FC<W9PropsType> = ({ contact }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();

  if (!isTinRequired(organizationContactRolesDict, contact)) {
    return null;
  }

  const getDescription = () => (contact.w9_date ? `W9 Form - ${isoDateToUs(contact.w9_date)}` : 'W9 Form');

  return (
    <div className="mt-10 grid grid-cols-2 rounded bg-slate-200 p-10">
      <div>
        <Caption variant={Caption.VARIANTS.LABEL}>W9 Form</Caption>
        <div>
          {contact.w9_contact_document_id ? (
            <Link
              className="font-medium text-teal-600 underline"
              href={`/api/v1/contacts/${contact.id}/w9_tin`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDescription()}
            </Link>
          ) : (
            <>-</>
          )}
        </div>
      </div>

      <div>
        {contact.tin && (
          <>
            <Caption variant={Caption.VARIANTS.LABEL}>TIN Number</Caption>
            <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
              {contact.tin || '-'}
            </Text>
          </>
        )}
      </div>

      <Form1099 contact={contact} />
    </div>
  );
};

export default W9;
