import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import _, { noop } from 'lodash';

import useOrganization from '../../OrganizationContext';

import { useStyles } from '../../../assets/styles';

const SubOrganizationSelect = ({
  value,
  formatEntry = defaultFormatEntry,
  onChange = noop,
  disabled = false,
  className,
}) => {
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const classes = useStyles();

  if (!subOrganizationEnabled) {
    return null;
  }

  const handleSelectionChanged = ({ target: { value: subOrgId } }) => {
    if (onChange) {
      onChange(subOrgId);
    }
  };

  const inputProps = {};
  if (!_.isUndefined(value)) {
    inputProps['value'] = value;
  }

  return (
    <TextField
      label="Sub Organization"
      className={className ? className : classes.textField}
      fullWidth
      select
      disabled={disabled}
      onChange={handleSelectionChanged}
      {...inputProps}
    >
      {subOrganizations.map((subOrg) => (
        <MenuItem key={subOrg.id} value={subOrg.id}>
          {formatEntry(subOrg)}
        </MenuItem>
      ))}
    </TextField>
  );
};

const defaultFormatEntry = (subOrganization) => subOrganization.name;

SubOrganizationSelect.propTypes = {
  disabled: PropTypes.bool,
  formatEntry: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
};

export default SubOrganizationSelect;
