import React from 'react';

import { AiChatContainer } from '~/components/AiChat/AiChatContainer/AiChatContainer';
import { ChatDialog } from '~/components/AiChat/ChatDialog';
import { useDocumentChat } from '~/components/AiChat/DocumentChat/useDocumentChat';
import { ChatProvider } from '~/components/AiChat/hooks/useChat';

export const DocumentChatDialog: React.FC<{ documentId: number; onClose: () => void }> = ({ documentId, onClose }) => {
  const { documentBaseRoute, isReadyForChat } = useDocumentChat({
    documentId,
  });

  return (
    <ChatProvider routeSuffix={documentBaseRoute} shouldStart={isReadyForChat}>
      <ChatDialog onClose={onClose}>
        <AiChatContainer showGreetingAsMessage={true} messagesContainerClassName="px-24" />
      </ChatDialog>
    </ChatProvider>
  );
};
