import React from 'react';

import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '~/components/core';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Tooltip from '~/components/core/Atomic/Tooltip/Tooltip';
import Text from '~/components/core/TextComponents/Text';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import type { ExposureLabelAndId, NotePartProps } from '~/components/Notes/NoteMiniCard';
import { serverDateTimeToLocal, serverDateToLocal } from '~/DateTimeUtils';

import { LockedIcon } from '../icons';

interface NoteHeaderProps extends NotePartProps {
  exposureLabels: ExposureLabelAndId[];
  noteSubjectConfig: { display: string; icon: string; is_manual: boolean };
}
const NoteHeader: React.FC<NoteHeaderProps> = ({ note, exposureLabels, noteSubjectConfig }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-5">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          #{note.claim_internal_id}
        </Text>
        <Text>{noteSubjectConfig.display}</Text>
        {exposureLabels
          .filter((exposure) => note.exposure_ids.includes(exposure.id))
          .map((exposure) => (
            <Chip key={exposure.id} label={exposure.label} size="small" />
          ))}
      </div>
      <div className="flex items-center gap-15">
        <RestrictedPermissions action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.FULL}>
          {note.is_confidential && (
            <Chip
              icon={<LockedIcon size={16} className="m-4 mr-0" />}
              variant="outlined"
              size="small"
              label="Confidential"
              className="p-5 font-medium"
            />
          )}
        </RestrictedPermissions>
        <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
          {note.creator}
        </Text>
        <Tooltip title={serverDateTimeToLocal(note.creation_date)}>
          <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
            {serverDateToLocal(note.creation_date)}
          </Text>
        </Tooltip>
        <span className="-mb-2">
          <ThreeDotsMenu options={[]} />
        </span>
      </div>
    </div>
  );
};

export default NoteHeader;
