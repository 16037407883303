import React from 'react';
import { Typography } from '@material-ui/core';
import axios from 'axios';

import { isExposureFinancialOnly, reportAxiosError, reportErrorInProductionOrThrow } from '../../Utils';
import { isClaimWriteDisabled } from '../../Utils/ClaimUtils';
import { useClaim } from '../ClaimContainer';
import { useCms } from '../hooks/useCms';
import TooltipLock from '../TooltipLock';

import CoverageDecisionContainer from './CoverageDecisionContainer';

function ClaimCoverageDecisionContainer() {
  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();

  if (!isExposureFinancialOnly(userOrganization)) {
    reportErrorInProductionOrThrow(
      `ClaimCoverageDecisionContainer was called for a claim which is not supported: ${claim.id} `
    );
    return null;
  }

  const coverageDecision = claim.coverage_decision;

  const handleUpdateCoverageDecision = async (values) => {
    try {
      const method = coverageDecision ? 'put' : 'post';
      await axios({ method, url: `/api/v1/claims/${claim.id}/coverage_decision`, data: values });
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const isPendingRequestExists = claim.exposures.reduce(
    (isPendingExists, exposure) => isPendingExists || exposure.is_pending_request_exists,
    false
  );
  const readOnly = isClaimWriteDisabled(claim, user);

  let title = '';
  if (isPendingRequestExists) {
    title = 'Pending request exists';
  } else if (claim.is_closed) {
    title = 'Claim Closed';
  }

  if (!coverageDecision && readOnly) {
    return <TooltipLock title={title} />;
  }

  return (
    <>
      <Typography variant="caption">Coverage Decision:</Typography>
      <CoverageDecisionContainer
        exposures={claim.exposures}
        coverageDecision={coverageDecision}
        readOnly={readOnly}
        onUpdateCoverageDecision={handleUpdateCoverageDecision}
        tooltipTitle={title}
      />
    </>
  );
}

ClaimCoverageDecisionContainer.propTypes = {};

export default ClaimCoverageDecisionContainer;
