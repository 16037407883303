import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { FormHelperText, Typography } from '@material-ui/core';
import filesize from 'filesize';
import { Line } from 'rc-progress'; // TODO: remove in NGTPA-15545

import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import cn from '~/Utils/cn';

import { FileIcon, UploadIcon } from './icons';

import { useStyles } from '../assets/styles';

const baseStyle = {
  width: 294,
  height: 180,
  borderWidth: '1px',
  borderColor: '#D0D0D0',
  borderStyle: 'dashed',
  borderRadius: '10px',
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
};

function FileDropZone({
  uploadPercent,
  error,
  errorMessage,
  file,
  onFileSelect,
  onCancelFileSelect,
  dropZoneOptions,
  fileDisplayComponent,
  baseStyleOverride = {},
  gridContainerAlignItems = 'center',
  disabled,
}) {
  const dropzoneRef = React.createRef();
  const isFileChosen = !!file;
  const classes = useStyles();

  return (
    // Important: react-dropzone doesn't manage dropped files. You need to destroy the object URL yourself whenever you don't need the preview by calling window.URL.revokeObjectURL(file.preview); to avoid memory lea
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid
        container
        style={{ height: '100%' }}
        justify="center"
        alignItems={gridContainerAlignItems}
        direction="column"
      >
        <Grid item xs={12}>
          <Dropzone
            onDrop={(files) => {
              if (files[0]) {
                onFileSelect(files[0]);
              }
            }}
            multiple={false}
            ref={dropzoneRef}
            {...dropZoneOptions}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              const isUploading = uploadPercent > 0 && uploadPercent < 100;
              return (
                <div
                  {...getRootProps({
                    // Disable click and keydown behavior
                    onClick: (event) => event.stopPropagation(),
                    onKeyDown: (event) => {
                      if (event.keyCode === 32 || event.keyCode === 13) {
                        event.stopPropagation();
                      }
                    },
                  })}
                  style={{
                    ...(isDragActive
                      ? { ...baseStyle, ...baseStyleOverride, ...activeStyle }
                      : { ...baseStyle, ...baseStyleOverride }),
                  }}
                >
                  <Grid container className="flex h-full flex-col items-center justify-center">
                    {isFileChosen && (
                      <>
                        <Grid item>
                          <Typography display="block" align="center" style={{ color: '#606060' }}>
                            File chosen
                          </Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                          <FileIcon />
                          <span style={{ padding: '8px' }}>{file.name}</span>
                          <span style={{ color: '#606060', fontSize: '12px' }}>{filesize(file.size)}</span>
                        </Grid>
                        {fileDisplayComponent ? <Grid item>{fileDisplayComponent}</Grid> : null}
                        <Grid item class={cn('mx-10 my-5 h-20')}>
                          <Line
                            className={cn('w-full stroke-teal-600', { hidden: !isUploading })}
                            percent={uploadPercent}
                            strokeWidth="1.5"
                            strokeColor="inherit"
                          />
                        </Grid>
                        <Grid item>
                          <CancelButton
                            onClick={onCancelFileSelect}
                            style={{ margin: '0' }}
                            content="remove"
                            disabled={disabled}
                          />
                        </Grid>
                      </>
                    )}
                    {!isFileChosen && (
                      <>
                        <Grid item>
                          <UploadIcon size={16} iconColor="#909090" />
                        </Grid>
                        <Grid item style={{ paddingTop: '6px', paddingBottom: '12px' }}>
                          <Typography display="block" align="center" style={{ color: '#606060' }}>
                            Drag & Drop your file here
                          </Typography>
                          <Typography display="block" align="center" style={{ color: '#606060' }}>
                            or
                          </Typography>
                        </Grid>
                        <Grid item>
                          <input {...getInputProps()} />
                          <CancelButton
                            className={classes.button}
                            variant="contained"
                            onClick={dropzoneRef.current ? dropzoneRef.current.open : null}
                            content="BROWSE"
                            style={{ margin: '0' }}
                            disabled={disabled}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              );
            }}
          </Dropzone>
        </Grid>
        <Grid item xs={12}>
          {error && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Grid>
      </Grid>
    </div>
  );
}

FileDropZone.propTypes = {
  uploadPercent: PropTypes.number.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
  onCancelFileSelect: PropTypes.func.isRequired,
  file: PropTypes.object,
  dropZoneOptions: PropTypes.object,
  fileDisplayComponent: PropTypes.node,
  baseStyleOverride: PropTypes.object,
  gridContainerAlignItems: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FileDropZone;
