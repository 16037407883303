import React from 'react';
import { getIn, useFormikContext } from 'formik';

import cn from '../../../Utils/cn';
import { FsMenuItem } from '../../core';
import useFormikChangeListener from '../../core/Formik/FormikChangeListener';
import TextFieldFormik from '../../TextFieldFormik';
import type { Option } from '../hooks/useLegalActionsConfiguration';
import { isOptionOther } from '../LegalActionsUtils';

interface OtherFieldWrapperProps {
  controllingFieldKey: string;
  controllingFieldLabel: string;
  controllingFieldOptions: Option[];
  controlledFieldKey: string;
  controlledFieldLabel: string;
  otherFieldOnly?: boolean;
  disabled?: boolean;
}

export const OptionsSelectFieldWithOtherText: React.FC<OtherFieldWrapperProps> = ({
  controllingFieldKey,
  controllingFieldLabel,
  controllingFieldOptions,
  controlledFieldKey,
  controlledFieldLabel,
  otherFieldOnly,
  disabled,
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, string>>(); // this is true for the two fields in question
  const controllingFieldValue = getIn(values, controllingFieldKey);
  const isOtherSelected = isOptionOther(controllingFieldOptions, controllingFieldValue);

  useFormikChangeListener({
    onChange: (values: Record<string, string>) => {
      if (isOptionOther(controllingFieldOptions, values[controllingFieldKey])) {
        setFieldValue(controlledFieldKey, '');
      }
    },
    listenForKeys: [controllingFieldKey],
    runOnFirstRender: false,
  });

  return (
    <>
      <div className={cn({ 'flex w-full gap-30': !otherFieldOnly })}>
        {!otherFieldOnly ? (
          <div
            className={cn('flex', {
              'w-1/2': isOtherSelected,
              'w-full': !isOtherSelected,
            })}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <TextFieldFormik
              id={controllingFieldKey}
              label={controllingFieldLabel}
              select
              fullWidth
              disabled={disabled}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {controllingFieldOptions.map((option) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <FsMenuItem key={option.key} value={option.key}>
                  {option.description}
                </FsMenuItem>
              ))}
            </TextFieldFormik>
          </div>
        ) : null}
        {isOtherSelected ? (
          <div
            className={cn('flex', {
              'w-1/2': !otherFieldOnly,
              'w-full': otherFieldOnly,
            })}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <TextFieldFormik id={controlledFieldKey} label={controlledFieldLabel} fullWidth />
          </div>
        ) : null}
      </div>
    </>
  );
};
