import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getIn, useFormikContext } from 'formik';

import FsButton from '~/components/core/FsWrappers/FsButton/FsButton';
import FsIconButton from '~/components/core/FsWrappers/FsIconButton/FsIconButton';
import Heading from '~/components/core/TextComponents/Heading';
import { useCms } from '~/components/hooks/useCms';
import { TrashIcon } from '~/components/icons';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { capitalize, isFeatureEnabled } from '~/Utils';

import { FORMIK_COMMUNICATION_METHOD_FIELD_IDS } from './constants';
import { getFullCommunicationPath } from './utils';

const FillOutCommunicationMethod = ({
  index,
  disabled,
  label,
  fieldId,
  communicationMethodName,
  typeFieldId,
  typeOptions,
}) => {
  const { userOrganization } = useCms();
  const { setFieldValue } = useFormikContext();

  // Qover - CONFIGURATION_FEATURES_NAMES.QOVER_CONTACTS_UI_UNIQUE_BEHAVIOR
  const handleTextFieldChangedAlwaysAddPlus = (event, fieldId) => {
    if (!event.target.value.startsWith('+') && event.target.value.length > 0) {
      setFieldValue(fieldId, '+' + event.target.value);
    } else {
      setFieldValue(fieldId, event.target.value);
    }
  };

  return (
    <div className={`grid ${typeFieldId ? 'grid-cols-8' : 'grid-cols-6'} gap-20`}>
      {typeFieldId ? (
        <div className="col-span-2">
          <TextFieldFormik
            id={`${getFullCommunicationPath(fieldId)}.[${index}].[${typeFieldId}]`}
            label={`${label} Type`}
            fullWidth
            select
            disabled={disabled}
          >
            {typeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {capitalize(option)}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      ) : null}
      <div className="max-w-60 col-span-3">
        <TextFieldFormik
          id={`${getFullCommunicationPath(fieldId)}.[${index}].[${communicationMethodName}]`}
          label={label}
          fullWidth
          disabled={disabled}
          {...(communicationMethodName === 'phone' &&
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.QOVER_CONTACTS_UI_UNIQUE_BEHAVIOR)
            ? {
                onChange: (e) =>
                  handleTextFieldChangedAlwaysAddPlus(
                    e,
                    `${getFullCommunicationPath(fieldId)}.[${index}].[${communicationMethodName}]`
                  ),
              }
            : null)}
        />
      </div>
      <div className="col-span-3">
        <TextFieldFormik
          id={`${getFullCommunicationPath(fieldId)}.[${index}].[${FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION}]`}
          label="Description"
          fullWidth
          disabled={disabled}
        />
      </div>
    </div>
  );
};

FillOutCommunicationMethod.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  typeFieldId: PropTypes.string,
  communicationMethodName: PropTypes.string.isRequired,
  typeOptions: requiredIf(PropTypes.array, (props) => props.typeFieldId),
};

const CommunicationMethod = ({ disabled, label, fieldId, typeFieldId, typeOptions, communicationMethodName }) => {
  const { values, setFieldValue } = useFormikContext();
  const options = getIn(values, getFullCommunicationPath(fieldId));

  const handleDelete = (index) => {
    setFieldValue(getFullCommunicationPath(fieldId), [
      ...options.slice(0, index),
      ...options.slice(index + 1, options.length),
    ]);
  };

  const handleAdd = () => {
    const initialComm = { [communicationMethodName]: '', [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]: '' };

    if (typeFieldId) {
      initialComm[typeFieldId] = '';
    }

    setFieldValue(getFullCommunicationPath(fieldId), [...options, initialComm]);
  };

  return (
    <div>
      <Heading variant="h4" className="mb-10">
        {label}
      </Heading>
      {options.map((method, index) => (
        <div
          key={`method_${method[FORMIK_COMMUNICATION_METHOD_FIELD_IDS.ID] || `index_${index}`}`}
          className="my-10 flex items-center rounded bg-slate-200 p-10"
        >
          <span className="grow">
            {method[FORMIK_COMMUNICATION_METHOD_FIELD_IDS.ID] ? (
              `${method[communicationMethodName]}${method[typeFieldId] ? ` - ${capitalize(method[typeFieldId])}` : ''}${
                method[FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]
                  ? ` - ${method[FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]}`
                  : ''
              }`
            ) : (
              <FillOutCommunicationMethod
                index={index}
                disabled={disabled}
                label={label}
                fieldId={fieldId}
                typeFieldId={typeFieldId}
                typeOptions={typeOptions}
                communicationMethodName={communicationMethodName}
              />
            )}
          </span>
          <FsIconButton onClick={() => handleDelete(index)} disabled={disabled} icon={TrashIcon} />
        </div>
      ))}
      <FsButton color={FsButton.COLORS.primary} onClick={handleAdd} disabled={disabled}>
        <AddIcon />
        <span>Add {label}</span>
      </FsButton>
    </div>
  );
};

CommunicationMethod.propTypes = {
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  typeFieldId: PropTypes.string,
  label: PropTypes.string.isRequired,
  communicationMethodName: PropTypes.string.isRequired,
  typeOptions: requiredIf(PropTypes.array, (props) => props.typeFieldId),
};

export default CommunicationMethod;
