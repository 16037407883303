import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import { FsIconButton, PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsButtonWrapper } from '../../../../core';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { InvolvedPersonIcon, InvolvedVehicleIcon, TrashIcon } from '../../../../icons';
import { InvolvedPersonsListDetails } from '../../../../InvolvedPerson';

import { DriverDetails } from './DriverInvolved';
import PassengerInvolvedFragment, {
  getInvolvedPassengerValidationFields,
  involvedPassengerFields,
} from './PassengerInvolved';
import VehicleInvolvedDetails from './VehicleDetails';

import { useStyles } from '../../../../../assets/styles';

const vehiclePartyFields = {
  involved_vehicle: null,
  passengers: [],
  driver: null,
};

function VehicleParty(props) {
  const classes = useStyles();
  const { incidentConfiguration } = useIncidentConfiguration();

  const {
    disabled,
    title,
    isInsured,
    vehicleParty,
    showOnly,
    onFetchInvolvedPerson,
    onFetchInvolvedProperty,
    onUpdateVehicle,
    onDeleteVehicle,
    allowDeleteInvolved,
    shouldHidePassengers,
    errors,
  } = props;

  const action = onDeleteVehicle && (
    <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CONTACT}>
      <WithConfirm title="Delete Vehicle?" primaryButtonName="Delete" shouldCloseOnPrimary>
        <FsIconButton onClick={onDeleteVehicle} disabled={disabled} icon={TrashIcon} />
      </WithConfirm>
    </PermissionsButtonWrapper>
  );

  return (
    <CardDialog
      title={
        <>
          {title} {action}
        </>
      }
      outlinedCard
    >
      <div>
        <div className={classes.containerCentered}>
          <InvolvedVehicleIcon />
          &nbsp;
          <Typography style={{ paddingRight: 14 }} display="block" variant="subtitle1">
            Vehicle
          </Typography>
        </div>
        <VehicleInvolvedDetails
          classes={classes}
          disabled={disabled}
          showOnly={showOnly}
          isInsured={isInsured}
          vehicleInvolvedHeader={vehicleParty.involved_vehicle}
          onFetchVehicleDetails={async () => await onFetchInvolvedProperty(vehicleParty.involved_vehicle)}
          onSetVehicleDetails={async (vehicleInvolved) =>
            await onUpdateVehicle('SET_INVOLVED_VEHICLE', vehicleInvolved)
          }
          onSaveVehicleDetails={async (vehicleInvolved) =>
            await onUpdateVehicle('UPDATE_INVOLVED_VEHICLE', vehicleInvolved)
          }
          onDeleteVehicle={allowDeleteInvolved && (async () => await onUpdateVehicle('DELETE_INVOLVED_VEHICLE', ''))}
          error={errors && errors['involved_vehicle']}
        />
      </div>
      {!shouldHidePassengers && (
        <>
          <div className={`${classes.containerCentered} ${classes.containerTitle} ${classes.inputContainer}`}>
            <InvolvedPersonIcon />
            &nbsp;
            <Typography display="block" variant="subtitle1">{`Passengers (including driver): ${
              (vehicleParty.passengers ? vehicleParty.passengers.length : 0) + (vehicleParty.driver ? 1 : 0)
            }`}</Typography>
          </div>
          <DriverDetails
            classes={classes}
            disabled={disabled}
            isInsured={isInsured}
            driver={vehicleParty.driver}
            onFetchDriverDetails={async () => await onFetchInvolvedPerson(vehicleParty.driver)}
            onSetDriverDetails={async (driver) => await onUpdateVehicle('SET_DRIVER', driver)}
            onSaveDriverDetails={async (driver) => await onUpdateVehicle('UPDATE_DRIVER', driver)}
            onDeleteDriver={allowDeleteInvolved && (() => onUpdateVehicle('DELETE_DRIVER', ''))}
            error={errors && errors['driver']}
          />
          <InvolvedPersonsListDetails
            involvedPersons={vehicleParty.passengers}
            onFetchInvolvedPersonDetails={async (passenger) => await onFetchInvolvedPerson(passenger)}
            onSaveInvolvedPersonDetails={async (passenger, idx) =>
              await onUpdateVehicle('UPDATE_PASSENGER', { passenger, idx })
            }
            onAddInvolvedPerson={async (passenger) => await onUpdateVehicle('ADD_PASSENGER', passenger)}
            onDeleteInvolvedPerson={async (passenger, idx) =>
              await onUpdateVehicle('DELETE_PASSENGER', { passenger, idx })
            }
            personLabel="Passenger"
            allowDelete={allowDeleteInvolved}
            CustomInvolvedPersonFragment={PassengerInvolvedFragment}
            customInitialFields={involvedPassengerFields}
            customValidationFields={getInvolvedPassengerValidationFields(incidentConfiguration)}
            disabled={disabled}
            contactAcceptedRoles={['insured', 'named_driver', 'spouse', 'family_member', 'claimant', 'other']}
          />
        </>
      )}
    </CardDialog>
  );
}

VehicleParty.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  vehicleParty: PropTypes.object.isRequired,
  onFetchInvolvedPerson: PropTypes.func.isRequired,
  onFetchInvolvedProperty: PropTypes.func.isRequired,
  onUpdateVehicle: PropTypes.func.isRequired,
  onDeleteVehicle: PropTypes.func,
  allowDeleteInvolved: PropTypes.bool,
  isInsured: PropTypes.bool,
  showOnly: PropTypes.bool,
  shouldHidePassengers: PropTypes.bool,
  errors: PropTypes.object,
};

export { vehiclePartyFields };
export default VehicleParty;
