import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { useClaim } from '../ClaimContainer';
import { Text } from '../core';
import { useCms } from '../hooks/useCms';
import TextFieldFormik from '../TextFieldFormik';

import additionalFinanceTableMenuOptions from './AdditionalFinanceTableMenuOptions/AdditionalFinanceTableMenuOptions';
import { ClaimsPayMethodTextFieldFormik } from './ClaimsPayMethodTextFieldFormik';
import {
  ONE_INC_CLAIMSPAY_METHODS,
  ONE_INC_CLAIMSPAY_TYPES,
  ONE_INC_EXPENSES_CLAIMSPAY_TYPES,
  ONE_INC_METHOD_CHECK_ISSUANCE_TYPES,
  ONE_INC_TYPES_REQUIRING_METHOD,
} from './consts';

import { useStyles } from '../../assets/styles';

const methodSpecificInitialValues = {
  one_inc_claimspay_type: '',
  one_inc_claimspay_method: ONE_INC_CLAIMSPAY_METHODS.LET_CUSTOMER_PICKUP,
  one_inc_lienholder_loan_account_number: '',
  one_inc_home_loan_account_number: '',
  one_inc_is_approval_required: '',
  one_inc_check_issuance_type: 'Standard',
};

const methodSpecificValidationSchema = Yup.object().shape({
  one_inc_claimspay_type: Yup.string().required('Required'),
  one_inc_claimspay_method: Yup.string().when('one_inc_claimspay_type', {
    is: (one_inc_claimspay_type) => ONE_INC_TYPES_REQUIRING_METHOD.includes(one_inc_claimspay_type),
    then: (schema) => schema.required('Required'),
  }),
  one_inc_lienholder_loan_account_number: Yup.string().when('one_inc_claimspay_type', {
    is: (one_inc_claimspay_type) => [ONE_INC_CLAIMSPAY_TYPES.LIENHOLDER].includes(one_inc_claimspay_type),
    then: (schema) => schema.required('Required'),
  }),
  one_inc_home_loan_account_number: Yup.string().when('one_inc_claimspay_type', {
    is: (one_inc_claimspay_type) => [ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_MORTGAGEE].includes(one_inc_claimspay_type),
    then: (schema) => schema.required('Required'),
  }),
  one_inc_is_approval_required: Yup.string().when('one_inc_claimspay_type', {
    is: (one_inc_claimspay_type) => [ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_VENDOR].includes(one_inc_claimspay_type),
    then: (schema) => schema.required('Required'),
  }),
  one_inc_check_issuance_type: Yup.string().when('one_inc_claimspay_method', {
    is: ONE_INC_CLAIMSPAY_METHODS.CHECK,
    then: (schema) => schema.required('Required'),
  }),
});

const PaymentDetailsComponent = ({ payableType, paymentRequestStore, showOnly = false }) => {
  const classes = useStyles();
  const { user } = useCms();

  const { values, setFieldValue } = useFormikContext();
  const { claim } = useClaim();
  const { paymentMethodConfiguration } = usePaymentsConfiguration('one_inc');

  // Select configured types out of all available (in case we removed support for a type)
  const claimsPayType = getIn(values, 'method_specific.one_inc_claimspay_type');
  const claimsPayMethod = getIn(values, 'method_specific.one_inc_claimspay_method');
  const methodSpecificApprovalRequests = values?.method_specific_approval_requests;
  const configuredClaimsPayTypes =
    paymentMethodConfiguration.method_configuration.method_specific.one_inc_claimspay_types;
  let availableClaimspayTypes = Object.values(ONE_INC_CLAIMSPAY_TYPES).filter((claimsPayType) =>
    configuredClaimsPayTypes.includes(claimsPayType)
  );

  const checkIssuanceType = values.method_specific?.one_inc_check_issuance_type;
  const isSupervisor = user.is_org_level_supervisor;

  useEffect(() => {
    // This code is to check if there needs to be a method specific approval, consider moving to a dedicated function or hook if adding more methods
    const issuanceTypesRequiringApproval = [
      ONE_INC_METHOD_CHECK_ISSUANCE_TYPES.CERTIFIED,
      ONE_INC_METHOD_CHECK_ISSUANCE_TYPES.EXPEDITED,
    ];

    const newMethodSpecificApprovalRequestsValue = { ...(methodSpecificApprovalRequests || {}) };

    delete newMethodSpecificApprovalRequestsValue.one_inc_issuance_type;

    if (claimsPayMethod === 'Check' && issuanceTypesRequiringApproval.includes(checkIssuanceType)) {
      newMethodSpecificApprovalRequestsValue.one_inc_issuance_type = {
        message: `Check issuance of type ${checkIssuanceType} requested`,
      };
    }
    if (
      !isSupervisor &&
      newMethodSpecificApprovalRequestsValue?.one_inc_issuance_type?.message !==
        methodSpecificApprovalRequests?.one_inc_issuance_type?.message
    ) {
      setFieldValue('method_specific_approval_requests', newMethodSpecificApprovalRequestsValue);
    }
  }, [checkIssuanceType, claimsPayMethod, isSupervisor, methodSpecificApprovalRequests, setFieldValue]);

  if (claim?.lob !== 'home_claim') {
    // this specific type is currently not supported outside of home context
    availableClaimspayTypes = availableClaimspayTypes.filter(
      (claimsPayType) => claimsPayType !== ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_MORTGAGEE
    );
  }

  // we currently only support Vendor for expense payments
  if (payableType === 'expenses') {
    availableClaimspayTypes = availableClaimspayTypes.filter((claimsPayType) =>
      ONE_INC_EXPENSES_CLAIMSPAY_TYPES.includes(claimsPayType)
    );
  }

  return (
    <Grid container justify="space-between" spacing={1}>
      <Grid item xs={6}>
        <TextFieldFormik
          id="method_specific.one_inc_claimspay_type"
          label="ClaimsPay Type"
          className={classes.textField}
          fullWidth
          select
          showOnly={showOnly}
        >
          {availableClaimspayTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6} />
      {claimsPayType && ONE_INC_TYPES_REQUIRING_METHOD.includes(claimsPayType) && (
        <>
          <Grid item xs={6}>
            <ClaimsPayMethodTextFieldFormik paymentRequestStore={paymentRequestStore} showOnly={showOnly} />
          </Grid>
          <Grid item xs={6}>
            {claimsPayMethod === ONE_INC_CLAIMSPAY_METHODS.CHECK && (
              <TextFieldFormik
                id="method_specific.one_inc_check_issuance_type"
                label="Issuance Type"
                className={classes.textField}
                fullWidth
                select
                showOnly={showOnly}
              >
                {Object.values(ONE_INC_METHOD_CHECK_ISSUANCE_TYPES).map((method) => (
                  <MenuItem key={method} value={method}>
                    {method}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            )}
          </Grid>
        </>
      )}
      {claimsPayType === ONE_INC_CLAIMSPAY_TYPES.LIENHOLDER && (
        <>
          <Grid item xs={6}>
            <TextFieldFormik
              id="method_specific.one_inc_lienholder_loan_account_number"
              label="Lienholder Loan Account Number"
              className={classes.textField}
              fullWidth
              showOnly={showOnly}
            />
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      {claimsPayType === ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_MORTGAGEE && (
        <>
          <Grid item xs={6}>
            <TextFieldFormik
              id="method_specific.one_inc_home_loan_account_number"
              label="Home Loan Account Number"
              className={classes.textField}
              fullWidth
              showOnly={showOnly}
            />
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      {claimsPayType === ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_VENDOR && (
        <>
          <Grid item xs={8}>
            {showOnly ? (
              <Text variant={Text.VARIANTS.SM} colorVariant={Text.COLOR_VARIANTS.PRIMARY} weight={Text.WEIGHTS.MEDIUM}>
                {values?.method_specific?.one_inc_is_approval_required ? 'Approval Required' : 'Approval Not required'}
              </Text>
            ) : (
              <YesNoQuestionFormik
                id="method_specific.one_inc_is_approval_required"
                questionText="Additional party approval required for payment"
                className={classes.textField}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={4} />
        </>
      )}
    </Grid>
  );
};

PaymentDetailsComponent.propTypes = {
  payableType: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  paymentRequestStore: PropTypes.object,
};

const MethodDetailsComponent = () => {
  return null;
};

const getMethodSpecificPaymentRequest = () => {
  return {
    methodSpecificInitialValues,
    methodSpecificValidationSchema,
    PaymentDetailsComponent,
    MethodDetailsComponent,
    additionalFinanceTableMenuOptions,
  };
};

export default getMethodSpecificPaymentRequest;
