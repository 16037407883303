// TODO - Remove in NGTPA-14881
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { getExposuresLabels, reportAxiosError } from '../Utils';

import { ExposureInvolvedHoverInfo } from './exposures/ExposuresCard';
import {
  extractDynamicFieldsValuesFromFormikValues,
  getDocumentTemplateDynamicFieldsValidation,
  TemplateDynamicFieldsFragment,
} from './Templating/TemplateUtils';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { LoadingSwitch } from './core';
import { CreateNoteDialog } from './Note';
import TextFieldFormik from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

const spacing = 1;

function CreateNoteFromTemplateDialog({ onCancel, onSubmitNote }) {
  const classes = useStyles();
  const { claim } = useClaim();

  const [createNoteValues, setCreateNoteValues] = React.useState(false);
  const [templateDynamicFields, setTemplateDynamicFields] = React.useState();

  const initialValues = {
    exposure_id: '',
    template_id: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        exposure_id: Yup.number().required('Required'),
        template_id: Yup.number().required('Required'),
        ...getDocumentTemplateDynamicFieldsValidation(templateDynamicFields),
      })}
      onSubmit={async (values, formikPros) => {
        const templatesParams = {
          exposure_id: values['exposure_id'],
          ...extractDynamicFieldsValuesFromFormikValues(templateDynamicFields, values),
        };

        try {
          const {
            data: { title, text },
          } = await axios.get(`/api/v1/claims/${claim.id}/notes/templates/${values['template_id']}/instantiate`, {
            params: templatesParams,
          });
          setCreateNoteValues({ title, text });
        } catch (error) {
          reportAxiosError(error);
        }
        formikPros.setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, handleSubmit, isSubmitting }) => {
        const exposureLabels = getExposuresLabels(claim, true);
        const chosenExposureId = values.exposure_id;
        const chosenExposure =
          chosenExposureId &&
          chosenExposureId !== 0 &&
          claim.exposures.find((exposure) => exposure.id === chosenExposureId);

        return (
          <>
            <CardDialog
              isDialog
              title="Create from Template"
              maxWidth="xs"
              fullWidth
              onClose={onCancel}
              preventClose={isSubmitting}
            >
              <Grid container alignItems="flex-end" spacing={spacing}>
                <Grid item xs={11}>
                  <TextFieldFormik
                    id="exposure_id"
                    select
                    label="Exposure Label"
                    className={classes.textField}
                    fullWidth
                  >
                    {exposureLabels.map((exposure) => (
                      <MenuItem key={exposure.id} value={exposure.id}>
                        {exposure.label}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={1}>
                  {chosenExposure ? <ExposureInvolvedHoverInfo exposure={chosenExposure} /> : null}
                </Grid>
                {values['exposure_id'] !== '' && (
                  <Grid item xs={12}>
                    <TemplateSelectFormik
                      id="template_id"
                      label="Template"
                      claimId={claim.id}
                      exposureId={values['exposure_id']}
                      fullWidth
                    />
                  </Grid>
                )}
                {values['exposure_id'] !== '' && values['template_id'] !== '' && (
                  <TemplateDynamicFieldsFragment
                    dynamicFieldsUri={`/api/v1/claims/${claim.id}/notes/templates/${values['template_id']}/dynamic_fields`}
                    exposureId={values['exposure_id']}
                    templateDynamicFields={templateDynamicFields}
                    onUpdateTemplateDynamicFields={setTemplateDynamicFields}
                  />
                )}
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      // documentTemplateDynamicFields === undefined while fetching and is [] if no dynamic params; don't allow submit while still fetching dynamic params
                      disabled={!values['template_id'] || templateDynamicFields === undefined || isSubmitting}
                    >
                      Continue
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardDialog>
            {createNoteValues && ( // dispose of the dialog after values change to get updated preview
              <CreateNoteDialog
                onClose={() => setCreateNoteValues(undefined)}
                onSubmitNote={onSubmitNote}
                defaultExposureIds={[values['exposure_id']]}
                initialTitle={createNoteValues.title}
                initialNoteText={createNoteValues.text}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

CreateNoteFromTemplateDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitNote: PropTypes.func.isRequired,
};

function TemplateSelectFormik({ id, label, claimId, exposureId, ...rest }) {
  const {
    isLoading,
    isError,
    data: templatesList,
  } = useDataFetcher(`/api/v1/claims/${claimId}/notes/templates`, { params: { exposure_id: exposureId } });
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    setFieldValue(id, '');
  }, [exposureId, setFieldValue, id]);

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <TextFieldFormik id={id} label={label} select {...rest} key={isLoading}>
        {sortBy(templatesList, ['display_name']).map((template) => (
          <MenuItem key={template.id} value={template.id}>
            {template.display_name}
          </MenuItem>
        ))}
      </TextFieldFormik>
    </LoadingSwitch>
  );
}

TemplateSelectFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
};

export default CreateNoteFromTemplateDialog;
