import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { getPolicyStatus, getPolicyStatusDuringLoss } from '../../PolicyUtils';
import { isPolicyNotFound, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import PlainTable from '../PlainTable';
import { EditManuallyFilledPolicyContainer } from '../Policy/PolicyNotFoundContainer';
import { ShowOnlyTextField } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const CustomPolicyPage = ({ EditCoverageHover }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={7} xs={12}>
        <div className={classes.cardDivRow}>
          <CustomPolicyDetailsCard />
        </div>
      </Grid>
      <Grid item md={4}>
        <div className={classes.cardDivRow}>
          <CustomCoveragesTable EditCoverageHover={EditCoverageHover} />
        </div>
      </Grid>
    </Grid>
  );
};

CustomPolicyPage.propTypes = {
  EditCoverageHover: PropTypes.func,
};

const CustomPolicyDetailsCard = () => {
  const { claim } = useClaim();
  const classes = useStyles();
  const { policy } = claim;

  if (isPolicyNotFound(claim)) {
    return (
      <CardDialog className={classes.CardDialog} title="Policy Details">
        <Typography display="block" color="secondary">
          Policy Not Found
        </Typography>
      </CardDialog>
    );
  }

  const policyStatusDuringLoss = getPolicyStatusDuringLoss(
    policy,
    claim?.incident?.date_of_loss,
    claim?.incident?.time_of_loss
  );
  const policyStatus = getPolicyStatus(policyStatusDuringLoss);

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title="Policy Details"
        action={<EditManuallyFilledPolicyContainer />}
        subheader={
          <span style={{ color: policyStatus === 'In Force' ? 'green' : 'red' }}>
            <strong>{policyStatus}</strong>
          </span>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ShowOnlyTextField label="Policy Number" classes={classes} showOnlyValueComponent={policy.policy_number} />
          </Grid>
          <Grid item xs={4}>
            <ContactShowOnlyTextField
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact.full_name}
              label="Policy Holder"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField label="Client" classes={classes} showOnlyValueComponent={policy.client} />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Effective Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Expiration Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Risk Carrier"
              classes={classes}
              showOnlyValueComponent={policy.underwriting_company}
            />
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
};

const CustomCoveragesTable = ({ EditCoverageHover }) => {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { currencyFormatter } = useCurrencyFormatter();

  const { policy } = claim;

  const handleCoverageUpdate = async (coverage_key, values) => {
    try {
      await axios.patch(`/api/v1/custom_claims/${claim.id}/policy/${policy.id}/coverage`, { [coverage_key]: values });
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  if (isPolicyNotFound(claim)) {
    return (
      <CardDialog className={classes.CardDialog} title="Coverages">
        <Typography display="block" color="secondary">
          Coverages Not Found
        </Typography>
      </CardDialog>
    );
  }

  const { coverages } = policy;

  const columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'desc',
      numeric: false,
      label: 'Coverage',
      specialCell: (row) => (
        <div>
          <Typography display="block" variant="caption">{`Coverage ${row.id.slice(-1).toUpperCase()}`}</Typography>
          <Typography display="block" variant="body2">
            {row.desc}
          </Typography>
        </div>
      ),
    },
    {
      id: 'limit',
      numeric: true,
      label: 'Limit',
      specialCell: (row) => (!row.limit ? '' : row.limit !== null && currencyFormatter.format(row.limit)),
    },
    {
      id: 'edit_coverage',
      numeric: false,
      disabledPadding: true,
      width: 20,
      specialCell: (coverage, isHover) => (
        // eslint-disable-next-line react/prop-types
        <EditCoverageHover
          coverage={{ ...coverage, coverage_name: coverage.desc }}
          displayIcon={isHover}
          onUpdate={(vals) => handleCoverageUpdate(coverage.id, vals)}
          hideUpdateConfirm
        />
      ),
    },
  ];

  const rows = Object.keys(coverages).map((coverageKey) => ({ ...coverages[coverageKey], id: coverageKey }));

  return (
    <CardDialog className={classes.CardDialog} title="Coverages">
      <PlainTable classes={classes} rows={rows} columns={columnData} />
    </CardDialog>
  );
};

CustomCoveragesTable.propTypes = {
  EditCoverageHover: PropTypes.func,
};

export { CustomPolicyPage };
