import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../../../CardDialog';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../../core';

import { useStyles } from '../../../../../assets/styles';
import involvedPartiesStyles from '../index.module.scss';
import styles from './index.module.scss';

const InvolvedPartiesInnerCard = ({
  children,
  title,
  Icon,
  action = undefined,
  dataToShow,
  isSetMode,
  iconStyle = undefined,
  onSetParty,
  propertyLabel,
  errorHelper = undefined,
  ...restProps
}) => {
  const classes = useStyles();

  const containerStyle = {
    border: '1px solid #D0D0D0',
    marginRight: '10px',
  };

  const contentStyle = {
    borderRadius: '6px',
    margin: '6px 24px 24px',
    ...(!isSetMode && { background: '#F5F5F5' }),
  };

  const headerStyle = {
    fontSize: '16px',
    fontWeight: '500',
    paddingTop: '5px',
  };

  return (
    <div className={styles.innerCardContainer}>
      <CardDialog
        title={
          <>
            <div className={styles.header}>
              {Icon && (
                <span style={iconStyle && { ...iconStyle }}>
                  <Icon />
                </span>
              )}
              <span className={styles.title}>{title}</span>
              {action && <span>{action}</span>}
            </div>
          </>
        }
        containerStyle={containerStyle}
        contentStyle={contentStyle}
        headerStyle={headerStyle}
        {...restProps}
      >
        {isSetMode ? (
          <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
            <div className={involvedPartiesStyles.buttonWrapper}>
              <FsButton className={involvedPartiesStyles.setButton} onClick={onSetParty}>
                Set {propertyLabel}
              </FsButton>
            </div>
          </RestrictedPermissions>
        ) : (
          <Grid container className={classes.container}>
            {dataToShow.map(({ field, value }, index) => (
              <Grid key={index} item xs className={styles.contentWrapper}>
                <Typography className={styles.firstBody}>{field}</Typography>
                <Typography className={styles.secondBody}>{value}</Typography>
              </Grid>
            ))}
            {children}
          </Grid>
        )}
        {errorHelper}
      </CardDialog>
    </div>
  );
};

InvolvedPartiesInnerCard.propTypes = {
  title: PropTypes.string.isRequired,
  body1: PropTypes.string,
  body2: PropTypes.string,
  address: PropTypes.object,
  iconStyle: PropTypes.object,
  dataToShow: PropTypes.object,
  Icon: PropTypes.node.isRequired,
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  isSetMode: PropTypes.bool.isRequired,
  onSetParty: PropTypes.func,
  propertyLabel: PropTypes.string.isRequired,
  errorHelper: PropTypes.object,
};

export { InvolvedPartiesInnerCard };
