import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { isArray } from 'lodash';

import { useExposureSubReserves } from '~/components/hooks/useSubReserves';

import { CurrencyFormatterContextProvider } from '../../../CurrencyFormatterContext';
import { useCms } from '../../../hooks/useCms';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';
import { SubReservePaymentAmountsFormik } from '../../SubReservesFormik';

import { useStyles } from '../../../../assets/styles';

const PaymentAmountFields = ({ readOnly, payableType, payableWithReserve, exposure }) => {
  const { values, isSubmitting } = useFormikContext();
  const { isMultipleSubReservePaymentsEnabled, isSubReservesConfigEnabled } = useExposureSubReserves(
    payableType,
    exposure
  );
  const classes = useStyles();
  const { userOrganization } = useCms();
  return (
    <>
      {isSubReservesConfigEnabled && !isMultipleSubReservePaymentsEnabled ? null : (
        <CurrencyFormatterContextProvider currency={values.request_currency}>
          <MonetaryValueTextFieldFormik
            id="amount"
            label="Amount"
            className={classes.textField}
            disabled={readOnly}
            showOnly={isMultipleSubReservePaymentsEnabled}
          />
        </CurrencyFormatterContextProvider>
      )}
      <SubReservePaymentAmountsFormik
        showOnly={readOnly}
        isSubmitting={isSubmitting}
        payableType={payableType}
        exposure={exposure}
        payableWithReserve={payableWithReserve}
      />
      {isArray(userOrganization.configuration?.supported_currencies) &&
      userOrganization.configuration.supported_currencies.length > 1 ? (
        <div>
          <TextFieldFormik
            id="request_currency"
            label="Currency"
            className={classes.textField}
            fullWidth
            select
            disabled={readOnly}
          >
            {userOrganization.configuration?.supported_currencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      ) : null}
    </>
  );
};

PaymentAmountFields.propTypes = {
  readOnly: PropTypes.bool,
  payableType: PropTypes.string.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  exposure: PropTypes.object,
};
export default PaymentAmountFields;
