interface EmailTextParams {
  contactName: string;
  adjusterName: string;
  organizationName: string;
  href: string;
}
const getEmailShareText = ({ contactName, adjusterName, organizationName, href }: EmailTextParams): string => {
  return `
                        <div>
                        <p>
                        Hi ${contactName},
                        </p>
                        <br/>
                        <p>
                        ${adjusterName} from ${organizationName} would like to start a video call with you. Click the link below to join 
                        </p>
                        <p>
                        <br/>
                        <a href="${href}">Join video call</a>
                        </p>
                        </div>
                         `;
};

export default getEmailShareText;
