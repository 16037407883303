import React from 'react';
import { MenuItem } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { useCurrentEditor } from '@tiptap/react';

import Text from '~/components/core/TextComponents/Text';
import DropDownIcon from '~/components/icons/editor/DropDownIcon';
import cn from '~/Utils/cn';

import ToolbarButton from '../ToolbarButton';

interface FontOption {
  label: string;
  value: string;
}

interface FontComponentProps {
  options: FontOption[];
  defaultOption: string;
  containerClassName?: string;
  isActiveFunc: (value: string) => boolean;
  handleChange: (value: string) => void;
  isEnabledFunc: () => boolean;
}

const FontComponent: React.FC<FontComponentProps> = ({
  options,
  containerClassName,
  defaultOption,
  handleChange,
  isActiveFunc,
  isEnabledFunc,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { editor } = useCurrentEditor();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const disabled = !editor?.isEditable || !isEnabledFunc();

  const currentActive = options.find(({ value }) => isActiveFunc(value));

  return (
    <>
      <ToolbarButton disabledHoverEffect disabled={disabled} onClick={handleClick}>
        <div className={cn('inline-flex items-center justify-between', containerClassName)}>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM} className="truncate">
            {currentActive?.label || defaultOption}
          </Text>
          <DropDownIcon className="ml-5 min-w-fit" size={12} />
        </div>
      </ToolbarButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem
            key={label}
            value={value}
            onClick={() => {
              handleChange(value);
              handleClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default FontComponent;
