import React from 'react';

import { PropertyDetails } from '~/components/core';
import { PropertyDetailsRow } from '~/components/core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { useCms } from '~/components/hooks/useCms';
import { LEGAL_ACTION_PARTY_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { getPartyFieldLabel } from '~/components/LegalActions/LegalActionsUtils';
import type { LegalActionPartyApiResponse } from '~/components/LegalActions/types';
import { PartyType } from '~/components/LegalActions/types';
import { serverDateToLocal } from '~/DateTimeUtils';

export const PartyContent: React.FC<{ party: LegalActionPartyApiResponse; singleRow?: boolean }> = ({
  party,
  singleRow,
}) => {
  const { userOrganization } = useCms() as { userOrganization: { name: string } };
  const counselSuffix = party.type === PartyType.PLAINTIFF ? ' (PC)' : ' (DC)';
  const counselLabel = `${getPartyFieldLabel(party.type, LEGAL_ACTION_PARTY_FIELDS.counsel.label)} ${counselSuffix}`;

  const contactComponent = (
    <PropertyDetails
      title={getPartyFieldLabel(party.type, LEGAL_ACTION_PARTY_FIELDS.contact.label)}
      text={party.is_carrier_party ? userOrganization.name : party.contact_name}
    />
  );
  const counselComponent = <PropertyDetails title={counselLabel} text={party.counsel_name} />;
  const fileNumberComponent = (
    <PropertyDetails
      title={getPartyFieldLabel(party.type, LEGAL_ACTION_PARTY_FIELDS.fileNumber.label)}
      text={party.file_number}
    />
  );
  const startDateComponent = (
    <PropertyDetails
      title={LEGAL_ACTION_PARTY_FIELDS.dateOfRepresentation.label}
      text={party.date_of_representation ? serverDateToLocal(party.date_of_representation) : ''}
    />
  );

  const endDateComponent = (
    <PropertyDetails
      title={LEGAL_ACTION_PARTY_FIELDS.endDateOfRepresentation.label}
      text={party.end_date_of_representation ? serverDateToLocal(party.end_date_of_representation) : ''}
    />
  );

  if (singleRow) {
    return (
      <PropertyDetailsRow>
        {contactComponent}
        {counselComponent}
        {fileNumberComponent}
        {startDateComponent}
      </PropertyDetailsRow>
    );
  }

  return (
    <div className="flex w-full flex-col gap-15">
      <div className="flex w-full">
        <PropertyDetailsRow>{contactComponent}</PropertyDetailsRow>
      </div>
      <div className="flex w-full">
        <PropertyDetailsRow>
          {counselComponent}
          {fileNumberComponent}
        </PropertyDetailsRow>
      </div>
      <div className="flex w-full">
        <PropertyDetailsRow>
          {startDateComponent}
          {endDateComponent}
        </PropertyDetailsRow>
      </div>
    </div>
  );
};
