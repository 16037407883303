import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import PrintIconOutlined from '@material-ui/icons/PrintOutlined';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../Utils';

import CommunicationsTable from './communications/CommunicationsTable';
import { ErrorHelperTextFormik } from './core/Formik/ErrorHelperTextFormik';
import DocumentsContainer, { getExposuresLabels } from './Documents/DocumentsContainer';
import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { useClaim } from './ClaimContainer';
import { LoadingSwitch } from './core';
import ExposureMultiSelectTextFieldFormik from './ExposureMultiSelectTextFieldFormik';
import InlineIconButton from './InlineIconButton';
import LoadingIndicator from './LoadingIndicator';
import RadioButtonFormik from './RadioButtonFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function ExportClaimContainer() {
  const [showExportClaimDialog, setShowExportClaimDialog] = useState(false);
  const { claim } = useClaim();

  if (!claim) {
    throw Error('Exporting claim should be within claim context');
  }

  return (
    <>
      <InlineIconButton
        defaultColor="inherit"
        onClick={() => setShowExportClaimDialog(true)}
        icon={PrintIconOutlined}
        tooltipTitle="Export/Print Claim"
      />

      {showExportClaimDialog && <ExportClaimDialog onClose={() => setShowExportClaimDialog(false)} />}
    </>
  );
}

function ExportClaimDialog({ onClose }) {
  const [showExportExposuresChoosingDialog, setShowExportExposuresChoosingDialog] = useState(true);
  const [showExportClaimDetailsDialog, setShowExportClaimDetailsDialog] = useState(false);
  const [exportExposuresParams, setExportExposuresParams] = useState({});

  const { claim } = useClaim();

  return (
    <>
      <ExportExposuresChoosingDialog
        claim={claim}
        open={showExportExposuresChoosingDialog}
        onSubmit={(values) => {
          setExportExposuresParams(values);
          setShowExportExposuresChoosingDialog(false);
          setShowExportClaimDetailsDialog(true);
        }}
        onClose={() => {
          setShowExportExposuresChoosingDialog(false);
          onClose();
        }}
      />

      <ExportClaimDetailsDialog
        open={showExportClaimDetailsDialog}
        onClose={() => {
          setShowExportClaimDetailsDialog(false);
          onClose();
        }}
        shouldExportEntireClaim={exportExposuresParams.should_export_entire_claim}
        exposureIds={exportExposuresParams.exposure_ids}
      />
    </>
  );
}

ExportClaimDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function ExportClaimDetailsDialog(props) {
  const { open, onClose, shouldExportEntireClaim, exposureIds } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();

  const handleExportClaim = async (values) => {
    try {
      const result = await axios.post(`/api/v1/claims/${claim.id}/export`, values);
      await onAsyncClaimUpdate();
      window.open(`/api/v1/claims/${claim.id}/documents/${result.data.document_id}`, '_blank');
      onClose();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  if (!open) {
    return <></>;
  }

  const isPipExposureExists = _.some(
    claim.exposures,
    (exposure) =>
      (shouldExportEntireClaim || exposureIds.includes(exposure.id)) && exposure.damage_assessment_type === 'pip'
  );
  const isLiabilityExposureExists = _.some(
    claim.exposures,
    (exposure) =>
      (shouldExportEntireClaim || exposureIds.includes(exposure.id)) && exposure.damage_assessment_type === 'liability'
  );
  const shouldExportLiabilityDashboards = isLiabilityExposureExists;
  const shouldExportPipDashboards = isPipExposureExists;

  return (
    <Formik
      initialValues={{
        should_export_notes: true,
        should_export_financial_transactions: true,
        should_export_entire_claim: shouldExportEntireClaim,
        should_export_liability_dashboards: shouldExportLiabilityDashboards,
        should_export_pip_dashboards: shouldExportPipDashboards,
        exposures_id_list: exposureIds,
        documents_id_list: [],
        communications_id_list: [],
      }}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        handleExportClaim(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <ExportClaimDetails
          open={open}
          onClose={onClose}
          shouldExportEntireClaim={shouldExportEntireClaim}
          exposureIds={exposureIds}
          isLiabilityExposureExists={isLiabilityExposureExists}
          isPipExposureExists={isPipExposureExists}
          shouldExportLiabilityDashboards={shouldExportLiabilityDashboards}
          shouldExportPipDashboards={shouldExportPipDashboards}
          {...formikProps}
        />
      )}
    </Formik>
  );
}

ExportClaimDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shouldExportEntireClaim: requiredIf(PropTypes.bool, (props) => props.open === true),
  exposureIds: requiredIf(PropTypes.array, (props) => props.shouldExportEntireClaim === false),
};

function ExportClaimDetails(props) {
  const {
    open,
    onClose,
    isSubmitting,
    handleSubmit,
    values,
    setFieldValue,
    shouldExportEntireClaim,
    exposureIds,
    shouldExportLiabilityDashboards,
    shouldExportPipDashboards,
  } = props;
  const classes = useStyles();
  const { claim } = useClaim();

  const {
    isLoading,
    isError,
    data: { communications } = {},
  } = useDataFetcher(`/api/v1/claims/${claim.id}/communications`, {
    params: {
      exposures_filters: exposureIds,
    },
  });

  const exposureLabels = getExposuresLabels(claim);

  return (
    <CardDialog
      isDialog
      open={open}
      title="Export Claim"
      subheader={
        <Grid container direction="row" justify="space-between" alignItems="center" item xs={12}>
          <Grid item md={8}>
            <Typography variant="body2">
              {shouldExportEntireClaim ? (
                <em>Exporting Entire Claim</em>
              ) : (
                <>
                  <span>Exporting exposures:</span>
                  <em>
                    {exposureIds
                      .map((exposureId) => exposureLabels.find(({ id }) => id === exposureId).label)
                      .join(', ')}
                  </em>
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <div className={classes.inLineButtonsContainer}>
              <div style={{ position: 'relative' }}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Export to PDF
                </Button>
                {isSubmitting && <LoadingIndicator size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </Grid>
          <Grid item md={8}>
            <CheckboxFormik id="should_export_notes" label="Export Notes" />
            <CheckboxFormik id="should_export_financial_transactions" label="Export Financial Transactions" />
            {shouldExportPipDashboards && (
              <CheckboxFormik id="should_export_pip_dashboards" label="Export PIP Dashboards" />
            )}
            {shouldExportLiabilityDashboards && (
              <CheckboxFormik id="should_export_liability_dashboards" label="Export Liability Dashboards" />
            )}
          </Grid>
          <Grid item md={4} container justify="flex-end">
            <Grid item>
              <Typography>Exported PDF will also be saved in claim Documents</Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      preventClose={isSubmitting}
    >
      <Grid container>
        <Grid item md={12}>
          <CardDialog title="Select Documents to Export">
            <DocumentsContainer
              classes={classes}
              claim={claim}
              selectOnly
              disableExposuresFilter
              documentsFilter={(document) => !document.is_removed && document.type !== 'claim_export'}
              selectedDocumentIds={values['documents_id_list']}
              onDocumentsClick={(documentIds) => setFieldValue('documents_id_list', documentIds)}
              maxHeight="35vh"
              autoPaginateRowsPerPage={15}
              initialValues={{ exposure_ids: shouldExportEntireClaim ? [] : exposureIds }}
            />
          </CardDialog>
        </Grid>
        <Grid item md={12}>
          <LoadingSwitch isLoading={isLoading} isError={isError}>
            <CardDialog title="Select Communication to Export">
              <CommunicationsTable
                claim={claim}
                communications={communications}
                selectedList={values['communications_id_list']}
                onSelectCommunication={(communicationIds) => setFieldValue('communications_id_list', communicationIds)}
                onUpdatedSelected={(communicationIds) => setFieldValue('communications_id_list', communicationIds)}
                maxHeight="35vh"
                autoPaginateRowsPerPage={15}
                addViewColumn
                enableSelection
              />
            </CardDialog>
          </LoadingSwitch>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

ExportClaimDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  shouldExportPipDashboards: PropTypes.bool.isRequired,
  shouldExportLiabilityDashboards: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  shouldExportEntireClaim: PropTypes.bool.isRequired,
  exposureIds: requiredIf(PropTypes.array, (props) => props.shouldExportEntireClaim === false),
};

function ExportExposuresChoosingDialog({ claim, onSubmit, onClose, open }) {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        should_should_should_export_entire_claim: '',
        exposure_ids: [],
      }}
      validationSchema={Yup.object().shape({
        should_export_entire_claim: Yup.boolean().nullable().required('Required'),
        exposure_ids: Yup.array().when('should_export_entire_claim', {
          is: false,
          then: Yup.array().required('Required').min(1, 'Required'),
        }),
      })}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <CardDialog isDialog open={open} title="Export claim to PDF" onClose={onClose} maxWidth="sm" fullWidth>
          <Grid container>
            <Grid item xs={12}>
              <RadioButtonFormik id="should_export_entire_claim" label="Export entire claim" optionValue={true} />
            </Grid>
            <Grid item xs={6}>
              <RadioButtonFormik
                id="should_export_entire_claim"
                label="Select Exposures to Export"
                optionValue={false}
              />
            </Grid>
            <Grid item xs={6}>
              {values.should_export_entire_claim === false && <ExposureMultiSelectTextFieldFormik claim={claim} />}
            </Grid>
            <ErrorHelperTextFormik id="should_export_entire_claim" />
          </Grid>
          <br />
          <Typography>
            Policy and Incident details, as well as all claim parties will be exported regardless of selection
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Proceed to select items to export
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

ExportExposuresChoosingDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export { ExportClaimDialog };
export default ExportClaimContainer;
