import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import { Formik } from 'formik';
import { capitalize } from 'lodash';
import { FileDocument, FileSendOutline } from 'mdi-material-ui';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateTimeToLocalDate } from '../../DateTimeUtils';
import { reportAxiosError, reportErrorInProductionOrThrow } from '../../Utils';
import { isClaimWriteDisabled, useFetchClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { DOCUMENT_NAME_MAX_SIZE, DocumentCoreFields } from '../Documents/DocumentCard';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import InlineIconButton from '../InlineIconButton';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';

import { useStyles } from '../../assets/styles';

export const vendorCommunicationToFormikValues = (communication) => ({
  summary: communication.summary,
});

export function VendorCommunicationSpecificBody(props) {
  const classes = useStyles();
  const { communication, isDocument, isNew, onUpdate } = props;

  const isView = !isNew && !isDocument;
  if (!isView) {
    reportErrorInProductionOrThrow('Vendor communication is currently only supported in view mode');
    return null;
  }

  return (
    <div className={classes.inputContainer}>
      <VendorsAttachmentTable vendorCommunication={communication} onUpdate={onUpdate} />
    </div>
  );
}

VendorCommunicationSpecificBody.propTypes = {
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  communicationInitialFields: PropTypes.shape({
    communicationContact: PropTypes.object,
  }),
  onUpdate: requiredIf(PropTypes.func, (props) => !props.isNew && !props.isDocument),
};

function VendorsAttachmentTable(props) {
  const { vendorCommunication, onUpdate } = props;
  const classes = useStyles();
  const { attachments } = vendorCommunication;
  const [attachmentToUploadId, setAttachmentToUploadId] = React.useState(undefined);

  const isAttachedToClaim = !!vendorCommunication.claim_id;
  const shouldAllowUploadAttachments = isAttachedToClaim;
  const { documentTypesDict } = useOrganization();

  const handleUploadDocument = async (values) => {
    try {
      await axios.post(
        `/api/v1/claims/${vendorCommunication.claim_id}/communications/vendor/${vendorCommunication.id}/attachments/${attachmentToUploadId}/upload_attachment`,
        values
      );
      await onUpdate();
      return setAttachmentToUploadId(undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const getAttachmentFileUrl = (attachment) =>
    !attachment.document
      ? `/api/v1/communications/vendor/${vendorCommunication.id}/attachments/${attachment.id}/stored_file`
      : `/api/v1/claims/${attachment.document.claim_id}/documents/${attachment.document.id}`;

  let attachmentsColumnsData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'view_action',
      specialCell: (attachment) => (
        <InlineIconButton
          icon={FileDocument}
          onClick={() => window.open(getAttachmentFileUrl(attachment), '_target')}
        />
      ),
    },
    {
      id: 'file_name',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
      // eslint-disable-next-line react/display-name
      specialCell: (attachment) =>
        attachment.document ? (
          <span>{attachment.file_name}</span>
        ) : (
          <a href={getAttachmentFileUrl(attachment)} target="_blank" rel="noopener noreferrer">
            {attachment.file_name}
          </a>
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'upload',
      numeric: false,
      label: '',
      specialCell: (attachment) =>
        attachment.document ? (
          'uploaded'
        ) : (
          <Button color="primary" onClick={() => setAttachmentToUploadId(attachment.id)}>
            <CloudUploadIcon />
          </Button>
        ),
    },
    {
      id: 'document_id',
      numeric: true,
      disablePadding: false,
      label: '#',
      // eslint-disable-next-line react/display-name
      specialCell: (attachment) =>
        attachment.document && (
          <a href={getAttachmentFileUrl(attachment)} target="_blank" rel="noopener noreferrer">
            {attachment.document.claim_internal_id}
          </a>
        ),
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      specialCell: (attachment) =>
        attachment.document && (documentTypesDict?.[attachment.document.type]?.desc || attachment.document.type),
    },
    {
      id: 'document_name',
      numeric: false,
      disablePadding: false,
      label: 'Document Name',
      specialCell: (attachment) => attachment.document && attachment.document.document_name,
    },
  ];

  if (!shouldAllowUploadAttachments) {
    attachmentsColumnsData = attachmentsColumnsData.filter((attachmentsColumn) => attachmentsColumn.id !== 'upload');
  }

  const attachmentToUpload =
    attachmentToUploadId && attachments.find((attachment) => attachment.id === attachmentToUploadId);

  return (
    <>
      <PlainTable classes={classes} columns={attachmentsColumnsData} rows={attachments} />

      {shouldAllowUploadAttachments && (
        <UploadDocumentFromVendorDialog
          open={!!attachmentToUploadId}
          attachment={attachmentToUpload}
          claimId={vendorCommunication.claim_id}
          onUploadAttachment={handleUploadDocument}
          onCancel={() => setAttachmentToUploadId(null)}
        />
      )}
    </>
  );
}

VendorsAttachmentTable.propTypes = {
  vendorCommunication: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

function UploadDocumentFromVendorDialog(props) {
  const { attachment, claimId, open, onCancel, onUploadAttachment } = props;
  const classes = useStyles();
  const [claim, isLoading, isError] = useFetchClaim(claimId);

  // We can't render the formik if we don't have the attachment
  if (!open) {
    return <></>;
  }

  return (
    <Formik
      initialValues={{
        type: '',
        document_name: '',
        document_date: serverDateTimeToLocalDate(attachment.stored_file.datetime_uploaded),
        exposure_ids: [0],
        summary: '',
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Required'),
        document_name: Yup.string().required('Required').max(DOCUMENT_NAME_MAX_SIZE),
        document_date: Yup.date().required('Required'),
        exposure_ids: Yup.array().required('Required').min(1, 'Required'),
      })}
      onSubmit={(values, formikProps) => {
        onUploadAttachment(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog
            open={open}
            title="Upload Document"
            maxWidth="xs"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            {isLoading || isError ? (
              <LoadingIndicator isError={isError} />
            ) : (
              <Grid container spacing={1}>
                <DocumentCoreFields classes={classes} claim={claim} />
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Upload
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

UploadDocumentFromVendorDialog.propTypes = {
  attachment: requiredIf(PropTypes.object, (props) => props.open),
  claimId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
};

export function SendDocumentViaVendorCommunication(props) {
  const {
    document,
    displayIcon,
    isDocumentRelevant,
    sentIndicationKeyName,
    vendorNameDisplay,
    children,
    validationSchema = {},
    getInitialValues = () => ({}),
    getSubmitRoute,
  } = props;
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const { claim, onClaimUpdate } = useClaim();
  const { user } = useCms();

  const classes = useStyles();
  // TODO: could be a more generic condition, currently not worth the effort
  if (document.document_extra[sentIndicationKeyName] && !document.is_uploading) {
    return (
      <span
        className={classes.showValueField}
        style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}
      >
        <FileSendOutline htmlColor="green" />
      </span>
    );
  }

  const cardDialogProps = {
    noCardTitle: true,
    isPopover: true,
    open: Boolean(popoverAnchorEl),
    anchorEl: popoverAnchorEl,
    closeOnBackdropClick: true,
  };

  const disabled =
    !claim || isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true }) || !isDocumentRelevant(document, claim);

  return (
    <>
      {!disabled && (
        <HoverActionField
          tooltipTitle={`Send to ${capitalize(vendorNameDisplay)}`}
          icon={FileSendOutline}
          onAction={(e) => setPopoverAnchorEl(e.currentTarget)}
          permanent={displayIcon}
        />
      )}
      <Formik
        initialValues={getInitialValues(document)}
        validationSchema={Yup.object().shape({ ...validationSchema })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await axios.post(getSubmitRoute(claim.id), { attached_documents: [{ id: document.id }], ...values });
            await onClaimUpdate();
            setPopoverAnchorEl(null);
          } catch (error) {
            reportAxiosError(error);
          }
          setSubmitting(false);
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;
          return (
            <CardDialog {...cardDialogProps} onClose={() => setPopoverAnchorEl(null)} preventClose={isSubmitting}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {children}
                </Grid>
                <Typography>
                  Send document <em>{document.document_name}</em> to <span>{`${capitalize(vendorNameDisplay)}`}</span>
                </Typography>
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Upload
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

SendDocumentViaVendorCommunication.propTypes = {
  document: PropTypes.object.isRequired,
  displayIcon: PropTypes.bool,
  isDocumentRelevant: PropTypes.func.isRequired,
  sentIndicationKeyName: PropTypes.string.isRequired,
  vendorNameDisplay: PropTypes.string.isRequired,
  children: PropTypes.object,
  validationSchema: PropTypes.object,
  getInitialValues: PropTypes.func,
  getSubmitRoute: PropTypes.func.isRequired,
};
