import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

import cn from '~/Utils/cn';

import styles from './PaymentRequestContainer.module.scss';

function TooltipPrimaryPayee({ children, isPrimaryPayee }) {
  if (isPrimaryPayee) {
    return <Tooltip title="Primary Payee">{children}</Tooltip>;
  }

  return children;
}

TooltipPrimaryPayee.propTypes = {
  children: PropTypes.node.isRequired,
  isPrimaryPayee: PropTypes.bool.isRequired,
};

const PRIMARY_PAYEE_INDICATOR_SIZES = {
  SMALL: 'small',
  MEDIUM: 'default',
  LARGE: 'large',
};

export default function PrimaryPayeeIndicator({ isPrimaryPayee, size = PRIMARY_PAYEE_INDICATOR_SIZES.MEDIUM }) {
  return (
    <span style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }} className={styles.mainContainer}>
      <TooltipPrimaryPayee isPrimaryPayee={isPrimaryPayee}>
        <StarIcon
          fontSize={size}
          className={cn(styles.starIcon, { [styles.selected]: isPrimaryPayee })}
          // style={{color: isPrimaryPayee ? 'orange' : undefined}}
          color={isPrimaryPayee ? undefined : 'disabled'}
        />
      </TooltipPrimaryPayee>
    </span>
  );
}

PrimaryPayeeIndicator.SIZES = PRIMARY_PAYEE_INDICATOR_SIZES;

PrimaryPayeeIndicator.propTypes = {
  size: PropTypes.oneOf(Object.values(PRIMARY_PAYEE_INDICATOR_SIZES)),
  isPrimaryPayee: PropTypes.bool.isRequired,
};
