import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import useOrganization from '../../OrganizationContext';
import TextFieldFormik from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const SubOrganizationSelectFormik = ({ fieldId, formatEntry = defaultFormatEntry, disabled = false }) => {
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const classes = useStyles();

  if (!subOrganizationEnabled) {
    return null;
  }

  return (
    <TextFieldFormik
      id={fieldId}
      label="Sub Organization"
      className={classes.textField}
      select
      fullWidth
      disabled={disabled}
    >
      {subOrganizations.map((subOrg) => (
        <MenuItem key={subOrg.id} value={subOrg.id}>
          {formatEntry(subOrg)}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

const defaultFormatEntry = (subOrganization) => subOrganization.name;

SubOrganizationSelectFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  formatEntry: PropTypes.func,
};

export default SubOrganizationSelectFormik;
