import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import NoUpdatedPayeeDetailsFormikError from '~/components/OneInc/AdditionalFinanceTableMenuOptions/UpdatePayeeDetailsDialog/NoUpdatedPayeeDetailsFormikError';
import {
  getPayeeDetailsInitialValues,
  getPayeeDetailsValidationSchema,
} from '~/components/OneInc/AdditionalFinanceTableMenuOptions/UpdatePayeeDetailsDialog/updatePayeeDetailsDialogUtils';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import { getContact } from '../../../Contact';
import { ContactShowOnlyTextField } from '../../../ContactTextFieldFormik';
import { FsButton, Heading, LoadingSwitch } from '../../../core';
import PrimaryPayeeIndicator from '../../../exposures/PaymentRequestContainer/PrimaryPayeeIndicator';
import TextFieldFormik from '../../../TextFieldFormik';
import ErrorComponent from '../ErrorComponent';

import { useStyles } from '../../../../assets/styles';
import styles from '../additionalFinanceTableMenuOptions.module.scss';

const UpdatePayeeDetailsDialog = ({
  payees = [],
  onClose = noop,
  onSubmit = noop,
  primary_payee_id,
  isSubmitError,
  isSubmitLoading,
  errorMessage,
}) => {
  const classes = useStyles();
  const [contacts, setContacts] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchContactDetails = async (contact_id) => {
    return await getContact(contact_id);
  };

  useEffect(() => {
    const fetchAllContacts = async () => {
      try {
        const contacts = await Promise.all(payees.map(({ contact_id }) => fetchContactDetails(contact_id)));
        const contactDict = {};

        contacts.forEach((contact) => {
          contactDict[contact.id] = contact;
        });
        setContacts(contactDict);
      } catch (error) {
        await reportAxiosError(error);
        setIsError(true);
      }
      setIsLoading(false);
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchAllContacts();
  }, [payees]);

  const initialValues = getPayeeDetailsInitialValues(payees, contacts);

  return (
    <CardDialog isDialog title="Update Payee Details" onClose={onClose} maxWidth="md" fullWidth>
      <LoadingSwitch
        isLoading={isLoading || isSubmitLoading}
        isError={isError || isSubmitError}
        ErrorComponent={errorMessage ? () => <ErrorComponent message={errorMessage} /> : undefined}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={getPayeeDetailsValidationSchema(payees, initialValues)}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          enableReinitialize
          debug
        >
          {({ isSubmitting, handleReset, handleSubmit }) => (
            <div>
              <InnerCard title="" className={styles.updatePayeesContainer}>
                <Heading variant={Heading.TYPES.H3} className={styles.updatePayeeDetailsTitle}>
                  Payees
                </Heading>
                {payees.map(({ contact_id, payee_email, payee_phone }) => {
                  const contact = contacts[contact_id];
                  const isEmailDisplayed = !!payee_email;
                  const isPhoneDisplayed = !!payee_phone;

                  if (!contact) {
                    return null;
                  }

                  return (
                    <Grid container key={contact_id} spacing={2} className={styles.updatePayeeDetails}>
                      <Grid item xs={4}>
                        <div className={styles.contactPayeeDetails}>
                          <PrimaryPayeeIndicator isPrimaryPayee={contact_id === primary_payee_id} />
                          <ContactShowOnlyTextField
                            contactId={contact_id}
                            contactDisplayName={contact.full_name}
                            label="Contact"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        {isEmailDisplayed ? (
                          <TextFieldFormik id={`${contact_id}.email_id`} label="Email" fullWidth select>
                            {contact.emails.map((email) => (
                              <MenuItem key={email.id} value={email.id}>
                                {email.email}
                              </MenuItem>
                            ))}
                          </TextFieldFormik>
                        ) : null}
                      </Grid>
                      <Grid item xs={4}>
                        {isPhoneDisplayed ? (
                          <TextFieldFormik id={`${contact_id}.phone_id`} label="Phone Number" fullWidth select>
                            {contact.phones.map(({ id, phone }) => (
                              <MenuItem key={id} value={id}>
                                {phone}
                              </MenuItem>
                            ))}
                          </TextFieldFormik>
                        ) : null}
                      </Grid>
                    </Grid>
                  );
                })}
                <NoUpdatedPayeeDetailsFormikError />
              </InnerCard>
              <div className={cn(classes.buttonsContainer, styles.updatePayeeDetailsButtons)}>
                <CancelButton
                  disabled={isSubmitting}
                  onClick={() => {
                    handleReset();
                    onClose();
                  }}
                />
                <FsButton variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                  Save
                </FsButton>
              </div>
            </div>
          )}
        </Formik>
      </LoadingSwitch>
    </CardDialog>
  );
};

UpdatePayeeDetailsDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  payees: PropTypes.arrayOf(PropTypes.object),
  primary_payee_id: PropTypes.number,
  isSubmitError: PropTypes.bool,
  isSubmitLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default UpdatePayeeDetailsDialog;
