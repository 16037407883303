import React from 'react';

import { colorPalette } from '~/assets/styles';
import TooltipIcon from '~/components/core/TooltipIcon';
import InfoIcon from '~/components/icons/InfoIcon';

interface VendorContactSearchLabelProps {
  disabled: boolean;
}

const VendorContactSearchLabel: React.FC<VendorContactSearchLabelProps> = ({ disabled = false }) => (
  <div className="inline-flex items-center">
    <span className="pr-5">Service Provider</span>
    <TooltipIcon placement="right" arrow title="The method selected influences the service provider search results.">
      <InfoIcon size={20} iconColor={disabled ? colorPalette.text.disabled : colorPalette.text.primary} />
    </TooltipIcon>
  </div>
);

export default VendorContactSearchLabel;
