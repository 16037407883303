/**
 * This is a POC component for Tableau Self Service - it will be deleted
 */
import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Text from '~/components/core/TextComponents/Text';
import { useCms } from '~/components/hooks/useCms';
import LoadingIndicator from '~/components/LoadingIndicator';
import { NewWorkbookCreator } from '~/components/ReportsDashboardsPage/NewWorkbookCreator';
import TableauView from '~/components/ReportsDashboardsPage/TableauView';
import useDataFetcher from '~/components/useDataFetcher';
import cn from '~/Utils/cn';

const OPTIONS = {
  width: `${window.innerWidth - 290}px`,
  height: `${window.innerHeight - 120}px`,
  hideToolbar: true,
};

const CLEAR_IFRAME_TIMEOUT = 1000;

export const TableauSelfService = () => {
  const { user, userOrganization } = useCms();
  const [isLoading, setIsLoading] = React.useState(true);

  const { data: views } = useDataFetcher(
    `/api/v1/tableau_users/organizations/${userOrganization.id}/users/${user.id}/views`
  );

  const { data: rawWorkbooks } = useDataFetcher(
    `/api/v1/tableau_users/organizations/${userOrganization.id}/users/${user.id}/workbooks`
  );
  const [selectedWorkbook, setSelectedWorkbook] = React.useState(null);
  const [selectedView, setSelectedView] = React.useState(null);
  const [workbooks, setWorkbooks] = React.useState(null);
  const [createNew, setCreateNew] = React.useState(false);
  const filteredViews = React.useMemo(
    () => views?.filter((view) => view.workbook_id === selectedWorkbook?.id),
    [selectedWorkbook, views]
  );

  const { viewName, workbookName } = React.useMemo(() => {
    if (!selectedView) return { viewName: '', workbookName: '' };

    const parts = selectedView.content_url.split('/');

    return { viewName: parts[parts.length - 1], workbookName: parts[0] };
  }, [selectedView]);

  const onSelectWorkbook = (event) => {
    const workbook = event?.target?.value;
    setSelectedView(null);

    // we have to wait for the embed to be flushed out before setting the new view
    setTimeout(() => {
      setSelectedView(null);
      setSelectedWorkbook(workbook);
      setSelectedView(workbook?.views[0]);
      setCreateNew(false);
    }, CLEAR_IFRAME_TIMEOUT);
  };

  const onSelectView = (event) => {
    const view = event?.target?.value;
    setSelectedView(null);

    setTimeout(() => {
      setSelectedView(view);
      setCreateNew(false);
    }, CLEAR_IFRAME_TIMEOUT);
  };

  const onCreateNew = React.useCallback(() => {
    setSelectedView(null);
    setSelectedWorkbook(null);
    setCreateNew(true);
  }, []);

  React.useEffect(() => {
    if (views && rawWorkbooks) {
      // populate workbooks with views
      const workbooks = rawWorkbooks.map((workbook) => {
        const workbookViews = views.filter((view) => view.workbook_id === workbook.id);
        return { ...workbook, views: workbookViews };
      });

      setIsLoading(false);
      setWorkbooks(workbooks);
      setSelectedWorkbook(workbooks[0]);
      setSelectedView(workbooks[0]?.views[0]);
    }
  }, [views, rawWorkbooks]);

  return (
    <div className=" flex h-[calc(100vh-115px)] w-full">
      <div className={cn('flex w-[200px] flex-shrink-0 flex-col bg-slate-400 p-10', { ['hidden']: createNew })}>
        <Button color="primary" onClick={onCreateNew} className="mb-40 justify-center bg-teal-600" variant="contained">
          <AddIcon />
          CREATE NEW
        </Button>

        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            <div className="mb-40 flex flex-col">
              <Text variant="small">Workbook</Text>
              <TextField fullWidth select onChange={onSelectWorkbook} value={selectedWorkbook}>
                {workbooks?.map((workbook) => (
                  <MenuItem key={workbook.id} value={workbook}>
                    {workbook.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="mb-40 flex flex-col">
              <Text variant="small">View</Text>
              <TextField fullWidth select onChange={onSelectView} value={selectedView}>
                {filteredViews?.map((view) => (
                  <MenuItem key={view.id} value={view}>
                    {view.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </>
        )}
      </div>

      {selectedView && (
        <TableauView view={viewName} workbook={workbookName} onFilterApplied={noop} onLoad={noop} options={OPTIONS} />
      )}

      {createNew && <NewWorkbookCreator />}
    </div>
  );
};
