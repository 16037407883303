import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import { flattenOptions } from '~/components/core/Editor/MenuBar/suggestion/suggestion';
import type { DisplayConfigProps, SuggestionOption } from '~/components/core/Editor/types';

import BulletList from './alignment/BulletList';
import OrderList from './alignment/OrderList';
import TextAlign from './alignment/TextAlign';
import HighlightComponent from './color/Highlight';
import TextColor from './color/TextColor';
import FontFamily from './font/FontFamily';
import FontSize from './font/FontSize';
import Link from './link/Link';
import SuggestionComponent from './suggestion/SuggestionMenuButton';
import Bold from './text/Bold';
import Italic from './text/Italic';
import Strike from './text/Strike';
import Underline from './text/Underline';

interface MenuBarProps {
  suggestionOptions: SuggestionOption[];
  displayConfig: DisplayConfigProps;
}

const MenuBar: React.FC<MenuBarProps> = ({ displayConfig, suggestionOptions }) => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="inline-flex w-full border-0 border-b-1 border-solid border-slate-600 p-5">
      {displayConfig.font ? (
        <>
          <FontFamily />
          <FontSize />
        </>
      ) : null}
      {displayConfig.textColor ? (
        <>
          <TextColor />
          <HighlightComponent />
        </>
      ) : null}
      {displayConfig.textStyle ? (
        <>
          <Bold />
          <Italic />
          <Underline />
          <Strike />
        </>
      ) : null}
      {displayConfig.align ? (
        <>
          <OrderList />
          <BulletList />
          <TextAlign />
        </>
      ) : null}
      {displayConfig.link ? <Link /> : null}
      {displayConfig.suggestions ? (
        /*Currently use Flatten options*/
        <SuggestionComponent options={flattenOptions(suggestionOptions)} buttonClassName="ml-auto" />
      ) : null}
    </div>
  );
};

export default MenuBar;
