import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { AdvancedSearchContact } from '~/components/ContactSearch/AdvancedSearchContact/AdvancedSearchContact';
import { ContactSearch } from '~/components/ContactSearch/ContactSearch';

import { compose, getAxiosParamsSerializer, reportAxiosError, reportErrorInProductionOrThrow } from '../../Utils';
import { withClaim } from '../ClaimContainer';
import { CreateContact, getContact, getSupportedContactRolesTypes } from '../Contact';
import { withContacts } from '../ContactsContext';
import { contactPopulate } from '../ContactUtils';
import { withCmsContext } from '../hooks/useCms';
import { withOrganization } from '../OrganizationContext';

const ContactSearchWithContext = withOrganization(ContactSearch);

class ContactSearchContainer extends Component {
  constructor(props) {
    super(props);
    const { acceptedRoles, claim, userOrganization, rolesClaimType, organizationContactRolesDict } = props;
    this.currentRequestCancelTokenSource = undefined;

    let supportedClaimTypes;
    if (claim) {
      supportedClaimTypes = [claim.lob];
    } else if (rolesClaimType) {
      supportedClaimTypes = [rolesClaimType];
    } else {
      supportedClaimTypes = userOrganization.supported_claim_types;
    }

    const contactSupportedRoles = getSupportedContactRolesTypes(
      organizationContactRolesDict,
      acceptedRoles,
      supportedClaimTypes
    );

    this.state = {
      isSearchInProgress: false,
      searchResultsContacts: [],
      contactSelected: null,
      contact: undefined,
      isAddingContact: false,
      newContactOpen: false,
      newContactSuggestedValues: {},
      contactSupportedRoles,
      advancedSearchOpen: false,
      advancedSearchValue: '',
    };
  }

  componentWillUnmount() {
    // cancel pending request if any
    if (this.currentRequestCancelTokenSource) {
      this.currentRequestCancelTokenSource.cancel();
      this.currentRequestCancelTokenSource = undefined;
    }
  }

  componentDidMount() {
    const {
      selectedContactId,
      selectedContactDisplayName,
      onSelectContact,
      updateOnInit,
      claim: claimInContext,
    } = this.props;

    if (selectedContactId) {
      const { contactSelected } = this.state;
      if (!contactSelected || selectedContactId !== contactSelected.id) {
        this.setState({
          initialSelectedContact: { selectedContactId, selectedContactDisplayName },
          isSearchInProgress: true,
        });
        getContact(selectedContactId, claimInContext)
          .then((contact) => {
            this.setState({
              contact,
              contactSelected: contact,
              initialSelectedContact: undefined,
              isSearchInProgress: false,
            });
            if (updateOnInit) {
              onSelectContact(contact);
            }
          })
          .catch((error) => reportAxiosError(error));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedContactId, selectedContactDisplayName, claim: claimInContext } = this.props;

    if (prevProps.selectedContactId !== selectedContactId) {
      if (selectedContactId) {
        const { contactSelected } = this.state;
        if (!contactSelected || selectedContactId !== contactSelected.id) {
          this.setState({
            initialSelectedContact: { selectedContactId, selectedContactDisplayName },
            isSearchInProgress: true,
          });
          getContact(selectedContactId, claimInContext)
            .then((contact) => {
              this.setState({
                contact,
                contactSelected: contact,
                initialSelectedContact: undefined,
                isSearchInProgress: false,
              });
            })
            .catch((error) => reportAxiosError(error));
        }
      } else {
        this.clearContact();
      }
    }
  }

  handleSearchValueChange = (inputValue) => {
    const {
      searchContacts,
      contactsOrganizationId,
      excludedContactIdsList,
      disallowOutOfClaimContacts,
      organizationContactRolesDict,
      acceptedExpertises,
      userOrganization,
      includeSingleClaimContactsInServerQuery,
    } = this.props;
    const { contactSelected, contactSupportedRoles } = this.state;

    const allowOutOfClaimContacts =
      contactSupportedRoles.some((role) => organizationContactRolesDict[role].is_multi_claim) &&
      !disallowOutOfClaimContacts;

    if (!searchContacts && !allowOutOfClaimContacts) {
      reportErrorInProductionOrThrow(
        'In ContactSearchContainer::handleSearchValueChange - one of searchContacts or allowOutOfClaimContacts must defined'
      );
    }

    // on empty input and same input, do nothing
    if (!inputValue || (contactSelected && inputValue === contactSelected.full_name)) {
      this.setState({ isSearchInProgress: false, searchResultsContacts: [] });
      return;
    }

    let excludedContactIdsListWithoutSelected = excludedContactIdsList;
    if (excludedContactIdsListWithoutSelected && contactSelected) {
      excludedContactIdsListWithoutSelected = excludedContactIdsListWithoutSelected.filter(
        (contactId) => contactId !== contactSelected.id
      );
    }

    let filteredContacts = [];
    // search in contacts context
    if (searchContacts) {
      filteredContacts = searchContacts(inputValue);
      if (excludedContactIdsListWithoutSelected) {
        filteredContacts = filteredContacts.filter(
          (contact) => !excludedContactIdsListWithoutSelected.includes(contact.id)
        );
      }

      filteredContacts = filteredContacts.filter((contact) => contactSupportedRoles.includes(contact.role));
      this.setState({ isSearchInProgress: allowOutOfClaimContacts, searchResultsContacts: filteredContacts });
    }

    // search multi claim contacts
    if (allowOutOfClaimContacts) {
      // cancel previous request if any
      if (this.currentRequestCancelTokenSource) {
        this.currentRequestCancelTokenSource.cancel();
        this.currentRequestCancelTokenSource = undefined;
      }

      // input not empty, initiate search
      this.currentRequestCancelTokenSource = axios.CancelToken.source();

      let searchParams = {
        search: inputValue,
        accepted_roles: contactSupportedRoles,
        excluded_contact_ids: excludedContactIdsListWithoutSelected,
      };

      if (acceptedExpertises) {
        searchParams['accepted_expertise'] = acceptedExpertises;
      }

      if (includeSingleClaimContactsInServerQuery) {
        searchParams['include_single_claim_contacts'] = true;
      }

      let organizationId = contactsOrganizationId;

      if (!organizationId) {
        reportErrorInProductionOrThrow(
          'In ContactSearchContainer::handleSearchValueChange - contactsOrganizationId is not defined, using user org'
        );
        organizationId = userOrganization.id;
      }

      axios
        .get(`/api/v1/organizations/${organizationId}/contacts`, {
          params: searchParams,
          cancelToken: this.currentRequestCancelTokenSource.token,
          paramsSerializer: getAxiosParamsSerializer('none'),
        })
        .then((res) => {
          this.currentRequestCancelTokenSource = undefined;
          const filteredContactIds = filteredContacts.map((contact) => contact.id);
          // getContact populates exposure_ids and immutable_exposure_ids; fetchedContacts are all out-of-claim (otherwise, we would have found them in the context), populate with empty lists
          const fetchedContacts = res.data.contacts.map((contact) => ({
            ...contact,
            is_fetched: true,
            exposure_ids: [],
            immutable_exposure_ids: [],
          }));
          const contactsFetchedUnique = fetchedContacts.filter((contact) => !filteredContactIds.includes(contact.id));
          this.setState({
            isSearchInProgress: false,
            searchResultsContacts: [...filteredContacts, ...contactsFetchedUnique],
          });
        })
        .catch((error) => {
          // if we cancelled the request, that's OK
          if (axios.isCancel(error)) {
            return;
          }
          reportAxiosError(error);
          this.setState({ isSearchInProgress: false, searchResultsContacts: [] });
        });
    }
  };

  clearContact = () => {
    const { onSelectContact } = this.props;

    this.setState({ contactSelected: null, contact: undefined });
    if (onSelectContact) {
      onSelectContact('');
    }
  };

  handleContactSelect = (contact, stateAndHelpers) => {
    const { clearSelection } = stateAndHelpers;

    // handle contact de-selection
    if (!contact) {
      this.clearContact();
      return;
    }

    // handle request for a new contact
    if (contact.id === 'new') {
      this.handleCreateNewContact(contact.full_name);
      clearSelection();
      return;
    }

    this.handleUpdateSelectedContact(contact);
  };

  handleCreateNewContact = (fullName) => {
    const trimmedFullName = fullName.trim();
    let fullNameSplit = trimmedFullName.split(' ');
    let first_name;
    let last_name;
    if (fullNameSplit.length === 1) {
      first_name = trimmedFullName;
    } else {
      last_name = fullNameSplit.pop().trim();
      first_name = fullNameSplit.join(' ').trim();
    }

    // remove undefined values
    const newContactSuggestedValues = { first_name, last_name, company_name: trimmedFullName };
    Object.keys(newContactSuggestedValues).forEach((key) =>
      newContactSuggestedValues[key] === undefined ? delete newContactSuggestedValues[key] : ''
    );

    this.setState({ newContactOpen: true, newContactSuggestedValues });
  };

  handleAdvancedSearch = (searchText) => {
    this.setState({ advancedSearchOpen: true, advancedSearchValue: searchText });
  };

  handleAdvancedSearchSelect = async (contact) => {
    const { onSelectContact } = this.props;

    this.setState({ contact, contactSelected: contact, advancedSearchOpen: false });
    await onSelectContact(contact);
  };

  handleUpdateSelectedContact = (contact) => {
    const { onSelectContact } = this.props;
    const contactPopulated = contactPopulate(contact);
    this.setState({ contact: contactPopulated, contactSelected: contactPopulated, isSearchInProgress: false });
    if (onSelectContact) {
      onSelectContact(contactPopulated);
    }
  };

  handleCreateContact = async (contact) => {
    const { onAddContact, onSelectContact, onNewContactCreated } = this.props;
    const contactRes = contactPopulate(contact);

    await onAddContact(contactRes);
    this.setState({ contact: contactRes, contactSelected: contactRes, newContactOpen: false });
    await onSelectContact(contactRes);
    if (onNewContactCreated) {
      await onNewContactCreated(contactRes);
    }
  };

  render() {
    const {
      textInputRef,
      isAddingContact,
      disallowNew,
      TextFieldProps,
      disableEditingRole,
      newContactRole,
      fixedSearchResults,
      RemoveOutOfClaimDivider,
      contactsOrganizationId,
      disableAdvanceSearch,
      acceptedExpertises,
      shouldShowAllOption,
      handleShowAllContacts,
      restrictAcceptedEntities = false,
    } = this.props;

    const {
      isSearchInProgress,
      contactSupportedRoles,
      searchResultsContacts,
      contactSelected,
      contact,
      newContactOpen,
      newContactSuggestedValues,
      initialSelectedContact,
      advancedSearchOpen,
      advancedSearchValue,
    } = this.state;

    return (
      <>
        <ContactSearchWithContext
          textInputRef={textInputRef}
          initialSelectedContact={initialSelectedContact}
          isSearchInProgress={isSearchInProgress}
          contactsList={searchResultsContacts}
          onSearchValueChange={this.handleSearchValueChange}
          onContactSelect={this.handleContactSelect}
          contactSelected={contactSelected}
          onContactUpdate={this.handleUpdateSelectedContact}
          contact={contact}
          isAddingContact={isAddingContact}
          disallowNew={disallowNew}
          TextFieldProps={TextFieldProps}
          fixedSearchResults={fixedSearchResults}
          RemoveOutOfClaimDivider={RemoveOutOfClaimDivider}
          handleAdvancedSearch={this.handleAdvancedSearch}
          disableAdvanceSearch={disableAdvanceSearch}
          shouldShowAllOption={shouldShowAllOption}
          onShowAllContacts={handleShowAllContacts}
        />

        {newContactOpen && (
          <CreateContact
            suggestedValues={newContactSuggestedValues}
            onSubmitContact={this.handleCreateContact}
            onCancel={() => this.setState({ newContactOpen: false })}
            newContactRole={newContactRole}
            disableEditingRole={disableEditingRole}
            acceptedRoles={contactSupportedRoles}
          />
        )}
        {advancedSearchOpen && (
          <AdvancedSearchContact
            inputText={advancedSearchValue}
            onSelectContact={this.handleAdvancedSearchSelect}
            onCancel={() => this.setState({ advancedSearchOpen: false })}
            acceptedRoles={contactSupportedRoles}
            acceptedExpertises={acceptedExpertises}
            restrictAcceptedEntities={restrictAcceptedEntities}
            organizationId={contactsOrganizationId}
          />
        )}
      </>
    );
  }
}

ContactSearchContainer.propTypes = {
  textInputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  searchContacts: PropTypes.func.isRequired, //Contacts context
  contactsOrganizationId: PropTypes.number.isRequired, //Contacts context
  rolesClaimType: PropTypes.string, //Contacts context
  onAddContact: PropTypes.func.isRequired, //Contacts context
  userOrganization: PropTypes.object.isRequired, // Cms Context
  onSelectContact: PropTypes.func,
  selectedContactId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // makes this a controlled component
  selectedContactDisplayName: PropTypes.string,
  isAddingContact: PropTypes.bool,
  disallowNew: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  newContactRole: PropTypes.string,
  disableEditingRole: PropTypes.bool,
  fixedSearchResults: PropTypes.bool,
  updateOnInit: PropTypes.bool,
  excludedContactIdsList: PropTypes.array,
  acceptedRoles: PropTypes.array.isRequired,
  acceptedExpertises: PropTypes.array,
  claim: PropTypes.object,
  disallowOutOfClaimContacts: PropTypes.bool,
  RemoveOutOfClaimDivider: PropTypes.bool,
  onNewContactCreated: PropTypes.func,
  organizationContactRolesDict: PropTypes.object.isRequired,
  disableAdvanceSearch: PropTypes.bool,
  restrictAcceptedEntities: PropTypes.bool,
  shouldShowAllOption: PropTypes.bool,
  handleShowAllContacts: PropTypes.func,
  includeSingleClaimContactsInServerQuery: PropTypes.bool,
};

export default compose(withContacts, withClaim, withOrganization, withCmsContext)(ContactSearchContainer);
