import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useClaim } from '~/components/ClaimContainer';
import { EditDocumentDialog, isDocumentAPdf } from '~/components/Documents/DocumentCard';
import ManipulatePdfContainer from '~/components/Documents/DocumentMoreActions/ManipulatePdf/ManipulatePdfContainer';
import { useCms } from '~/components/hooks/useCms';
import HoverActionField from '~/components/HoverActionField';
import { ThreeDotsIcon } from '~/components/icons';
import { AddInternalCommunicationDialog } from '~/components/InternalCommunication';
import { isClaimWriteDisabled } from '~/Utils/ClaimUtils';

const DocumentMoreActionsContainer = ({ document }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddInternalCommunicationDialogOpen, setIsAddInternalCommunicationDialogOpen] = useState(false);
  const [isEditDocumentDialogOpen, setIsEditDocumentDialogOpen] = useState(false);
  const [isManipulatePdfDialogOpen, setIsManipulatePdfDialogOpen] = useState(false);

  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();

  const disabled = document.is_uploading;

  const isManipulatePdfDisabled =
    isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true }) || !isDocumentAPdf(document);

  return (
    <>
      {disabled ? null : (
        <>
          <HoverActionField
            icon={ThreeDotsIcon}
            onAction={(event) => setAnchorEl(event.currentTarget)}
            permanent
            ignorePermissions
          />
          <Menu
            PaperProps={{
              style: {
                width: 200,
              },
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setIsAddInternalCommunicationDialogOpen(true);
                setAnchorEl(null);
              }}
            >
              Internal communication
            </MenuItem>

            <MenuItem
              onClick={() => {
                setIsEditDocumentDialogOpen(true);
                setAnchorEl(null);
              }}
            >
              Edit Document
            </MenuItem>

            {isManipulatePdfDisabled ? null : (
              <MenuItem
                onClick={() => {
                  setIsManipulatePdfDialogOpen(true);
                  setAnchorEl(null);
                }}
              >
                Manipulate PDF
              </MenuItem>
            )}
          </Menu>

          <AddInternalCommunicationDialog
            open={isAddInternalCommunicationDialogOpen}
            claim={claim}
            icObject={{ type: 'document', document }}
            onCancel={() => setIsAddInternalCommunicationDialogOpen(false)}
            onSubmit={() => setIsAddInternalCommunicationDialogOpen(false)}
          />

          <EditDocumentDialog
            open={isEditDocumentDialogOpen}
            claim={claim}
            onCancel={() => setIsEditDocumentDialogOpen(false)}
            document={document}
            onUpdateDocument={async () => {
              await onAsyncClaimUpdate();
              setIsEditDocumentDialogOpen(false);
            }}
          />

          <ManipulatePdfContainer
            isOpen={isManipulatePdfDialogOpen}
            document={document}
            onClose={() => setIsManipulatePdfDialogOpen(false)}
          />
        </>
      )}
    </>
  );
};

DocumentMoreActionsContainer.propTypes = {
  document: PropTypes.object.isRequired,
};

export default DocumentMoreActionsContainer;
