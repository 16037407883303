import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import PermissionDeniedTooltip from '~/components/core/Permissions/PermissionDeniedTooltip';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import cn from '~/Utils/cn';

import { useRootStyles } from '../../assets/rootStyles';
import { SEARCH_MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, CONSTANTS } from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import { ClaimSearchResults } from '../ClaimSearch';
import mixpanel from '../CmsMain/mixpanel';
import { useCms } from '../hooks/useCms';

import CardDialog from './../CardDialog';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, TooltipIcon } from './../core';
import { AdvancedSearchIcon } from './../icons';

import colors from '../../assets/colors.module.scss';
import styles from './searchBar.module.scss';

function BarSearchField() {
  const classes = useRootStyles();
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState(undefined);
  const [isSearchInProgress, setIsSearchInProgress] = useState(false);
  const { userOrganization } = useCms();

  const userHasClaimPermissions = useHasPermission({ action: PERMISSION_ACTIONS.CLAIM, verb: PERMISSION_VERBS.READ });
  const overrideSearchStyle = { backgroundColor: 'rgba(0,0,0,0.04)' };

  const handleSearch = async (e) => {
    e.preventDefault();

    mixpanel.track(SEARCH_MIXPANEL_EVENTS.SEARCH_BUTTON_CLICKED, {
      search_value: searchValue,
    });

    setIsSearchInProgress(true);
    try {
      const res = await axios.get('/api/v1/claims/search_query', { params: { search: searchValue } });
      const searchResults = res.data.claims;
      setSearchResults(searchResults);

      mixpanel.track(SEARCH_MIXPANEL_EVENTS.SEARCH_RESULTS_RETURNED, {
        search_value: searchValue,
        num_results_returned: searchResults ? searchResults.length : 0,
      });

      setIsSearchInProgress(false);
    } catch (error) {
      setIsSearchInProgress(false);
      reportAxiosError(error);
    }
  };

  const handleClaimLinkClick = (claim) => {
    setSearchResults(undefined);

    const indexOfResult = searchResults.indexOf(claim);
    mixpanel.track(SEARCH_MIXPANEL_EVENTS.CLAIM_OPENED_FROM_SEARCH_RESULTS, {
      search_value: searchValue,
      num_results_returned: searchResults ? searchResults.length : 0,
      claim_clicked: claim.claim_id_display,
      result_clicked_order: indexOfResult + 1,
    });
  };

  return (
    <>
      <PermissionDeniedTooltip action={PERMISSION_ACTIONS.CLAIM} verb={PERMISSION_VERBS.READ}>
        <div className={classes.search} style={userHasClaimPermissions ? null : overrideSearchStyle}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            disabled={!userHasClaimPermissions}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === 'Enter' && !isSearchInProgress) {
                  handleSearch(e);
                }
              },
            }}
            endAdornment={
              <div className={styles.advancedSearchButtonContainer}>
                {isSearchInProgress && (
                  <InputAdornment position="end">
                    <CircularProgress style={{ color: colors.loading }} size={20} />
                  </InputAdornment>
                )}
                {!isSearchInProgress && (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleSearch}
                      size="small"
                      style={{
                        visibility: searchValue ? 'visible' : 'hidden',
                        border: '1px solid rgba(0,0,0,0.09)',
                        height: '22px',
                        marginRight: '4px',
                        backgroundColor: '#FFFFFF',
                        color: '#rgba(0,0,0,0.6)',
                      }}
                    >
                      Search
                    </Button>
                    {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ADVANCED_SEARCH_DIALOG) ? (
                      <IconButton
                        className={cn(styles.advancedSearchIconButton)}
                        component={Link}
                        to="/advanced_search"
                      >
                        <TooltipIcon title="Advanced Search">
                          <AdvancedSearchIcon
                            size={18}
                            iconColor="currentColor"
                            className={styles.advancedSearchIcon}
                          />
                        </TooltipIcon>
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                )}
              </div>
            }
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </PermissionDeniedTooltip>
      {searchResults && searchResults.length > 0 && (
        <ClaimSearchResults
          searchResults={searchResults}
          preResultsTableComponent={
            searchResults.length === CONSTANTS.DEFAULT_CLAIMS_PER_PAGE && (
              <div className={classes.cardDivRow}>
                <span
                  className={classes.textFieldRow}
                >{`Showing only the first ${CONSTANTS.DEFAULT_CLAIMS_PER_PAGE} results. Refine your search query to find other claims or use the All Claims view`}</span>
              </div>
            )
          }
          onCancel={() => setSearchResults(undefined)}
          onClaimLinkClick={handleClaimLinkClick}
        />
      )}
      {searchResults && searchResults.length === 0 && (
        <CardDialog isDialog noCardTitle onClose={() => setSearchResults(null)}>
          <Typography>No Results Were Found</Typography>
        </CardDialog>
      )}
    </>
  );
}

export default BarSearchField;
