import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { useLob } from '../hooks/useLob';
import TextFieldFormik from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function ConfiguredIncidentTypeAndSubtypeFormik({ disabled, configuration, onSubTypeChange }) {
  const classes = useStyles();
  const formikContext = useFormikContext();
  const { values, setFieldValue } = formikContext;
  const { lob } = useLob();

  const typesConfig = React.useMemo(
    () => configuration.incident_details.types.filter((incidentType) => incidentType.active !== false),
    [configuration.incident_details]
  );
  const selectedTypeConfig = typesConfig.find((t) => t.id === values.incident_type);
  const selectTypeActiveSubTypes = React.useMemo(
    () => selectedTypeConfig?.sub_types.filter((option) => option.active !== false),
    [selectedTypeConfig]
  );

  useEffect(() => {
    if (typesConfig?.length === 1) {
      setFieldValue('incident_type', typesConfig[0].id);
    }
    if (selectTypeActiveSubTypes?.length === 1) {
      setFieldValue('incident_sub_type', selectTypeActiveSubTypes[0].id);
    }
  }, [typesConfig, selectTypeActiveSubTypes, setFieldValue]);

  return (
    <>
      <Grid item xs={6}>
        <TextFieldFormik
          id="incident_type"
          select
          label={lob === 'home_claim' ? 'Peril' : 'Incident type'}
          className={classes.textField}
          disabled={disabled}
          onChange={(e) => {
            setFieldValue('incident_type', e.target.value);
            setFieldValue('incident_sub_type', '');
          }}
          fullWidth
        >
          {typesConfig.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="incident_sub_type"
          select
          label={lob === 'home_claim' ? 'Cause of Loss' : 'Incident sub-type'}
          disabled={disabled}
          className={classes.textField}
          onChange={(e) => {
            setFieldValue('incident_sub_type', e.target.value);
            onSubTypeChange && onSubTypeChange(e.target.value, formikContext);
          }}
          fullWidth
        >
          {selectTypeActiveSubTypes?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
    </>
  );
}

ConfiguredIncidentTypeAndSubtypeFormik.propTypes = {
  disabled: PropTypes.bool,
  configuration: PropTypes.object.isRequired,
  onSubTypeChange: PropTypes.func,
};

export default ConfiguredIncidentTypeAndSubtypeFormik;
