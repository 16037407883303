import React from 'react';
import PropTypes from 'prop-types';
import { Link, MenuItem, TextField, Typography } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { CancelIcon } from '../../../../icons';
import useOrganization from '../../../../OrganizationContext';
import SubOrganizationSelect from '../../../../TPA/SubOrganizations/SubOrganizationSelect';

import { useStyles } from '../../../../../assets/styles';
import styles from '../../financeApprovals.module.scss';

const PAYABLE_TYPES = {
  indemnity: 'Indemnity',
  expenses: 'Expenses',
};

const FILTER_KEYS = {
  payable_types_filter: 'payable_types_filter',
  sub_organization_id: 'sub_organization_id',
  payment_method: 'payment_method',
};

const Filters = ({ filters, onChange, disabled }) => {
  const classes = useStyles();
  const { subOrganizationEnabled } = useOrganization();

  const resetFilters = () => {
    onChange(FILTER_KEYS.payable_types_filter, []);
    onChange(FILTER_KEYS.sub_organization_id, '');
  };

  return (
    <Grid container spacing={3} className={styles.paymentSubmissionFilters}>
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle1">
          Filters
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={filters[FILTER_KEYS.payable_types_filter]?.[0] || ''}
          label="Payable Type"
          className={classes.textField}
          fullWidth
          select
          disabled={disabled}
          onChange={(e) => onChange(FILTER_KEYS.payable_types_filter, [e.target.value])}
        >
          {Object.keys(PAYABLE_TYPES).map((type) => (
            <MenuItem key={type} value={type}>
              {PAYABLE_TYPES[type]}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {subOrganizationEnabled && (
        <Grid item xs={3}>
          <SubOrganizationSelect
            value={filters[FILTER_KEYS.sub_organization_id] || ''}
            disabled={disabled}
            onChange={(subOrgId) => onChange(FILTER_KEYS.sub_organization_id, subOrgId)}
          />
        </Grid>
      )}
      <Grid item xs={3} className={styles.clearFilters}>
        <Link
          href=""
          onClick={(e) => {
            e.preventDefault();
            resetFilters();
          }}
          className={cn(classes.alignedContainerForGrid, classes.secondaryLink, disabled ? styles.disabled : '')}
        >
          <CancelIcon className={cn(classes.filledIcon, classes.leftButtonIcon, disabled ? styles.disabled : '')} />
          Clear all filters
        </Link>
      </Grid>
    </Grid>
  );
};

Filters.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export { FILTER_KEYS };

export default Filters;
