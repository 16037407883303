import React from 'react';

import getEmailShareText from '~/components/communications/VideoCommunication/getEmailShareText';
import EmailCommunicationPopover from '~/components/Contacts/ContactCommunications/Email/EmailCommunicationPopover';
import SmsCommunicationPopover from '~/components/Contacts/ContactCommunications/SMS/SmsCommunicationPopover';
import type { ContactFullModel } from '~/components/Contacts/types';
import IconButton from '~/components/core/Buttons/IconButton';
import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import { useCms } from '~/components/hooks/useCms';
import FilesIcon from '~/components/icons/FilesIcon';

interface Props {
  title: string;
  href: string;
  contact: ContactFullModel;
  adjusterName: string;
}

const LinkShare: React.FC<Props> = ({ title, href, contact, adjusterName }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();

  return (
    <div className="my-20 flex justify-between bg-slate-200 p-10">
      <div className="mr-10 overflow-hidden">
        <Caption>{title}</Caption>
        <Text
          colorVariant={Text.COLOR_VARIANTS.SECONDARY}
          variant={Text.VARIANTS.XS}
          weight={Text.WEIGHTS.REGULAR}
          className="truncate"
        >
          {href}
        </Text>
      </div>

      <div className="flex flex-row flex-nowrap items-center">
        <SmsCommunicationPopover
          contact={contact}
          useIconButton
          messageContent={`Hi ${contact.full_name}, ${adjusterName} from ${user?.organization_name} would like to start a video call with you, click the following link to join ${href}`}
        />
        <EmailCommunicationPopover
          contact={contact}
          useIconButton
          emailTitle={`${adjusterName} is inviting you to a video call`}
          emailText={getEmailShareText({
            contactName: contact.full_name || '',
            adjusterName,
            organizationName: user.organization_name,
            href,
          })}
        />
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(href);
          }}
          icon={FilesIcon}
          iconColor="currentColor"
          className="text-slate-900 hover:text-teal-700"
        />
      </div>
    </div>
  );
};

export default LinkShare;
