import React from 'react';

import Divider from '~/components/core/Atomic/Divider/Divider';
import {
  AddNoteIcon,
  AllDoneNoteIcon,
  AssignVendorNoteIcon,
  CoverageDecisionNoteIcon,
  DocumentNoteIcon,
  DoneNoteIcon,
  EmailNoteIcon,
  GeneralNoteIcon,
  MoneyNoteIcon,
  PaymentNoteIcon,
  PhoneNoteIcon,
  PhotoGalleryNoteIcon,
  PhysicalMailNoteIcon,
  ReopenNoteIcon,
  SearchIcon,
  SmsNoteIcon,
  VideoCallNoteIcon,
} from '~/components/icons/notes';
import NoteContent from '~/components/Notes/NoteContent';
import NoteHeader from '~/components/Notes/NoteHeader';
import type { Note } from '~/components/Notes/Notes';
import { NOTE_SUBJECT } from '~/Types';
import { getExposuresLabels, reportErrorInProductionOrThrow } from '~/Utils';

const NOTES_ICONS = {
  search: SearchIcon,
  thumbs_up_down: CoverageDecisionNoteIcon,
  unarchive: ReopenNoteIcon,
  note: GeneralNoteIcon,
  email: EmailNoteIcon,
  call: PhoneNoteIcon,
  mail_outline: PhysicalMailNoteIcon,
  message: SmsNoteIcon,
  videocam: VideoCallNoteIcon,
  business_center: AssignVendorNoteIcon,
  note_add: AddNoteIcon,
  done_all: AllDoneNoteIcon,
  done: DoneNoteIcon,
  attach_money: MoneyNoteIcon,
  payment: PaymentNoteIcon,
  description: DocumentNoteIcon,
  photo_library: PhotoGalleryNoteIcon,
};

const getNoteIcon = (iconName: keyof typeof NOTES_ICONS) => {
  try {
    const Icon = NOTES_ICONS[iconName];
    return <Icon size={32} />;
  } catch (error) {
    reportErrorInProductionOrThrow("Can't find note icon");
    return <GeneralNoteIcon size={32} />;
  }
};

export interface NotePartProps {
  note: Note;
}

interface NotesMiniCardProps extends NotePartProps {
  claim: { id: number };
  onNoteUpdated: () => void;
}

export interface ExposureLabelAndId {
  id: number;
  label: string;
}
const NoteMiniCard: React.FC<NotesMiniCardProps> = ({ note, claim }) => {
  const noteSubjectConfig = NOTE_SUBJECT[note.subject] ? NOTE_SUBJECT[note.subject] : NOTE_SUBJECT['note'];
  const exposureLabels: ExposureLabelAndId[] = getExposuresLabels(claim);

  return (
    <div className="m-24">
      <div className="flex items-center">
        {getNoteIcon(noteSubjectConfig.icon)}
        <div className="ml-24 w-full">
          <NoteHeader note={note} exposureLabels={exposureLabels} noteSubjectConfig={noteSubjectConfig} />
          <NoteContent note={note} />
        </div>
      </div>
      <Divider className="my-24" />
    </div>
  );
};

export default NoteMiniCard;
