import React from 'react';
import PropTypes from 'prop-types';

import cn from '~/Utils/cn';

const ConfigPanel = ({ mainActionChild, label, className, isBottomAligned = false }) => (
  <div className={cn('mx-0 my-5 w-full rounded-md border border-solid border-slate-600 p-15', className)}>
    <div className={`flex flex-nowrap ${isBottomAligned ? 'items-end' : 'items-center'} justify-between space-x-10`}>
      {mainActionChild ? <div>{mainActionChild}</div> : null}
      <div className="flex-grow">{label}</div>
    </div>
  </div>
);

ConfigPanel.propTypes = {
  label: PropTypes.node,
  mainActionChild: PropTypes.node,
  className: PropTypes.string,
  isBottomAligned: PropTypes.bool,
};

export default ConfigPanel;
