import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './index.css';
//import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// Removed this call to opt-out of caching
// registerServiceWorker();
