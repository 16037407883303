import React from 'react';
import AddIcon from '@material-ui/icons/Add';

import Button from '~/components/core/Atomic/Buttons/Button';
import EmptyState from '~/components/core/EmptyState';

interface ReportsEmptyStateProps {
  onCreate: () => void;
}

export const ReportsEmptyState: React.FC<ReportsEmptyStateProps> = ({ onCreate }) => {
  return (
    <EmptyState
      title="No dashboards and reports created yet."
      subtitle="Start by creating a new report"
      buttonComponent={
        <Button variant="contained" color="primary" onClick={() => onCreate()} startIcon={<AddIcon />}>
          ADD NEW REPORT
        </Button>
      }
    />
  );
};
