import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { getPolicyStatus, getPolicyStatusDuringLoss } from '../../PolicyUtils';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { getClaimCoverageTypesDict, isFeatureEnabled, isPolicyNotFound, reportAxiosError } from '../../Utils';
import { isClaimWriteDisabled } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import CoveredEntityCoveragesTable from '../CoveredEntity';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import HoverChangeField from '../HoverChangeField';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import { EditManuallyFilledPolicyContainer } from '../Policy/PolicyNotFoundContainer';
import { ShowOnlyTextField } from '../TextFieldFormik';
import UpdatePolicyEffectiveDateDialog from '../UpdatePolicyEffectiveDateDialog';

import { useStyles } from '../../assets/styles';

function HomePolicyDetailsCard(props) {
  const { claim, onClaimUpdate, showOnly } = props;
  const [showEditPolicyEffectiveDate, setShowEditPolicyEffectiveDate] = useState(false);
  const classes = useStyles();
  const { policy } = claim;
  const { user } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();

  const readOnly = showOnly || user.role.is_view_only;

  const handleUpdateFields = async (updateObject) => {
    try {
      const res = await axios.patch(`/api/v1/claims/${claim.id}/policy`, updateObject);
      await onClaimUpdate(res.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  if (isPolicyNotFound(claim)) {
    return (
      <CardDialog className={classes.CardDialog} title="Policy Details">
        <Typography display="block" color="secondary">
          Policy Not Found
        </Typography>
      </CardDialog>
    );
  }

  const policyStatusDuringLoss = getPolicyStatusDuringLoss(
    policy,
    claim?.incident?.date_of_loss,
    claim?.incident?.time_of_loss
  );
  const policyStatus = getPolicyStatus(policyStatusDuringLoss);

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title="Policy Details"
        action={<EditManuallyFilledPolicyContainer />}
        subheader={
          <span style={{ color: policyStatus === 'In Force' ? 'green' : 'red' }}>
            <strong>{policyStatus}</strong>
          </span>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ShowOnlyTextField label="Policy Number" classes={classes} showOnlyValueComponent={policy.policy_number} />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact.full_name}
              label="Named Insured"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <HomePolicyMortgageeWithEdit
              claim={claim}
              onUpdateMortgagee={async (mortgagee_contact_id) => await handleUpdateFields({ mortgagee_contact_id })}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField label="Client" classes={classes} showOnlyValueComponent={policy.client} />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Underwriting Company"
              classes={classes}
              showOnlyValueComponent={policy.underwriting_company}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Effective Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
              }
              fullWidth
              onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Expiration Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
              }
              fullWidth
              onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
            />
          </Grid>
          <Grid item xs={12}>
            <ShowOnlyTextField
              label="Insured Property Address"
              classes={classes}
              showOnlyValueComponent={policy.insured_property_full_address}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="AoP Deductible"
              classes={classes}
              showOnlyValueComponent={currencyFormatter.format(policy.aop_deductible)}
            />
          </Grid>
        </Grid>
      </CardDialog>
      <UpdatePolicyEffectiveDateDialog
        onClose={() => setShowEditPolicyEffectiveDate(false)}
        policy={policy}
        open={showEditPolicyEffectiveDate}
        onSubmit={async (values) => {
          await handleUpdateFields(values);
          setShowEditPolicyEffectiveDate(false);
        }}
      />
    </>
  );
}

HomePolicyDetailsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: requiredIf(PropTypes.func, (props) => !props.showOnly),
  showOnly: PropTypes.bool,
};

function HomePolicyMortgageeWithEdit({ claim, onUpdateMortgagee }) {
  const { policy } = claim;
  const { user } = useCms();

  return (
    <HoverChangeField
      name="mortgagee_contact_id"
      customFieldIdName="mortgagee_contact"
      value={policy.mortgagee_contact_id || ''}
      label="Mortgagee"
      onUpdate={async ({ mortgagee_contact_id }) => await onUpdateMortgagee(mortgagee_contact_id)}
      specialFieldType="contact"
      specialFieldAdditionalProps={{ acceptedRoles: ['mortgagee'] }}
      showOnly
      disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
    >
      {policy.mortgagee_contact ? (
        <ContactShowOnlyTextField
          contactId={policy.mortgagee_contact_id}
          contactDisplayName={policy.mortgagee_contact_full_name}
          label="Mortgagee"
          fullWidth
          showOnly
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Typography display="block">Property not mortgaged</Typography>
        </div>
      )}
    </HoverChangeField>
  );
}

HomePolicyMortgageeWithEdit.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdateMortgagee: PropTypes.func.isRequired,
};

function PolicyEndorsementsTable({ policy }) {
  const columnData = [
    { id: 'desc', label: 'Endorsement' },
    { id: 'code', label: 'Code' },
    { id: 'details_desc', label: 'Details' },
  ];

  return <PlainTable rows={policy.policy_endorsements} columns={columnData} />;
}

PolicyEndorsementsTable.propTypes = {
  policy: PropTypes.object.isRequired,
};

const HomeCoveragesTable = ({ classes, policy, claim, onClaimUpdate, EditCoverageHover }) => {
  const { supportedCoverages } = useOrganization();
  const { currencyFormatter } = useCurrencyFormatter();

  const handleCoverageUpdate = async (values) => {
    try {
      await axios.patch(`/api/v1/home_claims/${claim.id}/policy/coverage`, values);
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'warning',
      width: 5,
      disablePadding: true,
      specialCell: (coverage) =>
        // eslint-disable-next-line react/prop-types
        policy.coverages_status[coverage.id] === false ? (
          <Tooltip title="Missing Coverage Details">
            {/* eslint-disable-next-line react/prop-types */}
            <WarningIcon className={classes.warningColor} style={{ paddingLeft: '5px' }} />
          </Tooltip>
        ) : (
          ''
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'coverage_name',
      numeric: false,
      label: 'Coverage',
      specialCell: (row) => (
        <div>
          <Typography display="block" variant="caption">{`Coverage ${row.id.slice(-1).toUpperCase()}`}</Typography>
          <Typography display="block" variant="body2">
            {row.coverage_name}
          </Typography>
        </div>
      ),
    },
    {
      id: 'limit',
      numeric: true,
      label: 'Limit',
      specialCell: (row) => (row.limit === -1 ? '' : row.limit !== null && currencyFormatter.format(row.limit)),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit_coverage',
      numeric: false,
      disabledPadding: true,
      width: 20,
      specialCell: (coverage, isHover) => (
        // eslint-disable-next-line react/prop-types
        <EditCoverageHover
          coverage={coverage}
          displayIcon={isHover}
          onUpdate={handleCoverageUpdate}
          hideUpdateConfirm={policy.is_manually_created}
        />
      ),
    },
  ];

  //TODO NGTPA-3978 - need to show all coverages names
  const coverage_types_dict = getClaimCoverageTypesDict(claim);

  const rows = Object.keys(coverage_types_dict)
    .filter((key) => supportedCoverages['home_claim']?.includes(key))
    .map((coverage) => ({
      id: coverage,
      coverage_name: coverage_types_dict[coverage],
      limit: policy[`${coverage}_limit`],
    }));

  return <PlainTable classes={classes} rows={rows} columns={columnData} />;
};

HomeCoveragesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  EditCoverageHover: PropTypes.func.isRequired,
};

const ConfigureableCoveragesHomeTable = ({ classes, policy, claim }) => {
  return (
    <CardDialog className={classes.CardDialog}>
      <div className={classes.containerCentered}>
        <HomeIcon />
        &nbsp;
        <Typography display="block" variant="h6">
          Insured Home
        </Typography>
      </div>
      <Typography display="block" variant="subtitle 2">
        {policy.insured_property_full_address}
      </Typography>
      <br />
      <CoveredEntityCoveragesTable
        coveredEntity={policy.covered_entity}
        onCoverageUpdate={async (postValues, coverageTypeId) => {
          await axios.patch(`/api/v1/home_claims/${claim.id}/policy/coverage/${coverageTypeId}`, postValues);
        }}
        onSharedCoverageUpdate={async (postValues, sharedCoverageKey) => {
          await axios.patch(`/api/v1/home_claims/${claim.id}/policy/shared_coverage/${sharedCoverageKey}`, postValues);
        }}
      />
    </CardDialog>
  );
};

ConfigureableCoveragesHomeTable.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
};

const HomePolicyPage = ({ EditCoverageHover }) => {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { userOrganization } = useCms();
  const policy = claim.policy;

  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );

  return (
    <Grid container>
      <Grid item md={6}>
        <div className={classes.cardDivRow}>
          <HomePolicyDetailsCard classes={classes} claim={claim} onClaimUpdate={onClaimUpdate} />
        </div>
      </Grid>
      <Grid item md={6} />
      {isConfigurableCoveragesEnabled ? (
        <Grid item md={6}>
          <div className={classes.cardDivRow}>
            <ConfigureableCoveragesHomeTable classes={classes} policy={policy} claim={claim} />
          </div>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <div className={classes.cardDivRow}>
            <HomeCoveragesTable
              classes={classes}
              policy={policy}
              claim={claim}
              onClaimUpdate={onClaimUpdate}
              EditCoverageHover={EditCoverageHover}
            />
          </div>
        </Grid>
      )}
      {policy.policy_endorsements.length > 0 && (
        <Grid item xs={12}>
          <div className={classes.cardDivRow}>
            <PolicyEndorsementsTable policy={policy} claim={claim} onClaimUpdate={onClaimUpdate} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

HomePolicyPage.propTypes = {
  EditCoverageHover: PropTypes.func.isRequired,
};

export { HomePolicyDetailsCard };
export default HomePolicyPage;
