import React from 'react';

import { useStyles } from '~/assets/styles';
import type { OnUserFeedbackCallback } from '~/components/AiChat/components/Message/MessageTypes';
import { FsIconButton } from '~/components/core';
import { ThumbDownIcon, ThumbUpIcon } from '~/components/icons';

import colors from '../../../../theme/tailwind/colors';

const MessageFeedbackIconButton: React.FC<{
  tooltipText: string;
  onClick: OnUserFeedbackCallback;
  isSelectedValue: number;
  currentScore?: number;
  exchangeId: number;
  iconComponent: React.FC<{ iconColor?: string; size?: number }>;
}> = ({ tooltipText, onClick, isSelectedValue, currentScore, exchangeId, iconComponent }) => {
  const classes = useStyles() as { hoverableIcon: string };

  const isSelected = isSelectedValue === currentScore;

  const Icon = iconComponent;

  return (
    <FsIconButton
      className={classes.hoverableIcon}
      tooltipText={tooltipText}
      onClick={() => onClick(exchangeId, isSelectedValue)}
    >
      <Icon size={20} iconColor={isSelected ? colors.teal[700] : undefined} />
    </FsIconButton>
  );
};

interface MessageFeedbackProps {
  onClick: OnUserFeedbackCallback;
  exchangeId: number;
  currentScore?: number;
}

export const PositiveFeedbackIconButton: React.FC<MessageFeedbackProps> = ({ onClick, exchangeId, currentScore }) => (
  <MessageFeedbackIconButton
    tooltipText="This was a helpful response!"
    onClick={onClick}
    isSelectedValue={1}
    currentScore={currentScore}
    exchangeId={exchangeId}
    iconComponent={ThumbUpIcon}
  />
);

export const NegativeFeedbackIconButton: React.FC<MessageFeedbackProps> = ({ onClick, exchangeId, currentScore }) => (
  <MessageFeedbackIconButton
    tooltipText="This is not helpful"
    onClick={onClick}
    isSelectedValue={-1}
    currentScore={currentScore}
    exchangeId={exchangeId}
    iconComponent={ThumbDownIcon}
  />
);
