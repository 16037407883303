import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useFormikContext } from 'formik';

import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { ErrorHelperTextFormik } from '../../core/Formik/ErrorHelperTextFormik';
import HoverChangeField from '../../HoverChangeField';

function PayToFormikInner({ payeeContactsSelected, showOnly }) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { shouldUsePayToLine } = usePaymentsConfiguration(values.payment_method);
  let primaryPayee;
  let customPayToLine;
  let defaultPayToLineText;

  primaryPayee = payeeContactsSelected.find((contact) => contact.id === values['primary_payee_id']);
  customPayToLine = values.custom_pay_to_line;
  const payeeContactsSelectedSorted = [...payeeContactsSelected].sort(
    (contact1, contact2) => contact1.id - contact2.id
  ); // for consistency with backend
  defaultPayToLineText =
    `${primaryPayee ? primaryPayee.full_name : ''}` +
    `${payeeContactsSelectedSorted
      .filter((contact) => contact.id !== values['primary_payee_id'])
      .map((contact, idx) => `${idx === 0 && !primaryPayee ? ' ' : ', '}${contact.full_name}`)
      .join('')}`;

  // For backward compatibility we want to update pay_to_line even if we don't display it
  React.useEffect(() => {
    if (customPayToLine) {
      setFieldValue('pay_to_line', customPayToLine);
    } else {
      setFieldValue('pay_to_line', defaultPayToLineText);
    }

    setFieldTouched('pay_to_line');
  }, [defaultPayToLineText, customPayToLine, setFieldValue, setFieldTouched]);

  if (!shouldUsePayToLine) {
    return <></>;
  }

  return (
    <>
      <Typography variant="caption" display="block">
        <HoverChangeField
          name="custom_pay_to_line"
          value={values.custom_pay_to_line || defaultPayToLineText}
          label="Pay to"
          disabled={showOnly}
          onUpdate={({ custom_pay_to_line }) => setFieldValue('custom_pay_to_line', custom_pay_to_line)}
          width="450px"
        >
          <span style={{ textDecoration: 'underline' }}>Pay To</span>:
          <em>
            {values.custom_pay_to_line ? (
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                &nbsp;{values.custom_pay_to_line}
                <Tooltip title="Custom Defined">
                  <InfoIcon />
                </Tooltip>
              </span>
            ) : (
              <>
                {primaryPayee && <strong> {primaryPayee.full_name}</strong>}
                {payeeContactsSelectedSorted
                  .filter((contact) => contact.id !== values['primary_payee_id'])
                  .map((contact, idx) => (
                    <span key={contact.id}>
                      {idx === 0 && !primaryPayee ? ' ' : ', '}
                      {contact.full_name}
                    </span>
                  ))}
              </>
            )}
          </em>
        </HoverChangeField>
      </Typography>
      <ErrorHelperTextFormik id="pay_to_line" />
    </>
  );
}
PayToFormikInner.propTypes = {
  payeeContactsSelected: PropTypes.array.isRequired,
  showOnly: PropTypes.bool,
};

export default PayToFormikInner;
