import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';

import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';
import { getFirstPartyFromClaim, getThirdPartyVehiclesFromClaim } from '~/Utils/ClaimUtils';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../../Types';
import { cleanEmptyValues, isFeatureEnabled, reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../../core';
import { useCms } from '../../../../hooks/useCms';
import { InvolvedPropertyParty } from '../../../../InvolvedProperty';
import AdditionalInformationDialog from '../../AdditionalInformation/AdditionalInformationDialog';
import {
  getInitialFaultAssessmentInitialValidationSchema,
  getInitialFaultAssessmentInitialValues,
  getInitialFaultAssessmentSectionConfig,
  InitialFaultAssessmentFragment,
} from '../../AdditionalInformation/Fragments/InitialFaultAssessmentFragment';
import {
  getInvolvedPropertyValidationFields,
  InvolvedPropertyFragment,
} from '../InvolvedPropertyParty/InvolvedPropertyFragment';

import FaultAssessmentDetails from './FaultAssessment';
import NonMotoristDetails from './NonMotoristInvolved';
import VehicleParty from './VehicleParty';

import { useStyles } from '../../../../../assets/styles';

function NewFnolAutoInvolvedCard({ claim, onUpdate }) {
  return (
    <NewFnolAutoInvolvedCardInner
      onUpdate={onUpdate}
      claim={claim}
      firstParty={getFirstPartyFromClaim(claim)}
      thirdPartyVehicles={getThirdPartyVehiclesFromClaim(claim)}
      thirdPartyNonMotorists={claim.incident.non_motorist_parties.filter((party) => !party.is_first_party)}
      thirdPartyOtherProperties={claim.incident.other_properties_parties}
    />
  );
}

NewFnolAutoInvolvedCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

class NewFnolAutoInvolvedCardInner extends Component {
  state = {
    setIsFetching: false,
  };

  handleUpdateVehicleParty = async (partyUrlPrefix, operationType, data) => {
    try {
      switch (operationType) {
        case 'SET_INVOLVED_VEHICLE':
          await axios.post(`${partyUrlPrefix}/involved_vehicle`, {
            ...cleanEmptyValues(data),
            vehicle: cleanEmptyValues(data?.vehicle) || {},
          });
          break;
        case 'UPDATE_INVOLVED_VEHICLE':
          await axios.put(`${partyUrlPrefix}/involved_vehicle`, {
            ...cleanEmptyValues(data),
            vehicle: cleanEmptyValues(data?.vehicle) || {},
          });
          break;
        case 'DELETE_INVOLVED_VEHICLE':
          await axios.delete(`${partyUrlPrefix}/involved_vehicle`);
          break;
        case 'SET_DRIVER':
          await axios.post(`${partyUrlPrefix}/driver`, { injuries: [], ...cleanEmptyValues(data) });
          break;
        case 'UPDATE_DRIVER':
          await axios.put(`${partyUrlPrefix}/driver`, { injuries: [], ...cleanEmptyValues(data) });
          break;
        case 'DELETE_DRIVER':
          await axios.delete(`${partyUrlPrefix}/driver`);
          break;
        case 'ADD_PASSENGER':
          await axios.post(`${partyUrlPrefix}/passengers`, { injuries: [], ...cleanEmptyValues(data) });
          break;
        case 'UPDATE_PASSENGER':
          // data = { passenger, idx }
          await axios.put(`${partyUrlPrefix}/passengers/${data.passenger.id}`, {
            injuries: [],
            ...cleanEmptyValues(data.passenger),
          });
          break;

        case 'DELETE_PASSENGER':
          // data = { passenger, idx }
          await axios.delete(`${partyUrlPrefix}/passengers/${data.passenger.id}`);
          break;

        default:
          throw Error(`Should not happen Unknown ${operationType}`);
      }

      await this.props.onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleFetchInvolvedPerson = async (involvedHeader) => {
    const { claim } = this.props;
    try {
      const res = await axios.get(`/api/v1/claims/${claim.id}/involved_person/${involvedHeader.id}`);
      return res.data;
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleFetchInvolvedProperty = async (involvedHeader) => {
    const { claim } = this.props;
    try {
      const res = await axios.get(`/api/v1/claims/${claim.id}/involved_property/${involvedHeader.id}`);
      return res.data;
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateFirstPartyVehicle = (operation, data) => {
    const { claim } = this.props;
    return this.handleUpdateVehicleParty(`/api/v1/auto_claims/${claim.id}/first_party_vehicle`, operation, data);
  };

  handleUpdateFirstPartyNonMotorist = async (data) => {
    const { claim, onUpdate } = this.props;
    const cleanData = { injuries: [], ...cleanEmptyValues(data) };

    try {
      await axios.put(`/api/v1/auto_claims/${claim.id}/first_party_non_motorist`, cleanData);
      return await Promise.resolve(onUpdate());
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateThirdPartyVehicle = (idx, operation, data) => {
    const { claim, thirdPartyVehicles } = this.props;
    return this.handleUpdateVehicleParty(
      `/api/v1/auto_claims/${claim.id}/third_party_vehicles/${thirdPartyVehicles[idx].id}`,
      operation,
      data
    );
  };

  handleAddThirdPartyVehicle = () => {
    const { claim, onUpdate } = this.props;
    return axios
      .post(`/api/v1/auto_claims/${claim.id}/third_party_vehicles`)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleDeleteThirdPartyVehicle = (idx) => {
    const { claim, onUpdate, thirdPartyVehicles } = this.props;
    return axios
      .delete(`/api/v1/auto_claims/${claim.id}/third_party_vehicles/${thirdPartyVehicles[idx].id}`)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleUpdateThirdPartyNonMotorist = (idx, nonMotorist) => {
    const { claim, onUpdate, thirdPartyNonMotorists } = this.props;
    const currentNonMotorist = thirdPartyNonMotorists[idx];
    const data = { injuries: [], ...cleanEmptyValues(nonMotorist) };

    return axios({
      method: currentNonMotorist.involved_non_motorist ? 'put' : 'post',
      url: `/api/v1/auto_claims/${claim.id}/third_party_non_motorists/${currentNonMotorist.id}/involved_non_motorist`,
      data,
    })
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleDeleteThirdPartyNonMotorist = (idx) => {
    const { claim, onUpdate, thirdPartyNonMotorists } = this.props;
    return axios
      .delete(`/api/v1/auto_claims/${claim.id}/third_party_non_motorists/${thirdPartyNonMotorists[idx].id}`)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleAddThirdPartyNonMotorist = (newNonMotoristParty) => {
    const { claim, onUpdate } = this.props;
    return axios
      .post(`/api/v1/auto_claims/${claim.id}/third_party_non_motorists`, newNonMotoristParty)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleAddThirdPartyOtherProperty = async () => {
    const { claim, onUpdate } = this.props;

    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/third_party_other_properties`);
      return await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateThirdPartyOtherProperty = async (idx, otherPropertyParty) => {
    const { claim, thirdPartyOtherProperties, onUpdate } = this.props;
    const { generic_property_involved } = otherPropertyParty;
    const payload = {
      generic_property_involved: {
        ...generic_property_involved,
        property_name: generic_property_involved.property_description || '',
      },
    };

    try {
      await axios.post(
        `/api/v1/auto_claims/${claim.id}/third_party_other_properties/${thirdPartyOtherProperties[idx].id}`,
        payload
      );
      return await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleDeleteThirdPartyOtherProperty = async (idx) => {
    const { claim, thirdPartyOtherProperties, onUpdate } = this.props;

    try {
      await axios.delete(
        `/api/v1/auto_claims/${claim.id}/third_party_other_properties/${thirdPartyOtherProperties[idx].id}`
      );
      return await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateIncidentFields = async (updateObject) => {
    const { claim, onUpdate } = this.props;
    const claimObj = { incident: updateObject };
    try {
      await axios.patch(`/api/v1/auto_claims/${claim.id}`, claimObj);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  render() {
    const { claim, firstParty, thirdPartyVehicles, thirdPartyNonMotorists, thirdPartyOtherProperties } = this.props;

    const { isFetching } = this.state;
    return (
      <AutoInvolvedFragment
        claim={claim}
        disabled={isFetching}
        firstParty={firstParty}
        thirdPartyVehicles={thirdPartyVehicles}
        thirdPartyNonMotorists={thirdPartyNonMotorists}
        thirdPartyOtherProperties={thirdPartyOtherProperties}
        onFetchInvolvedPerson={this.handleFetchInvolvedPerson}
        onFetchInvolvedProperty={this.handleFetchInvolvedProperty}
        onUpdateFirstPartyVehicle={this.handleUpdateFirstPartyVehicle}
        onUpdateFirstPartyNonMotorist={this.handleUpdateFirstPartyNonMotorist}
        onUpdateThirdPartyVehicle={this.handleUpdateThirdPartyVehicle}
        onAddThirdPartyVehicle={this.handleAddThirdPartyVehicle}
        onDeleteThirdPartyVehicle={this.handleDeleteThirdPartyVehicle}
        onUpdateNonMotorist={this.handleUpdateThirdPartyNonMotorist}
        onDeleteNonMotorist={this.handleDeleteThirdPartyNonMotorist}
        onAddThirdPartyNonMotorist={this.handleAddThirdPartyNonMotorist}
        onAddOtherPropertyParty={this.handleAddThirdPartyOtherProperty}
        onUpdateOtherPropertyParty={this.handleUpdateThirdPartyOtherProperty}
        onDeleteOtherPropertyParty={this.handleDeleteThirdPartyOtherProperty}
        incident={claim.incident}
        onUpdateFields={this.handleUpdateIncidentFields}
      />
    );
  }
}

NewFnolAutoInvolvedCardInner.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  firstParty: PropTypes.object.isRequired,
  thirdPartyVehicles: PropTypes.array.isRequired,
  thirdPartyNonMotorists: PropTypes.array.isRequired,
  thirdPartyOtherProperties: PropTypes.array.isRequired,
};

function AutoInvolvedFragment(props) {
  const {
    disabled,
    claim,
    firstParty,
    thirdPartyVehicles,
    thirdPartyNonMotorists,
    thirdPartyOtherProperties,
    onFetchInvolvedPerson,
    onFetchInvolvedProperty,
    onAddThirdPartyNonMotorist,
    onAddThirdPartyVehicle,
    onAddOtherPropertyParty,
    onUpdateFirstPartyVehicle,
    onUpdateFirstPartyNonMotorist,
    onUpdateThirdPartyVehicle,
    onUpdateNonMotorist,
    onUpdateOtherPropertyParty,
    onDeleteThirdPartyVehicle,
    onDeleteNonMotorist,
    onDeleteOtherPropertyParty,
    incident,
    onUpdateFields,
  } = props;

  const currentFaultAssessment = {
    first_party_fault_assessment: incident?.first_party_fault_assessment,
    claim_handler_fault_assessment: incident?.claim_handler_fault_assessment,
    percentage_of_insured_liability: incident?.percentage_of_insured_liability,
    is_indemnity_review_needed: incident?.is_indemnity_review_needed,
    is_fraud_review_needed: incident?.is_fraud_review_needed,
    liability_summary: incident?.liability_summary,
  };

  const classes = useStyles();
  const { userOrganization } = useCms();

  const [showNewNonMotoristParty, setShowNewNonMotoristParty] = useState(false);
  const [addNewPartyAnchorEl, setAddNewPartyAnchorEl] = useState(null);
  const [ShowEditFaultAssessment, setShowEditFaultAssessment] = useState(false);
  const { incidentConfiguration } = useIncidentConfiguration();

  const allowDeletingInvolvedParties = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.DELETE_INVOLVED_PARTIES
  );

  const isMoveFaultAssessmentEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MOVE_FAULT_ASSESSMENT_TO_MANAGEMENT_TAB
  );

  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();

  return (
    <>
      <CardDialog
        title="Involved Parties"
        action={
          <>
            <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
              <FsButton
                className={`${classes.actionButton} ${classes.button}`}
                variant="contained"
                color="primary"
                onClick={(e) => setAddNewPartyAnchorEl(e.currentTarget)}
                endIcon={<ExpandMoreIcon />}
              >
                + Add
              </FsButton>
            </RestrictedPermissions>

            <Menu
              anchorEl={addNewPartyAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              getContentAnchorEl={null}
              open={!!addNewPartyAnchorEl}
              onClose={() => setAddNewPartyAnchorEl(null)}
            >
              {[
                { title: 'Add Vehicle', onClick: onAddThirdPartyVehicle },
                {
                  title: 'Add Non Motorist',
                  onClick: () => onAddThirdPartyNonMotorist({ non_motorist_type: 'non_motorist' }),
                },
                { title: 'Add Other Property', onClick: onAddOtherPropertyParty },
              ].map(({ title, onClick }, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => {
                    onClick();
                    setAddNewPartyAnchorEl(null);
                  }}
                >
                  {title}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      >
        {getInitialFaultAssessmentSectionConfig(incidentConfiguration)?.active && isMoveFaultAssessmentEnabled && (
          <div className={classes.cardDivRowInternal}>
            <FaultAssessmentDetails onEditFaultAssessment={() => setShowEditFaultAssessment(true)} />
          </div>
        )}
        <div className={classes.cardDivRowInternal}>
          {firstParty.type === 'vehicle_party' ? (
            <VehicleParty
              classes={classes}
              title="Insured Party"
              isInsured
              disabled={disabled}
              vehicleParty={firstParty}
              onFetchInvolvedPerson={onFetchInvolvedPerson}
              onFetchInvolvedProperty={onFetchInvolvedProperty}
              onUpdateVehicle={onUpdateFirstPartyVehicle}
              shouldIncludePassengers
              allowDeleteInvolved={allowDeletingInvolvedParties}
            />
          ) : (
            <NonMotoristDetails
              classes={classes}
              title="Insured Party"
              disabled={disabled}
              nonMotoristParty={firstParty}
              onFetchNonMotoristDetails={onFetchInvolvedPerson}
              onSaveNonMotoristDetails={async (nonMotorist) => {
                return await onUpdateFirstPartyNonMotorist(nonMotorist);
              }}
            />
          )}
        </div>

        {thirdPartyVehicles.map((vehicleParty, curIdx) => (
          <div className={classes.cardDivRowInternal} key={curIdx}>
            <VehicleParty
              classes={classes}
              title={`3rd Party Vehicle (${curIdx + 1})`}
              disabled={disabled}
              isInsured={false}
              vehicleParty={vehicleParty}
              onFetchInvolvedPerson={onFetchInvolvedPerson}
              onFetchInvolvedProperty={onFetchInvolvedProperty}
              onUpdateVehicle={(operation, data) => onUpdateThirdPartyVehicle(curIdx, operation, data)}
              shouldIncludePassengers
              allowDeleteInvolved={allowDeletingInvolvedParties}
              onDeleteVehicle={allowDeletingInvolvedParties ? () => onDeleteThirdPartyVehicle(curIdx) : undefined}
            />
          </div>
        ))}
        {thirdPartyNonMotorists.map((nonMotoristParty, curIdx) => (
          <div className={classes.cardDivRowInternal} key={nonMotoristParty.id}>
            <NonMotoristDetails
              classes={classes}
              title={`3rd Party Non Motorist (${curIdx + 1}`}
              disabled={disabled}
              nonMotoristParty={nonMotoristParty}
              onFetchNonMotoristDetails={onFetchInvolvedPerson}
              onSaveNonMotoristDetails={async (nonMotorist) => {
                const partyNonMotoristIndex = thirdPartyNonMotorists.findIndex(
                  (thirdPartyNonMotorist) => thirdPartyNonMotorist.id === nonMotoristParty.id
                );
                return await onUpdateNonMotorist(partyNonMotoristIndex, nonMotorist);
              }}
              onDeleteNonMotorist={allowDeletingInvolvedParties ? () => onDeleteNonMotorist(curIdx) : undefined}
            />
          </div>
        ))}
        {thirdPartyOtherProperties.map((involvedPropertyParty, curIdx) => (
          <div className={classes.cardDivRowInternal} key={curIdx}>
            <InvolvedPropertyParty
              title={`3rd Party Other Property (${curIdx + 1})`}
              propertyLabel="Other Property"
              involvedPropertyParty={involvedPropertyParty}
              onFetchInvolvedPropertyDetails={onFetchInvolvedProperty}
              onSaveInvolvedPropertyDetails={(involvedPropertyParty) =>
                onUpdateOtherPropertyParty(curIdx, involvedPropertyParty)
              }
              disabled={disabled}
              InvolvedPropertyFragmentOverride={InvolvedPropertyFragment}
              involvedPropertyValidationFields={getInvolvedPropertyValidationFields(claim.incident_configuration)}
              onDeleteInvolvedProperty={
                allowDeletingInvolvedParties ? () => onDeleteOtherPropertyParty(curIdx) : undefined
              }
            />
          </div>
        ))}
      </CardDialog>

      {showNewNonMotoristParty && (
        <NonMotoristDetails
          onSubmitNewNonMotorist={(newParty) => {
            onAddThirdPartyNonMotorist(newParty);
            setShowNewNonMotoristParty(false);
          }}
          onCancel={() => setShowNewNonMotoristParty(false)}
        />
      )}

      {ShowEditFaultAssessment && (
        <AdditionalInformationDialog
          initialValues={currentFaultAssessment}
          open={ShowEditFaultAssessment}
          onCancel={() => setShowEditFaultAssessment(false)}
          onSubmit={async (values) => {
            await onUpdateFields(values);
            setShowEditFaultAssessment(false);
          }}
          title="Fault Assessment"
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={InitialFaultAssessmentFragment}
          AdditionalInformationFragmentInitialValues={getInitialFaultAssessmentInitialValues(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
          AdditionalInformationFragmentValidationSchema={getInitialFaultAssessmentInitialValidationSchema(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
        />
      )}
    </>
  );
}

AutoInvolvedFragment.propTypes = {
  claim: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  firstParty: PropTypes.object.isRequired,
  thirdPartyVehicles: PropTypes.array.isRequired,
  thirdPartyNonMotorists: PropTypes.array.isRequired,
  thirdPartyOtherProperties: PropTypes.array.isRequired,
  onFetchInvolvedPerson: PropTypes.func.isRequired,
  onFetchInvolvedProperty: PropTypes.func.isRequired,
  onUpdateFirstPartyVehicle: PropTypes.func.isRequired,
  onUpdateFirstPartyNonMotorist: PropTypes.func.isRequired,
  onUpdateThirdPartyVehicle: PropTypes.func.isRequired,
  onAddThirdPartyVehicle: PropTypes.func.isRequired,
  onDeleteThirdPartyVehicle: PropTypes.func,
  onUpdateNonMotorist: PropTypes.func.isRequired,
  onDeleteNonMotorist: PropTypes.func,
  onAddThirdPartyNonMotorist: PropTypes.func.isRequired,
  onAddOtherPropertyParty: PropTypes.func.isRequired,
  onUpdateOtherPropertyParty: PropTypes.func.isRequired,
  onDeleteOtherPropertyParty: PropTypes.func,
  incident: PropTypes.object.isRequired,
  onUpdateFields: PropTypes.func.isRequired,
};

export default NewFnolAutoInvolvedCard;
