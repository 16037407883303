/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RestoreIcon from '@material-ui/icons/Restore';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isBoostOrganization, isExposureFinancialOnly, isFeatureEnabled, isPolicyNotFound } from '../../Utils';
import { isClaimWriteDisabled } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { FsButton, PermissionsButtonWrapper } from '../core';
import ClaimCoverageDecisionContainer from '../CoverageDecision/ClaimCoverageDecisionContainer';
import { ClaimGenericTransactionsSummaryContainer } from '../GenericTransactionSummary';
import { useCms } from '../hooks/useCms';
import { InvolvedPersonHoverInfo } from '../InvolvedPersonHoverInfo';
import { InvolvedPropertyHoverInfo } from '../InvolvedPropertyHoverInfo';
import { PetDeductibleContainer } from '../Pet/PetManagementFinancial';

import ExposuresTable from './ExposuresTable/ExposuresTable';
import DeductibleContainer from './DeductibleContainer';
import { AddExposureDialog } from './ExposureDialog';
import { AddExposureDialogConfigurableCoverages } from './ExposureDialogConfigurableCoverages';

import { useStyles } from '../../assets/styles';

function ExposuresCard() {
  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const classes = useStyles();

  const [showAddExposureDialog, setShowAddExposureDialog] = React.useState(false);
  const [showViewClaimTransactions, setShowViewClaimTransactions] = React.useState(false);

  const readOnly = isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true });
  const hideClaimTransactionHistory = claim.type === 'wc_claim'; // TODO: genericize

  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );

  return (
    <>
      <CardDialog
        title="Exposures"
        action={
          <div style={{ padding: '6px' }}>
            <Grid container>
              {!hideClaimTransactionHistory && (
                <Grid item>
                  <FsButton
                    color={FsButton.COLORS.primary}
                    onClick={() => setShowViewClaimTransactions(true)}
                    tooltipText="View Claim Transactions"
                    ignorePermissions
                  >
                    <RestoreIcon className={classes.leftButtonIcon} />
                    View transaction history
                  </FsButton>
                </Grid>
              )}
              {/* Add exposure is not supported for GL, WC claims */}
              {!['gl_claim', 'wc_claim'].includes(claim.type) && (
                <Grid item>
                  <PermissionsButtonWrapper>
                    <FsButton
                      color={FsButton.COLORS.primary}
                      disabled={readOnly}
                      onClick={() => setShowAddExposureDialog(true)}
                    >
                      <AddIcon />
                      Add Exposure
                    </FsButton>
                  </PermissionsButtonWrapper>
                </Grid>
              )}
            </Grid>
          </div>
        }
      >
        <Grid container>
          <Grid item>{claim.type === 'home_claim' && !isPolicyNotFound(claim) && <DeductibleContainer />}</Grid>
          <Grid item>
            {claim.lob === 'pet_claim' && isBoostOrganization(userOrganization) && <PetDeductibleContainer />}
          </Grid>
          <Grid item>{isExposureFinancialOnly(userOrganization) && <ClaimCoverageDecisionContainer />}</Grid>
        </Grid>
        <br />
        <ExposuresTable claim={claim} />
      </CardDialog>
      {showAddExposureDialog &&
        (isConfigurableCoveragesEnabled ? (
          <AddExposureDialogConfigurableCoverages
            open={showAddExposureDialog}
            onAddExposure={() => onClaimUpdate().then(() => setShowAddExposureDialog(false))}
            onCancel={() => setShowAddExposureDialog(false)}
          />
        ) : (
          <AddExposureDialog
            open={showAddExposureDialog}
            onAddExposure={() => onClaimUpdate().then(() => setShowAddExposureDialog(false))}
            onCancel={() => setShowAddExposureDialog(false)}
          />
        ))}
      {showViewClaimTransactions && (
        <ClaimGenericTransactionsSummaryContainer
          onClose={() => setShowViewClaimTransactions(false)}
          onUpdate={onClaimUpdate}
          isDialog
        />
      )}
    </>
  );
}

function ExposureInvolvedHoverInfo({ exposure }) {
  const involvedPerson = exposure.involved_person;
  const involvedProperty = exposure.involved_property;

  if (involvedPerson) {
    return <InvolvedPersonHoverInfo claimId={exposure.claim_id} involvedPerson={involvedPerson} />;
  } else if (involvedProperty) {
    return <InvolvedPropertyHoverInfo claimId={exposure.claim_id} involvedProperty={involvedProperty} />;
  } else {
    return <></>;
  }
}

ExposureInvolvedHoverInfo.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default ExposuresCard;
export { ExposureInvolvedHoverInfo };
