import React, { useEffect, useState } from 'react';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { noop } from 'lodash';
import type { BaseSchema } from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import AlertBanner from '~/components/core/AlertBanner';
import Stepper from '~/components/core/Stepper';
import Text from '~/components/core/TextComponents/Text';
import { TextWithIcon } from '~/components/core/TextWithIcon';
import EditMoiDialogInner from '~/components/exposures/moi/MoiStepper/EditMoiDialogInner';
import type {
  MethodOfInspection,
  MoiAlert,
  MoiFormikValues,
  MoiStatuses,
  MoiStepperProps,
} from '~/components/exposures/moi/MoiStepper/types';
import useMoiMethods from '~/components/exposures/moi/MoiStepper/useMoiMethods';
import {
  CREATE_MOI_INSTRUCTIONS_TEXT,
  GENERAL_EXPOSURE_FAKE_ID,
  mapToInitialValues,
  trackMoiSubmit,
} from '~/components/exposures/moi/MoiUtils';
import type { ExposureModel } from '~/components/exposures/types';
import { ArrowDownwardIcon } from '~/components/icons';
import MOI_STATUSES from '~/server_shared/generated-types/MOI_STATUSES';
import { reportAxiosError } from '~/Utils';

import type { MoiStepConfig } from './MoiSteps';
import MOI_STEPS from './MoiSteps';
import MoiStepWrapper from './MoiStepWrapper';
import MoiSummary from './MoiSummary';

const MoiStepper: React.FC<MoiStepperProps> = ({
  exposure,
  onBackClick = noop,
  onSubmit = noop,
  moiRecord,
  onClose = noop,
  isEditMode,
}) => {
  const [currentStep, setCurrentStep] = useState(MOI_STEPS({})[0]);
  const [currentValidation, setCurrentValidation] = useState(currentStep?.validationSchema);
  const { claim } = useClaim();
  const [selectedContact, setSelectedContact] = useState(moiRecord?.contact);
  const [isVendorApi, setIsVendorApi] = useState(false);

  const [isXactContractor, setIsXactContractor] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: AlertBanner.ALERT_TYPES.INFO, message: '' } as MoiAlert);

  useEffect(() => {
    setCurrentValidation(currentStep?.validationSchema);
  }, [currentStep]);

  useEffect(() => {
    (async () => {
      if (moiRecord?.contact?.id) {
        const { data } = await getIsContactVendorApi(moiRecord.contact);
        setIsVendorApi(data?.is_vendor_api);
      }
    })();
  }, [moiRecord]);

  const getIsContactVendorApi = (contact: ContactFullModel) => {
    return axios.get(`/api/v1/contacts/${contact.id}/is_vendor_api`);
  };

  const statuses: MoiStatuses = { ...MOI_STATUSES };
  if (!isVendorApi) {
    delete statuses['completed'];
    delete statuses['accepted'];
  }

  return (
    <>
      <Formik
        initialValues={mapToInitialValues(moiRecord, exposure, claim)}
        validationSchema={currentValidation}
        // Submitting is done from the Stepper
        onSubmit={noop}
        enableReinitialize
      >
        <MoiStepperInner
          onBackClick={onBackClick}
          exposure={exposure}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          getIsContactVendorApi={getIsContactVendorApi}
          setIsXactContractor={setIsXactContractor}
          setIsVendorApi={setIsVendorApi}
          isVendorApi={isVendorApi}
          onSubmit={onSubmit}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          moiRecord={moiRecord}
          setCurrentValidation={setCurrentValidation}
          currentValidation={currentValidation}
          isXactContractor={isXactContractor}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
          onClose={onClose}
          isEditMode={isEditMode}
        />
      </Formik>
    </>
  );
};

interface MoiStepperInnerProps {
  exposure?: ExposureModel;
  onBackClick?: () => void;
  onSubmit: (moiRecord: MethodOfInspection) => Promise<void> | void;
  moiRecord?: MethodOfInspection;
  selectedContact?: ContactFullModel;
  setSelectedContact: (contact: ContactFullModel | undefined) => void;
  getIsContactVendorApi: (contact: ContactFullModel) => Promise<AxiosResponse<{ is_vendor_api: boolean }>>;
  setIsXactContractor: (isXactContractor: boolean) => void;
  setIsVendorApi: (isVendorApi: boolean) => void;
  isVendorApi: boolean;
  setCurrentValidation: (currentValidation: BaseSchema) => void;
  currentValidation: BaseSchema;
  setCurrentStep: (currentStep: MoiStepConfig) => void;
  currentStep?: MoiStepConfig;
  isXactContractor: boolean;
  alertMessage: MoiAlert;
  setAlertMessage: (moiAlert: MoiAlert) => void;
  onClose: () => void;
  isEditMode: boolean;
}

const MoiStepperInner: React.FC<MoiStepperInnerProps> = ({
  onBackClick,
  exposure,
  selectedContact,
  setSelectedContact,
  getIsContactVendorApi,
  setIsXactContractor,
  setIsVendorApi,
  isVendorApi,
  onSubmit,
  setCurrentStep,
  currentStep,
  moiRecord,
  setCurrentValidation,
  currentValidation,
  isXactContractor,
  alertMessage,
  setAlertMessage,
  onClose,
  isEditMode,
}) => {
  const { values, setSubmitting } = useFormikContext<MoiFormikValues>();
  const {
    claim,
    onAsyncClaimUpdate,
  }: { claim: { [key: string]: unknown; id: number }; onAsyncClaimUpdate: () => Promise<unknown> } = useClaim();
  const moiMethodsReturn = useMoiMethods(exposure?.id, exposure?.isGeneralExpenses);
  const { moiMethodsById } = moiMethodsReturn;
  const MOI_STEPS_WITH_CONTEXT = MOI_STEPS({
    formikContext: useFormikContext<MoiFormikValues>(),
    moiMethodsById,
    claim_id: claim.id,
  });

  const handleSubmitMoi = async (values: MoiFormikValues, isEditMode = false): Promise<MethodOfInspection> => {
    if (isEditMode) {
      const { data } = await axios.patch(`/api/v1/claims/${claim.id}/method_of_inspection`, {
        ...values,
        contact: selectedContact,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trackMoiSubmit({ claim, exposure, actionType: 'edit' });
      return data;
    }

    const { data } = await axios.post(`/api/v1/claims/${claim.id}/method_of_inspection`, {
      ...values,
      contact: selectedContact,
      is_claim_level: values.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID),
      exposure_ids: values.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID) ? [] : values.exposure_ids,
      moi_method_key: moiMethodsById[values.moi_method_id]?.key,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    trackMoiSubmit({ claim, exposure, actionType: 'add' });
    return data;
  };

  const handleSubmitForm = async () => {
    try {
      setSubmitting(true);
      setAlertMessage({ message: '', type: 'info' });
      const data = await handleSubmitMoi(values, isEditMode);
      await onAsyncClaimUpdate();
      setSelectedContact(undefined);
      await onSubmit(data);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.response.data.message.startsWith('Notify User:')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const notifyUserMessage = error.response.data.message.replace('Notify User:', '');
        setAlertMessage({
          message: notifyUserMessage,
          type: 'error',
        });
        const actionType = 'add';
        trackMoiSubmit({ claim, exposure, actionType, errorMessage: notifyUserMessage });
        setSubmitting(false);
      } else {
        await reportAxiosError(error);
      }
    }
  };

  useEffect(() => {
    // TODO: this needs to be refactored and maybe moved to a hook / dedicated component
    if (isEditMode) {
      setAlertMessage({
        message: 'The changes will be updated at the MOI table, no update will be sent to the assignee.',
        type: 'warning',
      });
      return;
    }
    if (selectedContact?.id) {
      if (isXactContractor) {
        setAlertMessage({
          message: 'The assignment will be automatically sent to the service provider via XactAnalysis.',
          type: 'info',
        });
      } else if (isVendorApi) {
        setAlertMessage({
          message: 'The assignment will be automatically sent to the service provider via API.',
          type: 'info',
        });
      } else if (selectedContact?.emails.length > 0 && values['exposure_ids'].includes(GENERAL_EXPOSURE_FAKE_ID)) {
        setAlertMessage({
          message:
            'Assignment email will be presented for review. No update will be sent if new exposures will be added to the claim.',
          type: 'info',
        });
      } else if (selectedContact?.emails.length > 0) {
        setAlertMessage({
          message: 'Assignment email will be presented for review',
          type: 'info',
        });
      } else {
        setAlertMessage({
          message: 'To present an email for review, add an email address to the selected contact.',
          type: 'warning',
        });
      }
    } else if (values['assignee_type'] === 'internal_user' && values['user_id']) {
      setAlertMessage({
        message: 'The assignment will be automatically sent to the assigned user by internal communication.',
        type: 'info',
      });
    } else if (values['assignee_type'] === 'appraiser') {
      setAlertMessage({
        message: 'The assignment will be automatically sent to the selected appraiser via CCC',
        type: 'info',
      });
    } else {
      setAlertMessage({
        message: '',
        type: 'info',
      });
    }
  }, [isVendorApi, selectedContact, isXactContractor, values, setAlertMessage, isEditMode]);

  return isEditMode ? (
    <EditMoiDialogInner
      setSelectedContact={setSelectedContact}
      getIsContactVendorApi={getIsContactVendorApi}
      setIsXactContractor={setIsXactContractor}
      setIsVendorApi={setIsVendorApi}
      isVendorApi={isVendorApi}
      setCurrentValidation={setCurrentValidation}
      currentValidation={currentValidation}
      setAlertMessage={setAlertMessage}
      alertMessage={alertMessage}
      handleSubmitForm={handleSubmitForm}
      onClose={onBackClick}
      exposure={exposure}
      selectedContact={selectedContact}
      moiMethodsReturn={moiMethodsReturn}
    />
  ) : (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Stepper
      isDialog
      dialogProps={{
        subheader: (
          <>
            <Text variant={Text.VARIANTS.XL}>New MOI (Method of Inspection)</Text>
            <div className="mb-30 mt-20 leading-5 text-slate-800">{CREATE_MOI_INSTRUCTIONS_TEXT}</div>
          </>
        ),
        maxWidth: 'lg',
        fullWidth: true,
        onClose,
        trackAlt: 'New Method of Inspection Card',
        isDialog: true,
        headerStyle: { padding: '0 0 0 20px' },
        footerClassName: 'p-0 shadow-lg',
      }}
      contentClassName="py-24"
      headerClassName="p-16"
      stepsContainerClassName="flex-1 overflow-auto flex flex-col"
      sideComponent={
        <MoiSummary
          exposure={exposure}
          currentStep={currentStep}
          selectedContact={selectedContact}
          isEditMode={isEditMode}
          moiMethodsReturn={moiMethodsReturn}
        />
      }
      onComplete={handleSubmitForm}
      onStepChange={(nextStep, nextStepIndex) => setCurrentStep(MOI_STEPS({})[nextStepIndex])}
      onFirstStepBack={onBackClick}
      cancelButtonsWithoutBorder
      actionBtnText=""
      backBtnText={
        <TextWithIcon
          text="PREVIOUS STEP"
          icon={<ArrowDownwardIcon className="mr-15 rotate-90 fill-current" />}
          position="start"
        />
      }
      nextBtnText={
        <TextWithIcon
          text="NEXT STEP"
          icon={<ArrowDownwardIcon className="ml-15 -rotate-90 fill-current" />}
          position="end"
        />
      }
      finishBtnText="SUBMIT"
      firstStepBackBtnText={
        <TextWithIcon
          text={moiRecord ? 'BACK TO SELECTED MOIS' : 'CANCEL'}
          icon={<ArrowDownwardIcon className="mr-15 rotate-90 fill-current" />}
          position="start"
        />
      }
      firstStepNextBtnText={
        <TextWithIcon
          text="NEXT STEP"
          icon={<ArrowDownwardIcon className="ml-15 -rotate-90 fill-current" />}
          position="end"
        />
      }
    >
      {MOI_STEPS_WITH_CONTEXT.map(({ Content, key, ...stepProps }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Stepper.Step key={key} {...stepProps}>
          <MoiStepWrapper alertMessage={alertMessage}>
            <Content
              exposure={exposure}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              getIsContactVendorApi={getIsContactVendorApi}
              setIsXactContractor={setIsXactContractor}
              setIsVendorApi={setIsVendorApi}
              isVendorApi={isVendorApi}
              setCurrentValidation={setCurrentValidation}
              currentValidation={currentValidation}
              setAlertMessage={setAlertMessage}
              alertMessage={alertMessage}
              moiMethodsReturn={moiMethodsReturn}
            />
          </MoiStepWrapper>
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
        </Stepper.Step>
      ))}
    </Stepper>
  );
};

export default MoiStepper;
