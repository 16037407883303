import React from 'react';
import { Link } from '@mui/material';

import { Text } from '~/components/core';
import { QuestionCircleIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';

export const HowToUseTableauLink: React.FC = () => {
  return (
    <span className="flex items-center gap-10">
      <QuestionCircleIcon iconColor={colors.teal[700]} />
      <Link
        className="text-teal-700"
        href="https://help.tableau.com/current/pro/desktop/en-us/datafields_understanddatawindow.htm"
        target="_blank"
      >
        <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className="text-teal-700">
          How to use Tableau
        </Text>
      </Link>
    </span>
  );
};
