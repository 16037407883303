import React from 'react';
import { useFormikContext } from 'formik';

import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { LEGAL_ACTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';

import { useClaim } from '../../ClaimContainer';
import { FsMenuItem } from '../../core';
import ExposureMultiSelectTextFieldFormik from '../../ExposureMultiSelectTextFieldFormik';
import TextFieldFormik, { MonetaryValueTextFieldFormik } from '../../TextFieldFormik';
import { useLegalActionsConfiguration } from '../hooks/useLegalActionsConfiguration';

export const LegalActionsSummaryForm = () => {
  const { claim } = useClaim();
  const { priorityOptions } = useLegalActionsConfiguration();
  const { values, setFieldValue } = useFormikContext();

  useFormikChangeListener({
    onChange: (newValues) => {
      if (newValues[LEGAL_ACTION_FIELDS.isClaimLevel.key]) {
        setFieldValue(LEGAL_ACTION_FIELDS.exposures.key, []);
      }
    },
    listenForKeys: [LEGAL_ACTION_FIELDS.isClaimLevel.key],
  });

  return (
    <>
      <RadioButtonGroupFormik
        btnClassName="bg-slate-100"
        sizeButtonsEvenly
        id={LEGAL_ACTION_FIELDS.isClaimLevel.key}
        label={LEGAL_ACTION_FIELDS.isClaimLevel.label}
        options={[
          {
            text: 'Claim Level',
            optionValue: true,
          },
          {
            text: 'Exposure Level',
            optionValue: false,
            disabled: claim.exposures.length === 0,
          },
        ]}
      />
      <div className="w-100 bg-slate-100 p-15">
        <ExposureMultiSelectTextFieldFormik
          id={LEGAL_ACTION_FIELDS.exposures.key}
          claim={claim}
          disabled={values[LEGAL_ACTION_FIELDS.isClaimLevel.key]}
          shouldIncludeGeneral={false}
        />
      </div>
      <TextFieldFormik id={LEGAL_ACTION_FIELDS.displayName.key} label={LEGAL_ACTION_FIELDS.displayName.label} />
      <div className="flex gap-30">
        <TextFieldFormik
          id={LEGAL_ACTION_FIELDS.priority.key}
          label={LEGAL_ACTION_FIELDS.priority.label}
          select
          fullWidth
        >
          {priorityOptions.map((option) => (
            <FsMenuItem key={option.key} value={option.key}>
              {option.description}
            </FsMenuItem>
          ))}
        </TextFieldFormik>
        <MonetaryValueTextFieldFormik
          id={LEGAL_ACTION_FIELDS.lawsuitAmount.key}
          label={LEGAL_ACTION_FIELDS.lawsuitAmount.label}
          fullWidth
        />
      </div>
      <TextFieldFormik
        id={LEGAL_ACTION_FIELDS.complaintAllegationsSummary.key}
        label={LEGAL_ACTION_FIELDS.complaintAllegationsSummary.label}
        multiline
        rows={1}
        rowsMax={4}
      />
    </>
  );
};
