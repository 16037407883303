import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { every, set } from 'lodash';
import * as Yup from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import {
  CONTRACT_OF_SERVICE_TAB_KEY,
  FORMIK_COS_FIELD_IDS,
} from '~/components/Contacts/UpsertContact/Tabs/ContractOfService/constants';
import Discount from '~/components/Contacts/UpsertContact/Tabs/ContractOfService/Discount';
import { getFullCOSPath } from '~/components/Contacts/UpsertContact/Tabs/ContractOfService/utils';
import type { Tab } from '~/components/Contacts/UpsertContact/types';
import UploadDocument from '~/components/Contacts/UpsertContact/UploadDocument';
import {
  getDocumentInitialValues,
  getDocumentPayload,
  getDocumentValidationSchema,
} from '~/components/Contacts/UpsertContact/UploadDocument/utils';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import useOrganization from '~/components/OrganizationContext';

import { getInitializedValues } from '../../utils';
import TabWrapper from '../TabWrapper';

const ContractOfServiceTab: React.FC = () => {
  const { isSubmitting } = useFormikContext();
  const { documentUploadPercent } = useContractOfServiceTab();

  const sharedProps = {
    disabled: isSubmitting,
  };

  return (
    <TabWrapper>
      <div className="mb-30 grid grid-cols-2 gap-20">
        <UploadDocument
          {...sharedProps}
          documentFieldKey={FORMIK_COS_FIELD_IDS.CONTRACT_OF_SERVICE_DOCUMENT_ID}
          documentType="contract_of_service_form"
          documentFieldLabel="Contract of service"
          getFullPath={getFullCOSPath}
          documentUploadPercent={documentUploadPercent}
        />
        <Discount {...sharedProps} />
      </div>
    </TabWrapper>
  );
};

const useContractOfServiceTab = (): Tab => {
  // No Formik Context
  const { currentRole, originalContact, isCreation } = useContact();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  const [documentUploadPercent, setDocumentUploadPercent] = useState(0);
  const { claim } = useClaim();

  const getInitialValues = (contact?: ContactFullModel) => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    const documentInitialValues = getDocumentInitialValues(FORMIK_COS_FIELD_IDS.CONTRACT_OF_SERVICE_DOCUMENT_ID, claim);
    every(Object.keys(documentInitialValues), (formikFieldId) =>
      setInitialValue(formikFieldId, documentInitialValues[formikFieldId])
    );

    setInitialValue(
      FORMIK_COS_FIELD_IDS.PERCENTAGE_DISCOUNT,
      contact ? !contact.medical_provider_discount_fixed_amount : ''
    );
    setInitialValue(
      FORMIK_COS_FIELD_IDS.MEDICAL_PERCENTAGE_DISCOUNT,
      contact?.medical_provider_discount_percentage ?? 0
    );
    setInitialValue(FORMIK_COS_FIELD_IDS.MEDICAL_FIXED_DISCOUNT, contact?.medical_provider_discount_fixed_amount ?? 0);

    return values;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    const documentValidationSchema = getDocumentValidationSchema(
      FORMIK_COS_FIELD_IDS.CONTRACT_OF_SERVICE_DOCUMENT_ID,
      true,
      claim
    );
    every(Object.keys(documentValidationSchema), (formikFieldId) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      addValidation(formikFieldId, documentValidationSchema[formikFieldId])
    );

    addValidation(FORMIK_COS_FIELD_IDS.PERCENTAGE_DISCOUNT, Yup.boolean());
    addValidation(FORMIK_COS_FIELD_IDS.MEDICAL_PERCENTAGE_DISCOUNT, Yup.number());
    addValidation(FORMIK_COS_FIELD_IDS.MEDICAL_FIXED_DISCOUNT, Yup.number());

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    return getInitializedValues(values);
  };

  const getPreparedPayload = async (values: FormikValues) => {
    return await getDocumentPayload(values, originalContact, setDocumentUploadPercent);
  };

  const isHiddenForContact = () => {
    // Hidden Tabs cannot have absolute require fields, only conditionally required fields
    return isCreation || !organizationContactRolesDict[currentRole]?.is_contract_of_service_required;
  };

  return {
    label: 'COS',
    tabKey: CONTRACT_OF_SERVICE_TAB_KEY,
    tabComponent: ContractOfServiceTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    isHiddenForContact,
    documentUploadPercent,
    getPreparedPayload,
  };
};

export default useContractOfServiceTab;
