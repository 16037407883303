import React, { useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import CommunicationAttachmentTable from '~/components/communications/CommunicationAttachmentTable';
import { CommunicationReminderContainer } from '~/components/communications/CommunicationCard';
import CommunicationRecipientContainerFormik from '~/components/communications/CommunicationRecipientContainer';
import CommunicationsAiInsight from '~/components/communications/CommunicationsAiInsight/CommunicationsAiInsight';
import EditorFormik from '~/components/communications/EmailCommunicationCard/EmailEditor/EditorFormik';
import ContactsValidEmailWarning from '~/components/communications/EmailCommunicationCard/WarningEmail';
import { getAttachmentFileUrl } from '~/components/communications/utils';
import Button from '~/components/core/Atomic/Buttons/Button';
import FormikDocumentsAttachment from '~/components/Documents/FormikDocumentsAttachment';
import FailedTokensWarningBanner from '~/components/GenericTemplates/FailedTokensWarningBanner';
import GenericTemplateSelectionContainerFormik from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import { useCms } from '~/components/hooks/useCms';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import EmailSpoofingVerificationAlertBanner from './Banners/EmailSpoofingVerificationAlertBanner';
import EmailRecipient from './EmailRecipient';
import { recipientContactValidationScheme } from './schemaValidations';

export const EmailCommunicationSpecificBody = (props) => {
  const { userOrganization } = useCms();
  const classes = useStyles();
  const { values } = useFormikContext();

  // forcedRecipient is given in case of reply to email not attached to a claim
  const {
    communication,
    communicationInitialFields,
    isDocument,
    isNew,
    onUpdate,
    forcedRecipient,
    isNotClaimRelated,
    emailSignature,
  } = props;

  const [editorKey, setEditorKey] = useState('init');
  const [isFromTemplateDialogOpen, setIsFromTemplateDialogOpen] = useState(false);
  const isGenericTemplatesEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.GENERIC_TEMPLATES);
  const [failedTokens, setFailedTokens] = useState(null);

  // The EditorFormik wraps Editor components for content updates. Due to Editors' tight coupling
  // with their initial content, direct updates don't trigger rerenders. By updating the component's key via useEffect,
  // we force a rerender to reflect content changes, ensuring the UI stays updated
  useEffect(() => {
    if (!values.template_id) {
      return;
    }

    setEditorKey(values.template_id);
  }, [values.template_id]);

  const handleOpenFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(true);
  };

  const handleCloseFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(false);
  };

  const isView = !isNew && !isDocument;
  const maxAttachmentsHeight = '200px';

  let documentsAttachmentComponent;
  if (isView) {
    documentsAttachmentComponent = communication.attachments.length > 0 && (
      <CommunicationAttachmentTable
        communication={communication}
        communicationType="emails"
        onUpdate={onUpdate}
        maxHeight={maxAttachmentsHeight}
        getAttachmentFileUrl={(attachment) => getAttachmentFileUrl(attachment, 'emails')}
      />
    );
  } else {
    documentsAttachmentComponent = isNotClaimRelated ? undefined : (
      <FormikDocumentsAttachment id="attached_documents" maxHeight={maxAttachmentsHeight} />
    );
  }

  let recipientAddressComponent;
  if (!isView) {
    if (forcedRecipient) {
      recipientAddressComponent = (
        <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography display="block" variant="subtitle2">
            {forcedRecipient.emailAddress}
          </Typography>
        </div>
      );
    } else {
      // The normal flow

      if (
        communicationInitialFields.communicationContact &&
        communicationInitialFields.communicationContact.emails.length > 0
      ) {
        if (communicationInitialFields.communicationContact.emails.length === 1) {
          recipientAddressComponent = (
            <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Typography display="block" variant="subtitle2">
                {communicationInitialFields.communicationContact.emails[0].email}
              </Typography>
            </div>
          );
        } else {
          recipientAddressComponent = (
            <TextFieldFormik id="email_id" label="Email" className={classes.textField} fullWidth select>
              {communicationInitialFields.communicationContact.emails.map((email) => (
                <MenuItem key={email.id} value={email.id}>
                  {email.email}
                </MenuItem>
              ))}
            </TextFieldFormik>
          );
        }
      }
    }
  }

  const defaultDeliveryErrorMsg =
    "Unknown reason. Please try double-checking the recipient's email address, or try again later";

  const formattedContactName =
    communication?.contact?.full_name && communication?.contact_email?.email
      ? `${communication.contact.full_name} (${communication.contact_email.email})`
      : communication?.specific_identifier;
  const recipientTypes = isView ? ['to', 'cc', 'bcc'] : ['cc', 'bcc'];

  return (
    <>
      {recipientAddressComponent}
      {isView && (
        <List dense>
          {communication.delivery_issue === 'pending' && (
            <ListItem>
              <ListItemText
                primary={formattedContactName && `Email delivery pending for ${formattedContactName}`}
                primaryTypographyProps={{ style: { color: 'orange' } }}
              />
            </ListItem>
          )}
          {communication.delivery_issue === 'error' && (
            <ListItem>
              <ListItemText
                primary={formattedContactName && `Email delivery failed for ${formattedContactName}`}
                primaryTypographyProps={{ color: 'error' }}
                secondary={communication.delivery_issue_reason || defaultDeliveryErrorMsg}
                secondaryTypographyProps={{ color: 'textSecondary', variant: 'caption' }}
              />
            </ListItem>
          )}
          {communication.direction === 'Incoming' && (
            <EmailSpoofingVerificationAlertBanner communication={communication} />
          )}
          {[
            ...(communication?.to_contacts ?? []),
            ...(communication?.cc_contacts ?? []),
            ...(communication?.bcc_contacts ?? []),
          ].map((recipientEntry) => {
            if (recipientEntry.delivery_issue === 'pending') {
              return (
                <ListItem key={recipientEntry.email}>
                  <ListItemText
                    primary={`Email delivery pending for ${recipientEntry.contact_full_name} (${recipientEntry.email})`}
                    primaryTypographyProps={{ style: { color: 'orange' } }}
                  />
                </ListItem>
              );
            } else if (recipientEntry.delivery_issue === 'error') {
              return (
                <ListItem key={recipientEntry.email}>
                  <ListItemText
                    primary={`Email delivery failed for ${recipientEntry.contact_full_name} (${recipientEntry.email})`}
                    primaryTypographyProps={{ color: 'error' }}
                    secondary={recipientEntry.delivery_issue_reason || defaultDeliveryErrorMsg}
                    secondaryTypographyProps={{ color: 'textSecondary', variant: 'caption' }}
                  />
                </ListItem>
              );
            } else {
              return null;
            }
          })}
        </List>
      )}
      {!isNotClaimRelated && (
        <>
          {recipientTypes.map((recipientType) => (
            <div className={classes.inputContainer} key={recipientType}>
              {/* for now we assume isNotClaimRelated is given only when replying to email not attached to claim. Sw don't allow CC.
        In case you want to add support for other useCase of isNotClaimRelated - Reconsider changing this */}
              <CommunicationRecipientContainerFormik
                isView={isView}
                AdditionalComponent={ContactsValidEmailWarning}
                SpecificRecipientDialog={EmailRecipient}
                recipientContactValidationScheme={recipientContactValidationScheme}
                recipientType={recipientType}
                isNotClaimRelated={isNotClaimRelated}
              />
            </div>
          ))}
        </>
      )}
      {isView && <CommunicationsAiInsight communicationId={communication.id} />}
      {isGenericTemplatesEnabled && !isNotClaimRelated && !isView ? (
        <div className="flex items-center justify-end">
          <Button color="primary" onClick={handleOpenFromTemplateDialog}>
            <AddIcon />
            From template
          </Button>
        </div>
      ) : null}
      <FailedTokensWarningBanner className="pb-20" failedTokens={failedTokens} />
      <div className={classes.inputContainer}>
        <TextFieldFormik id="subject" label="Subject:" className={classes.textField} fullWidth showOnly={isView} />
      </div>
      <Divider />
      <div className={classes.inputContainer}>
        {isView && !communication.body_html ? (
          <TextFieldFormik
            id="body_text"
            label="Body:"
            className={classes.textField}
            fullWidth
            multiline
            rows="10"
            showOnly
          />
        ) : (
          <EditorFormik
            id="body_html"
            label="Body:"
            className={classes.textField}
            fullWidth
            maxHeight="45vh"
            showOnly={isView}
            autoFocus
            key={editorKey}
          />
        )}
      </div>
      <Divider />
      <div className={classes.inputContainer}>{documentsAttachmentComponent}</div>
      {isNew && !isNotClaimRelated && <CommunicationReminderContainer />}
      {isFromTemplateDialogOpen ? (
        <GenericTemplateSelectionContainerFormik
          handleClose={handleCloseFromTemplateDialog}
          templateType="email"
          titleFieldId="subject"
          bodyFieldId="body_html"
          signature={emailSignature}
          setFailedTokens={setFailedTokens}
        />
      ) : null}
    </>
  );
};

EmailCommunicationSpecificBody.propTypes = {
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  communicationInitialFields: PropTypes.shape({
    communicationChannel: PropTypes.string.isRequired,
    communicationContact: PropTypes.object,
    communicationDirection: requiredIf(PropTypes.string, (props) => props.isNew),
  }),
  onUpdate: requiredIf(PropTypes.func, (props) => !props.isNew && !props.isDocument),
  forcedRecipient: requiredIf(
    PropTypes.shape({
      optionalContact: PropTypes.object,
      optionalContactEmail: PropTypes.object,
      emailAddress: PropTypes.string,
    }),
    (props) => !!props.isNotClaimRelated
  ),
  isNotClaimRelated: PropTypes.bool,
  emailSignature: PropTypes.string,
};

EmailRecipient.propTypes = {
  recipientEntry: PropTypes.object.isRequired,
  recipientEntryId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
  isNotClaimRelated: PropTypes.bool,
};

export default EmailCommunicationSpecificBody;
