import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { getPolicyStatus, getPolicyStatusDuringLoss } from '../../PolicyUtils';
import { TRAVEL_COVERAGE_DICT } from '../../Types';
import { isPolicyNotFound, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import DisableableToolTip from '../DisableableTooltip';
import { useCms } from '../hooks/useCms';
import LoadingIndicator from '../LoadingIndicator';
import PlainTable from '../PlainTable';
import { EditManuallyFilledPolicyContainer } from '../Policy/PolicyNotFoundContainer';
import { usePolicy } from '../PolicyContainer';
import { ShowOnlyTextField } from '../TextFieldFormik';
import UpdatePolicyEffectiveDateDialog from '../UpdatePolicyEffectiveDateDialog';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

function TravelPolicyPage({ enableOpeningFnol }) {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { policy } = usePolicy();

  return (
    <Grid container>
      <Grid item md={7} xs={12}>
        <div className={classes.cardDivRow}>
          <TravelPolicyDetailsCard
            claim={claim}
            onClaimUpdate={onClaimUpdate}
            policy={policy}
            enableOpeningFnol={enableOpeningFnol}
          />
        </div>
      </Grid>
      <Grid item xs={5} />
      <Grid item xs={7}>
        <div className={classes.cardDivRow}>
          <TravelCoverageTableCard policy={policy} />
        </div>
      </Grid>
    </Grid>
  );
}

TravelPolicyPage.propTypes = {
  enableOpeningFnol: PropTypes.bool,
};

function TravelPolicyDetailsCard(props) {
  const { claim, onClaimUpdate, enableOpeningFnol } = props;
  const [showEditPolicyEffectiveDate, setShowEditPolicyEffectiveDate] = useState(false);
  const classes = useStyles();
  const { policy } = usePolicy();
  const { user } = useCms();

  const getClaimPolicyDocumentOrUndefined = () => {
    return claim?.documents.find((doc) => doc?.document_name === 'Policy Document');
  };
  const document = getClaimPolicyDocumentOrUndefined();

  const readOnly = user.role.is_view_only || !claim;

  const handleUpdateFields = async (updateObject) => {
    if (onClaimUpdate) {
      try {
        const res = await axios.patch(`/api/v1/claims/${claim.id}/policy`, updateObject);
        await onClaimUpdate(res.id);
      } catch (error) {
        reportAxiosError(error);
      }
    }
  };

  if (isPolicyNotFound(claim)) {
    return (
      <CardDialog className={classes.CardDialog} title="Policy Details">
        <Typography display="block" color="secondary">
          Policy Not Found
        </Typography>
      </CardDialog>
    );
  }

  const policyStatusDuringLoss = getPolicyStatusDuringLoss(
    policy,
    claim?.incident?.date_of_loss,
    claim?.incident?.time_of_loss
  );
  const policyStatus = getPolicyStatus(policyStatusDuringLoss);

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title="Policy Details"
        action={
          !enableOpeningFnol ? (
            <EditManuallyFilledPolicyContainer />
          ) : (
            <Button
              style={{ margin: '10px' }}
              variant="contained"
              color="primary"
              href={`/fnol?policyId=${policy?.id}`}
            >
              REPORT NEW LOSS
            </Button>
          )
        }
        subheader={
          <span style={{ color: policyStatus === 'In Force' ? 'green' : 'red' }}>
            <strong>{policyStatus}</strong>
          </span>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={4} container>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Policy Number"
                classes={classes}
                showOnlyValueComponent={policy.policy_number}
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Effective Date"
                classes={classes}
                showOnlyValueComponent={
                  isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
                }
                fullWidth
                onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Destinations"
                classes={classes}
                showOnlyValueComponent={policy?.policy_extra?.custom_extra?.destinations || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Medical Bundle"
                classes={classes}
                showOnlyValueComponent={policy?.policy_extra?.custom_extra?.medical_bundle || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Trip Value"
                classes={classes}
                showOnlyValueComponent={policy?.policy_extra?.custom_extra?.trip_value || ''}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={12}>
              <ContactShowOnlyTextField
                contactId={policy.insured_contact_id}
                contactDisplayName={policy.insured_contact.full_name}
                label="Named Insured"
                fullWidth
                showOnly
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Expiration Date"
                classes={classes}
                showOnlyValueComponent={
                  isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
                }
                fullWidth
                onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Plan Name"
                classes={classes}
                showOnlyValueComponent={policy?.policy_extra?.custom_extra?.plan_name || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Medical Type"
                classes={classes}
                showOnlyValueComponent={policy?.policy_extra?.custom_extra?.medical_type || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOnlyTextField
                label="Linked Policies"
                classes={classes}
                showOnlyValueComponent={policy?.policy_extra?.custom_extra?.linked_policies || ''}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            {!claim && (
              <Grid item xs={12}>
                <ShowOnlyTextField
                  label="Linked Claims"
                  classes={classes}
                  showOnlyValueComponent={
                    <div>
                      <DisableableToolTip
                        disabled={policy.linked_claims.length < 3}
                        title={policy.linked_claims.map((claim) => claim.claim_id_display).join(', ')}
                      >
                        {policy.linked_claims.slice(0, 1).map((claimSummary) => (
                          <div key={claimSummary.claim_id}>
                            <ClaimLink claimId={claimSummary.claim_id} linkText={claimSummary.claim_id_display} />
                          </div>
                        ))}
                      </DisableableToolTip>
                    </div>
                  }
                  fullWidth
                />
              </Grid>
            )}
            {claim && document ? (
              <Grid item xs={12}>
                <ShowOnlyTextField
                  label="Policy Document"
                  classes={classes}
                  showOnlyValueComponent={
                    <Link
                      href={`/api/v1/claims/${claim.id}/documents/${document.id}`}
                      style={{ margin: 0, textDecoration: 'underline' }}
                      target="_blank"
                    >
                      View Policy
                    </Link>
                  }
                  fullWidth
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </CardDialog>
      <UpdatePolicyEffectiveDateDialog
        onClose={() => setShowEditPolicyEffectiveDate(false)}
        policy={policy}
        open={showEditPolicyEffectiveDate}
        onSubmit={async (values) => {
          await handleUpdateFields(values);
          setShowEditPolicyEffectiveDate(false);
        }}
      />
    </>
  );
}

TravelPolicyDetailsCard.propTypes = {
  claim: PropTypes.object,
  onClaimUpdate: PropTypes.func,
  enableOpeningFnol: PropTypes.bool,
};

function TravelCoverageTableCard({ policy }) {
  const {
    isLoading,
    isError,
    data: amountSpentByCoverageType,
  } = useDataFetcher(`/api/v1/travel_claims/policy/${policy.policy_number}/amounts`);

  const { currencyFormatter } = useCurrencyFormatter();
  const travelCoverageColumns = [
    { id: 'coverage', label: 'Coverage', specialCell: ({ coverage_type }) => TRAVEL_COVERAGE_DICT[coverage_type].desc },
    {
      id: 'is_covered',
      label: 'Is Covered',
      specialCell: ({ coverage_type }) =>
        TRAVEL_COVERAGE_DICT[coverage_type].boolean_option ? (policy[coverage_type] ? 'Yes' : 'No') : undefined,
    },
    {
      id: 'amount_spent',
      label: 'Amount Spent',
      specialCell: ({ coverage_type }) => {
        const paidAmount =
          amountSpentByCoverageType?.[coverage_type] && amountSpentByCoverageType[coverage_type].paid_sum !== null
            ? amountSpentByCoverageType[coverage_type].paid_sum
            : undefined;
        return getAmountPaidComponent(paidAmount, getLimitPerPolicy(policy, coverage_type), currencyFormatter);
      },
    },
    {
      id: 'limit',
      label: 'Limit per Policy',
      specialCell: ({ coverage_type }) => {
        const limit = getLimitPerPolicy(policy, coverage_type);
        return limit ? currencyFormatter.format(limit) : undefined;
      },
    },
  ];

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <CardDialog title="Coverage Details">
      <PlainTable
        columns={travelCoverageColumns}
        rows={Object.keys(TRAVEL_COVERAGE_DICT).map((coverage_type, idx) => {
          return { id: idx, coverage_type };
        })}
      />
    </CardDialog>
  );
}

TravelCoverageTableCard.propTypes = {
  policy: PropTypes.object.isRequired,
};

const getAmountPaidComponent = (paidAmount, policyLimit, currencyFormatter) => {
  let color;
  if (paidAmount && policyLimit && policyLimit * 0.9 < paidAmount) {
    color = 'red';
  }
  const formattedAmount = paidAmount ? currencyFormatter.format(paidAmount) : '';
  return <span style={{ color }}>{formattedAmount}</span>;
};

const getLimitPerPolicy = (policy, coverageType) =>
  TRAVEL_COVERAGE_DICT[coverageType].is_limit_per_policy && policy[`${coverageType}_per_policy_limit`] !== null
    ? policy[`${coverageType}_per_policy_limit`]
    : undefined;

export { TravelPolicyPage };
