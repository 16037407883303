import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import { useClaim } from '../../../ClaimContainer';
import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';
import LoadingIndicator from '../../../LoadingIndicator';
import PlainTable from '../../../PlainTable';
import useDataFetcher from '../../../useDataFetcher';

// NOTE: copied and generalized from frontend/src/components/Pet/PetManagementFinancial.jsx

function CoveredEntityAccumulatedFinancialContainer({ coveredEntity }) {
  const { claim } = useClaim();
  const entityId = coveredEntity.entity_id;
  const {
    isLoading,
    isError,
    data: accumulatedFinanceData,
    reloadData,
  } = useDataFetcher(`/api/v1/general_claims/${claim.id}/claim_accumulated_finance`, {
    params: { entity_id: entityId },
  });
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // If anything changed in claim, reload
    reloadData();
  }, [reloadData, claim]);

  return (
    <CollapsibleWrapper noBackground actionCard title="Accumulated Payments">
      <Typography display="block" variant="body1">
        Accumulating amount for related entity and policy (including this claim)
      </Typography>
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} size={20} />
      ) : (
        <CoveredEntityAccumulatedFinancialTable
          coveredEntity={coveredEntity}
          accumulatedFinanceData={accumulatedFinanceData}
        />
      )}
    </CollapsibleWrapper>
  );
}

CoveredEntityAccumulatedFinancialContainer.propTypes = {
  coveredEntity: PropTypes.object.isRequired,
};

function CoveredEntityAccumulatedFinancialTable({ coveredEntity, accumulatedFinanceData }) {
  const { currencyFormatter } = useCurrencyFormatter();

  const accumulatedDeductible = accumulatedFinanceData.accumulated_deductible;
  const accumulatedPaidInPolicy = accumulatedFinanceData.accumulated_paid_for_policy_and_covered_entity;
  const accumulatedPaidForCoveredEntity = accumulatedFinanceData.accumulated_paid_for_covered_entity;

  const columnData = [
    { id: 'type', numeric: false, label: '' },
    { id: 'accumulated', numeric: false, label: 'Accumulated' },
    { id: 'policy_limit', numeric: false, label: 'Policy Limit' },
    { id: 'remaining', numeric: false, label: 'Remaining amount' },
  ];

  const deductible = {
    type: 'Deductible',
    accumulated: currencyFormatter.format(accumulatedDeductible),
    policy_limit: currencyFormatter.format(coveredEntity.deductible_per_policy_per_covered_entity),
    remaining: currencyFormatter.format(coveredEntity.deductible_per_policy_per_covered_entity - accumulatedDeductible),
  };
  const policy_paid = {
    type: 'Paid Within Policy',
    accumulated: currencyFormatter.format(accumulatedPaidInPolicy),
    policy_limit: currencyFormatter.format(coveredEntity.limit_per_policy_per_covered_entity),
    remaining: currencyFormatter.format(coveredEntity.limit_per_policy_per_covered_entity - accumulatedPaidInPolicy),
  };
  const lifetime_paid = {
    type: 'Paid Within Lifetime',
    accumulated:
      accumulatedPaidForCoveredEntity !== null ? currencyFormatter.format(accumulatedPaidForCoveredEntity) : 'N\\A',
    policy_limit: currencyFormatter.format(coveredEntity.limit_per_covered_entity),
    remaining:
      accumulatedPaidForCoveredEntity !== null
        ? currencyFormatter.format(coveredEntity.limit_per_covered_entity - accumulatedPaidForCoveredEntity)
        : 'N\\A',
  };
  let rows = [deductible, policy_paid, lifetime_paid];
  rows = rows.map((row, idx) => ({ ...row, id: idx }));

  return <PlainTable columns={columnData} rows={rows} keepRowsOrder />;
}

CoveredEntityAccumulatedFinancialTable.propTypes = {
  coveredEntity: PropTypes.object.isRequired,
  accumulatedFinanceData: PropTypes.object.isRequired,
};

export default CoveredEntityAccumulatedFinancialContainer;
