import React from 'react';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import CardDialog from '~/components/CardDialog';
import { WithConfirmFormik } from '~/components/ConfirmModal';
import type { ContactFullModel } from '~/components/Contacts/types';
import UpsertContactFormik from '~/components/Contacts/UpsertContact/UpsertContactFormik';
import { useContactTabs } from '~/components/Contacts/UpsertContact/useContactTabs';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerDialogTabs from '~/components/core/Layout/InnerTabs/InnerDialogTabs';

import { ContactContextProvider, useContact } from '../useContact';

interface EditContactDialogInnerProps {
  onClose: () => void;
  title?: string;
  buttonsOverride?: (prop: {
    isSubmitting: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  }) => React.ReactElement;
}

interface EditContactProps extends EditContactDialogInnerProps {
  contact: ContactFullModel;
  onContactUpdate: () => Promise<void>;
  specialValidationRequirements: Record<string, unknown>;
}

const EditContactDialogInner: React.FC<EditContactDialogInnerProps> = ({
  onClose,
  title = 'Edit Contact',
  buttonsOverride,
}) => {
  const { originalContact } = useContact();
  const { isSubmitting, handleSubmit, errors, touched } = useFormikContext();
  const { filteredTabs } = useContactTabs();

  const tabHasErrors = (tabKey: string) =>
    !isEmpty(getIn(errors, tabKey)) &&
    getIn(touched, tabKey) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(errors[tabKey]).some((i) => Object.keys(touched[tabKey]).includes(i));

  const tabs = filteredTabs.map((tab) => ({
    hasErrors: tabHasErrors(tab.tabKey),
    label: tab.label,
    component: tab.tabComponent,
  }));

  const saveButton = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
      Save
    </Button>
  );

  return (
    <CardDialog isDialog title={title} onClose={onClose} maxWidth="md" fullWidth>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <InnerDialogTabs tabs={tabs} />
      <div className="mt-px flex w-full justify-end">
        {buttonsOverride ? (
          buttonsOverride({ isSubmitting, handleSubmit })
        ) : (
          <>
            <CancelButton disabled={isSubmitting} onClick={onClose} />
            {originalContact ? (
              <WithConfirmFormik
                title="Are you sure?"
                contentText={`Are you sure you want to change the contact details for ${originalContact.full_name}? This change will take effect everywhere this contact is referenced, across all claims this contact belongs to`}
                primaryButtonName="Save"
                shouldCloseOnPrimary={true}
              >
                {saveButton}
              </WithConfirmFormik>
            ) : (
              { saveButton }
            )}
          </>
        )}
      </div>
    </CardDialog>
  );
};

const EditContactFormik: React.FC<EditContactProps> = ({
  contact: existingContact,
  onClose,
  onContactUpdate,
  title,
  buttonsOverride,
  specialValidationRequirements = {},
}) => {
  const handleSubmit = async (payload: Record<string, unknown>) => {
    await axios.patch(`/api/v1/contacts/${existingContact.id}/v2`, payload);
    await onContactUpdate();
  };

  return (
    <UpsertContactFormik
      contact={existingContact}
      onClose={onClose}
      onSubmit={handleSubmit}
      specialValidationRequirements={specialValidationRequirements}
      dialogInnerComponent={
        <EditContactDialogInner onClose={onClose} title={title} buttonsOverride={buttonsOverride} />
      }
    />
  );
};

const EditContactDialog: React.FC<EditContactProps> = ({ contact, ...restProps }) => {
  return (
    <ContactContextProvider contact={contact}>
      <EditContactFormik contact={contact} {...restProps} />
    </ContactContextProvider>
  );
};

export { EditContactDialog };
