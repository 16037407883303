import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { getExposuresLabels } from '../Utils';

import { useClaim } from './ClaimContainer';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';

import { useStyles } from '../assets/styles';

function ExposureLabelChips(props) {
  const { claimObject, disablePadding } = props;
  const classes = useStyles();
  const { claim } = useClaim();

  if (!claim) {
    throw Error('claimObject should be within claim context');
  }

  const exposureLabels = getExposuresLabels(claim);
  const claimObjectExposuresLabels = exposureLabels.filter((exposure) =>
    claimObject.exposure_ids.includes(exposure.id)
  );
  const chipClassName = !disablePadding ? classes.chip : undefined;

  if (claimObjectExposuresLabels.length === 0) {
    return null;
  } else if (claimObjectExposuresLabels.length === 1) {
    return (
      <Chip
        size="small"
        label={
          <OverflowTextWithToolTip maxWidth="220px">{claimObjectExposuresLabels[0].label}</OverflowTextWithToolTip>
        }
        className={chipClassName}
      />
    );
  } else {
    const toolTipText = claimObjectExposuresLabels.map((exposureLabel) => exposureLabel.label).join(', ');
    return (
      <Tooltip title={toolTipText}>
        <Chip size="small" label="Multiple Exposures" className={chipClassName} />
      </Tooltip>
    );
  }
}

ExposureLabelChips.propTypes = {
  claimObject: PropTypes.object.isRequired,
  disablePadding: PropTypes.bool,
};

export default ExposureLabelChips;
