import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { isMgmClaim } from '../../../../Utils';
import TextFieldFormik from '../../../TextFieldFormik';

import { useStyles } from '../../../../assets/styles';

const IndemnityPaymentTypeFieldFormik = ({ claim, exposure, readOnly }) => {
  const classes = useStyles();

  const { values } = useFormikContext();

  const { indemnityPaymentTypes, shouldUseIndemnityLabels } = usePaymentsConfiguration(values.payment_method);

  const getTypes = () => {
    const hasLabels = isMgmClaim(claim)
      ? Boolean(indemnityPaymentTypes[claim.type][exposure.coverage_type])
      : Boolean(indemnityPaymentTypes && Object.keys(indemnityPaymentTypes).length);

    if (!hasLabels || (!shouldUseIndemnityLabels && !isMgmClaim(claim))) {
      return null;
    }

    return isMgmClaim(claim)
      ? Object.entries(indemnityPaymentTypes[claim.type][exposure.coverage_type])
      : Object.entries(indemnityPaymentTypes).filter(([, type]) => !type.is_removed);
  };

  const types = getTypes();

  return (
    <div style={{ marginBottom: '16px' }}>
      {types && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldFormik
              id="indemnity_payment_type"
              label="Payment Type"
              className={classes.textField}
              fullWidth
              select
              disabled={readOnly}
            >
              {types.map(([typeKey, type]) => (
                <MenuItem key={typeKey} value={typeKey}>
                  {type?.desc}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

IndemnityPaymentTypeFieldFormik.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default IndemnityPaymentTypeFieldFormik;
