import React from 'react';
import { Formik } from 'formik';
import noop from 'lodash/noop';

import type { VideoCallCommunication } from '~/components/communications/types';
import NewVideoCallStartCallCommunicationsActions from '~/components/communications/VideoCommunication/NewVideoCallStartCallCommunicationsActions';
import PostVideoCommunicationCard from '~/components/communications/VideoCommunication/PostVideoCommunicationCard';
import PreVideoCommunicationCard from '~/components/communications/VideoCommunication/PreVideoCommunicationCard';
import VideoCallProviderSpecificDialog from '~/components/communications/VideoCommunication/VideoCallProviderSpecificDialog';

import type { ContactFullModel } from '../../Contacts/types';
import CommunicationCard, { useCreateCommunication } from '../CommunicationCard';

import VideoCallSpecificBody from './VideoCallSpecificBody';

interface Props {
  contact: ContactFullModel;
  onCancel: () => void;
  onNewVideoCallCreated: () => void;
}

const NewVideoCommunicationCard: React.FC<Props> = ({ contact, onCancel, onNewVideoCallCreated }) => {
  const [newVideoCallData, setNewVideoCallData] = React.useState<VideoCallCommunication | null>(null);
  const [videoDialogOpen, setVideoDialogOpen] = React.useState<boolean>(false);
  const [postVideoDialogOpen, setPostVideoDialogOpen] = React.useState<boolean>(false);
  const { communicationCommonInitialValues } = useCreateCommunication('video_call', { contact });
  const cardTitle = 'Video Call';
  if (newVideoCallData) {
    const provider = newVideoCallData.provider;

    if (postVideoDialogOpen) {
      return (
        <PostVideoCommunicationCard
          cardTitle={cardTitle}
          communication={newVideoCallData}
          onClose={() => {
            setPostVideoDialogOpen(false);
            onNewVideoCallCreated();
            onCancel();
          }}
        />
      );
    }
    return (
      <>
        <Formik
          initialValues={{
            ...communicationCommonInitialValues,
            summary: newVideoCallData.summary,
            exposure_ids: newVideoCallData.exposure_ids,
            provider,
          }}
          onSubmit={noop}
        >
          <CommunicationCard
            title={cardTitle}
            communication={newVideoCallData}
            communicationTypeSpecificBodyProps={{ showActionButtons: false }}
            CommunicationTypeSpecificBody={VideoCallSpecificBody}
            communicationHeaderAdditionalProps={{
              dynamicContactLabel: 'Call To',
            }}
            isDialog
            isNew={false}
            maxWidth="sm"
            onClose={onCancel}
            CommunicationAction={NewVideoCallStartCallCommunicationsActions}
            communicationActionProps={{
              onStartVideoCall: () => {
                setVideoDialogOpen(true);
              },
              onCancel,
            }}
          />
        </Formik>
        {videoDialogOpen ? (
          <VideoCallProviderSpecificDialog
            provider={provider}
            communication={newVideoCallData}
            onClose={() => {
              setVideoDialogOpen(false);
              setPostVideoDialogOpen(true);
            }}
          />
        ) : null}
      </>
    );
  }

  return (
    <PreVideoCommunicationCard
      setNewVideoCallData={setNewVideoCallData}
      contact={contact}
      onCancel={onCancel}
      cardTitle={cardTitle}
    />
  );
};

export default NewVideoCommunicationCard;
