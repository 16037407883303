import React from 'react';

import AlertBanner from '~/components/core/AlertBanner';
import cn from '~/Utils/cn';

const FailedTokensWarningBanner: React.FC<{ className?: string; failedTokens?: string[] }> = ({
  failedTokens,
  className,
}) => {
  return Array.isArray(failedTokens) && failedTokens.length > 0 ? (
    <div className={cn('mt-20', className)}>
      <AlertBanner
        title="Warning: Template Generation Incomplete"
        note={`Some tokens failed to generate correctly. Please review the following tokens: ${failedTokens.join(
          ', '
        )}`}
        alertType={AlertBanner.ALERT_TYPES.WARNING}
      />
    </div>
  ) : null;
};

export default FailedTokensWarningBanner;
