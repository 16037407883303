import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import CommunicationLink from '~/components/communications/CommunicationLink';
import type { DocumentMetadata } from '~/components/communications/types';
import Text from '~/components/core/TextComponents/Text';
import DocumentLink from '~/components/Documents/DocumentLink';
import PhotosLink from '~/components/Gallery/PhotosLink';
import { getInternalCommunicationObjectDescription } from '~/components/InternalCommunication';
import type { NotePartProps } from '~/components/Notes/NoteMiniCard';
import useOrganization from '~/components/OrganizationContext';
import { COMMUNICATION_CHANNEL_DICT } from '~/Types';

const NoteContent: React.FC<NotePartProps> = ({ note }) => {
  switch (note.type) {
    case 'communication_note':
      return <CommunicationNote note={note} />;
    case 'document_note':
      return <DocumentNote note={note} />;
    case 'multiple_documents_note':
      return <DocumentsNote note={note} />;
    case 'photos_note':
      return <PhotosNote note={note} />;
    case 'internal_communication_note':
      return <InternalCommunicationNote note={note} />;
    default:
      return <GeneralNote note={note} />;
  }
};

const CommunicationNote: React.FC<NotePartProps> = ({ note }) => {
  const { onClaimUpdate } = useClaim();
  if (!note.communication) {
    return <></>;
  }

  return (
    <>
      {note.is_detached && (
        <Text
          className="mb-10"
          colorVariant={Text.COLOR_VARIANTS.ERROR}
          weight={Text.WEIGHTS.REGULAR}
          variant={Text.VARIANTS.XS}
        >
          Communication was removed from claim
        </Text>
      )}
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
        Summary: {note.communication.summary}
      </Text>
      {['email', 'sms'].includes(note.communication.channel) && note.communication.is_delivery_issue_error_exists && (
        <Text colorVariant={Text.COLOR_VARIANTS.ERROR} weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
          {COMMUNICATION_CHANNEL_DICT[note.communication.channel]} delivery failed
        </Text>
      )}
      <div className="my-10">
        <CommunicationLink
          text="View communication"
          communication={note.communication}
          onUpdate={onClaimUpdate}
          displayLinkAsButton
        />
      </div>
    </>
  );
};

const DocumentNote: React.FC<NotePartProps> = ({ note }) => {
  if (!note.document) {
    return <></>;
  }

  return <DocumentNoteInner document={note.document} />;
};

interface DocumentNoteInnerProps {
  document: DocumentMetadata;
}

interface DocumentType {
  desc: string;
  is_public_option: boolean;
}

const DocumentNoteInner: React.FC<DocumentNoteInnerProps> = ({ document }) => {
  const { documentTypesDict } = useOrganization() as { documentTypesDict: { [document_type: string]: DocumentType } };

  if (!document) {
    return <></>;
  }

  return (
    <>
      {document.is_removed && (
        <Text
          className="mb-10"
          colorVariant={Text.COLOR_VARIANTS.ERROR}
          weight={Text.WEIGHTS.REGULAR}
          variant={Text.VARIANTS.XS}
        >
          Document was removed from claim, Reason: {document.removed_reason}
        </Text>
      )}
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
        Name: {document.document_name}
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
        Type: {documentTypesDict?.[document.type]?.desc || document.type}
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
        Summary: ${document.summary}
      </Text>
      <div className="my-10">
        <DocumentLink text={`View document #${document.claim_internal_id}`} document={document} displayLinkAsButton />
      </div>
    </>
  );
};

const DocumentsNote: React.FC<NotePartProps> = ({ note }) => {
  if (!note.documents) {
    return <></>;
  }
  return (
    <>
      {note.documents.map((document, i) => (
        <DocumentNoteInner document={document} key={i} />
      ))}
    </>
  );
};

const PhotosNote: React.FC<NotePartProps> = ({ note }) => {
  const { claim } = useClaim() as { claim: { documents: DocumentMetadata[] } };

  return (
    <PhotosLink
      photos={claim.documents.filter((doc) => note.document_ids?.includes(doc.id))}
      text="View media files"
      galleryDialogTitle="Media Uploaded"
      displayLinkAsButton
    />
  );
};

const InternalCommunicationNote: React.FC<NotePartProps> = ({ note }) => {
  const { claim, onClaimUpdate } = useClaim() as {
    claim: { documents: DocumentMetadata[] };
    onClaimUpdate: () => void;
  };
  const internalCommunication = note.internal_communication;
  const objectDescription = getInternalCommunicationObjectDescription(
    internalCommunication,
    claim,
    onClaimUpdate,
    true
  );
  return (
    <>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
        Title: {internalCommunication.title}
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
        Details:
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS} className="whitespace-pre-wrap">
        {internalCommunication.details}
      </Text>
      {objectDescription && (
        <Text className="my-10" weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
          {objectDescription}
        </Text>
      )}
    </>
  );
};

const GeneralNote: React.FC<NotePartProps> = ({ note }) => {
  return (
    <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
      {note.note_text}
    </Text>
  );
};

export default NoteContent;
