import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import Notes from '~/components/Notes/Notes';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import ExposuresLabelFilter from './exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from './exposures/ExposureUtils';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { NoteMiniCard } from './Note';
import useDataFetcher from './useDataFetcher';

const NotesSummary = ({ claim, onNoteClick, noCard, hideTypeFilter, isActive = true, exposureOptionsIds }) => {
  const { userOrganization } = useCms();

  if (isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTES_2_0)) {
    return (
      <Notes
        claim={claim}
        onNoteClick={onNoteClick}
        noCard={noCard}
        hideTypeFilter={hideTypeFilter}
        isActive={isActive}
        exposureOptionsIds={exposureOptionsIds}
      />
    );
  }

  return (
    <OldNotesSummary
      claim={claim}
      onNoteClick={onNoteClick}
      noCard={noCard}
      hideTypeFilter={hideTypeFilter}
      isActive={isActive}
      exposureOptionsIds={exposureOptionsIds}
    />
  );
};

NotesSummary.propTypes = {
  claim: PropTypes.object.isRequired,
  onNoteClick: PropTypes.func.isRequired,
  hideTypeFilter: PropTypes.bool,
  noCard: PropTypes.bool,
  isActive: PropTypes.bool,
  exposureOptionsIds: PropTypes.arrayOf(PropTypes.number),
};

function OldNotesSummary(props) {
  const { claim, onNoteClick, noCard, hideTypeFilter, isActive = true, exposureOptionsIds } = props;
  const { user } = useCms();
  const [filteredExposuresIds, setFilteredExposuresIds] = React.useState(
    () => exposureOptionsIds ?? getUserRelatedExposuresId(user, claim)
  );
  const [filteredNoteType, setFilteredNoteType] = React.useState('all');
  const { isLoading, isError, data: notes = [], reloadData } = useDataFetcher(`/api/v1/claims/${claim.id}/notes`);
  const displayLoading = isLoading || isError;

  const isFirstRun = React.useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    reloadData();
  }, [claim, reloadData]);

  const classes = useStyles();

  if (!isActive) {
    return null;
  }

  let filteredNotes = notes;

  if (filteredNoteType !== 'all') {
    filteredNotes = filteredNotes.filter((note) => {
      if (filteredNoteType === 'automatic') {
        return note.is_automatic;
      } else {
        return !note.is_automatic;
      }
    });
  }

  if (filteredExposuresIds.length !== 0) {
    filteredNotes = filteredNotes.filter((note) => _.intersection(filteredExposuresIds, note.exposure_ids).length > 0);
  }

  return (
    <CardDialog title="Notes" passThrough={noCard}>
      <>
        <Grid container direction="row" justify="space-between">
          <Grid item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <ExposuresLabelFilter
              exposureOptionsIds={exposureOptionsIds}
              filterList={filteredExposuresIds}
              onUpdateFiltered={setFilteredExposuresIds}
            />
          </Grid>
          {!hideTypeFilter ? (
            <Grid item>
              <TextField
                id="type"
                label="Note Type"
                className={classes.textFieldRow}
                value={filteredNoteType}
                onChange={(e) => setFilteredNoteType(e.target.value)}
                select
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="automatic">Automatic</MenuItem>
                <MenuItem value="manual">Manual</MenuItem>
              </TextField>
            </Grid>
          ) : null}
        </Grid>
        {displayLoading && <SkeletonTable rowsCount={10} columnsCount={1} cellClassName="h-[120px]" />}
        {!displayLoading &&
          filteredNotes
            .sort((note1, note2) => (note1.id < note2.id ? -1 : 1))
            .reverse()
            .map((note) => (
              <div className={classes.cardDivRow} key={note.id} onClick={() => onNoteClick(note.id)}>
                <NoteMiniCard note={note} onNoteUpdated={reloadData} />
              </div>
            ))}
      </>
    </CardDialog>
  );
}

OldNotesSummary.propTypes = {
  claim: PropTypes.object.isRequired,
  onNoteClick: PropTypes.func.isRequired,
  hideTypeFilter: PropTypes.bool,
  noCard: PropTypes.bool,
  isActive: PropTypes.bool,
  exposureOptionsIds: PropTypes.arrayOf(PropTypes.number),
};

export default NotesSummary;
