import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import cn from '~/Utils/cn';

import colors from '../../../../assets/colors.module.scss';

const VARIANTS = {
  XL: 'xl',
  LG: 'large',
  SM: 'small',
  XS: 'extra-small',
  XXS: 'extra-extra-small',
};

const WEIGHTS = {
  REGULAR: 'regular',
  MEDIUM: 'medium',
  SEMI_BOLD: 'semi-bold',
};

const COLOR_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LINK: 'link',
  BUTTON_LINK: 'button-link',
  DISABLED: 'disabled',
  BLACK: 'black',
  NOTICE: 'notice',
  WHITE: 'white',
  ERROR: 'error',
};

const WEIGHT_TO_NUM = {
  [WEIGHTS.REGULAR]: 400,
  [WEIGHTS.MEDIUM]: 500,
  [WEIGHTS.SEMI_BOLD]: 600,
};

const VARIANT_TO_SIZE = {
  [VARIANTS.XL]: 18,
  [VARIANTS.LG]: 16,
  [VARIANTS.SM]: 14,
  [VARIANTS.XS]: 12,
  [VARIANTS.XXS]: 10,
};

const COLOR_VARIANT_TO_COLOR = {
  [COLOR_VARIANTS.PRIMARY]: colors.textPrimary,
  [COLOR_VARIANTS.SECONDARY]: colors.textSecondary,
  [COLOR_VARIANTS.LINK]: colors.textLink,
  [COLOR_VARIANTS.DISABLED]: colors.textDisabled,
  [COLOR_VARIANTS.BUTTON_LINK]: colors.buttonLink,
  [COLOR_VARIANTS.BLACK]: 'black',
  [COLOR_VARIANTS.NOTICE]: colors.statusPending,
  [COLOR_VARIANTS.WHITE]: colors.white,
  [COLOR_VARIANTS.ERROR]: colors.error,
};

const useStyles = makeStyles(() => ({
  root: ({ variant, weight, colorVariant }) => ({
    fontWeight: WEIGHT_TO_NUM[weight],
    fontSize: VARIANT_TO_SIZE[variant],
    color: COLOR_VARIANT_TO_COLOR[colorVariant],
  }),
}));

const Text = ({
  className,
  variant = VARIANTS.LG,
  weight = WEIGHTS.MEDIUM,
  colorVariant = COLOR_VARIANTS.PRIMARY,
  children,
}) => {
  const displayClasses = useStyles({ variant, weight, colorVariant });
  return <Typography className={cn(displayClasses.root, className)}>{children}</Typography>;
};

Text.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  weight: PropTypes.oneOf(Object.values(WEIGHTS)),
  colorVariant: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
  children: PropTypes.node.isRequired,
};

Text.VARIANTS = VARIANTS;
Text.WEIGHTS = WEIGHTS;
Text.COLOR_VARIANTS = COLOR_VARIANTS;

export default Text;
