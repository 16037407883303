import React from 'react';
import PropTypes from 'prop-types';

import { shouldCheckForCommunicationPermissions } from '~/components/ContactUtils';
import Button from '~/components/core/Atomic/Buttons/Button';

import { FNOL_MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import mixpanel from '../CmsMain/mixpanel';
import { contactValidate, EditContactCardDialog } from '../Contact';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import useDataFetcher from '../useDataFetcher';

function InsuredInfoVerificationCard(props) {
  const { insuredContactId, onDetailsVerified, onClose } = props;
  const {
    isLoading,
    isError,
    data: insuredContact,
    reloadData,
  } = useDataFetcher(`/api/v1/contacts/${insuredContactId}`);

  const { userOrganization } = useCms();

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} track="Verify Insured Info" />;
  }

  const buttonsComponentOverride = ({ isSubmitting, handleSubmit }) => (
    <Button
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      onClick={() => {
        handleSubmit();
        mixpanel.track(FNOL_MIXPANEL_EVENTS.START_CREATING_FNOL_CLICKED_AFTER_INSURED_DETAILS);
      }}
    >
      verify
    </Button>
  );
  const requireCommunicationPrivileges = shouldCheckForCommunicationPermissions(
    userOrganization,
    insuredContact.is_company,
    insuredContact.role
  );

  return (
    <EditContactCardDialog
      contact={insuredContact}
      onCancel={onClose}
      onContactUpdate={reloadData}
      isDialog
      createContactTitle="Verify Policyholder Details"
      specialValidationRequirements={{
        requireCommunicationPrivileges,
      }}
      formikOverrides={{
        validateOverride: (values) => contactValidate(values, false, requireCommunicationPrivileges, userOrganization),
        onSubmitOverride: () => {
          onDetailsVerified();
          onClose();
        },
        buttonsComponentOverride,
      }}
    />
  );
}

InsuredInfoVerificationCard.propTypes = {
  insuredContactId: PropTypes.number.isRequired,
  onDetailsVerified: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InsuredInfoVerificationCard;
