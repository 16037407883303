import React from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { Tooltip } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

import { reportAxiosError } from '../Utils';

import { ViewClaimNotificationCard } from './ClaimNotificationsCard/ViewClaimNotificationCard';
import LoadingIndicator from './LoadingIndicator';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

// copied from https://github.com/fullcalendar/fullcalendar/blob/master/packages/common/src/common/StandardEvent.tsx
// idea came from https://stackoverflow.com/questions/57194259/react-fullcalendar-v4-tooltip
// someday this might be exported https://github.com/fullcalendar/fullcalendar/issues/5927
function renderInnerContent(innerProps) {
  return (
    <div className="fc-event-main-frame">
      {innerProps.timeText && <div className="fc-event-time">{innerProps.timeText}</div>}
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{innerProps.event.title || <>&nbsp;</>}</div>
      </div>
    </div>
  );
}

function AdjusterCalendar() {
  const classes = useStyles();
  const calendarRef = React.useRef();
  const [eventsPendingUpdate, setEventsPendingUpdate] = React.useState({});
  const [claimNotificationSelected, setClaimNotificationSelected] = React.useState();

  const {
    isLoading,
    isError,
    data: claimNotificationsAndExposures,
    reloadData: reloadClaimNotifications,
  } = useDataFetcher('/api/v1/claims/claims_notifications', { params: { show_future_reminders: true } });
  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  async function handleChangeDate(eventDropInfo) {
    const claimNotification = eventDropInfo.event.extendedProps.claimNotification;
    const start = eventDropInfo.event.start;

    setEventsPendingUpdate((curr) => ({ ...curr, [claimNotification.id]: eventDropInfo.event.start }));
    try {
      if (isReminder(claimNotification)) {
        await axios.patch(`/api/v1/claims/${claimNotification.claim_id}/reminders/${claimNotification.id}`, {
          due_date: moment(start).format('YYYY-MM-DD'),
        });
      } else {
        const baseUrl = claimNotification.claim_id
          ? `/api/v1/claims/${claimNotification.claim_id}/notifications`
          : '/api/v1/claims/claims_notifications';
        await axios.post(`${baseUrl}/${claimNotification.id}/postpone`, {
          postpone_date: moment(start).format('YYYY-MM-DD'),
        });
      }
      await reloadClaimNotifications();
    } catch (error) {
      reportAxiosError(error);
    }
    setEventsPendingUpdate((curr) => {
      let currCpy = { ...curr };
      delete currCpy[claimNotification.id];
      return currCpy;
    });
  }

  function exposuresArrayToDict(exposures) {
    let exposuresDict = {};
    for (const exposure of exposures) {
      exposuresDict[exposure.id] = exposure;
    }
    return exposuresDict;
  }

  function getClaimNotificationTitle(claimNotification, exposuresDict) {
    if (!claimNotification.exposure_id) {
      return claimNotification.claim_id_display || 'Unknown Claim';
    }

    const exposure = exposuresDict[claimNotification.exposure_id];
    const exposurePrefix = `${claimNotification.claim_id_display}-${exposure.claim_internal_id.toFixed(2)}`;

    if (exposure.involved_property) {
      return `${exposurePrefix} - ${exposure.involved_property.owner_contact_full_name}`;
    }

    if (exposure.involved_person) {
      return `${exposurePrefix} - ${exposure.involved_person.contact_full_name}`;
    }

    return exposurePrefix;
  }

  function isReminder(claimNotification) {
    return claimNotification.type === 'reminder_claim_notification';
  }

  function getClaimNotificationStartDate(claimNotification) {
    if (eventsPendingUpdate[claimNotification.id]) return eventsPendingUpdate[claimNotification.id];

    if (isReminder(claimNotification)) return claimNotification.due_date;

    if (claimNotification.postpone_date && claimNotification.postpone_date > moment().format('YYYY-MM-DD'))
      return claimNotification.postpone_date;

    return moment().format('YYYY-MM-DD');
  }

  const exposuresDict = exposuresArrayToDict(claimNotificationsAndExposures.exposures);

  const events = claimNotificationsAndExposures.notifications.map((claimNotification) => ({
    title: `${getClaimNotificationTitle(claimNotification, exposuresDict)} - ${claimNotification.title}`,
    start: getClaimNotificationStartDate(claimNotification),
    allDay: true,
    claimNotification,
    textColor: !isReminder(claimNotification) ? 'Blue' : undefined,
    backgroundColor: eventsPendingUpdate[claimNotification.id]
      ? 'PowderBlue'
      : !isReminder(claimNotification)
      ? 'White'
      : undefined,
  }));

  return (
    <>
      <div className={classes.cardDivRow} style={{ height: '100%' }}>
        <FullCalendar
          ref={calendarRef}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay',
          }}
          height="100%"
          firstDay={1}
          plugins={[dayGridPlugin, interactionPlugin]}
          editable
          eventStartEditable
          eventDrop={handleChangeDate}
          eventClick={(info) => setClaimNotificationSelected(info.event.extendedProps.claimNotification)}
          eventContent={(arg) => <Tooltip title={arg.event.title}>{renderInnerContent(arg)}</Tooltip>}
          dayMaxEventRows={true}
          events={events}
        />
      </div>
      {claimNotificationSelected && claimNotificationSelected.type !== 'claim_owner_change_claim_notification' && (
        <ViewClaimNotificationCard
          claimNotification={claimNotificationSelected}
          onUpdate={async () => {
            await reloadClaimNotifications();
            setClaimNotificationSelected(undefined);
          }}
          userContext
          onClose={() => setClaimNotificationSelected(undefined)}
        />
      )}
    </>
  );
}

export default AdjusterCalendar;
