import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import ClaimLink from '../../ClaimLink';
import { useCms } from '../../hooks/useCms';
import InlineIconButton from '../../InlineIconButton';
import LoadingDialog from '../../LoadingDialog';
import LoadingIndicator from '../../LoadingIndicator';
import { TextFieldFormik } from '../../TextFieldFormik';
import useDataFetcher from '../../useDataFetcher';

import { useStyles } from '../../../assets/styles';

function LossReportPage() {
  const classes = useStyles();
  const [policyNumbersList, setPolicyNumbersList] = useState([]);
  const [showingReport, setShowingReport] = useState(false);

  const handlePoliciesSubmitted = (newPolicyNumbersToSearch) => {
    setPolicyNumbersList(newPolicyNumbersToSearch);
    setShowingReport(true);
  };

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Loss Run Report">
        <Grid container spacing={2}>
          <Grid item xs={12} md={9} xl={6}>
            <PolicyNumbersChoosing
              policyNumbers={policyNumbersList}
              onSubmit={handlePoliciesSubmitted}
              onEdit={() => setShowingReport(false)}
              showOnly={showingReport}
            />
          </Grid>
          {showingReport && (
            <Grid item xs={12}>
              <LossesReport policyNumbers={policyNumbersList} />
            </Grid>
          )}
        </Grid>
      </CardDialog>
    </div>
  );
}

function PolicyNumbersChoosing({ policyNumbers, onSubmit, onEdit, showOnly }) {
  const classes = useStyles();
  return (
    <CardDialog
      title={showOnly ? 'Policy Numbers' : 'Choose Policy Numbers'}
      subheader="Please make sure to use the full policy structure (e.g AT01-001234)"
    >
      <Formik
        initialValues={{
          policyNumbers: policyNumbers.length !== 0 ? [...policyNumbers] : [''],
        }}
        validationSchema={Yup.object().shape({
          policyNumbers: Yup.array().of(Yup.string().required('Required')).required('Required').min(1, 'Required'),
        })}
        enableReinitialize
        onSubmit={(values) => onSubmit(values.policyNumbers)}
      >
        {({ handleSubmit, handleReset, isSubmitting, values, setFieldValue }) => (
          <>
            <Grid container spacing={2}>
              {values.policyNumbers.map((_, idx) => (
                <Grid item xs={3} key={idx}>
                  <TextFieldFormik
                    className={classes.textField}
                    id={`policyNumbers.${idx}`}
                    label="Policy Number"
                    showOnly={showOnly}
                    InputProps={
                      idx !== 0 && !showOnly
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ padding: 4 }}
                                  title="Remove"
                                  onClick={() =>
                                    setFieldValue(
                                      'policyNumbers',
                                      values.policyNumbers.filter((_, policyIdx) => idx !== policyIdx)
                                    )
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        : undefined
                    }
                    fullWidth
                  />
                </Grid>
              ))}
              {!showOnly && (
                <Grid item>
                  <IconButton onClick={() => setFieldValue('policyNumbers', [...values.policyNumbers, ''])}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <div className={classes.buttonsContainer}>
              {showOnly ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onEdit();
                    handleReset();
                  }}
                >
                  New Search
                </Button>
              ) : (
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Search
                </Button>
              )}
            </div>
          </>
        )}
      </Formik>
    </CardDialog>
  );
}

PolicyNumbersChoosing.propTypes = {
  policyNumbers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
};

function LossesReport({ policyNumbers }) {
  const { user } = useCms();
  const {
    isLoading,
    isError,
    data: lossReportData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/loss_report`, {
    params: { policy_numbers: policyNumbers },
  });

  const [pdfDialogDetails, setPdfDialogDetails] = useState(undefined);
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const classes = useStyles();

  const handleExportToPdf = async () => {
    try {
      setIsCreatingPdf(true);
      const res = await axios.post(`/api/v1/organizations/${user.organization_id}/loss_report/pdf`, {
        loss_report: lossReportData,
      });
      setPdfDialogDetails(res.data);
    } catch (error) {
      reportAxiosError(error);
    }
    setIsCreatingPdf(false);
  };

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <>
      <CardDialog
        title="Results"
        action={
          <InlineIconButton
            defaultColor="inherit"
            onClick={handleExportToPdf}
            icon={PrintIcon}
            tooltipTitle="Create Report PDF"
          />
        }
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Policy Number</TableCell>
              <TableCell className={classes.tableCell} align="left">
                Insured Name
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                Policy Period
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                File Number
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                Date Of Loss
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                Description
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                Close Date
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                Indemnity Paid
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lossReportData.map(({ policy_number: policyNumber, policies }) => {
              if (policies.length === 0) {
                return (
                  <TableRow key={policyNumber}>
                    <TableCell className={classes.tableCell}>{policyNumber}</TableCell>
                    <TableCell colSpan={7}>
                      <strong>No Policies Were Found</strong>
                    </TableCell>
                  </TableRow>
                );
              } else {
                return policies.map((claimOrPolicyWithoutClaim, idx) => (
                  <TableRow key={idx}>
                    {idx === 0 && (
                      <TableCell className={classes.tableCell} rowSpan={policies.length}>
                        {policyNumber}
                      </TableCell>
                    )}
                    <TableCell
                      className={classes.tableCell}
                      style={{ paddingLeft: 8 }} // Fix the treat for the cell as first child
                    >
                      {claimOrPolicyWithoutClaim.insured_name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{claimOrPolicyWithoutClaim.policy_period}</TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      <ClaimLink
                        claimId={claimOrPolicyWithoutClaim.claim_id}
                        linkText={claimOrPolicyWithoutClaim.claim_id_display}
                        openInNewTab
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align={claimOrPolicyWithoutClaim.is_claim ? 'left' : 'center'}
                      colSpan={claimOrPolicyWithoutClaim.is_claim ? 1 : 5}
                    >
                      {claimOrPolicyWithoutClaim.is_claim ? (
                        claimOrPolicyWithoutClaim.date_of_loss
                      ) : (
                        <strong>No Losses Were Found</strong>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {claimOrPolicyWithoutClaim.description}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {claimOrPolicyWithoutClaim.date_closed}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {claimOrPolicyWithoutClaim.total_indemnity_paid}
                    </TableCell>
                  </TableRow>
                ));
              }
            })}
          </TableBody>
        </Table>
      </CardDialog>
      {isCreatingPdf && <LoadingDialog track="Loss report pdf creation" />}
      {pdfDialogDetails && (
        <Dialog open={open} fullScreen>
          <Grid container style={{ margin: '8px' }} justify="flex-end" alignItems="flex-start">
            <Grid item>
              <IconButton className={classes.leftButtonDialog} onClick={() => setPdfDialogDetails(undefined)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <object
            style={{ height: '100%', width: '100%', zIndex: '1' }}
            alt=""
            data={`data:application/pdf;base64,${pdfDialogDetails['loss_report_base64']}`}
            type="application/pdf"
          />
        </Dialog>
      )}
    </>
  );
}

LossesReport.propTypes = {
  policyNumbers: PropTypes.array.isRequired,
};

export default LossReportPage;
