const CONTACT_EMAIL_CHANGE_REASONS = {
  not_in_use: {
    desc: 'Email address no longer in use',
  },
  wrong: {
    desc: 'Wrong email address',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default CONTACT_EMAIL_CHANGE_REASONS;
