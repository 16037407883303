import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import axios from 'axios';
import { connect, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';

import colors from '~/assets/colors.module.scss';
import { useStyles } from '~/assets/styles';
import { AttachOtherContacts } from '~/components/communications/AttachCommunicationContainer';
import Button from '~/components/core/Atomic/Buttons/Button';
import { isoDateToUs, serverDateTimeToLocal } from '~/DateTimeUtils';
import { capitalize, reportAxiosError } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import { RECIPIENTS_TYPES } from '../../Types';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { ContactEntity } from '../Contact';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { Caption, FsIconButton, Heading, PERMISSION_VERBS, RestrictedPermissions, Text } from '../core';
import ExposureMultiSelectTextFieldFormik from '../ExposureMultiSelectTextFieldFormik';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import { ContactIcon, ExpandIcon } from '../icons';
import InlineIconButton from '../InlineIconButton';
import useOrganization from '../OrganizationContext';
import {
  getDefaultReminderTarget,
  ReminderDialogCard,
  reminderValidationSchema,
} from '../ReminderNotificationContainer';
import { TextFieldFormik } from '../TextFieldFormik';

import {
  getCommunicationChannelIconComponent,
  getCommunicationDirectionIcon,
  getCommunicationDirectionIconComponent,
} from './CommunicationUtils';
import { displayRoleName, getAllSearchableContactRoles } from './ContactUtils';

import communicationStyles from './communications.module.scss';

const FormikToggleDirection = connect((props) => {
  const { formik } = props;
  const { values, setFieldValue } = formik;
  const direction = values['direction'];

  return (
    <>
      {direction === 'Outgoing' ? (
        <>
          {getCommunicationDirectionIconComponent('Outgoing')}
          <InlineIconButton
            onClick={() => setFieldValue('direction', 'Incoming')}
            icon={getCommunicationDirectionIcon('Incoming')}
          />
        </>
      ) : (
        <>
          <InlineIconButton
            onClick={() => setFieldValue('direction', 'Outgoing')}
            icon={getCommunicationDirectionIcon('Outgoing')}
          />
          {getCommunicationDirectionIconComponent('Incoming')}
        </>
      )}
    </>
  );
});

function calcExposureIds(claim, user, contact, attachedDocuments) {
  if (!claim) {
    return [];
  }
  const userRelatedExposureIds = claim.exposures
    .filter((exposure) => exposure.handling_adjuster_id === user.id)
    .map((exposure) => exposure.id);
  const contactExposureIds = contact ? contact.exposure_ids : [];
  const attachedDocumentsExposureIds = attachedDocuments
    ? _.flattenDeep(attachedDocuments.map((document) => document.exposure_ids))
    : [];
  const exposureIdsCombined = _.union(userRelatedExposureIds, contactExposureIds, attachedDocumentsExposureIds);
  exposureIdsCombined.sort();
  return exposureIdsCombined;
}

function getCommunicationReminderDefaultValues(channel, contact, user, claim) {
  const channelDescription = capitalize(channel.replace('_', ' '));
  return {
    title: 'Follow up on Communication',
    details: `${channelDescription} was sent on ${isoDateToUs(new Date())}${contact ? ' to ' + contact.full_name : ''}`,
    due_date: moment().add(30, 'd'),
    target_id: claim ? getDefaultReminderTarget(user, claim) : -1, // -1 = Self
    was_manually_edited: false,
  };
}

function useCreateCommunication(channel, defaultParams = {}) {
  const { summary, contact, exposure_ids } = defaultParams;

  const { claim } = useClaim();
  const { user } = useCms();

  const communicationCommonInitialValues = React.useMemo(
    () => ({
      channel,
      summary: summary || '',
      contact: contact ? contact : undefined,
      contact_id: contact ? contact.id : '',
      exposure_ids: exposure_ids ? exposure_ids : calcExposureIds(claim, user, contact),
      exposure_ids_specific_requested: !!exposure_ids,
      add_follow_up_reminder: false,
      reminder: getCommunicationReminderDefaultValues(channel, contact, user, claim),
    }),
    [channel, summary, contact, exposure_ids, user, claim]
  );

  return { communicationCommonInitialValues };
}

function CommunicationCardHeader(props) {
  const {
    communicationId,
    channel,
    direction,
    contactId,
    contactFullName,
    contactRole,
    isDynamicContact,
    onSelectContact,
    dynamicContactLabel,
    isView,
    communicationClaimId,
    onCommunicationUpdate,
    outOfContactsContextSearch = false,
  } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.container} style={{ justifyContent: 'center' }}>
        {getCommunicationChannelIconComponent(channel)}
        {direction ? (
          getCommunicationDirectionIconComponent(direction)
        ) : (
          // assume isDocument - otherwise, it's just a bug we don't have direction
          <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <FormikToggleDirection />
          </div>
        )}
      </div>
      <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
        {isView ? (
          <ViewCommunicationCardHeaderContactDetails
            communicationId={communicationId}
            communicationClaimId={communicationClaimId}
            communicationChannel={channel}
            contactId={contactId}
            contactFullName={contactFullName}
            contactRole={contactRole}
            enableAttachToContact={!contactId && communicationClaimId}
            onAttachToContact={onCommunicationUpdate}
          />
        ) : (
          <NonViewCommunicationCardHeaderContactDetails
            enableChoosingContact={isDynamicContact}
            contactId={contactId}
            contactFullName={contactFullName}
            contactRole={contactRole}
            onSelectContact={onSelectContact}
            outOfContactsContextSearch={outOfContactsContextSearch}
            dynamicContactLabel={dynamicContactLabel}
          />
        )}
      </div>
    </>
  );
}

CommunicationCardHeader.propTypes = {
  communicationId: PropTypes.number,
  communicationClaimId: PropTypes.number,
  channel: PropTypes.string.isRequired,
  direction: PropTypes.string,
  contactId: PropTypes.number,
  contactFullName: requiredIf(PropTypes.string, (props) => !!props.contactId),
  contactRole: requiredIf(PropTypes.string, (props) => !!props.contactId),
  isDynamicContact: PropTypes.bool,
  onSelectContact: PropTypes.func,
  dynamicContactLabel: PropTypes.string,
  isView: PropTypes.bool,
  outOfContactsContextSearch: PropTypes.bool,
  onCommunicationUpdate: PropTypes.func,
};

const ViewCommunicationCardHeaderContactDetails = ({
  contactId,
  contactFullName,
  contactRole,
  enableAttachToContact,
  communicationId,
  communicationClaimId,
  communicationChannel,
  onAttachToContact,
}) => {
  const { organizationContactRolesDict } = useOrganization();
  const classes = useStyles();
  const [showAttachToContactDialog, setShowAttachToContactDialog] = useState(false);

  const handleAttachContactToCommunication = async ({ contact_id, contact_attachment_choice }) => {
    try {
      await axios.post(`/api/v1/communications/${communicationId}/attach_contact`, {
        contact_id,
        should_create_new_contact: contact_attachment_choice === 'new_contact',
      });
      if (onAttachToContact) {
        await onAttachToContact();
      }
      setShowAttachToContactDialog(false);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  if (!contactId) {
    if (!enableAttachToContact || !communicationClaimId) {
      return null;
    }

    return (
      <>
        <Button
          className="flex items-center"
          variant="text"
          color="primary"
          onClick={() => setShowAttachToContactDialog(true)}
        >
          <ContactIcon className="mr-5" iconColor={colors.buttonLink} />
          Attach to contact
        </Button>
        {showAttachToContactDialog && (
          <AttachOtherContacts
            claimId={communicationClaimId}
            communicationId={communicationId}
            onClose={() => setShowAttachToContactDialog(false)}
            communicationChannel={communicationChannel}
            onSubmit={handleAttachContactToCommunication}
          />
        )}
      </>
    );
  }

  return (
    <Text variant="large" weight="semi-bold" className="flex">
      <ContactEntity classes={classes} contactId={contactId} contactDisplayName={contactFullName} />
      <span style={{ marginLeft: '16px' }}>
        <em>{displayRoleName(organizationContactRolesDict, contactRole)}</em>
      </span>
    </Text>
  );
};

ViewCommunicationCardHeaderContactDetails.propTypes = {
  communicationId: PropTypes.number,
  communicationClaimId: PropTypes.number,
  communicationChannel: PropTypes.string,
  isView: PropTypes.bool,
  enableChoosingContact: PropTypes.bool,
  contactId: PropTypes.number,
  contactFullName: PropTypes.string,
  dynamicContactLabel: PropTypes.string,
  contactRole: PropTypes.string,
  enableAttachToContact: PropTypes.bool,
  onAttachToContact: PropTypes.func,
};

const NonViewCommunicationCardHeaderContactDetails = ({
  enableChoosingContact,
  contactId,
  contactFullName,
  contactRole,
  onSelectContact,
  dynamicContactLabel,
  outOfContactsContextSearch,
}) => {
  const { organizationContactRolesDict } = useOrganization();
  const classes = useStyles();

  if (enableChoosingContact) {
    return (
      <ContactTextFieldFormik
        id="contact"
        label={dynamicContactLabel ? dynamicContactLabel : 'Contact'}
        className={classes.textField}
        style={{ width: '280px' }}
        acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict).concat(['user'])} // allow communicating with users
        fixedSearchResults
        onSelectContact={onSelectContact}
        outOfContactsContextSearch={outOfContactsContextSearch}
      />
    );
  }

  // Can happen when we reply / forward and the email is not attached to a specific contact
  if (!contactId) {
    return null;
  }

  return (
    <Text variant="large" weight="semi-bold" className="flex">
      <ContactEntity classes={classes} contactId={contactId} contactDisplayName={contactFullName} />
      <span style={{ marginLeft: '16px' }}>
        <em>{displayRoleName(organizationContactRolesDict, contactRole)}</em>
      </span>
    </Text>
  );
};

NonViewCommunicationCardHeaderContactDetails.propTypes = {
  enableChoosingContact: PropTypes.bool,
  contactId: PropTypes.number,
  contactFullName: PropTypes.string,
  contactRole: PropTypes.string,
  onSelectContact: PropTypes.func,
  dynamicContactLabel: PropTypes.string,
  outOfContactsContextSearch: PropTypes.bool,
};

function CommunicationActionSendNew(props) {
  const classes = useStyles();
  const { isSubmitting, onSubmit, disabled, buttonText } = props;

  return (
    <div className={classes.buttonsContainer}>
      <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitting || disabled}>
        {buttonText || 'Send'}
      </Button>
    </div>
  );
}

CommunicationActionSendNew.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
};

function CommunicationExposureLabels({ isView, isEditing, onEdit }) {
  const { values, setFieldValue } = useFormikContext();
  const [claim, isLoading, isError] = useFetchClaim(values.claim_id);
  const { user } = useCms();
  const [shouldSetExposuresAutomatically, setShouldSetExposuresAutomatically] = React.useState(
    !isView && !values['exposure_ids_specific_requested']
  );

  const currentExposureIds = values['exposure_ids'];
  const exposureIdsCombined = calcExposureIds(claim, user, values['contact'], values['attached_documents']);

  React.useEffect(() => {
    if (!shouldSetExposuresAutomatically) {
      return;
    }

    if (isView && !isEditing) {
      return;
    }

    if (!_.isEqual(exposureIdsCombined, currentExposureIds)) {
      setFieldValue('exposure_ids', exposureIdsCombined);
    }
  }, [currentExposureIds, exposureIdsCombined, setFieldValue, isView, isEditing, shouldSetExposuresAutomatically]);

  if (isLoading || isError || !claim) {
    return null;
  }

  return (
    <ExposureMultiSelectTextFieldFormik
      claim={claim}
      showOnly={isView && !isEditing}
      onEdit={onEdit}
      onChange={(e) => {
        setFieldValue('exposure_ids', e.target.value);
        setShouldSetExposuresAutomatically(false);
      }}
    />
  );
}

CommunicationExposureLabels.propTypes = {
  isView: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func,
};

function CommunicationReminderContainer() {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { claim } = useClaim();
  const { user } = useCms();
  const classes = useStyles();
  const [reminderDialogOpen, setReminderDialogOpen] = React.useState(false);

  const { channel, contact } = values;
  const was_reminder_manually_edited = values['reminder']['was_manually_edited'];
  React.useEffect(() => {
    if (was_reminder_manually_edited) {
      return;
    }

    setFieldValue('reminder', getCommunicationReminderDefaultValues(channel, contact, user, claim));
  }, [setFieldValue, was_reminder_manually_edited, channel, contact, user, claim]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckboxFormik
          id="add_follow_up_reminder"
          label="Create a Reminder to follow up on this communication"
          fullWidth
        />
        {values['add_follow_up_reminder'] && (
          <HoverActionField onAction={() => setReminderDialogOpen(true)} disabled={isSubmitting}>
            <em>{`Reminder will be set for ${isoDateToUs(values['reminder'].due_date)}`}</em>
          </HoverActionField>
        )}
      </div>
      {reminderDialogOpen && (
        <Formik
          initialValues={{
            ...values['reminder'], // NOTE: values is of the outer formik
          }}
          validationSchema={reminderValidationSchema}
          enableReinitialize
          onSubmit={(values) => {
            setFieldValue('reminder', { ...values, was_manually_edited: true }); // NOTE: setFieldValue is of the outer formik while values is of the inner one
            setReminderDialogOpen(false);
          }}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit } = formikProps;

            const cardDialogProps = {
              open: true,
              isDialog: true,
              fullWidth: true,
              maxWidth: 'sm',
              onClose: () => setReminderDialogOpen(false),
              title: 'Communication Reminder',
              preventClose: isSubmitting,
            };

            const buttonsComponent = (
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            );

            return (
              <ReminderDialogCard buttonsComponent={buttonsComponent} cardDialogProps={cardDialogProps} claim={claim} />
            );
          }}
        </Formik>
      )}
    </>
  );
}

CommunicationReminderContainer.propTypes = {};

const CommunicationCard = (props) => {
  const {
    open,
    communication,
    communicationInitialFields,
    communicationHeaderAdditionalProps,
    CommunicationTypeSpecificBody,
    communicationTypeSpecificBodyProps,
    CommunicationAction,
    communicationActionProps,
    communicationIdentifier,
    additionalCardActions,
    disableSoftClose,
    isEditing,
    isNew,
    isDialog,
    isDocument,
    onRequestEdit,
    onClose,
    onMinimized,
    disableMinimized,
    title,
    maxWidth,
    isCommunicationNotRelatedToClaimInContext,
    senderFragment,
    leftCardActions,
    onCommunicationUpdate,
  } = props;

  const classes = useStyles();
  const formik = useFormikContext();
  const { inClaimPage } = useClaim();
  const communicationClaimId = communication?.claim_id;
  const [claim] = useFetchClaim(communicationClaimId);

  const [requestedAsFullscreen, setRequestedAsFullscreen] = useState(false);

  const { isSubmitting } = formik;
  const isView = !isNew && !isDocument;

  let channel;
  let direction;
  let contact;

  if (isView) {
    channel = communication.channel;
    contact = communication.contact;
    direction = communication.direction;
  } else {
    const { communicationChannel, communicationContact, communicationDirection } = communicationInitialFields;
    channel = communicationChannel;
    contact = communicationContact;
    direction = communicationDirection;
  }

  let dialogProps = {};
  if (isDialog) {
    dialogProps = {
      isDialog,
      onClose: disableSoftClose || isEditing ? undefined : onClose,
      maxWidth: maxWidth ? maxWidth : 'sm',
      fullWidth: true,
    };
  }
  const fullScreenProps = {
    isDialog: true,
    onClose: () => setRequestedAsFullscreen(false),
    maxWidth: 'lg',
    fullWidth: true,
  };

  const displayDetachAction =
    isView &&
    !isEditing &&
    communicationClaimId &&
    ['email', 'phone_call', 'physical_mail'].includes(communication.channel);
  const displayNotificationAction = isView && !isEditing && !!communication.claim_id;

  const rightCardActions = React.Children.map(additionalCardActions, (child) =>
    React.cloneElement(child, {
      displayDetachAction,
      displayNotificationAction,
    })
  );

  const action = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {leftCardActions}
      {!requestedAsFullscreen && !isDialog && (
        <RestrictedPermissions verb={PERMISSION_VERBS.READ}>
          <span>
            <FsIconButton tooltipText="Full screen" icon={ExpandIcon} onClick={() => setRequestedAsFullscreen(true)} />
          </span>
        </RestrictedPermissions>
      )}
      {rightCardActions}
    </div>
  );

  const additionalHeaderProp = communicationHeaderAdditionalProps ? communicationHeaderAdditionalProps : {};
  return (
    <>
      {/* This is our way of showing the communication in 'Full Screen' - open in it again but as large dialog  */}
      {requestedAsFullscreen && <CommunicationCard {...props} {...fullScreenProps} />}
      <CardDialog
        action={action}
        open={open}
        {...dialogProps}
        title={title ? title : undefined}
        preventClose={isSubmitting}
        trackAlt="Communication"
        onMinimized={onMinimized}
        disableMinimized={disableMinimized}
        subheader={
          (!inClaimPage || isCommunicationNotRelatedToClaimInContext) &&
          claim && ( // Show link to the claim only if communication's claim different from the claim page were in, or we're outside claim page
            <ClaimLink claimId={claim.id} linkText={`Claim: ${claim.claim_id_display}`} />
          )
        }
      >
        {senderFragment}
        <CommunicationCardHeader
          communicationId={communication?.id}
          communicationClaimId={communicationClaimId}
          channel={channel}
          direction={direction}
          contactId={contact && contact.id}
          contactFullName={contact && contact.full_name}
          contactRole={contact && contact.role}
          isView={isView}
          onCommunicationUpdate={onCommunicationUpdate}
          {...additionalHeaderProp}
        />
        {isView ? (
          <>
            <div className={communicationStyles.contactDetailsRowContainer}>
              <Text variant={Text.VARIANTS.SM}>{communicationIdentifier}</Text>
            </div>
            <div className={communicationStyles.contactDetailsRowContainer}>
              <Heading variant={Heading.TYPES.H3}>{serverDateTimeToLocal(communication.datetime)}</Heading>
            </div>
            <div className={communicationStyles.contactDetailsRowContainer}>
              <Tooltip title={communication?.internal_email_address}>
                <span className={classes.containerCentered}>
                  {`${direction === 'Outgoing' ? 'from:' : 'to:'} ${communication.adjuster} `} &nbsp;
                  {RECIPIENTS_TYPES[communication?.adjuster_recipient_type] &&
                  communication?.adjuster_recipient_type !== 'to' ? (
                    <Caption variant={Caption.VARIANTS.LABEL}>
                      ({RECIPIENTS_TYPES[communication.adjuster_recipient_type]})
                    </Caption>
                  ) : null}
                </span>
              </Tooltip>
            </div>
          </>
        ) : null}
        <CommunicationTypeSpecificBody
          communication={communication}
          communicationInitialFields={communicationInitialFields}
          isDocument={isDocument}
          isEditing={isEditing}
          onRequestEdit={onRequestEdit}
          isNew={isNew}
          {...communicationTypeSpecificBodyProps}
        />

        {(!isView || communication.summary || isEditing || onRequestEdit) && ( // show summary field only if exists or can be edited
          <div className={classes.inputContainer}>
            <TextFieldFormik
              id="summary"
              label="Summary"
              className={classes.textField}
              fullWidth
              onEdit={onRequestEdit}
              showOnly={isView && !isEditing}
            />
          </div>
        )}

        {(!isView || communication.claim_id) && ( // don't display if we're in an existing communication which is not yet claim related
          <CommunicationExposureLabels isView={isView} isEditing={isEditing} onEdit={onRequestEdit} />
        )}

        <CommunicationAction
          communication={communication}
          isDocument={isDocument}
          isEditing={isEditing}
          isNew={isNew}
          {...communicationActionProps}
        />
      </CardDialog>
    </>
  );
};

CommunicationCard.propTypes = {
  open: PropTypes.bool,
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  onCommunicationUpdate: PropTypes.func,
  communicationIdentifier: PropTypes.string,
  isDialog: PropTypes.bool,
  title: PropTypes.string,
  disableSoftClose: PropTypes.bool,
  onClose: requiredIf(PropTypes.func, (props) => props.isDialog && !props.disableSoftClose),
  onMinimized: PropTypes.func,
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  disableMinimized: PropTypes.bool,
  additionalCardActions: PropTypes.node,
  communicationInitialFields: PropTypes.shape({
    communicationChannel: PropTypes.string.isRequired,
    communicationContact: PropTypes.object,
    communicationDirection: requiredIf(PropTypes.oneOf(['Incoming', 'Outgoing']), (props) => props.isNew),
  }),
  communicationHeaderAdditionalProps: PropTypes.object,
  CommunicationTypeSpecificBody: PropTypes.func.isRequired,
  communicationTypeSpecificBodyProps: PropTypes.object,
  CommunicationAction: PropTypes.func.isRequired,
  communicationActionProps: PropTypes.object,
  isEditing: PropTypes.bool,
  onRequestEdit: PropTypes.func,
  formik: PropTypes.object, // From formik
  maxWidth: PropTypes.string,
  isCommunicationNotRelatedToClaimInContext: PropTypes.bool,
  senderFragment: PropTypes.node,
  leftCardActions: PropTypes.node,
};

export { CommunicationActionSendNew, CommunicationCardHeader, CommunicationReminderContainer, useCreateCommunication };
export default CommunicationCard;
