import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function OverflowTextWithToolTip({
  children,
  textWrapperClassName,
  maxWidth,
  disabled,
  tooltipMaxWidth,
  tooltipPlacement,
  arrow = false,
}) {
  const textDivElement = React.useRef();
  const [isOverflowed, setIsOverflowed] = useState(false);
  // HACK - we have crashes in PROD
  const [renderedCounter, setRenderedCounter] = useState(0);

  useEffect(() => {
    if (disabled || renderedCounter > 5) {
      return;
    }

    const newOverflowValue = textDivElement.current.scrollWidth > textDivElement.current.clientWidth;
    if (isOverflowed !== newOverflowValue) {
      setRenderedCounter((curr) => curr + 1); // protection from infinite rendering
      setIsOverflowed(newOverflowValue);
    }
  }, [disabled, maxWidth, renderedCounter, isOverflowed]);

  const useStyles = makeStyles(() => ({
    customWidth: {
      maxWidth: tooltipMaxWidth || null,
    },
  }));

  const classes = useStyles();

  if (disabled) {
    return children;
  }

  return (
    <div>
      <Tooltip
        title={children}
        arrow={arrow}
        disableHoverListener={!isOverflowed}
        placement={tooltipPlacement}
        classes={tooltipMaxWidth ? { tooltip: classes.customWidth } : undefined}
      >
        <div
          ref={textDivElement}
          className={textWrapperClassName}
          style={{
            maxWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </div>
      </Tooltip>
    </div>
  );
}

OverflowTextWithToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  textWrapperClassName: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  arrow: PropTypes.bool,
};

export default OverflowTextWithToolTip;
