import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import Button from '~/components/core/Atomic/Buttons/Button';

import { isoDateToUs, serverDateToLocal, serverDateToLocalMoment } from '../../../DateTimeUtils';
import { stringCmp } from '../../../Utils';
import CardDialog from '../../CardDialog';
import ClaimLink from '../../ClaimLink';
import { ContactEntity } from '../../Contact';
import useOrganization from '../../OrganizationContext';
import PlainTable from '../../PlainTable';

import { useStyles } from '../../../assets/styles';

// TODO: remove eslint after enabling drafts
// eslint-disable-next-line no-unused-vars
function MgmFnolSearchResults({ foundClaims, claimType, relatedDraft, onReportNewFnol, shouldHideType }) {
  const classes = useStyles();
  const { incidentTypesDict } = useOrganization();

  let columnData = [
    {
      id: 'subtype',
      numeric: false,
      label: 'Type',
      specialCell: (claim) => incidentTypesDict[claimType][claim.subtype].desc,
    },
    // stopPropagation from Link, otherwise, Link will be followed but Table onClick will also occur
    {
      id: 'id',
      numeric: false,
      width: '160px',
      disablePadding: false,
      label: 'Claim',
      // eslint-disable-next-line react/display-name
      specialCell: (claim) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <ClaimLink
            claimId={claim.id}
            linkText={claim.claim_id_display}
            openInNewTab
            trackingInfo="gl_fnol_search_claim_clicked"
          />
        </div>
      ),
      specialCmpFunc: (row1, row2) => row1.id - row2.id,
    },
    {
      id: 'claimant_contact_full_name',
      numeric: false,
      disablePadding: false,
      label: 'Claimant Name',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        row.claimant_contact_id ? (
          <ContactEntity contactId={row.claimant_contact_id} contactDisplayName={row.claimant_contact_full_name} />
        ) : null,
    },
    {
      id: 'date_of_loss',
      width: '130px',
      numeric: false,
      disablePadding: false,
      label: 'Date of Loss',
      specialCell: (row) => isoDateToUs(row.date_of_loss),
      specialCmpFunc: (row1, row2) => stringCmp(row1.date_of_loss, row2.date_of_loss),
    },
    {
      id: 'creation_date',
      width: '130px',
      numeric: false,
      disablePadding: false,
      label: 'Reported On',
      specialCell: (row) => serverDateToLocalMoment(row.creation_date),
      specialCmpFunc: (row1, row2) => stringCmp(row1.creation_date, row2.creation_date),
    },
    {
      id: 'reporter_contact_full_name',
      numeric: false,
      disablePadding: false,
      label: 'Reporter Name',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        row.reporter_contact_id ? (
          <ContactEntity contactId={row.reporter_contact_id} contactDisplayName={row.reporter_contact_full_name} />
        ) : null,
    },
    { id: 'handling_adjuster', numeric: false, disablePadding: false, label: 'File Owner' },
    // eslint-disable-next-line react/display-name
    {
      id: 'is_closed',
      width: '70px',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      specialCell: (claim) => (claim.is_closed ? <font color="red">Closed</font> : <font color="green">Open</font>),
    },
    {
      id: 'last_touched',
      width: '135px',
      numeric: false,
      disablePadding: false,
      label: 'Last Touched',
      specialCell: (row) => (row.last_touched ? serverDateToLocal(row.last_touched) : ''),
      specialCmpFunc: (row1, row2) => stringCmp(row1.last_touched || '', row2.last_touched || ''),
    },
  ];

  if (shouldHideType) {
    columnData = columnData.filter((col) => col.id !== 'subtype');
  }

  return (
    <CardDialog noCardTitle>
      <CardDialog title="Existing Claims/Events" outlinedCard>
        {foundClaims.length > 0 ? (
          <PlainTable keepRowsOrder classes={classes} columns={columnData} rows={foundClaims} />
        ) : (
          <Typography>No related claims/events found</Typography>
        )}
      </CardDialog>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" color="primary" onClick={onReportNewFnol}>
          Report New Loss
        </Button>
      </div>
    </CardDialog>
  );
}

MgmFnolSearchResults.propTypes = {
  foundClaims: PropTypes.array,
  claimType: PropTypes.string.isRequired,
  onReportNewFnol: PropTypes.func.isRequired,
  relatedDraft: PropTypes.object,
  shouldHideType: PropTypes.bool,
};

export default MgmFnolSearchResults;
