import React, { useEffect, useState } from 'react';
import { Switch } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import ContactCard from '~/components/Contacts/OrganizationContactsScreen/ContactCard';
import OrganizationContactsAdvancedSearchContainer from '~/components/Contacts/OrganizationContactsScreen/OrganizationContactsAdvancedSearch/OrganizationContactsAdvancedSearchContainer';
import OrganizationContactsTable from '~/components/Contacts/OrganizationContactsScreen/OrganizationContactsTable';
import {
  ADVANCED_CONTACTS_SEARCH_FIELD_IDS,
  ALL_OPTIONS_VALUE,
  CONTACTS_PER_PAGE,
} from '~/components/Contacts/OrganizationContactsScreen/organizationContactsUtils';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, Text } from '~/components/core';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import { ChevronLeftIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';
import { getAxiosParamsSerializer, reportAxiosError } from '~/Utils';

import { getAllOrganizationRolesExcludingSystem, getAllOrganizationWideRoles } from '../../communications/ContactUtils';
import { CreateContact } from '../../Contact';
import ContactSearchContainerOutOfClaim from '../../ContactSearch/ContactSearchContainerOutOfClaim';
import { useCms } from '../../hooks/useCms';
import useOrganization from '../../OrganizationContext';

function OrganizationContactsScreen() {
  const { setPageTitle } = useCms();
  const classes = useStyles();
  const { organizationContactRolesDict, organizationMoiExpertises, organizationId } = useOrganization();
  const [selectedContact, setSelectedContact] = useState();
  const [isNewContactDialogOpen, setIsNewContactDialogOpen] = useState(false);

  const [onlyIncludeMultiClaimContacts, setOnlyIncludeMultiClaimContacts] = useState(true);
  const [prevSearchParams, setPrevSearchParams] = useState({});
  const [contacts, setContacts] = useState();
  const [contactsCount, setContactsCount] = useState(0);
  const [isNoContactFound, setIsNoContactFound] = useState(false);
  const [paginationProps, setPaginationProps] = useState({
    page: 0,
    limit: CONTACTS_PER_PAGE,
    sort_by: 'full_name',
    order_by: 'asc',
  });

  useEffect(() => setPageTitle('Contacts', 'Contacts - Five Sigma CMS'), [setPageTitle]);

  const allAcceptedRoles = getAllOrganizationRolesExcludingSystem(organizationContactRolesDict);
  const multiSelectAcceptedRoles = getAllOrganizationWideRoles(organizationContactRolesDict);

  const hasContactWritePermission = useHasPermission({
    action: PERMISSION_ACTIONS.CONTACT,
    verb: PERMISSION_VERBS.WRITE,
  });

  const handleOnSubmitContact = (contact) => {
    setSelectedContact(contact);
    setIsNewContactDialogOpen(false);
  };

  const handleContactSearch = async (searchParams, paginationProps) => {
    if (searchParams.accepted_roles.includes(ALL_OPTIONS_VALUE)) {
      searchParams.accepted_roles = allAcceptedRoles;
    }

    if (searchParams.accepted_expertise?.includes(ALL_OPTIONS_VALUE)) {
      searchParams.accepted_expertise = Object.keys(organizationMoiExpertises);
    }

    setPrevSearchParams(searchParams);
    setPaginationProps(paginationProps);

    try {
      const response = await axios.get(`/api/v1/organizations/${organizationId}/contacts`, {
        params: {
          ...searchParams,
          ...paginationProps,
          page: paginationProps.page + 1,
          include_single_claim_contacts: !onlyIncludeMultiClaimContacts,
        },
        paramsSerializer: getAxiosParamsSerializer('none'),
      });

      setContacts(response.data.contacts);
      setContactsCount(response.data.count);

      if (response.data.count === 0) {
        setIsNoContactFound(true);
      }
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onShowAllContacts = async (searchText) => {
    const searchParams = {
      [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SEARCH]: searchText,
      [ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_ROLES]: allAcceptedRoles,
    };

    const initialPaginationProps = {
      ...paginationProps,
      page: 0,
    };

    await handleContactSearch(searchParams, initialPaginationProps);
  };

  return (
    <>
      {selectedContact ? (
        <div className="m-10">
          <div className="m-10 flex w-fit cursor-pointer gap-5" onClick={() => setSelectedContact(null)}>
            <ChevronLeftIcon size={20} iconColor={colors.blue[600]} className="mt-2" />
            <Text
              variant={Text.VARIANTS.SM}
              colorVariant={Text.COLOR_VARIANTS.LINK}
              weight={Text.WEIGHTS.MEDIUM}
              className="underline"
            >
              Back to All Contacts
            </Text>
          </div>
          <ContactCard contactId={selectedContact.id} />{' '}
        </div>
      ) : (
        <>
          <div className="m-10 mb-0 w-full bg-white p-24">
            <div className="flex">
              <ContactSearchContainerOutOfClaim
                onSelectContact={setSelectedContact}
                selectedContactId={selectedContact ? selectedContact.id : undefined}
                selectedContactDisplayName={selectedContact ? selectedContact.full_name : undefined}
                TextFieldProps={{ label: 'Search Contact' }}
                acceptedRoles={allAcceptedRoles}
                disallowNew
                disableAdvanceSearch
                shouldShowAllOption
                includeSingleClaimContactsInServerQuery={!onlyIncludeMultiClaimContacts}
                handleShowAllContacts={onShowAllContacts}
              />
              <div className="grow">
                <Switch
                  checked={onlyIncludeMultiClaimContacts}
                  onChange={(e) => setOnlyIncludeMultiClaimContacts(e.target.checked)}
                  className={classes.formsSwitch}
                />{' '}
                Multi-Claim Contacts Only
              </div>
              {hasContactWritePermission ? (
                <div className="flex items-center">
                  <FsButton
                    color="primary"
                    disabled={false}
                    onClick={() => {
                      setIsNewContactDialogOpen(true);
                    }}
                  >
                    <AddIcon />
                    Add contact
                  </FsButton>
                </div>
              ) : null}
            </div>
            <OrganizationContactsAdvancedSearchContainer
              handleContactSearch={handleContactSearch}
              initialSearchTextValue={prevSearchParams?.[ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SEARCH]}
              includeSingleClaimRoles={!onlyIncludeMultiClaimContacts}
            />
            {isNoContactFound ? <Text>No contacts found</Text> : null}
            {contactsCount > 0 ? (
              <OrganizationContactsTable
                contacts={contacts}
                contactsCount={contactsCount}
                onSelectContact={setSelectedContact}
                onContactSearch={handleContactSearch}
                prevSearchParams={prevSearchParams}
                paginationProps={paginationProps}
              />
            ) : null}
          </div>
        </>
      )}

      {isNewContactDialogOpen && (
        <CreateContact
          onSubmitContact={handleOnSubmitContact}
          onCancel={() => setIsNewContactDialogOpen(false)}
          acceptedRoles={multiSelectAcceptedRoles}
        />
      )}
    </>
  );
}

export default OrganizationContactsScreen;
