import React from 'react';
import PropTypes from 'prop-types';

import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

import CardDialog from '../../CardDialog';
import { CalledOffIcon, CheckIcon, ClockIcon, ErrorIcon, InfoIcon, WarningIcon } from '../../icons';

const ALERT_TYPES = {
  DONE: 'done',
  ERROR: 'error',
  PENDING: 'pending',
  WARNING: 'warning',
  CALLED_OFF: 'called off',
  INFO: 'info',
};

const ICONS = {
  [ALERT_TYPES.DONE]: CheckIcon,
  [ALERT_TYPES.ERROR]: ErrorIcon,
  [ALERT_TYPES.PENDING]: ClockIcon,
  [ALERT_TYPES.WARNING]: WarningIcon,
  [ALERT_TYPES.CALLED_OFF]: CalledOffIcon,
  [ALERT_TYPES.INFO]: InfoIcon,
};

const AlertBanner = ({ note, alertType, title, trackAlt, withIcon = false, className }) => {
  const containerClassNames = cn('p-10 rounded-md	', {
    ['bg-green-100 border border-solid border-green-700']: alertType === ALERT_TYPES.DONE,
    ['bg-red-100 border border-solid border-red-700']: alertType === ALERT_TYPES.ERROR,
    ['bg-orange-100 border border-solid border-orange-700']: alertType === ALERT_TYPES.PENDING,
    ['bg-yellow-100 border border-solid border-yellow-700']: alertType === ALERT_TYPES.WARNING,
    ['bg-slate-300 border border-solid border-slate-700']: alertType === ALERT_TYPES.CALLED_OFF,
    ['bg-blueSky-100 border border-solid border-blue-300']: alertType === ALERT_TYPES.INFO,
  });
  const AlertIcon = ICONS[alertType];
  const icon = withIcon && AlertIcon ? <AlertIcon size={20} iconColor={colors.slate[900]} /> : undefined;

  const TitleContainer = () => {
    return (
      <div className="flex pt-10 font-bold">
        {icon && <div className="flex pr-10">{icon}</div>}
        {title}
      </div>
    );
  };

  const NoteContainer = () => {
    return (
      <div className={cn('flex pt-10', title && icon ? 'pl-30' : 'pl-0')}>
        {!title && icon && <div className="flex pr-10">{icon}</div>}
        {note}
      </div>
    );
  };

  return (
    <CardDialog containerClassName={cn(containerClassNames, className)} noCardTitle trackAlt={trackAlt}>
      {title && <TitleContainer />}
      <NoteContainer />
    </CardDialog>
  );
};

AlertBanner.ALERT_TYPES = ALERT_TYPES;

AlertBanner.propTypes = {
  note: PropTypes.string.isRequired | PropTypes.element.isRequired,
  alertType: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  title: PropTypes.string,
  trackAlt: PropTypes.string,
  withIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default AlertBanner;
