import React from 'react';
import { Avatar } from '@material-ui/core';

import cn from '~/Utils/cn';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import justerButtonImage from '../../../../assets/JusterSmall2.png';

export const AiAvatar: React.FC<{ className?: string }> = ({ className = cn('h-30 w-30') }) => {
  return <Avatar src={justerButtonImage} sizes="20px" className={className} />;
};
