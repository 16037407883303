import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const PaymentNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="none"
    disableStrokeColor
    {...props}
  >
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M25.75 25.75H28.75M21.25 25.75H22.75M12.25 19.0844H31.75M13 15.25H31C31.4142 15.25 31.75 15.5858 31.75 16V28C31.75 28.4142 31.4142 28.75 31 28.75H13C12.5858 28.75 12.25 28.4142 12.25 28V16C12.25 15.5858 12.5858 15.25 13 15.25Z"
      stroke={iconColor}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default PaymentNoteIcon;
