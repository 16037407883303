import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { Formik } from 'formik';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import EmailCommunicationSpecificBody from '~/components/communications/EmailCommunicationCard/EmailCommunicationSpecificBody';
import { emailCommunicationToFormikValues } from '~/components/communications/EmailCommunicationCard/schemaValidations';
import { getEmailCommunicationSpecificIdentifier } from '~/components/communications/EmailCommunicationCard/utils';
import SmsCommunicationSpecificBody from '~/components/communications/SmsCommunicationCard/SmsCommunicationSpecificBody';
import {
  getSmsCommunicationSpecificIdentifier,
  smsCommunicationToFormikValues,
} from '~/components/communications/SmsCommunicationCard/utils';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import TooltipIcon from '~/components/core/TooltipIcon';
import { reportAxiosError } from '~/Utils';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import CommunicationActionsMenu from '../CommunicationActionsMenu';
import {
  getPhoneCallCommunicationSpecificIdentifier,
  PhoneCallCommunicationSpecificBody,
  phoneCallCommunicationToFormikValues,
} from '../communications/PhoneCallCommunicationCard';
import { PhonePlusIcon } from '../icons';
import LoadingIndicator from '../LoadingIndicator';
import useDataFetcher from '../useDataFetcher';

import VideoCallSpecificBody from './VideoCommunication/VideoCallSpecificBody';
import AttachCommunicationContainer from './AttachCommunicationContainer';
import CommunicationCard from './CommunicationCard';
import NewPhoneCallCommunicationCardWithClaimContext from './NewPhoneCallCommunicationCardWithClaimContext';
import {
  getPhysicalCommunicationSpecificIdentifier,
  PhysicalCommunicationSpecificBody,
  physicalMailCommunicationToFormikValues,
} from './PhysicalMailCommunicationCard';
import { VendorCommunicationSpecificBody, vendorCommunicationToFormikValues } from './VendorCommunicationCard';

const ReturnPhoneCallAction = ({ communication }) => {
  const { contact, claim_id, contact_phone } = communication;
  const [showReturnPhoneCallCommunicationCard, setShowReturnPhoneCallCommunicationCard] = useState(false);
  const handleOpenReturnPhoneCallCommunicationCard = () => {
    setShowReturnPhoneCallCommunicationCard(true);
  };

  const handleCloseDialog = () => {
    setShowReturnPhoneCallCommunicationCard(false);
  };

  return (
    <>
      <TooltipIcon title="Call back">
        <IconButton onClick={handleOpenReturnPhoneCallCommunicationCard} className="group">
          <PhonePlusIcon
            iconColor="currentColor"
            className="h-20 w-20 text-grey-500 group-hover:text-teal-700"
            onClick={handleOpenReturnPhoneCallCommunicationCard}
          />
        </IconButton>
      </TooltipIcon>

      {showReturnPhoneCallCommunicationCard ? (
        <NewPhoneCallCommunicationCardWithClaimContext
          claimId={claim_id}
          contact={contact}
          contactPhoneId={contact_phone.id}
          handleCloseDialog={handleCloseDialog}
        />
      ) : null}
    </>
  );
};

ReturnPhoneCallAction.propTypes = {
  communication: PropTypes.object.isRequired,
};

const phoneCallBackAction = (communication, isEditing) => {
  const { claim_id } = communication;

  if (isEditing) {
    return undefined;
  }
  return claim_id && <ReturnPhoneCallAction communication={communication} />;
};

function ViewCommunicationCardContainer(props) {
  const {
    communicationId,
    isDialog,
    onClose,
    onUpdate,
    onDelete = noop,
    onRefer = noop,
    onAttach,
    onReply,
    onForward,
    isCommunicationNotRelatedToClaimInContext,
    displayAttachClaim = true,
    displayGeneralQueueActions = false,
    hideEmailCommunicationAction = false,
  } = props;
  const [isEditing, setIsEditing] = React.useState(props.startIsEditing || false);
  const { claim: claimInContext } = useClaim();

  const {
    isLoading,
    isError,
    data: communication,
    reloadData,
  } = useDataFetcher(
    claimInContext && !isCommunicationNotRelatedToClaimInContext
      ? `/api/v1/claims/${claimInContext.id}/communications/${communicationId}`
      : `/api/v1/communications/${communicationId}`
  );

  if (isLoading || isError) {
    return (
      <CardDialog isDialog={isDialog} onClose={onClose} noCardTitle>
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  const handleUpdate = async () => {
    const updatedCommunication = await reloadData();
    if (onUpdate) await onUpdate(updatedCommunication);
  };

  const emailCommunicationActions = () => {
    if (isEditing) {
      return undefined;
    }

    return (
      <CommunicationActionsMenu
        communication={communication}
        onUpdate={handleUpdate}
        displayGeneralQueueActions={displayGeneralQueueActions}
        displayEmailCommunicationAction={!hideEmailCommunicationAction}
        onDelete={onDelete}
        onRefer={onRefer}
        onAttach={onAttach}
        onForward={onForward}
        onReply={onReply}
      />
    );
  };

  const smsCommunicationActions = () => {
    if (isEditing) {
      return undefined;
    }

    return <CommunicationActionsMenu communication={communication} onUpdate={handleUpdate} />;
  };

  const generalCommunicationActions = () => {
    if (isEditing) {
      return undefined;
    }

    return <CommunicationActionsMenu communication={communication} onUpdate={handleUpdate} />;
  };

  let formikValues = { ...communication };
  // let validationSchema;
  let additionalCardActions;
  let communicationIdentifier;
  let CommunicationTypeSpecificBody;
  let leftCardActions;
  let communicationTypeSpecificBodyProps = {
    onUpdate: handleUpdate,
  };
  let CommunicationAction;
  let formikPropsToCommunicationActionProps;

  switch (communication.channel) {
    case 'email':
      formikValues = { ...formikValues, ...emailCommunicationToFormikValues(communication) };
      // validationSchema = getEmailMessageValidation(communication.direction);
      CommunicationTypeSpecificBody = EmailCommunicationSpecificBody;
      communicationIdentifier = getEmailCommunicationSpecificIdentifier(communication);
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = emailCommunicationActions();
      break;
    case 'sms':
      formikValues = { ...formikValues, ...smsCommunicationToFormikValues(communication) };
      // validationSchema = smsMessageValidation;
      CommunicationTypeSpecificBody = SmsCommunicationSpecificBody;
      communicationIdentifier = getSmsCommunicationSpecificIdentifier(communication);
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = smsCommunicationActions();
      break;
    case 'phone_call':
      formikValues = { ...formikValues, ...phoneCallCommunicationToFormikValues(communication) };
      // validationSchema = phoneCallMessageValidation;
      CommunicationTypeSpecificBody = PhoneCallCommunicationSpecificBody;
      communicationIdentifier = getPhoneCallCommunicationSpecificIdentifier(communication);
      CommunicationAction = PhoneCallCommunicationActionView;
      additionalCardActions = generalCommunicationActions();
      leftCardActions = phoneCallBackAction(communication, isEditing);
      break;
    case 'physical_mail':
      formikValues = { ...formikValues, ...physicalMailCommunicationToFormikValues(communication) };
      // validationSchema = physicalMailValidation;  - Obsolete, update if necessary according to new function
      CommunicationTypeSpecificBody = PhysicalCommunicationSpecificBody;
      communicationIdentifier = getPhysicalCommunicationSpecificIdentifier(communication);
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = generalCommunicationActions();
      break;
    case 'video_call':
      formikValues = { ...formikValues };
      CommunicationTypeSpecificBody = VideoCallSpecificBody;
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = generalCommunicationActions();
      break;
    case 'vendor':
      formikValues = { ...formikValues, ...vendorCommunicationToFormikValues(communication) };
      CommunicationTypeSpecificBody = VendorCommunicationSpecificBody;
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = generalCommunicationActions();
      break;
    default:
      throw Error(`Unknown communication channel: ${communication.channel}`);
  }

  formikPropsToCommunicationActionProps = (formikProps) => ({
    onCancelEdit: () => {
      formikProps.handleReset();
      setIsEditing(false);
    },
    onSubmit: () => {
      formikProps.handleSubmit();
    },
    isSubmitting: formikProps.isSubmitting,
  });

  // If the communication.contact is null claim_id will be as well, so it's enough to check only communication.claim_id
  const isClaimUnclassified = !communication.claim_id;
  if (isClaimUnclassified && displayAttachClaim) {
    CommunicationAction = AttachCommunicationContainer;
    formikPropsToCommunicationActionProps = () => ({
      communication,
      onAttach: async () => {
        await handleUpdate();
      },
    });
  }

  return (
    <Formik
      initialValues={formikValues}
      // validationSchema={validationSchema}  // currently there is no need for validation here
      onSubmit={async (values, formikProps) => {
        const changedValues = Object.keys(values).reduce(
          (acc, valId) => (values[valId] !== formikValues[valId] ? { ...acc, [valId]: values[valId] } : acc),
          {}
        );
        try {
          await axios.patch(`/api/v1/communications/${communication.id}`, changedValues);
          await handleUpdate();
          setIsEditing(false);
          formikProps.setSubmitting(false);
        } catch (error) {
          formikProps.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <CommunicationCard
            isDialog={isDialog}
            onClose={onClose}
            communication={communication}
            communicationIdentifier={communicationIdentifier}
            additionalCardActions={additionalCardActions}
            CommunicationTypeSpecificBody={CommunicationTypeSpecificBody}
            communicationTypeSpecificBodyProps={communicationTypeSpecificBodyProps}
            isEditing={isEditing}
            onRequestEdit={isClaimUnclassified ? undefined : () => setIsEditing(true)}
            CommunicationAction={CommunicationAction}
            communicationActionProps={formikPropsToCommunicationActionProps(formikProps)}
            maxWidth={isDialog ? 'lg' : undefined}
            isCommunicationNotRelatedToClaimInContext={isCommunicationNotRelatedToClaimInContext}
            leftCardActions={leftCardActions}
            onCommunicationUpdate={handleUpdate}
          />
        );
      }}
    </Formik>
  );
}

ViewCommunicationCardContainer.propTypes = {
  communicationId: PropTypes.number.isRequired,
  isDialog: PropTypes.bool,
  onClose: requiredIf(PropTypes.func, (props) => props.isDialog),
  startIsEditing: PropTypes.bool,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onRefer: PropTypes.func,
  onAttach: PropTypes.func,
  onForward: PropTypes.func,
  onReply: PropTypes.func,
  //The case of communication that was detached from the claim (and maybe attached to another claim)
  isCommunicationNotRelatedToClaimInContext: PropTypes.bool,
  displayAttachClaim: PropTypes.bool,
  displayGeneralQueueActions: PropTypes.bool,
  hideEmailCommunicationAction: PropTypes.bool,
  communication: PropTypes.shape({
    id: PropTypes.string,
    channel: PropTypes.string,
    claim_id: PropTypes.string,
  }),
};

function CommunicationActionEdit(props) {
  const classes = useStyles();

  const { isEditing, onCancelEdit, onSubmit, isSubmitting } = props;

  if (!isEditing) {
    return <></>;
  }

  return (
    <div className={classes.buttonsContainer}>
      <CancelButton disabled={isSubmitting} onClick={onCancelEdit} />
      <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitting}>
        Update
      </Button>
    </div>
  );
}

CommunicationActionEdit.propTypes = {
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancelEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function PhoneCallCommunicationActionView(props) {
  const { communication, isEditing } = props;

  if (communication.channel !== 'phone_call') {
    throw Error(`PhoneCallCommunicationActionView called with ${communication.channel}`);
  }

  if (!communication.is_no_answer || isEditing) {
    return <CommunicationActionEdit {...props} />;
  }

  return <></>;
}

PhoneCallCommunicationActionView.propTypes = {
  communication: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

export default ViewCommunicationCardContainer;
