import React from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import AdjusterSelectTextFieldFormik from '~/Adjuster/AdjusterSelectTextFieldFormik';
import { useStyles } from '~/assets/styles';
import { getAllOrganizationWideRoles } from '~/components/communications/ContactUtils';
import type { ContactFullModel } from '~/components/Contacts/types';
import ContactSearchContainerOutOfClaim from '~/components/ContactSearch/ContactSearchContainerOutOfClaim';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import type { MoiFormikValues, MoiStepProps } from '~/components/exposures/moi/MoiStepper/types';
import VendorContactSearchLabel from '~/components/exposures/moi/VendorContactSearchLabel';
import type { UserModel } from '~/components/exposures/types';
import useOrganization from '~/components/OrganizationContext';
import { getIsXactContractor } from '~/Utils';

const AssigneeSelectField: React.FC<MoiStepProps> = ({
  setSelectedContact,
  getIsContactVendorApi,
  setIsXactContractor,
  setIsVendorApi,
  selectedContact,
  moiMethodsReturn,
}) => {
  const { values, setFieldValue, setFieldTouched, setFieldError, isSubmitting } = useFormikContext<MoiFormikValues>();
  const {
    organizationContactRolesDict,
    organizationId,
  }: { organizationContactRolesDict?: { [key: string]: unknown }; organizationId?: number } = useOrganization();
  const { getMoiMethodExpertises } = moiMethodsReturn;
  const classes = useStyles();

  return (
    <div className="col-span-6 col-start-1 pt-5">
      <>
        {values['assignee_type'] === 'service_provider' && (
          <>
            <ContactSearchContainerOutOfClaim
              fixedSearchResults
              onSelectContact={async (contact: ContactFullModel) => {
                const isContactFitMoi = !isEmpty(
                  getMoiMethodExpertises(values['moi_method_id']).filter((expertise) =>
                    contact?.moi_expertises?.includes(expertise)
                  )
                );
                if (!isContactFitMoi) {
                  setFieldTouched('contact_id', true);
                  setFieldError('contact_id', 'Selected contact does not have relevant MOI expertises');
                  return;
                }

                const { data } = await getIsContactVendorApi(contact);
                setIsVendorApi(data?.is_vendor_api);
                if (!data?.is_vendor_api && ['completed', 'accepted'].includes(values['status'])) {
                  setFieldValue('status', null);
                  setFieldTouched('status', false);
                }

                const ans = await getIsXactContractor(contact, organizationId);
                setIsXactContractor(ans?.is_xactanalysis_contractor);

                setSelectedContact(contact);
                setFieldValue('contact_id', contact.id);
                setFieldTouched('contact_id', true);
              }}
              selectedContactId={selectedContact ? selectedContact.id : undefined}
              selectedContactDisplayName={selectedContact ? selectedContact.full_name : undefined}
              TextFieldProps={{
                size: 'small',
                label: <VendorContactSearchLabel disabled={false} />,
                disabled: !values['moi_method_id'],
              }}
              acceptedRoles={getAllOrganizationWideRoles(organizationContactRolesDict)}
              acceptedExpertises={getMoiMethodExpertises(values['moi_method_id'])}
              restrictAcceptedEntities
              textInputRef={undefined}
              overrideOfContactsContextSearchOrganizationId={undefined}
            />
            <ErrorHelperTextFormik className="mt-2" id="contact_id" />
          </>
        )}
        {values['assignee_type'] === 'internal_user' && (
          <>
            <AdjusterSelectTextFieldFormik
              id="user_id"
              label="Internal User"
              className={classes.textField}
              onChange={(_: unknown, adjuster: UserModel) => {
                setFieldValue('user_id', adjuster ? adjuster.id : '');
              }}
              fullWidth
              disabled={isSubmitting || !values['moi_method_id']}
              currentAdjusterMark=" (Assign to me)"
              sortAlphabetic
              includeDaSpecialist={undefined}
              excludedAdjustersIds={undefined}
              allowedAdjustersIds={undefined}
              showOnly={undefined}
              extraValues={undefined}
              allUsers={undefined}
              checkLicenses={undefined}
            />
          </>
        )}
      </>
    </div>
  );
};

export default AssigneeSelectField;
