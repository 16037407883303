import React from 'react';

import EmailCommunicationPopover from '~/components/Contacts/ContactCommunications/Email/EmailCommunicationPopover';
import type { ContactSubDetailsPropsType } from '~/components/Contacts/ContactMiniCard/types';
import Text from '~/components/core/TextComponents/Text';
import { MIXPANEL_EVENT_SOURCES } from '~/pocs/mixpanel';

const Emails: React.FC<ContactSubDetailsPropsType> = ({ contact, onUpdate, actionsEnabled }) => {
  return (
    <>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
        Emails
      </Text>
      {contact.emails.map((contactEmail) => (
        <div className="my-5 flex items-center" key={contactEmail.id}>
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="grow">
            {contactEmail.email}
          </Text>
          {actionsEnabled ? (
            <EmailCommunicationPopover
              contact={contact}
              onUpdate={onUpdate}
              communicationDetails={contactEmail}
              mixpanelSource={MIXPANEL_EVENT_SOURCES.CONTACT_MINI_CARD_NEW_EMAIL_COMMUNICATION_CLICKED}
              ignorePermissions={true}
            />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default Emails;
