import React from 'react';
import { Switch, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import { find } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import EmptyState from '~/components/core/EmptyState';
import StatusChip from '~/components/core/StatusChip';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { serverDateToLocal } from '../../../../DateTimeUtils';
import { getLobDescription } from '../../../../Utils/lobUtils';
import CardDialog from '../../../CardDialog';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsButtonWrapper, TooltipIcon } from '../../../core';
import { ThreeDotsHorizontalIcon } from '../../../icons';
import LoadingIndicator from '../../../LoadingIndicator';
import useOrganization from '../../../OrganizationContext';
import PlainTable from '../../../PlainTable';
import useDataFetcher from '../../../useDataFetcher';

import QaQueryDialog from './Dialog';
import NewQaQueryDialog from './NewQaQuery';

import { useStyles } from '../../../../assets/styles';

const QaSavedQueries = () => {
  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: qaSavedQueries,
    reloadData: reloadQaQueries,
  } = useDataFetcher('/api/v1/qa_queries');
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const [isCompletedChecked, setIsCompletedChecked] = React.useState(false);
  const [qaSavedQueryInFocusId, setQaSavedQueryInFocusId] = React.useState();
  const [newQaQueryDialogOpen, setNewQaQueryDialogOpen] = React.useState(false);
  const unCompletedQueries = qaSavedQueries?.filter((query) => query.datetime_completed === null);
  let displayedQueries = isCompletedChecked ? qaSavedQueries : unCompletedQueries;
  const { lobConfigurationsDict } = useLobConfiguration();

  const qaSavedQueryInFocus = qaSavedQueryInFocusId
    ? displayedQueries.find((qaSavedQuery) => qaSavedQuery.id === qaSavedQueryInFocusId)
    : undefined;

  const columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'query_name',
      label: 'Name',
      specialCell: (savedQuery) => savedQuery.query_name,
    },
    {
      id: 'datetime_created',
      label: 'Date Created',
      specialCell: (savedQuery) => serverDateToLocal(savedQuery.datetime_created),
    },
    {
      id: 'count',
      label: 'Exposures Count',
      specialCell: (savedQuery) => savedQuery.entries.length,
    },
    {
      id: 'sub_organization',
      label: 'Sub Organization',
      isHidden: !subOrganizationEnabled,
      specialCell: (savedQuery) => getSubOrganizationName(savedQuery.filters.sub_organization_id),
    },
    {
      id: 'lob',
      label: 'Line of Business',
      specialCell: (savedQuery) => getLobDescription(savedQuery.filters.claim_type, lobConfigurationsDict),
    },
    {
      id: 'incomplete',
      label: 'Incomplete',
      specialCell: (savedQuery) => ratioOfQueriesStatus(savedQuery.entries, 'Incomplete'),
    },
    {
      id: 'submitted',
      label: 'Submitted',
      specialCell: (savedQuery) => ratioOfQueriesStatus(savedQuery.entries, 'Submitted'),
    },
    {
      id: 'status',
      label: 'Query Status',
      specialCell: (savedQuery) =>
        savedQuery.datetime_completed ? (
          <StatusChip status={StatusChip.STATUSES.COMPLETED} />
        ) : (
          <StatusChip status={StatusChip.STATUSES.IN_PROGRESS} />
        ),
    },
    {
      id: 'actions',
      label: 'Actions',
      specialCell: (savedQuery) => (
        <TooltipIcon title="View more details">
          <ThreeDotsHorizontalIcon
            style={{ cursor: 'pointer' }}
            className={classes.hoverableIcon}
            onClick={(e) => {
              e.preventDefault();
              setQaSavedQueryInFocusId(savedQuery.id);
            }}
          />
        </TooltipIcon>
      ),
    },
  ];

  const getSubOrganizationName = (subOrganizationId) => {
    if (!subOrganizationId || subOrganizationId === '') {
      return;
    }
    return find(subOrganizations, ({ id }) => id === subOrganizationId)?.name;
  };

  const ratioOfQueriesStatus = (entries, status) => {
    let filteredExposures = [];
    if (status === 'Incomplete') {
      filteredExposures = entries.filter((entry) => !entry.qa_status || entry.qa_status === 'In Progress');
    } else {
      filteredExposures = entries.filter((entry) => entry.qa_status === status);
    }
    return filteredExposures.length.toString() + '/' + entries.length.toString();
  };

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const handleUpdate = async function () {
    await reloadQaQueries();
  };

  return (
    <>
      <div className={classes.cardDivRow}>
        {qaSavedQueries.length > 0 ? (
          <div className={classes.cardDivRow}>
            <Typography variant="h5">QUERIES</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isCompletedChecked}
                  className={classes.formsSwitch}
                  id="show_completed_queries"
                  onChange={() => setIsCompletedChecked(!isCompletedChecked)}
                />
              }
              label="Show Completed Queries"
            />
            <CardDialog
              title="Saved Queries"
              action={
                <PermissionsButtonWrapper verb={PERMISSION_VERBS.WRITE} action={PERMISSION_ACTIONS.QA}>
                  <Button className={classes.primaryLink} color="primary" onClick={() => setNewQaQueryDialogOpen(true)}>
                    <AddIcon className={classes.leftButtonIcon} />
                    Create New QA Query
                  </Button>
                </PermissionsButtonWrapper>
              }
            >
              <PlainTable rows={displayedQueries} columns={columnData} />
            </CardDialog>
          </div>
        ) : (
          <EmptyState
            title="No saved queries were created yet"
            buttonComponent={
              <Button variant="contained" color="primary" onClick={() => setNewQaQueryDialogOpen(true)}>
                <span style={{ display: 'flex' }}>
                  <AddIcon className={classes.leftButtonIcon} />
                  Create New QA Query
                </span>
              </Button>
            }
          />
        )}
      </div>
      {newQaQueryDialogOpen && (
        <NewQaQueryDialog
          onClose={() => setNewQaQueryDialogOpen(false)}
          onQaQuerySaved={async (newQaQuery) => {
            await reloadQaQueries();
            setQaSavedQueryInFocusId(newQaQuery.id);
            setNewQaQueryDialogOpen(false);
          }}
        />
      )}
      {qaSavedQueryInFocus && (
        <QaQueryDialog
          onClose={() => setQaSavedQueryInFocusId(undefined)}
          qaQueryResults={qaSavedQueryInFocus}
          onUpdate={() => {
            handleUpdate();
          }}
        />
      )}
    </>
  );
};

export default QaSavedQueries;
