import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MmsIcon from '@material-ui/icons/Mms';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import CommunicationAttachmentTable from '~/components/communications/CommunicationAttachmentTable';
import { CommunicationReminderContainer } from '~/components/communications/CommunicationCard';
import {
  getAttachmentFileUrl,
  MAX_SMS_LENGTH,
  SMS_FORM_FORMIK_IDS,
} from '~/components/communications/SmsCommunicationCard/utils';
import InlineIconButton from '~/components/InlineIconButton';
import TextFieldFormik from '~/components/TextFieldFormik';
import { serverDateTimeToLocal } from '~/DateTimeUtils';

const SmsContentTextFieldFormik = (props) => {
  const { isView, isNew, communication, onUpdate } = props;
  const { values } = useFormikContext();
  const classes = useStyles();
  const messagesDivRef = React.useRef();
  const currentMessageDivRef = React.useRef();
  const maxAttachmentsHeight = '200px';
  const shouldShowSmsThread = !!(isView && communication && communication.thread);

  React.useEffect(() => {
    if (shouldShowSmsThread) {
      messagesDivRef.current.scrollTo({
        top:
          currentMessageDivRef.current.offsetTop -
          messagesDivRef.current.clientHeight / 2 +
          currentMessageDivRef.current.clientHeight,
      });
      currentMessageDivRef.current.classList.add(classes.smsMessageSelected);
    }
  }, [classes.smsMessageSelected, shouldShowSmsThread]);

  let communicationAttachments = [];
  if (isView && communication) {
    communicationAttachments = communication.thread.reduce((acc, message) => [...acc, ...message.attachments], []);
  }

  return (
    <>
      {isNew && (
        <TextFieldFormik
          id={SMS_FORM_FORMIK_IDS.MESSAGE_CONTENT}
          label="Message"
          className={classes.textField}
          fullWidth
          multiline
          rows="5"
          inputProps={{ maxLength: MAX_SMS_LENGTH }}
          showOnly={isView}
        />
      )}
      {shouldShowSmsThread && (
        <div
          ref={messagesDivRef}
          style={{ position: 'relative', maxHeight: '300px', overflow: 'auto', margin: '16px 0' }}
        >
          {communication.thread.map((message) => {
            const contactIdentifier = isEmpty(communication.contact)
              ? communication.specific_identifier
              : communication.contact.full_name;
            const writer = message.direction === 'Incoming' ? contactIdentifier : communication.adjuster;
            return (
              <div
                key={message.id}
                className={classes.textField}
                ref={message.id === communication.id ? currentMessageDivRef : undefined}
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: '10px',
                  borderColor: 'grey',
                  padding: '4px',
                }}
              >
                <span style={{ marginTop: '8px' }}>
                  <Typography variant="caption">
                    <strong>{writer}</strong>
                    {` at ${serverDateTimeToLocal(message.datetime)}:`}
                  </Typography>
                </span>
                <Typography display="block" variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                  {message.message}
                </Typography>
                {message.is_attachment_exist &&
                  message.attachments.map((attachment) => (
                    <InlineIconButton
                      key={attachment.id}
                      icon={MmsIcon}
                      onClick={() => window.open(getAttachmentFileUrl(attachment), '_target')}
                    />
                  ))}
              </div>
            );
          })}
        </div>
      )}
      {!isView && (
        <Typography
          display="block"
          variant="caption"
        >{`${values.message_content.length}/${MAX_SMS_LENGTH}`}</Typography>
      )}
      {isView && communication && communicationAttachments.length > 0 && (
        <CommunicationAttachmentTable
          communication={{ ...communication, attachments: communicationAttachments }}
          communicationType="sms"
          onUpdate={onUpdate}
          maxHeight={maxAttachmentsHeight}
          getAttachmentFileUrl={getAttachmentFileUrl}
        />
      )}
      {isNew && <CommunicationReminderContainer />}
    </>
  );
};

SmsContentTextFieldFormik.propTypes = {
  communication: PropTypes.object,
  onUpdate: requiredIf(PropTypes.func, (props) => props.communication),
  isView: PropTypes.bool,
  isNew: PropTypes.bool,
};

export default SmsContentTextFieldFormik;
