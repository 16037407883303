import React from 'react';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment/moment';

import type { TextFieldProps } from '~/components/core/Molecules/Fields/TextField/TextField';

export interface DatePickerFieldProps extends Omit<TextFieldProps, 'onChange'> {
  format?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  containerClassName?: string;
  placeholder?: string;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  onError?: (error: React.ReactNode) => void;
  clearable?: boolean;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  containerClassName,
  value,
  helperText,
  error,
  onError,
  onBlur,
  format,
  placeholder,
  onChange,
  ...rest
}) => {
  return (
    <div className={containerClassName}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          format={format ? format : moment.localeData().longDateFormat('L')}
          value={value ? value : null}
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange}
          animateYearScrolling={false}
          onBlur={onBlur}
          error={error}
          onError={onError}
          helperText={helperText}
          InputProps={{
            margin: 'dense', // to match other TextField components
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePickerField;
export type PickerDate = MaterialUiPickersDate;
