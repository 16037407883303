import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { isProductionEnv } from '../../../../Utils';

const FsTooltip = ({ children, title, placement, arrow, disabled }) => {
  if (Array.isArray(children) || !React.isValidElement(children)) {
    if (!isProductionEnv()) {
      console.warn('FsTooltip: tooltip children should be a single node');
    }
    return children;
  }
  return (
    <Tooltip open={!title || disabled ? false : undefined} title={title ?? ''} placement={placement} arrow={arrow}>
      <span>{children}</span>
    </Tooltip>
  );
};

// TODO update placement option after merging the FsButton PR NGTPA-6010
FsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  placement: PropTypes.string,
  arrow: PropTypes.bool,
  disabled: PropTypes.bool,
};

FsTooltip.defaultProps = {
  placement: 'top',
};

export default FsTooltip;
