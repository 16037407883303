import React from 'react';

type VoidCallback = () => void;
type VoidAsyncCallback = () => Promise<void>;

export const useInterval = (
  callback: VoidCallback | VoidAsyncCallback | undefined,
  delay: number
): {
  start: VoidCallback;
  stop: VoidCallback;
} => {
  const savedCallback = React.useRef<VoidCallback | VoidAsyncCallback>();

  const intervalRef = React.useRef<number>();

  React.useEffect(() => {
    savedCallback.current = callback;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [callback]);

  const start = () => {
    if (savedCallback.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(async () => {
        savedCallback.current && (await savedCallback.current());
      }, delay) as unknown as number;
    }
  };

  const stop = () => {
    clearInterval(intervalRef.current);
  };

  return { start, stop };
};
