import type { ReactNode } from 'react';
import React from 'react';

import FsButton from '../FsWrappers/FsButton/FsButton';

export interface MessageWithIllustrationProps {
  illustration: ReactNode;
  title: string;
  text: string;
  btnText: string;
  btnOnClick: () => void;
  footer: ReactNode;
}

const MessageWithIllustration: React.FC<MessageWithIllustrationProps> = ({
  illustration,
  title,
  text,
  btnText,
  btnOnClick,
  footer,
}) => (
  <div className="flex flex-col items-center p-40">
    {illustration}
    <div className="mb-10 mt-20 text-3xl font-semibold">{title}</div>
    <pre className="text- mb-20 text-center font-serif text-base font-medium text-slate-800">{text}</pre>
    {btnText && (
      <div className="w-100 flex flex-col">
        <FsButton onClick={btnOnClick} color={FsButton.COLORS.primary} variant={FsButton.VARIANTS.contained}>
          {btnText}
        </FsButton>
      </div>
    )}
    {footer && <div className="mt-20">{footer}</div>}
  </div>
);
export default MessageWithIllustration;
