import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import OptionChips from '../OptionChips';

import { useStyles } from '../../../assets/styles';
import styles from './MultiSelectFilter.module.scss';

function MultiSelectFilter({
  onChange,
  options,
  value,
  label,
  allOptionsLabel,
  enableAllOptionsSelection,
  withOptionChips,
  renderOption,
  renderValue,
  className,
  renderOptionDisabled = () => false,
  renderChipLabel,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const activeColor = theme.palette.primary.darker;

  const removeSingleValueFromSelectedOptions = (values, removedValue) => {
    const indexValue = values.indexOf(removedValue);
    values.splice(indexValue, 1);
    onChange([...values]);
  };

  const handleRenderValue = (selectedValues) => {
    if (renderValue) {
      return renderValue(selectedValues);
    } else {
      return withOptionChips ? '' : selectedValues.join(', ');
    }
  };

  const getChipLabels = () => {
    if (renderChipLabel) {
      return value.map((v) => [v, renderChipLabel(v)]);
    }
    return value.map((v) => [v, renderOption ? renderOption(v) : v]);
  };

  return (
    <div className={className ?? styles.defaultContainer}>
      <InputLabel className={classes.textFieldRow} style={{ opacity: '0.5', fontSize: '12px', width: '220px' }}>
        {label}
      </InputLabel>
      <Select
        onChange={(event) => {
          const val =
            enableAllOptionsSelection && event.target.value.includes(allOptionsLabel) ? [] : event.target.value;
          onChange(val);
        }}
        label={label}
        className={classes.textFieldRow}
        style={{ width: '220px' }}
        value={value}
        multiple
        autoWidth
        renderValue={handleRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {enableAllOptionsSelection && (
          <MenuItem key="all_option" value={allOptionsLabel}>
            <Checkbox checked={value.length === 0} style={{ color: value.length === 0 ? activeColor : '#707070' }} />
            <ListItemText primary={allOptionsLabel} />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option} value={option} disabled={renderOptionDisabled(option)}>
            <Checkbox
              checked={value.indexOf(option) > -1}
              style={{ color: value.indexOf(option) > -1 ? activeColor : '#707070' }}
            />
            <ListItemText primary={renderOption ? renderOption(option) : option} />
          </MenuItem>
        ))}
      </Select>
      {withOptionChips && (
        <OptionChips
          values={getChipLabels()}
          onChange={(removedValue) => removeSingleValueFromSelectedOptions(value, removedValue)}
          disabledChips={options.filter((o) => renderOptionDisabled(o))}
        />
      )}
    </div>
  );
}

MultiSelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.node.isRequired,
  allOptionsLabel: PropTypes.string,
  enableAllOptionsSelection: PropTypes.bool,
  withOptionChips: PropTypes.bool,
  renderOption: PropTypes.func,
  renderValue: PropTypes.func,
  renderOptionDisabled: PropTypes.func,
  className: PropTypes.string,
  renderChipLabel: PropTypes.func,
};

export default MultiSelectFilter;
