import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import RadioWithButtonWrapperFormik from '~/components/core/Formik/RadioWithButtonWrapperFormik';
import ViewOnlyRadioButtonFormik from '~/components/core/Formik/ViewOnlyRadioButtonFormik';

import RadioButtonFormik from '../../../RadioButtonFormik';
import { ErrorHelperTextFormik } from '../ErrorHelperTextFormik';

const DEFAULT_TYPOGRAPHY_VARIANT = 'body2';

const BUTTON_VARIANTS = {
  WRAPPED: 'wrapped',
  DEFAULT: 'default',
};

const RadioButtonGroupFormik = ({
  id,
  label,
  options,
  direction = 'row',
  showOnly,
  noSpacing = false,
  spacing = 1,
  className,
  btnClassName,
  labelClassName,
  btnLabelClassName,
  variant,
  btnContainerClassName,
  onClick = noop,
  buttonVariant = BUTTON_VARIANTS.WRAPPED,
  withErrorHelper = true,
  viewOnly,
  allbtnsContainerClassName,
  sizeButtonsEvenly,
}) => {
  const { setFieldTouched } = useFormikContext();

  const handleClick = (optionValue, e) => {
    setFieldTouched(id, true);
    onClick(optionValue, e);
  };

  const getSizeButtonsEvenlyGridWidth = () => {
    if (12 / options.length < 3) {
      return 3;
    }
    return Math.floor(12 / options.length);
  };

  return (
    <div className={className}>
      {label && (
        <Typography
          variant={variant ? variant : DEFAULT_TYPOGRAPHY_VARIANT}
          style={{ marginBottom: '12px' }}
          className={labelClassName}
        >
          {label}
        </Typography>
      )}
      <Grid container direction={direction} spacing={noSpacing ? 0 : spacing} className={allbtnsContainerClassName}>
        {options.map((option) => (
          <Grid
            item
            key={option.optionValue}
            style={option.isFullWidth || sizeButtonsEvenly ? { marginBottom: '16px' } : {}}
            className={btnContainerClassName}
            xs={sizeButtonsEvenly ? getSizeButtonsEvenlyGridWidth() : undefined}
          >
            {viewOnly && <ViewOnlyRadioButtonFormik id={id} optionValue={option.optionValue} label={option.text} />}
            {!viewOnly && buttonVariant === BUTTON_VARIANTS.WRAPPED && (
              <RadioWithButtonWrapperFormik
                key={'button-' + option.optionValue}
                id={id}
                text={option.text}
                value={option.optionValue}
                disabled={option.disabled || showOnly}
                icon={option.icon}
                iconLocation={option.iconLocation || 'start'}
                wrapperOverrideStyle={
                  option.isFullWidth || sizeButtonsEvenly ? { width: '100%', justifyContent: 'left' } : {}
                }
                className={btnClassName}
                labelClassName={btnLabelClassName}
                onClick={(e) => handleClick(option.optionValue, e)}
              />
            )}
            {!viewOnly && buttonVariant === BUTTON_VARIANTS.DEFAULT && (
              <RadioButtonFormik
                key={'button-' + option.optionValue}
                id={id}
                label={option.text}
                optionValue={option.optionValue}
                labelClassName={btnLabelClassName}
                disabled={option.disabled || showOnly}
                onClick={(e) => handleClick(option.optionValue, e)}
              />
            )}
            <Typography variant="caption">{option.note}</Typography>
          </Grid>
        ))}
      </Grid>
      {withErrorHelper && <ErrorHelperTextFormik id={id} />}
    </div>
  );
};

RadioButtonGroupFormik.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      optionValue: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      iconLocation: PropTypes.oneOf(['start', 'end']),
      note: PropTypes.string,
      isFullWidth: PropTypes.bool,
    })
  ),
  id: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  label: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  noSpacing: PropTypes.bool,
  spacing: PropTypes.number,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  btnLabelClassName: PropTypes.string,
  variant: PropTypes.string,
  btnContainerClassName: PropTypes.string,
  allbtnsContainerClassName: PropTypes.string,
  onClick: PropTypes.func,
  buttonVariant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  withErrorHelper: PropTypes.bool,
  viewOnly: PropTypes.bool,
  sizeButtonsEvenly: PropTypes.bool,
};

RadioButtonGroupFormik.BUTTON_VARIANT = BUTTON_VARIANTS;

export default RadioButtonGroupFormik;
