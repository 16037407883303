import React from 'react';
import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';

export default function CheckDescriptionContainer() {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { shouldUseCheckDescription } = usePaymentsConfiguration(values.payment_method);
  const { note, invoice_number } = values;

  let check_description = note;
  if (invoice_number) {
    check_description += ' Invoice #: ' + invoice_number;
  }

  React.useEffect(() => {
    setFieldValue('check_description', check_description);
    setFieldTouched('check_description', true);
  }, [check_description, setFieldValue, setFieldTouched]);

  // For backward compatibility we want to update check_description even if we don't display it
  if (!shouldUseCheckDescription) {
    return <></>;
  }

  return (
    <>
      {check_description && (
        <Typography style={{ marginTop: '8px' }} variant="caption" display="block">
          {'Check description: ' + check_description}
        </Typography>
      )}
      <ErrorHelperTextFormik id="check_description" />
    </>
  );
}
