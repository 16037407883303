import React from 'react';

import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import { OptionsSelectFieldWithOtherText } from '~/components/LegalActions/components/OptionsSelectFieldWithOtherText';
import { DATE_OF_SERVICE_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import {
  ArrayFieldFormPropTypes,
  getArrayFieldKey,
} from '~/components/LegalActions/FormFragments/LegalActionsFormUtils';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import useOrganization from '~/components/OrganizationContext';
import { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';

export const DateOfServiceForm = ({ arrayFieldPrefix, index }) => {
  const { serviceTypeOptions } = useLegalActionsConfiguration();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <>
      <div className="flex gap-30">
        <div className="flex w-1/2">
          <DatePickerTextFieldFormik
            containerClassName="w-full"
            fullWidth
            id={getArrayFieldKey(DATE_OF_SERVICE_FIELDS.dateOfService.key, arrayFieldPrefix, index)}
            label={DATE_OF_SERVICE_FIELDS.dateOfService.label}
            nullIfEmpty
          />
        </div>
        <div className="flex w-1/2">
          {/* This is hard coded because the contact component will compute the name of the field where the contact id is set */}
          <ContactTextFieldFormik
            id={getArrayFieldKey('contact', arrayFieldPrefix, index)}
            label={DATE_OF_SERVICE_FIELDS.contact.label}
            acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
            fixedSearchResults={!arrayFieldPrefix}
            fullWidth
            nullIfEmpty
          />
        </div>
      </div>
      <OptionsSelectFieldWithOtherText
        controllingFieldKey={getArrayFieldKey(DATE_OF_SERVICE_FIELDS.serviceType.key, arrayFieldPrefix, index)}
        controllingFieldLabel={DATE_OF_SERVICE_FIELDS.serviceType.label}
        controllingFieldOptions={serviceTypeOptions}
        controlledFieldKey={getArrayFieldKey(DATE_OF_SERVICE_FIELDS.otherText.key, arrayFieldPrefix, index)}
        controlledFieldLabel={DATE_OF_SERVICE_FIELDS.otherText.label}
      />
    </>
  );
};

DateOfServiceForm.propTypes = ArrayFieldFormPropTypes;
