import type React from 'react';

import type { MinimizedType } from '~/components/core/MinimizedBar/types';
import { CreditCardIcon } from '~/components/icons';
import EmailOutgoingIcon from '~/components/icons/communication/EmailOutgoingIcon';
import MessageOutgoingIcon from '~/components/icons/communication/MessageOutgoingIcon';
import NoteIcon from '~/components/icons/NoteIcon';
import type { IconProps } from '~/components/icons/types';

import PhoneOutgoingIcon from '../../icons/communication/PhoneOutgoingIcon';

interface TypeSettings {
  bgColor: string;
  textColor: string;
  icon: React.FC<IconProps>;
}

export const typeToSettings: Record<MinimizedType, TypeSettings> = {
  PHONE: {
    bgColor: 'bg-green-700',
    textColor: 'text-green-700',
    icon: PhoneOutgoingIcon as React.FC<IconProps>,
  },
  EMAIL: {
    bgColor: 'bg-pink-800',
    textColor: 'text-pink-800',
    icon: EmailOutgoingIcon as React.FC<IconProps>,
  },
  SMS: {
    bgColor: 'bg-orange-700',
    textColor: 'text-orange-700',
    icon: MessageOutgoingIcon,
  },
  NOTE: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: NoteIcon as React.FC<IconProps>,
  },
  PAYMENT: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: CreditCardIcon as React.FC<IconProps>,
  },
};
