import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SIZE_TYPES = {
  XL: 'xl',
  LG: 'large',
  SM: 'small',
};

const SIZE_TO_PX = {
  [SIZE_TYPES.XL]: 40,
  [SIZE_TYPES.LG]: 32,
  [SIZE_TYPES.SM]: 24,
};

const useStyles = makeStyles(() => ({
  root: ({ size }) => ({
    fontWeight: 600,
    fontSize: SIZE_TO_PX[size],
  }),
}));

const Display = ({ variant = SIZE_TYPES.LG, children }) => {
  const displayClasses = useStyles({ size: variant });

  return <Typography className={displayClasses.root}>{children}</Typography>;
};

Display.propTypes = {
  variant: PropTypes.oneOf(Object.values(SIZE_TYPES)),
  children: PropTypes.node.isRequired,
};

Display.TYPES = SIZE_TYPES;

export default Display;
