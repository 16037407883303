import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocal } from '../../DateTimeUtils';
import { getPolicyStatusDuringLoss, getPolicyStatusSpan } from '../../PolicyUtils';
import { PET_PERILS_DICT } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { SiuClaimSummaryChip } from '../ClaimSummary';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import InsuredInfoVerificationCard from '../Fnol/InsuredInfoVerificationCard';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import { usePolicy } from '../PolicyContainer';
import {
  DatePickerTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const petIncidentDetailsFields = {
  date_of_loss: '',
  time_of_loss: '',
  reporter_id: '',
  primary_contact_id: '',
  description: '',
  verified_insured_info: false,
  organization_external_id: '',
};

const petIncidentDetailsValidationFields = {
  date_of_loss: Yup.date().required('Required'),
  time_of_loss: Yup.string().matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied'),
  reporter_id: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  primary_contact_id: Yup.string().required('Required'),
  verified_insured_info: Yup.boolean().oneOf([true], 'Must verify insured info'),
  organization_external_id: Yup.string(),
};

function PetIncidentDetailsFormikInner() {
  const spacing = 1;
  const classes = useStyles();
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const [showInsuredVerificationCard, setShowInsuredVerificationCard] = useState(false);
  const insuredDetailsVerified = getIn(values, 'verified_insured_info');
  const { policy } = usePolicy();
  const policyStatusDuringLoss = values.date_of_loss
    ? getPolicyStatusDuringLoss(policy, values.date_of_loss, values.time_of_loss)
    : null;

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          insuredDetailsVerified ? (
            <Button onClick={() => setShowInsuredVerificationCard(true)}>Insured info is verified &gt;</Button>
          ) : (
            <>
              <Button
                color="secondary"
                onClick={() => {
                  setShowInsuredVerificationCard(true);
                }}
              >
                <ErrorIcon />
                Verify insured info &gt;
              </Button>
              <ErrorHelperTextFormik id="verified_insured_info" />
            </>
          )
        }
      >
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="date_of_loss"
              label="Date of Incident"
              className={classes.textField}
              fullWidth
              disableFuture
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="time_of_loss"
              label="Time of Incident"
              fullWidth
              className={classes.textField}
              disabled={isSubmitting}
            />
          </Grid>

          {getPolicyStatusSpan(policyStatusDuringLoss, policy)}

          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="reporter"
              label="Reporter"
              className={classes.textField}
              acceptedRoles={[
                'insured',
                'agent',
                'claimant',
                'spouse',
                'family_member',
                'public_adjuster',
                'insurer',
                'attorney',
                'other',
              ]}
              contactSearchProps={{ newContactRole: 'claimant' }}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="primary_contact"
              label="Preferred Contact"
              acceptedRoles={['insured', 'spouse', 'family_member', 'public_adjuster', 'attorney', 'agent', 'other']}
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik id="peril" select label="Peril" className={classes.textField} fullWidth>
              {Object.keys(PET_PERILS_DICT).map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {PET_PERILS_DICT[option].desc}
                  </MenuItem>
                );
              })}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="organization_external_id"
              label="Partner Identifier"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="description"
              label="Incident description"
              className={classes.textField}
              fullWidth
              multiline
              rows="8"
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </CardDialog>
      {showInsuredVerificationCard && (
        <InsuredInfoVerificationCard
          insuredContactId={values.insured_contact.id}
          onDetailsVerified={() => {
            setFieldValue('verified_insured_info', true);
          }}
          onClose={() => setShowInsuredVerificationCard(false)}
        />
      )}
    </>
  );
}

PetIncidentDetailsFormikInner.propTypes = {};

function OldPetIncidentDetailsCard(props) {
  const classes = useStyles();
  const { claim, onUpdate, readOnly } = props;
  const [isFetching, setIsFetching] = useState(false);

  const { incident } = claim;
  const disabled = readOnly || isFetching;

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const handleUpdateFields = async (updateObject) => {
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/pet_claims/${claim.id}`, updateObject);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsFetching(false);
  };

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && <IncidentMoreActionsContainer incident={incident} onUpdateFields={handleUpdateIncidentFields} />
        }
      >
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        <IncidentMutualChips claim={claim} />
        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={12}>
            <SelectHoverChangeField
              label="Peril"
              fieldId="peril"
              value={claim.incident.peril}
              keys={Object.keys(PET_PERILS_DICT)}
              displayValueFunc={(key) => PET_PERILS_DICT[key].desc}
              disabled={disabled}
              onUpdate={async (values) => await handleUpdateIncidentField('peril', values.peril)}
              width="300px"
              overrideOnEdit
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={PET_PERILS_DICT[claim.incident.peril].desc}
                label="Peril"
                disabled={disabled}
              />
            </SelectHoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocal(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="organization_external_id"
              value={claim.organization_external_id}
              label="Partner Identifier"
              onUpdate={async (updatedValues) =>
                await handleUpdateClaimField('organization_external_id', updatedValues['organization_external_id'])
              }
              fullWidth
              width="300px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.organization_external_id}
                label="Partner Identifier"
                maxHeight="20em"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12} style={{ height: '250px' }}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={8}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="20em"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
}

OldPetIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export {
  OldPetIncidentDetailsCard,
  petIncidentDetailsFields,
  PetIncidentDetailsFormikInner,
  petIncidentDetailsValidationFields,
};
