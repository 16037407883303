import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../CardDialog';
import { SuccessIllustration } from '../../illustrations';

import { useStyles } from '../../../assets/styles';
import styles from './SuccessMessageDialog.module.scss';

const SuccessMessageDialog = ({ onClose, title = 'Success', subtitle, open = true }) => {
  const classes = useStyles();

  return (
    <CardDialog isDialog onClose={onClose} fullWidth maxWidth="sm" noCardTitle trackAlt="Success Message" open={open}>
      <Grid className={styles.successMessageContainer}>
        <div className={styles.content}>
          <Grid item xs={12}>
            <SuccessIllustration />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={styles.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{subtitle}</Typography>
          </Grid>
        </div>
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={onClose}>
              OK
            </Button>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
};

SuccessMessageDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default SuccessMessageDialog;
