import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import PropTypes from 'prop-types'; // ES6

import 'react-quill/dist/quill.snow.css';

// all options:
// const modules = {
//   'toolbar': [
//     [{ 'font': [] }, { 'size': [] }],
//     [ 'bold', 'italic', 'underline', 'strike' ],
//     [{ 'color': [] }, { 'background': [] }],
//     [{ 'script': 'super' }, { 'script': 'sub' }],
//     [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
//     [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
//     [ 'direction', { 'align': [] }],
//     [ 'link', 'image', 'video', 'formula' ],
//     [ 'clean' ]
//   ]
// };

const modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const ReactQuillWrapper = ({ autoFocus, value, onChange, disabled }) => {
  const quillRef = useRef();
  const [alreadyFocused, setAlreadyFocused] = useState(false);
  // replace <p> with <div> for new lines
  const Parchment = Quill.import('parchment');
  const Block = Parchment.query('block');
  Block.tagName = 'div';
  Quill.register(Block, true);

  useEffect(() => {
    if (autoFocus && quillRef.current && !alreadyFocused) {
      quillRef.current.focus();
      setAlreadyFocused(true);
    }
  }, [quillRef, autoFocus, alreadyFocused]);

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      ref={quillRef}
      value={value}
      onChange={onChange}
      readOnly={disabled}
      className="max-h-[40vh] overflow-auto"
    />
  );
};

ReactQuillWrapper.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default ReactQuillWrapper;
