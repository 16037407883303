import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import axios from 'axios';
import { Formik, setIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import RadioWithButtonWrapperFormik from '~/components/core/Formik/RadioWithButtonWrapperFormik';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { COUNTRY_TO_STATE_MAP, OSHA_300_CLASSIFICATION_OPTIONS, OSHA_300_INJURY_OPTIONS } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import PdfPreviewDialog from '../Documents/PdfPreviewCard';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik, TimePickerTextFieldFormik } from '../TextFieldFormik';
import UsStatesSelectTextFieldFormik from '../UsStatesSelectTextFieldFormik';

import { useStyles } from '../../assets/styles';

const contactTypes = { addManually: 'add_manually', fromContacts: 'from_contacts' };

const getFieldsInitialValuesFor301 = (claim) => {
  const {
    claim_id_display,
    incident,
    employee_party,
    physician_or_health_care_name,
    physician_or_health_care_name_contact,
    physician_or_health_care_name_contact_type,
    physician_or_health_care_facility,
    physician_or_health_care_facility_contact,
    physician_or_health_care_facility_contact_type,
    physician_or_health_care_location,
    physician_or_health_care_emergency_room_treatment,
    physician_or_health_care_hospitalization_overnight,
    case_info_work_beginning_time,
    case_info_is_time_determent,
    case_info_pre_event_description,
    case_info_event_description,
    case_info_injury_description,
    was_treatment_given_away_from_worksite,
    case_info_harming_object_or_substance,
    case_info_death_date,
  } = claim;
  const { date_of_loss, time_of_loss } = incident;
  const { full_name, street_address1, city, state, zipcode, date_of_birth, contact_extra, sex } =
    employee_party.involved_person.contact;
  const { date_hired } = employee_party.involved_person;
  const Gender = contact_extra?.employee_contact_orig?.Gender || sex || '';

  return {
    employeeInfo: {
      fullName: full_name,
      street: street_address1,
      city,
      state,
      zipcode,
      dateOfBirth: date_of_birth,
      dateHired: date_hired,
      gender: Gender.toLowerCase(),
    },
    physicianOrHealthCare: {
      wasTreatmentGivenAwayFromWorksite: was_treatment_given_away_from_worksite || false,
      name: physician_or_health_care_name || '',
      physician_contact_id: physician_or_health_care_name_contact?.id,
      physician_contact_full_name: physician_or_health_care_name_contact?.full_name,
      name_contact_type:
        physician_or_health_care_name_contact_type ||
        (physician_or_health_care_name ? contactTypes.addManually : contactTypes.fromContacts),
      facility: physician_or_health_care_facility || '',
      facility_contact_id: physician_or_health_care_facility_contact?.id,
      facility_contact_full_name: physician_or_health_care_facility_contact?.full_name,
      facility_contact_type:
        physician_or_health_care_facility_contact_type ||
        (physician_or_health_care_facility ? contactTypes.addManually : contactTypes.fromContacts),
      street: physician_or_health_care_location?.address1 || '',
      city: physician_or_health_care_location?.city || '',
      state: physician_or_health_care_location?.state || '',
      zip: physician_or_health_care_location?.zipcode || '',
      emergencyRoomTreatment: physician_or_health_care_emergency_room_treatment || false,
      hospitalizationOvernight: physician_or_health_care_hospitalization_overnight || false,
    },
    caseInfo: {
      caseNumber: claim_id_display,
      injuryDate: date_of_loss,
      workBeginningTime: case_info_work_beginning_time || '',
      eventTime: time_of_loss || '',
      isTimeDeterment: case_info_is_time_determent || false,
      preEventDescription: case_info_pre_event_description || '',
      eventDescription: case_info_event_description || '',
      injuryDescription: case_info_injury_description || '',
      harmingObjectOrSubstance: case_info_harming_object_or_substance || '',
      deathTime: case_info_death_date || '',
    },
  };
};

const getUpdatedValuesToClaim301 = (values) => {
  const { physicianOrHealthCare, caseInfo } = values;
  const {
    wasTreatmentGivenAwayFromWorksite,
    name,
    physician_contact_id,
    name_contact_type,
    facility,
    facility_contact_id,
    facility_contact_type,
    street,
    city,
    state,
    zip,
    emergencyRoomTreatment,
    hospitalizationOvernight,
  } = physicianOrHealthCare;
  const {
    workBeginningTime,
    isTimeDeterment,
    preEventDescription,
    eventDescription,
    injuryDescription,
    harmingObjectOrSubstance,
    deathTime,
  } = caseInfo;

  return {
    physician_or_health_care_name: name,
    physician_or_health_care_name_contact_id: physician_contact_id,
    physician_or_health_care_name_contact_type: name_contact_type,
    physician_or_health_care_facility: facility,
    physician_or_health_care_facility_contact_id: facility_contact_id,
    physician_or_health_care_facility_contact_type: facility_contact_type,
    was_treatment_given_away_from_worksite: wasTreatmentGivenAwayFromWorksite,
    physician_or_health_care_location: {
      address1: street,
      address2: '',
      city,
      state,
      zipcode: zip,
    },
    physician_or_health_care_emergency_room_treatment: emergencyRoomTreatment,
    physician_or_health_care_hospitalization_overnight: hospitalizationOvernight,
    case_info_work_beginning_time: workBeginningTime,
    case_info_is_time_determent: isTimeDeterment,
    case_info_pre_event_description: preEventDescription,
    case_info_event_description: eventDescription,
    case_info_injury_description: injuryDescription,
    case_info_harming_object_or_substance: harmingObjectOrSubstance,
    case_info_death_date: deathTime,
  };
};

function WCOsha301() {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const [editEnabled, setEditEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [oshaStoredFileIdToPreview, setOshaStoredFileIdToPreview] = useState('');

  const completed = claim.claim_extra.osha_301_edited;

  const handleIssueOsha301Form = async () => {
    try {
      setIsSubmitting(true);
      const res = await axios.post(`/api/v1/wc_claims/${claim.id}/generate_osha_301`);
      if (res.data) {
        setOshaStoredFileIdToPreview(res.data.stored_file_id);
      }
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // TODO: add i for explanations of each field and for the entire form

  return (
    <>
      <Formik initialValues={getFieldsInitialValuesFor301(claim)} enableReinitialize>
        <Osha301Edit showOnly={true} isDialog={false} completed={completed} />
      </Formik>
      {editEnabled && (
        <Formik
          initialValues={getFieldsInitialValuesFor301(claim)}
          validationSchema={Yup.object().shape({
            caseInfo: Yup.object().shape({
              workBeginningTime: Yup.string()
                .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
                .nullable(),
            }),
            physicianOrHealthCare: Yup.object().shape({
              name: Yup.string().max(64, 'Limited to 64 characters'),
              facility: Yup.string().max(64, 'Limited to 64 characters'),
              state: Yup.string().oneOf(Object.keys(COUNTRY_TO_STATE_MAP['US']), 'Invalid state code'),
            }),
          })}
          onSubmit={async (values) => {
            try {
              setIsSubmitting(true);
              const data = { osha_301: getUpdatedValuesToClaim301(values) };
              const eventTime = values.caseInfo.eventTime;
              if (eventTime) {
                data.incident = { time_of_loss: eventTime };
              }
              await axios.patch(`/api/v1/wc_claims/${claim.id}`, data);
              await onClaimUpdate();
              setEditEnabled(false);
            } catch (error) {
              reportAxiosError(error);
            } finally {
              setIsSubmitting(false);
            }
          }}
        >
          <Osha301Edit showOnly={false} isDialog={true} onClose={() => setEditEnabled(false)} completed={false} />
        </Formik>
      )}
      <div className={classes.buttonsContainer}>
        <div className={classes.cardDivRow}>
          <Button
            variant="contained"
            className={classes.leftButtonIcon}
            color="primary"
            onClick={() => setEditEnabled(true)}
            disabled={isSubmitting}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleIssueOsha301Form(true)}
            disabled={isSubmitting}
          >
            Generate Osha 301
          </Button>
        </div>
      </div>
      {oshaStoredFileIdToPreview && (
        <OshaFormDisplayDialog
          fileId={oshaStoredFileIdToPreview}
          onSubmit={async () => {
            await onClaimUpdate();
            setOshaStoredFileIdToPreview(null);
            setEditEnabled(false);
          }}
          onCancel={() => {
            setOshaStoredFileIdToPreview(null);
          }}
        />
      )}
    </>
  );
}

function Osha301Edit({ showOnly, isDialog, onClose, completed }) {
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <CardDialog isDialog={isDialog} title="Osha 301" onClose={onClose} maxWidth="xl" fullWidth>
      {completed && <Chip size="small" color="primary" label="Completed" className={classes.chip} />}
      <Grid container>
        <Grid item md={6}>
          <Grid container direction="column">
            <Grid item md={12}>
              <div className={classes.cardDivRow}>
                <EmployeeInformation showOnly={true} />
              </div>
            </Grid>
            <Grid item md={12}>
              <div className={classes.cardDivRow}>
                <CaseInformation showOnly={showOnly} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction="column">
            <Grid item md={12}>
              <div className={classes.cardDivRow}>
                <HealthCareInformation showOnly={showOnly} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!showOnly && (
        <div className={classes.buttonsContainer}>
          <div className={classes.cardDivRow}>
            <CancelButton disabled={isSubmitting} onClick={onClose} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      )}
    </CardDialog>
  );
}

Osha301Edit.propTypes = {
  isDialog: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  completed: PropTypes.bool,
};

function OshaFormDisplayDialog({ fileId, onSubmit, onCancel }) {
  const { claim } = useClaim();
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveOsha301 = async () => {
    setIsSaving(true);
    try {
      await axios.post(`/api/v1/wc_claims/${claim.id}/save_osha_301/${fileId}`);
      onSubmit();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <PdfPreviewDialog
      title="Osha 301 Preview"
      onCancel={onCancel}
      onSubmit={handleSaveOsha301}
      fileUrl={`/api/v1/wc_claims/${claim.id}/osha_form_301/${fileId}`}
      disabled={isSaving}
      submitTitle="Save to claim"
    />
  );
}

OshaFormDisplayDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fileId: PropTypes.number.isRequired,
};

function EmployeeInformation({ disabled, showOnly }) {
  const classes = useStyles();

  return (
    <CardDialog title="Information about the employee" outlinedCard>
      <Grid container>
        <Grid item xs={6}>
          <TextFieldFormik
            id="employeeInfo.fullName"
            label="Full name"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
          />
          <TextFieldFormik
            id="employeeInfo.street"
            label="street"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
          />
          <TextFieldFormik
            id="employeeInfo.city"
            label="city"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
          />
          <TextFieldFormik
            id="employeeInfo.state"
            label="state"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id="employeeInfo.zipcode"
            label="zip"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
          />
          <DatePickerTextFieldFormik
            id="employeeInfo.dateOfBirth"
            label="Date of birth"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
            disableFuture
          />
          <DatePickerTextFieldFormik
            id="employeeInfo.dateHired"
            label="Date hired"
            className={classes.textField}
            fullWidth
            disabled={disabled}
            showOnly={showOnly}
            disableFuture
          />
          <TextFieldFormik
            id="employeeInfo.gender"
            label="Gender"
            fullWidth
            className={classes.textField}
            disabled={disabled}
            showOnly={showOnly}
          />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

EmployeeInformation.propTypes = {
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
};
function HealthCareInformation({ disabled, showOnly }) {
  const classes = useStyles();
  const { values, setValues } = useFormikContext();

  return (
    <CardDialog title="Information about the physician or other health case professional" outlinedCard>
      <Grid container spacing={2} style={{ marginBottom: '5px', marginTop: '5px' }}>
        <Grid item xs={5}>
          <RadioWithButtonWrapperFormik
            disabled={showOnly}
            id="physicianOrHealthCare.name_contact_type"
            className={classes.radioOrCheckboxWithIconOverride}
            value={contactTypes.fromContacts}
            text="Choose from contacts"
          />
        </Grid>
        <Grid item xs={5}>
          <RadioWithButtonWrapperFormik
            disabled={showOnly}
            id="physicianOrHealthCare.name_contact_type"
            className={classes.radioOrCheckboxWithIconOverride}
            value={contactTypes.addManually}
            text="Add manually"
          />
        </Grid>
      </Grid>
      {values.physicianOrHealthCare.name_contact_type === contactTypes.addManually && (
        <TextFieldFormik
          id="physicianOrHealthCare.name"
          label="Name of physician or other health case professional"
          fullWidth
          className={classes.textField}
          disabled={disabled}
          showOnly={showOnly}
        />
      )}
      {values.physicianOrHealthCare.name_contact_type === contactTypes.fromContacts && (
        <ContactTextFieldFormik
          id="physicianOrHealthCare.physician_contact"
          label="Name of physician or other health case professional"
          acceptedRoles={[
            'injured_employee',
            'agent',
            'claimant',
            'spouse',
            'family_member',
            'public_adjuster',
            'insurer',
            'attorney',
            'other',
          ]}
          fullWidth
          className={classes.textField}
          disabled={disabled}
          showOnly={showOnly}
          acceptedExpertise={[]}
        />
      )}
      <div>
        <YesNoQuestionFormik
          questionText="Was treatment given away from the worksite?"
          id="physicianOrHealthCare.wasTreatmentGivenAwayFromWorksite"
          disabled={disabled || showOnly}
        />
        {values.physicianOrHealthCare.wasTreatmentGivenAwayFromWorksite && (
          <>
            <Grid container spacing={2} style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Grid item xs={5}>
                <RadioWithButtonWrapperFormik
                  disabled={showOnly}
                  id="physicianOrHealthCare.facility_contact_type"
                  className={classes.radioOrCheckboxWithIconOverride}
                  value={contactTypes.fromContacts}
                  text="Choose from contacts"
                />
              </Grid>
              <Grid item xs={5}>
                <RadioWithButtonWrapperFormik
                  disabled={showOnly}
                  id="physicianOrHealthCare.facility_contact_type"
                  className={classes.radioOrCheckboxWithIconOverride}
                  value={contactTypes.addManually}
                  text="Add manually"
                />
              </Grid>
            </Grid>
            {values.physicianOrHealthCare.facility_contact_type === contactTypes.addManually && (
              <TextFieldFormik
                id="physicianOrHealthCare.facility"
                label="Facility"
                fullWidth
                className={classes.textField}
                disabled={disabled}
                showOnly={showOnly}
              />
            )}
            {values.physicianOrHealthCare.facility_contact_type === contactTypes.fromContacts && (
              <ContactTextFieldFormik
                id="physicianOrHealthCare.facility_contact"
                label="Facility"
                acceptedRoles={[
                  'injured_employee',
                  'agent',
                  'claimant',
                  'spouse',
                  'family_member',
                  'public_adjuster',
                  'insurer',
                  'attorney',
                  'other',
                ]}
                fullWidth
                className={classes.textField}
                disabled={disabled}
                showOnly={showOnly}
                acceptedExpertise={[]}
                onSelectContact={(contact) => {
                  let valuesWithContact = { ...values };
                  valuesWithContact = setIn(
                    valuesWithContact,
                    'physicianOrHealthCare.street',
                    contact.street_address1 ? contact.street_address1 : ''
                  );
                  valuesWithContact = setIn(
                    valuesWithContact,
                    'physicianOrHealthCare.city',
                    contact.city ? contact.city : ''
                  );
                  valuesWithContact = setIn(
                    valuesWithContact,
                    'physicianOrHealthCare.state',
                    contact.state ? contact.state : ''
                  );
                  valuesWithContact = setIn(
                    valuesWithContact,
                    'physicianOrHealthCare.zip',
                    contact.zipcode ? contact.zipcode : ''
                  );
                  valuesWithContact = setIn(valuesWithContact, 'physicianOrHealthCare.facility_contact_id', contact.id);
                  valuesWithContact = setIn(
                    valuesWithContact,
                    'physicianOrHealthCare.facility_contact_full_name',
                    contact.full_name
                  );
                  setValues(valuesWithContact);
                }}
              />
            )}
            <TextFieldFormik
              id="physicianOrHealthCare.street"
              label="Street"
              fullWidth
              className={classes.textField}
              disabled={disabled}
              showOnly={showOnly}
            />
            <TextFieldFormik
              id="physicianOrHealthCare.city"
              label="City"
              fullWidth
              className={classes.textField}
              disabled={disabled}
              showOnly={showOnly}
            />
            <UsStatesSelectTextFieldFormik
              id="physicianOrHealthCare.state"
              label="State"
              fullWidth
              className={classes.textField}
              disabled={disabled}
              showOnly={showOnly}
            />
            <TextFieldFormik
              id="physicianOrHealthCare.zip"
              label="Zip"
              fullWidth
              className={classes.textField}
              disabled={disabled}
              showOnly={showOnly}
            />
            <YesNoQuestionFormik
              questionText="Employee was treated in an emergency room"
              id="physicianOrHealthCare.emergencyRoomTreatment"
              disabled={disabled || showOnly}
            />
            <YesNoQuestionFormik
              questionText="Employee was hospitalized overnight as an in-patient"
              id="physicianOrHealthCare.hospitalizationOvernight"
              disabled={disabled || showOnly}
            />
          </>
        )}
      </div>
    </CardDialog>
  );
}

HealthCareInformation.propTypes = {
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function CaseInformation({ disabled, showOnly }) {
  const classes = useStyles();

  return (
    <CardDialog title="Information about the case" outlinedCard>
      <TextFieldFormik
        id="caseInfo.caseNumber"
        label="Case number from the Log"
        fullWidth
        className={classes.textField}
        showOnly
      />
      <DatePickerTextFieldFormik
        id="caseInfo.injuryDate"
        label="Date of injury or illness"
        fullWidth
        className={classes.textField}
        showOnly
        disableFuture
      />
      <TimePickerTextFieldFormik
        id="caseInfo.workBeginningTime"
        label="Time employee began work"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        showOnly={showOnly}
      />
      <TimePickerTextFieldFormik
        id="caseInfo.eventTime"
        label="Time of event"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        showOnly={showOnly}
      />
      <YesNoQuestionFormik
        questionText="Is event time cannot be determined"
        id="caseInfo.isTimeDeterment"
        disabled={disabled || showOnly}
      />
      <TextFieldFormik
        id="caseInfo.preEventDescription"
        label="What was the employee doing just before the incident occurred?"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        showOnly={showOnly}
      />
      <TextFieldFormik
        id="caseInfo.eventDescription"
        label="What happened?"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        showOnly={showOnly}
      />
      <TextFieldFormik
        id="caseInfo.injuryDescription"
        label="What was the injury or illness"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        showOnly={showOnly}
      />
      <TextFieldFormik
        id="caseInfo.harmingObjectOrSubstance"
        label="What object or substance directly harmed the employee?"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        showOnly={showOnly}
      />
      <DatePickerTextFieldFormik
        id="caseInfo.deathTime"
        label="If the employee died, when did death occur?"
        fullWidth
        className={classes.textField}
        disabled={disabled}
        disableFuture
        showOnly={showOnly}
      />
    </CardDialog>
  );
}

CaseInformation.propTypes = {
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function WCOShaForms() {
  return (
    <>
      <WCOsha301 />
      <WCOsha300 />
    </>
  );
}

const getFieldsInitialValuesFor300 = (claim, insuredPropertiesAndLocationsDict) => {
  const {
    claim_id_display,
    incident,
    employee_party,
    injury_description,
    case_classification,
    days_away_from_work,
    days_on_job_transfer,
    is_privacy_case,
    is_recordable,
    injury_type,
    insured_property_osha_subproperty_id,
  } = claim;
  const { date_of_loss, insured_property_id, insured_property_sublocation_id } = incident;
  const { full_name } = employee_party.involved_person.contact;
  const { job_title, light_duty_days_away_from_work, light_duty_days_on_job_transfer } = employee_party.involved_person;

  const property = insuredPropertiesAndLocationsDict[insured_property_id];
  const locationName = property?.sublocations[insured_property_sublocation_id]?.desc;
  const propertyName = property?.desc;
  const subproperty =
    (insured_property_osha_subproperty_id && property.subproperties?.[insured_property_osha_subproperty_id]) || '';

  return {
    caseNumber: claim_id_display,
    employeeName: full_name,
    jobTitle: job_title || '',
    dateOfInjury: date_of_loss,
    location: `${subproperty && subproperty.name !== 'None' ? subproperty.name : propertyName} - ${locationName}`,
    injuryDescription: injury_description || '',
    caseClassification: case_classification || '',
    daysAwayFromWork: light_duty_days_away_from_work || days_away_from_work || 0,
    daysOnJobTransfer: light_duty_days_on_job_transfer || days_on_job_transfer || 0,
    isPrivacyCase: !!is_privacy_case,
    isNotRecordable: !(is_recordable === null || is_recordable),
    injuryType: injury_type || 'injury',
    subpropertyId: insured_property_osha_subproperty_id || '',
    daysJobTransferShowOnly: light_duty_days_on_job_transfer > 0,
    daysAwayFromWorkShowOnly: light_duty_days_away_from_work > 0,
  };
};

const getUpdatedValuesToClaim300 = (values) => {
  const {
    injuryDescription,
    caseClassification,
    daysAwayFromWork,
    daysOnJobTransfer,
    isPrivacyCase,
    isNotRecordable,
    injuryType,
    subpropertyId,
  } = values;

  return {
    injury_description: injuryDescription,
    case_classification: caseClassification,
    days_away_from_work: daysAwayFromWork,
    days_on_job_transfer: daysOnJobTransfer,
    is_privacy_case: isPrivacyCase,
    is_recordable: !isNotRecordable,
    injury_type: injuryType,
    insured_property_osha_subproperty_id: subpropertyId,
  };
};

function WCOsha300() {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { insuredPropertiesAndLocationsDict } = useOrganization();
  const [editEnabled, setEditEnabled] = useState(false);

  const completed = claim.claim_extra.osha_300_edited;

  // TODO: add i for explanations of each field and for the entire form

  return (
    <>
      <Formik initialValues={getFieldsInitialValuesFor300(claim, insuredPropertiesAndLocationsDict)} enableReinitialize>
        <Osha300Edit
          showOnly={true}
          isDialog={false}
          completed={completed}
          propertyId={claim.incident.insured_property_id}
        />
      </Formik>
      {editEnabled && (
        <Osha300EditDialog
          onSubmit={async () => {
            await onClaimUpdate();
            setEditEnabled(false);
          }}
          oshaDetails={getFieldsInitialValuesFor300(claim, insuredPropertiesAndLocationsDict)}
          onCancel={() => setEditEnabled(false)}
          claimId={claim.id}
          propertyId={claim.incident.insured_property_id}
        />
      )}
      <div className={classes.buttonsContainer}>
        <div className={classes.cardDivRow}>
          <Button variant="contained" color="primary" onClick={() => setEditEnabled(true)}>
            Edit
          </Button>
        </div>
      </div>
    </>
  );
}

function Osha300EditDialog({ onSubmit, oshaDetails, onCancel, claimId, propertyId }) {
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  const subproperties = insuredPropertiesAndLocationsDict[propertyId].subproperties;

  return (
    <Formik
      initialValues={oshaDetails}
      validationSchema={Yup.object().shape({
        injuryDescription: Yup.string(),
        caseClassification: Yup.string().oneOf(Object.keys(OSHA_300_CLASSIFICATION_OPTIONS)).required('Required'),
        daysAwayFromWork: Yup.number(),
        daysOnJobTransfer: Yup.number(),
        isPrivacyCase: Yup.boolean(),
        isNotRecordable: Yup.boolean(),
        injuryType: Yup.string().oneOf(Object.keys(OSHA_300_INJURY_OPTIONS)),
        subpropertyId: subproperties.length
          ? Yup.string().oneOf(Object.keys(subproperties)).required('Required')
          : undefined,
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.patch(`/api/v1/wc_claims/${claimId}/osha_300`, getUpdatedValuesToClaim300(values));
          await onSubmit();
        } catch (error) {
          reportAxiosError(error);
        } finally {
          formikProps.setSubmitting(false);
        }
      }}
    >
      <Osha300Edit showOnly={false} isDialog onClose={onCancel} propertyId={propertyId} />
    </Formik>
  );
}

Osha300EditDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  oshaDetails: PropTypes.object.isRequired,
  claimId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
};

function Osha300Edit({ showOnly, isDialog, onClose, completed, propertyId }) {
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <>
      <CardDialog isDialog={isDialog} title="Osha 300" onClose={onClose} maxWidth="xl" fullWidth>
        {completed && <Chip size="small" color="primary" label="Completed" className={classes.chip} />}
        <Grid container>
          <Grid item md={6}>
            <Grid container direction="column">
              <Grid item md={12}>
                <div className={classes.cardDivRow}>
                  <AboutTheEmployee />
                </div>
              </Grid>
              <Grid item md={12}>
                <div className={classes.cardDivRow}>
                  <AboutTheCase showOnly={showOnly} propertyId={propertyId} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container direction="column">
              <Grid item md={12}>
                <div className={classes.cardDivRow}>
                  <ClassifyTheCase showOnly={showOnly} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!showOnly && (
          <div className={classes.buttonsContainer}>
            <div className={classes.cardDivRow}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </div>
        )}
      </CardDialog>
    </>
  );
}

Osha300Edit.propTypes = {
  isDialog: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  completed: PropTypes.bool,
  propertyId: PropTypes.number.isRequired,
};

function AboutTheEmployee() {
  const classes = useStyles();

  return (
    <CardDialog title="Identify the person" outlinedCard>
      <Grid container>
        <Grid item xs={4}>
          <TextFieldFormik id="caseNumber" label="Case No." fullWidth className={classes.textField} showOnly />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormik id="employeeName" label="Employee's Name" fullWidth className={classes.textField} showOnly />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormik id="jobTitle" label="Job Title" fullWidth className={classes.textField} showOnly />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

function AboutTheCase({ showOnly, propertyId }) {
  const classes = useStyles();
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  const property = insuredPropertiesAndLocationsDict[propertyId];
  const shouldShowSubproperty = !!Object.keys(property.subproperties).length;

  return (
    <CardDialog title="Describe the case" outlinedCard>
      <Grid container>
        <Grid item xs={6}>
          <DatePickerTextFieldFormik
            id="dateOfInjury"
            label="Date of injury or onset of illness (mo./day)"
            fullWidth
            className={classes.textField}
            showOnly
            disableFuture
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id="location"
            label="Where the event occurred"
            fullWidth
            className={classes.textField}
            showOnly
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id="injuryDescription"
            label="Describe injury or illness, parts of body affected, and objects/substance that directly injured or made person ill"
            fullWidth
            className={classes.textField}
            showOnly={showOnly}
            multiline
            rows={4}
          />
        </Grid>
        {shouldShowSubproperty && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="subpropertyId"
              label="Sub Establishment"
              fullWidth
              className={classes.textField}
              showOnly={showOnly}
              select
              SelectProps={{
                multiple: showOnly,
                renderValue: (key) => property.subproperties[key]?.name || '',
              }}
            >
              {Object.keys(property.subproperties).map((key) => (
                <MenuItem key={key} value={key}>
                  {property.subproperties[key].name}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}
      </Grid>
    </CardDialog>
  );
}

AboutTheCase.propTypes = {
  showOnly: PropTypes.bool.isRequired,
  propertyId: PropTypes.number.isRequired,
};

function ClassifyTheCase({ showOnly }) {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { daysAwayFromWorkShowOnly, daysJobTransferShowOnly } = values;
  const lightDutyLabel = '(calculated from Light Duty)';
  const daysJobTransferLabel = 'number of days the injured or ill worker was on job transfer or restriction';
  const daysAwayFromWorkLabel = 'number of days the injured or ill worker was away from work';

  return (
    <CardDialog title="Classify the case" outlinedCard>
      <Grid container>
        <Grid item xs={12}>
          <TextFieldFormik
            id="caseClassification"
            label="Choose one option based on the most serious outcome for that case"
            fullWidth
            className={classes.textField}
            showOnly={showOnly}
            select
          >
            {Object.keys(OSHA_300_CLASSIFICATION_OPTIONS).map((key) => (
              <MenuItem key={key} value={key}>
                {OSHA_300_CLASSIFICATION_OPTIONS[key]}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id="daysAwayFromWork"
            label={daysAwayFromWorkShowOnly ? `${daysAwayFromWorkLabel} ${lightDutyLabel}` : daysAwayFromWorkLabel}
            fullWidth
            className={classes.textField}
            showOnly={showOnly ? true : daysAwayFromWorkShowOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id="daysOnJobTransfer"
            label={daysJobTransferShowOnly ? `${daysJobTransferLabel} ${lightDutyLabel}` : daysJobTransferLabel}
            fullWidth
            className={classes.textField}
            showOnly={showOnly ? true : daysJobTransferShowOnly}
          />
        </Grid>
        <TextFieldFormik
          id="injuryType"
          label="Choose the 'injury' option or the relevant type of illness"
          fullWidth
          className={classes.textField}
          showOnly={showOnly}
          select
        >
          {Object.keys(OSHA_300_INJURY_OPTIONS).map((key) => (
            <MenuItem key={key} value={key}>
              {OSHA_300_INJURY_OPTIONS[key]}
            </MenuItem>
          ))}
        </TextFieldFormik>
        <Grid item xs={12}>
          <CheckboxFormik id="isPrivacyCase" label="Mark as Privacy Case" fullWidth showOnly={showOnly} />
        </Grid>
        <Grid item xs={12}>
          <CheckboxFormik id="isNotRecordable" label="Not Recordable" fullWidth showOnly={showOnly} />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

ClassifyTheCase.propTypes = {
  showOnly: PropTypes.bool.isRequired,
};

export default WCOShaForms;
export { Osha300EditDialog };
