import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';
import axios from 'axios';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import CheckBanner from '~/components/core/CheckBanner';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { useExposureSubReserves } from '~/components/hooks/useSubReserves';
import TextFieldFormik from '~/components/TextFieldFormik';
import { FINANCIAL_STATUS_DICT } from '~/Types';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

export function getDeductibleConsideredAmount(claim, exposure, payableType) {
  if (
    claim.type === 'auto_claim' &&
    payableType === 'indemnity' &&
    exposure.policy_deductible &&
    !exposure.was_deductible_paid
  ) {
    return exposure.policy_deductible;
  }
  return undefined;
}

function checkIsDeductibleApplicationRelevant(claimType, exposure_deductible_amount, exposure_was_deductible_paid) {
  if (claimType !== 'general_claim') {
    return false;
  }

  if (!exposure_deductible_amount || exposure_was_deductible_paid) {
    return false;
  }

  return true;
}

export function checkDeductibleApplicationAndDeductibleSubReserve({
  adjustReserveIncludesDeductible,
  isMultipleSubReservePaymentsEnabled,
  subReservesConfigMap,
  claimType,
  exposure,
}) {
  const isDeductibleApplicationRelevant = checkIsDeductibleApplicationRelevant(
    claimType,
    exposure.deductible_amount,
    exposure.was_deductible_paid
  );

  return {
    isDeductibleApplicationRelevant,
    shouldChooseDeductibleSubReserve: !!(
      isDeductibleApplicationRelevant &&
      adjustReserveIncludesDeductible &&
      isMultipleSubReservePaymentsEnabled &&
      subReservesConfigMap
    ),
  };
}

export function ApplyDeductibleCheckbox({ claim, exposure, showOnly }) {
  const { currencyFormatter } = useCurrencyFormatter();
  const { values, setFieldValue, isSubmitting, setFieldTouched } = useFormikContext();
  const { adjustReserveIncludesDeductible, isMultipleSubReservePaymentsEnabled } = usePaymentsConfiguration();
  const { subReservesConfigMap } = useExposureSubReserves('indemnity', exposure);

  const classes = useStyles();

  const { isDeductibleApplicationRelevant, shouldChooseDeductibleSubReserve } =
    checkDeductibleApplicationAndDeductibleSubReserve({
      adjustReserveIncludesDeductible,
      isMultipleSubReservePaymentsEnabled,
      subReservesConfigMap,
      claimType: claim.type,
      exposure,
    });

  const isChecked = values['deductible_considered_amount'] > 0;
  const subReserveAmounts = values.sub_reserve_amounts || [];
  const subReserveKeyForAdjustReserveDeductible = values.sub_reserve_key_for_adjust_reserve_deductible;

  React.useEffect(() => {
    if (shouldChooseDeductibleSubReserve) {
      setFieldTouched('sub_reserve_key_for_adjust_reserve_deductible', true);
    }
  }, [setFieldTouched, shouldChooseDeductibleSubReserve, values.sub_reserve_amounts]);

  const menuItems = shouldChooseDeductibleSubReserve
    ? [...subReservesConfigMap.entries()].filter(([subReserveKey]) => {
        return subReserveAmounts?.some((sub_reserve_amount) => sub_reserve_amount.type_key === subReserveKey);
      })
    : null;

  React.useEffect(() => {
    if (
      subReserveKeyForAdjustReserveDeductible &&
      !menuItems?.map((itemDetails) => itemDetails[0]).includes(subReserveKeyForAdjustReserveDeductible)
    ) {
      setFieldValue('sub_reserve_key_for_adjust_reserve_deductible', '');
      setFieldTouched('sub_reserve_key_for_adjust_reserve_deductible', true);
    }
  }, [menuItems, subReserveKeyForAdjustReserveDeductible, setFieldValue, setFieldTouched]);

  if (!isDeductibleApplicationRelevant) {
    return null;
  }

  return (
    <div className="grid w-full grid-cols-5 pt-15">
      <CheckBanner
        wrapperClassNames={cn(shouldChooseDeductibleSubReserve ? 'col-span-3 mr-20' : 'col-span-5')}
        note={`Apply Deductible (${currencyFormatter.format(exposure.deductible_amount)})`}
        checked={isChecked}
        onClick={() => {
          if (isChecked) {
            setFieldValue('deductible_considered_amount', 0);
          } else {
            setFieldValue('deductible_considered_amount', exposure.deductible_amount);
          }
        }}
        disabled={showOnly}
      />
      {shouldChooseDeductibleSubReserve && isChecked ? (
        <TextFieldFormik
          id="sub_reserve_key_for_adjust_reserve_deductible"
          label="Sub reserve for deductible"
          className={cn(classes.textField, 'col-span-2')}
          fullWidth
          select
          disabled={isSubmitting || subReserveAmounts?.length === 0}
          showOnly={showOnly}
        >
          {menuItems.map(([key, { desc }]) => (
            <MenuItem key={key} value={key}>
              {desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      ) : null}
    </div>
  );
}

ApplyDeductibleCheckbox.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  showOnly: PropTypes.bool,
};

export function deductibleFinanceTableAdditionalMenuItemCondition(financeRecord) {
  const exposureFinanceMetadata = financeRecord.exposure_finance_metadata;
  const paymentRequest = financeRecord.payment_request;

  if (!FINANCIAL_STATUS_DICT[paymentRequest.financial_status].is_active) {
    return false;
  }

  if (financeRecord.payable_with_reserve_type !== 'indemnity') {
    return false;
  }

  const isDeductibleApplicationRelevant = checkIsDeductibleApplicationRelevant(
    financeRecord.claim_type,
    exposureFinanceMetadata.deductible_amount,
    exposureFinanceMetadata.was_deductible_paid
  );

  if (!isDeductibleApplicationRelevant) {
    return false;
  }

  return !exposureFinanceMetadata.was_deductible_paid;
}

export function DeductibleFinanceTableAdditionalMenuItem({ financeRecord, onReloadFinances }) {
  const paymentRequest = financeRecord.payment_request;
  const [isApplying, setIsApplying] = React.useState(false);
  const { currencyFormatter } = useCurrencyFormatter();

  const handleApplyDeductible = async () => {
    try {
      setIsApplying(true);
      await axios.post(
        `/api/v1/claims/${financeRecord.claim_id}/exposures/${financeRecord.exposure_id}/indemnity/payment_requests/${paymentRequest.id}/apply_deductible`
      );
      await onReloadFinances();
    } catch (error) {
      await reportAxiosError(error);
      // only setIsApplying(false) on error, on success we want it to remain disabled until disappearing to avoid double-click
      setIsApplying(false);
    }
  };

  return (
    <MenuItem onClick={() => handleApplyDeductible()} disabled={isApplying}>
      {`Apply Deductible (${currencyFormatter.format(financeRecord.exposure_finance_metadata.deductible_amount)})`}
    </MenuItem>
  );
}

DeductibleFinanceTableAdditionalMenuItem.propTypes = {
  financeRecord: PropTypes.object.isRequired,
  onReloadFinances: PropTypes.func.isRequired,
};

export function DeductibleSumExplanation({ claim, exposure }) {
  if (claim.type === 'home_claim') return <HomeClaimDeductibleSumExplanation claim={claim} />;

  if (claim.type === 'auto_claim') return <AutoClaimDeductibleSumExplanation exposure={exposure} />;

  return null;
}

DeductibleSumExplanation.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
};

function AutoClaimDeductibleSumExplanation({ exposure }) {
  const { currencyFormatter } = useCurrencyFormatter();

  if (exposure.policy_deductible === null) {
    return null;
  }
  return (
    <Typography display="block">
      Deductible:{' '}
      <span style={{ textDecoration: exposure.was_deductible_paid && 'line-through' }}>
        {currencyFormatter.format(exposure.policy_deductible)}
      </span>
    </Typography>
  );
}

AutoClaimDeductibleSumExplanation.propTypes = {
  exposure: PropTypes.object.isRequired,
};

// DEPRECATED: home_claim (non-GC) removed after TSC environment closes
function HomeClaimDeductibleSumExplanation({ claim }) {
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <Typography display="block">{`Deductible: ${
      claim.was_deductible_paid ? 'Deductible paid in full' : currencyFormatter.format(claim.deductible)
    }`}</Typography>
  );
}

HomeClaimDeductibleSumExplanation.propTypes = {
  claim: PropTypes.object.isRequired,
};
