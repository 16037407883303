import React from 'react';
import MinimizeIcon from '@material-ui/icons/Minimize';

import { ChatEmptyState } from '~/components/AiChat/ChatEmptyState';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import WithConfirm from '~/components/ConfirmModal';
import { FsIconButton } from '~/components/core';
import { RefreshIcon } from '~/components/icons';
import cn from '~/Utils/cn';

import CardDialog from '../CardDialog';

export const ChatDialog: React.FC<{
  onClose: () => void;
  onMinimize?: () => void;
  dialogOpen?: boolean;
}> = ({ children, onClose, dialogOpen = true, onMinimize }) => {
  const { restartChat, isChatLoading, chat } = useChatContext();
  const title = isChatLoading ? 'Loading Document Chat...' : chat?.chat_title || 'Document Chat';

  return (
    <CardDialog
      className={cn({ hidden: !dialogOpen, 'z-[-1000]': !dialogOpen })}
      isDialog
      open={dialogOpen ?? true}
      title={title}
      maxWidth="md"
      fullWidth
      action={
        <>
          {onMinimize ? (
            <FsIconButton onClick={onMinimize} tooltipText="Minimize">
              <MinimizeIcon />
            </FsIconButton>
          ) : null}
          <WithConfirm
            primaryButtonName="restart"
            title="Are you sure?"
            contentText="This will delete all messages and restart the chat"
            shouldCloseOnPrimary={true}
          >
            <FsIconButton onClick={restartChat} tooltipText="Restart chat">
              <RefreshIcon />
            </FsIconButton>
          </WithConfirm>
        </>
      }
      onClose={onClose}
      containerClassName="h-[calc(100vh-134px)]"
    >
      {!chat && isChatLoading ? <ChatEmptyState /> : null}
      {chat && children}
    </CardDialog>
  );
};
