const CONTACT_PHONE_CHANGE_REASONS = {
  not_in_use: {
    desc: 'Phone number no longer in use',
  },
  wrong: {
    desc: 'Wrong phone number',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default CONTACT_PHONE_CHANGE_REASONS;
