import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import { AccountSearch } from 'mdi-material-ui';
import * as Yup from 'yup';

import EmailCommunicationPopover from '~/components/Contacts/ContactCommunications/Email/EmailCommunicationPopover';
import PhoneCallCommunicationPopover from '~/components/Contacts/ContactCommunications/PhoneCall/PhoneCallCommunicationPopover';
import SmsCommunicationPopover from '~/components/Contacts/ContactCommunications/SMS/SmsCommunicationPopover';
import VideoCommunicationPopover from '~/components/Contacts/ContactCommunications/Video/VideoCommunicationPopover';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';

import { MIXPANEL_EVENT_SOURCES } from '../pocs/mixpanel';
import { isFeatureEnabled, reportAxiosError } from '../Utils';

import { getAllSearchableContactRoles } from './communications/ContactUtils';
import ExposuresLabelFilter from './exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from './exposures/ExposureUtils';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { ContactEntity, EditContactCardDialog, getContact } from './Contact';
import ContactTextFieldFormik from './ContactTextFieldFormik';
import { FsButton, SortableTable } from './core';
import ExposureLabelChips from './ExposureLabelChips';
import HoverActionField from './HoverActionField';
import useOrganization from './OrganizationContext';

import { useStyles } from '../assets/styles';
import communicationActionStyles from './Contacts/contacts.module.scss';

const ContactsTable = ({ contacts, onEditContact, maxHeight, handleUpdate }) => {
  const { user } = useCms();
  const { organizationContactRolesDict, isDyteVideoEnabled } = useOrganization();

  const columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'exposure_ids',
      disablePadding: false,
      label: 'Label',
      specialCell: (comm) => <ExposureLabelChips claimObject={comm} disablePadding />,
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'full_name',
      label: 'Name',
      specialCell: (contact) => <ContactEntity contactId={contact.id} contactDisplayName={contact.full_name} />,
    },
    { id: 'role', label: 'Role', specialCell: (contact) => organizationContactRolesDict[contact.role].desc },
    {
      id: 'email',
      label: 'Email',
      specialCell: (contact) => {
        if (contact.emails.length > 0) {
          return (
            <div className={communicationActionStyles.contactTableCellInnerContainer}>
              {contact.emails[0].email} {contact.emails.length === 1 ? '' : `(+${contact.emails.length - 1})`}
              <span className={communicationActionStyles.actionIconContainer}>
                <EmailCommunicationPopover
                  contact={contact}
                  onUpdate={handleUpdate}
                  communicationDetails={contact.emails[0]}
                  mixpanelSource={MIXPANEL_EVENT_SOURCES.CLAIM_CONTACTS_TAB_NEW_EMAIL_COMMUNICATION_CLICKED}
                />
              </span>
            </div>
          );
        }
      },
    },
    {
      id: 'phone',
      label: 'Phone',
      specialCell: (contact) => {
        if (contact.phones.length > 0 || isDyteVideoEnabled) {
          return (
            <div className={communicationActionStyles.contactTableCellInnerContainer}>
              {contact.phones.length > 0 ? (
                <>
                  {contact.phones[0].phone} {contact.phones.length === 1 ? '' : `(+${contact.phones.length - 1})`}
                  <span className={communicationActionStyles.actionIconContainer}>
                    <PhoneCallCommunicationPopover
                      contact={contact}
                      onUpdate={handleUpdate}
                      communicationDetails={contact.phones[0]}
                      mixpanelSource={MIXPANEL_EVENT_SOURCES.CLAIM_CONTACTS_TAB_NEW_NEW_PHONE_CALL}
                    />
                  </span>
                  <span className={communicationActionStyles.actionIconContainer}>
                    <SmsCommunicationPopover
                      contact={contact}
                      onUpdate={handleUpdate}
                      communicationDetails={contact.phones[0]}
                      mixpanelSource={MIXPANEL_EVENT_SOURCES.CLAIM_CONTACTS_TAB_NEW_MS_COMMUNICATION_CLICKED}
                    />
                  </span>
                </>
              ) : null}

              {isDyteVideoEnabled ? (
                <span className={communicationActionStyles.actionIconContainer}>
                  <VideoCommunicationPopover contact={contact} onUpdate={handleUpdate} />
                </span>
              ) : null}
            </div>
          );
        }
      },
    },
    { id: 'full_address', label: 'Address' },
    { id: 'note', label: 'Note' },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit_contact',
      disableSort: true,
      numeric: false,
      rightPaddingOnly: true,
      width: 20,
      specialCell: (contact, isHover) =>
        user.role.is_view_only ? null : (
          <HoverActionField onAction={() => onEditContact(contact)} permanent={isHover} />
        ),
    },
  ];

  return (
    <SortableTable
      rows={contacts}
      columns={columnData}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'full_name')}
      maxHeight={maxHeight}
      order="asc"
      stickyHeader
    />
  );
};

ContactsTable.propTypes = {
  contacts: PropTypes.array.isRequired,
  onEditContact: PropTypes.func.isRequired,
  maxHeight: PropTypes.number,
  handleUpdate: PropTypes.func,
};

function AddContactToClaimContainer() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();
  const { claim, onAsyncClaimUpdate } = useClaim();

  const { user } = useCms();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <>
      <FsButton color="primary" size="small" onClick={() => setDialogOpen(true)} disabled={user.role.is_view_only}>
        <AccountSearch className={classes.leftButtonIcon} />
        Add Contact
      </FsButton>
      <Formik
        initialValues={{
          contact_id: '',
        }}
        validationSchema={Yup.object().shape({
          contact_id: Yup.number().required('Required'),
        })}
        onSubmit={async (values, formikProps) => {
          try {
            await axios.post(`/api/v1/claims/${claim.id}/contacts`, { ...values, exposure_ids: [0] });
            await onAsyncClaimUpdate();
            setDialogOpen(false);
            formikProps.resetForm();
          } catch (error) {
            reportAxiosError(error);
            formikProps.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;
          return (
            <CardDialog title="Add Contact to Claim" isDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ContactTextFieldFormik
                    id="contact"
                    label="Contact"
                    acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict).concat(['user'])} // to avoid re-creation of already existing CMS-users, show the contacts here (although adding them to the claim will have no visible effect)
                    fullWidth
                    fixedSearchResults
                    contactSearchProps={{
                      onNewContactCreated: () => {
                        // new contact was created, since we're in context it was also probably added to claim.
                        // unfortunately, we can't verify since claim(.contacts) might still be not updated
                        setDialogOpen(false);
                        formikProps.resetForm();
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                      Add
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

const ContactsTab = () => {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const [editContact, setEditContact] = useState();
  const [filteredExposuresIds, setFilteredExposuresIds] = useState(() => getUserRelatedExposuresId(user, claim));

  let contactsFiltered = claim.contacts.filter((contact) =>
    getAllSearchableContactRoles(organizationContactRolesDict).includes(contact.role)
  );
  if (filteredExposuresIds.length !== 0) {
    contactsFiltered = contactsFiltered.filter(
      (contact) =>
        _.intersection(filteredExposuresIds, contact.exposure_ids).length > 0 ||
        (contact.exposure_ids.length === 0 && filteredExposuresIds.includes(0))
    );
  }

  return (
    <div className={classes.cardDivRow}>
      <CardDialog title="Contacts" action={<AddContactToClaimContainer />}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ExposuresLabelFilter filterList={filteredExposuresIds} onUpdateFiltered={setFilteredExposuresIds} />
          </Grid>
          <Grid item xs={12}>
            <ContactsTable
              contacts={contactsFiltered}
              onEditContact={(contact) => setEditContact(contact)}
              handleUpdate={onAsyncClaimUpdate}
            />
          </Grid>
        </Grid>
        {editContact && (
          <EditContactCardDialog
            contact={editContact}
            onCancel={() => setEditContact(undefined)}
            onContactUpdate={async () => {
              await onAsyncClaimUpdate();
              if (!isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONTACTS_UI_IMPROVEMENTS)) {
                // Refetch the updated data to display the user's changes in the dialog
                const updatedContact = await getContact(editContact.id, claim);
                setEditContact(updatedContact);
              }
            }}
            isDialog
          />
        )}
      </CardDialog>
    </div>
  );
};

ContactsTab.propTypes = {};

export default ContactsTab;
