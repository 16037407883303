import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Divider, IconButton, MenuItem, Typography } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { clearTableauAuthTime } from '~/tableauAuthenticationUtils';
import colors from '~/theme/tailwind/colors';
import { ORGANIZATION_TYPES } from '~/Types';
import { isUserFiveSigma, isUserImpersonated } from '~/UserUtils';
import { clearUserCache, isHiddenRole, isProductionEnv, reportAxiosError } from '~/Utils';

import CardDialog from '../../CardDialog';
import TooltipIcon from '../../core/TooltipIcon';
import { useCms } from '../../hooks/useCms';
import { CancelIcon, ImpersonationButtonIcon, StarFullIcon } from '../../icons';
import LoadingDialog from '../../LoadingDialog';
import { TextFieldFormik } from '../../TextFieldFormik';
import useDataFetcher from '../../useDataFetcher';

import styles from './ImpersonateContainer.module.scss';

function ImpersonateContainer() {
  const [isImpersonateDialogOpen, setIsImpersonateDialogOpen] = React.useState(false);
  const { user } = useCms();

  const isImpersonated = isUserImpersonated(user);

  if (!user || (isProductionEnv() && !isImpersonated && !user.is_super_user)) {
    return <></>;
  }

  const handleExitImpersonation = async () => {
    await clearUserCache();
    await axios.delete('/api/login_support');
    window.location.assign('/home');
  };

  return (
    <div className={styles.impersonateContainer}>
      {!isImpersonated ? (
        <Button
          size="small"
          variant="contained"
          onClick={setIsImpersonateDialogOpen}
          startIcon={<ImpersonationButtonIcon iconColor="white" />}
          className={styles.externalImpersonateButton}
        >
          {isProductionEnv() ? 'View As' : 'Impersonate'}
        </Button>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="caption">Impersonating</Typography>
              <Typography variant="body1">{user.organization_name}</Typography>
            </Grid>
            <Grid item xs={6} className={styles.internalImpersonatedButtonsContainer}>
              <IconButton onClick={setIsImpersonateDialogOpen} className={styles.internalButton}>
                <ImpersonationButtonIcon />
              </IconButton>
              <Divider orientation="vertical" variant="inset" className={styles.divider} flexItem />
              <IconButton onClick={handleExitImpersonation}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
      {isImpersonateDialogOpen && (
        <ImpersonateDialog onCancel={() => setIsImpersonateDialogOpen(false)} isImpersonated={isImpersonated} />
      )}
    </div>
  );
}

const ImpersonateDialog = ({ onCancel, isImpersonated }) => {
  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const history = useHistory();

  const {
    isLoading,
    isError,
    data: organizationsAndUsers,
  } = useDataFetcher(
    isProductionEnv() ? '/admin/api/v1/support/organizations_and_users' : '/test/api/v1/organizations_and_users'
  );

  if (isLoading || isError) {
    return <LoadingDialog onClose={onCancel} isError={isError} track="impersonation_dialog" />;
  }

  const singleOrganizationId =
    organizationsAndUsers.organizations.length === 1 &&
    organizationsAndUsers.organizations[0].id === user.organization_id &&
    user.organization_id;

  return (
    <Formik
      initialValues={{
        organization_id: singleOrganizationId || '',
        user_id: '',
        environment: singleOrganizationId ? organizationsAndUsers.organizations[0].organization_type : 'operational',
      }}
      validationSchema={Yup.object().shape({
        organization_id: Yup.number().required('Required'),
        user_id: Yup.string().required('Required'),
        environment: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await clearUserCache();
          clearTableauAuthTime();
          if (isImpersonated) {
            await axios.put('/api/login_support', values);
            history.push('/home');
          } else {
            await axios.post('/api/login_support', values);
          }
          window.location.reload();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, isSubmitting } = formikProps;
        const users = values['organization_id']
          ? organizationsAndUsers.organizations.find((impOpt) => impOpt.id === values['organization_id']).users
          : [];
        // TODO - Remove empty organization_type check when after NGTPA-4684
        const filteredOrganizations = organizationsAndUsers.organizations.filter((org) => {
          if (values.environment === 'operational') {
            return org.organization_type === values.environment || !org.organization_type;
          } else {
            return org.organization_type === values.environment;
          }
        });
        const sortedOrganizations = _.orderBy(filteredOrganizations, ['name']);
        const sortedUsers = _.orderBy(users, ['is_active', 'username'], ['desc', 'asc']).filter(
          (user) => !isHiddenRole(user.role_type)
        );

        if (sortedOrganizations.length === 1) {
          values['organization_id'] = sortedOrganizations[0].id;
        }

        return (
          <CardDialog
            title="Impersonate User"
            isDialog
            onClose={onCancel}
            maxWidth="xs"
            containerClassName={styles.cardContainer}
          >
            <Grid container spacing={1}>
              {!singleOrganizationId && userOrganization.organization_type !== 'demo' && (
                <>
                  <Grid items xs={12} className={styles.buttonsContainer}>
                    <RadioButtonGroupFormik
                      label="Select Environment"
                      id="environment"
                      direction="row"
                      options={Object.values(ORGANIZATION_TYPES).map((type) => ({
                        text: type.desc,
                        optionValue: type.desc.toLowerCase(),
                      }))}
                      btnClassName={styles.btnClassName}
                      variant="subtitle1"
                      btnContainerClassName={styles.btnContainerClassName}
                    />
                  </Grid>
                  <Grid item xs={12} className={styles.inputContainer}>
                    <TextFieldFormik
                      id="organization_id"
                      label="Organization"
                      fullWidth
                      className={classes.textField}
                      select
                      disabled={isSubmitting || values.environment === ''}
                    >
                      {sortedOrganizations.map((impOrg) => (
                        <MenuItem key={impOrg.id} value={impOrg.id}>
                          {impOrg.name}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>
                </>
              )}
              <Grid item xs={12} className={styles.inputContainer}>
                <TextFieldFormik
                  id="user_id"
                  label="User"
                  fullWidth
                  className={classes.textField}
                  select
                  disabled={isSubmitting || (!singleOrganizationId && values.organization_id === '')}
                >
                  {sortedUsers.map((currUser) => (
                    <MenuItem key={currUser.id} value={currUser.id} disabled={!currUser.is_active}>
                      <div className="flex items-center">
                        <span>
                          {currUser.username}
                          {!currUser.is_active ? ' (Locked) ' : ''}
                        </span>
                        {user &&
                        (user.is_impersonating_super_organization || isUserFiveSigma(user)) &&
                        (currUser.is_sys_config_editor || currUser.is_sys_config_editor_by_permission) ? (
                          <TooltipIcon title="System Configuration Editor">
                            <StarFullIcon size={16} iconColor={colors.teal['600']} className="m-5" />
                          </TooltipIcon>
                        ) : null}
                      </div>
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Impersonate
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ImpersonateDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isImpersonated: PropTypes.bool.isRequired,
};

export default ImpersonateContainer;
