import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import PetsIcon from '@mui/icons-material/Pets';
import axios from 'axios';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { getPolicyStatus, getPolicyStatusDuringLoss } from '../../PolicyUtils';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isBoostOrganization, isFeatureEnabled, isPolicyNotFound, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import CoveredEntityCoveragesTable from '../CoveredEntity';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import HoverChangeField from '../HoverChangeField';
import useOrganization from '../OrganizationContext';
import { EditManuallyFilledPolicyContainer } from '../Policy/PolicyNotFoundContainer';
import { ShowOnlyTextField } from '../TextFieldFormik';
import UpdatePolicyEffectiveDateDialog from '../UpdatePolicyEffectiveDateDialog';

import { EditPetDetailsDialog, ShowOnlyPetDetailsInfoCardDialog } from './PetInvolvedCard';
import { PetAccumulatedFinancialContainer } from './PetManagementFinancial';

import { useStyles } from '../../assets/styles';

function PetPolicyPage() {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { userOrganization } = useCms();
  const policy = claim.policy;
  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );
  const shouldShowAccumulatedFinancialContainer = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.PET_DEDUCTIBLE_UI
  );
  const PetPolicyCard = isConfigurableCoveragesEnabled ? ConfigurableCoveragesPetTable : PetPolicyCoveredPetCard;

  return (
    <Grid container>
      <Grid item md={shouldShowAccumulatedFinancialContainer ? 6 : 7} xs={12}>
        <div className={classes.cardDivRow}>
          <PetPolicyDetailsCard claim={claim} onClaimUpdate={onClaimUpdate} />
        </div>
      </Grid>
      {shouldShowAccumulatedFinancialContainer && (
        <Grid item xs={6}>
          <div className={classes.cardDivRow}>
            <PetAccumulatedFinancialContainer />
          </div>
        </Grid>
      )}
      {_.sortBy(policy.covered_pets, ['id']).map((coveredPet) => (
        <Grid key={coveredPet.id} item md={6} xs={12}>
          <div className={classes.cardDivRow}>
            <PetPolicyCard coveredPet={coveredPet} />
          </div>
        </Grid>
      ))}
      {claim.unlisted_covered_pet && (
        <Grid item md={6} xs={12}>
          <div className={classes.cardDivRow}>
            <PetPolicyCard coveredPet={claim.unlisted_covered_pet} isUnlistedPet />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

function PetPolicyCoveredPetCard({ coveredPet, isUnlistedPet }) {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const [showEditPet, setShowEditPet] = useState(false);
  const { currencyFormatter } = useCurrencyFormatter();

  const handleUpdateCoveredPet = async (values) => {
    const petUrl = isUnlistedPet ? 'unlisted_pet' : 'policy/covered_pets';
    try {
      await axios.patch(`/api/v1/pet_claims/${claim.id}/${petUrl}/${coveredPet.id}`, values);
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleUpdatePet = async (values) => {
    try {
      await axios.patch(`/api/v1/pet_claims/${claim.id}/unlisted_pet/pet/${coveredPet.id}`, values);
      await onClaimUpdate();
      setShowEditPet(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      <CardDialog
        title={isUnlistedPet ? 'Unlisted Pet Details' : 'Covered Pet Details'}
        action={
          isUnlistedPet && (
            <Button className={classes.button} color="primary" onClick={() => setShowEditPet(true)}>
              Edit Pet Details
            </Button>
          )
        }
      >
        <ShowOnlyPetDetailsInfoCardDialog pet={coveredPet.pet} />
        {}
        <HoverChangeField
          name="deductible"
          value={coveredPet.deductible}
          label="Deductible"
          specialFieldType="monetary"
          onUpdate={async (updatedValues) => await handleUpdateCoveredPet(updatedValues)}
          width="200px"
          showOnly
          permanent={isUnlistedPet}
        >
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={currencyFormatter.format(coveredPet.deductible)}
            label="Deductible"
            width="100px"
          />
        </HoverChangeField>
      </CardDialog>
      {showEditPet && (
        <EditPetDetailsDialog
          pet={coveredPet.pet}
          onSavePetDetails={handleUpdatePet}
          onClose={() => setShowEditPet(false)}
        />
      )}
    </>
  );
}
PetPolicyCoveredPetCard.propTypes = {
  coveredPet: PropTypes.object.isRequired,
  isUnlistedPet: PropTypes.bool,
};

function PetPolicyDetailsCard(props) {
  const { claim, showOnly, onClaimUpdate } = props;
  const [showEditPolicyEffectiveDate, setShowEditPolicyEffectiveDate] = useState(false);
  const classes = useStyles();
  const { policy } = claim;
  const { user, userOrganization } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();
  const { coverageConfiguration } = useOrganization();

  const readOnly = showOnly || user.role.is_view_only;

  const handleUpdateFields = async (updateObject) => {
    try {
      const res = await axios.patch(`/api/v1/claims/${claim.id}/policy`, updateObject);
      await onClaimUpdate(res.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  if (isPolicyNotFound(claim)) {
    return (
      <CardDialog className={classes.CardDialog} title="Policy Details">
        <Typography display="block" color="secondary">
          Policy Not Found
        </Typography>
      </CardDialog>
    );
  }

  const policyStatusDuringLoss = getPolicyStatusDuringLoss(
    policy,
    claim?.incident?.date_of_loss,
    claim?.incident?.time_of_loss
  );
  const policyStatus = getPolicyStatus(policyStatusDuringLoss);

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title="Policy Details"
        action={<EditManuallyFilledPolicyContainer />}
        subheader={
          <span style={{ color: policyStatus === 'In Force' ? 'green' : 'red' }}>
            <strong>{policyStatus}</strong>
          </span>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ShowOnlyTextField label="Policy Number" classes={classes} showOnlyValueComponent={policy.policy_number} />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact.full_name}
              label="Named Insured"
              fullWidth
              showOnly
            />
          </Grid>
          {isBoostOrganization(userOrganization) && (
            <>
              <Grid item xs={6}>
                <ShowOnlyTextField label="Client" classes={classes} showOnlyValueComponent={policy.client} />
              </Grid>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  label="Underwriting Company"
                  classes={classes}
                  showOnlyValueComponent={policy.underwriting_company}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Effective Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
              }
              fullWidth
              onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="Expiration Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
              }
              fullWidth
              onEdit={readOnly ? undefined : () => setShowEditPolicyEffectiveDate(true)}
            />
          </Grid>
          {coverageConfiguration.limit_per_claim && Number.isFinite(policy.limit_per_claim) && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Limit per Claim"
                classes={classes}
                showOnlyValueComponent={currencyFormatter.format(policy.limit_per_claim)}
                fullWidth
              />
            </Grid>
          )}
          {isBoostOrganization(userOrganization) && (
            <>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  label="Policy Limit"
                  classes={classes}
                  showOnlyValueComponent={currencyFormatter.format(policy.policy_limit)}
                />
              </Grid>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  label="Policy Limit Pet Incident"
                  classes={classes}
                  showOnlyValueComponent={
                    policy.limit_per_incident ? (
                      currencyFormatter.format(policy.limit_per_incident)
                    ) : (
                      <WarningIcon className={classes.warningColor} style={{ paddingLeft: '5px' }} />
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  label="Policy Limit Lifetime"
                  classes={classes}
                  showOnlyValueComponent={
                    policy.limit_lifetime ? (
                      currencyFormatter.format(policy.limit_lifetime)
                    ) : (
                      <WarningIcon className={classes.warningColor} style={{ paddingLeft: '5px' }} />
                    )
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardDialog>
      <UpdatePolicyEffectiveDateDialog
        onClose={() => setShowEditPolicyEffectiveDate(false)}
        policy={policy}
        open={showEditPolicyEffectiveDate}
        onSubmit={async (values) => {
          await handleUpdateFields(values);
          setShowEditPolicyEffectiveDate(false);
        }}
      />
    </>
  );
}

PetPolicyDetailsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: requiredIf(PropTypes.func, (props) => !props.showOnly),
  showOnly: PropTypes.bool,
};

const ConfigurableCoveragesPetTable = ({ coveredPet, isUnlistedPet }) => {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const [showEditPet, setShowEditPet] = useState(false);
  const { currencyFormatter } = useCurrencyFormatter();

  const petUrl = isUnlistedPet ? 'unlisted_pet' : 'policy/covered_pets';

  const handleUpdatePet = async (values) => {
    try {
      await axios.patch(`/api/v1/pet_claims/${claim.id}/unlisted_pet/pet/${coveredPet.id}`, values);
      await onClaimUpdate();
      setShowEditPet(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleUpdatePetDeductible = async (values) => {
    const petUrl = isUnlistedPet ? 'unlisted_pet' : 'policy/covered_pets';
    try {
      await axios.patch(
        `/api/v1/pet_claims/${claim.id}/${petUrl}/${coveredPet.id}/involved_pet_deductible_per_policy`,
        values
      );
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title={
          <div className={classes.containerCentered}>
            <PetsIcon />
            &nbsp;
            <Typography display="block" variant="h6">
              {isUnlistedPet ? 'Unlisted Pet Details' : 'Covered Pet Details'}
            </Typography>
          </div>
        }
        action={
          isUnlistedPet && (
            <Button className={classes.button} color="primary" onClick={() => setShowEditPet(true)}>
              Edit Pet Details
            </Button>
          )
        }
      >
        <ShowOnlyPetDetailsInfoCardDialog pet={coveredPet.pet} />
        <br />
        <div className={classes.outlinedCardLegend}>
          <CoveredEntityCoveragesTable
            coveredEntity={coveredPet.covered_entity}
            entityDesc="pet"
            onCoverageUpdate={async (postValues, coverageKey) => {
              await axios.patch(
                `/api/v1/pet_claims/${claim.id}/${petUrl}/${coveredPet.id}/coverage/${coverageKey}`,
                postValues
              );
            }}
            onSharedCoverageUpdate={async (postValues, sharedCoverageKey) => {
              await axios.patch(
                `/api/v1/pet_claims/${claim.id}/${petUrl}/${coveredPet.id}/shared_coverage/${sharedCoverageKey}`,
                postValues
              );
            }}
          />
          <>
            <Typography variant="subtitle2" style={{ paddingTop: '20px' }}>
              Deductible per Policy
            </Typography>
            <HoverChangeField
              name="deductible_per_policy_per_covered_entity"
              value={coveredPet.covered_entity.deductible_per_policy_per_covered_entity}
              label="Deductible per Policy"
              specialFieldType="monetary"
              onUpdate={async (updatedValues) => await handleUpdatePetDeductible(updatedValues)}
              width="200px"
              showOnly
              permanent={isUnlistedPet}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  coveredPet.covered_entity.deductible_per_policy_per_covered_entity &&
                  currencyFormatter.format(coveredPet.covered_entity.deductible_per_policy_per_covered_entity)
                }
                width="100px"
              />
            </HoverChangeField>
          </>
        </div>
      </CardDialog>
      {showEditPet && (
        <EditPetDetailsDialog
          pet={coveredPet.pet}
          onSavePetDetails={handleUpdatePet}
          onClose={() => setShowEditPet(false)}
        />
      )}
    </>
  );
};

ConfigurableCoveragesPetTable.propTypes = {
  coveredPet: PropTypes.object.isRequired,
  isUnlistedPet: PropTypes.bool,
};

export { PetPolicyPage };
