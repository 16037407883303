import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import { InvolvedPropertyItemsSectionFragment } from '../InvolvedPropertyParty/InvolvedPropertyItemsSectionFragment';

import { GenericSectionInnerCard } from './InvolvedGenericFragment';

import styles from './index.module.scss';

const LOSS_OBJECT_ID = 'related_property_loss';
const ITEMS_ID = 'items';

const RelatedPropertyLossFragment = ({ involvedParty, preDefinedFields }) => {
  const { values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const isAllowedRelatedDamagedProperty =
    involvedParty && incidentConfiguration.involved_parties[involvedParty]?.is_allowed_related_damaged_property;
  const relatedPropertyLossPredefinedFields = preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));
  const fragmentId = `involved_parties.${involvedParty}.${LOSS_OBJECT_ID}`;
  const sectionTitle = get(incidentConfiguration, `${fragmentId}.desc`, 'Related Property Loss');
  const propertyItemsId = `${LOSS_OBJECT_ID}.${ITEMS_ID}`;
  const isSectionActive = get(incidentConfiguration, `${fragmentId}.active`, true);
  const isItemsActive = get(incidentConfiguration, `${fragmentId}.${ITEMS_ID}.active`, true);

  return (
    <GenericSectionInnerCard
      sectionTitle={sectionTitle}
      isSectionAllowed={isAllowedRelatedDamagedProperty}
      isSectionActive={isSectionActive}
      preDefinedFields={preDefinedFields}
      enablingFieldId="is_related_property_loss"
      enablingValue={values?.is_related_property_loss}
      collapsibleTitle="Fill Property Loss Details"
    >
      <Grid item xs={12}>
        <Typography className={styles.subHeader} display="block" variant="subtitle1">
          Property Details
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        <PreDefinedField
          id="location"
          fields={relatedPropertyLossPredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="damages"
          fields={relatedPropertyLossPredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="is_stolen"
          fields={relatedPropertyLossPredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="damages_description"
          fields={relatedPropertyLossPredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="note"
          fields={relatedPropertyLossPredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
      </Grid>
      {isItemsActive && (
        <InvolvedPropertyItemsSectionFragment preDefinedFields={preDefinedFields} propertyItemsId={propertyItemsId} />
      )}
    </GenericSectionInnerCard>
  );
};

RelatedPropertyLossFragment.propTypes = {
  involvedParty: PropTypes.string,
  preDefinedFields: PropTypes.object.isRequired,
};

export { RelatedPropertyLossFragment };
