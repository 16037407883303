import React from 'react';
import { Typography } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';
import { useLob } from '~/components/hooks/useLob';

import CardDialog from '../../../CardDialog';

import {
  ACCIDENT_FOLLOW_UP_PATH,
  getInitialAccidentFollowUpInitialValidationSchema,
  getInitialAccidentFollowUpInitialValues,
  getInitialAccidentFollowUpSectionConfig,
  InitialAccidentFollowUpFragment,
} from './Fragments/InitialAccidentFollowUpFragment';
import {
  FAULT_ASSESSMENT_PATH,
  getInitialFaultAssessmentInitialValidationSchema,
  getInitialFaultAssessmentInitialValues,
  getInitialFaultAssessmentSectionConfig,
  InitialFaultAssessmentFragment,
} from './Fragments/InitialFaultAssessmentFragment';
import {
  getInitialTheftFollowUpInitialValidationSchema,
  getInitialTheftFollowUpInitialValues,
  getInitialTheftFollowUpSectionConfig,
  InitialTheftFollowUpFragment,
  THEFT_FOLLOW_UP_PATH,
} from './Fragments/InitialTheftFollowUpFragment';
import {
  getPoliceFireDepartmentInvolvementInitialValues,
  getPoliceFireDepartmentInvolvementSectionConfig,
  getPoliceFireDepartmentInvolvementValidationSchema,
  POLICE_AND_FIRE_DEPARTMENT_PATH,
  PoliceFireDepartmentInvolvementFragment,
} from './Fragments/PoliceFireDepartmentInvolvementFragment';
import {
  getIsSupplementaryInformationSectionActive,
  getSupplementaryInformationInitialValues,
  getSupplementaryInformationSectionConfig,
  getSupplementaryInformationSectionTitle,
  getSupplementaryInformationValidationSchema,
  SUPPLEMENTARY_INFORMATION_PATH,
  SupplementaryInformationFragment,
} from './Fragments/SupplementaryInformationFragment';
import AdditionalInformationSection from './AdditionalInformationSection';

import styles from './index.module.scss';

const DESCRIPTION = 'Please answer the questions listed in the following sections, if applicable.';

const getSectionsValidationSchema = (lob, incidentConfiguration) => {
  if (!getIsAdditionalInformationActive(incidentConfiguration, lob)) {
    return {};
  }

  const validations = {
    verified_supplementary_information: getIsSupplementaryInformationSectionActive(incidentConfiguration)
      ? Yup.boolean().oneOf([true], 'Required')
      : undefined,
  };

  if (lob === 'auto_claim') {
    return {
      ...validations,
      verified_initial_fault_assessment: getInitialFaultAssessmentSectionConfig(incidentConfiguration)?.active
        ? Yup.boolean().oneOf([true], 'Required')
        : undefined,
      verified_police_and_fire_department: getPoliceFireDepartmentInvolvementSectionConfig(incidentConfiguration)
        ?.active
        ? Yup.boolean().oneOf([true], 'Required')
        : undefined,
      verified_initial_theft_follow_up: getInitialTheftFollowUpSectionConfig(incidentConfiguration)?.active
        ? Yup.boolean().oneOf([true], 'Required')
        : undefined,
      verified_initial_accident_follow_up: getInitialAccidentFollowUpSectionConfig(incidentConfiguration)?.active
        ? Yup.boolean().oneOf([true], 'Required')
        : undefined,
    };
  }

  return validations;
};

const getIsAdditionalInformationActive = (incidentConfiguration, lob) => {
  const isSupplementaryInformationSectionActive = getIsSupplementaryInformationSectionActive(incidentConfiguration);

  if (lob !== 'auto_claim') {
    return isSupplementaryInformationSectionActive;
  }

  const isAutoConfigsActive = [
    isSupplementaryInformationSectionActive,
    getPoliceFireDepartmentInvolvementSectionConfig(incidentConfiguration)?.active,
    getInitialFaultAssessmentSectionConfig(incidentConfiguration)?.active,
    getInitialTheftFollowUpSectionConfig(incidentConfiguration)?.active,
    getInitialAccidentFollowUpSectionConfig(incidentConfiguration)?.active,
  ];

  return isAutoConfigsActive.some((isActive) => isActive);
};

const AdditionalInformation = () => {
  const { lob } = useLob();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { setFieldValue, values } = useFormikContext();
  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();

  const onSubmit = (sectionPath, formValues) => {
    setFieldValue(sectionPath, formValues);
  };

  const GENERAL_SECTIONS_PROPS = [
    {
      id: 'supplementary_information',
      title: getSupplementaryInformationSectionTitle(incidentConfiguration),
      initialValues: getIn(values, SUPPLEMENTARY_INFORMATION_PATH),
      onSubmit: (formValues) => onSubmit(SUPPLEMENTARY_INFORMATION_PATH, formValues),
      sectionConfig: getSupplementaryInformationSectionConfig(incidentConfiguration, isFieldSupportedBySubtype),
      AdditionalInformationFragment: SupplementaryInformationFragment,
      AdditionalInformationFragmentInitialValues: getSupplementaryInformationInitialValues(
        incidentConfiguration,
        lob,
        isFieldSupportedBySubtype
      ),
      AdditionalInformationFragmentValidationSchema: getSupplementaryInformationValidationSchema(
        incidentConfiguration,
        lob,
        isFieldSupportedBySubtype
      ),
    },
  ];

  const AUTO_SECTIONS_PROPS = [
    {
      id: 'police_and_fire_department',
      title: 'Police and Fire Department Involvement',
      initialValues: getIn(values, POLICE_AND_FIRE_DEPARTMENT_PATH),
      onSubmit: (formValues) => onSubmit(POLICE_AND_FIRE_DEPARTMENT_PATH, formValues),
      sectionConfig: getPoliceFireDepartmentInvolvementSectionConfig(incidentConfiguration, isFieldSupportedBySubtype),
      AdditionalInformationFragment: PoliceFireDepartmentInvolvementFragment,
      AdditionalInformationFragmentInitialValues: getPoliceFireDepartmentInvolvementInitialValues(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
      AdditionalInformationFragmentValidationSchema: getPoliceFireDepartmentInvolvementValidationSchema(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
    },
    {
      id: 'initial_fault_assessment',
      title: 'Initial Fault Assessment',
      initialValues: getIn(values, FAULT_ASSESSMENT_PATH),
      onSubmit: (formValues) => onSubmit(FAULT_ASSESSMENT_PATH, formValues),
      sectionConfig: getInitialFaultAssessmentSectionConfig(incidentConfiguration, isFieldSupportedBySubtype),
      AdditionalInformationFragment: InitialFaultAssessmentFragment,
      AdditionalInformationFragmentInitialValues: getInitialFaultAssessmentInitialValues(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
      AdditionalInformationFragmentValidationSchema: getInitialFaultAssessmentInitialValidationSchema(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
    },
    {
      id: 'initial_theft_follow_up',
      title: 'Initial Theft Follow-Up',
      initialValues: getIn(values, THEFT_FOLLOW_UP_PATH),
      onSubmit: (formValues) => onSubmit(THEFT_FOLLOW_UP_PATH, formValues),
      sectionConfig: getInitialTheftFollowUpSectionConfig(incidentConfiguration, isFieldSupportedBySubtype),
      AdditionalInformationFragment: InitialTheftFollowUpFragment,
      AdditionalInformationFragmentInitialValues: getInitialTheftFollowUpInitialValues(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
      AdditionalInformationFragmentValidationSchema: getInitialTheftFollowUpInitialValidationSchema(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
    },
    {
      id: 'initial_accident_follow_up',
      title: 'Initial Accident Follow-Up',
      initialValues: getIn(values, ACCIDENT_FOLLOW_UP_PATH),
      onSubmit: (formValues) => onSubmit(ACCIDENT_FOLLOW_UP_PATH, formValues),
      sectionConfig: getInitialAccidentFollowUpSectionConfig(incidentConfiguration, isFieldSupportedBySubtype),
      AdditionalInformationFragment: InitialAccidentFollowUpFragment,
      AdditionalInformationFragmentInitialValues: getInitialAccidentFollowUpInitialValues(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
      AdditionalInformationFragmentValidationSchema: getInitialAccidentFollowUpInitialValidationSchema(
        incidentConfiguration,
        isFieldSupportedBySubtype
      ),
    },
  ];

  const getSectionsProps = (lob) => {
    switch (lob) {
      case 'auto_claim':
        return [...GENERAL_SECTIONS_PROPS, ...AUTO_SECTIONS_PROPS];
      default:
        return GENERAL_SECTIONS_PROPS;
    }
  };

  return (
    <CardDialog title="Additional Information" containerStyle={{ borderRadius: '6px' }}>
      <Grid container className={styles.AdditionalInformation}>
        <Grid item xs={12} className={styles.description}>
          <Typography display="block" variant="body1" color="textSecondary">
            {DESCRIPTION}
          </Typography>
        </Grid>
        {getSectionsProps(lob).map((sectionProps, index) => (
          <AdditionalInformationSection key={index} {...sectionProps} />
        ))}
      </Grid>
    </CardDialog>
  );
};

export { getIsAdditionalInformationActive, getSectionsValidationSchema };
export default AdditionalInformation;
