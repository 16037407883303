import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useCms } from '~/components/hooks/useCms';
import LoadingIndicator from '~/components/LoadingIndicator';
import RadioButtonFormik from '~/components/RadioButtonFormik';
import TextFieldFormik from '~/components/TextFieldFormik';
import useDataFetcher from '~/components/useDataFetcher';
import { TWILIO_ADJUSTER_STATUS_DICT } from '~/Types';

const ForwardCallDialog = ({ participantsDetails, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { user } = useCms();

  const {
    isLoading: isLoadingAdjusters,
    isError: isErrorAdjusters,
    data: callableAdjuster,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/callable_adjusters_with_status`);
  const {
    isLoading: isLoadingQueues,
    isError: isErrorQueues,
    data: queuesDetails,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/calling_twilio_queues_status`);

  function getQueueAvailabilityIndicator(queuesWorker) {
    let color;
    let title;
    if (queuesWorker.length === 0) {
      color = 'grey';
      title = 'No adjusters assigned to queue';
    } else if (queuesWorker.filter((worker) => worker.is_available).length > 0) {
      color = '#119918'; // Green
      title = 'Available';
    } else {
      color = '#c70606'; // Red
      title = 'Busy';
    }

    return (
      <Tooltip title={title} placement="left">
        <FiberManualRecordIcon style={{ color }} />
      </Tooltip>
    );
  }

  return (
    <Formik
      initialValues={{
        forward_to_type: '',
        adjuster_id: '',
        queue_name: '',
      }}
      validationSchema={Yup.object().shape({
        forward_to_type: Yup.string().required('Required'),
        adjuster_id: Yup.string().when('forward_to_type', {
          is: 'adjuster',
          then: Yup.string().required('Required'),
        }),
        queue_name: Yup.string().when('forward_to_type', {
          is: 'queue',
          then: Yup.string().required('Required'),
        }),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <CardDialog
          title="Forward Call"
          isDialog
          maxWidth="sm"
          fullWidth
          onClose={onCancel}
          preventClose={isSubmitting}
          height="300px"
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>Forward to</span>
            </Grid>
            <Grid item xs={4}>
              <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                <RadioButtonFormik id="forward_to_type" label="Adjuster" optionValue="adjuster" />
                <RadioButtonFormik id="forward_to_type" label="Queue" optionValue="queue" />
              </div>
            </Grid>
            <Grid item xs={4} />
            {participantsDetails.length !== 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.warningColor}>
                  All additional participants would be disconnected from the call
                </Typography>
              </Grid>
            )}
            {!values.forward_to_type && (
              <Grid item xs={12}>
                <div style={{ visibility: 'hidden' }}>
                  <TextFieldFormik id="place_holder" label="place_holder" fullWidth />
                </div>
              </Grid>
            )}
            {values.forward_to_type === 'adjuster' &&
              (isLoadingAdjusters ? (
                <LoadingIndicator isError={isErrorAdjusters} classes={classes} />
              ) : (
                <Grid item xs={7}>
                  <TextFieldFormik id="adjuster_id" label="Adjuster" select fullWidth>
                    {callableAdjuster
                      .filter(({ adjuster_id }) => adjuster_id !== user.id)
                      .map(({ adjuster_id, adjuster_name, status }) => (
                        <MenuItem
                          key={adjuster_id}
                          value={adjuster_id}
                          disabled={!TWILIO_ADJUSTER_STATUS_DICT[status].can_accept_direct_incoming_calls}
                        >
                          <Typography variant="body2" display="inline">
                            {adjuster_name}
                          </Typography>
                          &nbsp;
                          <Typography variant="caption" display="inline">
                            ({TWILIO_ADJUSTER_STATUS_DICT[status].desc})
                          </Typography>
                        </MenuItem>
                      ))}
                  </TextFieldFormik>
                </Grid>
              ))}
            {values.forward_to_type === 'queue' &&
              (isLoadingQueues ? (
                <LoadingIndicator isError={isErrorQueues} classes={classes} />
              ) : (
                <Grid item xs={7}>
                  <TextFieldFormik id="queue_name" label="Queue" select fullWidth>
                    {queuesDetails.map(({ queue_sid, workers, name }) => (
                      <MenuItem key={queue_sid} value={name}>
                        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                          {getQueueAvailabilityIndicator(workers)}&nbsp;
                          <Typography variant="body2" display="inline">
                            {name}
                          </Typography>
                        </div>
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
              ))}
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              Forward Call
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

ForwardCallDialog.propTypes = {
  participantsDetails: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ForwardCallDialog;
