import React, { useCallback, useEffect, useState } from 'react';
import { ListItemText, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { useFormikContext } from 'formik';

import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import type { TemplateTypeKey } from '~/components/GenericTemplates/types';
import {
  FROM_TEMPLATE_FORM_KEYS,
  htmlToTextTemplateParser,
} from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import useOrganization from '~/components/OrganizationContext';
import GENERIC_TEMPLATES_TYPES from '~/server_shared/generated-types/GENERIC_TEMPLATES_TYPES';
import { getAxiosParamsSerializer, reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

const FETCH_PARAMS_SERIALIZER = getAxiosParamsSerializer('none');
const CLAIM_LEVEL_OPTION = 'claim_level';
const EXPOSURE_LEVEL_OPTION = 'exposure_level';

interface GenericTemplateSelectionContainerFormikProps {
  templateType: TemplateTypeKey;
  handleClose: () => void;
  titleFieldId?: string;
  bodyFieldId: string;
  shouldConvertBodyHtmlToText?: boolean;
  signature?: string;
  setFailedTokens?: (tokens: string[]) => void;
}

const GenericTemplateSelectionContainerFormik: React.FC<GenericTemplateSelectionContainerFormikProps> = ({
  templateType,
  handleClose,
  titleFieldId,
  bodyFieldId,
  shouldConvertBodyHtmlToText,
  signature,
  setFailedTokens,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId } = useOrganization();
  const { claim } = useClaim();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplatesId] = useState<number>();
  const [searchParams, setSearchParams] = useState({});
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [selectedTemplateLevel, setSelectedTemplateLevel] = useState<string | number>();
  const [selectedExposureId, setSelectedExposureId] = useState(NaN);

  const isClaimLevel = selectedTemplateLevel === CLAIM_LEVEL_OPTION;
  const coverageType = isClaimLevel
    ? undefined
    : claim.exposures.find((exposure: { id: number; coverage_type: string }) => exposure.id === selectedExposureId)
        ?.coverage_type;

  const getGenericTemplates = useCallback(
    async (claimLevel: boolean, coverageType?: string) => {
      try {
        const searchParams = {
          template_type: templateType,
          is_claim_level: claimLevel,
          lobs: [claim.lob],
          is_enabled: true,
          sub_organization_ids: claim.policy.sub_organization?.id ? [claim.policy.sub_organization.id] : [],
          coverage_keys: claimLevel ? [] : [coverageType],
          states: claim.policy.policy_state ? [claim.policy.policy_state] : [],
        };

        setIsLoadingTemplates(true);

        const { data } = await axios.get(GENERIC_TEMPLATES_ROUTES.GET_TEMPLATES(organizationId), {
          params: searchParams,
          paramsSerializer: FETCH_PARAMS_SERIALIZER,
        });
        setAvailableTemplates(data?.generic_templates_metadata);
        setSearchParams(searchParams);
        setIsLoadingTemplates(false);
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [claim.lob, claim.policy.policy_state, claim.policy.sub_organization?.id, organizationId, templateType]
  );

  useEffect(() => {
    if (!selectedTemplateLevel || (selectedTemplateLevel === EXPOSURE_LEVEL_OPTION && !selectedExposureId)) {
      return;
    }

    getGenericTemplates(isClaimLevel, coverageType);
  }, [coverageType, isClaimLevel, selectedTemplateLevel, getGenericTemplates, selectedExposureId]);

  const getFilledTemplate = async ({ templateId }: { templateId: number }) => {
    try {
      const { data } = await axios.get(
        GENERIC_TEMPLATES_ROUTES.GENERATE_FROM_TEMPLATE({
          organizationId,
          template_id: templateId,
          claimId: claim.id,
          exposureId: selectedExposureId,
        })
      );

      if (
        setFailedTokens &&
        Array.isArray(data?.failed_token_display_names) &&
        data?.failed_token_display_names.length > 0
      ) {
        setFailedTokens(data?.failed_token_display_names);
      }

      let bodyContent = shouldConvertBodyHtmlToText
        ? htmlToTextTemplateParser(data.body_template, true)
        : data.body_template;

      if (signature) {
        bodyContent += `\n${signature}`;
      }

      setFieldValue(bodyFieldId, bodyContent);
      setFieldTouched(bodyFieldId, true);

      setFieldValue(FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID, selectedTemplateId);
      setFieldValue(FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT, searchParams);

      if (titleFieldId) {
        setFieldValue(titleFieldId, htmlToTextTemplateParser(data.title_template, false));
        setFieldTouched(titleFieldId, true);
      }

      handleClose();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  return (
    <CardDialog
      isDialog
      title={`Select ${GENERIC_TEMPLATES_TYPES[templateType]?.display_name} Template`}
      fullWidth
      width="sm"
      onClose={handleClose}
    >
      {availableTemplates.length === 0 ? (
        <AlertBanner
          note="No templates exist for the currently selected context"
          alertType={AlertBanner.ALERT_TYPES.WARNING}
        />
      ) : null}
      <div className="my-10 w-full">
        <div className="my-10 w-full">
          <TextField
            label="Template Level"
            className="w-full"
            onChange={(event: { target: { value: string | number } }) => {
              const eventTargetValue = event.target.value;
              setSelectedTemplateLevel(eventTargetValue);
            }}
            select
            disabled={isLoadingTemplates}
          >
            <MenuItem value={CLAIM_LEVEL_OPTION}>
              <ListItemText primary="Claim Level" />
            </MenuItem>

            <MenuItem value={EXPOSURE_LEVEL_OPTION}>
              <ListItemText primary="Exposure Level" />
            </MenuItem>
          </TextField>
        </div>

        <div className="my-10 w-full">
          <TextField
            label="Exposure"
            className="w-full"
            onChange={(event: { target: { value: unknown } }) => {
              const eventTargetValue = event.target.value;
              setSelectedExposureId(eventTargetValue as number);
            }}
            select
            disabled={isLoadingTemplates || selectedTemplateLevel !== EXPOSURE_LEVEL_OPTION}
          >
            {claim.exposures.map((exposure: { id: number; label_text: string }) => (
              <MenuItem key={exposure.id} value={exposure.id}>
                <ListItemText primary={exposure.label_text} />
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="my-10 w-full">
          <Autocomplete
            id="template_id"
            size="small"
            options={availableTemplates}
            getOptionLabel={(option: { template_name: string; id: number }) => option.template_name}
            disabled={availableTemplates.length === 0}
            onChange={(_, selectedTemplate) => setSelectedTemplatesId(selectedTemplate?.id)}
            renderInput={(params) => <TextField label="Template" {...params} className="my-5 h-full" />}
          />
        </div>
        <div className="mt-15 flex w-full justify-end">
          <CancelButton onClick={handleClose} />
          <Button
            variant="contained"
            color="primary"
            disabled={availableTemplates.length === 0 || !selectedTemplateId}
            onClick={async () => {
              if (!selectedTemplateId) {
                return reportErrorInProductionOrThrow(
                  'getFilledTemplate should be disabled if there is no selectedTemplateId'
                );
              }
              await getFilledTemplate({ templateId: selectedTemplateId });
            }}
          >
            Select
          </Button>
        </div>
      </div>
    </CardDialog>
  );
};

export default GenericTemplateSelectionContainerFormik;
