import React from 'react';
import { ArrowExpand } from 'mdi-material-ui';

import { AiChatContainer } from '~/components/AiChat/AiChatContainer/AiChatContainer';
import { ChatEmptyState } from '~/components/AiChat/ChatEmptyState';
import { AiAvatar } from '~/components/AiChat/components/Message/AiAvatar';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import { ChatSidebarDialog } from '~/components/AiChat/SideBarDialog/ChatSidebarDialog';
import { SIDEBAR_DIALOG_CONFIG } from '~/components/AiChat/SideBarDialog/SidebarDialogConstants';
import { useClaim } from '~/components/ClaimContainer';
import { Text } from '~/components/core';
import cn from '~/Utils/cn';

export const ClaimChatDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { claim } = useClaim();

  const { chat, isChatLoading, exchanges } = useChatContext();

  const [isMinimized, setIsMinimized] = React.useState(false);

  const title = isChatLoading
    ? `Loading chat data for claim ${claim.claim_id_display}`
    : chat?.chat_title || `Claim Chat ${claim.claim_id_display}`;

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <>
      <ChatSidebarDialog
        onClose={onClose}
        dialogOpen={!isMinimized}
        onMinimize={handleMinimize}
        isDialogMinimized={isMinimized}
        isHeaderCollapsed={exchanges?.length > 0 && !isChatLoading}
        claimSummary={chat?.greeting}
        chatTitle={title}
        dialogTitle={`Claim ${claim.claim_id_display}`}
      >
        {!chat && isChatLoading ? <ChatEmptyState /> : null}
        {chat ? (
          <AiChatContainer
            messagesContainerClassName={cn(SIDEBAR_DIALOG_CONFIG.paddingX, 'pb-24')}
            showGreetingAsMessage={false}
            inputFieldType="inline"
            preDefinedMessages={[
              'Who are the involved parties in this claim?',
              'Who is liable for the incident?',
              'What is the status of the claim?',
            ]}
          />
        ) : null}
      </ChatSidebarDialog>
      {isMinimized ? (
        <div className="border-4 fixed bottom-30 right-30 flex h-[72px] w-[220px] cursor-pointer rounded-full border-solid border-slate-600 bg-aiChat bg-cover bg-no-repeat shadow-lg">
          <div
            onClick={handleMinimize}
            className="row flex w-full items-center justify-between gap-10 rounded-full pl-20 pr-5 align-middle font-bold text-white"
            style={{
              background: 'radial-gradient(47.06% 47.06% at 66.33% 42.13%, #2A3746ad 0%, #305A7Fc7 100%)',
              backgroundBlendMode: 'lighten',
            }}
          >
            <ArrowExpand className="text-white" />
            <Text className="text-white" variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
              Clive
            </Text>
            <AiAvatar className="h-[60px] w-[60px]" />
          </div>
        </div>
      ) : null}
    </>
  );
};
